import { TableCompHead } from '../../../../components/ui';
import React from 'react';
import { HeaderStandard_1, headerConstant } from '../helpers/constant';
import { getTotalColsSpan } from '../../../../utils/helpers/utils';
import { Checkbox } from '@mui/material';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
  updateCheckBox?: any;
}

const THead = ({ moduleData, updateModuleData, updateCheckBox }: IProps) => {
  const handleInputChange = (fieldName: string, value: any) => {
    updateCheckBox(fieldName, value);
  };
  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(HeaderStandard_1)}
        rowSpan={3}
        labels={['Doc No.', 'Rev-', 'Dated:']}
        values={['F-HRD-14', '01', '02.01.2024']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th align="left" colSpan={2}>
          Reason for OJT-Introduction of method/product
        </th>
        <th colSpan={3}>
          <Checkbox
            name="checkbox_1"
            key="checkbox_1"
            color="primary"
            checked={moduleData.checkbox_1 || ''}
            onChange={(e) => {
              handleInputChange(e.target.name, e.target.checked);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          Induction of new employee
        </th>
        <th colSpan={3}>
          <Checkbox
            name="checkbox_2"
            key="checkbox_2"
            color="primary"
            checked={moduleData.checkbox_2 || ''}
            onChange={(e) => {
              handleInputChange(e.target.name, e.target.checked);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          Modifications in the existing method/product
        </th>
        <th colSpan={3}>
          <Checkbox
            name="checkbox_3"
            key="checkbox_3"
            color="primary"
            checked={moduleData.checkbox_3 || ''}
            onChange={(e) => {
              handleInputChange(e.target.name, e.target.checked);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          Changes in the method due to quality issues
        </th>
        <th colSpan={3}>
          <Checkbox
            name="checkbox_4"
            key="checkbox_4"
            color="primary"
            checked={moduleData.checkbox_4 || ''}
            onChange={(e) => {
              handleInputChange(e.target.name, e.target.checked);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          Skill development of the concerned employee/department
        </th>
        <th colSpan={3}>
          <Checkbox
            name="checkbox_5"
            key="checkbox_5"
            color="primary"
            checked={moduleData.checkbox_5 || ''}
            onChange={(e) => {
              handleInputChange(e.target.name, e.target.checked);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          Any other
        </th>
        <th colSpan={3}>
          <Checkbox
            name="checkbox_6"
            key="checkbox_6"
            color="primary"
            checked={moduleData.checkbox_6}
            onChange={(e) => {
              handleInputChange(e.target.name, e.target.checked);
            }}
          />
        </th>
      </tr>
    </thead>
  );
};

export default React.memo(THead);
