import { AiOutlineCloudUpload, AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { GrCertificate } from 'react-icons/gr';
const tiles = [
  {
    sNo: 1,
    link: '/common/legal_compliance_listOfProcedures/?documentType=legal_compliance&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/clauseUploadList?documentType=masterProcedureClause19&heading=MASTER PROCEDURE',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'MASTER',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/clauseUploadList?documentType=certificatesStandardsClause19&heading=NOISE LEVEL IN SHOP FLOOR',
    isLink: true,
    clauseNo: 18,
    icon: <GrCertificate size="5rem" color="#003566" />,
    p: 'CERTIFICATES',
    span: '& STANDARDS',
    isIcon: true,
  },
];

export default tiles;
