import moment from 'moment';

const handleTotalSum = (process: any) => {
  const arr = [
    'off_shots',
    'n_f',
    'catch',
    'a_b',
    'b_h',
    'solder',
    'bend',
    'crack',
    'dent',
    'rejection_detail_others',
  ];
  let totalRejected = 0;
  arr.forEach((name) => {
    totalRejected += +process[name];
  });
  process.total_rej_shots = totalRejected;
  if (process.total_shots) {
    process.ok_shots = +process.total_shots - totalRejected;
  } else {
    process.ok_shots = 0;
  }
};

export const calculateTotalShots = (moduleData: any): void => {
  let sumOfTotalShots = 0;
  if (moduleData?.processes) {
    moduleData.processes.forEach(({ total_shots }: { total_shots: number }) => {
      console.log(total_shots);
      sumOfTotalShots += +total_shots || 0;
    });
  }

  moduleData.sumOfTotalShots = sumOfTotalShots;
};
export const handleChangeModuleData = (
  e: { target: { name: any; value: any } },
  process: any
) => {
  const { name, value } = e.target;
  process[name] = value;
  handleTotalSum(process);
};

export const handleChangeTimeByShift = (moduleData: any, shift: string) => {
  if (moduleData?.processes) {
    switch (shift) {
      case 'A':
        moduleData?.processes.forEach((process: any, index: number) => {
          process.counter_start = moment('9:00', 'HH:mm').add(index, 'hours');
          process.counter_end = moment('10:00', 'HH:mm').add(index, 'hours');
        });
        return;
      case 'B':
        moduleData?.processes.forEach((process: any, index: number) => {
          process.counter_start = moment('21:00', 'HH:mm').add(index, 'hours');
          process.counter_end = moment('22:00', 'HH:mm').add(index, 'hours');
        });
        return;
      default:
        return;
    }
  }
};
