import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { createConstant } from '../../constant';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../../../components/common/formatDate';
import InputMuiCustom from '../../../../../../components/common/inputs/InputMuiCustom';
import { SelectChangeEvent } from '@mui/material';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
// import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
  handleDoubleClick: any;
}

const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];

const TBody = ({ moduleData, isView, allmonth, handleDoubleClick }: IProps) => {
  const [render, setRender] = useState(false);
  const [data, setData] = useState<any>({});

  return (
    <tbody>
      {moduleData?.maintenance_list?.map((item: any, prvIndex: any) => (
        <>
          <tr className="listDataCenter">
            <td rowSpan={2}>{prvIndex + 1}</td>
            <td rowSpan={2}>
              {item?.machine_no
                ? item?.machine_no
                : item?.machine_id?.machine_no}
            </td>
            <td rowSpan={2}>
              {item?.machine_name
                ? item?.machine_name
                : item?.machine_id?.machine_name}
            </td>
            <td rowSpan={2}>
              {item?.department_name
                ? item?.department_name
                : item?.machine_id?.department_id?.department_name}
            </td>
            <td>P</td>
            {[
              ['april', 4, new Date(moduleData.date_range).getFullYear()],
              ['may', 5, new Date(moduleData.date_range).getFullYear()],
              ['june', 6, new Date(moduleData.date_range).getFullYear()],
              ['july', 7, new Date(moduleData.date_range).getFullYear()],
              ['august', 8, new Date(moduleData.date_range).getFullYear()],
              ['september', 9, new Date(moduleData.date_range).getFullYear()],
              ['october', 10, new Date(moduleData.date_range).getFullYear()],
              ['november', 11, new Date(moduleData.date_range).getFullYear()],
              ['december', 12, new Date(moduleData.date_range).getFullYear()],
              ['january', 1, new Date(moduleData.date_range).getFullYear() + 1],
              [
                'february',
                2,
                new Date(moduleData.date_range).getFullYear() + 1,
              ],
              ['march', 3, new Date(moduleData.date_range).getFullYear() + 1],
            ].map((month) => (
              <td
                style={{
                  padding: '0px',
                  margin: '0px',
                }}
              >
                <DatePickerMui
                  label=""
                  minDate={moment(new Date(`${month[1]}/01/${month[2]}`))}
                  maxDate={moment(
                    new Date(
                      `${month[1]}/${new Date(
                        +month[2],
                        +month[1],
                        0
                      ).getDate()}/${month[2]}`
                    )
                  )}
                  value={moment(item[month[0]]?.created_date || null)}
                  onChange={(date: any) => {
                    handleDoubleClick(date, month[0], prvIndex);
                  }}
                />
              </td>
            ))}
            <td
              contentEditable={true}
              data-name="remarks"
              onKeyUp={(e: any) => {
                item[e.target.dataset.name] = e.target.innerText;
              }}
              style={{
                border: '1px solid black',
                backgroundColor: '#f9e0e0',
              }}
            >
              <span>{item.remarks}</span>
            </td>
          </tr>
          <tr className="listDataCenter">
            <td>A</td>
            {[
              'april',
              'may',
              'june',
              'july',
              'august',
              'september',
              'october',
              'november',
              'december',
              'january',
              'february',
              'march',
            ].map((month) => (
              <td
                height="75px"
                width="75px"
                style={{
                  backgroundColor: item[month].doneDate && '#37b24d',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
              >
                {item[month].doneDate}
              </td>
            ))}
            <td
              contentEditable={true}
              data-name="remarks"
              onKeyUp={(e: any) => {
                item[e.target.dataset.name] = e.target.innerText;
              }}
              style={{
                border: '1px solid black',
                backgroundColor: '#f9e0e0',
              }}
            >
              <span>{item.remarks}</span>
            </td>
          </tr>
        </>
      ))}
    </tbody>
  );
};

export default TBody;
