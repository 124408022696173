// TableRowComponent.jsx
import React from 'react';
import { Checkbox } from '@mui/material';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import AutocompleteMuiCustomRevised from '../../../../../components/common/tableinputs/AutocompleteMuiCustomRevised';

export const TableRowComponent = React.memo(
  ({
    key,
    item,
    index,
    allmonth,
    updateModuleData,
    selectionArray,
    handleBulkDeleteFeature,
    machineData,
    settings,
  }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;
    const isChecked = selectionArray[index] === rowId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData(index, fieldName, { [fieldName]: value });
    };

    const handleAutocompleteChange = (newValue: any) => {
      // Update a1, a2, and row_id fields in the row
      updateModuleData(index, 'a1', { a1: newValue?.defect_name });
      // row id should  never be changed and it is populated id and you can assign value to a1 a2 from this row id and it is fallback value
      updateModuleData(index, 'row_id', newValue);
    };

    const handleCheckboxChange = (event: any) => {
      handleBulkDeleteFeature(index, event.target.checked, rowId);
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td rowSpan={2}>{index + 1}</td>
          <td rowSpan={2}>
            {item?._id && item?.row_id === null ? (
              item?.a1
            ) : (
              <AutocompleteMuiCustomRevised
                id="defect_id"
                label=""
                option_name="defect_name"
                key={'defect'}
                arrayofObj={machineData}
                value={item?.row_id}
                onChange={(_, newValue: any) =>
                  handleAutocompleteChange(newValue)
                }
                sx={{ width: '200px' }}
              />
            )}
          </td>
          {/* <td rowSpan={2}>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="frequency"
              type="text"
              value={item.frequency}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td> */}

          <td>P</td>

          {/* always constant ⬇️*/}
          <TBodyRight
            item={item}
            isView={isView}
            index={index}
            allmonth={allmonth}
            updateModuleData={updateModuleData}
          />
          {/* 👉 */}

          <td rowSpan={2}>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e: any) =>
                handleInputChange('remarks', e.target.value)
              }
            />
          </td>
          <td rowSpan={2}>
            <Checkbox
              style={{ color: '#fa5252' }}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </td>
        </tr>
        <tr>
          <td>A</td>
          {allmonth.map((month: any, mIdx: number) => (
            <td
              width="80px"
              height="45px"
              style={{
                backgroundColor: item[month].doneDate && '#37b24d',
                color: 'white',
                textTransform: 'capitalize',
                fontSize: '1.6rem',
              }}
            >
              {settings.show_A && item[month].isTask && 'A'}
              {(settings.show_date && formatDate(item[month].doneDate)) || null}
            </td>
          ))}
        </tr>
        {/* ---x---- */}
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item &&
      prevProps.selectionArray[prevProps.index] ===
        nextProps.selectionArray[nextProps.index]
    );
  }
);
