export const apiConstant = {
  // list api url
  gettableListDataurl: 'thirdPartyMaterialTestRoute/monthlylist',
  deleteListurl: 'thirdPartyMaterialTestRoute',
  getListPlanByid: 'thirdPartyMaterialTestRoute',
  createListPlanByid: 'thirdPartyMaterialTestRoute',
  // -----plan--
  getAllPlanById: 'thirdPartyMaterialTestRoute/allplansbyid',
  upadateCreateAllPlan:
    'thirdPartyMaterialTestRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'thirdPartyMaterialTestRoute/maintenancePlanDeleteBulkById',
  sync: 'thirdPartyMaterialTestRoute/handleSync',
  // ----status---
  getAllStatus: 'thirdPartyMaterialTestRoute/getAllSatusByYear',
  upadateListStatus: 'thirdPartyMaterialTestRoute/handleStatusUpdate',

  //
  updatePlanByid: 'thirdPartyMaterialTestRoute',
  checklistUpload: 'thirdPartyMaterialTestRoute/handleUpload',
  checklistDeleteImage: 'thirdPartyMaterialTestRoute/handleDeleteImage',
  checklistData: 'thirdPartyMaterialTestRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'THIRD PARTY MATERIAL TEST',
};
