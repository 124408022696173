import moment from 'moment';
import { useState } from 'react';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import { TimePicker } from '@mui/x-date-pickers';
import { TableInput } from '../../../../../components/common';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  return (
    <tbody>
      {moduleData.histories &&
        moduleData.histories.map((spec: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            {/* auto : date */}
            <td>{formatDate(spec.date)}</td>
            <td>
              {/* user : name of injured person */}
              <TableInput
                isView={false}
                name="nameOfInjuredPerson"
                type="text"
                value={spec.nameOfInjuredPerson}
                onChange={(e) => {
                  spec.nameOfInjuredPerson = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '250px' }}
              />
            </td>
            <td>
              {/*auto : name of deppt */}
              <TableInput
                isView={true}
                name="nameOfDepartment"
                type="text"
                value={spec.nameOfDepartment}
                onChange={(e) => {
                  spec.nameOfDepartment = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '500px' }}
              />
            </td>
            <td>
              {/*auto : accident type */}
              <TableInput
                isView={true}
                name="accidentType"
                type="text"
                value={spec.accidentType}
                onChange={(e) => {
                  spec.accidentType = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '500px' }}
              />
            </td>
            <td>
              {/*user : accident detail */}
              <TableInput
                isView={false}
                name="accidentDetail"
                type="text"
                value={spec.accidentDetail}
                onChange={(e) => {
                  spec.accidentDetail = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '500px' }}
              />
            </td>
            <td>
              {/*user : reason for acc*/}
              <TableInput
                isView={false}
                name="reasonForAccident"
                type="text"
                value={spec.reasonForAccident}
                onChange={(e) => {
                  spec.reasonForAccident = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '500px' }}
              />
            </td>
            <td>
              {/*user : action plan to avoid accident*/}
              {/* <TableInput
               isView={false}
                name="actionPlanToAvoidAccident"
                type="text"
                value={spec.actionPlanToAvoidAccident}
                onChange={(e) => {
                  spec.actionPlanToAvoidAccident = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '500px' }}
              /> */}
              {spec?._id &&
                (spec?.fillCapa_id ? (
                  <EditIconButton
                    tooltipTitle="Fill CAPA"
                    onClick={() =>
                      navigate(
                        `/common/createFillCapa/${spec?.fillCapa_id}?isView=false&dailyAccidentId=${moduleData?._id}&historyId=${spec?._id}&department=${spec?.nameOfDepartment}&date=${spec?.date}&nameOfInjuredPersoon=${spec?.nameOfInjuredPerson}&accident_type=${spec?.accidentType}&accident_detail=${spec?.accidentDetail}&reasonForAccident=${spec?.reasonForAccident}`
                      )
                    }
                  />
                ) : (
                  <ViewIconButton
                    tooltipTitle="Fill CAPA"
                    onClick={() =>
                      navigate(
                        `/common/createFillCapa/?isView=false&dailyAccidentId=${moduleData?._id}&historyId=${spec?._id}&department=${spec?.nameOfDepartment}&date=${spec?.date}&nameOfInjuredPersoon=${spec?.nameOfInjuredPerson}&accident_type=${spec?.accidentType}&accident_detail=${spec?.accidentDetail}&reasonForAccident=${spec?.reasonForAccident}`
                      )
                    }
                  />
                ))}
            </td>
            <td>
              {/*user : supSign*/}
              <TableInput
                isView={false}
                name="supSign"
                type="text"
                value={spec.supSign}
                onChange={(e) => {
                  spec.supSign = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '250px' }}
              />
            </td>

            <td>
              <TableInput
                isView={false}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '250px' }}
              />
            </td>
            {!isView && (
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
