import {
  Box,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import {
  PersitedDatePicker,
  SearchBar,
  TableCustom,
} from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import {
  listConstant,
  apiConstant,
  navigationConstant,
} from './helpers/constant';
import React from 'react';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import useConfirm from '../../../components/common/useConfirm';
import FilterBy from '../../../components/common/FilterBy';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import ModalCustom from '../../../components/common/Modal';
import ModalTable from './components/ModalTable';
import { VscPass } from 'react-icons/vsc';
import { RxCrossCircled } from 'react-icons/rx';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'NAME', rowspan: 1, colspan: 1 },
    { text: 'LEVEL', rowspan: 1, colspan: 1 },
    { text: 'DEPARTMENT', rowspan: 1, colspan: 1 },
    { text: 'STATUS', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const TableList = () => {
  const { state } = useAuth();
  const { user } = state;
  const [tableData, setTableData] = useState<any[]>([{}]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState(0);
  const employee_id = useQuery('employee_id');
  const search = useQuery('search') || '';
  const test_level = useQuery('test_level');
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const [moduleId, setModuleId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE CUSTOMER',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/${apiConstant.get}/${employee_id}/${test_level}?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setTableData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const handleDelete = async (id: string) => {
    const ans = await confirmDelete();

    if (!ans) return;
    await axios
      .delete(`/api/${apiConstant.delete}/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, filter, isModal]);

  const handleNavigate = useCallback(
    (id: any, mastersheet_id: any, isView: boolean) => {
      navigate(
        `${navigationConstant.skillEvaluationTestSheetRouter}/${mastersheet_id}/${id}?isView=${isView}`
      );
    },
    [navigate]
  );

  const handleStatus = useCallback((percentage: number) => {
    const iconStyle = {
      fontSize: '2rem',
      color: percentage >= 60 ? '#4c9a50' : '#d64f4f', // Green for pass, red for fail
    };

    const textStyle = {
      marginLeft: '0.5rem',
      fontWeight: 'bold',
      color: iconStyle.color,
    };

    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ gap: 1 }}
      >
        {percentage >= 60 ? (
          <VscPass style={iconStyle} />
        ) : (
          <RxCrossCircled style={iconStyle} />
        )}
        <Typography variant="inherit" style={textStyle}>
          {percentage >= 60 ? 'Pass' : 'Fail'}
        </Typography>
      </Stack>
    );
  }, []);

  return (
    <Box>
      <ChildHeader text={`${listConstant.childHeader}`}>
        <AddButton
          onClick={() => setIsModal(true)}
          label={`${listConstant.addButtonLable}`}
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item?.sheet_name}</TableCell>
                  <TableCell align="center">{item?.level}</TableCell>
                  <TableCell align="center">
                    {item?.department_id?.department_name}
                  </TableCell>
                  <TableCell align="center">
                    {handleStatus(item?.percentage)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="create plan"
                        onClick={() =>
                          handleNavigate(item?._id, item?.mastersheet_id, true)
                        }
                      />
                      {/* <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          handleDelete(item?._id || '');
                        }}
                      /> */}
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <DialogDelete isSubmitContent={false} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setIsModal(false);
        }}
        title="PICK SHEET"
      >
        <ModalTable />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
