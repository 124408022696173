import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

import Checkbox from '@mui/material/Checkbox';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';

export const CustomTypography: any = styled(Typography)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  background: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '4px',
  padding: '4px 8px',
  display: 'inline-flex',
  letterSpacing: '0.0003333em',
  alignItems: 'center',
  fontWeight: 500,
  '&::first-letter': {
    textTransform: 'capitalize',
  },
}));

export const GreenCheckIcon = styled(Check)(({}) => ({
  color: '#2b8a3e',
  border: '1.5px solid #343a40',
  borderRadius: '2px',
}));

export const BorderCloseIcon = styled(Close)(({}) => ({
  color: '#c92a2a',
  border: '1.5px solid #343a40',
  borderRadius: '2px',
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    borderRadius: '2px',
    padding: 0,
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const StyledNA = styled('p')(({}) => ({
  color: '#fa5252',
  margin: 0,
  padding: '8px',
  fontWeight: '400',
  textAlign: 'center',
  fontSize: '16px',
}));
