import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
}

const THead = ({ moduleData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc', 'Rev No', 'Rev Date']}
        values={['F-MNT-11', '01', '24.02.2023']}
        heading="Machine History Card"
      />

      <tr>
        <th colSpan={6} align="left">
          Machine No. : {moduleData.machine_no}
        </th>
        <th colSpan={6} align="left">
          Machine Name : {moduleData.machine_name}
        </th>
      </tr>

      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.NO',
          'DATE',
          'TIME',
          'Breakdown Details/ Preventive Maintenance',
          'Action Taken',
          'Breakdown Complete Date',
          'Breakdown Complete Time',
          'Breakdown Hours',
          'Spare Parts Qty',

          'REMARKS',
        ].map((head) => (
          <th
            style={{ textTransform: 'uppercase' }}
            colSpan={head === 'REMARKS' ? 2 : 1}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr></tr>
    </thead>
  );
};

export default THead;
