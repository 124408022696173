import moment from 'moment';

export function formatDateinMMMYY(month: number, date: string) {
  const year = date && moment(date).format('YY');
  // Construct a date using the provided month and year
  const inputDate = moment(`${year}-${month}`, 'YY-MM');

  // Format the date as "MMM-YY"
  const formattedDate = inputDate.format('MMM-YY');

  return formattedDate;
}
