import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { AnyARecord } from 'dns';
interface IProps {
  moduleData: any;
  isView: boolean;
  vendorData: any;
  customerData: any;
  machineData: any;
  departmentData: any;
}

const TController = ({
  moduleData,
  isView,
  vendorData,
  customerData,
  machineData,
  departmentData,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView }} />
        <TBody
          {...{
            moduleData,
            isView,
            vendorData,
            customerData,
            machineData,
            departmentData,
          }}
        />
        {/* <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
