export const apiConstant = {
  // list api url
  gettableListDataurl: 'operatorObservancePlanRoute/monthlylist',
  deleteListurl: 'operatorObservancePlanRoute',
  getListPlanByid: 'operatorObservancePlanRoute',
  createListPlanByid: 'operatorObservancePlanRoute',
  // -----plan--
  getAllPlanById: 'operatorObservancePlanRoute/allplansbyid',
  upadateCreateAllPlan:
    'operatorObservancePlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'operatorObservancePlanRoute/maintenancePlanDeleteBulkById',
  sync: 'operatorObservancePlanRoute/handleSync',
  // ----status---
  getAllStatus: 'operatorObservancePlanRoute/getAllSatusByYear',
  upadateListStatus: 'operatorObservancePlanRoute/handleStatusUpdate',

  //
  updatePlanByid: 'operatorObservancePlanRoute',
  checklistUpload: 'operatorObservancePlanRoute/handleUpload',
  checklistDeleteImage: 'operatorObservancePlanRoute/handleDeleteImage',
  checklistData: 'operatorObservancePlanRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'Operator Observance',
};
