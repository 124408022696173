import { Checkbox } from '@mui/material';
import InteractiveIcon from '../../../../components/common/InteractiveIcon';
import useConfirm from '../../../../components/common/useConfirm';
import React from 'react';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import {
  StyledCheckbox,
  BorderCloseIcon,
  GreenCheckIcon,
  StyledNA,
} from '../helpers/StyledElements.styled';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  updateModuleData: any;
  headerTopicData: any;
}

const TBodyRight = ({
  item,
  isView,
  index,
  updateModuleData,
  headerTopicData,
}: IProps) => {
  const [DialogisCheck, setDialogisCheck] = useConfirm(
    'Chnage Plan',
    'Are you sure you want to Change Plan?'
  );

  const handleCheckBoxChange = (
    fieldName: string,
    value: boolean | null,
    list_idx: number
  ) => {
    updateModuleData(
      'row-list',
      index,
      fieldName,
      { [fieldName]: value },
      list_idx
    );
  };

  return (
    <>
      {[
        ...(item?.list?.length
          ? item.list
          : Array(headerTopicData.length).fill(null)),
      ].map((listItem: any, listItemIdx: number) => (
        <td
          key={listItem?._id || listItemIdx + 'row-item-checkbox'}
          style={{
            padding: '0px',
            margin: '0px',
          }}
        >
          {listItem != null &&
            (typeof listItem?.is_training_required === 'boolean' ? (
              <StyledCheckbox
                checked={listItem?.is_training_required}
                onChange={(e) => {
                  handleCheckBoxChange(
                    'is_training_required',
                    e.target.checked,
                    listItemIdx
                  );
                }}
                icon={<BorderCloseIcon />}
                checkedIcon={<GreenCheckIcon />}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) : (
              <StyledNA>N/A</StyledNA>
            ))}
        </td>
      ))}
      <DialogisCheck isSubmitContent={false} />
    </>
  );
};

export default React.memo(TBodyRight);
