import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdFeaturedPlayList, MdSupervisorAccount } from 'react-icons/md';
import { MdSummarize } from 'react-icons/md';
import { AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { SiCloudfoundry } from 'react-icons/si';

const tiles = [
  {
    sNo: 23,
    link: '/common/changing_management_listOfProcedures/?documentType=changing_management&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/changing_management_listOfProcedures/?documentType=wi_4m_clause3&heading=LIST OF WI 4M',
    isLink: true,
    icon: <SiCloudfoundry size="5rem" color="#003566" />,
    p: 'WI 4M',
    span: '',
    isIcon: true,
  },
  {
    sNo: 19,
    link: '/common/4mSummaryList',
    isLink: true,
    icon: <MdSummarize size="5rem" color="#003566" />,
    p: '4M',
    span: 'SUMMARY',
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=masterProcedureClause3&heading=MASTER PROCEDURE',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'MASTER',
  //   span: 'PROCEDURE',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/4mPdc',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: '4M',
  //   span: 'PDC',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/common/4mPressShop',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#a12222" />,
  //   p: '4M PRESS',
  //   span: 'SHOP',
  //   isIcon: true,
  // },
  // {
  //   sNo: 4,
  //   link: '/common/4mRotor',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: '4M',
  //   span: 'ROTOR',
  //   isIcon: true,
  // },
  // {
  //   sNo: 5,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   target: '_blank',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdSupervisorAccount size="5rem" color="#a12222" />,
  //   p: '4M',
  //   span: 'MANAGEMENT',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=wiUploadClause3&heading=WI UPLOAD',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'WI',
  //   span: 'UPLOAD',
  //   isIcon: true,
  // },
  // {
  //   sNo: 6,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdFeaturedPlayList size="5rem" color="#135089" />,
  //   p: '4M MONITERNING',
  //   span: 'REPORTS',
  //   isIcon: true,
  // },
];

export default tiles;
