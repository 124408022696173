import React, { useState, useEffect } from 'react';

import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import useConfirm from '../../../components/common/useConfirm';
import { useQuery } from '../../../hooks/UseQuery';

const View = () => {
  const [moduleData, setModuleData] = useState<IData>(
    lodash.cloneDeep(initialState)
  );

  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id } = useParams();

  const isView = useQuery('isView') === 'true' ? true : false;

  const departmentId = useQuery('departmentId');

  console.log('departmentId', departmentId);
  console.log('selectedMonth', selectedMonth);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  useEffect(() => {
    if (selectedMonth && departmentId) {
      axios
        .get(
          `/api/dailyAccidentRoute/${selectedMonth}?departmentId=${departmentId}`
        )
        .then((res) => {
          console.log('res.data', res.data);
          setModuleData(res?.data);
        })
        .catch((err) => {
          console.log(err);
          setModuleData(
            lodash.cloneDeep({
              ...initialState,
              department_id: departmentId,
            })
          );
        });
    }
  }, [selectedMonth, departmentId]); // Use the selectedMonth state

  console.log('modeulDate', moduleData);

  const handleSumbit = async (isSubmitted: boolean) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;

    if (moduleData?._id) {
      await axios
        .put(`/api/dailyAccidentRoute/${moduleData?._id}`, moduleData)
        .then((res) => {
          if (res?.data?.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (!selectedMonth) {
        return alert('Please pick a month and year');
      }
      moduleData.annual_month = selectedMonth;
      await axios
        .post('/api/dailyAccidentRoute', moduleData)
        .then((res) => {
          if (res?.data?.status === 'success') {
            alert('Created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Box>
        <ChildHeader text="DAILY ACCIDENT MONITORING CHECK SHEET">
          {!isView && (
            <>
              {state.user?.userType === state.IS.Supervisor && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => handleSumbit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => handleSumbit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          setRender,
          setSelectedMonth,
          selectedMonth,
        }}
      />

      <DialogSubmit />
    </>
  );
};

export default View;
