import React, { useState } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import axios from "axios";
import useDebounce from "../../../../hooks/useDebounce";
import { StatePagination } from "../../../../components/common";
import formatDate from "../../../../components/common/formatDate";
import { CustomIconButton } from "../../../../components/common/button/CustomIconButton";
import Add from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import StateCustomTable from "../../../../components/common/StateCustomTable";
import TableCustom from "../../../../components/common/customTable/TableCustom";

interface Customer {
  customer_name: string;
}
interface IPart {
  _id?: string;
  partNumber: string | null;
  isSubmitted: "draft" | "publish";
  isAdminRequestNo: number | null;
  partName: string | null;
  customer_id: string | null | Customer;
  key_contact: string | null;
  core_team: string | null;
  supplier: string | null;
  supplier_code: string | null;
  notes: string | null;
  date_orig: moment.Moment;
  createdAt?: Date;
  supplier_approval_date: moment.Moment;
  Other_approval_date: moment.Moment;
  type: any;
}
const tableHeaderObj = [
  [
    { text: "#", rowspan: 1, colspan: 1 },
    { text: "Item Number", rowspan: 1, colspan: 1 },
    { text: "Item Name/Description", rowspan: 1, colspan: 1 },
    { text: "Type", rowspan: 1, colspan: 1 },
    { text: "Created At", rowspan: 1, colspan: 1 },
    { text: "Action", rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: "part_number", name: "A-Z" }];

const PartTable = (props: any) => {
  const { moduleData } = props;
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<IPart[]>([]);
  const [perPage, setPerPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<string>("part_number");
  const [render, setRender] = useState(false);
  const fetchPartData = () => {
    axios
      .get(
        `/api/riDepartment/riPart/?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`
      )
      .then((res) => {
        setPartData(res?.data?.data.results);
        setTotalPages(res?.data?.data?.totalPages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);

  return (
    <StateCustomTable
      filterOptions={filterOptions}
      {...{ search, setSearch, filter, setFilter }}
      label="Search Part"
    >
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {partData &&
            partData.map((item: IPart, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.partNumber}</TableCell>
                  <TableCell align="center">{item.partName}</TableCell>
                  <TableCell align="center">{item.type}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || "")}
                  </TableCell>
                  <TableCell align="center">
                    {moduleData.part_id === item._id ? (
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={"remove"}
                        onClick={() => {
                          moduleData.part_id = null;
                          moduleData.partName = null;
                          moduleData.partNumber = null;
                          moduleData.type = null;
                          setRender((prev) => !prev);
                        }}
                      >
                        <RemoveIcon style={{ color: "#c92a2a" }} />
                      </CustomIconButton>
                    ) : (
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={"add"}
                        onClick={() => {
                          moduleData.part_id = item?._id;
                          moduleData.partName = item?.partName;
                          moduleData.partNumber = item?.partNumber;
                          moduleData.type = item?.type;
                          setRender((prev) => !prev);
                        }}
                      >
                        <Add style={{ color: "#343a40" }} />
                      </CustomIconButton>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
    </StateCustomTable>
  );
};

export default PartTable;