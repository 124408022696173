import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.list.map((spec: any, index: number) => (
        <tr>
          <td>{index + 1}</td>
          <td>{spec.checkPoint}</td>
          <td>{spec.standard}</td>
          <td>{spec.action}</td>
          <td style={{ minWidth: '100px' }}>
            {isView ? (
              spec.status
            ) : (
              <CustomSelect
                name="status"
                value={spec.status || ''}
                onChange={(e: any) => {
                  spec.status = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem value="A">A</MenuItem>
                <MenuItem value="NA">NA</MenuItem>=
              </CustomSelect>
            )}
          </td>
          <td>{spec.method}</td>
          <td>
            <TableInput
              isView={isView}
              name="before"
              type="text"
              value={spec.before}
              onChange={(e: any) => {
                spec.before = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="after"
              type="text"
              value={spec.after}
              onChange={(e: any) => {
                spec.after = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
