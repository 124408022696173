import { Stack, TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { TableCustom } from '../../../../components/common';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { SearchBar } from '../../../../components/common/SearchBar';
import { Loader } from '../../../../components/common/Loader';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import ViewFile from './ViewFile';
import ModalCustom from '../../../../components/common/Modal';
interface IData {
  _id: string;
  part_id?: any;
  customer?: string;
  date?: Date;
  description?: string;
  createdAt?: Date;
  file2?: string;
  file1?: string;
}
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'PART NUMBER', rowspan: 1, colspan: 1 },
    { text: 'CUSTOMER NAME', rowspan: 1, colspan: 1 },
    { text: 'DATE', rowspan: 1, colspan: 1 },
    { text: 'DESCRIPTION', rowspan: 1, colspan: 1 },
    {
      text: 'OK Proof',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'NG Proof',
      colspan: 1,
      rowspan: 1,
    },

    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];
interface IProps {
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  isOpenPopup: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}
const TableList = ({ setId, isOpenPopup, setIsOpenPopup }: IProps) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const idRef = useRef('');
  const fileTypeRef = useRef('');
  const [isFileOpen, setIsFileOpen] = useState(false);

  const { state } = useAuth();
  const search = useQuery('search');
  useEffect(() => {
    axios
      .get(`/api/qualityAlertRoute?search=${search}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, isOpenPopup, search]);
  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this item?',
    );
    if (isDeleted) {
      axios
        .delete(`/api/qualityAlertRoute/${id}`)
        .then(() => {
          alert('item deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  const uploadFile = async (e: any, id: string, fileType: string) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);

    axios
      .patch(`/api/qualityAlertRoute/uploadFile/${fileType}/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('item upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <Box>
      <Loader loader={isLoading} />
      <Box sx={{ margin: 2 }}>
        <SearchBar label="Search Part Number" />
      </Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        {data.map((item: IData, index: number) => (
          <TableRow key={item._id}>
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">{item.part_id?.part_number}</TableCell>
            <TableCell align="center">
              {item.part_id && item.part_id?.customer_id?.customer_name}
            </TableCell>
            <TableCell align="center">
              {item.date && formatDate(item.date)}
            </TableCell>
            <TableCell align="center">{item.description}</TableCell>
            <TableCell align="center">
              <Stack
                direction={'row'}
                alignItems="center"
                justifyContent="center"
              >
                <CustomUploadButton
                  accept="image/png,image/jpeg,image/jpg,application/pdf"
                  id={'file1' + item._id}
                  multiple={true}
                  onChange={(e) => {
                    uploadFile(e, item._id, 'file1');
                  }}
                />
                {item.file1 && item.file1.length > 0 && (
                  <>
                    <ViewIconButton
                      tooltipTitle="view"
                      sx={{ marginLeft: '1.5rem' }}
                      onClick={() => {
                        idRef.current = item._id;
                        fileTypeRef.current = 'file1';
                        setIsFileOpen(true);
                      }}
                    />
                  </>
                )}
              </Stack>
            </TableCell>
            <TableCell align="center">
              <Stack
                direction={'row'}
                alignItems="center"
                justifyContent="center"
              >
                <CustomUploadButton
                  id={'file2' + item._id}
                  accept="image/png,image/jpeg,image/jpg,application/pdf"
                  multiple={true}
                  onChange={(e) => {
                    uploadFile(e, item._id, 'file2');
                  }}
                />
                {item.file2 && item.file2.length > 0 && (
                  <>
                    <ViewIconButton
                      tooltipTitle="view"
                      sx={{ marginLeft: '1.5rem' }}
                      onClick={() => {
                        idRef.current = item._id;
                        fileTypeRef.current = 'file2';
                        setIsFileOpen(true);
                      }}
                    />
                  </>
                )}
              </Stack>
            </TableCell>

            <TableCell align="center">
              <>
                <EditIconButton
                  tooltipTitle="EDIT"
                  onClick={() => {
                    setId(item?._id! || null);
                    setIsOpenPopup(true);
                  }}
                />
                <DeleteIconButton
                  tooltipTitle="DELETE"
                  onClick={() => {
                    deleteDocument(item._id || '');
                  }}
                />
              </>
            </TableCell>
          </TableRow>
        ))}
      </TableCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpen}
        closeModal={() => {
          setIsFileOpen(false);
        }}
      >
        <ViewFile id={idRef.current} fileType={fileTypeRef.current} />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
