import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleChecklist: any;
  checklistType: any;
}

const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];

const TController = ({
  moduleData,
  isView,
  handleChecklist,
  checklistType,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView, handleChecklist, checklistType }} />
        <TBody {...{ moduleData, isView, handleChecklist, checklistType }} />
        <TFoot {...{ moduleData, isView, handleChecklist, checklistType }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
