import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import AutocompleteMuiCustomRevised from '../../../../components/common/tableinputs/AutocompleteMuiCustomRevised';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { convToTime } from '../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';
import { StyledNotesField } from '../../../../components/common/FormControlMui';
import DescriptionTextfield from '../../../../components/common/DescriptionNotes';

interface IProps {
  moduleData: any;
  machineData?: any;
  updateModuleData?: any;
}

const THead = ({ machineData, moduleData, updateModuleData }: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const [render, setRender] = useState<any>('');

  const handleAutocompleteChange = (newValue: any) => {
    updateModuleData('key-pair', null, 'machine_name', {
      machine_name: newValue?.machine_name,
    });
    updateModuleData('key-pair', null, 'machine_no', {
      machine_no: newValue?.machine_no,
    });
    updateModuleData('key-pair', null, 'machine_id', { machine_id: newValue });
    // updateModuleData('key-pair', null, 'machine_id', newValue);
  };
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['F-HRD-18B', '01', '02.01.2024']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th>Line Name : </th>
        <th colSpan={2}></th>
        <th>Component Name :</th>
        <th colSpan={3}></th>
      </tr>
      <tr>
        <th>M/C Name :</th>
        <th colSpan={2}></th>
        <th>Operation Name :</th>
        <th colSpan={3}></th>
      </tr>
      <tr>
        <th>Operator Name :</th>
        <th colSpan={2}></th>
        <th>Month :</th>
        <th colSpan={3}></th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
