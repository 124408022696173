import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import TBody2 from './TBody2';
import TBody3 from './TBody3';
import TBody4 from './TBody4';
import TBody5 from './TBody5';
import TBody6 from './TBody6';
import TBody7 from './TBody7';
import TBody8 from './TBody8';
import TBody9 from './TBody9';
import TBody10 from './TBody10';
import { useState } from 'react';
interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
}

const TController = ({ moduleData, isView, customer }: IProps) => {
  const [controllerRender, SetControllerRender] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView, customer, SetControllerRender }} />
        <TBody {...{ moduleData, isView, SetControllerRender }} />
        <TBody2 {...{ moduleData, isView, SetControllerRender }} />
        <TBody3 {...{ moduleData, isView, SetControllerRender }} />
        <TBody4 {...{ moduleData, isView, SetControllerRender }} />
        <TBody5 {...{ moduleData, isView, SetControllerRender }} />
        <TBody6 {...{ moduleData, isView, SetControllerRender }} />
        <TBody7 {...{ moduleData, isView, SetControllerRender }} />
        <TBody8 {...{ moduleData, isView, SetControllerRender }} />
        <TBody9 {...{ moduleData, isView, SetControllerRender }} />
        <TBody10 {...{ moduleData, isView }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
