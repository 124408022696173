import L1 from './level1.png';
import L2 from './level2.png';
import L3 from './level3.png';
import L4 from './level4.png';

export const LevelSymbolImg: any = {
  L1,
  L2,
  L3,
  L4,
  '': '',
};
