import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';
import { TableCustom } from '../../../../components/common';
import { TableBody } from '@mui/material';
import {
  CircleIndicator,
  StyledTableCell,
  StyledTableRow,
} from '../../../clause5/helpers/module.styled';
import { useEffect, useState } from 'react';
const style1 = {
  backgroundColor: '#4F81BD',
  // backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
  border: '1px solid #343a40',
};

export const tableHeaderObj = [
  [
    { text: 'Department', style: { ...style1, minWidth: '150px' } },
    { text: 'Action', style: { ...style1 } },
  ],
];
const Reports = ({
  annual_month,
  setDepartmentAutoComplete,
  setIsOpenPopup,
}: any) => {
  const [data, setData] = useState([]);
  const getAllReportsBeingMade = () => {
    axios
      .get(`/api/ppeMonitoringRoute/reportsBeingMade/${annual_month}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllReportsBeingMade();
  }, []);

  return (
    <div
      style={{
        width: '500px',
      }}
    >
      <TableCustom tableHeaderObj={tableHeaderObj} aria-label="contacts">
        <TableBody>
          {data?.map((item: any, index: number) => (
            <StyledTableRow
              index={index}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setDepartmentAutoComplete(item);
                setIsOpenPopup(false);
              }}
            >
              <StyledTableCell align="center">
                {item?.department_name}
              </StyledTableCell>
              <StyledTableCell align="center">
                <CircleIndicator width="15px" height="15px"></CircleIndicator>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </TableCustom>
    </div>
  );
};

export default Reports;
