export const handleRej = (moduleData: any, key: string, index: number) => {
  if (
    moduleData?.[key]?.total_qty[index] === undefined ||
    moduleData?.[key]?.total_qty[index] === null ||
    moduleData?.[key]?.rejection_qty[index] === undefined ||
    moduleData?.[key]?.rejection_qty[index] === null
  ) {
    return;
  }
  const rejPercentage =
    ((moduleData?.[key]?.rejection_qty[index] || 0) /
      (moduleData?.[key]?.total_qty[index] || 1)) *
    100;

  moduleData[key].rejection_percentage[index] = rejPercentage.toFixed(2);
};
