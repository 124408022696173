import { Box } from '@mui/material';
import TBody from './TBody';
// import TFoot from './TFoot';
import THead from './THead';
import { RenderTable } from '../../../../../components/ui';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const allmonth = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const TController = ({ moduleData, isView }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView }} />
        <TBody {...{ moduleData, isView, allmonth }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
