import { SetStateAction, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  machineData: any;
  autoCompleteMachineValue: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  machineData,
  autoCompleteMachineValue,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <>
              <tr key={index + 'module data'}>
                <td rowSpan={6}>{index + 1}</td>
                <td rowSpan={6} style={{ minWidth: '150px' }}>
                  {isView ? (
                    formatDate(process.date)
                  ) : (
                    <DatePickerMui
                      label=""
                      value={moment(process.date || null)}
                      onChange={(date) => {
                        process.date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td rowSpan={6} style={{ minWidth: '200px' }}>
                  {isView
                    ? process?.mc_no?.machine_no
                    : process.date && (
                        <AutocompleteMuiCustom
                          id="Machine"
                          label=""
                          option_name="machine_no"
                          arrayofObj={machineData}
                          value={autoCompleteMachineValue[index]}
                          onChange={(e, value) => {
                            process.mc_no = value?._id;
                            autoCompleteMachineValue[index] = value;
                            setRender((prev) => !prev);
                          }}
                        />
                      )}
                </td>
                {/* {process.partNumber} */}
                <td rowSpan={6} style={{ width: '280px', minWidth: '280px' }}>
                  {isView ? (
                    process?.part_id &&
                    process?.part_id?.part_name +
                      ' - ' +
                      process?.part_id?.part_number
                  ) : (
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      alignItems={'center'}
                    >
                      <Grid item xs={10}>
                        <p
                          style={{
                            fontSize: '1.2rem',
                          }}
                        >
                          {typeof process?.part_id === 'object' &&
                          process?.part_id !== null
                            ? process?.part_id?.part_name +
                              ' - ' +
                              process?.part_id?.part_number
                            : (process?.partName || '') +
                              ' - ' +
                              (process?.partNumber || '')}
                          {!process?.part_id && 'Pick Part No.'}
                        </p>
                      </Grid>
                      {process.part_id ? (
                        <Grid item xs={2}>
                          <RemoveIconButton
                            tooltipTitle="remove part"
                            onClick={() => {
                              process.part_id = null;
                              process.partName = null;
                              process.partNumber = null;
                              setRender((prev) => !prev);
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={2}>
                          <AddIconButton
                            tooltipTitle="add part"
                            onClick={() => {
                              setIsOpenPopup(true);
                              setPartIdx(index);
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </td>
                <td rowSpan={6} style={{ minWidth: '80px' }}>
                  <TableInput
                    isView={isView}
                    name="total_production_qty"
                    type="number"
                    value={process.total_production_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      process.rejection_percentage = +(
                        (+process.rejection_qty * 100) /
                        +process.total_production_qty
                      ).toFixed(2);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td rowSpan={6} style={{ minWidth: '80px' }}>
                  <TableInput
                    isView={isView}
                    name="rejection_qty"
                    type="number"
                    value={process.rejection_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      process.rejection_percentage = +(
                        (+process.rejection_qty * 100) /
                        +process.total_production_qty
                      ).toFixed(2);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td rowSpan={6} style={{ minWidth: '150px' }}>
                  {isView ? (
                    formatDate(process.inspection_date)
                  ) : (
                    <DatePickerMui
                      label=""
                      value={moment(process.inspection_date)}
                      onChange={(date) => {
                        process.inspection_date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td style={{ minWidth: '80px' }} rowSpan={6}>
                  {process.rejection_percentage}
                  {/* <TableInput
                    isView={isView}
                    name="rejection_percentage"
                    type="number"
                    value={process.rejection_percentage}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  /> */}
                </td>
                <th style={{ minWidth: '120px' }} align="left">
                  NON FILLING
                </th>
                <td>
                  <TableInput
                    isView={isView}
                    name="non_filling_qty"
                    type="number"
                    value={process.non_filling_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '80px' }} rowSpan={6}>
                  <TableInput
                    isView={isView}
                    name="remarks"
                    type="text"
                    value={process.remarks}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }} rowSpan={6}>
                  {isView ? (
                    ''
                  ) : (
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => handelDeleteRows(index)}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th align="left">ANGLE</th>
                <td>
                  <TableInput
                    isView={isView}
                    name="angle_qty"
                    type="number"
                    value={process.angle_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th align="left">CUTTING</th>
                <td>
                  <TableInput
                    isView={isView}
                    name="cutting_qty"
                    type="number"
                    value={process.cutting_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th align="left">I.D. DAMAGE</th>
                <td>
                  <TableInput
                    isView={isView}
                    name="i_d_damage_qty"
                    type="number"
                    value={process.i_d_damage_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th align="left">CATCHING</th>
                <td>
                  <TableInput
                    isView={isView}
                    name="catching_qty"
                    type="number"
                    value={process.catching_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th align="left">O.D. DAMAGE</th>
                <td>
                  <TableInput
                    isView={isView}
                    name="o_d_damage_qty"
                    type="number"
                    value={process.o_d_damage_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
