import { useEffect, useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../../components/common/useConfirm';
import { c_list, d_list, m_list, s_list } from '../../helpers/initialState';
import lodash from 'lodash';
import CustomerList from './CustomerList';
import DepartmentList from './DepartmentList';
import MachineList from './MachineList';
import SupplierList from './SupplierList';
import NormalList from './NormalList';

interface IProps {
  moduleData: any;
  isView: boolean;
  vendorData: any;
  customerData: any;
  machineData: any;
  departmentData: any;
}
const month = [
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
  'jan',
  'feb',
  'mar',
];
const TBody = ({
  moduleData,
  isView,
  vendorData,
  customerData,
  machineData,
  departmentData,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );

  const handelDeleteRows = async (
    listName: any,
    processIndex: number,
    subProcessIndex: number
  ) => {
    const ans = window.confirm('Are you sure you want to delete this row');
    if (!ans) return;
    if (listName === 'c_list')
      moduleData?.customer_list[processIndex].splice(subProcessIndex, 1);
    else if (listName === 'd_list')
      moduleData?.department_list[processIndex].splice(subProcessIndex, 1);
    else if (listName === 'm_list')
      moduleData?.machine_list[processIndex].splice(subProcessIndex, 1);
    else if (listName === 's_list')
      moduleData?.supplier_list[processIndex].splice(subProcessIndex, 1);
    setRender((prev) => !prev);
  };

  return (
    <>
      <tbody>
        <CustomerList
          moduleData={moduleData}
          setRender={setRender}
          confirmAddRow={confirmAddRow}
          customerData={customerData}
          isView={isView}
          handelDeleteRows={handelDeleteRows}
        />
        <DepartmentList
          moduleData={moduleData}
          setRender={setRender}
          confirmAddRow={confirmAddRow}
          departmentData={departmentData}
          isView={isView}
          handelDeleteRows={handelDeleteRows}
        />
        <SupplierList
          moduleData={moduleData}
          setRender={setRender}
          confirmAddRow={confirmAddRow}
          vendorData={vendorData}
          isView={isView}
          handelDeleteRows={handelDeleteRows}
        />
        <MachineList
          moduleData={moduleData}
          setRender={setRender}
          confirmAddRow={confirmAddRow}
          machineData={machineData}
          isView={isView}
          handelDeleteRows={handelDeleteRows}
        />
        <NormalList
          moduleData={moduleData}
          setRender={setRender}
          isView={isView}
        />
      </tbody>
      <DialogAddRow />
    </>
  );
};

export default TBody;
