import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
}
const theadData = [
  'S.N.',
  'Part Name',
  'Model',
  'Customer Name',
  'S. No.',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  'Total parts',
  'Total Ng qty',
  'Total Ok qty',
  'Remarks',
  'Delete',
];

const THead = ({ moduleData, updateModuleData }: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const inspectorName = useQuery('inspectorName');
  const [render, setRender] = useState<any>('');

  const handleAutocompleteChange = (newValue: any) => {
    updateModuleData('key-pair', null, 'machine_name', {
      machine_name: newValue?.machine_name,
    });
    updateModuleData('key-pair', null, 'machine_no', {
      machine_no: newValue?.machine_no,
    });
    updateModuleData('key-pair', null, 'machine_id', { machine_id: newValue });
    // updateModuleData('key-pair', null, 'machine_id', newValue);
  };
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={28}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['F-HR-17', '03', '01.11.2019']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th align="left" colSpan={10}>
          Visual inspector Name :{inspectorName}
          {/* <TableInput
            isView={isView}
            name="name"
            type="text"
            value={moduleData.name}
            onChange={(e) => {
              moduleData.name = e.target.value;
              setRender((prev: any) => !prev);
            }}
          /> */}
        </th>
        <th align="left" colSpan={18}>
          Date :
          {isView ? (
            formatDate(moduleData.moduleData_Date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_Date)}
              onChange={(date) => {
                moduleData.moduleData_Date = date;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} rowSpan={index <= 3 || index >= 23 ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        <th>Defect details</th>
        {moduleData?.defect_details?.map(
          (_: any, defect_details_index: any) => {
            return (
              <th>
                {' '}
                <TableInput
                  isView={isView}
                  name={`defect_details[${defect_details_index}]`}
                  type="text"
                  value={moduleData?.defect_details?.[defect_details_index]}
                  onChange={(e) => {
                    moduleData.defect_details[defect_details_index] =
                      e.target.value;
                    setRender((prev: any) => !prev);
                  }}
                />
              </th>
            );
          }
        )}
      </tr>
      {/* <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} /> */}
    </thead>
  );
};

export default THead;
