import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AuthProvider from "./services/auth/AuthProvider";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
