import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ isView, moduleData }: IProps) => {
  const [render, setRender] = useState(false);
  const documentType = useQuery('documentType');
  return (
    <tfoot>
      <tr>
        <th style={{ minWidth: '150px' }}>TOTAL PARTS TO BE INSPECT</th>
        <th>OK QTY</th>
        <th>REJECTED QTY</th>
        <th>REWORKED QTY</th>
        <th colSpan={documentType === 'retro' ? 20 : 10}>
          REJECTION DETAILS(DEFECT NAME/QTY)
        </th>
        <th colSpan={2}>LOT NO.</th>
        <th>4M CHANGE STICKER ATTACHED(Y/N)</th>
        <th>NEW REGULAR OK STICKER APPLIED(Y/N)</th>
      </tr>
      <>
        {(documentType === 'retro' || documentType === 'suspected') &&
          moduleData.rejectionDetails.map(
            (rejectionDetail: any, index: number) => (
              <tr>
                {index === 0 && (
                  <>
                    <td rowSpan={2}>
                      <TableInput
                        isView={isView}
                        name="total_part_to_inspect"
                        type="text"
                        value={moduleData?.total_part_to_inspect}
                        onChange={(e) => {
                          moduleData.total_part_to_inspect = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td rowSpan={2}>
                      <TableInput
                        isView={isView}
                        name="ok_qty"
                        type="number"
                        value={moduleData?.ok_qty}
                        onChange={(e) => {
                          moduleData.ok_qty = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td rowSpan={2}>
                      <TableInput
                        isView={isView}
                        name="rejected_qty"
                        type="number"
                        value={moduleData?.rejected_qty}
                        onChange={(e) => {
                          moduleData.rejected_qty = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td rowSpan={2}>
                      <TableInput
                        isView={isView}
                        name="rework_qty"
                        type="number"
                        value={moduleData?.rework_qty}
                        onChange={(e) => {
                          moduleData.rework_qty = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                  </>
                )}
                {documentType === 'retro'
                  ? Array.from({ length: 10 }, (_, rejectionIndex) => (
                      <td
                        colSpan={2}
                        style={{ width: '150px', minWidth: '150px' }}
                      >
                        <TableInput
                          isView={isView}
                          name="rejectionDetails"
                          type="text"
                          value={
                            rejectionDetail.rejectionValues[rejectionIndex]
                          }
                          onChange={(e) => {
                            rejectionDetail.rejectionValues[rejectionIndex] =
                              e.target.value;
                            setRender((prev) => !prev);
                          }}
                        />
                      </td>
                    ))
                  : Array.from({ length: 5 }, (_, rejectionIndex) => (
                      <td
                        colSpan={2}
                        style={{ width: '150px', minWidth: '150px' }}
                      >
                        <TableInput
                          isView={isView}
                          name="rejectionDetails"
                          type="text"
                          value={
                            rejectionDetail.rejectionValues[rejectionIndex]
                          }
                          onChange={(e) => {
                            rejectionDetail.rejectionValues[rejectionIndex] =
                              e.target.value;
                            setRender((prev) => !prev);
                          }}
                        />
                      </td>
                    ))}
                <td colSpan={2}>
                  <TableInput
                    isView={isView}
                    name="lot_no"
                    type="text"
                    value={moduleData?.lot_no}
                    onChange={(e) => {
                      moduleData.lot_no = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td>
                  <TableInput
                    isView={isView}
                    name="change_sticker"
                    type="text"
                    value={moduleData?.change_sticker}
                    onChange={(e) => {
                      moduleData.change_sticker = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td>
                  <TableInput
                    isView={isView}
                    name="ok_sticker"
                    type="text"
                    value={moduleData?.ok_sticker}
                    onChange={(e) => {
                      moduleData.ok_sticker = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
            ),
          )}
        <tr>
          <th colSpan={9} align="left">
            Inspected By:
            <TableInput
              isView={isView}
              name="batch_no"
              type="text"
              value={moduleData?.inspected_by}
              onChange={(e) => {
                moduleData.inspected_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
          <th colSpan={documentType === 'retro' ? 9 + 10 : 9} align="left">
            Approved By:
            <TableInput
              isView={isView}
              name="approved_by"
              type="text"
              value={moduleData?.approved_by}
              onChange={(e) => {
                moduleData.approved_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
      </>
    </tfoot>
  );
};

export default TFoot;
