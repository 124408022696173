import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { IModule } from '../../interface';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No:', 'Rev No.:', ' Eff. Date:']}
        values={['F-PROD-22B', '03', '10.06.2020']}
        heading="Before Loading Die Checksheet  (PDC)"
      />
      <tr>
        <th colSpan={2} align="left">
          Mold Name :{' '}
          {moduleData?.moldName || moduleData?.mold_id?.mold_name || ''}{' '}
        </th>
        <th colSpan={2} align="left">
          Department :{' '}
          {moduleData?.departmentName ||
            moduleData?.mold_id?.department_id?.department_name ||
            ''}{' '}
        </th>
        <th colSpan={2} align="left">
          Frequency/No. of shots :{' '}
          {moduleData?.frequency || moduleData?.mold_id?.frequency || ''}
        </th>
        <th colSpan={1} style={{ textAlign: 'left' }}>
          Date :
          {isView ? (
            formatDate(moduleData.insp_standard_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.insp_standard_date)}
              onChange={(date) => {
                moduleData.insp_standard_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {[
          'S.No',
          'CHECK POINTS',
          'STANDARD',
          'ACTION IF NOT AS PER STANDARD',
          'A / NA',
          'METHOD',
          // 'Remark',
          'Action',
        ].map((heading) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
