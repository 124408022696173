import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleChange: any;
}

const TController = ({ moduleData, isView, handleChange }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView }} />
        <TBody {...{ moduleData, isView, handleChange }} />
        <TFoot
        // preparedBy={moduleData.report_prepared_by}
        // approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
