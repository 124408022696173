export const checkListList = {
  childHeader: 'Annual PM Plan Trolly STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'annualPmPlanTrollyStatusRoute/getAllSatusByYear',
  upadateListStatus: 'annualPmPlanTrollyStatusRoute/handleStatusUpdate',
  checklistUpload: 'annualPmPlanTrollyStatusRoute/handleUpload',
  checklistDeleteImage:
    'annualPmPlanTrollyStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const navigationConstant = {
  checklistRouter: '/common/annualPmPlanTrollyStatusCheckList',
};
