import moment from 'moment';
import { useState } from 'react';

import { TableCompHead } from '../../../../../../components/ui';
import formatDate from '../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../../../components/common';
interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
  SetControllerRender: any;
}

const heading = [
  'SR. NO.',
  'AUDIT PARAMETER',
  'SCORE',
  'OBSERVATION',
  'REMARKS',
];
const heading2 = [0, 1, 2];
const THead = ({
  moduleData,
  isView,
  customer,
  SetControllerRender,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['DOC NO.: ', 'REV. NO.:', 'REV DATE:']}
        values={['F-PUR-5B', '04', '10.10.2020']}
        heading="SUPPLIER'S AUDIT CHECK SHEET"
      />
      <tr>
        <th colSpan={3} align="left">
          TOTAL SCORE :{moduleData?.total_achieved_score}
        </th>
        <th colSpan={4} align="left">
          SUPPLIER NAME: {customer}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          MAX. SCORE :
          <TableInput
            isView={isView}
            name="total_max_score"
            type="number"
            value={moduleData.total_max_score}
            onChange={(e: any) => {
              moduleData.total_max_score = e.target.value;
              SetControllerRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={4} align="left">
          AUDITEE:
          <TableInput
            isView={isView}
            name="s1"
            type="text"
            value={moduleData.s1}
            onChange={(e: any) => {
              moduleData.s1 = e.target.value;
              SetControllerRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          RATING:
          <TableInput
            isView={isView}
            name="s2"
            type="text"
            value={moduleData.s2}
            onChange={(e: any) => {
              moduleData.s2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={4} align="left">
          AUDITOR:
          <TableInput
            isView={isView}
            name="s3"
            type="text"
            value={moduleData.s3}
            onChange={(e: any) => {
              moduleData.s3 = e.target.value;
              SetControllerRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          TARGET DATE OF NC'S CLOSED:
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1 || null) || ''}
              onChange={(date: any) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4} align="left">
          AUDIT DATE :{' '}
          {isView ? (
            formatDate(moduleData.d2)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d2 || null) || ''}
              onChange={(date: any) => {
                moduleData.d2 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {heading.map((header) => (
          <th
            key={header}
            colSpan={(header === 'SCORE' && 3) || 1}
            rowSpan={header === 'SCORE' ? 1 : 2}
          >
            {header}
          </th>
        ))}
      </tr>
      <tr>
        {heading2.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
