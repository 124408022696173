import { MenuItem } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import CustomSelect from '../../../../components/common/SelectField';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-09', '00', '01-04-2011']}
        heading="INCOMING INSPECTION REPORT"
      />

      <tr>
        <th colSpan={2} align="left">
          Item Name:&emsp;
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.ri_part_id?.part_name}{' '}
        </th>
        <th colSpan={1} align="left">
          Item Number:&emsp;
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.ri_part_id?.part_number}
        </th>
        <th colSpan={3} align="left">
          Qty:
          <TableInput
            name="qty"
            type="text"
            value={moduleData.qty}
            onChange={(e) => {
              moduleData.qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4} align="left">
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            Date:
            {isView ? (
              formatDate(moduleData.inspection_date)
            ) : (
              <DatePickerMui
                label=""
                value={moment(moduleData.inspection_date)}
                onChange={(date) => {
                  moduleData.date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </div>
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Supplier Name:&emsp;
          {moduleData.supplierName
            ? moduleData.supplierName
            : moduleData.ri_part_id?.vendor_id?.vender_name}
        </th>
        <th colSpan={3} align="left">
          Shift:
          <TableInput
            name="shift"
            type="text"
            value={moduleData.shift}
            onChange={(e) => {
              moduleData.shift = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4} align="left">
          Invoice/Challan No & Date:
          <TableInput
            name="invoice_and_challan"
            type="text"
            value={moduleData.invoice_and_challan}
            onChange={(e) => {
              moduleData.invoice_and_challan = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>

      <tr>
        {['S.NO', 'Parameter', 'specification'].map((head) => (
          <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
        <th colSpan={6} style={{ textTransform: 'uppercase' }}>
          Observations
        </th>
        <th style={{ textTransform: 'uppercase', width: '13%' }} rowSpan={2}>
          Remarks
        </th>
      </tr>
      <tr>
        {Array.from({ length: 5 }, (_, index) => (
          <th style={{ width: '90px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}
        <th style={{ textTransform: 'uppercase' }}>Status</th>
      </tr>
    </thead>
  );
};

export default THead;
