import React, { useState } from 'react';
import { allmonth } from '../../helpers/utils';
import { TableRowComponent } from './TableRowComponent';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  handleDelete: any;
  updateModuleData: any;
  customerData?: any;
  setPartIdx: any;
  setIsOpenPopup: any;
}
const TBody = ({
  handleDelete,
  moduleData,
  updateModuleData,
  customerData,
  setPartIdx,
  setIsOpenPopup,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            allmonth,
            updateModuleData,
            handleDelete,
            customerData,
            setPartIdx,
            setIsOpenPopup,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
