import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  return (
    <tfoot>
      <tr></tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
