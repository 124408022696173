export const apiConstant = {
  // list api url
  gettableListDataurl: 'internalAuditPlanRoute/monthlylist',
  deleteListurl: 'internalAuditPlanRoute',
  getListPlanByid: 'internalAuditPlanRoute',
  createListPlanByid: 'internalAuditPlanRoute',
  // -----plan--
  getAllPlanById: 'internalAuditPlanRoute/allplansbyid',
  upadateCreateAllPlan: 'internalAuditPlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'internalAuditPlanRoute/maintenancePlanDeleteBulkById',
  sync: 'internalAuditPlanRoute/handleSync',
  // ----status---
  getAllStatus: 'internalAuditPlanRoute/getAllSatusByYear',
  upadateListStatus: 'internalAuditPlanRoute/handleStatusUpdate',

  //
  updatePlanByid: 'internalAuditPlanRoute',
  checklistUpload: 'internalAuditPlanRoute/handleUpload',
  checklistDeleteImage: 'internalAuditPlanRoute/handleDeleteImage',
  checklistData: 'internalAuditPlanRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'Internal Audit',
};
