import lodash from 'lodash';
import moment from 'moment';

export const process = {
  desc: '',
  freq: '',
  shift_A_results: Array(31).fill(''),
  remarks: ''
};


export const processes = [
  {
    desc: 'मशीन पर साफ सफाई होनी चाहिए |',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'हर दो घंटे के बाद  डाई का तापमान और मेटल का तापमान चेक करना है व् उसे रिकॉर्ड शीट में भरना है |मेटल का तापमान 640 से 660 °c होना चाहिए व die  का तापमान 180 से 220 °C होना चाहिए | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'हर दो घंटे के बाद मेटल को डीगेसिंग करना है व् उसे रिकॉर्ड शीट में भरना है |',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'शिफ्ट की शुरुआत में डेली मशीन चेक शीट को भरना है | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'मशीन पर प्रोडक्शन के दौरान , प्रत्येक पार्ट को विसुअल चेक करना है | पार्ट में यदि कोई डिफेक्ट आता है तो पार्ट को लाल बिन में रखना है | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'मशीन पर प्रोडक्शन के दौरान , सभी ऑफ शॉट  पार्ट को  लाल बिन में रखना है | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'मशीन को मशीन का दरवाजा बंद करके ही चलाना  है | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'मशीन पर प्रेशर डाई कास्टिंग को चलाने के लिए , डाई प्री हीटिंग करने के लिए  , डी गैटिंग- ओवर फ्लो को तोड़ने के लिए , डिगेसिंग करने के लिए , फर्नेस को साफ करने के लिए सभी की कार्य निर्देश मशीन पर लगे होने चाहिए |',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'डाई फ्लश नहीं मारनी चाहिए | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'फर्नेस में मटेरियल ज्यादा कम नहीं होना चाहिए | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'मशीन पर कोई भी पार्ट बनाने के बाद फर्नेस में नहीं डालना है |',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'प्लंजर से पानी लीकेज नहीं  होना चाहिए | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'ऑटो ड्रेन वाल्व को हर दो घंटे में चेक करना है कि वाल्व काम कर  रहा की नहीं | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'मशीन पर डिस्प्ले बोर्ड लगा होना चाहिए व् साफ होना चाहिए | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'मशीन पर रौशनी के लिए बल्ब लगा होना चाहिए यदि नहीं है बल्ब लगवाये | ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'मशीन पर फर्स्ट पार्ट अप्रूवल (FPA) का पार्ट रखा होना चाहिए |',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'ऑपरेटर ने पी पी ई पहना  होना चाहिए |',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'स्प्रे हैड ट्रे से पानी डाइ के ऊपर नही गिरना चाहिए |',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'डाइ के सभी एयर वेंट खुले होने चाहिए ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'सभी हाउसिंग के सेंटर वाले ओवर फ्लो को खुला होना चाहिए | व डाइ कोट का स्प्रे डाइ  पर  सही से लगना चाहिए |',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'डाइ लोडिंग होने के बाद डाइ को गरम करना है जब तक डाइ  का तापमान 120 °C तक न आ जाए | व शुरुआत मे पानी का स्प्रे टाइम 0.5 सेकंड होना चाहिए ',
    freq: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'जाँच करने वाला',
    freq: '',
    shift_A_results: Array(31).fill(''),
    remarks: '',
    data_type:'footer'
  },

  {
    desc: 'निरीक्षण करने वाला:-',
    freq: '',
    shift_A_results: Array(31).fill(''),
    remarks: '',
    data_type:'footer'
  },
];


export const initialState = {
  report_prepared_by: '',
  report_approved_by: '',
  dailyBin_date: moment(),
  processes: lodash.cloneDeep(processes),
  machine_id: null
};