import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
// import { useEffect, useState } from 'react';
// import axios from 'axios';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
}

const TController = ({
  moduleData,
  departmentData,
  isView,
  setDepartmentAutoComplete,
  departmentAutoComplete,
}: any) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            departmentData,
            setDepartmentAutoComplete,
            departmentAutoComplete,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
          }}
        />
        <TFoot moduleData={moduleData} isView={isView} />
      </RenderTable>
    </Box>
  );
};

export default TController;
