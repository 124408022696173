import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { IModule } from '../../interface';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={3}
        labels={['Doc No:', 'REV. NO.:', 'REV. DATE:']}
        values={['F-QA-09A', '02', '09.10.2019']}
        heading="INCOMING INSPECTION STANDARD"
      />
      <tr>
        <th colSpan={1} align="left">
          ITEM NAME
        </th>
        <th colSpan={2} align="left">
          {moduleData?.ri_part_id?.part_name || moduleData.partName || ''}
        </th>
        <th colSpan={1} align="left">
          RESPONSIBILITY
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="responsibility"
            type="text"
            value={moduleData.responsibility}
            onChange={(e) => {
              moduleData.responsibility = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={1} align="left">
          INSPECTION QTY
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="qty"
            type="text"
            value={moduleData.qty}
            onChange={(e) => {
              moduleData.qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left"></th>
        <th colSpan={2} align="left"></th>
      </tr>
      <tr>
        <th colSpan={1} align="left">
          ITEM NUMBER
        </th>
        <th colSpan={2} align="left">
          {moduleData?.ri_part_id?.part_number || moduleData.partNumber || ''}
        </th>
        <th colSpan={1} align="left">
          Date:
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.moduleDate)
          ) : (
            <DatePickerMui
              value={moment(moduleData.moduleDate)}
              onChange={(date) => {
                moduleData.moduleDate = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr>
        {[
          'S.N.',
          'PARAMETER NAME',
          'SPECIFICATION',
          'CHECKING METHOD',
          'REMARKS',
          'Action',
        ].map((heading) => (
          <th key={heading} style={{ textTransform: 'uppercase' }}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
