import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { IModule } from '../../interface';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const TFoot = ({ isView, moduleData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={7}>
          मेंटेनेंस करने वाले का नाम/sign :-
          <TableInput
            name="prepared_by"
            type="text"
            value={moduleData.prepared_by}
            onChange={(e) => {
              moduleData.prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={7}>
          मेंटेनेंस चेक करने वाले का नाम/sign :-
          <TableInput
            name="approved_by"
            type="text"
            value={moduleData.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
