import { BiLogoProductHunt } from 'react-icons/bi';
import { BsCalendar2CheckFill } from 'react-icons/bs';
import { FcProcess } from 'react-icons/fc';
import { MdList } from 'react-icons/md';
const tiles = [
  {
    sNo: 1,
    link: '/common/masterListOfProcess',
    isLink: true,
    icon: <MdList size="5rem" color="#003566" />,
    p: 'MASTER LIST',
    span: 'OF PROCESS',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/processAuditTiles',
    isLink: true,
    icon: <FcProcess size="5rem" color="#003566" />,
    p: 'PROCESS',
    span: 'AUDIT',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/productAuditPlanTiles',
    isLink: true,
    icon: <BiLogoProductHunt size="5rem" color="#003566" />,
    p: 'PRODUCT',
    span: 'AUDIT',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/layoutPlanNewTiles',
    isLink: true,
    clauseNo: 4,
    icon: <BsCalendar2CheckFill size="5rem" color="#a12222" />,
    p: 'Layout Inspection',
    span: 'Plan',
    isIcon: true,
  },

  {
    sNo: 6,
    link: '/common/internalAuditTiles',
    isLink: true,
    clauseNo: 4,
    icon: <BsCalendar2CheckFill size="5rem" color="#a12222" />,
    p: 'Internal Audit',
    span: 'Plan',
    isIcon: true,
  },

  {
    sNo: 6,
    link: '/common/qualifiedAuditors',
    isLink: true,
    clauseNo: 4,
    icon: <FcProcess size="5rem" color="#a12222" />,
    p: 'List of Qualified',
    span: 'Auditors',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/internalAuditorSelection',
    isLink: true,
    clauseNo: 4,
    icon: <MdList size="5rem" color="#a12222" />,
    p: 'Internal Auditor Selection',
    span: 'Criteria',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/internalAuditNcSummaryTracker',
    isLink: true,
    clauseNo: 4,
    icon: <MdList size="5rem" color="#003566" />,
    p: 'Internal Audit NC',
    span: 'Summary Tracker',
    isIcon: true,
  },
];

export default tiles;
