import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
}

const THead = ({ moduleData, isView, machineData }: IProps) => {
  const machineLength = machineData.length;
  const departmentName = useQuery('departmentName');
  return (
    <thead>
      <TableCompHead
        colSpan={4 + machineLength}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-HRD-08A', '00', '01.04.2019']}
        heading="MAN MACHINE MATRIX"
      />
      <tr>
        <th colSpan={4 + machineLength} align="left">
          DEPTT NAME:{departmentName}
        </th>
      </tr>
      <tr>
        <th colSpan={4 + machineLength} align="left">
          DEPTT OWNER NAME:
        </th>
      </tr>
      <tr>
        <th rowSpan={2}>S.N.</th>
        <th rowSpan={2}>OPERATOR NAME</th>
        <th rowSpan={2}>SKILL LEVEL</th>
        {machineData.length > 0 && (
          <th colSpan={machineData.length}>MACHINE NO.</th>
        )}
        <th rowSpan={2}>REMARKS</th>
      </tr>
      <tr>
        {machineData.map((machine: any) => (
          <th>{machine.machine_no}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
