import lodash from 'lodash';

const process = {
  action: '',
  responsibility: '',
  target_date: null,
  status: '',
};

const revision_history = {
  rev_no: '',
  rev_date: null,
  rev_details: '',
  revided_by: '',
  approved_by: '',
};

const header = {
  time: null,
  shift: '',
  age: '',
  date_of_joining: null,
  emp_exp: '',
  type_of_emp: '',
  emp_name: '',
  body_parts: '',
  treatment: '',
  job: '',
  problem_why1: '',
  problem_why2: '',
  problem_why3: '',
  problem_why4: '',
  problem_why5: '',
  root_cause_incident: '',
};

const footer = {
  horizonatl_deploy: '',
  hira_updated: '',
  hira_date: null,
  sign_prod_incharge: '',
  sign_prod_incharge_date: null,
  sign_quality_incharge: '',
  sign_quality_incharge_date: null,
  sign_maint_incharge: '',
  sign_maint_incharge_date: null,
  sign_hr_incharge: '',
  sign_hr_incharge_date: null,
};

export const initialState = {
  dailyAccidentId: null,
  historyId: null,
  ...header,
  processes: [...lodash.clone(Array.from({ length: 4 }).map(() => process))],
  ...footer,
  revision_history: [
    ...lodash.clone(Array.from({ length: 1 }).map(() => revision_history)),
  ],
};
