import lodash from 'lodash';
export const initialState = {
  vender_name: '',
  vender_code: '',
  product_service: '',
  address: '',
  contact_person: '',
  contact_no: null,
  email_id: '',
  lead_time: '',
  inducted_on_date: null,
  qms_certification_iso_iatf: '',
  qms_certification_valid_upto_date: null,
  remarks: '',

  files: [],
};
