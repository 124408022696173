import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { createConstant } from '../../constant';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../../../components/common/formatDate';
import InputMuiCustom from '../../../../../../components/common/inputs/InputMuiCustom';
import { MenuItem, Select, SelectChangeEvent, makeStyles } from '@mui/material';
// import { MenuItem, Select, makeStyles } from '@material-ui/core';
import Styled from 'styled-components';
import CustomSelect from '../../../../../../components/common/SelectField';
import React from 'react';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleChecklist: any;
  checklistType: any;
}

const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];
// const dropdownStyles: any = makeStyles({
//   root: {
//     backgroundColor: '#fff',
//   },
//   select: {
//     '&:hover': {
//       backgroundColor: '#fff',
//     },
//     '&:focus': {
//       backgroundColor: '#fff',
//     },
//   },
//   underline: {
//     borderBottom: '0px solid red !important',
//     '&:hover': {
//       borderBottom: '0px solid rgba(0,0,0,0)',
//     },
//   },
// });
// const classes = dropdownStyles();
const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;
  th{
        font-size: 13px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
        b{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
        strong{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
        word-wrap: break-word;
    }
    tbody th {
    text-align: center;
    }

    .input {
      outline: none;
      width: 100px;
      border: none;
      height: 50px;
      text-align:center;
  }
    
`;
const TBody = ({
  moduleData,
  isView,
  handleChecklist,
  checklistType,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [data, setData] = useState<any>({});
  // const getAverage = (index: number) => {
  //   let sum = 0;
  //   for (const month in moduleData?.list[index]) {
  //     if (month !== 'item' && month != 'remarks' && month != '_id') {
  //       sum += +moduleData?.list[index][month];
  //     }
  //   }
  //   return (sum / 12).toFixed(2);
  // };

  // const getTargetAverage = () => {
  //   let sum = 0;
  //   for (const targetMonth in moduleData) {
  //     if (
  //       targetMonth !== 'title' &&
  //       targetMonth != 'date_range' &&
  //       targetMonth != '_id' &&
  //       targetMonth != 'list' &&
  //       targetMonth != 'createdAt' &&
  //       targetMonth != 'updatedAt'
  //     ) {
  //       sum += +moduleData[targetMonth];
  //     }
  //   }
  //   return (sum / 12).toFixed(2);
  // };
  // const getBreakDownTime = (monthName: string) => {
  //   moduleData.list[0][monthName] = breakDownTime[monthName];
  //   return breakDownTime[monthName];
  // };
  // const getMttr = (monthName: any) => {
  //   const breakDown_time = getBreakDownTime(monthName);
  //   const no_of_reapairs = moduleData?.list[1]?.[monthName];
  //   if (no_of_reapairs === 0) {
  //     moduleData.list[2][monthName] = 0;
  //     return 0;
  //   }
  //   moduleData.list[2][monthName] = breakDown_time / no_of_reapairs;
  //   return (breakDown_time / no_of_reapairs).toFixed(2);
  // };

  const menuItemStyles = {
    display: 'flex',
    width: '60px',
    alignItems: 'center',
    justifyContent: 'center',
  };
  // const CustomSelect = (
  //   name: string,
  //   value: any,
  //   index: any,
  //   pos:
  //     | string
  //     | number
  //     | boolean
  //     | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  //     | React.ReactFragment
  //     | null
  //     | undefined,
  //   neg:
  //     | string
  //     | number
  //     | boolean
  //     | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  //     | React.ReactFragment
  //     | null
  //     | undefined,
  //   Width: string
  // ) => {
  //   return (
  //     <Select
  //       // classes={{
  //       //   // root: classes.root,
  //       //   select: classes.select,
  //       // }}
  //       inputProps={{ IconComponent: () => null }}
  //       style={{
  //         fontSize: '0.8rem',
  //         width: Width,
  //         display: 'flex',

  //         alignItems: 'center',
  //         justifyContent: 'center',
  //       }}
  //       disableUnderline
  //       name={name}
  //       value={value}
  //       onChange={(e: any) => {
  //         handleChecklist(e, index);
  //         setRender((prev) => !prev);
  //       }}
  //     >
  //       <MenuItem value="">
  //         <em>None</em>
  //       </MenuItem>

  //       <MenuItem /* value={pos}*/>
  //         <span
  //           style={{
  //             fontSize: '1.3rem',
  //             color: '#40c057',
  //             ...menuItemStyles,
  //           }}
  //         >
  //           {pos}
  //         </span>
  //       </MenuItem>
  //       <MenuItem /*value={neg}*/>
  //         <span
  //           style={{
  //             fontSize: '1.3rem',
  //             color: '#f03e3e',
  //             ...menuItemStyles,
  //           }}
  //         >
  //           {neg}
  //         </span>
  //       </MenuItem>
  //     </Select>
  //   );
  // };
  return (
    // <tbody>
    //   {moduleData?.list?.map((item: any, index: number) => (
    //     <>
    //       <tr key={`${index}_table_row`}>
    //         <td>{index + 1}</td>
    //         <td>{item?.item}</td>
    //         {allmonth.map((monthName: string) => {
    //           return (
    //             <td>
    //               <TableInput
    //                 isView={index === 0 || index === 2 ? true : false}
    //                 style={{
    //                   height: '80px',
    //                   textAlign: 'center',
    //                   paddingLeft: '10px',
    //                 }}
    //                 name=""
    //                 type="number"
    //                 value={
    //                   (index == 0 && getBreakDownTime(monthName)) ||
    //                   (index === 2 && getMttr(monthName)) ||
    //                   item[monthName]
    //                 }
    //                 onChange={(e) => {
    //                   item[monthName] = e.target.value;
    //                   setRender((prev) => !prev);
    //                 }}
    //               />
    //             </td>
    //           );
    //         })}
    //         <td>{getAverage(index)}</td>
    //         <td>
    //           <InputMuiCustom
    //             placeholder={''}
    //             name=""
    //             type="text"
    //             value={item?.remarks}
    //             onChange={(e) => {
    //               item.remarks = e.target.value;
    //               setRender((prev) => !prev);
    //             }}
    //           />
    //         </td>
    //       </tr>
    //     </>
    //   ))}
    //   <tr>
    //     <td></td>
    //     <td>Target</td>
    //     {allmonth.map((montName: string) => {
    //       return (
    //         <td>
    //           <TableInput
    //             isView={isView}
    //             style={{
    //               height: '80px',
    //               textAlign: 'center',
    //               paddingLeft: '10px',
    //             }}
    //             name=""
    //             type="number"
    //             value={moduleData?.[`target_${montName}`]}
    //             onChange={(e) => {
    //               moduleData[`target_${montName}`] = e.target.value;
    //               setRender((prev) => !prev);
    //             }}
    //           />
    //         </td>
    //       );
    //     })}
    //     <td>{getTargetAverage()}</td>
    //     <td></td>
    //   </tr>
    // </tbody>
    <tbody>
      {moduleData.checkListMachineData[checklistType].map(
        (item: any, index: any) => (
          <React.Fragment key={index}>
            <tr>
              <td>{index + 1}</td>
              <td>{item[`check_point`]}</td>
              <td
                align="center"
                style={{
                  border: '1px solid black',

                  backgroundColor: isView ? '' : '#f9e0e0',
                }}
              >
                {isView ? (
                  item.freq_m
                ) : (
                  <CustomSelect
                    name="freq_m"
                    value={item.freq_m || ''}
                    onChange={(e) => {
                      item.freq_m = e.target.value;
                      // handleChangeColorOnRemarks(spec, spec.remarks);
                      handleChecklist(e, index);
                      setRender((prev) => !prev);
                    }}
                    sx={{ width: '100px' }}
                  >
                    <MenuItem value="none">NONE</MenuItem>
                    <MenuItem value="✓">
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#40c057',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        ✓
                      </span>
                    </MenuItem>
                    <MenuItem value="✖">
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#f03e3e',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        ✖
                      </span>
                    </MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td
                align="center"
                style={{
                  border: '1px solid black',
                  backgroundColor: isView ? '' : '#f9e0e0',
                }}
              >
                {isView ? (
                  item.freq_q
                ) : (
                  <CustomSelect
                    name="freq_q"
                    value={item.freq_q || ''}
                    onChange={(e) => {
                      item.freq_q = e.target.value;
                      // handleChangeColorOnRemarks(spec, spec.remarks);
                      handleChecklist(e, index);
                      setRender((prev) => !prev);
                    }}
                    sx={{ width: '100px' }}
                  >
                    <MenuItem value="none">NONE</MenuItem>
                    <MenuItem value="✓">
                      {' '}
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#40c057',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        ✓
                      </span>
                    </MenuItem>
                    <MenuItem value="✖">
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#f03e3e',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        ✖
                      </span>
                    </MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td
                align="center"
                style={{
                  border: '1px solid black',
                  backgroundColor: isView ? '' : '#f9e0e0',
                }}
              >
                {isView ? (
                  item.freq_h
                ) : (
                  <CustomSelect
                    name="freq_h"
                    value={item.freq_h || ''}
                    onChange={(e) => {
                      item.freq_h = e.target.value;
                      // handleChangeColorOnRemarks(spec, spec.remarks);
                      handleChecklist(e, index);
                      setRender((prev) => !prev);
                    }}
                    sx={{ width: '100px' }}
                  >
                    <MenuItem value="none">NONE</MenuItem>
                    <MenuItem value="✓">
                      {' '}
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#40c057',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        ✓
                      </span>
                    </MenuItem>
                    <MenuItem value="✖">
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#f03e3e',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        ✖
                      </span>
                    </MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td
                align="center"
                style={{
                  border: '1px solid black',
                  backgroundColor: isView ? '' : '#f9e0e0',
                }}
              >
                {isView ? (
                  item.freq_a
                ) : (
                  <CustomSelect
                    name="freq_a"
                    value={item.freq_a || ''}
                    onChange={(e) => {
                      item.freq_a = e.target.value;
                      // handleChangeColorOnRemarks(spec, spec.remarks);
                      handleChecklist(e, index);
                      setRender((prev) => !prev);
                    }}
                    sx={{ width: '100px' }}
                  >
                    <MenuItem value="none">NONE</MenuItem>
                    <MenuItem value="✓">
                      {' '}
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#40c057',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        ✓
                      </span>
                    </MenuItem>
                    <MenuItem value="✖">
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#f03e3e',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        ✖
                      </span>
                    </MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td
                align="center"
                style={{
                  border: '1px solid black',
                  backgroundColor: isView ? '' : '#f9e0e0',
                }}
              >
                {isView ? (
                  item.observation
                ) : (
                  <TableInput
                    type="text"
                    name="observation"
                    defaultValue={item.observation}
                    onChange={(e) => handleChecklist(e, index)}
                    isView={isView}
                  />
                )}
              </td>
              <td
              // align="center"
              // style={{
              //   border: '1px solid black',
              //   backgroundColor: isView ? '' : '#f9e0e0',
              // }}
              >
                {isView ? (
                  item.remarks
                ) : (
                  <CustomSelect
                    name="remarks"
                    value={item.remarks || ''}
                    onChange={(e) => {
                      item.remarks = e.target.value;
                      // handleChangeColorOnRemarks(spec, spec.remarks);
                      handleChecklist(e, index);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="none">NONE</MenuItem>
                    <MenuItem value="OK">
                      {' '}
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#40c057',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        OK
                      </span>
                    </MenuItem>
                    <MenuItem value="NOT OK">
                      <span
                        style={{
                          fontSize: '1.3rem',
                          color: '#f03e3e',
                          display: 'flex',
                          width: '60px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        NOT OK
                      </span>
                    </MenuItem>
                  </CustomSelect>
                )}
              </td>
            </tr>
          </React.Fragment>
        )
      )}
    </tbody>
  );
};

export default TBody;
