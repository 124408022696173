import moment from 'moment';
import { useState } from 'react';
import { FlexBox, TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';
import CustomSelect from '../../../../../components/common/SelectField';
import MenuItem from '@mui/material/MenuItem';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={4 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['F-HRD-01B', '01', '01.11.2019']}
        heading={`${createConstant.theadHeader}`}
      />
      <tr>
        {[
          'Processes Level',
          'Symbol',
          'Evaluation criteria',
          moduleData?.department_id?.department_name,
        ].map((el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            rowSpan={index >= 0 && index <= 2 ? 2 : 1}
            colSpan={1}
            style={{
              textAlign: 'center',
              border: '1px solid black',
            }}
          >
            {el}
          </th>
        ))}
      </tr>
      <tr>
        <th
          style={{
            textAlign: 'center',
            border: '1px solid black',
          }}
          colSpan={1}
        >
          <FlexBox>
            <div style={{ margin: 'auto' }}>
              Minimum Skill Required :
              {isView ? (
                moduleData.minimum_level_required
              ) : (
                <CustomSelect
                  name="part_type"
                  value={moduleData?.minimum_level_required || ''}
                  onChange={(e: any) => {
                    moduleData.minimum_level_required = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  sx={{
                    fontSize: '1rem',
                    width: '200px',
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="L1">L1</MenuItem>
                  <MenuItem value="L2">L2</MenuItem>
                  <MenuItem value="L3">L3</MenuItem>
                  <MenuItem value="L4">L4</MenuItem>
                </CustomSelect>
              )}
            </div>
          </FlexBox>
        </th>
      </tr>
    </thead>
  );
};

export default THead;
