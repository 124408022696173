import React from 'react';
import { useQuery } from '../../../../hooks/UseQuery';
import { StyledTableRow } from '../helpers/module.styled';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { levels } from '../../helpers/constant';
import { LevelSymbolImg } from '../../assets/levelSymbols';
import { TableInput } from '../../../../components/common';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';

export const TableRowComponent2 = React.memo(
  ({ key, item, index, updateModuleData, handleDeleteRows }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;

    const handleInputChange = (fieldName: string, value: any) => {
      updateModuleData(index, fieldName, value, 'list_2');
    };

    return (
      <React.Fragment key={key}>
        <StyledTableRow index={index}>
          <td>
            <TableInput
              isView={false}
              name="description"
              type="text"
              value={item.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={false}
              name="name_of_trainee"
              type="text"
              value={item.name_of_trainee}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={false}
              name="signature"
              type="text"
              value={item.signature}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={false}
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td style={{ minWidth: '50px' }}>
            <DeleteIconButton
              tooltipTitle={'delete'}
              onClick={() => handleDeleteRows(index, 'list_2')}
            />
          </td>
        </StyledTableRow>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
