export const listConstant = {
  childHeader: 'MSA Plan list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'MSA Plan list',
  theadHeader: ' MSA Plan',
  extracolSpan: 5,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'msaPlanNewRoute/monthlylist',
  deleteListurl: 'msaPlanNewRoute',
  getListPlanByid: 'msaPlanNewRoute',
  createListPlanByid: 'msaPlanNewRoute',

  // -----plan--
  sync: 'msaPlanNewRoute/handleSync',
  getAllPlanById: 'msaPlanNewRoute/allplansbyid',
  upadateCreateAllPlan: 'msaPlanNewRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'msaPlanNewRoute/maintenancePlanDeleteBulkById',
  forAutoComplete: '/api/masterlistinstrument/forAutocomplete',
};

export const settingPanelConstant = {
  // settingPanel setting for plan
  crudApi: 'msaPlanNewSettingRoute',
  docType: 'msaPlanNew',
};

export const tilesConstant = {
  childHeaderText: 'MSA Plan',
};

export const navigationConstant = {
  planRouter: '/common/msaPlanNewCreate',
};
