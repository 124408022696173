import { TbWashMachine } from 'react-icons/tb';
import ChildHeader from '../../components/ui/ChildHeader';
import { RenderTiles } from '../../components/common';
import { FcBusinessman } from 'react-icons/fc';
import { MdOutlineSupervisorAccount } from 'react-icons/md';

const tiles = [
  {
    sNo: 1,
    link: '/common/hpdcChecksheets/dailyMachineChecksheetList',
    isLink: true,
    icon: <TbWashMachine size="5rem" color="green" />,
    p: 'Daily Machine',
    span: 'Checksheet',
    isIcon: true,
  },
  // {
  //   sNo: 2,
  //   link: '/common/hpdcChecksheets/operatorChecksheet',
  //   isLink: true,
  //   icon: <FcBusinessman size="5rem" color="" />,
  //   p: 'Operator List',
  //   span: 'Checksheet',
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/hpdcChecksheets/supervisorChecksheet',
    isLink: true,
    icon: <MdOutlineSupervisorAccount size="5rem" color="purple" />,
    p: 'Supervisor List',
    span: 'Checksheet',
    isIcon: true,
  },
];

const HPDCChecksheetsHome = () => {
  return (
    <>
      <ChildHeader text="HPDC  CHECKSHEETS" />
      <RenderTiles height="100vh" justify="start" tilesObj={tiles} />
    </>
  );
};

export default HPDCChecksheetsHome;
