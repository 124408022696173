import React, { useState } from 'react';
import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import useDebounce from '../../../../../hooks/useDebounce';
import StateCustomTable from '../../../../../components/common/StateCustomTable';
import { StatePagination, TableCustom } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { CustomIconButton } from '../../../../../components/common/button/CustomIconButton';

const tableHeaderObj = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'Item NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Item NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'MACHINE NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: ' DATE',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const filterOptions = [{ value: 'part_number', name: 'A-Z' }];

const PickProcessStd = ({ link }: { link?: string }) => {
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<any>([]);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('part_number');
  const navigate = useNavigate();

  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/processParameterStdRoute?page=${page}&sortBy=${
          filter || 'part_name'
        }&search=${search}&perPage=${10}`
      )
      .catch((err) => {
        console.log(err);
      });
    setTableData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };
  useDebounce(fetchData, 1000, [page, search, filter]);

  return (
    <StateCustomTable
      filterOptions={filterOptions}
      {...{ search, setSearch, filter, setFilter }}
      label="Search Part"
    >
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData?.map((item: any, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {item?.part_id?.part_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.part_id?.part_number}
                  </TableCell>
                  <TableCell align="center">
                    {item?.machine_id?.machine_no}
                  </TableCell>
                  <TableCell align="center">
                    {item?.moduleDate && formatDate(item.moduleDate)}
                  </TableCell>
                  <TableCell align="center">
                    <CustomIconButton
                      sx={{ p: 0 }}
                      tooltipTitle={'select'}
                      onClick={() =>
                        navigate(
                          `${link}/${item._id}?part_id=${item?.part_id?._id}&machine_id=${item?.machine_id?._id}&partName=${item.part_id?.part_name}&partNumber=${item?.part_id?.part_number}&machineNo=${item.machine_id?.machine_no}&isView=false`
                        )
                      }
                    >
                      <Add style={{ color: '#343a40' }} />
                    </CustomIconButton>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
    </StateCustomTable>
  );
};

export default PickProcessStd;
