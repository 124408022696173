export const processes = [
  {
    time: '9:00 AM - 11:00 AM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '11:00 AM - 1:00 PM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '1:00 PM - 3:00 PM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '03:00 PM - 05:00 PM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '05:00 PM - 07:00 PM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '07:00 PM - 09:00 PM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '09:00 PM - 11:00 PM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '11:00 PM - 01:00 AM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '01:00 AM - 03:00 AM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '03:00 AM - 05:00 AM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },

  {
    time: '05:00 AM - 07:00 AM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    time: '07:00 AM - 09:00 AM',
    desc: 'Degassing ',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Metal Temp (640°C-660°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'Die Temp (180°C - 220°C)',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'CHECKED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    desc: 'VERIFIED BY',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
];
