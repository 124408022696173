import lodash from 'lodash';
import moment from 'moment';

export const process = {
  classfication: '',
  place: '',
  tool: '',
  description: '',
  frequency: '',
  shift_A_results: Array(31).fill(''),
  shift_B_results: Array(31).fill(''),
  remarks: ''
};


const processes = [
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'खुरचनी, कपड़',
    description: 'सप्रे यूनिट की सफाई करना',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'खुरचनी, कपड़ा',
    description: 'लेड्ल यूनिट की सफाई करना',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'कपड़',
    description: 'मशीन के ऊपर लगे कवर की सफाई करना',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'खुरचनी, कपड़ा',
    description: 'टोगग्ल सिस्टम की सफाई करना',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'कपड़ा',
    description: 'मशीन के इंजेक्शन यूनिट की सफाई करना',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'कपड़ा',
    description: 'मशीन के दोनों तरफ लगे सेफ्टी डोर की सफाई करना',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'कपड़ा',
    description: 'टाईबार रोड की सफाई करना',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'खुरचनी, कपड़ा',
    description: 'डाई प्लेटेन की फिसलने वाली पत्ती की सफाई करना',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'खुरचनी, कपड़ा',
    description: 'प्लन्जर लुब्रीकेन्ट यूनिट की सफाई करना',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'खुरचनी, बेलचा',
    description: 'होल्डिंग फर्नेस को साफ करना',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'सफाई',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'कपड़ा, झाडू, बेलचा',
    description: 'मशीन के बेस की सफाई करना',
    frequency: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'लुब्रिकेट',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'लेड्ल कोट',
    description: 'लेडल कप पे लेड्ल कोट लगाना है',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'लुब्रिकेट',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'PL NG- 58  तेल',
    description: 'प्लन्जर लुब्रीकेंटस के डिब्बे में तेल चेक करना है',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'सुन कर, देख कर',
    description: 'जांच करनी है के मेन मोटर से असामान्य आवाज नही आ रही',
    frequency: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'सुन कर, देख कर',
    description: 'जांच करनी है के मशीन के टॉगल से असामान्य आवाज नहीं आनी चाहिए',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर',
    description: 'जांच करनी है के मशीन के किसी भी पाइप से तेल नही रिसना चाहिए',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर',
    description: 'जांच करनी है के मशीन के मुख्य हाइड्रोलिक ब्लॉक से तेल नही रिसना चाहिए',
    frequency: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर',
    description: 'जांच करनी है के मशीन के किसी भी हवा के पाइप से हवा नही रिसनी चाहिए',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर',
    description: 'जांच करनी है के मशीन की प्लंजर कप्लींग के बोल्ट टाइट होने चाहिए',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर',
    description: 'जांच करनी है के मशीन की प्लंजर रोड से पानी नहीँ बहना चाहिए',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर',
    description: 'जांच करनी है के मशीन के विसूयलाईजर के लिए लगी हुई लाइट जलती हुई दिखनी चाहिए',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर',
    description: 'चेक करना है बल्ब ठीक से काम कर रहा है',
    frequency: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर',
    description: 'जांच करनी है के मशीन पैनल में लगा AC सही से काम करना चाहिए',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'गेज से 6',
    description: 'नाइट्रोजन का प्रैशर 110- 115 kgcm2 होना चाहिए|',
    frequency: '15 दिन',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर 6',
    description: 'प्लंजर लुब्रिकेसन 2 - 3 सीसी होना चाहिए |',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'जांच',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'देख कर 7',
    description: 'होल पिन पर स्प्रे का पाइप लगा होना चाहिए',
    frequency: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'टाइट',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'एलन-कि 8 MM , 10 MM',
    description: 'लेड्ल यूनिट का कप का बोल्ट टाइट करना है',
    frequency: 'प्रतिदिन',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'टाइट',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'डी-स्पेनर 41,36, 46, 50',
    description: 'प्लंजर टाइट करना है',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'टाइट',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'एलन-कि 8 MM , 10 MM',
    description: 'मशीन की प्लंजर कप्लींग के बोल्ट टाइट करने है',
    frequency: 'साप्ताहिक',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
  {
    classfication: 'टाइट',
    place: 'फोटो वाली रेफरेंस शीट- (F-Prod-44A)  देखें',
    tool: 'रिंग-स्पेनर-30-32',
    description: 'डाई क्लाम्पिंग नट टाइट करने है',
    frequency: 'शिफ्ट',
    shift_A_results: Array(31).fill(''),
    shift_B_results: Array(31).fill(''),
    remarks: ''
  },
];

export const initialState = {
  isSubmitted: false,
  report_prepared_by: '',
  report_approved_by: '',
  status:null,
  dailyBin_date: moment(),
  processes: lodash.cloneDeep(processes),
  machine_id: null
};