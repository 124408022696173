import { TbAspectRatio, TbFaceIdError } from 'react-icons/tb';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { RiDeleteBin4Fill } from 'react-icons/ri';
import { SiFloatplane } from 'react-icons/si';
import { GrPlan } from 'react-icons/gr';
import { BsCalendar2CheckFill } from 'react-icons/bs';
import { GoReport } from 'react-icons/go';
import { LuListChecks } from 'react-icons/lu';
import { FaCalendarCheck } from 'react-icons/fa6';

const tiles = [
  {
    sNo: 1,
    link: '/common/scrapNoteList',
    isLink: true,
    icon: <RiDeleteBin4Fill size="5rem" color="#a12222" />,
    p: 'SCRAP CHALLAN',
    span: 'REPORT',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/masterListOfProblemOrDefects',
    isLink: true,
    icon: <TbFaceIdError size="5rem" color="#a12222" />,
    p: 'List Of Problem ',
    span: '/ Defects',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/msaPlanNewTiles',
    isLink: true,
    clauseNo: 4,
    icon: <SiFloatplane size="5rem" color="#a12222" />,
    p: 'MSA',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/attributeMsaPlanNewTiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrPlan size="5rem" color="#a12222" />,
    p: 'Attribute MSA',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/spcPlanNewTiles',
    isLink: true,
    clauseNo: 4,
    icon: <BsCalendar2CheckFill size="5rem" color="#a12222" />,
    p: 'SPC',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/lastShotReportTableList',
    isLink: true,
    clauseNo: 4,
    icon: <GoReport size="5rem" color="#a12222" />,
    p: 'Last Shot',
    span: 'Report',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/checksheetFormatsChecklist',
    isLink: true,
    icon: <LuListChecks size="5rem" color="purple" />,
    p: 'Instrument Checksheet',
    span: 'Formats',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/fillCheckSheetChecklist',
    isLink: true,
    icon: <FaCalendarCheck size="5rem" color="green" />,
    p: 'Fill Instrument',
    span: 'Checksheet',
    isIcon: true,
  },
];

const QualityDeparmentHome = () => {
  return (
    <>
      <ChildHeader text="QUALITY DEPARTMENT" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default QualityDeparmentHome;
