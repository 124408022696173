import React, { useEffect, useState } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../../components/common';

import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/auth/AuthProvider';
import { apiConstant, listConstant, navigationConstant } from './constant';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import { SearchBar } from '../../../components/common/SearchBar';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../../components/common/button/CustomIconButton';
import { IData } from '../../spc/interface';
import ModalCustom from '../../../components/common/Modal';
import { SupervisorPartTable } from '../../../components/ui';
import formatDate from '../../../components/common/formatDate';
const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Part Name', rowspan: 1, colspan: 1 },
    { text: 'Part No', rowspan: 1, colspan: 1 },
    { text: 'Instrument Name', rowspan: 1, colspan: 1 },
    { text: 'Created Date', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const List = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  const navigation = useNavigate();
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this document?'
  );
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const page = useQuery('page') || 1;

  const fetchData = async () => {
    axios
      .get(
        `${apiConstant.gettableDataurl}?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&date=${date}`
      )
      .then((res) => {
        setTableData(res?.data?.results);
        setPerPage(res?.data?.perPage);
        setTotalPages(res?.data?.pages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(fetchData, 500, [page, search, render, date, filter]);
  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`${apiConstant.deleteurl}/${id}`)
      .then(() => {
        alert('document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  };

  return (
    <>
      <ChildHeader text={listConstant.childHeader}>
        <AddButton
          label={listConstant.addButtonLable}
          onClick={() => setIsOpenPopup(true)}
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>

                  <TableCell align="center">
                    {item?.part_id?.part_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.part_id?.part_number}
                  </TableCell>
                  <TableCell align="center">
                    {item?.instrument_id?.instrument_name}
                  </TableCell>
                  <TableCell align="center">{formatDate(item?.date)}</TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          navigation(
                            `${navigationConstant.createPlanSchedule}/${
                              item.part_id._id
                            }/${item._id}?partName=${
                              item?.part_id?.part_name
                            }&partNumber=${
                              item?.part_id?.part_number
                            }&customer_name=${
                              item?.part_id?.customer_id?.customer_name ?? ''
                            }`
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteDocument(item?._id);
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title="CHOOSE PART"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
        sxDialogContent={{
          width: '70vw',
        }}
      >
        <SupervisorPartTable link={navigationConstant.createPlanSchedule} />
      </ModalCustom>
      <CustomPagination totalPage={totalPages} />
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
