import {
  MdOutlineCompress,
  MdPlaylistAdd,
  MdReceiptLong,
} from 'react-icons/md';
import { TbFaceIdError } from 'react-icons/tb';
const tiles = [
  {
    sNo: 1,
    link: '/common/similarPartManagementList',
    isLink: true,
    icon: <MdPlaylistAdd size="5rem" color="#003566" />,
    p: 'Similar Part',
    span: 'Management',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/reasonsForRejectionList?filter=createdAt',
    isLink: true,
    icon: <TbFaceIdError size="5rem" color="#a12222" />,
    p: 'Master List of',
    span: 'REASONS FOR REJECTION',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/reworkRegisterList',
    isLink: true,
    icon: <MdOutlineCompress size="5rem" color="#1864ab" />,
    p: 'Rework',
    span: 'Register',
    isIcon: true,
  },
];

export default tiles;
