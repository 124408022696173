import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}
const getYearRange = (currentDate: string | number | Date) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const yearRange = useQuery('yearRange');
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={13 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['F-HRD-05', '01', '01-03-2018']}
        heading={`${createConstant.theadHeader} ${getYearRange(
          moduleData.created_year
        )}`}
      />
      <tr>
        <th colSpan={19} align="left">
          DEPARTMENT:
          {moduleData?.departments?.department_name}
        </th>
      </tr>
      <tr>
        {[
          'S.NO.',
          'TOPIC OF TRAINING',
          'TRAINER',
          'INTERNAL/EXTERNAL',
          'PLANNED HOURS',
          `YEAR: ${getYearRange(moduleData.created_year)}`,
          'REMARKS',
        ].map((el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            rowSpan={index === 5 ? 1 : 2}
            colSpan={index === 5 ? 12 : index === 6 ? 2 : 1}
            style={{
              textAlign: 'center',
              // ...style1,
              // minWidth: `${
              //   (el === 'INSTRUMENTS/GAUGE' && '250px') ||
              //   (el === 'S. No.' && '50px') ||
              //   style1.minWidth
              // }`,
            }}
          >
            {el}
          </th>
        ))}
      </tr>
      <tr>
        {[
          'APR',
          'MAY',
          'JUN',
          'JUL',
          'AUG',
          'SEP',
          'OCT',
          'NOV',
          'DEC',
          'JAN',
          'FEB',
          'MAR',
        ].map((el, index) => (
          <th
            key={`${index}_headcol`}
            align="center"
            colSpan={1}
            style={{ textAlign: 'center' }}
          >
            {el}
          </th>
        ))}
      </tr>
      {/* <th style={{ textAlign: 'center', ...style1 }}>Remarks</th> */}
    </thead>
  );
};

export default THead;
