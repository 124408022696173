import moment from 'moment';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={7}>
          NOTE: -
        </th>
      </tr>
      <tr>
        <th align="center" colSpan={7}>
          1.Employee need to score 60% and above to qualify the level.
        </th>
      </tr>
      <tr>
        <th align="center" colSpan={7}>
          2.Question marked with "**" must be correct to qualify the level.
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={4}>
          Candidate Sign:-{' '}
        </th>
        <th align="left" colSpan={3}>
          Checked By:-
        </th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
