import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import { Grid, SelectChangeEvent } from '@mui/material';
import PartTable from './components/PartTable';
import ModalCustom from '../../components/common/Modal';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../components/common/button/CustomIconButton';
import { DivStyled } from '../../assets/styles/components/DivStyledLableInputs';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    part_id: null,
  });
  const [_, setRender] = useState(false);
  const [isPartModal, setIsPartModal] = useState(false);
  const [departmentData, setDepartmentData] = useState<any>([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Tools Or  Die' : 'Create Tools Or Die'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    axios
      .get(`/api/department/department/departmentForAutoComplete`)
      .then((res) => {
        setDepartmentData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListOfToolsOrDieRoute/${id}`)
        .then((res) => {
          setModuleData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleMoooduleData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterListOfToolsOrDieRoute/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.is_admin_request = 'pending';
      await axios
        .post('/api/masterListOfToolsOrDieRoute', moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'die_name'}
        name={'die_name'}
        html_for={'die_name'}
        label_name={'Die Name'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.die_name}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'die_number'}
        name={'die_number'}
        html_for={'die_number'}
        label_name={'Die Number'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.die_number}
        onChange={handleMoooduleData}
      />

      {/* <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Number'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof moduleData?.part_id === 'object' &&
                moduleData?.part_id !== null
                  ? moduleData?.part_id?.part_number
                  : moduleData?.partNumber || ''}
              </p>
            </Grid>
            {moduleData.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    moduleData.part_id = null;
                    moduleData.partNumber = null;
                    moduleData.partName = null;
                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Name'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          {moduleData?.partName || moduleData?.part_id?.part_name}
        </div>
      </DivStyled> */}
      <AutocompleteGridmui
        id={'department_name'}
        label={'Department : '}
        option_name={'department_name'}
        arrayofObj={departmentData}
        value={moduleData.department_id || ''}
        onChange={(e, value) => {
          moduleData.department_id = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'frequency'}
        name={'frequency'}
        html_for={'frequency'}
        label_name={'Frequency/No. of Shots (From): '}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.frequency || ''}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'frequency_to'}
        name={'frequency_to'}
        html_for={'frequency_to'}
        label_name={'Frequency/No. of Shots (To): '}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.frequency_to || ''}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks}
        onChange={handleMoooduleData}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={moduleData} />
      </ModalCustom>
    </>
  );
};

export default Create;
