import { Checkbox, Stack, Tooltip } from '@mui/material';
import {
  CustomButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { PersitedDatePicker, SearchBar } from '../../../components/common';
import useConfirm from '../../../components/common/useConfirm';
import FilterBy from '../../../components/common/FilterBy';
import axios from 'axios';
import { useQuery } from '../../../hooks/UseQuery';
import { GoSync } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { CustomIconButton } from '../../../components/common/button/CustomIconButton';
import { CustomTypography } from './helpers/StyledElements.styled';

const HeaderCreate = ({
  addRow,
  handelSync,
  onSubmit,
  isSelected,
  handleDelete,
  moduleData,
  setSelectionArray,
  isSyncRequired,
}: any) => {
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const department_id = useQuery('department_id');

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 2,
          pb: 1,
          pt: 2,
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'start',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <SearchBar label="Search" sx={{ width: '500px' }} />
        </Stack>

        <Stack
          direction="row"
          sx={{
            gap: 2,
          }}
        >
          {isSelected.length > 0 && (
            <>
              <CustomTypography variant="caption">
                {`${isSelected.length} ${
                  isSelected.length === 1
                    ? 'report on this page is selected'
                    : 'reports on this page are selected'
                }`}
              </CustomTypography>
              <div>
                <CustomIconButton
                  sx={{ p: 0 }}
                  tooltipTitle={'Delete'}
                  onClick={handleDelete}
                >
                  <MdDeleteForever color="#f03e3e" size={'3.5rem'} />
                </CustomIconButton>
                <Tooltip title="SELECT ALL" arrow>
                  <Checkbox
                    aria-label="check"
                    sx={{ color: '#c92a2a' }}
                    onClick={(e: any) => {
                      let getIds = [];
                      if (e.target.checked) {
                        getIds = moduleData.map(
                          (item: any) => item?._id || item?._tempId
                        );
                      } else {
                        getIds = moduleData.map((item: any) => null);
                      }
                      setSelectionArray(getIds);
                    }}
                  />
                </Tooltip>
              </div>
            </>
          )}

          <CustomButton
            sx={{
              backgroundColor: '#228be6',
              color: '#e7f5ff',
            }}
            icon={<GoSync />}
            size="large"
            onClick={() => handelSync()}
          >
            Sync
          </CustomButton>
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
            }}
            onClick={async () => {
              if (isSyncRequired) {
                alert('Data need to sync first before adding a row !!!🛑');
                return;
              }
              const ans = await confirmAddRow();
              if (!ans) return;
              addRow();
            }}
          >
            ADD ROWS
          </CustomButton>
          <UpdateButton
            label="update"
            sx={{ mr: '37px', fontSize: '2rem' }}
            size="large"
            onClick={() => {
              onSubmit();
            }}
          />
        </Stack>
      </Stack>
      <DialogAddRow />
    </>
  );
};

export default HeaderCreate;
