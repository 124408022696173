const totalAchievedScore = (moduleData: any) => {
  let totalAchievedScore = 0;
  [
    'total_last_audit_nc_verification',
    'total_management_of_quality',
    'total_quality_planning_and_change_control',
    'total_control_of_purchase_material',
    'total_control_during_processing',
    'total_control_of_finished_product',
    'total_control_of_nonconforming_parts_and_corrective_preventive_actions',
    'total_control_of_measuring_and_test_equipment',
    'total_control_of_5s_management',
  ].forEach((key) => {
    totalAchievedScore += +moduleData[key] || 0;
  });
  moduleData.total_achieved_score = totalAchievedScore;

  moduleData.score_percentage = (
    ((+totalAchievedScore || 0) / +moduleData.total_max_score) *
    100
  ).toFixed(3);
  console.log(moduleData.score_percentage);
};
export const calculateScore = (moduleData: any, key: string) => {
  let total = 0;
  moduleData[key]?.forEach((item: any, index: number) => {
    item?.score?.forEach((score: number) => {
      total += +score || 0;
    });
  });
  moduleData[`total_${key}`] = total;
  totalAchievedScore(moduleData);
};
