import moment from 'moment';
import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  handleChangeMachine: (value: any) => void;
}

const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = ['Sr.No.', 'विवरण', 'फ्रीक्वेंसी', ...numberCol, 'Remarks'];

const THead = ({
  moduleData,
  handleChangeMachine,
  isView,
  machineData,
}: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('annual_date');

  return (
    <thead>
      <TableCompHead
        colSpan={35}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date:']}
        values={['F-PROD-52', '02', '01-04-2021']}
        heading="Operator  (HPDC) Check Sheet"
      />
      <tr>
        <th colSpan={35} align="left">
          Month : {date}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine No. :{' '}
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            `${moduleData?.machine_id?.machine_no}/
            ${moduleData?.machine_id?.machine_name}`
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              option_name_2="machine_name"
              arrayofObj={machineData}
              value={moduleData.machine_id || ''}
              onChange={(e, value) => {
                handleChangeMachine(value);
              }}
            />
          )}
        </th>
        <th colSpan={29}></th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Date :{' '}
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            formatDate(moduleData.dailyBin_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyBin_date)}
              onChange={(date) => {
                moduleData.dailyBin_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={29}></th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
