import React, { useState } from 'react';
import { allmonth } from '../../helpers/utils';
import { TableRowComponent } from './TableRowComponent';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import moment from 'moment';

interface IProps {
  moduleData: any;

  updateModuleData: any;
}
export interface IRev {
  rev_no?: any;
  rev_date?: any;
  rev_desc?: string;
  prepared_by?: string;
  approved_by?: string;
}
const TBody = ({ moduleData, updateModuleData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            allmonth,
            updateModuleData,
          }}
        />
      ))}
      <tr
        style={{
          background: 'rgb(33, 37, 41)',
          color: '#fff',
          height: '40px',
        }}
      >
        <th colSpan={6} align="center">
          Revision History
        </th>
      </tr>
      <tr style={{ background: '#00B050', color: '#fff' }}>
        {[
          'Rev.No',
          'REV. DATE',
          'REVISION REASON',
          'REVISED BY',
          'APPROVED BY',
        ].map((heading) => (
          <th key={heading} colSpan={heading === 'REVISION REASON' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>

      {moduleData?.revision_history?.map((revData: IRev, index: number) => (
        <tr>
          <td style={{ width: '120px' }}>
            <TableInput
              name="rev_no"
              type="number"
              value={revData.rev_no}
              onChange={(e) => {
                revData.rev_no = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
          <td style={{ width: '250px' }}>
            <DatePickerMui
              value={moment(revData.rev_date)}
              onChange={(date) => {
                revData.rev_date = date;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={2}>
            <TableInput
              name="rev_desc"
              type="text"
              value={revData.rev_desc}
              isView={false}
              onChange={(e) => {
                revData.rev_desc = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              name="prepared_by"
              type="text"
              value={revData.prepared_by}
              isView={false}
              onChange={(e) => {
                revData.prepared_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              name="approved_by"
              type="text"
              value={revData.approved_by}
              isView={false}
              onChange={(e) => {
                revData.approved_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default React.memo(TBody);
