import Admin from '../pages/adminHome/AdminHome';
import { clause4 } from './admin/clause4';
import { clause7 } from './admin/clause7';
import { clause5 } from './admin/clause5';
import { other } from './admin/other';
import { clause1 } from './admin/clause1';
import { Clause17Home } from '../pages/clause17';
import { Clause6Home } from '../pages/clause6';
import { Clause11Home } from '../pages/clause11';
import { Clause9Home } from '../pages/clause9';
import { Clause12Home } from '../pages/clause12Page';
interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const IndexAdmin: routes[] = [
  {
    path: 'home',
    element: <Admin />,
  },
  {
    path: 'clause17',
    element: <Clause17Home />,
  },
  {
    path: 'clause6',
    element: <Clause6Home />,
  },
  {
    path: 'clause11',
    element: <Clause11Home />,
  },
  {
    path: 'clause12',
    element: <Clause12Home />,
  },
  {
    path: 'clause9',
    element: <Clause9Home />,
  },
  // ...productions,
  ...clause1,
  ...clause4,
  ...clause5,
  ...clause7,
  ...other,
];
