export const apiConstant = {
  // list api url
  gettableListDataurl: 'annualPmPlanMetallicBinRoute/monthlylist',
  deleteListurl: 'annualPmPlanMetallicBinRoute',
  getListPlanByid: 'annualPmPlanMetallicBinRoute',
  createListPlanByid: 'annualPmPlanMetallicBinRoute',
  // -----plan--
  getAllPlanById: 'annualPmPlanMetallicBinRoute/allplansbyid',
  upadateCreateAllPlan:
    'annualPmPlanMetallicBinRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'annualPmPlanMetallicBinRoute/maintenancePlanDeleteBulkById',
  sync: 'annualPmPlanMetallicBinRoute/handleSync',
  // ----status---
  getAllStatus: 'annualPmPlanMetallicBinRoute/getAllSatusByYear',
  upadateListStatus: 'annualPmPlanMetallicBinRoute/handleStatusUpdate',

  //
  updatePlanByid: 'annualPmPlanMetallicBinRoute',
  checklistUpload: 'annualPmPlanMetallicBinRoute/handleUpload',
  checklistDeleteImage: 'annualPmPlanMetallicBinRoute/handleDeleteImage',
  checklistData: 'annualPmPlanMetallicBinRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'Annual PM Plan Metallic Bin',
};
