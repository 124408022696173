import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/Tcontroller';
import useGetData from './helpers/useGetData';
import { useAuth } from '../../../services/auth/AuthProvider';
interface MyData {
  moduleData: any;
  isLoading: boolean;
  error?: string | null;
}
const Create = () => {
  const { state } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const { moduleData, isLoading, error }: MyData = useGetData(id);

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    if (moduleData._id) {
      axios
        .put(`/api/lastShotReportRoute/${moduleData._id}`, moduleData)
        .then((res) => {
          if (res.data) alert('updated successfully');
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`/api/lastShotReportRoute`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('created successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="LAST SHOT REPORT">
          {!isView && (
            <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
        }}
      />
    </Box>
  );
};

export default Create;
