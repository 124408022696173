import React, { useState } from 'react';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  // customerData: any;
  isView: boolean;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}

const TBody = ({
  moduleData,
  // customerData,
  isView,
}: // handelDeleteRows,
  // setIsOpenPopup,
  // setPartIdx,
  IProps) => {
  const [render, setRender] = useState(false);
  let shift_A_results = moduleData.processes[4].shift_A_results;
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <React.Fragment key={index + 1 + 'process'}>
              <TBodyRow
                {...{
                  moduleData,
                  process,
                  index,
                  isView,
                  shift_A_results,
                  setRender
                  // handelDeleteRows,
                  // setIsOpenPopup,
                  // setPartIdx,
                }}
              />
            </React.Fragment>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
