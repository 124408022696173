import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import lodash from 'lodash';
import { initialState } from './helpers/initialState';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import moment from 'moment';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    ...lodash.cloneDeep(initialState),
  });
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/vendorRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModuleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/vendorRoute/${id}`, moduleData)
        .then((res: any) => {
          if (res?.data?.error?.message === 'vendor must be unique') {
            return alert('same vendor name exists');
          }
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .post('/api/vendorRoute', moduleData)
        .then((res: any) => {
          if (res?.data?.error?.message === 'vendor must be unique') {
            return alert('same vendor name exists');
          }
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'vender_name'}
        name={'vender_name'}
        html_for={'vender_name'}
        label_name={'Supplier Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.vender_name || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'address'}
        name={'address'}
        html_for={'address'}
        label_name={'Address: '}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.address || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'material'}
        name={'material'}
        html_for={'material'}
        label_name={'Material: '}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.material || ''}
        onChange={handleModuleData}
      />
      <SelectGridMui
        name="grade"
        value={moduleData?.grade || ''}
        lable={'Grade(A, B, C):'}
        onChange={(e: any) => handleModuleData(e)}
      >
        <MenuItem value="A">A</MenuItem>
        <MenuItem value="B">B</MenuItem>
        <MenuItem value="C">C</MenuItem>
      </SelectGridMui>
      <GridInputs
        id={'category'}
        name={'category'}
        html_for={'category'}
        label_name={'Category (RM, JOB WORK, CONSUMABLE, SERVICE):'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.category || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'contact_person'}
        name={'contact_person'}
        html_for={'contact_person'}
        label_name={'Contact Person:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.contact_person || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'contact_no'}
        name={'contact_no'}
        html_for={'contact_no'}
        label_name={'Phone:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.contact_no || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'email_id'}
        name={'email_id'}
        html_for={'email_id'}
        label_name={'Email ID:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.email_id || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'gst_no'}
        name={'gst_no'}
        html_for={'gst_no'}
        label_name={'GSTIN NO.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.gst_no || ''}
        onChange={handleModuleData}
      />
      <DateGridMui
        lable="Joining Date:"
        onChange={(date: any) => {
          moduleData.joining_date = date;
          setRender((prev) => !prev);
        }}
        value={
          moduleData?.joining_date ? moment(moduleData.joining_date) : null
        }
      />
      <SelectGridMui
        name="iso_cert"
        value={moduleData?.iso_cert || ''}
        lable={'ISO Certifiaction(YES, NO):'}
        onChange={(e: any) => handleModuleData(e)}
      >
        <MenuItem value="YES">YES</MenuItem>
        <MenuItem value="NO">NO</MenuItem>
      </SelectGridMui>
      <SelectGridMui
        name="iso_cert_valid_dt"
        value={moduleData?.iso_cert_valid_dt || ''}
        lable={'ISO Certifiaction Valid DT.:'}
        onChange={(e: any) => handleModuleData(e)}
      >
        <MenuItem value="YES">YES</MenuItem>
        <MenuItem value="NO">NO</MenuItem>
      </SelectGridMui>
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks || ''}
        onChange={handleModuleData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
