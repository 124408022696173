import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot style={{ background: '#a9e34b' }}>
      <tr>
        <th colSpan={2} align="left">
          Deptt. :
          <TableInput
            isView={isView}
            // style={{ height: '80px', width: '300px' }}
            name="department"
            type="text"
            value={moduleData.department}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={7} align="left">
          Name Of User:-
          <TableInput
            isView={isView}
            // style={{ height: '80px', width: '300px' }}
            name="user_name"
            type="text"
            value={moduleData.user_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={6} align="left">
          Sign.-
          <TableInput
            isView={isView}
            // style={{ height: '80px', width: '300px' }}
            name="sign"
            type="text"
            value={moduleData.sign}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={15}>
          NOTE:- इंस्ट्रूमेंट में किसी भी तरह की प्रॉब्लम आने पर Q.A इंजिनियर को
          तुरंत इसकी कैल्लिब्रेशन के लिए सूचित करें |
        </th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
