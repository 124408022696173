const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Mould Status / Parameter', style: style1 },
    { text: 'OK / NG' , style: style1 },
    { text: 'Part verified by QA Engg Sign.', style: style1 },
    { text: 'Remark', style: style1 },
  ],
];

export const headerConstant = {
  tableCompHeadTitle: 'THIRD PARTY MATERIAL TEST CHECKSHEET ',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getById: 'statusCheckListRouteThirdPartyMaterialTestPlan',
  post: 'statusCheckListRouteThirdPartyMaterialTestPlan',
  update: 'statusCheckListRouteThirdPartyMaterialTestPlan',
  delete: 'statusCheckListRouteThirdPartyMaterialTestPlan',
  masterCheckListAutoComplete:
    'masterCheckListRouteThirdPartyMaterialTestPlan/masterCheckListAutoComplete',
  getMasterCheckListById: 'masterCheckListRouteThirdPartyMaterialTestPlan',
};

export const navigationConstant = {};
