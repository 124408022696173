import { Checkbox, Tooltip } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import useConfirm from '../../../../../components/common/useConfirm';
import { IModule, IRev, ISpec } from '../../interface';
import moment from 'moment';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );

  const handelDeleteRows = async (index: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    moduleData.specs.splice(index, 1);
    setRender((prev) => !prev);
  };
  return (
    <tbody>
      {moduleData?.specs?.map((item: ISpec, index: number) => (
        <tr>
          <td style={{ width: '120px' }}>
            <TableInput
              name="s_no"
              type="text"
              value={item.s_no}
              onChange={(e) => {
                item.s_no = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="parameter"
              type="text"
              value={item.parameter}
              onChange={(e) => {
                item.parameter = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="specification"
              type="text"
              value={item.specification}
              onChange={(e) => {
                item.specification = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="method"
              type="text"
              value={item.method}
              onChange={(e) => {
                item.method = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e) => {
                item.remarks = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>

          <td style={{ width: '150px' }}>
            {isView ? (
              item.spec_to_inspection && '✓'
            ) : (
              <>
                <Tooltip title="Add To Inspection Report">
                  <Checkbox
                    checked={item.spec_to_inspection === true}
                    onChange={(e) => {
                      item.spec_to_inspection = e.target.checked;
                      setRender((prev) => !prev);
                    }}
                  />
                </Tooltip>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </>
            )}
          </td>
        </tr>
      ))}
      {/* <tr>
        <th colSpan={11} align="left">
          Revision History
        </th>
      </tr> */}

      {/* {moduleData?.revision_history?.map((revData: IRev, index: number) => (
        <tr>
          <td style={{ width: '120px' }}>
            <TableInput
              name="rev_no"
              type="number"
              value={revData.rev_no}
              onChange={(e) => {
                revData.rev_no = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td style={{ width: '250px' }}>
            {isView ? (
              formatDate(revData.rev_date || null)
            ) : (
              <DatePickerMui
                value={moment(revData.rev_date)}
                onChange={(date) => {
                  revData.rev_date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </td>
          <td colSpan={7}>
            <TableInput
              name="rev_desc"
              type="text"
              value={revData.rev_desc}
              onChange={(e) => {
                revData.rev_desc = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="prepared_by"
              type="text"
              value={revData.prepared_by}
              onChange={(e) => {
                revData.prepared_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="approved_by"
              type="text"
              value={revData.approved_by}
              onChange={(e) => {
                revData.approved_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
        </tr>
      ))} */}
      {/* <tr>
        {['S.NO.', 'DATE', 'AMENDMENTS', 'PREP. BY.', 'APPR. BY.'].map(
          (heading) => (
            <th key={heading} colSpan={heading === 'AMENDMENTS' ? 7 : 1}>
              {heading}
            </th>
          )
        )}
      </tr> */}

      <DialogDeleteRow isSubmitContent={false} />
    </tbody>
  );
};

export default TBody;
