import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import { IData } from '../helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<IData>({});
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Gauge' : 'Create Gauge'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListGaugeRoute/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterListGaugeRoute/${id}`, data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterListGaugeRoute', data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'gauge_no'}
        name={'gauge_no'}
        html_for={'gauge_no'}
        label_name={'Gauge No:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.gauge_no || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'gauge_name'}
        name={'gauge_name'}
        html_for={'gauge_name'}
        label_name={'Gauge Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.gauge_name || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'size'}
        name={'size'}
        html_for={'size'}
        label_name={'Size:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.size || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.location || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'method_of_calibration'}
        name={'method_of_calibration'}
        html_for={'method_of_calibration'}
        label_name={'Method of Calibration:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.method_of_calibration || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'calibration_freq'}
        name={'calibration_freq'}
        html_for={'calibration_freq'}
        label_name={'Calibration Frequency:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.calibration_freq || ''}
        onChange={handleChange}
      />
      <GridDatePicker
        label_name="Calibration Done Date"
        html_for={'calibration_done_date'}
        value={data?.calibration_done_date || null}
        onChange={(date: any) => {
          data.calibration_done_date = date;
          setRender((prev) => !prev);
        }}
      />
      <GridDatePicker
        label_name="Next Due Date"
        html_for={'next_due_date'}
        value={data?.next_due_date || null}
        onChange={(date: any) => {
          data.next_due_date = date;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'status'}
        name={'status'}
        html_for={'status'}
        label_name={'Status:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.status || ''}
        onChange={handleChange}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
