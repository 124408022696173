import { useState } from 'react';
import { Checkbox, Grid } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import { TimePicker } from '@mui/x-date-pickers';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import lodash from 'lodash';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  customerData: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
  customerData,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <>
              <tr key={index + 'module data'}>
                <td rowSpan={3}>{index + 1}</td>
                <td style={{ width: '250px', minWidth: '250px' }} rowSpan={3}>
                  {isView ? (
                    process?.part_id && process.part_id?.part_name
                  ) : (
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      alignItems={'center'}
                    >
                      <Grid item xs={10}>
                        <p
                          style={{
                            fontSize: '1.2rem',
                          }}
                        >
                          {typeof process?.part_id === 'object' &&
                          process?.part_id !== null
                            ? process.part_id?.part_name
                            : process?.partName || ''}
                          {!process?.part_id && 'Pick Part No.'}
                        </p>
                      </Grid>

                      {process.part_id ? (
                        <Grid item xs={2}>
                          <RemoveIconButton
                            tooltipTitle="remove part"
                            onClick={() => {
                              process.part_id = null;
                              process.partNumber = null;
                              process.partName = null;
                              setRender((prev) => !prev);
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={2}>
                          <AddIconButton
                            tooltipTitle="add part"
                            onClick={() => {
                              setIsOpenPopup(true);
                              setPartIdx(index);
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </td>
                <td rowSpan={3} style={{ width: '150px', minWidth: '150px' }}>
                  {typeof process?.part_id === 'object' &&
                  process?.part_id !== null
                    ? process.part_id?.part_number
                    : process?.partNumber || ''}
                </td>
                <td rowSpan={3} style={{ width: '150px', minWidth: '150px' }}>
                  <AutocompleteMuiCustom
                    id={'customer_name'}
                    label={''}
                    option_name={'customer_name'}
                    arrayofObj={customerData}
                    value={process.customer_id || ''}
                    onChange={(e, value) => {
                      process.customer_id = value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <th style={{ background: 'yellow' }}>Actual</th>
                {process?.defect_no?.actual.map(
                  (actual_qty: any, actual_qty_index: any) => {
                    if (actual_qty_index < 19) {
                      return (
                        <td style={{ minWidth: '100px' }}>
                          <TableInput
                            isView={isView}
                            name="actual_qty"
                            type="number"
                            value={process.defect_no.actual[actual_qty_index]}
                            onChange={(e) => {
                              process.defect_no.actual[actual_qty_index] =
                                e.target.value;
                              process.defect_no.actual[19] = lodash.sum(
                                process.defect_no.actual
                                  .slice(0, 18)
                                  .map(Number)
                              );
                              process.defect_no.actual[20] =
                                +process.defect_no.actual[18] -
                                +process.defect_no.actual[19];
                              setRender((prev) => !prev);
                            }}
                          />
                        </td>
                      );
                    } else if (
                      actual_qty_index == 19 ||
                      actual_qty_index == 20
                    ) {
                      return (
                        <td style={{ minWidth: '100px' }}>
                          {process.defect_no.actual[actual_qty_index]}
                        </td>
                      );
                    } else {
                      return (
                        <td style={{ minWidth: '100px' }}>
                          <TableInput
                            isView={isView}
                            name="remarks"
                            type="text"
                            value={process.defect_no.actual[actual_qty_index]}
                            onChange={(e) => {
                              process.defect_no.actual[actual_qty_index] =
                                e.target.value;
                              setRender((prev) => !prev);
                            }}
                          />
                        </td>
                      );
                    }
                  }
                )}
                {!isView && (
                  <td style={{ minWidth: '100px' }} rowSpan={3}>
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => handelDeleteRows(index)}
                    />
                  </td>
                )}
              </tr>
              <tr>
                <th style={{ background: 'green' }}>Result</th>
                {process?.defect_no?.result.map(
                  (result_qty: any, result_qty_index: any) => {
                    if (result_qty_index < 19) {
                      return (
                        <td style={{ minWidth: '100px' }}>
                          <TableInput
                            isView={isView}
                            name="result_qty"
                            type="number"
                            value={process.defect_no.result[result_qty_index]}
                            onChange={(e) => {
                              process.defect_no.result[result_qty_index] =
                                e.target.value;
                              process.defect_no.result[19] = lodash.sum(
                                process.defect_no.result
                                  .slice(0, 18)
                                  .map(Number)
                              );
                              process.defect_no.result[20] =
                                +process.defect_no.result[18] -
                                +process.defect_no.result[19];
                              setRender((prev) => !prev);
                            }}
                          />
                        </td>
                      );
                    } else if (
                      result_qty_index == 19 ||
                      result_qty_index == 20
                    ) {
                      return (
                        <td style={{ minWidth: '100px' }}>
                          {process.defect_no.result[result_qty_index]}
                        </td>
                      );
                    } else {
                      return (
                        <td style={{ minWidth: '100px' }}>
                          <TableInput
                            isView={isView}
                            name="remarks"
                            type="text"
                            value={process.defect_no.result[result_qty_index]}
                            onChange={(e) => {
                              process.defect_no.result[result_qty_index] =
                                e.target.value;
                              setRender((prev) => !prev);
                            }}
                          />
                        </td>
                      );
                    }
                  }
                )}
              </tr>
              <tr>
                <th style={{ background: 'grey' }}>Status</th>
                {process?.defect_no?.status.map(
                  (status_qty: any, status_qty_index: any) => {
                    if (status_qty_index < 19) {
                      return (
                        <td style={{ minWidth: '100px' }}>
                          <TableInput
                            isView={isView}
                            name="result_qty"
                            type="number"
                            value={process.defect_no.status[status_qty_index]}
                            onChange={(e) => {
                              process.defect_no.status[status_qty_index] =
                                e.target.value;
                              process.defect_no.status[19] = lodash.sum(
                                process.defect_no.status
                                  .slice(0, 13)
                                  .map(Number)
                              );
                              process.defect_no.status[20] =
                                +process.defect_no.status[18] -
                                +process.defect_no.status[19];
                              setRender((prev) => !prev);
                            }}
                          />
                        </td>
                      );
                    } else if (
                      status_qty_index == 19 ||
                      status_qty_index == 20
                    ) {
                      return (
                        <td style={{ minWidth: '100px' }}>
                          {process.defect_no.status[status_qty_index]}
                        </td>
                      );
                    } else {
                      return (
                        <td style={{ minWidth: '100px' }}>
                          <TableInput
                            isView={isView}
                            name="remarks"
                            type="text"
                            value={process.defect_no.status[status_qty_index]}
                            onChange={(e) => {
                              process.defect_no.status[status_qty_index] =
                                e.target.value;
                              setRender((prev) => !prev);
                            }}
                          />
                        </td>
                      );
                    }
                  }
                )}
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
