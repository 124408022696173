import { Clause5 } from '../../pages/clause5page';
import List from '../../pages/employee/List';
import ListOfProcedures from '../../pages/listOfProcedures/listOfProcedures';
import FacultyList from '../../pages/msterListOfFaculty/List';

export const clause5 = [
  {
    path: 'education_training_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'clause5',
    element: <Clause5 />,
  },
  {
    path: 'employee_list',
    element: <List />,
  },
  {
    path: 'masterlistoffaculty',
    element: <FacultyList />,
  },
];
