import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  handleDelete: any;
  isView: boolean;
  toolData: any;
  toolProblemData: any;
  machineData: any;
}

const TBody = ({
  moduleData,
  isView,
  handleDelete,
  toolData,
  toolProblemData,
  machineData,
}: IProps) => {
  return (
    <tbody>
      {moduleData?.map((item: any, index: number) => (
        <TBodyRow
          {...{
            item,
            index,
            isView,
            handleDelete,
            toolData,
            toolProblemData,
            machineData,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
