import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
  ListIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import ViewFile from './components/ViewFile';
import TextAreaInput from '../../../components/common/TextAreaInput';
const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'Name of POKA-YOKE', rowspan: 1, colspan: 1 },
    { text: 'Machine No.', rowspan: 1, colspan: 1 },
    { text: 'Department', rowspan: 1, colspan: 1 },
    { text: 'POKA-YOKE NO.', rowspan: 1, colspan: 1 },
    // { text: 'URL ADDRESS', rowspan: 1, colspan: 1 },
    { text: 'FREQUENCY', rowspan: 1, colspan: 1 },
    { text: 'PROVISIONAL CONTROL', rowspan: 1, colspan: 1 },
    { text: 'REACTION PLAN', rowspan: 1, colspan: 1 },

    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const List = () => {
  const [data, setData] = useState<any>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const search = useQuery('search') || '';
  const sort = useQuery('sort') || 'createdAt';
  const filter = useQuery('filter') || '';
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/listOfPYRoute?page=${page}&sortBy=${
          filter || 'createdAt'
        }&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/listOfPYRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, sort, filter]);

  return (
    <>
      <ChildHeader text="LIST OF POKA YOKE">
        <AddButton onClick={() => setIsModal(true)} label="ADD POKA YOKE" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search POKA YOKE" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.nameofpkyk}</TableCell>
                  <TableCell align="center">
                    {item?.machine_id?.machine_no}
                  </TableCell>
                  <TableCell align="center">
                    {item?.department_id?.department_name}
                  </TableCell>
                  <TableCell align="center">{item?.pkykno}</TableCell>
                  <TableCell align="center">{item?.freq}</TableCell>
                  <TableCell align="center">{item?.provisionalctrl}</TableCell>
                  <TableCell align="center">{item?.reactionplan}</TableCell>

                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {/* <ListIconButton
                        tooltipTitle="FILE LIST"
                        onClick={() => {
                          setId(item._id || null);
                          setIsFileOpenPopup(true);
                        }}
                      /> */}
                      <ViewIconButton
                        tooltipTitle="VIEW"
                        onClick={() => {
                          setComplaintId(item?._id || '');
                          setDisable(true);
                          setIsModal(true);
                        }}
                      />
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setComplaintId(item?._id || '');
                          setDisable(false);
                          setIsModal(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteCustomer(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="ADD POKA-YOKE"
      >
        <Create id={complaintId} setIsModal={setIsModal} disable={isDisable} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};
export default List;
