import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import {
  CustomButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import TController from './components/TController';

export const AppearancePlanMachineHistoryCard = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistofAppearance/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/rejectionNoteRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    await axios
      .put(`/api/masterlistofAppearance/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.histories.splice(rowIdx, 1);
    setModuleData(data);
  };

  console.log('moduleData', moduleData);

  return (
    <>
      <Box>
        <ChildHeader text="MACHINE HISTORY CARD">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.histories.push({});
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <UpdateButton label="Update" onClick={() => handleSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
    </>
  );
};
