import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/create/TController';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import useConfirm from '../../../components/common/useConfirm';

const Create = () => {
  const navigate = useNavigate();
  const { partId, id } = useParams();
  const partName = useQuery('partName') || '';
  const partNumber = useQuery('partNumber');
  const [render, setRender] = useState(false);

  const [moduleData, setModuleData] = useState<any>({
    moduleDate: new Date(),
    part_id: partId || null,
    machine_id: null,
    partName: partName || '',
    partNumber: partNumber || '',
    specs: [],
  });
  const [loader, setLoader] = useState<boolean>(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [machineData, setMachineData] = useState([]);
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/processParameterStdRoute/${id}`)
      .then((res) => {
        setModuleData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const addRows = () => {
    const values = { ...moduleData };
    values.specs.push({
      s_no: `${moduleData.specs.length + 1}`,
      spec_to_inspection: true,
    });
    setModuleData(values);
  };

  const onSubmit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      axios
        .put(`/api/processParameterStdRoute/${id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfull');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/processParameterStdRoute', moduleData)
        .then((res) => {
          if (res?.data) {
            if (res?.data?.isDuplicate) {
              return alert(res?.data?.msg);
            }
            alert('created successfull');
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const upload = (e: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0], 'processParameterStd.xlsx');

    axios
      .post('/api/excelUploadRoute/processParameterStd', formData)
      .then((res) => {
        if (res.data.isExcel) {
          const uploadData = res.data?.specs;
          uploadData.forEach((item: any, index: number) => {
            // item.s_no = moduleData.specs.length + index + 1;
            item.spec_to_inspection = true;
          });
          moduleData.specs = [...uploadData, ...moduleData.specs];
          e.target.value = '';
        } else {
          window.alert(
            'Something thing went wrong. Make sure excel should have .xlsx extension and excel should be editable {should not be secure} '
          );
        }

        setLoader(false);
        setRender((prev) => !prev);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.'
          );
        }
      });
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="Process Parameter Standard">
          {!isView && (
            <>
              <CustomUploadButton lable={'UPLOAD'} onChange={upload} />

              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={addRows}
              >
                ADD ROWS
              </CustomButton>

              <SubmitButton label="Submit" onClick={() => onSubmit()} />
            </>
          )}
        </ChildHeader>
        <TController {...{ moduleData, machineData, isView }} />
      </Box>
      <DialogSubmit />
    </Box>
  );
};

export default Create;
