import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import formatDate from '../../../../components/common/formatDate';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { apiConstant, createConstant } from './constant';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../../../components/common/useConfirm';
import { Loader } from '../../../../components/common/Loader';
import {
  Air_Decay,
  Air_Leaking_Testing,
  Bending_Machine,
  Bending_Machine_01,
  Bending_Machine_02,
  Benjo_Press_Machine,
  Blockage_Testing_Machine,
  Bust_Testing_Machine,
  Centre_Lathe_Machine,
  Clamping,
  Crimping_Machine,
  Cutting_Machine,
  Deburring_Machine,
  Drill_Machine,
  End_Forming_Machine,
  Farule_Fitting_Machine,
  Flushing_Machine,
  Grinding,
  High_Pressure_Leakage,
  Hydraullic_Press_1,
  Hydraullic_Press_2,
  Induction_Brazing_Machine,
  Lazer_Marking,
  Magna_Flux,
  NRV_Testing_Machine,
  Oil_Flushing_Machine,
  ORing_Testing_Machine,
  Oven,
  Pad_Printing,
  Pera_Flange_Machine,
  Pipe_Flairing_Machine,
  Polishing_Machine,
  Radial_Drill_Machine,
  Soco_Cutter_Machine,
  Spot_Welding,
  Ss_Furnace,
  Surface_Grinder,
  Tig_Welding,
  T_Drill_Machine,
  Ultrasonic_1,
  Ultrasonic_2,
} from './allmachineDATA';
import lodash from 'lodash';
// import BarLineChart from './components/Graph/BarLineChart';

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  handelSync: any;
}
const tableType: any = lodash.cloneDeep({
  Spot_Welding: Spot_Welding,
  Grinding: Grinding,
  // Bending_Machine_01: Bending_Machine_01,
  // Induction_Brazing_Machine: Induction_Brazing_Machine,
  // NRV_Testing_Machine: NRV_Testing_Machine,
  // Pera_Flange_Machine: Pera_Flange_Machine,
  // Bending_Machine_02: Bending_Machine_02,
  // Flushing_Machine: Flushing_Machine,
  // Pipe_Flairing_Machine: Pipe_Flairing_Machine,
  // Flushing: Flushing,
  // Leakage_Testing: Leakage_Testing,
  // Pipe_Bending: Pipe_Bending,
  // Ultrasonic: Ultrasonic,
  // Tig_Welding: Tig_Welding,
  // Clamping: Clamping,
  // Ultrasonic_1: Ultrasonic_1,
  // Oven: Oven,
  // Farule_Fitting_Machine: Farule_Fitting_Machine,
  // Magna_Flux: Magna_Flux,
  // Lazer_Marking: Lazer_Marking,
  // Air_Decay: Air_Decay,
  // Soco_Cutter_Machine: Soco_Cutter_Machine,
  // Crimping_Machine: Crimping_Machine,
  // Ss_Furnace: Ss_Furnace,
  // Cutting_Machine: Cutting_Machine,
  // Benjo_Press_Machine: Benjo_Press_Machine,
  // Deburring_Machine: Deburring_Machine,
  // Oil_Flushing_Machine: Oil_Flushing_Machine,
  // Polishing_Machine: Polishing_Machine,
  // End_Forming_Machine: End_Forming_Machine,
  // Pad_Printing: Pad_Printing,
  // Centre_Lathe_Machine: Centre_Lathe_Machine,
  // Hydraullic_Press_1: Hydraullic_Press_1,
  // Radial_Drill_Machine: Radial_Drill_Machine,
  // Ultrasonic_2: Ultrasonic_2,
  // Hydraullic_Press_2: Hydraullic_Press_2,
  // Surface_Grinder: Surface_Grinder,
  // Blockage_Testing_Machine: Blockage_Testing_Machine,
  // Drill_Machine: Drill_Machine,
  // Air_Leaking_Testing: Air_Leaking_Testing,
  // T_Drill_Machine: T_Drill_Machine,
  // High_Pressure_Leakage: High_Pressure_Leakage,
  // Bust_Testing_Machine: Bust_Testing_Machine,
  // ORing_Testing_Machine: ORing_Testing_Machine,
  // Bending_Machine: Bending_Machine,
});
const initialData = {
  checkListMachineData: {
    Spot_Welding: [],
    Grinding: [],
    // Bending_Machine_01: [],
    // Induction_Brazing_Machine: [],
    // NRV_Testing_Machine: [],
    // Pera_Flange_Machine: [],
    // Bending_Machine_02: [],
    // Flushing_Machine: [],
    // Pipe_Flairing_Machine: [],
    // Flushing: [],
    // Leakage_Testing: [],
    // Pipe_Bending: [],
    // Ultrasonic: [],
    // Tig_Welding: [],
    // Clamping: [],
    // Ultrasonic_1: [],
    // Oven: [],
    // Farule_Fitting_Machine: [],
    // Magna_Flux: [],
    // Lazer_Marking: [],
    // Air_Decay: [],
    // Soco_Cutter_Machine: [],
    // Crimping_Machine: [],
    // Ss_Furnace: [],
    // Cutting_Machine: [],
    // Benjo_Press_Machine: [],
    // Deburring_Machine: [],
    // Oil_Flushing_Machine: [],
    // Polishing_Machine: [],
    // End_Forming_Machine: [],
    // Pad_Printing: [],
    // Centre_Lathe_Machine: [],
    // Hydraullic_Press_1: [],
    // Radial_Drill_Machine: [],
    // Ultrasonic_2: [],
    // Hydraullic_Press_2: [],
    // Surface_Grinder: [],
    // Blockage_Testing_Machine: [],
    // Drill_Machine: [],
    // Air_Leaking_Testing: [],
    // T_Drill_Machine: [],
    // High_Pressure_Leakage: [],
    // Bust_Testing_Machine: [],
    // ORing_Testing_Machine: [],
    // Bending_Machine: [],
  },
};

const Create = () => {
  const { id, checklistType } = useParams();
  const date = useQuery('date');
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    `Are you sure you want to submit?`
  );
  // const { /*moduleData isLoading, error,*/ handelSync, Sync }: MyData =
  //   useGetData(id);
  // const [moduleData, setModuleData] = useState<any[]>([{}]);
  const [breakDownTime, setBreakDownTime] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleData, setMoudleData] = useState<any>(initialData);

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (id) {
  //     axios
  //       .get(`/api/${apiConstant.getPlanByid}/${id}`)
  //       .then((res) => {
  //         setModuleData(res.data);
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         setError(err);
  //         setIsLoading(false);
  //       });
  //     axios
  //       .get(`/api/maintenaceLogRoute/getByYear/?date=${date}`)
  //       .then((res) => {
  //         setBreakDownTime(res.data.totalBreakDownTime);
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  //         setError(err);
  //         setIsLoading(false);
  //       });
  //   }
  // }, []);
  useEffect(() => {
    axios
      .get(`/api/preventiveChecklist/getChecklistById/${id}`)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          if (!data.document_name) {
            const values = { ...data };
            if (checklistType)
              values.checkListMachineData[checklistType] =
                tableType[checklistType];
            setMoudleData(values);
          }
          setMoudleData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const onSubmit = async (isSubmitted: boolean) => {
  //   // moduleData.isSubmitted = isSubmitted;
  //   // moduleData.report_prepared_by = state.user?.name;
  //   // console.log('GGGGGGGGGGGGGGGGG', moduleData);
  //   const ans = await confirmSave();
  //   if (!ans) return;

  //   axios
  //     .put(`/api/${apiConstant.updatePlanByid}/${id}`, moduleData)
  //     .then((res) => alert('updated successfully '))
  //     .catch((err) => console.log(err));
  // };
  const handleSubmit = async (status: string) => {
    if (!moduleData.last_maintenance_date) {
      return alert('please choose date field?');
    }
    console.log('ggggggggggggggggggggexexexx', moduleData);
    if (status === 'completed') {
      const ansSubmit = await confirmSubmit();
      if (ansSubmit) {
        moduleData.document_name = checklistType;
        moduleData.status = status;

        axios
          .put(`/api/preventiveChecklist/updateChecklist/${id}`, moduleData)
          .then((res) => {
            window.alert('successfully done ');
          })
          .then(() => {
            // history.goBack();
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    if (status === 'started') {
      const ansSave = await confirmSave();
      if (ansSave) {
        moduleData.document_name = checklistType;
        moduleData.status = status;

        axios
          .put(`/api/preventiveChecklist/updateChecklist/${id}`, moduleData)
          .then((res) => {
            window.alert('successfully done ');
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  function excelDownload() {
    const data = document.getElementById(moduleData.document_type)?.innerHTML;

    axios
      .post(
        `/api/createExcelRoute`,
        { table: data, name: moduleData.document_type },
        { responseType: 'blob' }
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'plan.xlsx');
        // document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const handleChecklist = (e: any, index: number) => {
    const values = { ...moduleData };
    if (checklistType)
      values.checkListMachineData[checklistType][index][e.target.name] =
        e.target.value;
    setMoudleData(values);
  };

  console.log('hgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgj', moduleData);
  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text={`${createConstant.childHeader}`}>
          {/* <SubmitButton label="Submit" onClick={() => onSubmit(true)} /> */}
          {/* <SubmitButton
            label="Excel Download"
            onClick={() => excelDownload()}
          /> */}
          <SubmitButton
            label="Save For Later"
            onClick={() => handleSubmit('started')}
          />
          <SubmitButton
            label="Submit"
            onClick={() => handleSubmit('completed')}
          />
        </ChildHeader>
      </Box>
      <TController
        {...{ moduleData, isView, handleChecklist, checklistType }}
      />
      {checklistType === 'None' && 'NOT AVAILABLE '}
      {/* <Box style={{ paddingBottom: '5rem', width: '50vw' }}>
        <BarLineChart moduleData={moduleData} />
      </Box> */}
      <DialogSave isSubmitContent={false} />
      <DialogSubmit isSubmitContent={false} />
    </Box>
  );
};

export default Create;
