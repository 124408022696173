import { Clause7 } from '../../pages/clause7page';
import ListOfProcedures from '../../pages/listOfProcedures/listOfProcedures';
import List from '../../pages/supplier/List';

export const clause7 = [
  {
    path: 'supplier_control_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'clause7',
    element: <Clause7 />,
  },
  {
    path: 'supplier_list',
    element: <List />,
  },
];
