import { useState } from 'react';

interface IProps {
  apiData: any;
}
const TBody = ({ apiData }: IProps) => {
  const { machineMonthWise } = apiData;
  return (
    <tbody>
      {machineMonthWise &&
        machineMonthWise?.map((item: any, index: number) => (
          <tr
            key={index + 'item'}
            style={index % 2 !== 0 ? { background: '#ced4da' } : {}}
          >
            <td>{index + 1}</td>
            <td>{item?.machineNo}</td>
            <td>{Number(item?.downTime).toFixed(2)}</td>
            <td>{Number(item?.comTime).toFixed(2)}</td>
            <td>{Number(item?.comPerc).toFixed(2)}</td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
