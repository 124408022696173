import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, MenuItem } from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import { FlexBox } from '../../../../components/common';
import CustomSelect from '../../../../components/common/SelectField';
import { handleCpHeaderInputs } from '../../helpers/handleChange';
import { IModuleData } from '../../helpers/Interface';
export interface IProps {
  moduleData: IModuleData;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
  controlPlanId: string | undefined;
}

export default function HeaderInputs({
  moduleData,
  setRender,
  setModuleData,
  controlPlanId,
}: IProps) {
  return (
    <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <InputMuiCustom
            type="number"
            name="revision_no"
            placeholder="Revision Number"
            onChange={(e) => {
              handleCpHeaderInputs(e, moduleData);
              setRender((prev) => !prev);
            }}
            disabled={!controlPlanId}
            value={moduleData.revision_no || 0}
            InputProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePickerMui
            label="Revision Date"
            onChange={(date) => {
              moduleData.revision_date = moment(date, 'MM-DD-YYYY');
              setRender((prev) => !prev);
            }}
            value={moment(moduleData.revision_date) || null}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="control_plan_no"
            placeholder="Control Plan Number"
            onChange={(e) => {
              handleCpHeaderInputs(e, moduleData);
              setRender((prev) => !prev);
            }}
            // disabled={!id}
            value={moduleData.control_plan_no || null}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="latest_change_level"
            placeholder="Latest Change Level"
            onChange={(e) => {
              handleCpHeaderInputs(e, moduleData);
              setRender((prev) => !prev);
            }}
            value={moduleData.latest_change_level || null}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="model"
            placeholder="Model / Vehicle"
            onChange={(e) => {
              handleCpHeaderInputs(e, moduleData);
              setRender((prev) => !prev);
            }}
            // disabled={!id}
            value={moduleData.model || null}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomSelect
            label="select type"
            name="part_type"
            value={moduleData.part_type}
            onChange={(e) => {
              handleCpHeaderInputs(e, moduleData);
              setRender((prev) => !prev);
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="prototype">Prototype</MenuItem>
            <MenuItem value="pre-Launch">Pre-Launch</MenuItem>
            <MenuItem value="production">Production</MenuItem>
          </CustomSelect>
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="end_customer"
            placeholder="End customer"
            onChange={(e) => {
              handleCpHeaderInputs(e, moduleData);
              setRender((prev) => !prev);
            }}
            value={moduleData.end_customer || null}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePickerMui
            label="Supplier Plant Approval date"
            onChange={(date) => {
              moduleData.supplier_approval_date = date;
              setRender((prev) => !prev);
            }}
            value={moment(moduleData.supplier_approval_date) || null}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePickerMui
            label="Other Date If req. Supplier "
            onChange={(date) => {
              moduleData.supplier_other_approval_date = date;
              setRender((prev) => !prev);
            }}
            value={moment(moduleData.supplier_other_approval_date) || null}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePickerMui
            label="Customer Engineering Approval Date(If Reqd)"
            onChange={(date) => {
              moduleData.customer_engg_approval_date = date;
              setRender((prev) => !prev);
            }}
            value={moment(moduleData.customer_engg_approval_date) || null}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePickerMui
            label="Customer Quality Approval date(If Reqd)"
            onChange={(date) => {
              moduleData.customer_quality_approval_date = date;
              setRender((prev) => !prev);
            }}
            value={moment(moduleData.customer_quality_approval_date) || null}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePickerMui
            label="Other Approval Date(If Reqd)"
            onChange={(date) => {
              moduleData.customer_other_approval_date = date;
              setRender((prev) => !prev);
            }}
            value={moment(moduleData.customer_other_approval_date) || null}
          />
        </Grid>
      </Grid>
      <FlexBox style={{ gap: '1rem', margin: '2rem 0.1%' }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={(_) =>
            setModuleData((prev: any) => ({
              ...prev,
              revision_date: null,
              supplier_approval_date: null,
              supplier_other_approval_date: null,
              customer_engg_approval_date: null,
              customer_quality_approval_date: null,
              customer_other_approval_date: null,
            }))
          }
          startIcon={<ClearAllIcon />}
        >
          RESET DATE
        </Button>
        <p style={{ fontSize: '1.2rem', margin: 0 }}>
          Click to clear/reset all dates
        </p>
      </FlexBox>
    </Box>
  );
}
