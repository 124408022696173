import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  autoCompleteMachineValue: any;
  setAutoCompleteMachineValue: any;
  handelDeleteRows: any;
  // isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TController = ({
  moduleData,
  isView,
  handleSumbit,
  autoCompleteMachineValue,
  setAutoCompleteMachineValue,
  handelDeleteRows,
  // isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [machineData, setMachine] = useState({});

  useEffect(() => {
    axios
      .get('/api/machineRoutes/')
      .then((res) => {
        setMachine(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            machineData,
            autoCompleteMachineValue,
            setAutoCompleteMachineValue,
            setIsOpenPopup,
            setPartIdx,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handleSumbit,
            autoCompleteMachineValue,
            handelDeleteRows,
          }}
        />
        <TFoot {...{ moduleData, isView }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
