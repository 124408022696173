export const apiConstant = {
  // list api url
  gettableListDataurl: 'poisonTestPlanRoute/monthlylist',
  deleteListurl: 'poisonTestPlanRoute',
  getListPlanByid: 'poisonTestPlanRoute',
  createListPlanByid: 'poisonTestPlanRoute',
  // -----plan--
  getAllPlanById: 'poisonTestPlanRoute/allplansbyid',
  upadateCreateAllPlan:
    'poisonTestPlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'poisonTestPlanRoute/maintenancePlanDeleteBulkById',
  sync: 'poisonTestPlanRoute/handleSync',
  // ----status---
  getAllStatus: 'poisonTestPlanRoute/getAllSatusByYear',
  upadateListStatus: 'poisonTestPlanRoute/handleStatusUpdate',

  //
  updatePlanByid: 'poisonTestPlanRoute',
  checklistUpload: 'poisonTestPlanRoute/handleUpload',
  checklistDeleteImage: 'poisonTestPlanRoute/handleDeleteImage',
  checklistData: 'poisonTestPlanRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'Poison Test',
};
