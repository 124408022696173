import React from 'react';
import { IModuleData, IProcess, ISpec } from '../../../helpers/Interface';
import { Grid } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Add, ExpandLess, ExpandMore } from '@mui/icons-material';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import { FlexBox } from '../../../../../components/common';
import { spec } from '../../../helpers/initialState';
interface IProps {
  moduleData: IModuleData;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
  setExpandState: React.Dispatch<React.SetStateAction<any>>;
  removeProcess: (index: number) => void;
  process: IProcess;
  index: number;
  handleChangeProcess: (e: any, index: any) => any;
  expandState: any;
}
const AddSpecRow = ({
  moduleData,
  setModuleData,
  removeProcess,
  process,
  index,
  handleChangeProcess,
  setExpandState,
  expandState,
  setRender,
}: IProps) => {
  return (
    <Grid container direction="row" sx={{ alignItems: 'center' }}>
      <Grid item xs={1}>
        <InputMuiCustom
          type="number"
          name="s_no"
          placeholder=""
          onChange={(e) => {
            handleChangeProcess(e, index);
          }}
          value={process.s_no || null}
          InputProps={{
            inputProps: { min: 0 },
          }}
        />
      </Grid>
      <Grid
        item
        xs={2}
        sx={{
          fontSize: '1.8rem',
          textAlign: 'center',
        }}
      >
        {process?.process_no}
      </Grid>
      <Grid item xs={4} sx={{ fontSize: '1.8rem' }}>
        {process?.process_name}
      </Grid>
      <Grid
        item
        xs={5}
        sx={{
          fontSize: '1.6rem',
        }}
      >
        <FlexBox justify="end">
          <FlexBox
            justify="center"
            style={{
              height: '45px',
              width: '175px',
              backgroundColor: '#003566',
              color: 'white',
              cursor: 'pointer',
              display: 'flex',
              gap: '0.5rem',
              marginRight: '0.2rem',
            }}
            onClick={() => {
              const newSpec: ISpec = {
                ...spec,
                s_no: process?.specs.length + 1,
                key: Math.random(),
              };
              moduleData.processes[index].specs.push(newSpec);
              setExpandState((prev: any) => {
                prev[index] = true;
                return [...prev];
              });
            }}
          >
            <Add />
            <span>Add Specs</span>
          </FlexBox>
          {expandState[index] ? (
            <FlexBox
              style={{
                height: '45px',
                width: '130px',
                backgroundColor: '#003566',
                cursor: 'pointer',
                color: 'white',
                alignItems: 'center',
                gap: '0.5rem',
                // justifyContent: "center",
              }}
              onClick={() =>
                setExpandState((prev: any) => {
                  prev[index] = false;
                  return [...prev];
                })
              }
            >
              <ExpandLess style={{ marginLeft: '0.5rem' }} />
              <span>Collapse</span>
            </FlexBox>
          ) : (
            <FlexBox
              style={{
                height: '45px',
                width: '130px',
                backgroundColor: '#003566',
                cursor: 'pointer',
                color: 'white',
                alignItems: 'center',
                gap: '0.5rem',
              }}
              onClick={() =>
                setExpandState((prev: any) => {
                  prev[index] = true;
                  return [...prev];
                })
              }
            >
              <ExpandMore style={{ marginLeft: '0.5rem' }} />
              <span>Expand</span>
            </FlexBox>
          )}
          <FlexBox
            style={{
              height: '45px',
              width: '45px',
              color: '#f1f3f5',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              gap: '0.5rem',
              backgroundColor: '#D70012',
            }}
            justify="center"
            onClick={() => removeProcess(index)}
          >
            <DeleteIcon style={{ color: '#f8f9fa' }} />
          </FlexBox>
        </FlexBox>
      </Grid>
    </Grid>
  );
};

export default AddSpecRow;
