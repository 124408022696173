import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { CiViewList } from 'react-icons/ci';
import { GiBoxUnpacking } from 'react-icons/gi';

const tiles = [
  {
    sNo: 1,
    link: '/common/home/masterList/pfd',
    isLink: true,
    icon: <CiViewList size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'PFD',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/home/masterList/pkgStd',
    isLink: true,
    icon: <GiBoxUnpacking size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Packing Standard',
    isIcon: true,
  },
];

const QualityDeparmentHome = () => {
  return (
    <>
      <ChildHeader text="Master List" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default QualityDeparmentHome;
