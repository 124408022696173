import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import { SelectChangeEvent } from '@mui/material';
import { moduleConstant } from './constant';
import moment from 'moment';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    machine_id: null,
  });
  const [_, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? `Update ` : `Save`} ${moduleConstant?.moduleName}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [machineData, setMachineData] = useState([]);
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`${moduleConstant?.crudApi}/${id}`)
        .then((res) => {
          setModuleData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleMoooduleData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (!moduleData?.gauge_id) {
      alert('Gauge Id is required');
      return;
    }
    if (id) {
      await axios
        .put(`${moduleConstant?.crudApi}/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`${moduleConstant?.crudApi}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'gauge_no'}
        name={'gauge_no'}
        html_for={'gauge_no'}
        label_name={'Gauge No.'}
        input_type={'Number'}
        focusType={'onblur'}
        value={moduleData?.gauge_no}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'gauge_id'}
        name={'gauge_id'}
        html_for={'gauge_id'}
        label_name={'Gauge ID'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.gauge_id}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'range'}
        name={'range'}
        html_for={'range'}
        label_name={'Range'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.range}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'make'}
        name={'make'}
        html_for={'make'}
        label_name={'Make'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.make}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'least_count'}
        name={'least_count'}
        html_for={'least_count'}
        label_name={'Least Count'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.least_count}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.location}
        onChange={handleMoooduleData}
      />
      {/* <AutocompleteGridmui
        label={'Location'}
        id={'machine_no'}
        option_name={'machine_no'}
        value={moduleData.machine_id || ''}
        arrayofObj={machineData}
        onChange={(e, value) => {
          moduleData.machine_id = value;
          setRender((prev) => !prev);
        }}
      /> */}
      <GridInputs
        id={'desc'}
        name={'desc'}
        html_for={'desc'}
        label_name={'Description'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.desc}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'calibration_frequency'}
        name={'calibration_frequency'}
        html_for={'calibration_frequency'}
        label_name={'Calibration  Frequency'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.calibration_frequency}
        onChange={handleMoooduleData}
      />
      <DateGridMui
        lable={`Cali. On`}
        value={
          (moduleData?.calibration_on && moment(moduleData?.calibration_on)) ||
          null
        }
        onChange={(date: any) => {
          moduleData.calibration_on = moment(date._d);
          setRender((prev) => !prev);
        }}
      />
      <DateGridMui
        lable={`Next Due`}
        value={(moduleData?.next_due && moment(moduleData?.next_due)) || null}
        onChange={(date: any) => {
          moduleData.next_due = moment(date._d);
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks}
        onChange={handleMoooduleData}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
