// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import {
  StyledCheckbox,
  StyledDiv,
  StyledSpan,
  StyledTableRow,
} from '../helpers/module.styled';
import TableTextArea from '../../../../components/common/tableinputs/TableTextArea';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { levels } from '../../helpers/constant';
import { LevelSymbolImg } from '../../assets/levelSymbols';

export const TableRowComponent = React.memo(
  ({ key, item, index, updateModuleData, handleDelete }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null,
      ishandleCalc: boolean = false
    ) => {
      updateModuleData(
        'row',
        index,
        fieldName,
        { [fieldName]: value },
        ishandleCalc
      );
    };

    return (
      <React.Fragment key={key}>
        <StyledTableRow index={index}>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="s_no"
              type="number"
              value={item.s_no}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            {isView ? (
              item?.minimum_level
            ) : (
              <CustomSelect
                name="minimum_level"
                value={item.minimum_level || ''}
                onChange={(e: any) => {
                  handleInputChange(e.target.name, e.target.value);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {levels.map((item) => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
              </CustomSelect>
            )}
          </td>
          <td>
            <img
              src={LevelSymbolImg[item?.minimum_level || '']}
              width={50}
              height={'auto'}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="traning_topic"
              type="text"
              value={item.traning_topic}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          {!isView && (
            <td style={{ minWidth: '50px' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handleDelete(index)}
              />
            </td>
          )}
        </StyledTableRow>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
