export const checkListList = {
  childHeader: 'Maintenance STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'poisonTestPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'poisonTestPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'poisonTestPlanStatusRoute/handleUpload',
  checklistDeleteImage:
    'poisonTestPlanStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const navigationConstant = {
  checklistRouter: '/common/poisonTestStatusCheckList',
};
