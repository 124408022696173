// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

export const TableRowComponent = React.memo(
  ({ key, item, index, allmonth, updateModuleData }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td>{index + 1}</td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '400px' }}
              name="checkpoint"
              type="text"
              value={item.checkpoint}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '300px' }}
              name="observation"
              type="text"
              value={item.observation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>

          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '300px' }}
              name="observation_retraining"
              type="text"
              value={item.observation_retraining}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="status"
              type="text"
              value={item.status}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remark"
              type="text"
              value={item.remark}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
        </tr>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
