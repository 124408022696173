import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';

import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import { Checkbox } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
  });
  const [files, setFiles] = useState<any>(null);
  const [images, setImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer Record' : 'Create Customer Record'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [customerData, setCustomerData] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/customerComplaintRecords/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.customer_id) {
      alert('Please enter customer name');
      return;
    }
    if (!data.type_of_complaint) {
      alert('Please enter type of complaint(Uc/Supplier)');
      return;
    }

    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);
    const formData = new FormData();

    if (typeof data.customer_id === 'object') {
      data.customer_id = data.customer_id._id;
    }
    if (typeof data.part_id === 'object') {
      data.part_id = data.part_id._id;
    }
    for (const key in data) {
      if (data[key] || typeof data[key] == 'boolean') {
        if (key !== 'files' && key !== 'images') {
          formData.append(key, data[key]);
        }
      }
    }

    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (images) {
      for (const key of Object.keys(images)) {
        formData.append('images', images[key]);
      }
    }

    if (id) {
      await axios
        .put(`/api/customerComplaintRecords/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/customerComplaintRecords', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridDatePicker
        label_name="Problem Date"
        html_for={'issue_reported_date'}
        value={data?.issue_reported_date || null}
        onChange={(date) => {
          data.issue_reported_date = date;
        }}
      />
      <Grid item xs={3} style={{ marginTop: '10px' }}>
        <AutocompleteGridmui
          id={'customer_name'}
          label={'Customer'}
          option_name={'customer_name'}
          arrayofObj={customerData}
          value={data.customer_name || data.customer_id || ''}
          onChange={(e, value) => {
            data.customer_id = value._id;
            data.customer_name = value;
            setRender((prev) => !prev);
          }}
        />
      </Grid>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part No.'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_number
                  : data?.partNumber || ''}
              </p>
            </Grid>

            {data.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Name'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_name
                  : data?.partName || ''}
              </p>
            </Grid>
          </Grid>
        </div>
      </DivStyled>
      <GridInputs
        id={'problem_description'}
        name={'problem_description'}
        html_for={'problem_description'}
        label_name={'Problem Description:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.problem_description || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'responsibility'}
        name={'responsibility'}
        html_for={'responsibility'}
        label_name={'Responsibility:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.responsibility || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="Target Date"
        html_for={'target_date'}
        value={data?.target_date || null}
        onChange={(date) => {
          data.target_date = date;
        }}
      />
      <GridInputs
        id={'action_taken'}
        name={'action_taken'}
        html_for={'action_taken'}
        label_name={'Action Taken:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.action_taken || ''}
        onChange={handleCustomerData}
      />
      <GridSelect
        name="status"
        label_name="Status"
        html_for={'status'}
        value={data?.status || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="OK">OK</MenuItem>
        <MenuItem value="NG">NG</MenuItem>
      </GridSelect>
      <GridSelect
        name="type_of_complaint"
        label_name="Type of Complaint"
        html_for={'type_of_complaint'}
        value={data?.type_of_complaint || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="UC">UC</MenuItem>
        <MenuItem value="Supplier">Supplier</MenuItem>
      </GridSelect>
      <h1>Effectiveness Monitoring</h1>
      <h2>1st lot: </h2>
      <GridInputs
        id={'first_lot_quantity'}
        name={'first_lot_quantity'}
        html_for={'first_lot_quantity'}
        label_name={'Lot qty.'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.first_lot_quantity || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="Lot Date"
        html_for={'first_lot_date'}
        value={data?.first_lot_date || null}
        onChange={(date) => {
          data.first_lot_date = date;
        }}
      />
      <GridSelect
        name="first_lot_status"
        label_name="Status"
        html_for={'first_lot_status'}
        value={data?.first_lot_status || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="OK">OK</MenuItem>
        <MenuItem value="NG">NG</MenuItem>
      </GridSelect>

      <h2>2nd lot: </h2>
      <GridInputs
        id={'second_lot_quantity'}
        name={'second_lot_quantity'}
        html_for={'second_lot_quantity'}
        label_name={'Lot qty.'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.second_lot_quantity || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="Lot Date"
        html_for={'second_lot_date'}
        value={data?.second_lot_date || null}
        onChange={(date) => {
          data.second_lot_date = date;
        }}
      />
      <GridSelect
        name="second_lot_status"
        label_name="Status"
        html_for={'second_lot_status'}
        value={data?.second_lot_status || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="OK">OK</MenuItem>
        <MenuItem value="NG">NG</MenuItem>
      </GridSelect>

      <h2>3rd lot: </h2>
      <GridInputs
        id={'third_lot_quantity'}
        name={'third_lot_quantity'}
        html_for={'third_lot_quantity'}
        label_name={'Lot qty.'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.third_lot_quantity || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="Lot Date"
        html_for={'third_lot_date'}
        value={data?.third_lot_date || null}
        onChange={(date) => {
          data.third_lot_date = date;
        }}
      />
      <GridSelect
        name="third_lot_status"
        label_name="Status"
        html_for={'third_lot_status'}
        value={data?.third_lot_status || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="OK">OK</MenuItem>
        <MenuItem value="NG">NG</MenuItem>
      </GridSelect>
      <h1></h1>
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleCustomerData}
      />
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Defect photo'}
          multiple
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => {
            if (files) {
              setFiles([...files, ...e.target.files]);
            } else {
              setFiles(e.target.files);
            }
          }}
        />{' '}
      </Grid>
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Attach Supporting Doucments'}
          multiple
          accept="application/pdf,application/doc,application/excel/,application/ppt"
          onChange={(e) => {
            if (files) {
              setFiles([...files, ...e.target.files]);
            } else {
              setFiles(e.target.files);
            }
          }}
        />{' '}
      </Grid>
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
