import { GrTest } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { GiSkills } from 'react-icons/gi';

export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    // link: 'https://uc.secure.aqua.mushinlabs.com',
    link: '/common/SkillEvaluationList',
    isLink: true,
    // target: '_blank',
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'SKILL REQUIREMENT',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 1,
    // link: 'https://uc.secure.aqua.mushinlabs.com',
    link: '/common/skillMatrixList',
    isLink: true,
    // target: '_blank',
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'SKILL',
    span: 'MATRIX',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/clauseUploadList?documentType=skillEvaluationClause5&heading=SKILL EVALUATION ANSWER SHEET',
    isLink: true,
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'SKILL EVALUATION',
    span: 'ANSWER SHEET',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/skillEvaluationTestPaperHome',
    isLink: true,
    p: 'SKILL EVALUATION TEST',
    span: 'PAPER',
    isIcon: true,
    icon: <GiSkills size="5rem" color="#135089" />,
  },
];

const SkillEvaluationHome = () => {
  return (
    <>
      <ChildHeader text="TRAINING" />
      <RenderTiles tilesObj={tiles} height={'100vh'} justify="start" />
    </>
  );
};

export default SkillEvaluationHome;
