import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import tiles from './Tiles';
const Production = () => {
  return (
    <>
      <ChildHeader text="PRODUCTION MANAGEMENT" />
      <RenderTiles tilesObj={tiles} height="100%" />
    </>
  );
};

export default Production;
