import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../../components/common/useConfirm';
import moment from 'moment';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import GridSelect from '../../../components/common/inputs/GridSelect';
import { SelectChangeEvent } from '@mui/material';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';

const ModalInput = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    title: '',
    departments: {
      department_name: '',
    },
    created_year: new Date(),
    processes: [],
  });

  const [render, setRender] = useState(false);
  const [autoCompleteDepartment, setAutocompleteDepartment] = useState('');
  const [department, setDepartment] = useState('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update' : 'Submit'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setAutocompleteDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(
          `/api/annualTrainingPlan/getAnnualTrainingById/${id}?onlyFields=${true}`
        )
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleTopicPaper(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      axios
        .put(`/api/annualTrainingPlan/updateAnnualTraining/${id}`, moduleData)
        .then((res) => {
          window.alert('success');
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`/api/annualTrainingPlan/createAnnualTraining`, moduleData)
        .then((res) => {
          window.alert('success');
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title}
        onChange={handleTopicPaper}
      />
      <AutocompleteGridmui
        label={'Select Department'}
        id={'department_name'}
        option_name={'department_name'}
        value={department || moduleData?.departments || ''}
        arrayofObj={autoCompleteDepartment}
        onChange={(e, value) => {
          moduleData.departments = value?._id;
          //moduleData.area_of_work_id = value;
          setDepartment(value);
        }}
      />
      <GridDatePicker
        label_name="Select Year:"
        html_for={'date_range'}
        views={['year']}
        value={moduleData?.created_year || null}
        onChange={(date) => {
          moduleData.created_year = moment(date._d).add(10, 'hours');
          setRender((prev) => !prev);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
