export const listConstant = {
  childHeader: 'Operator Observance list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Operator Observance list',
  theadHeader: 'Operator Observance',
  extracolSpan: 3,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'operatorObservancePlanRoute/monthlylist',
  deleteListurl: 'operatorObservancePlanRoute',
  getListPlanByid: 'operatorObservancePlanRoute',
  createListPlanByid: 'operatorObservancePlanRoute',

  // -----plan--
  sync: 'operatorObservancePlanRoute/handleSync',
  getAllPlanById: 'operatorObservancePlanRoute/allplansbyid',
  upadateCreateAllPlan:
    'operatorObservancePlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'operatorObservancePlanRoute/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'Operator Observance',
};

export const navigationConstant = {
  planRouter: '/common/operatorObservancePlanCreate',
};
