import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  autoCompleteMachineValue: any;
  setAutoCompleteMachineValue: any;
}

const theadData = [
  'Date',
  'Shift A/B ',
  'Operator Name',
  'Part Name/ No.',
  'Rotor ID',
  'Batch No.',
  'Material Size',
  'Material Grade',
  'Supplier Name ',
  'Time',
  'Counter',
  'No. of Rows',
  'Total Strokes ',
  'OK Strokes',
  'Rejected Strokes ',
  'SPM',
  'Total run time (Hrs) ',
  'Average Strokes per Hrs',
  'Standard Weight (kg)',
  'Production Weight (KG)',
  'Ok Weight (kg)',
  'Rejected Weight (KG)',
  'Verified By Sign.',
];

const THead = ({
  moduleData,
  isView,
  machineData,
  autoCompleteMachineValue,
  setAutoCompleteMachineValue,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={27}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-PROD-02A', '01', '01.04.2023']}
        heading="Daily Production Report - Press Shop"
      />
      <tr>
        <th colSpan={3}>Daily Production Date : </th>
        <th colSpan={3} align="left">
          {isView ? (
            formatDate(moduleData.dailyProduction_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyProduction_date)}
              onChange={(date) => {
                moduleData.dailyProduction_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={21}></th>
      </tr>
      <tr>
        <th colSpan={3}>Machine No. : </th>
        <th colSpan={3} align="left">
          {isView ? (
            moduleData?.mc_no?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={autoCompleteMachineValue}
              onChange={(e, value) => {
                if (!value) {
                  moduleData.mc_no = null;
                }
                moduleData.mc_no = value?._id;
                setAutoCompleteMachineValue(value);
              }}
            />
          )}
        </th>
        <th colSpan={21}></th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            rowSpan={heading === 'Time' || heading === 'Counter' ? 1 : 2}
            colSpan={heading === 'Time' || heading === 'Counter' ? 2 : 1}
          >
            {heading}
          </th>
        ))}
        <th colSpan={2} rowSpan={2}>
          Remarks
        </th>
      </tr>
      <tr>
        <th>Start</th>
        <th>Stop</th>
        <th>Start</th>
        <th>Stop</th>
      </tr>
    </thead>
  );
};

export default THead;
