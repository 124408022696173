import BarLineChart from '../../../../../components/common/chart/BarLineChart';
import TConroller from './TController';

interface IProps {
  apiData: any;
}

const MonthlyMachineWise = ({ apiData }: IProps) => {
  const { machineMonthWise } = apiData;
  const machineNames =
    machineMonthWise?.map((item: any) => item.machineNo) || [];
  const downTimes = machineMonthWise?.map((item: any) => item.downTime) || [];
  const comTimes = machineMonthWise?.map((item: any) => item.comTime) || [];
  const comPercs = machineMonthWise?.map((item: any) => item.comPerc) || [];
  return (
    <div>
      <BarLineChart
        datas={downTimes}
        yTitle={'DOWN TIME (HRS)'}
        xTitle={'MACHINE'}
        labels={machineNames}
        type={'bar'}
        borderTitle={'MACHINE WISE B/D PARETO CHART'}
        headTitle={`DOWN TIME (HRS)`}
        borderColor={'#38d9a9'}
        backgroundColor={'#1864ab'}
        borderWidth={2}
        fill={true}
        width={'80vw'}
        margin={'14rem'}
        otherType={'line'}
        otherLabel={`COM. %`}
        otherData={comPercs}
        otherBorderColor={'#c92a2a'}
        otherFill={true}
        otherBorderWidth={2}
      />
      <TConroller apiData={apiData} />
    </div>
  );
};

export default MonthlyMachineWise;
