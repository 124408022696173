import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import formatDate from '../../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
  toolData: any;
  toolProblemData: any;
  machineData: any;
}

const TBodyRow = ({
  item,
  index,
  isView,
  handleDelete,
  toolData,
  toolProblemData,
  machineData,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);
  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
    setRowRender((prev: any) => !prev);
  };

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td style={{ textAlign: 'center', minWidth: '160px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.breakdown_date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={
              (item?.breakdown_date && moment(item?.breakdown_date)) || null
            }
          />
        </td>
        <td style={{ minWidth: '160px' }}>
          {isView ? (
            item?.tool_id?.die_name
          ) : (
            <AutocompleteMuiCustom
              id="tool_id"
              label=""
              option_name="die_name"
              arrayofObj={toolData}
              value={item.tool_id || ''}
              onChange={(e, value) => {
                item.tool_id = value;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ minWidth: '160px' }}> {item?.tool_id?.die_number}</td>
        <td style={{ minWidth: '160px' }}>
          {isView ? (
            item?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="machine_no"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={item.machine_id || ''}
              onChange={(e, value) => {
                item.machine_id = value;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ minWidth: '200px' }}>
          {isView ? (
            item?.tool_problem_id?.problem
          ) : (
            <AutocompleteMuiCustom
              id="problem"
              label=""
              option_name="problem"
              arrayofObj={toolProblemData}
              value={item.tool_problem_id || ''}
              onChange={(e, value) => {
                item.tool_problem_id = value;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ textAlign: 'center', minWidth: '200px', width: '200px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="action_taken"
            value={item?.action_taken ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="breakdown_time"
            value={item?.breakdown_time ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        {/* <td style={{ minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="breakdown_time_to"
            value={item?.breakdown_time_to ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td> */}
        {!isView && (
          <td style={{ textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(item?._id, index);
              }}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
