const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    {
      text: 'S.No.',
      style: { ...style1, minWidth: '90px', width: '80px' },
    },
    {
      text: 'Current Skill Level',
      style: { ...style1, minWidth: '300px', width: '300px' },
    },
    {
      text: 'Minimum Skill Required',
      style: { ...style1, minWidth: '300px', width: '300px' },
    },
    { text: 'Symbol', style: { ...style1, minWidth: '150px', width: '130px' } },
    { text: 'Training Topic', style: style1 },
  ],
];

export const listConstant = {
  childHeader: 'List of Training Topics',
  addButtonLable: 'New Topic',
};

export const headerConstant = {
  tableCompHeadTitle: 'Training Topics SHEET',
};

export const apiConstant = {
  getById: 'employeeTniTopicRoute',
  post: 'employeeTniTopicRoute',
  update: 'employeeTniTopicRoute',
  delete: 'employeeTniTopicRoute',
  getTniTopicById: 'tniTopicRoute',
};

export const tilesConstant = {
  childHeaderText: 'Training Topics SHEET',
};

export const navigationConstant = {
  createEditChecklist: '/common/empTraningTopicsCreateEdit',
};
