export const listConstant = {
  childHeader: 'Layout Plan list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Layout Plan list',
  theadHeader: ' Layout Plan',
  extracolSpan: 4,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'layoutPlanNewRoute/monthlylist',
  deleteListurl: 'layoutPlanNewRoute',
  getListPlanByid: 'layoutPlanNewRoute',
  createListPlanByid: 'layoutPlanNewRoute',

  // -----plan--
  sync: 'layoutPlanNewRoute/handleSync',
  getAllPlanById: 'layoutPlanNewRoute/allplansbyid',
  upadateCreateAllPlan: 'layoutPlanNewRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'layoutPlanNewRoute/maintenancePlanDeleteBulkById',
};

export const settingPanelConstant = {
  // settingPanel setting for plan
  crudApi: 'layoutPlanNewSettingRoute',
  docType: 'layoutPlanNew',
};

export const tilesConstant = {
  childHeaderText: 'Layout Plan',
};

export const navigationConstant = {
  planRouter: '/common/layoutPlanNewCreate',
};
