import moment from 'moment';
import { useState } from 'react';
import { FlexBox, TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';
import CustomSelect from '../../../../../components/common/SelectField';
import MenuItem from '@mui/material/MenuItem';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={3 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['F-HRD-08', '02', '01-12-2019']}
        heading={`${createConstant.theadHeader}`}
      />
      <tr>
        <th
          style={{
            border: '1px solid black',
            textTransform: 'uppercase',
          }}
          colSpan={2}
          align="left"
        >
          REVIEWED DATE :{formatDate(moduleData?.reviewed_on_date)}
        </th>
        <th
          style={{
            border: '1px solid black',
            textTransform: 'uppercase',
          }}
          align="left"
        >
          NEXT REV: {formatDate(moduleData?.next_reviewed_on_date)}
        </th>
      </tr>
      <tr>
        <th
          style={{
            border: '1px solid black',
            textTransform: 'uppercase',
          }}
          align="left"
          colSpan={3}
        >
          DEPT. NAME: {moduleData?.department_id?.department_name}
        </th>
      </tr>
      <tr>
        <th
          style={{
            border: '1px solid black',
            textTransform: 'uppercase',
          }}
          align="left"
          colSpan={3}
        >
          NAME OF DEPT. HEAD : {moduleData?.department_id?.hod}
        </th>
      </tr>
      <tr>
        {['S. NO.', 'NAME', 'Activities'].map((el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            rowSpan={index === 2 ? 1 : 2}
            colSpan={1}
            style={{
              border: '1px solid black',
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {el}
          </th>
        ))}
      </tr>
      <tr>
        <th
          colSpan={1}
          style={{
            border: '1px solid black',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          Turner & Die Fitter
        </th>
      </tr>
    </thead>
  );
};

export default THead;
