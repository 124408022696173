const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1, rowspan: 2 },
    {
      text: 'Check Sheet',
      style: { ...style1, minWidth: '400px' },
      rowspan: 2,
    },
    { text: '1st Week', style: style1, rowspan: 1, colspan: 3 },
    { text: '2nd Week', style: style1, rowspan: 1, colspan: 3 },
    { text: '3rd Week', style: style1, rowspan: 1, colspan: 3 },
    { text: '4th Week', style: style1, rowspan: 1, colspan: 3 },
    {
      text: 'Action',
      style: { ...style1, backgroundColor: '#c92a2a' },
      rowspan: 2,
    },
  ],
  [
    { text: 'OK', style: style1, rowspan: 1 },
    { text: 'NOT OK', style: style1, rowspan: 1 },
    { text: 'Remarks', style: style1, rowspan: 1 },
    { text: 'OK', style: style1, rowspan: 1 },
    { text: 'NOT OK', style: style1, rowspan: 1 },
    { text: 'Remarks', style: style1, rowspan: 1 },
    { text: 'OK', style: style1, rowspan: 1 },
    { text: 'NOT OK', style: style1, rowspan: 1 },
    { text: 'Remarks', style: style1, rowspan: 1 },
    { text: 'OK', style: style1, rowspan: 1 },
    { text: 'NOT OK', style: style1, rowspan: 1 },
    { text: 'Remarks', style: style1, rowspan: 1 },
  ],
];

export const listConstant = {
  childHeader: 'Instrument Checksheet Formats list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'Instrument Checksheet Formats',
};

export const tilesConstant = {
  childHeaderText: 'Instrument Checksheet Formats',
};

export const navigationConstant = {
  createEditChecklist: '/common/checkSheetFormatsChecklistCreate',
};

export const apiConstant = {
  get: 'masterCheckListRouteCheckSheetFormats',
  getById: 'masterCheckListRouteCheckSheetFormats',
  post: 'masterCheckListRouteCheckSheetFormats',
  update: 'masterCheckListRouteCheckSheetFormats',
  delete: 'masterCheckListRouteCheckSheetFormats',
  instrumentCategoryForAutoComplete:
    'masterlistinstrument/instrumentcategory/forAutocomplete',
};
