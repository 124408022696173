export const checkListList = {
  childHeader: 'MSA Plan STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'attributeMsaPlanNewStatusRoute/getAllSatusByYear',
  upadateListStatus: 'attributeMsaPlanNewStatusRoute/handleStatusUpdate',
  checklistUpload: 'attributeMsaPlanNewStatusRoute/handleUpload',
  checklistDeleteImage: 'attributeMsaPlanNewStatusRoute/handleDeleteImage',
  forAutoComplete: '/api/defectRoutes/defectList/defectForAutoComplete',
};

export const navigationConstant = {
  checklistRouter: '/common/attributeMsaPlanNewStatusCheckList',
};
