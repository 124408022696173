export const listConstant = {
  childHeader: 'DIE PM list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'DIE PM PLAN list',
  theadHeader: 'DIE PM PLAN',
  extracolSpan: 3,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'diePmPlanRoute/monthlylist',
  deleteListurl: 'diePmPlanRoute',
  getListPlanByid: 'diePmPlanRoute',
  createListPlanByid: 'diePmPlanRoute',

  // -----plan--
  sync: 'diePmPlanRoute/handleSync',
  getAllPlanById: 'diePmPlanRoute/allplansbyid',
  upadateCreateAllPlan: 'diePmPlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById: 'diePmPlanRoute/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'DIE PM PLAN',
};

export const navigationConstant = {
  planRouter: '/common/diePmPlan_PlanCreate',
};
