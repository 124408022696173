import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  updateModuleData: any;
  handleDelete: any;
  machineData: any;
  department: any;
}

const TController = ({
  handleDelete,
  moduleData,
  updateModuleData,
  machineData,
  department,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ machineData, moduleData, updateModuleData, department }} />
        <TBody
          {...{
            handleDelete,
            moduleData,
            updateModuleData,
          }}
        />
        <TFoot
          {...{
            moduleData,
            updateModuleData,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
