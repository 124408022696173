import {
  AiOutlineCloudUpload,
  AiOutlineDeliveredProcedure,
  AiOutlineFundView,
} from 'react-icons/ai';

import { BsFillEaselFill } from 'react-icons/bs';
import { GrTest } from 'react-icons/gr';
import { ImUserTie } from 'react-icons/im';

const tiles = [
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=masterProcedureClause5&heading=MASTER PROCEDURE',
  //   isLink: true,
  //   clauseNo: 5,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'MASTER',
  //   span: 'PROCEDURE',
  //   isIcon: true,
  // },
  {
    sNo: 1,
    link: '/admin/education_training_listOfProcedures/?documentType=education_training&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 1,
    // link: 'https://uc.secure.aqua.mushinlabs.com/',
    link: '/admin/masterlistoffaculty?filter=createdAt',
    isLink: true,
    // target: '_blank',
    p: 'Master List Of',
    span: 'FACULTY',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
  {
    sNo: 2,
    link: '/common/masterlistofdepartment?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    p: 'Master List Of',
    span: 'Department',
    icon: <AiOutlineFundView size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/admin/employee_list?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'EMPLOYEE',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
  {
    sNo: 1,
    link: '/common/effectivenessTestPaperHome',
    isLink: true,
    p: 'EFFECTIVENESS TEST',
    span: 'PAPER',
    isIcon: true,
    icon: <GrTest size="5rem" color="#135089" />,
  },
  {
    sNo: 1,
    link: '/common/trainingTniHome',
    isLink: true,
    p: 'TRAINING',
    span: '',
    isIcon: true,
    icon: <BsFillEaselFill size="5rem" color="#135089" />,
  },
  {
    sNo: 1,
    link: '/common/skillEvaluationHome',
    isLink: true,
    p: 'SKILL',
    span: 'EVALUATION',
    isIcon: true,
    icon: <BsFillEaselFill size="5rem" color="#135089" />,
  },
];

export default tiles;
