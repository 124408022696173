import React from 'react';
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import { FaBold, FaItalic, FaUnderline, FaStrikethrough, FaHeading, FaListOl, FaListUl, FaQuoteLeft, FaParagraph} from 'react-icons/fa6';
import { LuHeading1, LuHeading2, LuHeading3, LuHeading4, LuHeading5, LuHeading6 } from "react-icons/lu";
import { VscHorizontalRule } from "react-icons/vsc";
import { MdOutlineWrapText } from "react-icons/md";
import { FaRedo, FaUndo } from 'react-icons/fa'
import { useEffect } from 'react';
import { useCallback, useState } from 'react';

type MenuBarProps = {
  editor: Editor | null;
};

const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="control-group" style={{ display:'flex', justifyContent:'center', border:'none'}}>
      <div className="button-group" style={{backgroundColor:'white', borderRadius:'10px', marginBottom:'10px', maxWidth:'600px', minWidth:'600px', paddingLeft:'10px', paddingRight:'10px', paddingTop:'5px', paddingBottom:'4px'}}>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <FaBold />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <FaItalic />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          }
          className={editor.isActive('underline') ? 'is-active' : ''}
        >
          <FaUnderline />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          <FaStrikethrough />
        </button>
        
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          <FaParagraph />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          <LuHeading1 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          <LuHeading2 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          <LuHeading3 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          <LuHeading4 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          <LuHeading5 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          <LuHeading6 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <FaListUl />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <FaListOl />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          <FaQuoteLeft />
        </button>
        {/*<button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <VscHorizontalRule />
        </button>*/}
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().setHardBreak().run()}>
          <MdOutlineWrapText />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().undo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .undo()
              .run()
          }
        >
          <FaUndo />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', marginTop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().redo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .redo()
              .run()
          }
        >
          <FaRedo />
        </button>
      </div>
    </div>
  );
};

type TipTapProps = {
  setDesc: (desc: string) => void;
  initialContent: string; 
};

const TipTap: React.FC<TipTapProps> = ({ setDesc, initialContent  }) => {
  const [localContent, setLocalContent] = useState(initialContent);
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },
        orderedList: { keepMarks: true, keepAttributes: false },
      }),
      Table.configure({
        resizable: false,
      }),
      TableRow,
      TableHeader,
      TableCell,
      TextStyle,
      Color,
      Underline,
    ],
    content: localContent,
    
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setLocalContent(html)
    },
  });

  useEffect(() => {
    if (editor && initialContent !== localContent) {
      editor.commands.setContent(initialContent);
      setLocalContent(initialContent);
    }
  }, [editor, initialContent]);

  const handleBlur = useCallback(() => {
    setDesc(localContent);
  }, [setDesc, localContent]);

  if (!editor) {
    return null;
  }

  return (
    <div onBlur={handleBlur}>
      <MenuBar editor={editor} />
      <div className="control-group" style={{ display:'flex', justifyContent:'center', border:'none'}}>
        <div className="button-group"  style={{ borderRadius:'5px', marginBottom:'10px',  minWidth:'560px', paddingLeft:'10px', paddingRight:'10px', display:"flex", justifyContent:'center'}}>
          {/*<button onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}>
            Insert table
          </button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().addColumnBefore().run()}>Add column before</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().addColumnAfter().run()}>Add column after</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().deleteColumn().run()}>Delete column</button>*/}
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' ,padding:'10px', borderRadius:'10px', margin:'5px', paddingLeft:'15px', paddingRight:'15px'}} onClick={() => editor.chain().focus().addRowBefore().run()}>ADD ROW BEFORE</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' ,padding:'10px', borderRadius:'10px', margin:'5px', paddingLeft:'15px', paddingRight:'15px'}} onClick={() => editor.chain().focus().addRowAfter().run()}>ADD ROW AFTER</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' ,padding:'10px', borderRadius:'10px', margin:'5px', paddingLeft:'15px', paddingRight:'15px'}} onClick={() => editor.chain().focus().deleteRow().run()}>DELETE ROW</button>
          {/*<button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().deleteTable().run()}>Delete table</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().mergeCells().run()}>Merge cells</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().splitCell().run()}>Split cell</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().toggleHeaderColumn().run()}>Toggle header column</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().toggleHeaderRow().run()}>Toggle header row</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().toggleHeaderCell().run()}>Toggle header cell</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().mergeOrSplit().run()}>Merge or split</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().setCellAttribute('colspan', 2).run()}>Set cell attribute</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' }} onClick={() => editor.chain().focus().fixTables().run()}>Fix tables</button>*/}
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' ,padding:'10px', borderRadius:'10px', margin:'5px', paddingLeft:'15px', paddingRight:'15px'}} onClick={() => editor.chain().focus().goToNextCell().run()}>NEXT CELL</button>
          <button style={{fontSize:'14px', outline:'none', border:'none', background:'white', marginTop:'5px', marginBottom:'2px' ,padding:'10px', borderRadius:'10px', margin:'5px', paddingLeft:'15px', paddingRight:'15px'}} onClick={() => editor.chain().focus().goToPreviousCell().run()}>PREVIOUS CELL</button>
        </div>
      </div>
      <EditorContent editor={editor} />
    </div>
  );
};

export default TipTap;
