import { MenuItem } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import CustomSelect from '../../../../../components/common/SelectField';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Dated']}
        values={['F-PROD-08B', '01', '01.04.2021']}
        heading="Process Validation Sheet"
      />

      <tr>
        <th colSpan={3} align="left">
          Part Name:&emsp;
          {moduleData?.process_std_id?.part_id?.part_name ||
            moduleData?.partName}
        </th>
        <th colSpan={6} align="left">
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            Date:
            {isView ? (
              formatDate(moduleData.moduleData_date)
            ) : (
              <DatePickerMui
                label=""
                value={moment(moduleData.moduleData_date)}
                onChange={(date) => {
                  moduleData.moduleData_date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </div>
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Part No.:&emsp;
          {moduleData?.process_std_id?.part_id?.part_number ||
            moduleData?.partNumber}
        </th>
        <th colSpan={6} align="left">
          Machine No:&emsp;
          {moduleData?.process_std_id?.machine_id?.machine_no ||
            moduleData?.machineNo}
        </th>
      </tr>
      <tr>
        {['S.NO', 'M/C Parameter', 'Specification', 'Insp. Method'].map(
          (head) => (
            <th style={{ textTransform: 'uppercase' }} rowSpan={2}>
              {head}
            </th>
          )
        )}
        {Array.from({ length: 5 }, (_, index) => (
          <th
            key={index + 'Heading'}
            style={{ minWidth: '160px', width: '160px' }}
          >
            {isView ? (
              formatDate(moduleData?.date_obs[index])
            ) : (
              <DatePickerMui
                value={
                  (moduleData?.date_obs[index] &&
                    moment(moduleData?.date_obs[index])) ||
                  null
                }
                onChange={(date) => {
                  moduleData.date_obs[index] = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </th>
        ))}

        {/* <th style={{ textTransform: 'uppercase' }}>Status</th> */}
        {/* <th style={{ textTransform: 'uppercase', width: '13%' }}>Remarks</th> */}
      </tr>
      <tr>
        {Array.from({ length: 5 }, (_, index) => (
          <th
            key={index + 'Heading'}
            style={{ minWidth: '160px', width: '160px' }}
          >
            {'T' + (index + 1)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
