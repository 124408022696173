import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [tools, setTools] = useState([]);
  useEffect(() => {
    axios
      .get('/api/masterListOfToolsOrDieRoute/toolsList/toolsForAutoComplete')
      .then((res) => {
        if (res.data) setTools(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-PROD-22B', '12', '18.01.2023']}
        heading="Before Loading Die Checksheet  (PDC)"
      />
      <tr>
        <th colSpan={2} align="left">
          Die Name:
          {isView ? (
            moduleData?.tool_id?.die_name
          ) : (
            <AutocompleteMuiCustom
              id="Tools"
              label=""
              option_name="die_name"
              key={'tools'}
              arrayofObj={tools}
              value={moduleData?.tool_id || ''}
              onChange={(e, value) => {
                moduleData.tool_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          Department: {moduleData?.tool_id?.department_id?.department_name}
        </th>
        <th colSpan={2} align="left">
          Frequency/No. of shots:
          {moduleData?.tool_id?.frequency}
        </th>
        <th colSpan={1}>
          Date:
          {isView ? (
            formatDate(moduleData.checklist_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.checklist_date)}
              onChange={(date) => {
                moduleData.checklist_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {[
          'S.NO',
          'CHECK POINTS',
          'STANDARD',
          'ACTION IF NOT AS PER STANDARD',
          'A / NA',
          'METHOD',
          'BEFORE',
          'AFTER',
        ].map((head) => (
          <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
