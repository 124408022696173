import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../../../../components/common/button/CustomButtons';
import SelectGridMui from '../../../../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../../../../components/common/useConfirm';
import moment from 'moment';
import DateGridMui from '../../../../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../../../../components/common/autocomplete/AutocompleteGridmui';
import GridSelect from '../../../../../components/common/inputs/GridSelect';
import { SelectChangeEvent } from '@mui/material';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

const ModalInput = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    topic_paper_name: '',
    area_of_work: {
      department_name: '',
    },
    topics: [],
  });

  const [render, setRender] = useState(false);
  const [autoCompleteDepartment, setAutocompleteDepartment] = useState('');
  const [department, setDepartment] = useState('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update' : 'Submit'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setAutocompleteDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/tni/getOneTopicPaper/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleTopicPaper(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      axios
        .patch(`/api/tni/patchTopicPaper/${id}`, moduleData)
        .then((res) => {
          window.alert('success');
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      // moduleData['topics'] = new Array(4).fill(0).map((_, index) => {
      //   return { levels_type: `Level_${index + 1}`, question: [] };
      // });

      axios
        .post(`/api/tni/createTopicPaper`, moduleData)
        .then((res) => {
          window.alert('success');
          setIsModal(false);
        })
        .catch((err) => {
          if (err.response.data.error.message.includes('E11000')) {
            window.alert('Can not create duplicate area of work');
          }
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'topic_paper_name'}
        name={'topic_paper_name'}
        html_for={'topic_paper_name'}
        label_name={'Topic Paper Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.topic_paper_name}
        onChange={handleTopicPaper}
      />
      <AutocompleteGridmui
        label={'Department'}
        id={'department_name'}
        option_name={'department_name'}
        value={department || moduleData?.area_of_work || ''}
        arrayofObj={autoCompleteDepartment}
        onChange={(e, value) => {
          moduleData.area_of_work = value?._id;
          //moduleData.area_of_work_id = value;
          setDepartment(value);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
