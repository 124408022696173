import moment from 'moment';

export const processes = [
  {
    agenda_point_discussed: '',
    discussion: '',
    action_taken: '',
    proposed_completion_date: '',
    to_be_verified: '',
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  no_of_rows: 5,
  //is_admin_request_no: null,
  //is_admin_request: null,
  //report_prepared_by: null,
  //report_approved_by: null,
  //moduleData_Date: moment(),
  meeting_agenda: '<h1></h1>',
  list_of_invitees: '<h1></h1>',
  management_representative: '',
  mrm_minutes:'<table style="width: 1392px"><colgroup><col style="width: 58px"><col style="width: 223px"><col style="width: 283px"><col style="width: 258px"><col style="width: 221px"><col style="width: 177px"><col style="width: 172px"></colgroup><tbody><tr><th colspan="1" rowspan="1" colwidth="58"><p>S.NO.</p></th><th colspan="1" rowspan="1" colwidth="223"><p>AGENDA POINT DISCUSSED</p></th><th colspan="1" rowspan="1" colwidth="283"><p>DISCUSSION</p></th><th colspan="1" rowspan="1" colwidth="258"><p>ACTION TAKEN</p></th><th colspan="1" rowspan="1" colwidth="221"><p>PROPOSED COMPLETION DATE</p></th><th colspan="1" rowspan="1" colwidth="177"><p>TO BE VERIFIED</p></th><th colspan="1" rowspan="1" colwidth="172"><p>ANY OTHER REMARKS</p></th></tr><tr><td colspan="1" rowspan="1" colwidth="58"><p></p></td><td colspan="1" rowspan="1" colwidth="223"><p></p></td><td colspan="1" rowspan="1" colwidth="283"><p></p></td><td colspan="1" rowspan="1" colwidth="258"><p></p></td><td colspan="1" rowspan="1" colwidth="221"><p></p></td><td colspan="1" rowspan="1" colwidth="177"><p></p></td><td colspan="1" rowspan="1" colwidth="172"><p></p></td></tr><tr><th colspan="2" rowspan="1" colwidth="58,223"><p>MANAGEMENT REPRESENTATIVE:</p></th><td colspan="5" rowspan="1" colwidth="283,258,221,177,172"><p></p></td></tr></tbody></table>',
  module_date: moment(),
  mrm_date: null,
  processes: Array.from({ length: 5 }, () => ({ ...processes[0] })),
};
