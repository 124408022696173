import moment from 'moment';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot style={{ background: '#a9e34b' }}>
      <tr>
        <th colSpan={4} align="left">
          प्रिवेंटिव मेंटेनेंस करने वाले का नाम
          <TableInput
            isView={isView}
            name="prepared_by"
            type="text"
            value={moduleData.prepared_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={10}></th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          प्रिवेंटिव मेंटेनेंस चेक करने वाले का नाम
          <TableInput
            isView={isView}
            name="checked_by"
            type="text"
            value={moduleData.checked_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={10}></th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
