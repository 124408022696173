import moment from 'moment';

export const processes = [
  {
    spare_part_id: null,
    breakdown_details: '',
    action_taken: '',
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  moduleData_Date: moment(),
  type_of_breakdown: '', // option mechanical , electrical , electronic , fixture
  machine_id: null,
  tool_id: null,
  department_id: null,
  time: null,
  //footer
  result: '', // options ---> satisfactory , not satisfactory , under observation
  sign_sup_prod: '',
  sign_sup_qa: '',
  sign_sup_maintenance: '',

  processes: Array.from({ length: 1 }, () => ({ ...processes[0] })),
};
