// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

export const TableRowComponent = React.memo(
  ({ key, item, index, allmonth, updateModuleData, handleDelete }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td>{index + 1}</td>
          <td>
            <TableInput
              isView={true}
              style={{}}
              name="checkpoint"
              type="text"
              value={item.checkpoint}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={true}
              style={{}}
              name="frequency"
              type="text"
              value={item.frequency}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={true}
              style={{}}
              name="standard"
              type="text"
              value={item.standard}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={true}
              style={{}}
              name="action_not_per_standard"
              type="text"
              value={item.action_not_per_standard}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="a_na"
              type="text"
              value={item.a_na}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="before_1"
              type="text"
              value={item.before_1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="after_1"
              type="text"
              value={item.after_1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="before_2"
              type="text"
              value={item.before_2}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="after_2"
              type="text"
              value={item.after_2}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
        </tr>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
