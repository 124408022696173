import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CloseButton,
  SaveButton,
} from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import useConfirm from '../../components/common/useConfirm';
import HeaderInputs from './components/create/HeaderInputs';
import ModuleBody from './components/create/ModuleBody';
interface MyData {
  moduleData: any; // replace PirType with the type of your `pir` data
  isLoading: boolean;
  error?: string | null;
}
const Create = () => {
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const { state } = useAuth();
  const navigate = useNavigate();

  const isView = useQuery('isView') === 'true' ? true : false;
  const [moduleData, setModuleData] = useState<any>('');
  const [render, setRender] = useState(false);
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);

  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );

  const [DialogUpdate, confirmUpdate] = useConfirm(
    'SAVE',
    'Are you sure you want to save?'
  );
  const [DialogDeleteProcess, confirmDeleteProcess] = useConfirm(
    'DELETE QUESTION',
    'Are you sure you want to delete this Question?'
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/skillEvaluationQuestionPaper/getOneQuestionPaper/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const onSubmit = async () => {
    let ans = await confirmUpdate();
    if (!ans) return;
    axios
      .put(
        `/api/skillEvaluationQuestionPaper/updateQuestionPaper/${id}`,
        moduleData
      )
      .then((res) => {
        window.alert('success');
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="ADD EDIT QUESTION PAPER">
          {!isView && (
            <>
              <SaveButton label="Save & Continue" onClick={() => onSubmit()} />
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (!ans) return;
                  navigate(-1);
                }}
                label="Close"
              />
            </>
          )}
        </ChildHeader>
        <HeaderInputs
          {...{
            moduleData,
            setRender,
            setModuleData,
            id,
            currentLevelIndex,
            setCurrentLevelIndex,
          }}
        />
        <ModuleBody {...{ moduleData, setModuleData, currentLevelIndex }} />
      </Box>
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
      <DialogDeleteProcess isSubmitContent={false} />
    </Box>
  );
};

export default Create;
