import { Box, Paper, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PersitedDatePicker } from '../../../components/common';
import MonthlyMachineWise from './dashBoard/MonthlyMachineWise/MonthlyMachineWise';
import { useQuery } from '../../../hooks/UseQuery';
import ChildHeader from '../../../components/ui/ChildHeader';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import { UpdateButton } from '../../../components/common/button/CustomButtons';

const MonthlyBreakDownRecordsDashboardTabs = () => {
  const date = useQuery('date') || '';
  const [tabValue, setTabValue] = useState(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [data, setData] = useState<any>({});
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [graphData, setGraphData] = useState({
    machineMonthWise: [],
  });

  const fetchData = async () => {
    if (date) {
      if (tabValue == 0) {
        const res = await axios
          .get(
            `/api/breakdownRecords/dashboard/getDownTimeByMonth/?date=${date}`
          )
          .catch((err) => {
            console.log(err);
          });
        setGraphData(res?.data);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [date, tabValue]);

  //   let isConfirm = window.confirm('Are you sure you want to update target');
  //   if (isConfirm) {
  //     axios
  //       .patch(`/api/dailyProdReport/updateTarget`, {
  //         target: target,
  //       })
  //       .then((res) => {
  //         if (res.data.status === 'success') {
  //           alert('target Change successfully');
  //         }
  //         setRender((prev) => !prev);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  return (
    <Box>
      <ChildHeader text="BREAK DOWN GRAPH"></ChildHeader>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="MACHINE WISE PARETO CHART" />
        </Tabs>
      </Paper>
      <Box sx={{ margin: 2, display: 'flex' }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>

      <section>
        {tabValue === 0 && <MonthlyMachineWise apiData={graphData} />}
      </section>
    </Box>
  );
};

export default MonthlyBreakDownRecordsDashboardTabs;
