import {
  EmployeeList,
  EmployeeSkillEvaluationSheetCreate,
  EmployeeSkillEvaluationSheetList,
  SkillEvaluationSheetCreate,
  SkillEvaluationSheetList,
  Clause5,
  TraningTopicsList,
  TraningTopicsCreateEdit,
  TniTiles,
  EmpTraningTopicsCreateEdit,
  TniReportCreate,
  TniReportList,
  OnJobTraining,
} from '../../pages/clause5';

export const clause5new = [
  {
    path: 'clause_5',
    element: <Clause5 />,
  },
  {
    path: 'employeesList',
    element: <EmployeeList />,
  },
  {
    path: 'traningtopicslist',
    element: <TraningTopicsList />,
  },
  {
    path: 'tnihome',
    element: <TniTiles />,
  },
  {
    path: 'tniReportList',
    element: <TniReportList />,
  },
  {
    path: 'tniReportCreate/:id?',
    element: <TniReportCreate />,
  },
  {
    path: 'traningtopicscreateedit/:id?',
    element: <TraningTopicsCreateEdit />,
  },
  {
    path: 'skillEvaluationSheetList',
    element: <SkillEvaluationSheetList />,
  },
  {
    path: 'skillEvaluationSheetCreate/:id?',
    element: <SkillEvaluationSheetCreate />,
  },
  {
    path: 'employeeSkillEvaluationSheetList',
    element: <EmployeeSkillEvaluationSheetList />,
  },
  {
    path: 'employeeSkillEvaluationSheetCreate/:testSheetId/:id?',
    element: <EmployeeSkillEvaluationSheetCreate />,
  },
  {
    path: 'empTraningTopicsCreateEdit/:id?',
    element: <EmpTraningTopicsCreateEdit />,
  },
  {
    path: 'onJobTraining/:id?',
    element: <OnJobTraining />,
  },
];
