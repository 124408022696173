import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import {
  Autocomplete,
  Grid,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import moment from 'moment';
import formatDate from '../../../components/common/formatDate';
import DatePickerMui from '../../../components/common/DatePickerMui';
import NotesTextfield from '../../../components/common/NotesTextfield';
import { StyledNotesField } from '../../../components/common/FormControlMui';
import { Description } from '@mui/icons-material';
import DescriptionTextfield from '../../../components/common/DescriptionNotes';
import { useAuth } from '../../../services/auth/AuthProvider';
import MushinRemarksTextField from '../../../components/common/MushinRemarksTextFieldNotes';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
const Create = ({
  id,
  setIsModal,
  disable,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  disable: boolean;
}) => {
  const { state } = useAuth();
  // console.log(state,'dfdfgdfh')
  const [data, setData] = useState<any>({});

  const [render, setRender] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [departmentData, setDepartmentData] = useState<any>([]);
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Vendor' : 'Create Ventor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/listOfPYRoute/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      data.user_id = state?.user?.username;
      data.date_of_complaint = moment().format('DD-MM-YYYY');
      data.status = 'OPEN';
    }
  }, [id]);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    if (id) {
      await axios
        .put(`/api/listOfPYRoute/${id}`, data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/listOfPYRoute', data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/department/department/departmentForAutoComplete`)
      .then((res) => {
        setDepartmentData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // function YourComponent() {
  //   const [machines, setMachines] = useState([]);

  //   useEffect(() => {
  //     // Fetch machines data from backend API
  //     axios.get('/api/listOfPYRoute')
  //       .then(response => {
  //         setMachines(response.data);
  //       })
  //       .catch(error => {
  //         console.error('Error fetching machines:', error);
  //       });
  //   }, []);

  return (
    <>
      <Loader loader={isLoading} />
      <GridInputs
        id={'user_id'}
        name={'user_id'}
        html_for={'user_id'}
        label_name={'ID:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.user_id || ''}
        // isView={isView}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'nameofpkyk'}
        name={'nameofpkyk'}
        html_for={'nameofpkyk'}
        label_name={'Name of POKA-YOKE:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.nameofpkyk || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />

      <Grid item xs={3}>
        <AutocompleteGridmui
          id={'machine_name'}
          label={'Machine : '}
          option_name={'machine_no'}
          arrayofObj={machineData}
          value={data.machine_id || ''}
          onChange={(e, value) => {
            data.machine_id = value;
            setRender((prev) => !prev);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <AutocompleteGridmui
          id={'department_name'}
          label={'Department : '}
          option_name={'department_name'}
          arrayofObj={departmentData}
          value={data.department_id || ''}
          onChange={(e, value) => {
            data.department_id = value;
            setRender((prev) => !prev);
          }}
        />
      </Grid>
      <GridInputs
        id={'pkykno'}
        name={'pkykno'}
        html_for={'pkykno'}
        label_name={'POKA-YOKE Number:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.pkykno || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'freq'}
        name={'freq'}
        html_for={'freq'}
        label_name={'Frequency:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.freq || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'provisionalctrl'}
        name={'provisionalctrl'}
        html_for={'provisionalctrl'}
        label_name={'Provisional Control:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.provisionalctrl || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'reactionplan'}
        name={'reactionplan'}
        html_for={'reactionplan'}
        label_name={'Reaction Plan:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.reactionplan || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />

      {!disable && (
        <div
          className="btn_save"
          style={{
            position: 'absolute',
            right: '140px',
            top: '-0.6px',
          }}
        >
          <SubmitButton
            label={`${id ? 'update' : 'submit'}`}
            type="button"
            onClick={handleSumbit}
          />
        </div>
      )}
      <DialogSubmit />
    </>
  );
};

export default Create;
