import { useState } from 'react';

const THead = () => {
  return (
    <thead style={{ background: '#f8f9fa' }}>
      <th style={{ minWidth: '90px' }}>Group No</th>
      {Array.from({ length: 25 }, (_, row) => (
        <th>{row + 1}</th>
      ))}
    </thead>
  );
};

export default THead;
