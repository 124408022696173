import { MenuItem } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import CustomSelect from '../../../../../../components/common/SelectField';
import { TableCompHead } from '../../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={3}
        labels={['Doc No:', 'Rev No.:', ' Eff. Date:']}
        values={['F-PROD-22B', '03', '10.06.2020']}
        heading="Before Loading Die Checksheet  (PDC)"
      />
      <tr>
        <th colSpan={3} align="left">
          Mold Name : {moduleData?.mold_id?.mold_name || ''}{' '}
        </th>
        <th colSpan={2} align="left">
          Department :{' '}
          {moduleData?.mold_id?.department_id?.department_name || ''}{' '}
        </th>
        <th colSpan={2} align="left">
          Frequency/No. of shots : {moduleData?.mold_id?.frequency || ''}
        </th>
        <th colSpan={1} style={{ textAlign: 'left' }}>
          Date :
          {isView ? (
            formatDate(moduleData.inspection_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.inspection_date)}
              onChange={(date) => {
                moduleData.inspection_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {[
          'S.No',
          'CHECK POINTS',
          'STANDARD',
          'ACTION IF NOT AS PER STANDARD',
          'A / NA',
          'METHOD',
          'Before',
          'After',
        ].map((heading) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
