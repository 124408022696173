import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';

import ChildHeader from '../../../components/ui/ChildHeader';
import {
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

const View = () => {
  const [moduleData, setModuleData] = useState<IData>(
    lodash.cloneDeep(initialState),
  );
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/dieTrialRecordRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (isSubmitted: boolean) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    if (id) {
      await axios
        .put(`/api/dieTrialRecordRoute/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/dieTrialRecordRoute', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Box>
        <ChildHeader text="DIE TRIAL RECORD">
          {!isView && (
            <>
              {state.user?.userType === state.IS.Supervisor && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => handleSumbit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => handleSumbit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
        }}
      />

      <DialogSubmit />
    </>
  );
};

export default View;
