const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Clause Ref.', style: style1 },
    { text: 'Header', style: style1 },
    { text: 'Item', style: style1 },
    { text: 'Ref.Document', style: style1 },
    { text: 'Observations', style: style1 },
    { text: 'Status', style: style1 },
    { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'Internal Audit Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'Internal Audit CHECKSHEET ',
};

export const apiConstant = {
  get: 'masterCheckListRouteInternalAuditPlan',
  getById: 'masterCheckListRouteInternalAuditPlan',
  post: 'masterCheckListRouteInternalAuditPlan',
  update: 'masterCheckListRouteInternalAuditPlan',
  delete: 'masterCheckListRouteInternalAuditPlan',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
  childHeaderText: 'Internal Audit',
};

export const navigationConstant = {
  createEditChecklist: '/common/internalAuditChecklistCreate',
};
