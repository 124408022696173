import { useCallback, useMemo } from 'react';
import { TableInput } from '../../../../components/common';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import { createHeaderStandard, tilesConstant } from '../helpers/constant';
import { getYearRange } from '../helpers/utills';
import React from 'react';

interface IProps {
  headerTopicData: any;
  isView: boolean;
}

const THead = ({ headerTopicData, isView }: IProps) => {
  const date = useQuery('date');
  const department_name = useQuery('department_name');

  const HeaderStandard = useMemo(() => {
    return createHeaderStandard({
      headerList: headerTopicData,
    });
  }, [headerTopicData]);

  const yearRange = useCallback(
    () => getYearRange(date, 'financialYear'),
    [date]
  );

  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={0}
        labels={['']}
        values={['']}
        heading={`${
          tilesConstant.childHeaderText
        } ${department_name} (${yearRange()})`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
