import { AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { HiDocumentReport } from 'react-icons/hi';
import { TbFaceIdError, TbReportAnalytics } from 'react-icons/tb';
import { TbReport } from 'react-icons/tb';
// import { RiDeleteBin4Fill } from 'react-icons/ri';
import { TbFileReport } from 'react-icons/tb';
import { MdOutlineEditNote } from 'react-icons/md';
import { GrUserWorker, GrVmMaintenance } from 'react-icons/gr';
import { MdChecklistRtl } from 'react-icons/md';
import { FcDataSheet } from 'react-icons/fc';
import { BiSpreadsheet } from 'react-icons/bi';

const tiles = [
  {
    sNo: 1,
    link: '/common/production_listOfProcedures/?documentType=production&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/rotorSection',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#a12222" />,
    p: 'ROTOR',
    span: 'Section',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/pressShop',
    isLink: true,
    icon: <TbReportAnalytics size="5rem" color="#a12222" />,
    p: 'Press',
    span: 'Shop',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/pdc',
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'PDC',
    span: 'Section',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/cnc',
    isLink: true,
    icon: <TbFileReport size="5rem" color="#a12222" />,
    p: 'CNC',
    span: 'Section',
    isIcon: true,
  },
  // {
  //   sNo: 5,
  //   link: '/common/dailyAccident',
  //   isLink: true,
  //   icon: <TbFileReport size="5rem" color="#a12222" />,
  //   p: 'DAILY ACCIDENT MONITORING',
  //   span: 'CHECK SHEET',
  //   isIcon: true,
  // },

  // {
  //   sNo: 6,
  //   link: '/common/ppeMonitoringCS',
  //   isLink: true,
  //   icon: <TbFileReport size="5rem" color="#a12222" />,
  //   p: 'PPE MONITORING SHEET',
  //   span: ' FOR SAFETY',
  //   isIcon: true,
  // },
  {
    sNo: 7,
    link: '/common/processParameterStdList',
    isLink: true,
    icon: <MdOutlineEditNote size="5rem" color="#003566" />,
    p: 'Process Parameter',
    span: 'Standard',

    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/processValidationList',
    isLink: true,
    icon: <FcDataSheet size="5rem" color="#003566" />,
    p: 'Process Validation',
    span: 'Sheet',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/processParameterList',
    isLink: true,
    icon: <BiSpreadsheet size="5rem" color="#003566" />,
    p: 'Process Parameter',
    span: 'Sheet',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/listOfPokaYoke',
    isLink: true,
    icon: <MdOutlineEditNote size="5rem" color="#003566" />,
    p: 'List of Poka Yoke',
    span: '',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/pokeyokeChecksheet',
    isLink: true,
    icon: <MdChecklistRtl size="5rem" color="#a12222" />,
    p: 'Poke Yoke',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/productionWorkersList?filter=createdAt',
    isLink: true,
    icon: <GrUserWorker size="5rem" color="#a12222" />,
    p: 'Master List of',
    span: 'Workers',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/listOfPdcDefects',
    isLink: true,
    icon: <TbFaceIdError size="5rem" color="#a12222" />,
    p: 'List Of PDC ',
    span: '/ Defects',
    isIcon: true,
  },
];

export default tiles;
