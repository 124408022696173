import {
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CloseButton,
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../../../components/common/button/CustomButtons';
import formatDate from '../../../../../components/common/formatDate';
import ChildHeader from '../../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../../hooks/UseQuery';
import { useAuth } from '../../../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { apiConstant, createConstant } from './constant';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../../../../components/common/useConfirm';
import { Loader } from '../../../../../components/common/Loader';
import lodash from 'lodash';
import CustomSelect from '../../../../../components/common/SelectField';

const maintenanceObj = {
  april: {
    p: false,
    a: [],
  },
  may: {
    p: false,
    a: [],
  },
  june: {
    p: false,
    a: [],
  },
  july: {
    p: false,
    a: [],
  },
  august: {
    p: false,
    a: [],
  },
  september: {
    p: false,
    a: [],
  },
  october: {
    p: false,
    a: [],
  },
  november: {
    p: false,
    a: [],
  },
  december: {
    p: false,
    a: [],
  },
  january: {
    p: false,
    a: [],
  },
  february: {
    p: false,
    a: [],
  },
  march: {
    p: false,
    a: [],
  },
};

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  handelSync: any;
}

const Create = () => {
  const { id } = useParams();
  const date = useQuery('date');
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [error, setError] = useState(null);
  const [render, setRender] = useState(false);
  const [allDepartment, setAllDepartment] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState<any>('');
  const [moduleData, setModuleData] = useState<any>([]);
  const [tniTopics, setTniTopics] = useState<any>([]);
  const [isLoading, setIsloading] = useState(false);

  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );

  const getTopicByDepartment = (departmentId: any) => {
    axios
      .get(`/api/tni/getTopicByDepartment/${departmentId}`)
      .then((res) => {
        if (res.data) {
          if (res.data.topics.length) {
            setTniTopics(res.data?.topics);
          } else {
            setTniTopics([{ name: 'Other' }]);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => {
        setAllDepartment(res.data.results);
        res.data.results.length > 0 && setAutocompleteData(res.data.results[0]);
        res.data.results.length > 0 &&
          getTopicByDepartment(res.data.results[0]._id);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id && autocompleteData) {
      axios
        .get(`/api/tniReport/getOneTni/report/${id}/${autocompleteData._id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id, autocompleteData, isLoading]);

  const handleSaveAndContinue = (deparmentId: any) => {
    axios
      .put(
        `/api/tniReport/updateTniReport/yealy/${id}/${deparmentId}`,
        moduleData.departments
      )
      .then((res) => {
        window.alert('Updated Successfully!!');
      })
      .catch((err) => console.log(err));
  };

  const handleSyncTniReport = async () => {
    setIsloading(true);
    axios
      .get(`/api/tniReport/syncTniReport/${moduleData._id}`)
      .then((res) => {
        const timer = setTimeout(() => {
          setIsloading(false);
          window.alert('successfully updated');
        }, 1000);
      })
      .catch((err) => {
        const timer = setTimeout(() => {
          setIsloading(false);
        }, 1000);
        console.log(err);
      });
  };
  const TickCrossSelectField = (item: any, index: number) => {
    return (
      <CustomSelect
        name=""
        value={item.training_topics[index] || ''}
        label={''}
        onChange={(e: any) => {
          item.training_topics[index] = e.target.value;
          setRender((prev) => !prev);
        }}
        // sx={{ width: '120px' }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="R">R</MenuItem>
        <MenuItem value="NR">NR</MenuItem>
      </CustomSelect>
    );
  };
  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text={`${createConstant.childHeader}`}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            style={{
              backgroundColor: '#1864ab',
              marginRight: '0.5rem',
            }}
            startIcon={<GoSync />}
            onClick={() => {
              handleSyncTniReport();
            }}
          >
            Synch Employee
          </Button>
          <SaveButton
            label="Save & Continue"
            onClick={() =>
              handleSaveAndContinue(moduleData?.departments[0]?._id)
            }
          />
          <CloseButton
            onClick={async () => {
              let ans = await confirmClose();
              if (!ans) return;
              navigate(-1);
            }}
            label="Close"
          />
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          tniTopics,
          TickCrossSelectField,
          allDepartment,
          getTopicByDepartment,
          autocompleteData,
          setAutocompleteData,
        }}
      />
      <DialogClose isSubmitContent={false} />
    </Box>
  );
};

export default Create;
