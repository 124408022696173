import axios from 'axios';
import { useState, useEffect } from 'react';

const useGetData = (
  moldId: string | undefined,
  beforeDieCastingDailyId: string | undefined
) => {
  const [moduleData, setModuleData] = useState<any>({
    inspection_date: new Date(),
    processes: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const moldName = useQuery('moldName') || '';
  // const departmentName = useQuery('departmentName');
  // const frequency = useQuery('frequency');
  useEffect(() => {
    setIsLoading(true);
    if (beforeDieCastingDailyId) {
      axios
        .get(`/api/beforeLoadingDieDaily/${beforeDieCastingDailyId}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(
          `/api/beforeLoadingDieDaily/getSpecFromBeforeLoadingDieDaily/${moldId}`
        )
        .then((res) => {
          const response = res.data;
          setModuleData((item: any) => {
            item.inspection_date = new Date();
            item.prepared_by = response.prepared_by;
            item.approved_by = response.approved_by;
            item.mold_id = response.mold_id;
            item.processes = response?.processes?.map((spec: any) => {
              return {
                s_no: spec.s_no,
                checkpoint: spec.checkpoint,
                standard: spec.standard,
                action: spec.action,
                a_na: spec.a_na,
                method: spec.method,
              };
            });
            return { ...item };
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, []);
  return { moduleData, isLoading, error };
};

export default useGetData;
