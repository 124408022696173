import moment from 'moment';

export const processes = [
  {
    date: moment(),
    shift: null,
    mc_no: null,
    part_id: null,
    rotor_size: null,
    batch_number: null,
    operator: null,
    operator_2: null,
    time_start: null,
    time_stop: null,
    shot: null,
    no_of_cavity: null,
    total_qty: null,
    ok_qty: null,
    rej_qty: null,
    verified_sign: null,
    remarks: null,
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  shift: 'A',
  processes: Array.from({ length: 12 }, (_, index) => ({
    ...processes[0],
    time_start: moment('9:00', 'HH:mm').add(index, 'hours'),
    time_stop: moment('10:00', 'HH:mm').add(index, 'hours'),
  })),
};
