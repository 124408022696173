const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Check Point for Operator Observation', style: style1 },
    { text: 'Observations', style: style1 },
    { text: 'Observations(If Re-Training Required)', style: style1 },
    { text: 'Status', style: style1 },
    { text: 'Remark', style: style1 },
    { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'Posion Test Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'POISON TEST RECORD SHEET',
};

export const apiConstant = {
  get: 'masterCheckListRoutePoisonTestPlan',
  getById: 'masterCheckListRoutePoisonTestPlan',
  post: 'masterCheckListRoutePoisonTestPlan',
  update: 'masterCheckListRoutePoisonTestPlan',
  delete: 'masterCheckListRoutePoisonTestPlan',
  customerDataForAutoComplete: 'customerRoutes/customerList/customerForAutoComplete',
};

export const tilesConstant = {
  childHeaderText: 'Poison Test',
};

export const navigationConstant = {
  createEditChecklist: '/common/poisonTestChecklistCreate',
};
