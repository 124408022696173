const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Mould Status / Parameter', style: style1 },
    { text: 'OK / NG', style: style1 },
    { text: 'Part verified by QA Engg Sign.', style: style1 },
    { text: 'Remark', style: style1 },
    { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'THIRD PARTY MATERIAL TEST PLAN Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'THIRD PARTY MATERIAL TEST PLAN CHECKSHEET ',
};

export const apiConstant = {
  get: 'masterCheckListRouteThirdPartyMaterialTestPlan',
  getById: 'masterCheckListRouteThirdPartyMaterialTestPlan',
  post: 'masterCheckListRouteThirdPartyMaterialTestPlan',
  update: 'masterCheckListRouteThirdPartyMaterialTestPlan',
  delete: 'masterCheckListRouteThirdPartyMaterialTestPlan',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
  childHeaderText: 'THIRD PARTY MATERIAL TEST PLAN',
};

export const navigationConstant = {
  createEditChecklist: '/common/thirdPartyMaterialTestChecklistCreate',
};
