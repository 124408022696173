import { useEffect, useState } from 'react';
import TBodyRow from './TBodyRow';
import axios from 'axios';

interface IProps {
  moduleData: any;
  handleDelete: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView, handleDelete }: IProps) => {
  return (
    <tbody>
      {moduleData?.processes?.map((process: any, index: number) => (
        <TBodyRow
          {...{
            process,
            index,
            isView,
            handleDelete,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
