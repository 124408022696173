import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../../components/common';
import { calculateScore } from '../../helpers/calculateFn';

interface IProps {
  moduleData: any;
  SetControllerRender: any;
  isView: boolean;
}

const TBody7 = ({ moduleData, isView, SetControllerRender }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      <tr style={{ height: '50px', fontSize: '2rem' }}>
        <th>7</th>
        <th colSpan={6} align="left">
          CONTROL OF NONCONFERMING PARTS AND CORRECTIVE & PREVENTIVE ACTIONS.:-{' '}
        </th>
      </tr>
      {moduleData &&
        moduleData?.control_of_nonconforming_parts_and_corrective_preventive_actions?.map(
          (process: any, index: number) => (
            <tr>
              <td>{process?.s_no}</td>
              <td style={{ minWidth: '300px', textAlign: 'left' }}>
                {process?.auditor_parameter}
              </td>
              {['sc1', 'sc2', 'sc3'].map((heading, sInd) => (
                <td key={heading + sInd + index} style={{ minWidth: '150px' }}>
                  <TableInput
                    isView={isView}
                    name="sc"
                    type="number"
                    value={process?.score?.[sInd] ?? null}
                    onChange={(e: any) => {
                      process.score[sInd] = e.target.value;
                      calculateScore(
                        moduleData,
                        'control_of_nonconforming_parts_and_corrective_preventive_actions'
                      );
                      SetControllerRender((prev: any) => !prev);
                    }}
                  />
                </td>
              ))}
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="obs"
                  type="text"
                  value={process.obs}
                  onChange={(e: any) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e: any) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </tr>
          )
        )}
      <tr>
        <th colSpan={2}>TOTAL SCORE:-</th>
        <th colSpan={3}>
          {
            moduleData?.total_control_of_nonconforming_parts_and_corrective_preventive_actions
          }
        </th>
        <th colSpan={2}></th>
      </tr>
    </tbody>
  );
};

export default TBody7;
