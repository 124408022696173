import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import { IModule } from '../../interface';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const TController = ({ moduleData, isView }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            isView,
            moduleData,
          }}
        />
        <TBody
          {...{
            isView,
            moduleData,
          }}
        />
        <TFoot
          {...{
            isView,
            moduleData,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
