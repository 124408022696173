import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handelDeleteRows: any;
  machineData: any;
  handleChangeMachine: (value: any) => void;
}

const TController = ({
  moduleData,
  isView,
  handelDeleteRows,
  machineData,
  handleChangeMachine,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            machineData,
            handleChangeMachine,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
          }}
        />
        <TFoot moduleData={moduleData} isView={isView} />
      </RenderTable>
    </Box>
  );
};

export default TController;
