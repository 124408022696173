import React, { useState } from 'react';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData?.processes?.map((process: any, index: number) => (
            <React.Fragment key={index + 1 + 'process'}>
              <TBodyRow
                {...{
                  moduleData,
                  process,
                  index,
                  isView,
                }}
              />
            </React.Fragment>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
