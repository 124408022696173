import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { convToTime } from '../../../../utils/helpers';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
  machineData?: any;
  autoCompleteMachineValue?: any;
  setAutoCompleteMachineValue?: any;
}

const THead = ({
  tabValue,
  moduleData,
  isView,
  machineData,
  autoCompleteMachineValue,
  setAutoCompleteMachineValue,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={15}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-10B', '01', '22.03.2023']}
        heading="line inspection report"
      />
      <tr>
        <th colSpan={15} align="left">
          Customer:
          {moduleData.customer_name
            ? moduleData.customer_name
            : moduleData.customer_id?.customer_name}
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Part Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={5} align="left">
          Part Number:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={5}></th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Date:
          {isView ? (
            formatDate(moduleData.pir_date)
          ) : (
            <DatePickerMui
              label="Line Inspection Date"
              value={moment(moduleData.pir_date)}
              onChange={(date) => {
                moduleData.pir_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4} align="left">
          Shift:
          <TableInput
            name="shift"
            type="text"
            value={moduleData.shift}
            onChange={(e) => {
              moduleData.shift = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4} align="left">
          M/C No:{' '}
          {isView ? (
            moduleData?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label="Machine"
              option_name="machine_no"
              arrayofObj={machineData}
              value={autoCompleteMachineValue}
              onChange={(e, value) => {
                moduleData.machine_id = value?._id;
                setAutoCompleteMachineValue(value);
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          Heat No:
          <TableInput
            name="heat_no"
            type="text"
            value={moduleData.heat_no}
            onChange={(e) => {
              moduleData.heat_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {['S.NO', 'PARAMETER', 'SPECIFICATION', 'MODE OF INSPECTION'].map(
          (head) => (
            <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
              {head}
            </th>
          )
        )}
        <th colSpan={10}>OBSERVATIONS</th>
        <th rowSpan={2}>Remarks</th>
      </tr>
      <tr>
        {moduleData.pirs.length > 0 &&
          Array.from({ length: 5 }, () => 0).map((_, index) => (
            <>
              <th style={{ width: '120px' }}>
                {isView ? (
                  convToTime(moduleData.pirs[tabValue].times[index])
                ) : (
                  <TimePicker
                    value={moment(moduleData.pirs[tabValue].times[index])}
                    onChange={(date) => {
                      moduleData.pirs[tabValue].times[index] = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </th>
              <th>JUDGEMENT</th>
            </>
          ))}
      </tr>
    </thead>
  );
};

export default THead;
