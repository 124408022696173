import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    id: 1,
    desc: 'Accident',
    freq: 'Major',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 2,
    desc: '',
    freq: 'Minor',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 3,
    desc: '',
    freq: 'Near Miss',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 4,
    desc: '',
    freq: 'First Aid',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 5,
    desc: '',
    freq: 'Holiday',
    shift_A_results: Array(31).fill('false'),
    remarks: '',
  },
  {
    id: 6,
    desc: 'Verified By',
    freq: '',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyBin_date: moment(),
  machine_id: null,
  department_id: null,
  processes: lodash.cloneDeep(processes),
};
