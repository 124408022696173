export const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];
export const isPlainObject = (obj: any) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const removeFPrefix = (string: any) => {
  // Check if the property exists and is a string
  if (typeof string === 'string') {
    // Use a regular expression to check for 'F-' at the start and remove it
    if (string.startsWith('F-')) {
      string = string.replace(/^F-/, '');
    }
  }
  // Return the possibly modified or unmodified string
  return string;
};
