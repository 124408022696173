import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { apiConstant } from './constant';
import useConfirm from '../../../../components/common/useConfirm';
import { useQuery } from '../../../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';

const useGetData = (
  id: string | undefined | null,
  testSheetId: string | undefined | null
) => {
  const [moduleData, setModuleData] = useState<any>({
    list: [],
  });
  const navigate = useNavigate();
  const employee_name = useQuery('employee_name');
  const employee_id = useQuery('employee_id');
  const department_id = useQuery('department_id');
  const test_level = useQuery('test_level');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerRender, setTriggerRender] = useState(false);

  const [department, setDepartment] = useState([{}]);
  const [skillSheetType, setSkillSheetType] = useState<any>(null);
  const [Sync, confirmSync] = useConfirm(
    'SYNC',
    'Are you sure you want to sync? Save any changes before sync!'
  );

  const handleDelete = async () => {
    axios
      .delete(`/api/${apiConstant.delete}/${id}?employee_id=${employee_id}`)
      .then((res) => {
        alert('Succesfully deleted');
        navigate(-1);
      })
      .catch((err) => {
        setError(err);
        alert('cant  deleted');
      });
  };

  const updateModuleData = useCallback(
    (
      updateType: 'key-pair' | 'row',
      rowIndex: number | null,
      key: string,
      newValues: any,
      isHandleCalc: boolean
    ) => {
      setModuleData((prev: any) => {
        const updatedModuleData: any = { ...prev };
        if (updateType === 'key-pair') {
          updatedModuleData[key] = newValues[key];
        } else if (updateType === 'row') {
          //specific row that needs updating
          if (rowIndex === null) return;
          const updatedRow = { ...updatedModuleData.list[rowIndex] };
          updatedRow[key] = newValues[key];
          updatedModuleData.list[rowIndex] = updatedRow;
          if (isHandleCalc) {
            const total = handleCalcTotal(updatedModuleData.list);
            updatedModuleData.total_max_marks = total;
          }
        }

        return updatedModuleData;
      });
    },
    []
  );

  const handleCalcTotal = useCallback((data: any[]) => {
    if (!data) return null;
    const value = data?.reduce((accumulator: any, currentValue: any) => {
      if (currentValue.max_marks) {
        return (+accumulator || 0) + (+currentValue.max_marks || 0);
      }
      return accumulator;
    }, 0);
    return value;
  }, []);

  useEffect(() => {
    axios
      .get(`/api/${apiConstant.departmentAutoComplete}`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axios
        .get(`/api/${apiConstant.getById}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else if (testSheetId) {
      setIsLoading(true);
      axios
        .get(`/api/${apiConstant.getMasterSkillSheetById}/${testSheetId}`)
        .then((res) => {
          const data = res.data;
          data?.list?.forEach((item: any) => delete item.answer);
          data.employee_name = employee_name || null;
          data.employee_id = employee_id || null;
          data.mastersheet_id = testSheetId;
          data.test_type = test_level;
          delete data._id;
          delete data.__v;
          delete data.updatedAt;
          delete data.createdAt;
          setModuleData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      setModuleData({
        list: [],
      });
    }
  }, [triggerRender, skillSheetType]);

  return {
    moduleData,
    isLoading,
    error,
    updateModuleData,
    handleDelete,
    setTriggerRender,
    skillSheetType,
    setSkillSheetType,
    department,
    employee_id,
  };
};

export default useGetData;
