import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../../components/common/useConfirm';
import moment from 'moment';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { levels } from '../helpers/constant';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [render, setRender] = useState(false);
  const [department, setDepartment] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Employee' : 'Create Employee'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/employee/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModulData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    if (id) {
      await axios
        .put(`/api/employee/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/employee', moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <GridInputs
        id={'employee_name'}
        name={'employee_name'}
        html_for={'employee_name'}
        label_name={'Employee Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.employee_name}
        onChange={handleModulData}
      />
      <GridInputs
        id={'designation'}
        name={'designation'}
        html_for={'designation'}
        label_name={'Designation:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.designation}
        onChange={handleModulData}
      />

      <AutocompleteGridmui
        label={'Department Name:'}
        id={'department_name'}
        option_name={'department_name'}
        locked={moduleData?._id}
        value={moduleData.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          moduleData.department_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />

      <SelectGridMui
        name="employee_type"
        value={moduleData?.employee_type || ''}
        lable={'Employee Type:'}
        onChange={(e: any) => handleModulData(e)}
      >
        <MenuItem value="Staff">Staff</MenuItem>
        <MenuItem value="Worker">Worker</MenuItem>
      </SelectGridMui>

      <SelectGridMui
        name="skill_level"
        value={moduleData?.skill_level || ''}
        lable={'Skill Level'}
        onChange={(e: any) => handleModulData(e)}
      >
        <MenuItem value="">None</MenuItem>
        {levels.map((item) => (
          <MenuItem value={item.value}>{item.name}</MenuItem>
        ))}
      </SelectGridMui>

      <DateGridMui
        lable="Joining Date"
        onChange={(date: any) => {
          moduleData.doj = date;
        }}
        value={moduleData?.doj ? moment(moduleData?.doj) : null}
      />

      <GridInputs
        id={'qualification'}
        name={'qualification'}
        html_for={'qualification'}
        label_name={'Qualification'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.qualification}
        onChange={handleModulData}
      />
      <GridInputs
        id={'work_experience'}
        name={'work_experience'}
        html_for={'work_experience'}
        label_name={'Work Experience'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.work_experience}
        onChange={handleModulData}
      />
      <GridInputs
        id={'status'}
        name={'status'}
        html_for={'status'}
        label_name={'STATUS'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.status}
        onChange={handleModulData}
      />

      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks}
        onChange={handleModulData}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
