export const moduleConstant = {
  crudApi: '/api/emergencyLightRoute',
  childHeader: 'List Of Emergency Light',
  btnName: 'New Emergency Light',
  searchLabel: 'Code No. / Location',
  moduleName: 'Emergency Light',
  checklist: {
    childHeader: 'Daily Check Sheet For Emergency Light',
    toolTipTitle: 'Checklist',
    crudApi: '/api/emergencyLightRoute',

    router: {
      checklist: '/common/emergencyLight/checklist',
    },
  },
};
