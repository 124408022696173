import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import "../../../../App.css";
import TipTap from '../../TipTapTable';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';
import HTMLReactParser from 'html-react-parser';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setRender: any;
  setModuleData: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
  setRender,
  setModuleData,
}: IProps) => {
  

  const handleSetDesc = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      mrm_minutes: desc
    }));
  };

  return (
    <>
      <tbody>


      <tr style={{border:'none'}}>
        <th colSpan={5} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
             MINUTES OF MEETING
          </div>
        </th>
        <th colSpan={1} style={{borderRight:'none', borderLeft:'none', borderBottom:'none', borderTop:'none'}}>
          <div style={{marginLeft:'4px', textAlign:'right', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', borderLeft:'none', borderBottom:'none',fontSize:'18px'}}>
            DATE :
          </div>
        </th>

        <th colSpan={1} align="left" style={{borderLeft:'none', borderBottom:'none', borderTop:'none'}}>
          {isView ? (
            formatDate(moduleData.mrm_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.mrm_date)}
              onChange={(date) => {
                moduleData.mrm_date = date;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr style={{border:'none'}}>
        {isView ? (
          <td align='center' colSpan={7} style={{border:'none'}}>
            <div className='tiptaptable' style={{textAlign:'center'}}>
              {HTMLReactParser(moduleData.mrm_minutes)}
            </div>
          </td>
        ) : (
          <td align='center' colSpan={7} style={{border:'none', textAlign:'center'}}>
            <div  className='tiptaptable' style={{columnSpan:'all', textAlign:'center'}}>
              <TipTap setDesc={handleSetDesc} initialContent={moduleData.mrm_minutes} />
            </div>
          </td>
        )}
      </tr>
      </tbody>
    </>
  );
};

export default TBody;
