import React from 'react';
import { FlexBox } from '../../../../../components/common';
import {
  AddProcessButton,
  CopyProcessButton,
} from '../../../../../components/common/button/CustomButtons';
import { IModuleData, IProcess } from '../../../helpers/Interface';

interface IProps {
  moduleData: IModuleData;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
  setExpandState: React.Dispatch<React.SetStateAction<any>>;
}
const Heading = ({
  moduleData,
  setRender,
  setModuleData,
  setExpandState,
}: IProps) => {
  return (
    <FlexBox
      justify="space-between"
      style={{
        borderBottom: '1px solid #c80202',
        width: '99%',
        margin: '0 auto',
      }}
    >
      <p style={{ color: '#c80202', fontSize: '2rem' }}>
        • ADD <span style={{ fontWeight: 500, color: 'black' }}>PROCESS</span>
      </p>
      <FlexBox>
        <CopyProcessButton label="copy process" />
        <AddProcessButton
          label="add process"
          onClick={() => {
            const values: IModuleData = { ...moduleData };
            const newProcess: IProcess = {
              s_no: moduleData.processes.length + 1,
              process_no: '',
              process_name: '',
              specs: [],
            };
            moduleData.processes.push(newProcess);

            setModuleData(values);
            setExpandState((prev: any) => {
              prev[moduleData.processes.length - 1] = true;
              return [...prev];
            });
          }}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default Heading;
