import { useState } from 'react';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
import moment from 'moment';
interface IProps {
  moduleData: any;
  setModuleData: any;
  isView: boolean;
  setRenderforUpdate: any;
  departmentData: any;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};
const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);
const theadData = [
  'Sr.No.',
  'NAME OF POKA-YOKE',
  'MACHINE NO.',
  'POKA-YOKE NO.',
  'FREQUENCY',
  'PROVISIONAL CONTROL',
  'REACTION PLAN	',
  ...numberCol,
  'Remarks',
];

const THead = ({ moduleData, setRenderforUpdate, departmentData }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();

  return (
    <thead>
      <tr>
        <th style={{ background: '#495057', color: '#f8f9fa' }} colSpan={39}>
          {`${moduleConstant?.heading} - ${moment(date).format(
            'MMM-YY'
          )}`?.toUpperCase()}
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Department</th>
        <th colSpan={37} align="left">
          <AutocompleteMuiCustom
            id="Department"
            label=""
            option_name="department_name"
            arrayofObj={departmentData}
            value={moduleData.department_id || ''}
            onChange={(e, value) => {
              moduleData.department_id = value;
              setRenderforUpdate((prev: any) => !prev);
            }}
          />
        </th>
      </tr>

      <tr>
        {theadData.map((heading, index) => (
          <th
            style={{
              backgroundColor: '#4F81BD',
              color: '#fff',
              minWidth: '100px',
              position: 'sticky',
              width: '100%',
              top: '-.5rem',
              border: '1px solid black',
              zIndex: '1',
            }}
            key={heading}
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
