import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  AdminProtectedRoute,
  CommonProtectedRoutes,
  SupervisorProtectedRoute,
} from './utils/validators/ProtectedRoute';
import { IndexAdmin } from './routes/IndexAdmin';
import { IndexSupervisor } from './routes/IndexSupervisor';
import ErrorPage from './error-page';
import Login from './pages/login/Login';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IndexCommon } from './routes/IndexCommon';

//mendatory setting for adding color in palette
declare module '@mui/material/styles' {
  interface PaletteOptions {
    blueButton: PaletteOptions['primary'];
    greenButton: PaletteOptions['primary'];
    // Button: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#c80202',
    },
    secondary: {
      main: '#212529',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    text: {
      // primary: '#111',
      // secondary: '#f1f3f5',
    },
    blueButton: {
      main: '#003566',
      contrastText: '#fff',
    },
    greenButton: {
      main: '#064a29',
      contrastText: '#fff',
    },
  },
  typography: {
    // fontFamily: 'Raleway, Arial',
    fontSize: 22,
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: '#FFFFFF', // White for selected item
            color: '#212121', // Dark text for readability
          },
          '&[data-focus="true"]': {
            backgroundColor: '#E0E0E0', // Light gray for highlighted item
            color: '#212121', // Dark text for visibility
          },
          backgroundColor: '#F5F5F5', // Off-white for all other options
          color: '#424242', // Default text color
        },
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin',
    element: <AdminProtectedRoute />,
    errorElement: <ErrorPage />,
    children: [...IndexAdmin],
  },
  {
    path: '/common',
    element: <CommonProtectedRoutes />,
    errorElement: <ErrorPage />,
    children: [...IndexCommon],
  },
  {
    path: '/supervisor',
    element: <SupervisorProtectedRoute />,
    errorElement: <ErrorPage />,
    children: [...IndexSupervisor],
  },
]);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
