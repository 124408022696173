import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { IoMdAdd } from 'react-icons/io';

import {
  CustomButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../../../components/common/useConfirm';
import { Loader } from '../../../../components/common/Loader';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import { MdDeleteForever } from 'react-icons/md';
import { Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import TController from './components/TController';
import useGetData from './helpers/useGetData';
import { apiConstant } from './helpers/constant';

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  updateModuleData: any;
  addRow: any;
  department: any;
  machineData: any;
  handleDelete: any;
  setTriggerRender: any;
}

const Create = () => {
  const { id } = useParams();
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?'
  );
  const {
    moduleData,
    isLoading,
    error,
    Sync,
    updateModuleData,
    handleDelete,
    machineData,
    addRow,
    department,
    setTriggerRender,
  }: MyData = useGetData(id);

  const onSubmit = async (isSubmitted: boolean) => {
    const ans = await confirmSave();
    if (!ans) return;
    if (id) {
      axios
        .put(`/api/${apiConstant.update}/${id}`, moduleData)
        .then((res) => {
          alert('updated successfully ');
          // setTriggerRender((prev: any) => !prev);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`/api/${apiConstant.post}`, moduleData)
        .then((res) => {
          alert('create successfully');
          // setTriggerRender((prev: any) => !prev);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text="">
          {!isView && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{}}
            >
              <CustomButton
                sx={{
                  backgroundColor: '#228be6',
                  color: '#e7f5ff',
                }}
                icon={<IoMdAdd />}
                size="large"
                onClick={addRow}
              >
                ADD ROW
              </CustomButton>
              <SubmitButton
                label={id ? 'Update' : 'submit'}
                onClick={() => onSubmit(true)}
              />
            </Stack>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          handleDelete,
          moduleData,
          updateModuleData,
          machineData,
          department,
        }}
      />
      <Sync isSubmitContent={false} />
      <DialogSave isSubmitContent={false} />
    </Box>
  );
};

export default Create;
