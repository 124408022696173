import { AiOutlineCloudUpload } from 'react-icons/ai';
import { GrTest } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { RenderTiles } from '../../../../components/common';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { GiSkills } from 'react-icons/gi';

export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    link: '/common/createSkillEvaluationTestPaper',
    isLink: false,
    clauseNo: 5,
    icon: <GiSkills size="5rem" color="#135089" />,
    p: 'CREATE TEST',
    span: 'PAPER',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/downloadSkillEvaluationTestPaper',
    isLink: true,
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'DOWNLOAD TEST',
    span: 'PAPER',
    isIcon: true,
  },
];

const SkillEvaluationTestHome = () => {
  return (
    <>
      <ChildHeader text="SKILL EVALUATION TEST PAPER" />
      <RenderTiles tilesObj={tiles} height={'100vh'} justify="start" />
    </>
  );
};

export default SkillEvaluationTestHome;
