const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1, rowspan: 2 },
    {
      text: 'Check Sheet',
      style: { ...style1, minWidth: '400px' },
      rowspan: 2,
    },
    { text: '1st Week', style: style1, rowspan: 1, colspan: 3 },
    { text: '2nd Week', style: style1, rowspan: 1, colspan: 3 },
    { text: '3rd Week', style: style1, rowspan: 1, colspan: 3 },
    { text: '4th Week', style: style1, rowspan: 1, colspan: 3 },
    {
      text: 'Action',
      style: { ...style1, backgroundColor: '#c92a2a' },
      rowspan: 2,
    },
  ],
  [
    { text: 'OK', style: style1, rowspan: 1 },
    { text: 'NOT OK', style: style1, rowspan: 1 },
    { text: 'Remarks', style: style1, rowspan: 1 },
    { text: 'OK', style: style1, rowspan: 1 },
    { text: 'NOT OK', style: style1, rowspan: 1 },
    { text: 'Remarks', style: style1, rowspan: 1 },
    { text: 'OK', style: style1, rowspan: 1 },
    { text: 'NOT OK', style: style1, rowspan: 1 },
    { text: 'Remarks', style: style1, rowspan: 1 },
    { text: 'OK', style: style1, rowspan: 1 },
    { text: 'NOT OK', style: style1, rowspan: 1 },
    { text: 'Remarks', style: style1, rowspan: 1 },
  ],
];

export const listConstant = {
  childHeader: 'Fill Instrument Checksheet List',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'Fill Instrument Checksheet',
};

export const tilesConstant = {
  childHeaderText: 'Fill Instrument Checksheet',
};

export const navigationConstant = {
  createEditChecklist: '/common/fillCheckSheetChecklistCreate',
};

export const apiConstant = {
  get: 'statusCheckListRouteFillCheckSheet',
  getById: 'statusCheckListRouteFillCheckSheet',
  post: 'statusCheckListRouteFillCheckSheet',
  update: 'statusCheckListRouteFillCheckSheet',
  delete: 'statusCheckListRouteFillCheckSheet',
  instrumentCategoryForAutoComplete: 'masterlistinstrument/forAutocomplete',
  getMasterCheckListById: 'masterCheckListRouteCheckSheetFormats',
  masterCheckListAutoComplete:
    'masterCheckListRouteCheckSheetFormats/masterCheckListAutoComplete',
};
