// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const numberCol = Array.from({ length: 8 }, (_, index) => index);

const theadData = [
  'Sr.No.',
  '',
  '',
  ...numberCol.map((colNum) => `T${colNum}`), // Dynamically generate column labels
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [tools, setTools] = useState([]);
  useEffect(() => {
    axios
      .get(
        '/api/dieTrialRecordRoute/toolsList/toolsForAutoCompleteUnderTestingTrue'
      )
      .then((res) => {
        if (res.data) setTools(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date:']}
        values={[' F-PROD-38   ', '00', '01.01.2017']}
        heading="Die Trial Record"
      />
      <tr>
        <th colSpan={2} align="left">
          Date :{' '}
          {isView ? (
            formatDate(moduleData.dailyBin_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyBin_date)}
              onChange={(date) => {
                moduleData.dailyBin_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1} align="left">
          Die Name :{' '}
        </th>
        <th colSpan={2}>
          {isView ? (
            moduleData?.tool_id?.die_name
          ) : (
            <AutocompleteMuiCustom
              id="Tools"
              label=""
              option_name="die_name"
              key={'tools'}
              arrayofObj={tools}
              value={moduleData?.tool_id || ''}
              onChange={(e, value) => {
                moduleData.tool_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          Die Number:{moduleData?.tool_id?.die_number}
        </th>
        <th colSpan={2} align="left">
          Customer:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            isView={isView}
            name="customer"
            type="text"
            value={moduleData.customer}
            onChange={(e: any) => {
              moduleData.customer = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
