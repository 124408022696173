import { useState } from "react";
import { TableInput } from "../../../../../components/common";

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}
interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <th colSpan={5} align="left">
        Legends
      </th>
      <th colSpan={5} align="left">
        SITUATION OK (NO ACCIDENT) ✔<br></br>
        SITUATION NOT OK (ACCIDENT OCCURS) X
      </th>
      <th colSpan={25}></th>

      {/* <tr>
        <th colSpan={17} align="left">
          Prepared By: 
          <TableInput
            isView={isView}
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
            />
        </th>
        <th colSpan={18} align="left">
          Approved By: 
          <TableInput
            isView={isView}
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr> */}
    </tfoot>
  );
};

export default TFoot;
