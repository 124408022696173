import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { Checkbox, MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import SimpleSelect from '../../../../../components/common/SimpleSelect';

// const shiftResult = {
//   true: '✓',
//   false: '⨯',
// };

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  shift_A_results: any;
  setRender: any;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  backgroundColor: '#ffec99',
  // color: '#fff',
} as const;
const TBodyRow = ({
  moduleData,
  isView,
  process,
  index,
  shift_A_results,
  setRender
}: // handelDeleteRows,
  // setIsOpenPopup,
  // setPartIdx,
  IProps) => {

  return (
    <>
      <React.Fragment key={index + 10.1 + 'heading '}>
        <tr>
          <th style={{
            minWidth: '50px', textAlign: 'center',
          }}>{index + 1}</th>
          {process.id === 1 || process.id === 6 ? (
            <td
              rowSpan={process.id == 1 ? 5 : 1}
              colSpan={process.id == 1 ? 1 : 2}
              style={{
                minWidth: '200px', textAlign: 'center',
                ...stickStylesColumn,
                left: 0,
              }}
            >
              {process.desc}
            </td>
          ) : null}
          {process.id != 6 && <td style={{
            minWidth: '200px', textAlign: 'center',
            ...stickStylesColumn,
            left: 200,
          }}>
            {process.freq}
          </td>}

          {process.id <= 4 && Array.from({ length: 31 }, (_, indx) =>
          (
            shift_A_results[indx] == "false" ?
              <td
                style={{
                  minWidth: '100px',
                  textAlign: 'center',
                  fontSize: '2rem',
                }}
                key={index + 2.2 + indx + 'after' + 111.33}
              >
                {isView ? (
                  (process.shift_A_results[indx] === 'true' && '✓') ||
                  (process.shift_A_results[indx] === 'false' && '⨯') ||
                  (process.shift_A_results[indx] === '' && '')
                ) : (
                  <SimpleSelect
                    name={`shift_A_results[${indx}]`}
                    value={process.shift_A_results[indx]}
                    onChange={(e: any) => {
                      process.shift_A_results[indx] = e.target.value;
                      setRender((prev: any) => !prev);
                    }}
                    values={[
                      { value: '', label: 'none' },
                      { value: 'true', label: '✓' },
                      { value: 'false', label: '⨯' },
                    ]}
                    style={{
                      padding: '8px',
                      fontSize: '1.2rem',
                      border: '3px solid white',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      outline: 'none',
                      width: '70px',
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                    optionStyle={{
                      backgroundColor: 'white',
                      color: 'black',
                      width: '40px',
                    }}
                  />
                )}
              </td>
              :
              <td style={{
                minWidth: '100px',
                textAlign: 'center',
                fontSize: '2rem',
                background: "red"
              }}
                key={index + 2.2 + indx + 'after' + 111.33}>
              </td>
          )
          )}
          {process.id === 5 && Array.from({ length: 31 }, (_, indx) =>
          (
            <td key={index + 2.2 + indx + 'after' + 111.33}>
              <Checkbox
                checked={process.shift_A_results[indx].toLowerCase() === "true"}
                onChange={(e) => {
                  process.shift_A_results[indx] = "" + e.target.checked;
                  setRender((prev: any) => !prev);
                }}
              />
            </td>
          )
          )}

          {process.id === 6 && Array.from({ length: 31 }, (_, indx) =>
          (
            <td key={index + 2.2 + indx + 'after' + 111.33}>
              <TableInput
                isView={isView}
                name="verified_by"
                type="text"
                value={process.shift_A_results[indx]}
                onChange={(e) => {
                  process.shift_A_results[indx] = e.target.value;
                  setRender((prev: any) => !prev);
                }}
              />
            </td>
          )
          )}

          <td style={{ minWidth: '150px', textAlign: 'center' }}>
            <TableInput
              isView={isView}
              name="remarks"
              type="text"
              value={process.remarks}
              onChange={(e) => {
                handleChangeModuleData(e, process);
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        </tr>
      </React.Fragment>
    </>
  );
};

export default TBodyRow;
