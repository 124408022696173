import { TableInput } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  machineData?: any;
  updateModuleData?: any;
}

const THead = ({ machineData, moduleData, updateModuleData }: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const machineName = useQuery('machineName');
  const machineNo = useQuery('machineNo');

  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['FRM-MNT-A-013', '00', '01-04-2023']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr style={{ height: '50px' }}>
        <th colSpan={1}>CHECKSHEET NAME</th>
        <th colSpan={2}>
          <TableInput
            isView={true}
            style={{ height: '80px' }}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={8}></th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th>Machine Discription</th>
        <th colSpan={2}>{machineName}</th>
        <th>Location</th>
        <th colSpan={2}>
          <TableInput
            isView={true}
            style={{ height: '80px' }}
            name="location"
            type="text"
            value={moduleData.location}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th>PM Date:</th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.pm_date_1)
          ) : (
            <DatePickerMui
              label=""
              value={
                moduleData?.pm_date_1 ? moment(moduleData.pm_date_1) : null
              }
              onChange={(date) => {
                handleInputChange('pm_date_1', date);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left"></th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th>Machine No.</th>
        <th colSpan={2}>{machineNo}</th>
        <th colSpan={6}></th>
        <th colSpan={2}></th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
