import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';

interface IProps {
  moduleData: any;
  updateModuleData: any;
  selectionArray: any;
  handleBulkDeleteFeature: any;
  isView: any;
  empData: any;
  headerTopicData: any;
}

const TController = ({
  moduleData,
  isView,
  headerTopicData,
  selectionArray,
  handleBulkDeleteFeature,
  updateModuleData,
  empData,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            headerTopicData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            empData,
            headerTopicData,
            selectionArray,
            handleBulkDeleteFeature,
            updateModuleData,
          }}
        />
        {/* <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
