function calculateOkQty(process: any) {
  process.ok_qty_nos =
    (+process?.qty_received_nos || 0) - (+process?.qty_rejected || 0);
}

function qualityRating(process: any) {
  process.quality_rating = (
    ((+process?.ok_qty_nos || 0) / (process?.qty_received_nos || 1)) *
    100
  ).toFixed(2);
}
function deliveryRating(process: any) {
  const { qty_order_nos, qty_received_nos } = process;
  const gettingMateriaDelayForCalc = (h: number) => {
    if (+h >= 0 && +h <= 3) {
      return +(100 * 0.25).toFixed(2);
    } else if (+h > 3 && +h <= 10) {
      return +(90 * 0.25).toFixed(2);
    } else if (+h > 10 && +h <= 20) {
      return +(80 * 0.25).toFixed(2);
    } else return null;
  };
  const materialCalc =
    gettingMateriaDelayForCalc(process?.material_delivery_delay) || 0;

  const delivery_rating =
    ((+qty_received_nos || 0) / (+qty_order_nos || 1)) * 75 + +materialCalc;

  process.delivery_rating = delivery_rating.toFixed(2);
}

function overallRating(process: any) {
  const {
    quality_rating,
    delivery_rating,
    premium_freight,
    field_failure,
    line_stoppages_customer_disruptions,
  } = process;

  const gettingFrieghtValue = +premium_freight === 0 ? 100 * 0.1 : 90 * 0.1;
  const gettingFailureValue = +field_failure === 0 ? 100 * 0.5 : 90 * 0.5;
  const gettinglineStoppageValue =
    +line_stoppages_customer_disruptions === 0 ? 100 * 0.5 : 90 * 0.5;

  const qualityRatingCalc = (+quality_rating || 0) * 0.4;
  const deliveryRatingCalc = (+delivery_rating || 0) * 0.4;

  const overAllRatingPercent = (
    qualityRatingCalc +
    deliveryRatingCalc +
    gettingFrieghtValue +
    gettingFailureValue +
    gettinglineStoppageValue
  ).toFixed(2);

  process.overall_rating_percentage = +overAllRatingPercent;
}

function calcRatingAndRemarks(process: any) {
  const { overall_rating_percentage: orp } = process;
  if (orp >= 90) {
    process.rating = 'A+';
    process.remarks = 'Excellent';
  } else if (orp > 80) {
    process.rating = 'A';
    process.remarks = 'Very Good';
  } else if (orp > 71) {
    process.rating = 'B';
    process.remarks = 'Satisfactory';
  } else {
    process.rating = 'C';
    process.remarks = 'Need Improvement and Action Plan';
  }
}

export function calcRow(process: any) {
  calculateOkQty(process);
  qualityRating(process);
  deliveryRating(process);
  overallRating(process);
  calcRatingAndRemarks(process);
}
