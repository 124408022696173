import lodash from 'lodash';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const obj = {
  qty_order_nos: null,
  expected_delivery_date: null,
  act_material_received_date: null,
  material_delivery_delay: null,
  invoice_no: '',
  qty_received_nos: null,
  qty_rejected: null,
  ok_qty_nos: null,
  material_received_in_no_of_supply: null,
  quality_rating: null,
  delivery_rating: null,
  premium_freight: 0,
  field_failure: 0,
  line_stoppages_customer_disruptions: 0,
  overall_rating_percentage: null,
  rating: '',
  remarks: '',
};

export const initialState = {
  processes: Array.from({ length: 12 }, () => ({
    ...lodash.cloneDeep(obj),
  })),
};
