import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import { IData } from '../Interface';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../../components/common/autocomplete/AutocompleteGridmui';
import ModalCustom from '../../../../components/common/Modal';
import PartTable from './PartTable';
import { DivStyled } from '../../../../assets/styles/components/DivStyledLableInputs';
import { Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../../components/common/Loader';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    part_id: null,
    img: '',
  });

  const [customer, setCustomer] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Part List' : 'Create Part List'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/similarPartManagement/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    const formData = new FormData();
    if (typeof moduleData.part_id === 'object') {
      moduleData.part_id = moduleData.part_id._id;
    }

    for (const key in moduleData) {
      if (key !== 'files') formData.append(key, moduleData[key] as string);
    }
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/similarPartManagement/${id}`, formData)
        .then((res) => {
          setIsModal(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      await axios
        .post('/api/similarPartManagement', formData)
        .then((res) => {
          setIsModal(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Number'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof moduleData?.part_id === 'object' &&
                moduleData?.part_id !== null
                  ? moduleData?.part_id?.part_number
                  : moduleData?.partNumber || ''}
              </p>
            </Grid>

            {moduleData.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    moduleData.part_id = null;
                    moduleData.partNumber = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'UPLOAD DOCUMENT'}
          multiple
          accept="image/png,image/jpeg,image/jpg,application/pdf"
          onChange={(e) => setFiles(e.target.files)}
        />{' '}
      </Grid>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={moduleData} />
      </ModalCustom>
    </>
  );
};

export default Create;
