import React, { useState } from 'react';
import { TableRowComponent } from './TableRowComponent';

interface IProps {
  moduleData: any;
  handleBulkDeleteFeature: any;
  empData: any;
  selectionArray: any;
  headerTopicData: any;
  updateModuleData: any;
}
const TBody = ({
  moduleData,
  empData,
  selectionArray,
  updateModuleData,
  handleBulkDeleteFeature,
  headerTopicData,
}: IProps) => {
  return (
    <tbody>
      {moduleData.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || `${index}_table_row`}
          item={item}
          index={index}
          empData={empData}
          headerTopicData={headerTopicData}
          updateModuleData={updateModuleData}
          handleBulkDeleteFeature={handleBulkDeleteFeature}
          selectionArray={selectionArray}
        />
      ))}
    </tbody>
  );
};

export default React.memo(TBody);
