import { useState } from 'react';
import { TableInput } from '../../../components/common';

interface IProps {
  spcData: any;
}
const TBody = ({ spcData }: IProps) => {
  const [render, setRender] = useState(false);

  const tableRows = Array.from(
    { length: Math.floor(spcData.spc_array.length / 25) },
    (_, rowIdx) => (
      <tr key={rowIdx + 'spc_row'}>
        <td>{'X' + (rowIdx + 1)}</td>
        {spcData.spc_array
          .slice(rowIdx * 25, (rowIdx + 1) * 25)
          .map((_: any, sopidx: any) => (
            <td
              key={sopidx + rowIdx * 25}
              style={{ backgroundColor: '#fff3bf' }}
            >
              <TableInput
                name="remarks"
                type="number"
                isView={false}
                style={{ width: '100px', backgroundColor: '#fff3bf' }}
                value={spcData.spc_array[sopidx + rowIdx * 25]}
                onChange={(e) => {
                  spcData.spc_array[sopidx + rowIdx * 25] =
                    e.target.valueAsNumber;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          ))}
      </tr>
    )
  );
  return <tbody>{tableRows}</tbody>;
};

export default TBody;
