import { useState } from 'react';

import { TableInput } from '../../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody10 = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      <tr>
        <th colSpan={2} style={{ fontSize: '1.5rem' }}>
          Score with % :-
        </th>
        <th colSpan={3}>{moduleData?.score_percentage}</th>
        <th colSpan={2}>
          Audit Status:
          <TableInput
            isView={isView}
            name="audit_status"
            type="text"
            value={moduleData?.audit_status}
            onChange={(e: any) => {
              moduleData.audit_status = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} style={{ textAlign: 'left' }}>
          ASSESMENT SUMMERY:-
        </th>
      </tr>
      <tr>
        <th>S.N.</th>
        <th>AUDIT PARAMETER</th>
        <th colSpan={3}>TOTAL SCORE</th>
        <th colSpan={2}>MAJOR AREA OF CONCERN:-</th>
      </tr>
      <tr>
        <th>1</th>
        <th align="left">Last audit's NC verification.</th>
        <th colSpan={3}>{moduleData?.total_last_audit_nc_verification}</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            name="major_area_concern"
            type="text"
            value={moduleData?.major_area_concern}
            onChange={(e: any) => {
              moduleData.major_area_concern = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th>2</th>
        <th align="left">MANAGEMENT OF QUALITY.</th>
        <th colSpan={3}>{moduleData?.total_management_of_quality}</th>
        <th colSpan={2} rowSpan={4} align="left">
          {
            'Criteria for Regular supplier Audit :-  > 60 %  :- Supplier approved (Action plan to be made for improvement & submit when ask by.  51% - 60% : Action plan to be submitted by Supplier with closure in 30 days and re audit to be planned.< 50% ; Action plan to be submitted by Supplier with closure in 40 days and re audit to be planned.Also Qualify in Quality with >65% (Clause-1+Clause-2+Clause-5+Clause-6+Clause-7)'
          }
        </th>
      </tr>
      <tr>
        <th>3</th>
        <th align="left">QUALITY PLANNING AND CHANGE CONTROL.</th>
        <th colSpan={3}>
          {moduleData?.total_quality_planning_and_change_control}
        </th>
      </tr>
      <tr>
        <th>4</th>
        <th align="left">CONTROL OF PURCHAGE MATERIAL</th>
        <th colSpan={3}>{moduleData?.total_control_of_purchase_material}</th>
      </tr>
      <tr>
        <th>5</th>
        <th align="left">CONTROL DURING PROCESSING</th>
        <th colSpan={3}>{moduleData?.total_control_during_processing}</th>
      </tr>
      <tr>
        <th>6</th>
        <th align="left">CONTROL OF FINISHED PRODUCTS</th>
        <th colSpan={3}>{moduleData?.total_control_of_finished_product}</th>
        <th colSpan={2} rowSpan={6} align="left">
          {
            'Criteria for Audit New supplier:-  > 70 %  :- Supplier approved (Action plan to be made for improvement & submit when ask by. 61% - 70% : Action plan to be submitted by Supplier with closure in 90 days and re audit to be planned. Also Qualify in Quality with >75% (Clause-1+Clause-2+Clause-5+Clause-6+Clause-7)'
          }
        </th>
      </tr>
      <tr>
        <th>7</th>
        <th align="left">
          CONTROL OF NONCONFERMING PARTS AND CORRECTIVE & PREVENTIVE ACTIONS.
        </th>
        <th colSpan={3}>
          {
            moduleData?.total_control_of_nonconforming_parts_and_corrective_preventive_actions
          }
        </th>
      </tr>
      <tr>
        <th>8</th>
        <th align="left">CONTROL OF MEASURING AND TEST EQUIPMENT</th>
        <th colSpan={3}>
          {moduleData?.total_control_of_measuring_and_test_equipment}
        </th>
      </tr>
      <tr>
        <th>9</th>
        <th align="left">CONTROL OF 5'S' MANAGEMENT</th>
        <th colSpan={3}>{moduleData?.total_control_of_5s_management}</th>
      </tr>
      <tr>
        <th colSpan={2}>Achived Score:- </th>
        <th colSpan={3}>{moduleData?.total_achieved_score}</th>
      </tr>
      <tr>
        <th colSpan={2}>Maximum. Score:- </th>
        <th colSpan={3}>{moduleData?.total_max_score}</th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          Legend: 0- No system and Docments, 1- System in place but not
          implemented, 2- Well defined systems and implementation available.
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          SIGNATURE OF AUDITOR:- {moduleData?.s3}
        </th>
        <th colSpan={4} align="left">
          SIGNATURE OF AUDITEE:-
          {moduleData?.s1}
        </th>
      </tr>
    </tbody>
  );
};

export default TBody10;
