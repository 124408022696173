import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import TController from './components/TController';
import lodash from 'lodash';

const CreateMachineHistory = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const [unmergeModuleData, setUnmergeModuleData] = useState<any>({});
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const calculateCumulative = (moduleData: any) => {
    //for calcualting  cumulative uses for tool and status
    const values = { ...moduleData };
    let temp = 0;
    let found = false;
    values.list.length > 0 &&
      values.list.forEach((item: any) => {
        item.status = '';
        if (!found) values.maintenance_done = 'completed';
        temp += item.actual_prod;
        item.comulative = temp;

        if (temp >= values.changed_frequncy) {
          found = true;
          temp = 0;
          values.maintenance_done = 'start';
          item.status = 'RED (R)';
        }
      });
    setModuleData(values);
  };
  const mergeAndAccumulateOrdered = (list: any) => {
    if (!list.length) return [];

    const result: any = [];
    const indexMap: any = {};

    list?.forEach((item: any) => {
      if (!item || !item.date) return;

      const date = item?.date?.split('T')[0];

      if (!date.length) return; // Skip if date format is incorrect
      const production = Number(item.actual_prod) || 0;

      if (indexMap.hasOwnProperty(date)) {
        const entryIndex: number = indexMap[date];
        const entry = result[entryIndex];
        const updatedEntry = {
          ...item,
          actual_prod: entry.actual_prod + production,
          prod_logbook_row_id: item?.prod_logbook_row_id,
          _id: item?._id || null,
        };
        result[entryIndex] = updatedEntry;
      } else {
        const newEntry = {
          ...item,
        };
        result.push(newEntry);
        indexMap[date] = result.length - 1;
      }
    });
    return result;
  };
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListOfToolsOrDieRoute/${id}`)
        .then((res) => {
          setUnmergeModuleData(lodash.cloneDeep(res.data));
          const data = res?.data;
          const getMergeList = mergeAndAccumulateOrdered(data.list);
          data.list = getMergeList;
          calculateCumulative(data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  const onSubmit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.list.forEach((item: any) => {
      const getIdx = unmergeModuleData?.list?.findIndex(
        (i: any) => i.prod_logbook_row_id === item.prod_logbook_row_id
      );
      if (getIdx >= 0) {
        unmergeModuleData.list[getIdx] = {
          ...unmergeModuleData.list[getIdx],
          die_condition_after_run: item?.die_condition_after_run || '',
          dot_no: item?.dot_no || '',
          incharge_sign: item?.incharge_sign || '',
          remarks: item?.remarks || '',
          repair_detail_pm_breakdown: item?.repair_detail_pm_breakdown || '',
          sign: item?.sign || '',
          status: item?.status || '',
        };
      }
    });

    await axios
      .put(`/api/masterListOfToolsOrDieRoute/${id}`, unmergeModuleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (rowIdx: number) => {
    let data = { ...moduleData };
    data.histories.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="DIE HISTORY CARD">
          {!isView && (
            <SubmitButton label="Submit" onClick={() => onSubmit()} />
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
          customerData,
          calculateCumulative,
          setModuleData,
        }}
      />

      <DialogSubmit />
    </>
  );
};

export default CreateMachineHistory;
