import React, { useState, useEffect, useRef } from "react";
import { Box, Stack, TableBody, TableCell, TableRow } from "@mui/material";
import axios from "axios";
import { AddButton } from "../../../components/common/button/CustomButtons";
import ChildHeader from "../../../components/ui/ChildHeader";
import { SearchFilterContainer } from "../../../assets/styles/layout/searchFilter.styled";
import {
  CustomPagination,
  PersitedDatePicker,
  TextAreaInput,
} from "../../../components/common";
import { useNavigate } from 'react-router-dom';

import FilterBy from "../../../components/common/FilterBy";
import formatDate from "../../../components/common/formatDate";
import useDebounce from "../../../hooks/useDebounce";
import {
  EditIconButton,
  AddIconButton,
  DeleteIconButton,
  ViewIconButton,
} from "../../../components/common/button/CustomIconButton";
import Create from "./Create";
import ModalCustom from "../../../components/common/Modal";
import { useQuery } from "../../../hooks/UseQuery";
import useConfirm from "../../../components/common/useConfirm";
import { IData } from "./helpers/interfaces";
import ViewFile from "./components/ViewFile";
import TableScroll from "../../../components/ui/renderTable/TableScroll";
import { monthsName } from "./helpers/monthName";
import CustomUploadButton from "../../../components/common/button/CustomUploadButton";
import TableCustom from "../../../components/common/customTable/TableCustom";

const tableHeaderObj = [

  [
    {
      text: "SR. NO.",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
      },
    },
    {
      text: "AUDIT DATE ",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
      },
    },
    {
      text: "DEPARTMENT",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "150px",
        border: "1px solid black",
      },
    },
    {
      text: "NC",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
      },
    },
    {
      text: "RESPONSIBILITY",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
      },
    },
    {
      text: "TARGET DATE",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
      },
    },
    {
      text: "ACTION",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
      },
    },
    {
      text: "CLOSING DATE ",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
      },
    },
    {
      text: "Effectiveness",
      rowspan: 1,
      colspan: 4,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
        textAlign: "center",
      },
    },
    {
      text: "NC REPORT",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
        textAlign: "center",
      },
    },
    {
      text: "Action",
      rowspan: 2,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
        textAlign: "center",
      },
    },
  ],
  [
    ...Array.from(monthsName, (_, index) => ({
      text: monthsName[index],
      rowspan: 1,
      colspan: 1,
      style: {
        background: "#38d9a9",
        minWidth: "100px",
        border: "1px solid black",
      },
    })),
  ],
 
];

const SupplierComplaintsRegister = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [filterSupplier, setFilterSupplier] = useState<any>([]);
  const [filterDept, setFilterDept] = useState<any>([]);
  const [filterDefectsReported, setFilterDefectsReported] = useState<any>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [all, setAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const search = useQuery("search") || "";
  const filter = useQuery("filter") || "createdAt";
  const page = useQuery("page") || 1;
  const supplier = useQuery("supplier");
  const dept = useQuery("dept");
  const defect = useQuery("defects");
  const date = useQuery("date");
  const idRef = useRef("");
  const navigate = useNavigate();


  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this?"
  );

  useEffect(() => {
    axios
      .get("/api/department/department/departmentForAutoComplete")
      .then((res) => {
        if (res?.data) {
          const formattedData = res?.data.map((dept: any) => ({
            name: dept.department_name,
            value: dept.department_name,
          }));
          setFilterDept([...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);
  const fetchData = async () => {
    if (date) {
      const res = await axios
        .get(
          `/api/nctracker/${date}/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&dept=${dept}&all=${all}`
          // `/api/supplierComplaintRoutes/${date}/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&supplier=${supplier}&defects=${defect}&all=${all}`
        )
        .catch((err) => {
          console.log(err);
        });

        console.log("res is res?.data?.data.results ",res?.data?.data.results);
      setData(res?.data?.data?.results);
      setTotalPages(res?.data?.data?.totalPages);
    }
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/nctracker/${id}`)
      .then((res) => {
        if (res.data) {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };

  const uploadFile = async (e: any, id: string) => {
    if (!window.confirm("Are you sure you want upload this Files")) return;
    const files = e.target.files;

    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append("files", files[key]);
    }

    setIsLoading(true);
    axios
      .patch(`/api/nctracker/uploadFile/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert("document upload successly");
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    supplier,
    defect,
    all,
    date,
    dept,
    isOpenPopup,
  ]);

  return (
    <>
      <ChildHeader text="NC SUMMARY TRACKER">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? "Hide" : "Save"}
        />
        <AddButton onClick={() => setIsModal(true)} label="NEW REPORT" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <Box style={{ display: "flex", margin: 2 }}>
          <PersitedDatePicker
            label={`Search by Year`}
            views={["year"]}        
          />

          <FilterBy
            sx={{ minWidth: 500, marginLeft: 15 }}
            name="dept"
            label="Filter By Department"
            options={filterDept}
          />
         
        </Box>
      </SearchFilterContainer>

      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid black" }}
                    >
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid black" }}
                    >
                      {item?.audit_date && 
                      formatDate(item.audit_date)
                      }
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid black" }}
                    >
                      {item?.dept_id?.department_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid black", minWidth: "200px" }}
                    >
                      {item?.nc}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid black", minWidth: "200px" }}
                    >
                      {item?.resp}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid black", minWidth: "200px" }}
                    >
                      {               
                      item?.target_date &&       
                       formatDate(item.target_date)
                      }
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid black" }}
                    >
                      {item?.action}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid black" }}
                    >
                      {item?.closing_date &&   formatDate(item?.closing_date)}
                    </TableCell>
                   
                    {Array.from(monthsName, (_, i) => (
                      <TableCell
                        align="center"
                        sx={
                          item?.[`${monthsName[i]}`] == "OK"
                            ? {
                                minWidth: "150px",
                                border: "1px solid black",
                                background: "#2b8a3e",
                              }
                            : item?.[`${monthsName[i]}`] == "NOT OK"
                            ? {
                                minWidth: "150px",
                                border: "1px solid black",
                                background: "#c92a2a",
                              }
                            : { minWidth: "150px", border: "1px solid black" }
                        }
                      >
                        {item?.[`${monthsName[i]}`]}
                      </TableCell>
                    ))}
                    
                    <TableCell
                      align="center"
                      sx={{ border: "1px solid black" }}
                    >
                     <AddIconButton
                          tooltipTitle="NC REPORT"
                          onClick={() => {
                            navigate(`/common/internalAuditNcSummaryTracker/create/${item?._id}`)
                          }}
                        />
                    </TableCell>

                    <TableCell sx={{ border: "1px solid black" }}>
                      <Stack direction="row" sx={{ justifyContent: "center" }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setComplaintId(item?._id || "");
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomerConcern(item?._id || "");
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="NC SUMMARY TRACKER"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={idRef.current} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default SupplierComplaintsRegister;