const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'चेक पोइंट', style: style1 },
    { text: 'फ्रीक्वेंसी', style: style1 },
    { text: 'क्या होना चाहिए', style: style1 },
    { text: 'ठीक नहीं है तो क्या करे', style: style1 },
    { text: 'पहले', style: style1 },
    { text: 'बाद में', style: style1 },
    { text: 'पहले', style: style1 },
    { text: 'बाद में', style: style1 },
    { text: 'पहले', style: style1 },
    { text: 'बाद में', style: style1 },
    { text: 'पहले', style: style1 },
    { text: 'बाद में', style: style1 },
  ],
];

export const headerConstant = {
  tableCompHeadTitle: 'Annual PM plan Metallic Bin CHECKSHEET ',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getById: 'statusCheckListAnnualPMPlanMetallicBin',
  post: 'statusCheckListAnnualPMPlanMetallicBin',
  update: 'statusCheckListAnnualPMPlanMetallicBin',
  delete: 'statusCheckListAnnualPMPlanMetallicBin',
  masterCheckListAutoComplete:
    'masterCheckListAnnualPMPlanMetallicBin/masterCheckListAutoComplete',
  getMasterCheckListById: 'masterCheckListAnnualPMPlanMetallicBin',
};

export const navigationConstant = {};
