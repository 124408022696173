export const listConstant = {
  childHeader: 'Annual Pm Die Casting Plan list',
  addButtonLable: 'New Annual Pm Die Casting',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Annual Pm Die Casting Plan list',
  theadHeader: 'Annual Pm Die Casting PLAN',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'Annual Pm Die Casting STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'annualPmPlanDieCasting',
  gettableDataurl: 'annualPmPlanDieCasting/monthly',
  getPlanByid: 'annualPmPlanDieCasting',
  createPlanByid: 'annualPmPlanDieCasting',
  updatePlanByid: 'annualPmPlanDieCasting',
  sync: 'annualPmPlanDieCasting/handleSync',
  getAllCheckList: 'annualPmPlanDieCasting/getAllCheckList',
  checkListStatus: 'annualPmPlanDieCasting/handleStatus',
  checklistUpload: 'annualPmPlanDieCasting/handleUpload',
  checklistDeleteImage: 'annualPmPlanDieCasting/handleDeleteImage',
};


export const tilesConstant = {
  childHeaderText: 'Annual Pm Die Casting PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/annualPmDieCastingPlanCreate',
};