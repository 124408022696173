import moment from 'moment';

export const handleChangeModuleData = (
  e: { target: { name: any; value: any } },
  process: any
) => {
  const { name, value } = e.target;
  process[name] = value;
};

export const handleChangeTimeByShift = (moduleData: any, shift: string) => {
  if (moduleData?.processes) {
    switch (shift) {
      case 'A':
        moduleData?.processes.forEach((process: any, index: number) => {
          process.time_start = moment('9:00', 'HH:mm').add(index, 'hours');
          process.time_stop = moment('10:00', 'HH:mm').add(index, 'hours');
        });
        return;
      case 'B':
        moduleData?.processes.forEach((process: any, index: number) => {
          process.time_start = moment('21:00', 'HH:mm').add(index, 'hours');
          process.time_stop = moment('22:00', 'HH:mm').add(index, 'hours');
        });
        return;
      default:
        return;
    }
  }
};
