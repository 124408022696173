import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
interface IProps {
  moduleData: any;
  machineData?: any;
  updateModuleData?: any;
}

const THead = ({ machineData, moduleData, updateModuleData }: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const [render, setRender] = useState<any>('');

  const handleAutocompleteChange = (newValue: any) => {
    updateModuleData('key-pair', null, 'machine_name', {
      machine_name: newValue?.machine_name,
    });
    updateModuleData('key-pair', null, 'machine_no', {
      machine_no: newValue?.machine_no,
    });
    updateModuleData('key-pair', null, 'machine_id', { machine_id: newValue });
    // updateModuleData('key-pair', null, 'machine_id', newValue);
  };
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['F-MR-07', '03', '28.11.2022']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={5}></th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          Department :{' '}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Date :{' '}
        </th>
        <th colSpan={2} align="left">
          Auditor :{' '}
        </th>
        <th colSpan={2} align="left">
          Auditee :{' '}
        </th>
        <th colSpan={2} align="left">
          Counter Measure :{' '}
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
