export const listConstant = {
  childHeader: 'ANNUAL PM UTILITY list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'ANNUAL PM PLAN UTILITY list',
  theadHeader: 'ANNUAL PM PLAN UTILITY',
  extracolSpan: 3,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'annualPmPlanNewUtilityRoute/monthlylist',
  deleteListurl: 'annualPmPlanNewUtilityRoute',
  getListPlanByid: 'annualPmPlanNewUtilityRoute',
  createListPlanByid: 'annualPmPlanNewUtilityRoute',

  // -----plan--
  sync: 'annualPmPlanNewUtilityRoute/handleSync',
  getAllPlanById: 'annualPmPlanNewUtilityRoute/allplansbyid',
  upadateCreateAllPlan: 'annualPmPlanNewUtilityRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'annualPmPlanNewUtilityRoute/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'ANNUAL PM PLAN UTILITY',
};

export const navigationConstant = {
  planRouter: '/common/annualPmPlanNewUtility_PlanCreate',
};
