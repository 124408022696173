import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { createConstant } from '../../constant';
import formatDate from '../../../../../components/common/formatDate';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
// import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import CustomSelect from '../../../../../components/common/SelectField';
interface IProps {
  moduleData: any;
  isView: boolean;
}
const selectSymbolForLevel: any = {
  L1: '◔',
  L2: '◑',
  L3: '◕',
  L4: '⬤',
};

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [data, setData] = useState<any>({});

  return (
    <tbody>
      {moduleData &&
        moduleData?.employee_details?.map((employee: any, index: number) => (
          <tr key={employee._id || index}>
            <th
              style={{
                width: '100px',
                border: '1px solid black',
                textAlign: 'center',
                padding: '0 0.4rem',
                backgroundColor: '#f8f9fa',
              }}
            >
              {index + 1}
            </th>
            <td
              align="center"
              style={{
                border: '1px solid black',
                minWidth: '400px',
              }}
            >
              {employee?.employee_id?.employee_name}
            </td>

            <td
              align="center"
              style={{
                border: '1px solid black',
                fontSize: '1.6rem',
              }}
            >
              {isView ? (
                selectSymbolForLevel[employee.employee_id?.skill_level]
              ) : (
                <CustomSelect
                  name="part_type"
                  value={
                    employee.skill_level ||
                    employee.employee_id?.skill_level ||
                    ''
                  }
                  onChange={(e: any) => {
                    employee.skill_level = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  sx={{
                    fontSize: '1rem',
                    width: '200px',
                    margin: 'auto',
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="L1">◔</MenuItem>
                  <MenuItem value="L2">◑</MenuItem>
                  <MenuItem value="L3">◕</MenuItem>
                  <MenuItem value="L4">⬤</MenuItem>
                </CustomSelect>
              )}
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
