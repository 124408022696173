import moment from 'moment';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot style={{ background: '#a9e34b' }}>
      <tr>
        <th colSpan={7} align="left">
          TOTAL NON CONFORMANCES
          <TableInput
            isView={isView}
            name="total_non_conformance"
            type="text"
            value={moduleData.total_non_conformance}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          AUDITOR SIGNATURE
          <TableInput
            isView={isView}
            name="auditor_sign"
            type="text"
            value={moduleData.auditor_sign}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          CONCERNED AUDITEE SIGNATURE
          <TableInput
            isView={isView}
            name="auditee_sign"
            type="text"
            value={moduleData.auditee_sign}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Prepared By :-
          <TableInput
            isView={isView}
            name="prepared_by"
            type="text"
            value={moduleData.prepared_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3} align="left">
          Approved By :-
          <TableInput
            isView={isView}
            name="approved_by"
            type="text"
            value={moduleData.approved_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
