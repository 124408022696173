// TableRowComponent.jsx
import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { Grid, MenuItem } from '@mui/material';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import lodash from 'lodash';

export const TableRowComponent =
  // React.memo(
  ({
    key,
    item,
    index,
    allmonth,
    updateModuleData,
    handleDelete,
    customerData,
    setPartIdx,
    setIsOpenPopup,
  }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };
    const [render, setRender] = useState(false);

    return (
      <React.Fragment key={key}>
        {/* <tr>
          <td>{index + 1}</td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '400px' }}
              name="checkpoint"
              type="text"
              value={item.checkpoint}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '300px' }}
              name="observation"
              type="text"
              value={item.observation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>

          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '300px' }}
              name="observation_retraining"
              type="text"
              value={item.observation_retraining}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="status"
              type="text"
              value={item.status}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remark"
              type="text"
              value={item.remark}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          {!isView && (
            <td style={{ minWidth: '50px' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handleDelete(index)}
              />
            </td>
          )}
        </tr> */}
        <tr key={index + 'module data'}>
          <td rowSpan={3}>{index + 1}</td>
          <td style={{ width: '250px', minWidth: '250px' }} rowSpan={3}>
            {isView ? (
              item?.part_id && item.part_id?.part_name
            ) : (
              <Grid container xs={12} direction="row" alignItems={'center'}>
                <Grid item xs={10}>
                  <p
                    style={{
                      fontSize: '1.2rem',
                    }}
                  >
                    {typeof item?.part_id === 'object' && item?.part_id !== null
                      ? item.part_id?.part_name
                      : item?.partName || ''}
                    {!item?.part_id && 'Pick Part No.'}
                  </p>
                </Grid>

                {item.part_id ? (
                  <Grid item xs={2}>
                    <RemoveIconButton
                      tooltipTitle="remove part"
                      onClick={() => {
                        item.part_id = null;
                        item.partNumber = null;
                        item.partName = null;
                        setRender((prev: any) => !prev);
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <AddIconButton
                      tooltipTitle="add part"
                      onClick={() => {
                        setIsOpenPopup(true);
                        setPartIdx(index);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </td>
          <td rowSpan={3} style={{ width: '150px', minWidth: '150px' }}>
            {typeof item?.part_id === 'object' && item?.part_id !== null
              ? item.part_id?.part_number
              : item?.partNumber || ''}
          </td>
          <td rowSpan={3} style={{ width: '150px', minWidth: '150px' }}>
            <AutocompleteMuiCustom
              id={'customer_name'}
              label={''}
              option_name={'customer_name'}
              arrayofObj={customerData}
              value={item.customer_id || ''}
              onChange={(e, value) => {
                item.customer_id = value;
                setRender((prev: any) => !prev);
              }}
            />
          </td>
          <th style={{ background: 'yellow' }}>Actual</th>
          {item?.defect_no?.actual.map(
            (actual_qty: any, actual_qty_index: any) => {
              if (actual_qty_index < 19) {
                return (
                  <td style={{ minWidth: '100px' }}>
                    <TableInput
                      isView={isView}
                      name="actual_qty"
                      type="number"
                      value={item.defect_no.actual[actual_qty_index]}
                      onChange={(e) => {
                        item.defect_no.actual[actual_qty_index] =
                          e.target.value;
                        item.defect_no.actual[19] = lodash.sum(
                          item.defect_no.actual.slice(0, 18).map(Number)
                        );
                        item.defect_no.actual[20] =
                          +item.defect_no.actual[18] -
                          +item.defect_no.actual[19];
                        setRender((prev: any) => !prev);
                      }}
                    />
                  </td>
                );
              } else if (actual_qty_index == 19 || actual_qty_index == 20) {
                return (
                  <td style={{ minWidth: '100px' }}>
                    {item.defect_no.actual[actual_qty_index]}
                  </td>
                );
              } else {
                return (
                  <td style={{ minWidth: '100px' }}>
                    <TableInput
                      isView={isView}
                      name="remarks"
                      type="text"
                      value={item.defect_no.actual[actual_qty_index]}
                      onChange={(e) => {
                        item.defect_no.actual[actual_qty_index] =
                          e.target.value;
                        setRender((prev: any) => !prev);
                      }}
                    />
                  </td>
                );
              }
            }
          )}
          {!isView && (
            <td style={{ minWidth: '100px' }} rowSpan={3}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handleDelete(index)}
              />
            </td>
          )}
        </tr>
        <tr>
          <th style={{ background: 'green' }}>Result</th>
          {item?.defect_no?.result.map(
            (result_qty: any, result_qty_index: any) => {
              if (result_qty_index < 19) {
                return (
                  <td style={{ minWidth: '100px' }}>
                    <TableInput
                      isView={isView}
                      name="result_qty"
                      type="number"
                      value={item.defect_no.result[result_qty_index]}
                      onChange={(e) => {
                        item.defect_no.result[result_qty_index] =
                          e.target.value;
                        item.defect_no.result[19] = lodash.sum(
                          item.defect_no.result.slice(0, 18).map(Number)
                        );
                        item.defect_no.result[20] =
                          +item.defect_no.result[18] -
                          +item.defect_no.result[19];
                        setRender((prev: any) => !prev);
                      }}
                    />
                  </td>
                );
              } else if (result_qty_index == 19 || result_qty_index == 20) {
                return (
                  <td style={{ minWidth: '100px' }}>
                    {item.defect_no.result[result_qty_index]}
                  </td>
                );
              } else {
                return (
                  <td style={{ minWidth: '100px' }}>
                    <TableInput
                      isView={isView}
                      name="remarks"
                      type="text"
                      value={item.defect_no.result[result_qty_index]}
                      onChange={(e) => {
                        item.defect_no.result[result_qty_index] =
                          e.target.value;
                        setRender((prev: any) => !prev);
                      }}
                    />
                  </td>
                );
              }
            }
          )}
        </tr>
        <tr>
          <th style={{ background: 'grey' }}>Status</th>
          {item?.defect_no?.status.map(
            (status_qty: any, status_qty_index: any) => {
              if (status_qty_index < 19) {
                return (
                  <td style={{ minWidth: '100px' }}>
                    <TableInput
                      isView={isView}
                      name="result_qty"
                      type="number"
                      value={item.defect_no.status[status_qty_index]}
                      onChange={(e) => {
                        item.defect_no.status[status_qty_index] =
                          e.target.value;
                        item.defect_no.status[19] = lodash.sum(
                          item.defect_no.status.slice(0, 13).map(Number)
                        );
                        item.defect_no.status[20] =
                          +item.defect_no.status[18] -
                          +item.defect_no.status[19];
                        setRender((prev: any) => !prev);
                      }}
                    />
                  </td>
                );
              } else if (status_qty_index == 19 || status_qty_index == 20) {
                return (
                  <td style={{ minWidth: '100px' }}>
                    {item.defect_no.status[status_qty_index]}
                  </td>
                );
              } else {
                return (
                  <td style={{ minWidth: '100px' }}>
                    <TableInput
                      isView={isView}
                      name="remarks"
                      type="text"
                      value={item.defect_no.status[status_qty_index]}
                      onChange={(e) => {
                        item.defect_no.status[status_qty_index] =
                          e.target.value;
                        setRender((prev: any) => !prev);
                      }}
                    />
                  </td>
                );
              }
            }
          )}
        </tr>
      </React.Fragment>
    );
  };
//   (prevProps, nextProps) => {
//     // Check if the relevant data for this row has changed
//     return (
//       prevProps.item === nextProps.item && prevProps.index === nextProps.index
//     );
//   }
// );
