import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import { SelectChangeEvent } from '@mui/material';
import { moduleConstant } from './constant';
import moment from 'moment';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [_, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? `Update ` : `Save`} ${moduleConstant?.moduleName}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`${moduleConstant?.crudApi}/${id}`)
        .then((res) => {
          setModuleData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleMoooduleData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (!moduleData?.equipment_name) {
      alert('Equipment Name is required');
      return;
    }
    if (id) {
      await axios
        .put(`${moduleConstant?.crudApi}/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`${moduleConstant?.crudApi}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'equipment_name'}
        name={'equipment_name'}
        html_for={'equipment_name'}
        label_name={'Equipment Name'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.equipment_name}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'qty'}
        name={'qty'}
        html_for={'qty'}
        label_name={'Quantity'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.qty}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.location}
        onChange={handleMoooduleData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks}
        onChange={handleMoooduleData}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
