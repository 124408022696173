import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../components/common/SelectField';
import { Checkbox, MenuItem } from '@mui/material';
import { handleRej } from '../../helpers/handleRejectionCal';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((spec: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>
            <td style={{ minWidth: '180px' }}>
              <TableInput
                isView={isView}
                name="parameter"
                type="text"
                value={spec.parameter}
                onChange={(e) => {
                  spec.parameter = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '180px' }}>
              <TableInput
                isView={isView}
                name="specification"
                type="text"
                value={spec.specification}
                onChange={(e) => {
                  spec.specification = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '180px' }}>{spec?.method}</td>
            {Array.from({ length: 5 }, (_, obsIndex) => (
              <td
                key={obsIndex + 'obs'}
                style={{
                  textTransform: 'uppercase',
                  // background: obsIndex % 2 ? 'white' : '#63c4ef4d',
                }}
              >
                <ObservationInput
                  {...{ spec, setRender, isView, obsIndex }}
                  obsNumber={0}
                  checkType={spec.appearance}
                />
              </td>
            ))}
            {/* <td style={{ textTransform: 'uppercase', width: '150px' }}>
              {spec.appearance === 'check3' ? (
                isView ? (
                  spec.result
                ) : (
                  <CustomSelect
                    name="dropdown"
                    value={spec.result || ''}
                    onChange={(e) => {
                      spec.result = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="OK">OK</MenuItem>
                    <MenuItem value="NOT OK">NOT OK</MenuItem>
                  </CustomSelect>
                )
              ) : (
                spec.result
              )}
            </td> */}
            {/* <td colSpan={1} style={{ width: '180px' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td> */}
          </tr>
        ))}
      <tr>
        <th colSpan={9} align="left">
          STATUS : -
        </th>
      </tr>
      <tr>
        <th colSpan={2} rowSpan={3}>
          CASTING REJECTION % :-
        </th>
        <th></th>
        <th>TOTAL QTY :-</th>
        {Array.from({ length: 5 }, (_, index) => (
          <td key={index + 'casting total '}>
            <TableInput
              name="total_qty"
              type="number"
              value={moduleData?.casting_rej_percentage?.total_qty[index]}
              onChange={(e) => {
                moduleData.casting_rej_percentage.total_qty[index] =
                  e.target.value;
                handleRej(moduleData, 'casting_rej_percentage', index);
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th></th>
        <th>REJECTION QTY :-</th>
        {Array.from({ length: 5 }, (_, index) => (
          <td key={index + 'casting rej '}>
            <TableInput
              name="rejection_qty"
              type="number"
              value={moduleData?.casting_rej_percentage?.rejection_qty[index]}
              onChange={(e) => {
                moduleData.casting_rej_percentage.rejection_qty[index] =
                  e.target.value;
                handleRej(moduleData, 'casting_rej_percentage', index);
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
        ))}
      </tr>
      <tr style={{ height: '50px' }}>
        <th></th>
        <th>REJECTION PERCENTAGE :-</th>
        {Array.from({ length: 5 }, (_, index) => (
          <td key={index + 'casting rej percent'}>
            {moduleData.casting_rej_percentage.rejection_percentage[index]}
          </td>
        ))}
      </tr>

      <tr>
        <th colSpan={2} rowSpan={3}>
          MACHINING REJECTION % :-
        </th>
        <th></th>
        <th>TOTAL QTY :-</th>
        {Array.from({ length: 5 }, (_, index) => (
          <td key={index + 'maching total '}>
            <TableInput
              name="total_qty"
              type="number"
              value={moduleData?.machining_rej_percentage?.total_qty[index]}
              onChange={(e) => {
                moduleData.machining_rej_percentage.total_qty[index] =
                  e.target.value;
                handleRej(moduleData, 'machining_rej_percentage', index);
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th></th>
        <th>REJECTION QTY :-</th>
        {Array.from({ length: 5 }, (_, index) => (
          <td key={index + 'maching rej '}>
            <TableInput
              name="rejection_qty"
              type="number"
              value={moduleData?.machining_rej_percentage?.rejection_qty[index]}
              onChange={(e) => {
                moduleData.machining_rej_percentage.rejection_qty[index] =
                  e.target.value;
                handleRej(moduleData, 'machining_rej_percentage', index);
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
        ))}
      </tr>
      <tr style={{ height: '50px' }}>
        <th></th>
        <th>REJECTION PERCENTAGE :-</th>
        {Array.from({ length: 5 }, (_, index) => (
          <td key={index + 'maching rej percent'}>
            {moduleData.machining_rej_percentage.rejection_percentage[index]}
          </td>
        ))}
      </tr>
      <tr>
        <th colSpan={2}>Any other problem:-</th>
        {Array.from({ length: 7 }, (_, index) => (
          <td key={index + 'any other '}>
            <TableInput
              name="any_other_problem"
              type="text"
              value={moduleData?.any_other_problem[index]}
              onChange={(e) => {
                moduleData.any_other_problem[index] = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" colSpan={3}>
          QA:-
          <TableInput
            name="qa"
            type="text"
            value={moduleData?.qa}
            onChange={(e) => {
              moduleData.qa = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          Production Manager : -
          <TableInput
            name="prod_manager"
            type="text"
            value={moduleData?.prod_manager}
            onChange={(e) => {
              moduleData.prod_manager = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          Work Manager : -
          <TableInput
            name="works_manager"
            type="text"
            value={moduleData?.works_manager}
            onChange={(e) => {
              moduleData.works_manager = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
