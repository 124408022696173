import { TableInput } from '../../../../../../components/common';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { useQuery } from '../../../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { getTotalColsSpan } from '../../../../../../utils/helpers/utils';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
}

const THead = ({ moduleData, updateModuleData }: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const mold_name = useQuery('mold_name');
  const department_name = useQuery('department_name');
  const frequency = useQuery('frequency');

  const handleInputChange = (
    fieldName: string,
    value: string | number | null,
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(HeaderStandard)}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Date']}
        values={['F-MNT-02D', '11', '01.04.2023']}
        heading={`${headerConstant.tableCompHeadTitle} ${
          moduleData?.checkList_name || ''
        }`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME</th>
        <th colSpan={1}>
          <TableInput
            isView={isView}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={8}></th>
      </tr>

      <tr style={{ height: '50px' }}>
        <th colSpan={2} align="left">
          Mold Name: {mold_name}
        </th>
        <th colSpan={3} align="left">
          Department: {department_name}
        </th>
        <th colSpan={2} align="left">
          Frequency/No. of shots: {frequency}
        </th>
        <th colSpan={2} align="left">
          Date:{formatDate(moduleData.pm_date_1)}
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
