import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  SaveButton,
  SubmitButton,
} from '../../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../../components/ui/ChildHeader';
import { useAuth } from '../../../../../services/auth/AuthProvider';
import TController from './components/view/TController';
import { mergerRowsControlPlan } from './helpers/merger';
interface MyData {
  moduleData: any; // replace PdirType with the type of your `pdir` data
  isLoading: boolean;
  error?: string | null;
}
interface Iprops {
  hideHeader?: boolean;
}
const View = () => {
  const { id } = useParams();
  const [moduleData, setModuleData] = useState<any>();
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/tni/getOneTopicPaper/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  return (
    <Box>
      <TController {...{ moduleData }} />
    </Box>
  );
};

export default View;
