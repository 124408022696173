import React, { useState } from 'react';
import { ISpec } from '../../../helpers/Interface';
import { Divider, Grid, MenuItem, Stack } from '@mui/material';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import CustomSelect from '../../../../../components/common/SelectField';
import Button from '@mui/material/Button';
// import { FlexBox } from '../../../../../components/common';
// import { spclChar } from '../../../helpers/SpecialCharacters';
import criticalParameters from '../../../img/criticalParameters.png';
import minorParameters from '../../../img/minorParmeters.png';
import outSourceProcess from '../../../img/outSourceProcess.png';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
interface Iprop {
  specModalData: ISpec;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSpec: (e: any, specs: ISpec) => void;
  handleAppearance: (e: any, spec: ISpec) => void;
}
const SpecsInputs = (props: Iprop) => {
  const {
    specModalData,
    openModal,
    setOpenModal,
    handleSpec,
    handleAppearance,
  } = props;
  const [render, setRender] = useState<boolean>(false);

  return (
    <>
      <Grid
        container
        gap={3}
        columnGap={4}
        xs={12}
        sx={{ width: '80vw', alignItems: 'center', marginLeft: '4rem' }}
      >
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="machine"
            placeholder="Machine"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.machine}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="char_product"
            placeholder="Product Char."
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.char_product}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="char_process"
            placeholder="Process Char."
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.char_process}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomSelect
            name={'special_character'}
            value={specModalData.special_character}
            label={'Special Char.'}
            onChange={(e) => handleSpec(e, specModalData)}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="CP">
              <img
                src={criticalParameters}
                alt="critical parameteres"
                height={30}
                width={50}
              />
              {'-'}
              Critical Parameters
            </MenuItem>
            <MenuItem value="MP">
              <img
                src={minorParameters}
                alt="minor parameteres"
                height={30}
                width={50}
              />{' '}
              - Minor Parameters
            </MenuItem>
            <MenuItem value="OP">
              <img
                src={outSourceProcess}
                alt="outsource process"
                height={30}
                width={50}
              />{' '}
              - Outsource Process
            </MenuItem>
          </CustomSelect>
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="methods_measure"
            placeholder="Evaluation"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.methods_measure}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="methods_size"
            placeholder="Sample Size"
            // className="section-input"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.methods_size}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="methods_by"
            placeholder="Sample Frequency"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.methods_by}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="responsibility"
            placeholder="Responsibility"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.responsibility}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="methods_process" // control method or current process control
            placeholder="Current Process Control"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.methods_process}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="reaction_plan"
            placeholder="Reaction Plan"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.reaction_plan}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="error_proofing"
            placeholder="Error Proofing"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.error_proofing}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="corrective_action"
            placeholder="Corrective Action"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.corrective_action}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="process_capability"
            placeholder="Process Capability"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.process_capability}
          />
        </Grid>
      </Grid>

      <p
        style={{
          marginLeft: '0.5rem',
          marginTop: '2.3rem',
          fontWeight: 600,
          fontSize: '1.8rem',
        }}
      >
        Product/Process Specification/Tolerance
      </p>
      <Divider />
      <Stack
        mt={2}
        ml={4.2}
        p={1}
        spacing={2}
        direction="row"
        alignItems="center"
      >
        <InputMuiCustom
          type="text"
          sx={{ width: '350px' }}
          name="control_product"
          placeholder="Product/Process Specification/Tolerance"
          onChange={(e) => handleSpec(e, specModalData)}
          value={specModalData.control_product}
        />
        <Button
          sx={{
            fontSize: '2rem',
            backgroundColor: '#e9ecef',
          }}
          onClick={(e) => {
            if (specModalData.control_product) {
              specModalData.control_product += ' ± ';
              setRender((prev) => !prev);
            }
          }}
        >
          ±
        </Button>
        <Button
          sx={{
            fontSize: '2rem',
            backgroundColor: '#e9ecef',
          }}
          onClick={(e) => {
            if (specModalData.control_product) {
              specModalData.control_product += ' ~ ';
              setRender((prev) => !prev);
            }
          }}
        >
          ~
        </Button>
        <Button
          sx={{
            fontSize: '2rem',
            backgroundColor: '#e9ecef',
          }}
          onClick={(e) => {
            specModalData.control_product === null &&
              (specModalData.control_product = '');
            if (!/\b(Max)\b/gi.test(specModalData.control_product)) {
              specModalData.control_product += 'Min ';
              setRender((prev) => !prev);
            }
          }}
        >
          Min
        </Button>
        <Button
          sx={{
            fontSize: '2rem',
            backgroundColor: '#e9ecef',
          }}
          onClick={(e) => {
            specModalData.control_product === null &&
              (specModalData.control_product = '');
            if (!/\b(Min)\b/gi.test(specModalData.control_product)) {
              specModalData.control_product += 'Max ';
              setRender((prev) => !prev);
            }
          }}
        >
          Max
        </Button>
        <Button
          sx={{
            fontSize: '2rem',
            backgroundColor: `${
              specModalData.appearance_exception === 'check3'
                ? '#ffe066'
                : '#e9ecef'
            }`,
            color: `${
              specModalData.appearance_exception === 'check3' && '#343a40'
            }`,
          }}
          onClick={(e) => {
            const appearance_exception: any =
              specModalData?.appearance_exception || '';

            specModalData.appearance_exception =
              appearance_exception === ''
                ? (specModalData.appearance_exception = 'check3')
                : (specModalData.appearance_exception = '');
            setRender((prev) => !prev);
          }}
        >
          Convert Text
        </Button>
      </Stack>
    </>
  );
};

export default SpecsInputs;
