interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
  moduleData: any;
  render: any;
}
const getTotalRejQty = (processes: any) => {
  return processes.reduce(
    (sum: any, curr: any) => sum + +curr.no_of_rejection,
    0
  );
};
const TFoot = ({ preparedBy, approvedBy, moduleData, render }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th colSpan={3}>Total</th>
        <th>{getTotalRejQty(moduleData.processes)}</th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Prepared By: {preparedBy}
        </th>
        <th colSpan={5} align="left">
          Approved By: {approvedBy}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
