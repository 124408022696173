import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import { TbReportSearch } from 'react-icons/tb';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import Reports from './helpers/Reports';
import ModalCustom from '../../../components/common/Modal';

const View = () => {
  const [moduleData, setModuleData] = useState<IData>(
    lodash.cloneDeep(initialState)
  );
  const annual_month = useQuery('annual_date') || '';
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentAutoComplete, setDepartmentAutoComplete] = useState<any>({});
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  useEffect(() => {
    axios
      .get('/api/department/department/departmentForAutocomplete')
      .then((res) => {
        setDepartmentData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (annual_month && departmentAutoComplete?._id) {
      axios
        .get(
          `/api/ppeMonitoringRoute/${annual_month}/${departmentAutoComplete?._id}`
        )
        .then((res) => {
          setModuleData(res?.data);
        })
        .catch((err) => {
          console.log(err);
          setModuleData(lodash.cloneDeep(initialState));
        });
    }
  }, [annual_month, departmentAutoComplete]);

  const handleSumbit = async (isSubmitted: boolean) => {
    const ans = await confirmSubmit();
    if (!ans) return;

    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    moduleData.department_id = departmentAutoComplete?._id;

    if (!moduleData.department_id) {
      alert('Please select a Department');
      return;
    }

    if (moduleData?._id) {
      await axios
        .put(`/api/ppeMonitoringRoute/${moduleData?._id}`, moduleData)
        .then((res) => {
          if (res?.data?.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (!annual_month) {
        return alert('Please pick annual year?');
      }
      moduleData.annual_month = annual_month;
      await axios
        .post('/api/ppeMonitoringRoute', moduleData)
        .then((res) => {
          if (res?.data?.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Box>
        <ChildHeader text="DAILY PPE MONITORING SHEET FOR SAFETY">
          <CustomButton
            sx={{
              backgroundColor: '#228be6',
              color: '#e7f5ff',
              mr: 2,
            }}
            icon={<TbReportSearch />}
            size="large"
            onClick={() => setIsOpenPopup(true)}
          >
            Reports Overview
          </CustomButton>
          {!isView && (
            <>
              {state.user?.userType === state.IS.Supervisor && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => handleSumbit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => handleSumbit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          departmentData,
          setDepartmentData,
          setDepartmentAutoComplete,
          departmentAutoComplete,
        }}
      />
      <ModalCustom
        title={`Generated Reports`}
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <Reports
          annual_month={annual_month}
          setIsOpenPopup={setIsOpenPopup}
          setDepartmentAutoComplete={setDepartmentAutoComplete}
        />
      </ModalCustom>
      <DialogSubmit />
    </>
  );
};

export default View;
