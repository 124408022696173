import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { PersitedDatePicker, TableCustom } from '../../../components/common';
import {
  EditIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../hooks/UseQuery';
import ChildHeader from '../../../components/ui/ChildHeader';
import { getMonthYearly } from './helpers/utils';
import MasterListOfDepartment from './masterListOfDepartment';

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'MONTH & YEAR',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const TableList = () => {
  const [monitoringList, setMoniteringList] = useState<
    { month_year: string }[]
  >([]);
  const navigate = useNavigate();

  const date = useQuery('date');

  useEffect(() => {
    let data = getMonthYearly(date);
    setMoniteringList(data);
  }, [date]);

  return (
    <Box>
      <ChildHeader text="Daily Accident Monitoring Check Sheet" />

      <MasterListOfDepartment />

      {/* <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {monitoringList.map((item: any, index) => (
            <React.Fragment>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item.month_year}</TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/createDailyAccident?isView=true&annual_date=${item.month_year}`
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/createDailyAccident?isView=false&annual_date=${item.month_year}`
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </TableCustom> */}
    </Box>
  );
};

export default TableList;
