import React, { useEffect, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './components/Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

import { IData } from './Interface';
import ViewFile from './components/ViewFile';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Item Name', rowspan: 1, colspan: 1 },
    { text: 'Part Number', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];
const PERPAGE = 10;

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [filterCustomer, setFilterCustomer] = useState([]);

  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE PART List',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/similarPartManagement?page=${page}&sortBy=${filter}&search=${search}&perPage=${PERPAGE}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setTotalPages(res?.data?.pages);
  };

  const deleteDocument = async (documentId: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/similarPartManagement/${documentId}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, filter, search, render, isModal]);
  return (
    <>
      <ChildHeader text="Similar Part Management">
        <AddButton onClick={() => setIsModal(true)} label="New Part List" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Part Name" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 130 }}
            label="Sort By"
            options={filterOptions}
          />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {PERPAGE * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    {item?.part_id?.part_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.part_id?.part_number}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="view"
                        onClick={() => {
                          setId(item._id || null);
                          setIsFileOpenPopup(true);
                        }}
                      />
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setModuleId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteDocument(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title="PART LIST"
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
