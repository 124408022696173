export const listConstant = {
  childHeader: 'Annual PM Plan Trolly list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Annual PM Plan Trolly list',
  theadHeader: 'Annual PM Plan Trolly',
  extracolSpan: 3,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'annualPmPlanTrollyRoute/monthlylist',
  deleteListurl: 'annualPmPlanTrollyRoute',
  getListPlanByid: 'annualPmPlanTrollyRoute',
  createListPlanByid: 'annualPmPlanTrollyRoute',

  // -----plan--
  sync: 'annualPmPlanTrollyRoute/handleSync',
  getAllPlanById: 'annualPmPlanTrollyRoute/allplansbyid',
  upadateCreateAllPlan:
    'annualPmPlanTrollyRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'annualPmPlanTrollyRoute/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'Annual PM Plan Trolly',
};

export const navigationConstant = {
  planRouter: '/common/annualPmPlanTrollyCreate',
};
