interface Ifoot {
  moduleData: any;
  render: any;
}
const getTotalRejQty = (processes: any) => {
  return processes.reduce(
    (sum: any, curr: any) => sum + +curr.no_of_rejection,
    0
  );
};
const TFoot = ({ moduleData, render }: Ifoot) => {
  return (
    <tfoot>
      
    </tfoot>
  );
};

export default TFoot;
