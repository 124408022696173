import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';

interface Ifoot {
  moduleData: any;
  isView: any;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <th align="left" colSpan={5}>
        Horizontally deployed in other areas :{' '}
        <TableInput
          name="horizontal_deploy"
          type="text"
          value={moduleData.horizontal_deploy}
          onChange={(e) => {
            moduleData.horizontal_deploy = e.target.value;
            setRender((prev: any) => !prev);
          }}
          isView={isView}
        />
      </th>
      <tr style={{ height: '60px' }}>
        <th align="left" colSpan={2}>
          HIRA updated:{' '}
          <TableInput
            name="hira_updated"
            type="text"
            value={moduleData.hira_updated}
            onChange={(e) => {
              moduleData.hira_updated = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          Date:{' '}
          <DatePickerMui
            label=""
            value={moment(moduleData.hira_date)}
            onChange={(date: any) => {
              moduleData.hira_date = date;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5}></th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Signature of Production Incharge</th>
        <th align="left">
          <TableInput
            name="sign_prod_incharge"
            type="text"
            value={moduleData.sign_prod_incharge}
            onChange={(e) => {
              moduleData.sign_prod_incharge = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          Date:{' '}
          <DatePickerMui
            label=""
            value={moment(moduleData.sign_prod_incharge_date)}
            onChange={(date: any) => {
              moduleData.sign_prod_incharge_date = date;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Signature of Quality Incharge</th>
        <th align="left">
          <TableInput
            name="sign_quality_incharge"
            type="text"
            value={moduleData.sign_quality_incharge}
            onChange={(e) => {
              moduleData.sign_quality_incharge = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          Date:{' '}
          <DatePickerMui
            label=""
            value={moment(moduleData.sign_quality_incharge_date)}
            onChange={(date: any) => {
              moduleData.sign_quality_incharge_date = date;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Signature of Maintenance Incharge</th>
        <th align="left">
          <TableInput
            name="sign_maint_incharge"
            type="text"
            value={moduleData.sign_maint_incharge}
            onChange={(e) => {
              moduleData.sign_maint_incharge = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          Date:{' '}
          <DatePickerMui
            label=""
            value={moment(moduleData.sign_maint_incharge_date)}
            onChange={(date: any) => {
              moduleData.sign_maint_incharge_date = date;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Signature of HR Incharge</th>
        <th align="left">
          <TableInput
            name="sign_hr_incharge"
            type="text"
            value={moduleData.sign_hr_incharge}
            onChange={(e) => {
              moduleData.sign_hr_incharge = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          Date:{' '}
          <DatePickerMui
            label=""
            value={moment(moduleData.sign_hr_incharge_date)}
            onChange={(date: any) => {
              moduleData.sign_hr_incharge_date = date;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Signature of Factory Manager (G.M)</th>
        <th align="left">
          <TableInput
            name="sign_gm"
            type="text"
            value={moduleData.sign_gm}
            onChange={(e) => {
              moduleData.sign_gm = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          Date:{' '}
          <DatePickerMui
            label=""
            value={moment(moduleData.sign_gm_date)}
            onChange={(date: any) => {
              moduleData.sign_gm_date = date;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr style={{ background: '#9775fa' }}>
        <th colSpan={5}>REVISION HISTORY </th>
      </tr>
      <tr style={{ background: '#38d9a9', height: '60px' }}>
        <th>REV. NO. </th>
        <th>REV. DATE</th>
        <th>REVISION DETAILS</th>
        <th>REVIDED BY.</th>
        <th>APPROVED BY. </th>
      </tr>
      {moduleData &&
        moduleData.revision_history.map((revision: any, index: number) => (
          <>
            <tr>
              <td>
                <TableInput
                  isView={isView}
                  name="rev_no"
                  type="text"
                  value={revision?.rev_no}
                  onChange={(e) => {
                    handleChangeModuleData(e, revision);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <DatePickerMui
                  label=""
                  value={moment(revision.rev_date)}
                  onChange={(date: any) => {
                    revision.rev_date = date;
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="rev_details"
                  type="text"
                  value={revision?.rev_details}
                  onChange={(e) => {
                    handleChangeModuleData(e, revision);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="revided_by"
                  type="text"
                  value={revision?.revided_by}
                  onChange={(e) => {
                    handleChangeModuleData(e, revision);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="approved_by"
                  type="text"
                  value={revision?.approved_by}
                  onChange={(e) => {
                    handleChangeModuleData(e, revision);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </tr>
          </>
        ))}
    </tfoot>
  );
};

export default TFoot;
