import { Box } from '@mui/system';
import axios from 'axios';
import { useMemo, useState } from 'react';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { Loader } from '../../../components/common/Loader';
import HeaderCreate from './HeaderCreate';
import useConfirm from '../../../components/common/useConfirm';
import { useParams } from 'react-router-dom';
import { apiConstant } from './helpers/constant';

interface MyData {
  moduleData: any;
  headerTopicData: any;
  Sync: any;
  isLoading: boolean;
  handelSync: any;
  updateModuleData: any;
  selectionArray: any;
  setSelectionArray: any;
  addRow: any;
  handleDelete: any;
  setTriggerRender: any;
  empData: any;
  DeleteConfirm: any;
  handleBulkDeleteFeature: any;
  isSyncRequired: boolean;
  fetchData: any;
}

const Create = () => {
  const { id: rawId } = useParams<{ id: string }>();
  const id = rawId === 'null' ? undefined : rawId;
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?'
  );

  const {
    moduleData,
    isLoading,
    empData,
    handelSync,
    Sync,
    updateModuleData,
    handleBulkDeleteFeature,
    selectionArray,
    setSelectionArray,
    addRow,
    handleDelete,
    setTriggerRender,
    DeleteConfirm,
    headerTopicData,
    isSyncRequired,
  }: MyData = useGetData(id);

  const onSubmit = async () => {
    const ans = await confirmSave();
    if (!ans) return;
    const hasDuplicateRowId = () => {
      const ids = new Set();
      for (const item of moduleData) {
        if (item?.employee_id?._id) {
          if (ids.has(item.employee_id._id)) {
            return true;
          }
          ids.add(item.employee_id._id);
        }
      }
      return false;
    };

    if (hasDuplicateRowId()) {
      alert('Duplicate row found.!!!🛑');
      return;
    }

    const isAllRowFiled = moduleData.every(
      (item: any) =>
        (item?._id && item?.employee_id === null) || item.employee_id !== null
    );

    if (!isAllRowFiled) {
      alert('All the rows must me filled first!!! ❌');
      return;
    }

    axios
      .post(`/api/${apiConstant.upadateCreateEmpTniReport}`, moduleData)
      .then((res) => {
        alert('updated successfully ');
        setTriggerRender((prev: any) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const isSelected = useMemo(() => {
    // return array of null or id's
    return selectionArray?.filter((item: any) => item !== null);
  }, [selectionArray]);

  return (
    <Box>
      <Loader loader={isLoading} />

      <HeaderCreate
        {...{
          id,
          addRow,
          updateModuleData,
          handelSync,
          onSubmit,
          isSelected,
          handleDelete,
          moduleData,
          setSelectionArray,
          isSyncRequired,
        }}
      />
      <TController
        {...{
          moduleData,
          selectionArray,
          empData,
          updateModuleData,
          handleBulkDeleteFeature,
          headerTopicData,
          isView,
        }}
      />
      <DialogSave isSubmitContent={false} />
      <Sync isSubmitContent={false} />
      <DeleteConfirm isSubmitContent={false} />
    </Box>
  );
};

export default Create;
