// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { Checkbox, MenuItem } from '@mui/material';

export const TableRowComponent = React.memo(
  ({ key, item, index, allmonth, updateModuleData, handleDelete }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null | boolean
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td>{index + 1}</td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '400px' }}
              name="checkpoint"
              type="text"
              value={item.checkpoint}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <Checkbox
              name="firstweek_ok"
              key="firstweek_ok"
              color="primary"
              checked={item.firstweek_ok || ''}
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.checked)
              }}
            />
          </td>
          <td>
            <Checkbox
              name="firstweek_not_ok"
              key="firstweek_not_ok"
              color="primary"
              checked={item.firstweek_not_ok || ''}
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.checked)
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '300px' }}
              name="firstweek_remarks"
              type="text"
              value={item.firstweek_remarks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>

          <td>
            <Checkbox
              name="secondweek_ok"
              key="secondweek_ok"
              color="primary"
              checked={item.secondweek_ok || ''}
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.checked)
              }}
            />
          </td>
          <td>
            <Checkbox
              name="secondweek_not_ok"
              key="secondweek_not_ok"
              color="primary"
              checked={item.secondweek_not_ok || ''}
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.checked)
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '300px' }}
              name="secondweek_remarks"
              type="text"
              value={item.secondweek_remarks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>

          <td>
            <Checkbox
              name="thirdweek_ok"
              key="thirdweek_ok"
              color="primary"
              checked={item.thirdweek_ok || ''}
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.checked)
              }}
            />
          </td>
          <td>
            <Checkbox
              name="thirdweek_not_ok"
              key="thirdweek_not_ok"
              color="primary"
              checked={item.thirdweek_not_ok || ''}
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.checked)
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '300px' }}
              name="thirdweek_remarks"
              type="text"
              value={item.thirdweek_remarks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <Checkbox
              name="fourthweek_ok"
              key="fourthweek_ok"
              color="primary"
              checked={item.fourthweek_ok || ''}
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.checked)
              }}
            />
          </td>
          <td>
            <Checkbox
              name="fourthweek_not_ok"
              key="fourthweek_not_ok"
              color="primary"
              checked={item.fourthweek_not_ok || ''}
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.checked)
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '300px' }}
              name="fourthweek_remarks"
              type="text"
              value={item.fourthweek_remarks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          {!isView && (
            <td style={{ minWidth: '50px' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handleDelete(index)}
              />
            </td>
          )}
        </tr>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
