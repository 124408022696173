import moment from 'moment';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import { TableInput } from '../../../../../components/common';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  setRender: any;
}

const theadData = [
  'Remedial / Corrective Action to Prevent Recurrence',
  'Responsibility',
  'Target date',
  'Status',
];

const THead = ({ moduleData, isView, setRender }: IProps) => {
  const department = useQuery('department');
  const date: any = useQuery('date');
  const nameOfInjuredPersoon = useQuery('nameOfInjuredPersoon');
  const accident_type = useQuery('accident_type');
  const accident_detail = useQuery('accident_detail');
  const reasonForAccident = useQuery('reasonForAccident');
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={['DOC NO.', 'REV.NO.', 'DATE']}
        values={['F-EHS-25A', '01', '26.04.2023']}
        heading="NEAR MISS /INCIDENT/ACCIDENT RECORD"
      />
      <tr style={{ height: '60px', background: '#9775fa' }}>
        <th colSpan={2}> (To be filled by Area Incharge/supervisor)</th>
        <th colSpan={3}>
          {' '}
          (To be submitted to P&A Deptt. within 3 days of incident)
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left" style={{ minWidth: '400px', fontWeight: '100px' }}>
          Department: {department}
        </th>
        <th align="left" style={{ minWidth: '150px', fontWeight: '100px' }}>
          Date: {formatDate(date)}
        </th>
        <th align="left" style={{ minWidth: '150px' }}>
          Time:{' '}
          <TimePicker
            value={moment(moduleData.time)}
            onChange={(date) => {
              moduleData.time = date;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th align="left" style={{ minWidth: '150px' }}>
          Shift:{' '}
          <TableInput
            name="shift"
            type="text"
            value={moduleData.shift}
            onChange={(e) => {
              moduleData.shift = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left">Witness If any</th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">
          Age:{' '}
          <TableInput
            name="age"
            type="text"
            value={moduleData.age}
            onChange={(e) => {
              moduleData.age = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={2}>
          Date of Joining:{' '}
          <DatePickerMui
            label=""
            value={moment(moduleData.date_of_joining)}
            onChange={(date: any) => {
              moduleData.date_of_joining = date;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={2}>
          Employee experience:{' '}
          <TableInput
            name="emp_exp"
            type="text"
            value={moduleData.emp_exp}
            onChange={(e) => {
              moduleData.emp_exp = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Location of Incident</th>
        <th colSpan={4}>
          <TableInput
            name="location"
            type="text"
            value={moduleData.location}
            onChange={(e) => {
              moduleData.location = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Type of Incident / Accident</th>
        <th colSpan={4} align="left">
          {accident_type}
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">
          Name of Person Injured / Nature of Dangerous occurance
        </th>
        <th colSpan={4} align="left" style={{ fontWeight: '100px' }}>
          {nameOfInjuredPersoon}
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">If Own Employee or Contractual</th>
        <th colSpan={4} align="left">
          <CustomSelect
            name="type_of_emp"
            value={moduleData.type_of_emp || ''}
            onChange={(e) => {
              moduleData.type_of_emp = e.target.value;

              setRender((prev: any) => !prev);
            }}
          >
            <MenuItem value="none">NONE</MenuItem>
            <MenuItem value="Own Employee">Own Employee</MenuItem>
            <MenuItem value="Contractual">Contractual</MenuItem>
          </CustomSelect>
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Emp Id / Contractual name</th>
        <th colSpan={4}>
          <TableInput
            name="emp_name"
            type="text"
            value={moduleData.emp_name}
            onChange={(e) => {
              moduleData.emp_name = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Body parts injured(if any)</th>
        <th colSpan={4}>
          <TableInput
            name="body_parts"
            type="text"
            value={moduleData.body_parts}
            onChange={(e) => {
              moduleData.body_parts = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Treatment given in OHC/Hospital</th>
        <th colSpan={4}>
          <TableInput
            name="treatment"
            type="text"
            value={moduleData.treatment}
            onChange={(e) => {
              moduleData.treatment = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">What Job was being done?</th>
        <th colSpan={4}>
          <TableInput
            name="job"
            type="text"
            value={moduleData.job}
            onChange={(e) => {
              moduleData.job = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">
          Brief description of the incident/Accident (How did the
          Incident/Accident occur?)
        </th>
        <th colSpan={4} align="left" style={{ fontWeight: '100px' }}>
          {accident_detail}
        </th>
      </tr>

      <tr style={{ height: '60px', background: '#9775fa' }}>
        <th align="left" colSpan={5}>
          Whether work was carried out according to standard operating
          procedure/practice. If not explain the reason to do so.
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">Reason of incident</th>
        <th colSpan={4} align="left" style={{ fontWeight: '100px' }}>
          {reasonForAccident}
        </th>
      </tr>

      <tr style={{ height: '60px', background: '#9775fa' }}>
        <th align="left" colSpan={5}>
          Use problem solving methods Like Why-Why analysis
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">1 Why ?</th>
        <th colSpan={4}>
          <TableInput
            name="problem_why1"
            type="text"
            value={moduleData.problem_why1}
            onChange={(e) => {
              moduleData.problem_why1 = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">2 Why ?</th>
        <th colSpan={4}>
          <TableInput
            name="problem_why2"
            type="text"
            value={moduleData.problem_why2}
            onChange={(e) => {
              moduleData.problem_why2 = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">3 Why ?</th>
        <th colSpan={4}>
          <TableInput
            name="problem_why3"
            type="text"
            value={moduleData.problem_why3}
            onChange={(e) => {
              moduleData.problem_why3 = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th align="left">4 Why ?</th>
        <th colSpan={4}>
          <TableInput
            name="problem_why4"
            type="text"
            value={moduleData.problem_why4}
            onChange={(e) => {
              moduleData.problem_why4 = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ height: '60px' }}>
        <th colSpan={5} align="left">
          Root cause of incident: {` `}
          <TableInput
            name="root_cause_incident"
            type="text"
            value={moduleData.root_cause_incident}
            onChange={(e) => {
              moduleData.root_cause_incident = e.target.value;
              setRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ background: '#38d9a9', height: '60px' }}>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={index == 0 ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
