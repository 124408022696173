import { TbAspectRatio } from 'react-icons/tb';
const tiles = [
  {
    sNo: 14,
    link: '/common/luxLevelSheet',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'LUX LEVEL',
    span: 'MONITORING SHEET',
    isIcon: true,
  },
];

export default tiles;
