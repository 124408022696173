const moment = require('moment');
export function differenceInDays(date1: any) {
  // Convert the dates to UTC to avoid issues with daylight saving time
  if (date1 === undefined || date1 === null || date1 === '') {
    return null;
  }

  // Your target date
  const targetDate = moment(date1); // Change this to your desired date

  // Current date
  const currentDate = moment();

  // Calculate the difference in days
  const daysLeft = targetDate.diff(currentDate, 'days');

  return daysLeft;
}
