export const checkListList = {
  childHeader: 'Annual PM Plan Metallic Bin STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'annualPmPlanMetallicBinStatusRoute/getAllSatusByYear',
  upadateListStatus: 'annualPmPlanMetallicBinStatusRoute/handleStatusUpdate',
  checklistUpload: 'annualPmPlanMetallicBinStatusRoute/handleUpload',
  checklistDeleteImage:
    'annualPmPlanMetallicBinStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const navigationConstant = {
  checklistRouter: '/common/annualPmPlanMetallicBinStatusCheckList',
};
