export const checkListList = {
  childHeader: 'LAYOUT PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'layoutPlanNewStatusRoute/getAllSatusByYear',
  upadateListStatus: 'layoutPlanNewStatusRoute/handleStatusUpdate',
  checklistUpload: 'layoutPlanNewStatusRoute/handleUpload',
  checklistDeleteImage: 'layoutPlanNewStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: '',
};

export const navigationConstant = {
  checklistRouter: '/common/layoutPlanNewStatusCheckList',
};
