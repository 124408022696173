import { useState } from 'react';
import TBodyQualityRating from './TBodyQualityRating';
import TBodyDeliveryRating from './TBodyDeliveryRating';
import TBodySupplierComplaint from './TBodySupplierComplaint';
import TBodyChange4m from './TBodyChange4m';
import TBodyIncidentOfAccess from './TBodyIncidentOfAccess';
import TBodyCustomerDisruption from './TBodyCustomerDisruption';
import TBodyCustomerNotification from './TBodyCustomerNotification';
import TBodyAuditActionPlan from './TBodyAuditActionPlan';
import TBodyResult from './TBodyResult';
interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView, allmonth }: IProps) => {
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  const [render, setRender] = useState(false);

  const calculateOverAllRating = (index: number) => {
    if (
      moduleData.AAPS_5_percent[index] === undefined ||
      moduleData.AAPS_5_percent[index] === null ||
      moduleData.CN_10_percent[index] === undefined ||
      moduleData.CN_10_percent[index] === null ||
      moduleData.CDLS_10_percent[index] === undefined ||
      moduleData.CDLS_10_percent[index] === null ||
      moduleData.IEF_5_percent[index] === undefined ||
      moduleData.IEF_5_percent[index] === null ||
      moduleData.CD4M_10_percent[index] === undefined ||
      moduleData.CD4M_10_percent[index] === null ||
      moduleData.SC_10_percent[index] === undefined ||
      moduleData.SC_10_percent[index] === null ||
      moduleData.DR_20_percent[index] === undefined ||
      moduleData.DR_20_percent[index] === null ||
      moduleData.QR_30_percent[index] === undefined ||
      moduleData.QR_30_percent[index] === null
    ) {
      moduleData.over_all_rating[index] = null;
      moduleData.card_type[index] = null;
    } else {
      moduleData.over_all_rating[index] =
        +moduleData.AAPS_5_percent[index] +
        +moduleData.CN_10_percent[index] +
        +moduleData.CDLS_10_percent[index] +
        +moduleData.IEF_5_percent[index] +
        +moduleData.CD4M_10_percent[index] +
        +moduleData.SC_10_percent[index] +
        +moduleData.DR_20_percent[index] +
        +moduleData.QR_30_percent[index];
      // for assigning color in card type
      if (moduleData.over_all_rating[index] >= 80) {
        moduleData.card_type[index] = '#2b8a3e';
      }
      if (
        moduleData.over_all_rating[index] < 80 ||
        moduleData.over_all_rating[index] >= 60
      ) {
        moduleData.card_type[index] = '#ffd43b';
      }
      if (moduleData.over_all_rating[index] < 60) {
        moduleData.card_type[index] = '#f03e3e';
      }
    }
  };

  return (
    <>
      {moduleData && moduleData?.QR_target_ppm?.length > 0 && (
        <TBodyQualityRating
          {...{ moduleData, isView, date, setRender, calculateOverAllRating }}
        />
      )}
      {moduleData && moduleData?.DR_schedule_quantity?.length > 0 && (
        <TBodyDeliveryRating
          {...{ moduleData, isView, date, setRender, calculateOverAllRating }}
        />
      )}
      {moduleData && moduleData?.SC_complaint_target?.length > 0 && (
        <TBodySupplierComplaint
          {...{ moduleData, isView, setRender, calculateOverAllRating }}
        />
      )}
      {moduleData && moduleData?.CD4M_first_week_100_percent?.length > 0 && (
        <TBodyChange4m
          {...{ moduleData, isView, setRender, calculateOverAllRating }}
        />
      )}
      {moduleData && moduleData?.IEF_target_percent?.length > 0 && (
        <TBodyIncidentOfAccess
          {...{ moduleData, isView, setRender, calculateOverAllRating }}
        />
      )}
      {moduleData && moduleData?.CDLS_target_no?.length > 0 && (
        <TBodyCustomerDisruption
          {...{ moduleData, isView, setRender, calculateOverAllRating }}
        />
      )}
      {moduleData && moduleData?.CN_target_no?.length > 0 && (
        <TBodyCustomerNotification
          {...{ moduleData, isView, setRender, calculateOverAllRating }}
        />
      )}
      {moduleData && moduleData?.AAPS_within_15_days?.length > 0 && (
        <TBodyAuditActionPlan
          {...{ moduleData, isView, setRender, calculateOverAllRating }}
        />
      )}
      {moduleData && moduleData?.over_all_rating?.length > 0 && (
        <TBodyResult {...{ moduleData }} />
      )}
    </>
  );
};

export default TBody;
