const reasons = [
  { name: 'पहले बता कर छुट्टी पर हो तो', value: 'level1' },
  { name: 'अचानक बिमार हो जाना', value: 'level2' },

  { name: 'ऑपरेटर का एक्सिडेंट हो जाना', value: 'level3' },
  {
    name: 'यदि  ऑपरेटर 60 दिन से ज्यादा लम्बी छुटटीयों के बाद आता है |',
    value: 'level4',
  },
  {
    name: 'नया ऑपरेटर (नई भर्ती)',
    value: 'level5',
  },
  {
    name: 'इंस्पेक्टर पोइजन टेस्ट में फ़ेल हो जाता है तो',
    value: 'level6',
  },
  {
    name: 'निर्धारित समय से ज्यादा काम करना (ओवरटाइम) 12 घंटे से ज्यादा (PDC लाइन पर एक ऑपरेटर 24  घंटे में केवल 12 घंटे काम करते है इसलिए उन का 24  घंटे के बाद  4M चेंज करेगे   )',
    value: 'level7',
  },
  {
    name: 'मशीन में ब्रेकडाउन हो गया हो। (मैंटेनेंस करने के बाद ही चलाना है )',
    value: 'level8',
  },
  {
    name: 'मोल्ड में ब्रेकडाउन हो गया हो।',
    value: 'level9',
  },
  {
    name: 'मशीन/ टूल का  प्रिवेंटिव मेंनटिनेन्स हुआ हो ।',
    value: 'level10',
  },
  {
    name: 'मशीन की प्रिवेंटिव मैंटेनेंस प्लान के अनुसार न होना ',
    value: 'level11',
  },
  {
    name: 'पोका योके का काम करना बंद कर देना',
    value: 'level12',
  },
  {
    name: 'नया पोका योके का लगाना',
    value: 'level13',
  },
  {
    name: 'रॉ मटेरियल/ सप्लायर बदल गया हो।',
    value: 'level14',
  },
  {
    name: 'गलत रॉ मटेरियल का ईस्तेमाल कर लेना',
    value: 'level15',
  },
  {
    name: 'चेक करने का तरीका बदल गया',
    value: 'level16',
  },
  {
    name: 'मशीन पैरामीटर चेंज हुए हो',
    value: 'level17',
  },
  {
    name: 'प्रोसैस में सुधार',
    value: 'level18',
  },
  {
    name: 'इंस्ट्रूमेंट्/ गेज का केलिब्रेशन/  वेलिडेशन के लिये भेजना',
    value: 'level19',
  },
  {
    name: 'पैकिग स्टैंर्ड में बदलाव आया हो',
    value: 'level20',
  },
];

const actionTakens: { [key: string]: any } = {
  level1: [
    {
      name: 'अन-स्किल्ड',
      value: 'action1',
    },
    {
      name: 'स्किल्ड',
      value: 'action2',
    },
  ],
  level2: [
    {
      name: 'अन-स्किल्ड',
      value: 'action1',
    },
    {
      name: 'स्किल्ड',
      value: 'action2',
    },
  ],
  level3: [
    {
      name: 'अन-स्किल्ड',
      value: 'action1',
    },
    {
      name: 'स्किल्ड',
      value: 'action2',
    },
  ],
  level4: [
    {
      name: 'स्किल्ड/ अन-स्किल्ड',
      value: 'action1',
    },
  ],
  level5: [
    {
      name: 'ऑपरेटर का स्किल लेवेल चेक करें',
      value: 'action1',
    },
  ],
  level6: [
    {
      name: 'दूसरा स्किल्ड इंस्पेक्टर लगाना है',
      value: 'action1',
    },
  ],
  level7: [
    {
      name: 'अगर वही ऑपरेटर ओवर टाइम पे मशीन को चलाता है',
      value: 'action1',
    },
  ],
  level8: [
    {
      name: 'मशीन का मैंटेनेंस करना है',
      value: 'action1',
    },
  ],
  level9: [
    {
      name: 'मोल्ड का मैंटेनेंस करना है',
      value: 'action1',
    },
  ],
  level10: [
    {
      name: 'प्रिवेंटिव मैंटेनेंस होने के बाद इंस्पेक्टर से FPA कराकर ओके की रिपोर्ट आने के बाद प्रोडक्शन चालू करें',
      value: 'action1',
    },
  ],
  level11: [
    {
      name: 'अब नॉर्मल स्थिति है जब तक  मेंनटिनेन्स होगा',
      value: 'action1',
    },
  ],
  level12: [
    {
      name: 'ठीक करने के बाद मशीन चलानी है',
      value: 'action1',
    },
  ],
  level13: [],
  level14: [],
  level15: [],
  level16: [
    {
      name: 'नया अपग्रेड इन्सपैक्शन मेथड का ईस्तेमाल करना',
      value: 'action1',
    },
  ],
  level17: [
    {
      name: 'पैरामीटर का वैलिडेसन करना है',
      value: 'action1',
    },
  ],
  level18: [
    {
      name: 'ऑपरेटर को सुधार के बारे में बताना है',
      value: 'action1',
    },
  ],
  level19: [],
  level20: [],
};

const actionTakenTickMarks: { [key: string]: any } = {
  level1action1: {
    change_type: 'P, ABN',
    setup: true,
    training: true,
    retro: false,
    suspected: true,
  },
  level1action2: {
    change_type: 'P',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
  },
  level2action1: {
    change_type: 'UP,ABN',
    setup: true,
    training: true,
    retro: true,
    suspected: true,
  },
  level2action2: {
    change_type: 'UP',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
  },
  level3action1: {
    change_type: 'UP,ABN',
    setup: true,
    training: true,
    retro: false,
    suspected: true,
  },
  level3action2: {
    change_type: 'UP',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
  },
  level4action1: {
    change_type: 'P,ABN',
    setup: true,
    training: true,
    retro: false,
    suspected: true,
  },
  level5action1: {
    change_type: 'P,ABN',
    setup: true,
    training: true,
    retro: false,
    suspected: true,
  },
  level6action1: {
    change_type: 'P,ABN',
    setup: false,
    training: false,
    retro: true,
    suspected: true,
  },
  level7action1: {
    change_type: 'P,ABN',
    setup: false,
    training: false,
    retro: false,
    suspected: true,
  },
  level8action1: {
    change_type: 'UP',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
  },
  level9action1: {
    change_type: 'UP,ABN',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
  },
  level10action1: {
    change_type: 'P',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
  },
  level11action1: {
    change_type: 'UP,ABN',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
  },
  level12action1: {
    change_type: 'P,UP,ABN',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
  },
  level13: {
    change_type: 'P',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
  },
  level14: {
    change_type: 'P',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
  },
  level15: {
    change_type: 'UP,ABN',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
  },
  level16action1: {
    change_type: 'P',
    setup: true,
    training: true,
    retro: true,
    suspected: false,
  },
  level17action1: {
    change_type: 'P',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
  },
  level18action1: {
    change_type: 'P',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
  },
  level19: {
    change_type: 'P',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
  },
  level20: {
    change_type: 'P,UP,ABN',
    setup: false,
    training: true,
    retro: false,
    suspected: false,
  },
};
export { reasons, actionTakenTickMarks, actionTakens };
