import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import { calculateHourDifference } from '../helpers/timeDifferenceHours';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.histories &&
        moduleData.histories.map((spec: any, index: number) => (
          <tr>
            <td>{index + 1}</td>

            <td>
              {isView ? (
                formatDate(spec.date)
              ) : (
                <DatePickerMui
                  value={moment(spec.date || null)}
                  onChange={(date) => {
                    spec.date = date;
                    setRender((prev) => !prev);
                  }}
                  sx={{ width: '150px' }}
                />
              )}
            </td>

            <td>

              {isView ? (
                formatDate(spec.time)
              ) : (
                <TimePicker
                  value={moment(spec.time || null)}
                  onChange={(time) => {
                    spec.time = time;
                    spec.breakdown_hours = calculateHourDifference(
                      spec.time,
                      spec.breakdown_complete_time
                    );
                    setRender((prev) => !prev);
                  }}
                  slotProps={{
                    // pass props `actions={['clear']}` to the actionBar slot
                    actionBar: { actions: ['clear'] },
                  }}
                  sx={{ width: '150px' }}
                />
              )}
            </td>

            <td>
              <TableInput
                isView={isView}
                name="breakdown_details"
                type="text"
                value={spec.breakdown_details}
                onChange={(e) => {
                  spec.breakdown_details = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '250px' }}
              />
            </td>

            <td>
              <TableInput
                isView={isView}
                name="action_taken"
                type="text"
                value={spec.action_taken}
                onChange={(e) => {
                  spec.action_taken = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '500px' }}
              />
            </td>


            <td>
              {isView ? (
                formatDate(spec.breakdown_complete_date)
              ) : (
                <DatePickerMui
                  value={moment(spec.breakdown_complete_date || null)}
                  onChange={(date) => {
                    spec.breakdown_complete_date = date;
                    setRender((prev) => !prev);
                  }}
                  sx={{ width: '150px' }}
                />
              )}
            </td>


            <td>
              {isView ? (
                formatDate(spec.breakdown_complete_time)
              ) : (
                <TimePicker
                  value={moment(spec.breakdown_complete_time || null)}
                  onChange={(time) => {
                    spec.breakdown_complete_time = time;
                    spec.breakdown_hours = calculateHourDifference(
                      spec.time,
                      spec.breakdown_complete_time
                    );
                    setRender((prev) => !prev);
                  }}
                  slotProps={{
                    // pass props `actions={['clear']}` to the actionBar slot
                    actionBar: { actions: ['clear'] },
                  }}
                  sx={{ width: '150px' }}
                />
              )}
            </td>


            {/* <td>
              <TableInput
                isView={isView}
                name="type_of_maintenance"
                type="text"
                value={spec.type_of_maintenance}
                onChange={(e) => {
                  spec.type_of_maintenance = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td> */}
            <td>
              {/* {spec?.breakdown_hours?.toFixed(2)} */}
              <TableInput
                isView={isView}
                name="breakdown_hours"
                type="number"
                value={spec.breakdown_hours}
                onChange={(e) => {
                  spec.breakdown_hours = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>


            <td>
              <TableInput
                isView={isView}
                name="spare_parts_qty"
                type="number"
                value={spec.spare_parts_qty}
                onChange={(e) => {
                  spec.spare_parts_qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>

            <td>
              <TableInput
                isView={isView}
                name="spare_parts_detail"
                type="text"
                value={spec.spare_parts_detail}
                onChange={(e) => {
                  spec.spare_parts_detail = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '250px' }}
              />
            </td>


            {/* <td>
              <TableInput
                isView={isView}
                name="details_of_part"
                type="text"
                value={spec.details_of_part}
                onChange={(e) => {
                  spec.details_of_part = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td> */}
            <td>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
                style={{ width: '250px' }}
              />
            </td>

            
            {!isView && (
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
