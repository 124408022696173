const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'CHECK POINTS', style: style1 },
    { text: 'FREQUENCY', style: style1 },
    { text: 'STANDARD', style: style1 },
    { text: 'ACTION IF NOT AS PER STANDARD', style: style1 },
    { text: 'A / NA', style: style1 },
    { text: 'BEFORE', style: style1 },
    { text: 'AFTER', style: style1 },
    { text: 'BEFORE', style: style1 },
    { text: 'AFTER', style: style1 },
  ],
];

export const headerConstant = {
  tableCompHeadTitle: 'Preventive Maintenance Check Sheet For',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getById: 'statusCheckListRouteAnnualPmPlanNewUtility',
  post: 'statusCheckListRouteAnnualPmPlanNewUtility',
  update: 'statusCheckListRouteAnnualPmPlanNewUtility',
  delete: 'statusCheckListRouteAnnualPmPlanNewUtility',
  masterCheckListAutoComplete:
    'masterCheckListRouteAnnualPmPlanNewUtility/masterCheckListAutoComplete',
  getMasterCheckListById: 'masterCheckListRouteAnnualPmPlanNewUtility',
};

export const navigationConstant = {};
