const reasons = [
  { name: 'पहले बता कर छुट्टी पर हो तो', value: 'level1' },
  { name: 'अचानक बिमार हो जाना', value: 'level2' },

  { name: 'ऑपरेटर का एक्सिडेंट हो जाना', value: 'level3' },
  {
    name: 'यदि  ऑपरेटर 60 दिन से ज्यादा लम्बी छुटटीयों के बाद आता है |',
    value: 'level4',
  },
  {
    name: 'नया ऑपरेटर (नई भर्ती)',
    value: 'level5',
  },
  {
    name: 'इंस्पेक्टर पोइजन टेस्ट में फ़ेल हो जाता है तो',
    value: 'level6',
  },
  {
    name: 'निर्धारित समय से ज्यादा काम करना (ओवरटाइम) 12 घंटे से ज्यादा (PDC लाइन पर एक ऑपरेटर 24  घंटे में केवल 12 घंटे काम करते है इसलिए उन का 24  घंटे के बाद  4M चेंज करेगे   )',
    value: 'level7',
  },
];

const actionTakens: { [key: string]: any } = {
  level1: [
    {
      name: 'अन-स्किल्ड',
      value: 'action1',
    },
    {
      name: 'स्किल्ड',
      value: 'action2',
    },
  ],
  level2: [
    {
      name: 'अन-स्किल्ड',
      value: 'action1',
    },
    {
      name: 'स्किल्ड',
      value: 'action2',
    },
  ],
  level3: [
    {
      name: 'अन-स्किल्ड',
      value: 'action1',
    },
    {
      name: 'स्किल्ड',
      value: 'action2',
    },
  ],
  level4: [
    {
      name: 'स्किल्ड/ अन-स्किल्ड',
      value: 'action1',
    },
  ],
  level5: [
    {
      name: 'ऑपरेटर का स्किल लेवेल चेक करें',
      value: 'action1',
    },
  ],
  level6: [
    {
      name: 'दूसरा स्किल्ड इंस्पेक्टर लगाना है',
      value: 'action1',
    },
  ],
  level7: [
    {
      name: 'अगर वही ऑपरेटर ओवर टाइम पे मशीन को चलाता है',
      value: 'action1',
    },
  ],
};

export { reasons, actionTakens };
