import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { apiConstant } from './helpers/constant';
import moment from 'moment';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';

const ModalInput = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [moduleData, setMoudleData] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Plan' : 'Create Plan'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  // const [department, setDepartment] = useState([]);
  const [filterLocation, setFilterLocation] = useState<any>([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${apiConstant.getListPlanByid}/${id}`)
        .then((res) => setMoudleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get('/api/department/department/departmentForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = Array.from(
            new Set(res.data.map((unit: any) => unit.location))
          )
            .filter((location) => location && location !== '')
            .map((location: any) => ({ name: location, value: location }));

          setFilterLocation(formattedData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setMoudleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/${apiConstant.getListPlanByid}/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`/api/${apiConstant.createListPlanByid}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleData}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date_range'}
        views={['year']}
        value={moduleData?.date_range || null}
        onChange={(date) => {
          moduleData.date_range = moment(date._d).add(20, 'hours');
          setRender((prev) => !prev);
        }}
      />
      {/* <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.location || ''}
        onChange={handleData}
      /> */}

      {/* <AutocompleteGridmui
        label={'Department Name'}
        id="Departmtn"
        option_name={'department_name'}
        value={moduleData?.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          moduleData.department_id = value;
          setRender((prev) => !prev);
        }}
        
        
      /> */}

      <SelectGridMui
        name="location"
        value={moduleData?.location || ''}
        lable={'Location'}
        onChange={(e) => {
          moduleData.location = e.target.value;
          setRender((prev) => !prev);
        }}
      >
        {filterLocation?.map((item: any, index: any) => (
          <MenuItem value={item.value}>{item.name}</MenuItem>
        ))}
      </SelectGridMui>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
