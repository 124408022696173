import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

const TFoot = ({ moduleData, isView }: any) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <td colSpan={2} style={{ textAlign: 'left' }}>
          मेंटेनेंस करने वाले का नाम/sign :-
        </td>
        <td colSpan={6} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="prepared_by"
            type="text"
            value={moduleData.prepared_by}
            onChange={(e: any) => {
              moduleData.prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td colSpan={2} style={{ textAlign: 'left' }}>
          {' '}
          मेंटेनेंस चेक करने वाले का नाम/sign :-{' '}
        </td>
        <td colSpan={6} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="checked_by"
            type="text"
            value={moduleData.checked_by}
            onChange={(e: any) => {
              moduleData.checked_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </tfoot>
  );
};

export default TFoot;
