import React, { useEffect, useState } from 'react';
import { Button, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  AddButton,
  CustomButton,
} from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import ModalInput from './ModalInput';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

import { IData } from './Interface';
import CustomTileButton from '../../../components/common/button/CustomTileButton';
import DescriptionIcon from '@mui/icons-material/Description';
import DatePickerMui from '../../../components/common/DatePickerMui';
import moment from 'moment';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Title', rowspan: 1, colspan: 1 },
    { text: 'Department', rowspan: 1, colspan: 1 },
    { text: 'Date Range', rowspan: 1, colspan: 1 },
    { text: 'Edit Date Range', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'faculty_name', name: 'Name' },
];

const getYearRange = (currentDate: any) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [tniTopicId, setTNITopicId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/api/annualTrainingPlan/getAnnualTraining/yearly/${selectedDate}`)
      .then((res) => {
        setData(res.data.getAllAnnualTraining);
      })
      .catch((err) => console.log(err));
  }, [selectedDate, render, isModal]);

  // const fetchData = async () => {
  //   const res = await axios
  //     .get(
  //       `/api/skillEvaluation/getAllSkillEvaluation/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
  //     )
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setData(res?.data?.results);
  //   setPerPage(res?.data?.perPage);
  //   setTotalPages(res?.data?.pages);
  // };

  const deleteTNITopic = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/annualTrainingPlan/deleteAnnualTraining/${id}`)
      .then((res) => {
        alert('TNI deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  // useDebounce(fetchData, 500, [page, search, render, isModal]);

  return (
    <>
      <ChildHeader text="TRAINING PLAN">
        <AddButton onClick={() => setIsModal(true)} label="New Plan" />
      </ChildHeader>
      {/* <SearchFilterContainer filter={false}>
        <SearchBar label="Search By Title" />
        <FilterBy options={filterOptions} /> 
      </SearchFilterContainer> */}
      <DatePickerMui
        views={['year']}
        label="Year"
        sx={{ margin: '1rem' }}
        value={moment(selectedDate || null)}
        onChange={setSelectedDate}
      />
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.title}</TableCell>
                  <TableCell align="center">
                    {item?.departments?.department_name}
                  </TableCell>
                  <TableCell align="center">
                    {getYearRange(item.created_year)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      overflow: 'auto',
                    }}
                  >
                    <EditIconButton
                      tooltipTitle="EDIT"
                      onClick={() => {
                        setTNITopicId(item?._id || '');
                        setIsModal(true);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          navigate(
                            `/common/create_edit_annualTraning/list/${item._id}?isView=false`
                          );
                        }}
                      />
                      <ViewIconButton
                        tooltipTitle="View Topic paper"
                        onClick={() => {
                          navigate(
                            `/common/create_edit_annualTraning/list/${item._id}?isView=true`
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteTNITopic(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setTNITopicId(null);
          setIsModal(false);
        }}
        title="CREATE NEW TNA"
      >
        <ModalInput id={tniTopicId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
