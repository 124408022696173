import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import useGetData from './helpers/useGetData';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import TController from './components/create/TController';
import { apiConstant } from '../constant';
interface MyData {
  moduleData: any;
  isLoading: boolean;
  error?: string | null;
}
const Create = () => {
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const customer = useQuery('customer');
  const documentId = useQuery('documentId');
  const listItemObjectId = useQuery('listItemObjectId');
  const month = useQuery('month');
  const { moduleData, isLoading, error }: MyData = useGetData(moduleId);

  const onSubmit = async () => {
    let response: any;
    if (moduleData._id) {
      response = await axios
        .put(`/api/${apiConstant.checklistData}/${moduleData._id}`, moduleData)
        .then((res) => {
          alert('updated  successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(
          `/api/${apiConstant.checklistData}?documentId=${documentId}&listItemObjectId=${listItemObjectId}&month=${month}`,
          moduleData
        )
        .then((res) => {
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="Checklist">
          {!isView && (
            <>
              <SubmitButton label="Submit" onClick={() => onSubmit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, isView, customer }} />
    </Box>
  );
};

export default Create;
