import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  isView: boolean;
  moduleData: any;
  machineData: any;
}
const THead = ({ moduleData, machineData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={3}
        labels={['Doc No:', 'REV. NO.:', 'REV. DATE:']}
        values={['F-Prod-08-46', '01', '22.04.2021']}
        heading="PROCESS PARAMETER STANDARD"
      />
      <tr>
        <th colSpan={2} align="left">
          Date:
        </th>
        <th colSpan={6} align="left">
          {isView ? (
            formatDate(moduleData.moduleDate)
          ) : (
            <DatePickerMui
              value={
                (moduleData.moduleDate && moment(moduleData.moduleDate)) || null
              }
              onChange={(date) => {
                moduleData.moduleDate = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          COMPONENT:-
          {moduleData?.part_id?.part_name || moduleData.partName || ''}
        </th>
        <th colSpan={4} align="left">
          PART NO.:-{' '}
          {moduleData?.part_id?.part_number || moduleData.partNumber || ''}
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          MACHINE NO.:-
          {isView || moduleData?._id ? (
            moduleData?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData.machine_id || ''}
              onChange={(e, value) => {
                moduleData.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4} align="left">
          DIE:-{' '}
          <TableInput
            name="die"
            type="text"
            value={moduleData.die}
            onChange={(e) => {
              moduleData.die = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        {[
          'S.No.',
          'Machine Parameters',
          'Specification',
          'Insp. Method',
          'Resp.',
          'Freq.',
          'Remarks',
        ].map((heading) => (
          <th
            key={heading}
            style={{ textTransform: 'uppercase' }}
            colSpan={heading === 'Remarks' ? 2 : 1}
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
