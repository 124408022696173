import { TableCompHead } from '../../../../components/ui';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { getTotalColsSpan } from '../../../../utils/helpers/utils';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import { PiSortAscendingLight } from 'react-icons/pi';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
  handleSort: any;
}

const THead = ({ moduleData, updateModuleData, handleSort }: IProps) => {
  HeaderStandard[0][0]['component'] = (
    <CustomIconButton
      sx={{}}
      tooltipTitle="sort data"
      onClick={() => {
        handleSort();
      }}
    >
      <PiSortAscendingLight style={{ color: '#f8f9fa', fontSize: '2rem' }} />
    </CustomIconButton>
  );

  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(HeaderStandard)}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
