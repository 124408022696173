export const moduleConstant = {
  crudApi: '/api/pressureGaugeRoute',
  childHeader: 'List Of Pressure Gauge',
  btnName: 'New Gauge',
  searchLabel: 'Gauge Id / Range',
  moduleName: 'Pressure Gauge',
};

export const tableHeaderObj = [
  [
    { text: '#', style: { border: '1px solid black' } },
    { text: 'Gauge No.', style: { border: '1px solid black' } },
    {
      text: 'Gauge Id',
      style: { border: '1px solid black' },
    },
    {
      text: 'Range',
      style: { border: '1px solid black' },
    },
    {
      text: 'Make',
      style: { border: '1px solid black' },
    },
    {
      text: 'Least Count',

      style: { border: '1px solid black' },
    },
    {
      text: 'Location',

      style: { border: '1px solid black' },
    },
    {
      text: 'Description',

      style: { border: '1px solid black' },
    },
    {
      text: 'Calibration  Frequency',

      style: { border: '1px solid black' },
    },
    {
      text: 'Cali. On',

      style: { border: '1px solid black' },
    },
    {
      text: 'Next Due',

      style: { border: '1px solid black' },
    },
    {
      text: 'Remarks',
      style: { border: '1px solid black' },
    },
    {
      text: 'Action',
      style: { border: '1px solid black' },
    },
  ],
];
