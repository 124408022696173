import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { moduleConstant } from '../../helpers/constant';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  const yearRange = getYearRange(new Date(moduleData?.date_range));
  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date:']}
        values={['F-PUR-03', '04', '01-01-2021']}
        heading={`${moduleConstant.tableHeader} (YEAR : ${yearRange})`}
      />
      <tr>
        <th colSpan={7} align="left">
          Supplier Name: {moduleData?.supplier_id?.vender_name}
        </th>
        <th colSpan={7} align="left">
          Material Name: {moduleData?.supplier_id?.commodity}
        </th>
      </tr>
    </thead>
  );
};

export default THead;
