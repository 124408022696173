export const moduleConstant = {
  crudApi: '/api/masterListOfPfdRoute',
  childHeader: 'Master List Of PFD',
  btnName: 'New PFD',
  searchLabel: 'Part Name Or Number',
  moduleName: 'Master List of PFD',
};

export const tableHeaderObj = [
  [
    { text: '#', rowspan: 2, colspan: 1, style: { border: '1px solid black' } },
    {
      text: 'Part Name',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Part Number ',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Document ID',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },

    {
      text: 'Rev. No. & Date of Implementation',
      rowspan: 1,
      colspan: 8,
      style: { border: '1px solid black' },
    },
    {
      text: 'Action',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },
  ],
  [
    {
      text: 'Initial Date',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: '1',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black', minWidth: '120px' },
    },
    {
      text: '2',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black', minWidth: '120px' },
    },
    {
      text: '3',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black', minWidth: '120px' },
    },
    {
      text: '4',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black', minWidth: '120px' },
    },
    {
      text: '5',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black', minWidth: '120px' },
    },
    {
      text: '6',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black', minWidth: '120px' },
    },
    {
      text: 'Remarks',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
  ],
];
