const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Check Point for Operator Observation', style: style1 },
    { text: 'Observations', style: style1 },
    { text: 'Observations(If Re-Training Required)', style: style1 },
    { text: 'Status', style: style1 },
    { text: 'Remark', style: style1 },
  ],
];

export const headerConstant = {
  tableCompHeadTitle: 'Operator Observance CHECKSHEET ',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getById: 'statusCheckListRouteOperatorObservancePlan',
  post: 'statusCheckListRouteOperatorObservancePlan',
  update: 'statusCheckListRouteOperatorObservancePlan',
  delete: 'statusCheckListRouteOperatorObservancePlan',
  masterCheckListAutoComplete:
    'masterCheckListRouteOperatorObservancePlan/masterCheckListAutoComplete',
  getMasterCheckListById: 'masterCheckListRouteOperatorObservancePlan',
};

export const navigationConstant = {};
