import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import lodash from 'lodash';
import { useParams } from 'react-router-dom';
import GridInputs from '../../../components/common/inputs/GridInputs';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import { initialState } from './helpers/initialState';
import { moduleConstant } from './helpers/constant';

const ModalInput = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const { supplierId } = useParams();

  const [moduleData, setMoudleData] = useState<any>({
    supplier_id: supplierId,
    ...lodash.cloneDeep(initialState),
  });

  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier Rating' : 'Create Supplier Rating'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${moduleConstant.api}/${id}`)
        .then((res) => setMoudleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setMoudleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/${moduleConstant.api}/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
          setListId('');
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`/api/${moduleConstant.api}`, moduleData)
        .then((res) => {
          setIsModal(false);
          setListId('');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleData}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date_range'}
        views={['year']}
        value={moduleData?.date_range || null}
        onChange={(date) => {
          moduleData.date_range = moment(date._d).add(10, 'hours');
          setRender((prev) => !prev);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
