import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';
import { apiConstant } from './constant';
import moment from 'moment';
import AutocompleteGridmui from '../../../../components/common/autocomplete/AutocompleteGridmui';
import { useQuery } from '../../../../hooks/UseQuery';

const maintenanceObj = {
  april: {
    p: false,
  },
  may: {
    p: false,
  },
  june: {
    p: false,
  },
  july: {
    p: false,
  },
  august: {
    p: false,
  },
  september: {
    p: false,
  },
  october: {
    p: false,
  },
  november: {
    p: false,
  },
  december: {
    p: false,
  },
  january: {
    p: false,
  },
  february: {
    p: false,
  },
  march: {
    p: false,
  },
};

const ModalInput = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    date_range: new Date(),
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const documentType = useQuery('documentType');

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'MACHINE',
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`
  );

  // useEffect(() => {
  //   if (id) {
  //     axios
  //       .get(`/api/${apiConstant.getPlanByid}/${id}`)
  //       .then((res) => setModuleData(res.data))
  //       .catch((err) => console.log(err));
  //   }
  // }, [id]);

  useEffect(() => {
    axios
      .get(`/api/machineRoutes/machine/ForAutocomplete`)
      .then((res) => {
        const response = res.data;
        moduleData.maintenance_list = response.map((item: any) => {
          return {
            machine_id: item._id,
            ...maintenanceObj,
          };
        });
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleMachineData(e: { target: { name: any; value: any } }) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }
    moduleData.document_type = documentType;
    if (id) {
      await axios
        .put(`/api/preventiveMaintenance/updatePreventive/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
          //history.goBack();
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/preventiveMaintenance/create/preventive', moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  //console.log('ggggggggggggggggggggggggg', tableData);
  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleMachineData}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date_range'}
        views={['year']}
        value={moduleData?.date_range || null}
        onChange={(date) => {
          moduleData.date_range = moment(date); //.add(10, 'hours');
          setRender((prev) => !prev);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
