import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { TableCustom } from '../../../../../components/common';
import {
  DeleteIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { apiConstant } from './helpers/constant';

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

interface IProps {
  docFiles: any;
  setIsOpenFiles: any;
  id: any;
}

const ViewFile = ({ id, docFiles, setIsOpenFiles }: IProps) => {
  const deleteFile = async (index: number) => {
    if (!window.confirm('Are you sure you want to delete this file?')) {
      return;
    }
    axios
      .patch(
        `/api/${apiConstant.checklistDeleteImage}/${id.docId}/${id.month}/${index}`
      )
      .then((res) => {
        if (res.data) {
          alert('document deleted successfully');
          setIsOpenFiles(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ width: 500 }}>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {docFiles?.length > 0 &&
            docFiles.map((file: any, index: number) => (
              <React.Fragment key={`${index}_files`}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{file.name}</TableCell>
                  <TableCell align="center">
                    <>
                      <a target={'_blank'} href={file.url}>
                        <ViewIconButton
                          tooltipTitle="view"
                          sx={{ marginLeft: '1.5rem' }}
                        />
                      </a>
                      <DeleteIconButton
                        tooltipTitle="delete"
                        onClick={() => deleteFile(index)}
                      />
                    </>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
    </Box>
  );
};

export default ViewFile;
