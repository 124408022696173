import { TableCompHead } from '../../../../components/ui';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { getTotalColsSpan } from '../../../../utils/helpers/utils';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
}

const THead = ({ moduleData, updateModuleData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(HeaderStandard)}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
