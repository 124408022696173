import moment from 'moment';

// Function to calculate the difference in hours between two given times
export const calculateHourDifference = (startTime: any, endTime: any) => {
  if (!startTime || !endTime) {
    return;
  }

  const startMoment = moment(startTime);
  const endMoment = moment(endTime);

  // Calculate the duration in minutes
  const minutes = endMoment.diff(startMoment, 'minutes');

  return minutes;
};
