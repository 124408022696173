const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'चेक पोइंट', style: style1 },
    { text: 'फ्रीक्वेंसी', style: style1 },
    { text: 'क्या होना चाहिए', style: style1 },
    { text: 'ठीक नहीं है तो क्या करे', style: style1 },
    { text: 'पहले', style: style1 },
    { text: 'बाद में', style: style1 },
    { text: 'पहले', style: style1 },
    { text: 'बाद में', style: style1 },
    { text: 'पहले', style: style1 },
    { text: 'बाद में', style: style1 },
    { text: 'पहले', style: style1 },
    { text: 'बाद में', style: style1 },
    { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'Annual PM Plan Trolly Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'Annual PM Plan Trolly CHECKSHEET ',
};

export const apiConstant = {
  get: 'masterCheckListAnnualPMPlanTrolly',
  getById: 'masterCheckListAnnualPMPlanTrolly',
  post: 'masterCheckListAnnualPMPlanTrolly',
  update: 'masterCheckListAnnualPMPlanTrolly',
  delete: 'masterCheckListAnnualPMPlanTrolly',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
  childHeaderText: 'Annual PM Plan Trolly',
};

export const navigationConstant = {
  createEditChecklist: '/common/annualPMPlanTrollyChecklistCreate',
};
