import moment from 'moment';

// Function to calculate the difference in hours between two given times
export const calculateHourDifference = (startTime: any, endTime: any) => {
  if (!startTime || !endTime) {
    return;
  }
  // Parse the times and dates
  const startMoment = moment(startTime);
  const endMoment = moment(endTime);

  // If the end time is before the start time, add a day to the end time
  if (endMoment.isBefore(startMoment)) {
    endMoment.add(1, 'day');
  }

  // Calculate the duration in milliseconds
  const durationMillis = endMoment.diff(startMoment);

  // Convert milliseconds to hours
  const hours = moment.duration(durationMillis).asHours();

  return hours;
};
