import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { calculateDueDate } from '../../helpers/utils';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}

const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.histories &&
        moduleData?.histories.map((item: any, index: number) => (
          <tr>
            <td>{index + 1}</td>

            <td style={{ width: '180px', minWidth: '180px' }}>
              {isView ? (
                formatDate(item.calibration_done_date) // cal done on
              ) : (
                <DatePickerMui
                  value={
                    item.calibration_done_date
                      ? moment(item.calibration_done_date)
                      : null
                  }
                  onChange={(date) => {
                    item.calibration_done_date = date;
                    item.next_due_date = calculateDueDate(
                      item?.calibration_done_date,
                      Number(moduleData?.frequency_cycle_new)
                    );
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '200px' }}>
              <TableInput
                isView={isView}
                name="cal_done_by"
                type="text"
                value={item.cal_done_by}
                onChange={(e) => {
                  item.cal_done_by = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '200px' }}>
              <TableInput
                isView={isView}
                name="obs"
                type="text"
                value={item.obs}
                onChange={(e) => {
                  item.obs = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ width: '180px', minWidth: '180px' }}>
              {item.next_due_date && formatDate(item.next_due_date)}
            </td>
            <td style={{ minWidth: '200px' }}>
              <TableInput
                isView={isView}
                name="approved_by"
                type="text"
                value={item.approved_by}
                onChange={(e) => {
                  item.approved_by = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td colSpan={isView ? 2 : 1} style={{ minWidth: '200px' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            {!isView && (
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
