import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <>
              <tr>
                <td colSpan={2}>
                  <TableInput
                    isView={isView}
                    name="action"
                    type="text"
                    value={process?.action}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td>
                  <TableInput
                    isView={isView}
                    name="responsibility"
                    type="text"
                    value={process?.responsibility}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td>
                  <DatePickerMui
                    label=""
                    value={moment(process.target_date)}
                    onChange={(date: any) => {
                      process.target_date = date;
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td>
                  <TableInput
                    isView={isView}
                    name="status"
                    type="text"
                    value={process?.status}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
