import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  console.log(moduleData);
  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((oneProcesses: any, index: number) =>
          oneProcesses.process.map((item: any, idx: number) => (
            <tr>
              {idx === 0 && (
                <td rowSpan={oneProcesses?.process?.length}>{index + 1}</td>
              )}

              {idx === 0 && (
                <td rowSpan={oneProcesses?.process?.length}>
                  {moduleData?.capacity}
                </td>
              )}
              {idx === 0 && (
                <td rowSpan={oneProcesses?.process?.length}>
                  {isView ? (
                    formatDate(oneProcesses.month_year)
                  ) : (
                    <DatePickerMui
                      value={
                        (oneProcesses.month_year &&
                          moment(oneProcesses.month_year)) ||
                        null
                      }
                      onChange={(date) => {
                        oneProcesses.month_year = date?._d;
                        console.log(oneProcesses.month_year);
                        setRender((prev) => !prev);
                      }}
                      format={'MMMM-YY'}
                      views={['month', 'year']}
                      sx={{ width: '150px' }}
                    />
                  )}
                </td>
              )}

              <td style={{ minWidth: '150px' }}>{item?.checkpoint}</td>
              <td>
                {isView ? (
                  item.last_filling_date && formatDate(item.last_filling_date)
                ) : (
                  <DatePickerMui
                    value={
                      (item.last_filling_date &&
                        moment(item.last_filling_date)) ||
                      null
                    }
                    onChange={(date) => {
                      item.last_filling_date = date;
                      setRender((prev) => !prev);
                    }}
                    sx={{ width: '180px' }}
                  />
                )}
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="location"
                  type="text"
                  value={item.location}
                  onChange={(e) => {
                    item.location = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="pressure"
                  type="text"
                  value={item.pressure}
                  onChange={(e) => {
                    item.pressure = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="seal_condition"
                  type="text"
                  value={item.seal_condition}
                  onChange={(e) => {
                    item.seal_condition = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td>
                <TableInput
                  isView={isView}
                  name="pipe_condition"
                  type="text"
                  value={item.pipe_condition}
                  onChange={(e) => {
                    item.pipe_condition = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {idx === 0 && (
                <td
                  style={{ minWidth: '100px', textAlign: 'center' }}
                  rowSpan={oneProcesses?.process?.length}
                >
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))
        )}
      <tr>
        <td colSpan={4}>Checked By</td>
        <td colSpan={5}>
          <TableInput
            isView={isView}
            name="checked_by"
            type="text"
            value={moduleData.checked_by}
            onChange={(e) => {
              moduleData.checked_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td></td>
      </tr>
      <tr>
        <td colSpan={4}>Incharge of Maintenance</td>
        <td colSpan={5}>
          <TableInput
            isView={isView}
            name="incharge_of_maintenance"
            type="text"
            value={moduleData.incharge_of_maintenance}
            onChange={(e) => {
              moduleData.incharge_of_maintenance = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td></td>
      </tr>
    </tbody>
  );
};

export default TBody;
