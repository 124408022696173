import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { Grid, SelectChangeEvent } from '@mui/material';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { Checkbox } from '@mui/material';
import GridTimePicker from '../../../components/common/inputs/GridTimePicker';
import { calculateHourDifference } from "../../../helpers/timeDifferenceMinutes";
import moment from 'moment';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
  });
  const [files, setFiles] = useState<any>(null);
  const [images, setImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [problemData, setProblemData] = useState([]);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Breakdown Record' : 'Create Breakdown Record'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/breakDownRecords/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/masterListOfProblem/problem/problemForAutoComplete')
      .then((res) => {
        setProblemData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.machine_id) {
      alert('Please enter machine name');
      return;
    }
    if (!data.problem_id) {
      alert('Please enter Problem');
      return;
    }

    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);
    const formData = new FormData();

    if (typeof data.machine_id === 'object') {
      data.machine_id = data.machine_id._id;
    }
    if (typeof data.problem_id === 'object') {
      data.problem_id = data.problem_id._id;
    }
    for (const key in data) {
      if (data[key] || typeof data[key] == 'boolean') {
        if (key !== 'files' && key !== 'images') {
          formData.append(key, data[key]);
        }
      }
    }

    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (images) {
      for (const key of Object.keys(images)) {
        formData.append('images', images[key]);
      }
    }

    if (id) {
      await axios
        .put(`/api/breakDownRecords/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/breakDownRecords', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridDatePicker
        label_name="Date : "
        html_for={'issue_reported_date'}
        value={data?.issue_reported_date || null}
        onChange={(date) => {
          data.issue_reported_date = date;
        }}
      />
      <Grid item xs={3}>
        <AutocompleteGridmui
          id={'machine_no'}
          label={'Machine : '}
          option_name={'machine_no'}
          arrayofObj={machineData}
          value={data.machine_id || ''}
          onChange={(e, value) => {
            data.machine_id = value;
            setRender((prev) => !prev);
          }}
        />
      </Grid>
      <GridInputs
        id={'assy'}
        name={'assy'}
        html_for={'assy'}
        label_name={'Assy : '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.assy || ''}
        onChange={handleCustomerData}
      />
      <Grid item xs={3}>
        <AutocompleteGridmui
          id={'problem'}
          label={'Problem : '}
          option_name={'problem'}
          arrayofObj={problemData}
          value={data.problem_id || ''}
          onChange={(e, value) => {
            data.problem_id = value;
            setRender((prev) => !prev);
          }}
        />
      </Grid>
      <GridInputs
        id={'action_taken'}
        name={'action_taken'}
        html_for={'action_taken'}
        label_name={'Action Taken : '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.action_taken || ''}
        onChange={handleCustomerData}
      />
      <GridTimePicker
        label_name="B/D Start time : "
        html_for={'bd_start_time'}
        value={moment(data?.bd_start_time || null)}
        onChange={(date) => {
          data.bd_start_time = date;
          const time: any = calculateHourDifference(data.bd_start_time, data.bd_end_time);
          data.down_time_mins = +time;
          data.down_time_hrs = data.down_time_mins / 60;
        }}
      />
      <GridTimePicker
        label_name="B/D End time : "
        html_for={'bd_end_time'}
        value={moment(data?.bd_end_time || null)}
        onChange={(date) => {
          data.bd_end_time = date;
          const time: any = calculateHourDifference(data.bd_start_time, data.bd_end_time);
          data.down_time_mins = +time;
          data.down_time_hrs = data.down_time_mins / 60;
        }}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks : '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleCustomerData}
      />
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Defect photo'}
          multiple
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => {
            if (files) {
              setFiles([...files, ...e.target.files]);
            } else {
              setFiles(e.target.files);
            }
          }}
        />{' '}
      </Grid>
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Attach Supporting Doucments'}
          multiple
          accept="application/pdf,application/doc,application/excel/,application/ppt"
          onChange={(e) => {
            if (files) {
              setFiles([...files, ...e.target.files]);
            } else {
              setFiles(e.target.files);
            }
          }}
        />{' '}
      </Grid>
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
