import { TbAspectRatio } from 'react-icons/tb';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { BiLogoMediumOld } from 'react-icons/bi';
import { LiaCodiepie } from 'react-icons/lia';
import { FaCheckToSlot } from 'react-icons/fa6';
import { AiOutlineFundView } from 'react-icons/ai';
import { MdReceiptLong } from 'react-icons/md';

// import { tilesConstant } from './helpers/constant';

const tiles = [
  {
    sNo: 1,
    link: '/common/masterListofToolProblems',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'List of Tool',
    span: 'Problems',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/masterListofProblems',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'List of Machine',
    span: 'Problems',
    isIcon: true,
  },
];

const Tiles = () => {
  return (
    <>
      <ChildHeader text={'Tool Room'} />
      {/* tilesConstant.childHeaderText */}
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Tiles;
