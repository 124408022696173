import { TableInput } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';

interface IProps {
  moduleData: any;
  machineData?: any;
  updateModuleData?: any;
}

const THead = ({ machineData, moduleData, updateModuleData }: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const inspDate = useQuery('inspDate');

  const handleInputChange = (
    fieldName: string,
    value: string | number | null,
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['FRM-QAS-A-003', '00', '01-04-2023']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />

      <tr>
        <th colSpan={1}>CHECKSHEET NAME</th>
        <th colSpan={1}>{moduleData.checkList_name}</th>
        <th colSpan={9}></th>
      </tr>
      <tr>
        <th>PART NAME</th>
        <th colSpan={1}>{partName}</th>
        <th>Shop/Plant</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="sop_plant"
            type="text"
            value={moduleData.sop_plant}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th>Inspection Date:</th>
        <th colSpan={3} align="left">
          {formatDate(inspDate || '')}
        </th>
      </tr>
      <tr>
        <th>PART NO.</th>
        <th colSpan={1}>{partNumber}</th>
        <th>Line(Vendor)</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="line_vendor"
            type="text"
            value={moduleData.line_vendor}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th>Insp. Result:</th>
        <th colSpan={3} align="left">
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="insp_result"
            type="text"
            value={moduleData.insp_result}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th>Vendor Name</th>
        <th colSpan={1}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="vendor_name"
            type="text"
            value={moduleData.vendor_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th>Batch Code</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="batch_code"
            type="text"
            value={moduleData.batch_code}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={4} align="left"></th>
      </tr>
      <tr>
        <th>Vendor Code</th>
        <th colSpan={1}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="vendor_code"
            type="text"
            value={moduleData.vendor_code}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th>Model</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="model"
            type="text"
            value={moduleData.model}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th>Done by</th>
        <th>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="done_by"
            type="text"
            value={moduleData.done_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th>Checked by</th>
        <th>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="checked_by"
            type="text"
            value={moduleData.checked_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
