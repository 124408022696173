import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../helpers/constant';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData?: any;
}
const THead = ({}: IProps) => {
  const yearRange = useQuery('yearRange');
  const plan_date = useQuery('plan_date');
  const isView = useQuery('isView') === 'true' ? true : false;
  const date = new Date(plan_date || '')?.getFullYear().toString().slice(-2);

  return (
    <thead
      style={{
        position: 'sticky',
        width: '100%',
        top: '-.5rem',
        border: '1px solid black',
        zIndex: '1',
        backgroundColor: 'rgb(79, 129, 189)',
        color: '#fff',
      }}
    >
      <TableCompHead
        colSpan={16 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['DOC NO-', 'REV. NO -', 'REV. DATE -']}
        values={['F-MR-15', ' 01', '01.04.2023']}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />
      {['S.N.', 'PART NAME', 'PART NO.', 'TYPE', 'CUSTOMER', 'STATUS'].map(
        (el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            style={{
              textAlign: 'center',
              ...style1,
              minWidth: `${(el === 'S.N.' && '50px') || style1.minWidth}`,
            }}
          >
            {el}
          </th>
        )
      )}

      {[
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
        'JAN',
        'FEB',
        'MAR',
      ].map((el, index) => (
        <th
          key={`${index}_headcol`}
          align="center"
          style={{ textAlign: 'center', ...style2 }}
        >
          {el}-{index > 8 ? +date + 1 : date}
        </th>
      ))}
      <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
      <th style={{ textAlign: 'center', ...style1, background: '#f03e3e' }}>
        ACTION
      </th>
    </thead>
  );
};

export default React.memo(THead);
