import { MdOutlineDataUsage } from 'react-icons/md';
import { TableInput } from '../../../../../components/common';
import { useState } from 'react';

interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th colSpan={5} align="left">
          Observations/ Comments Of PDC:{' '}
        </th>
        <th colSpan={6} align="left">
          <TableInput
            isView={isView}
            name="observations"
            type="text"
            value={moduleData.observations}
            onChange={(e) => {
              moduleData.observations = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Action Taken By Tool Room:{' '}
        </th>
        <th colSpan={6} align="left">
          <TableInput
            isView={isView}
            name="actionTaken"
            type="text"
            value={moduleData.actionTaken}
            onChange={(e) => {
              moduleData.actionTaken = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          No. of NG Dimensions:{' '}
        </th>
        <th colSpan={6} align="left">
          <TableInput
            isView={isView}
            name="ngDimensions"
            type="text"
            value={moduleData.ngDimensions}
            onChange={(e) => {
              moduleData.ngDimensions = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th rowSpan={3} colSpan={2} align="left">
          APPROVAL
        </th>
        <th colSpan={3} align="left">
          TOOL ROOM:{' '}
        </th>
        <th colSpan={6} align="left">
          <TableInput
            isView={isView}
            name="toolRoom"
            type="text"
            value={moduleData.toolRoom}
            onChange={(e) => {
              moduleData.toolRoom = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          PDC PRODUCTION:{' '}
        </th>
        <th colSpan={6} align="left">
          <TableInput
            isView={isView}
            name="pdcProduction"
            type="text"
            value={moduleData.pdcProduction}
            onChange={(e) => {
              moduleData.pdcProduction = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          QUALITY:{' '}
        </th>
        <th colSpan={6} align="left">
          <TableInput
            isView={isView}
            name="quality"
            type="text"
            value={moduleData.quality}
            onChange={(e) => {
              moduleData.quality = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
