import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useState } from 'react';
interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TController = ({
  moduleData,
  isView,
  handleSumbit,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handleSumbit,
            handelDeleteRows,
            isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
            setRender,
          }}
        />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
          moduleData={moduleData}
          render={render}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
