// TableRowComponent.jsx
import React, { useCallback } from 'react';
import { TableInput } from '../../../../components/common';
import {
  StyledCheckbox,
  StyledDiv,
  StyledSpan,
  StyledTableRow,
} from '../helpers/module.styled';

export const TableRowComponent = React.memo(
  ({ key, item, index, handleInputChange, id }: any) => {
    const getColors = useCallback((option_type: string) => {
      if (!id) return '';
      const { answer, correct_answer } = item;
      if (correct_answer === option_type) {
        return '#69db7c';
      }
      if (correct_answer !== answer && answer === option_type) {
        return '#ff8787';
      }
      return '';
    }, []);

    return (
      <React.Fragment key={key}>
        <StyledTableRow index={index}>
          <td rowSpan={2}>{item.s_no}</td>
          <td rowSpan={2}>{item.question}</td>
          <td
            style={{
              background: getColors('option_a'),
            }}
          >
            <StyledDiv>
              <StyledSpan>a)</StyledSpan>
              <TableInput
                isView={true}
                divStyle={{ width: '200px', flex: 1 }}
                spanStyle={{ height: '50px' }}
                name="option_a"
                type="text"
                value={item.option_a}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.name, e.target.value, index)
                }
              />
              {!id && (
                <StyledCheckbox
                  checked={item.answer === 'option_a'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange('answer', 'option_a', index)
                  }
                />
              )}
            </StyledDiv>
          </td>
          <td
            style={{
              background: getColors('option_b'),
            }}
          >
            <StyledDiv>
              <StyledSpan>b)</StyledSpan>
              <TableInput
                isView={true}
                divStyle={{ width: '200px', flex: 1 }}
                spanStyle={{ height: '50px' }}
                name="option_b"
                type="text"
                value={item.option_b}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.name, e.target.value, index)
                }
              />
              {!id && (
                <StyledCheckbox
                  checked={item.answer === 'option_b'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange('answer', 'option_b', index)
                  }
                />
              )}
            </StyledDiv>
          </td>
          <td rowSpan={2}>{item.obtained_marks}</td>
          <td rowSpan={2}>{item.max_marks}</td>
        </StyledTableRow>
        <StyledTableRow index={index}>
          <td
            style={{
              background: getColors('option_c'),
            }}
          >
            <StyledDiv>
              <StyledSpan>c)</StyledSpan>
              <TableInput
                isView={true}
                divStyle={{ width: '200px', flex: 1 }}
                spanStyle={{ height: '50px' }}
                name="option_c"
                type="text"
                value={item.option_c}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.name, e.target.value, index)
                }
              />

              {!id && (
                <StyledCheckbox
                  checked={item.answer === 'option_c'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange('answer', 'option_c', index)
                  }
                />
              )}
            </StyledDiv>
          </td>
          <td
            style={{
              background: getColors('option_d'),
            }}
          >
            <StyledDiv>
              <StyledSpan>d)</StyledSpan>
              <TableInput
                isView={true}
                divStyle={{ width: '200px', flex: 1 }}
                spanStyle={{ height: '50px' }}
                name="option_d"
                type="text"
                value={item.option_d}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.name, e.target.value, index)
                }
              />
              {!id && (
                <StyledCheckbox
                  checked={item.answer === 'option_d'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange('answer', 'option_d', index)
                  }
                />
              )}
            </StyledDiv>
          </td>
        </StyledTableRow>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
