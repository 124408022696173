import React, { useState } from 'react';
import { TableRowComponent1 } from './TableRowComponent1';
import { TableRowComponent2 } from './TableRowComponent2';
import { HeaderStandard_1, HeaderStandard_2 } from '../helpers/constant';
import { CustomButton } from '../../../../components/common/button/CustomButtons';
import { IoMdAdd } from 'react-icons/io';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';

interface IProps {
  moduleData: any;
  addRowList1: any;
  addRowList2: any;
  updateModuleData: any;
  handleDeleteRows: any;
  machineData?: any;
}
const TBody = ({
  moduleData,
  updateModuleData,
  addRowList1,
  addRowList2,
  handleDeleteRows,
}: IProps) => {
  // console.log(moduleData);

  HeaderStandard_1[0][HeaderStandard_1[0].length - 1]['component'] = (
    <CustomButton
      sx={{
        backgroundColor: '#228be6',
        color: '#e7f5ff',
      }}
      icon={<IoMdAdd />}
      size="large"
      onClick={addRowList1}
    >
      ADD ROW
    </CustomButton>
  );

  HeaderStandard_2[0][HeaderStandard_2[0].length - 1]['component'] = (
    <CustomButton
      sx={{
        backgroundColor: '#228be6',
        color: '#e7f5ff',
      }}
      icon={<IoMdAdd />}
      size="large"
      onClick={addRowList2}
    >
      ADD ROW
    </CustomButton>
  );

  return (
    <tbody>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard_1} />
      {moduleData?.list_1?.map((item: any, index: any) => (
        <TableRowComponent1
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            updateModuleData,
            handleDeleteRows,
          }}
        />
      ))}
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard_2} />
      {moduleData?.list_2?.map((item: any, index: any) => (
        <TableRowComponent2
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            updateModuleData,
            handleDeleteRows,
          }}
        />
      ))}
    </tbody>
  );
};

export default React.memo(TBody);
