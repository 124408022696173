import { Stack } from '@mui/material';
import {
  AddButton,
  CustomButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { PersitedDatePicker } from '../../../components/common';
import useConfirm from '../../../components/common/useConfirm';
import { moduleConstant } from './constant';
import axios from 'axios';
import { useQuery } from '../../../hooks/UseQuery';
import { MdDeleteForever } from 'react-icons/md';

const HeaderCreate = ({
  handleUpdate,
  setModuleData,
  moduleData,
  setRenderforUpdate,
}: any) => {
  const date = useQuery('date');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `Save`,
    `Are you sure you want to save this?`
  );

  const [DelelteReport, confirmDelete] = useConfirm(
    'Add Row',
    'Are you sure you want reset the report?'
  );

  const handleReportDelete = async () => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`${moduleConstant.apiConstant}/${moduleData?._id}`)
      .then((res) => {
        setRenderforUpdate((prev: any) => !prev);
        alert('successfully Deleted');
      })
      .catch((err) => console.error(err));
  };

  const handleSumbit = async () => {
    if (!date) return;
    const ans = await confirmSubmit();
    if (!ans) return;
    await axios
      .post(
        `${moduleConstant.apiConstant}?date=${date}&departmentId=${moduleData.department_id?._id}`
      )
      .then((res) => {
        setRenderforUpdate((prev: any) => !prev);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 2,
          pb: 1,
          pt: 2,
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'start',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <PersitedDatePicker
            label="Search By Year"
            views={['year', 'month']}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            gap: 2,
          }}
        >
          <CustomButton
            sx={{
              backgroundColor: '#c92a2a',
              color: '#e7f5ff',
            }}
            icon={<MdDeleteForever />}
            size="large"
            disabled={!moduleData._id}
            onClick={() => handleReportDelete()}
          >
            RESET REPORT
          </CustomButton>

          <AddButton
            disabled={moduleData._id}
            onClick={() => handleSumbit()}
            label="CREATE REPORT"
          />
          <UpdateButton
            label="update"
            sx={{ mr: '37px', fontSize: '2rem' }}
            size="large"
            onClick={() => {
              handleUpdate();
            }}
          />
        </Stack>
      </Stack>
      <DialogSubmit />
      <DelelteReport />
    </>
  );
};

export default HeaderCreate;
