import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const THead = ({ moduleData, isView }: IProps) => {
  const created_year = useQuery('created_year');
  const yearRange = getYearRange(created_year);

  const year = +new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={20}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['F-MR-03', '01', '26.10.2020']}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />
      <tr style={{ height: '60px', background: '#a9e34b' }}>
        {[
          'S.No.',
          'Parameters',
          'Key result area (KRA)',
          'UOM',
          'Status',
          'Target',
          `APR-${year}`,
          `MAY-${year}`,
          `JUN-${year}`,
          `JUL-${year}`,
          `AUG-${year}`,
          `SEP-${year}`,
          `OCT-${year}`,
          `NOV-${year}`,
          `DEC-${year}`,
          `JAN-${year + 1}`,
          `FEB-${year + 1}`,
          `MAR-${year + 1}`,
          `Total`,
          'Delete',
        ].map((el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            style={{ textAlign: 'center' }}
            // colSpan={index >= 3 ? 4 : 1}
          >
            {el}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
