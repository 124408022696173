import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

import ObservationInput from './ObservationInput';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((spec: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>

            <td>{spec.parameter}</td>
            <td>{spec?.specification}</td>
            <td>{spec?.method}</td>
            {Array.from({ length: 8 }, () => null).map((_, obsIndex) => (
              <ObservationInput
                {...{ spec, isView, obsIndex }}
                obsNumber={0}
                checkType={spec.appearance}
              />
            ))}
            <td colSpan={1} style={{ width: '180px' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      <tr>
        <th colSpan={4}>Supervisor Sign ➡️</th>
        {Array.from({ length: 17 }, (_, index) => (
          <th key={index + 'Heading'}>
            <TableInput
              isView={isView}
              name="remarks"
              type="text"
              value={moduleData.supervisor_sign[index]}
              onChange={(e) => {
                moduleData.supervisor_sign[index] = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        ))}
      </tr>
    </tbody>
  );
};

export default TBody;
