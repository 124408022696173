import { Box, SelectChangeEvent } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { handleChangeTimeByShift } from '../../helpers/handleChangeModuleData';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  autoCompleteMachineName: any;
  setAutoCompleteMachineName: any;
  toolsData: any;
}

const TController = ({
  moduleData,
  isView,
  handleSumbit,
  handelDeleteRows,
  autoCompleteMachineName,
  setAutoCompleteMachineName,
  toolsData,
}: IProps) => {
  const [machineData, setMachine] = useState({});
  useEffect(() => {
    axios
      .get('/api/machineRoutes/')
      .then((res) => {
        if (res.data) {
          setMachine(res.data.results);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const [render, setRender] = useState(false);
  const handleChangeShift = (e: any) => {
    const { name, value } = e.target;
    moduleData[name] = value;
    handleChangeTimeByShift(moduleData, value);
    setRender((prev) => !prev);
  };
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            machineData,
            autoCompleteMachineName,
            setAutoCompleteMachineName,
            handleChangeShift,
          }}
        />
        <TBody
          {...{
            autoCompleteMachineName,
            handleSumbit,
            moduleData,
            isView,
            handelDeleteRows,
            toolsData,
          }}
        />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
