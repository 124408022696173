export const checkListList = {
  childHeader: 'STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'internalAuditPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'internalAuditPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'internalAuditPlanStatusRoute/handleUpload',
  checklistDeleteImage: 'internalAuditPlanStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const navigationConstant = {
  checklistRouter: '/common/internalAuditStatusCheckList',
};
