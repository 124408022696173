import { createConstant } from '../../constant';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = () => {
  return (
    <tfoot>
      <tr>
        <th colSpan={2} align="left">
          PREPARED BY :
        </th>
        <th colSpan={2} align="left">
          APPROVED BY :
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
