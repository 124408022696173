import { useState } from 'react';
import { TableCompHead } from '../../../../components/ui';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={34}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-PROD-28', '06', '01.04.2023']}
        heading="4M Change Detail"
      />
      <tr>
        <th rowSpan={2}>MACHINE NUMBER</th>
        <th rowSpan={2}>DATE</th>
        <th colSpan={2}>DURATION DATE</th>
        <th rowSpan={2}>TOTAL MINTUS</th>
        <th rowSpan={2}>REASON OF CHANGE( MAN,MACHINE, MATERIAL, METHOD)</th>
        <th rowSpan={2}>ACTION TAKEN CONDITION</th>
        <th rowSpan={2}>CHANGE TYPE P/ UP/ ABN</th>
        <th rowSpan={2}>PART NAME/NO.</th>
        <th colSpan={3}>DETAILS OF CHANGE</th>
        <th colSpan={4}>ACTION TAKEN (TICKMARK)</th>
        <th colSpan={3}>RETROACTIVE</th>
        <th colSpan={3}>SUSPECTED</th>
        <th rowSpan={2}>SIG. PROD. HEAD</th>
        <th rowSpan={2}>SIG. QA INSPECTOR</th>
        <th rowSpan={2}>SIG. QA HEAD</th>
        <th rowSpan={2}>SIG. MAINT HEAD</th>
        <th colSpan={4}>DISPATCH DETAIL</th>
        <th rowSpan={2}>SETUP</th>
        <th rowSpan={2}>RETRO</th>
        <th rowSpan={2}>SUSPECTED</th>
        {!isView && <th rowSpan={2}>ACTION</th>}
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'FROM',
          'TILL',
          'OLD',
          'NEW',
          'INFORM TO QA',
          'SET UP APPROVAL',
          'TRAINING PROVIDED',
          'RETROACTIVE',
          'SUSPECTED',
          'QTY PRODUCED',
          'OK QTY',
          'REJ. QTY',
          'QTY PRODUCED',
          'OK QTY',
          'REJ. QTY',
          'CUSTOMER',
          'HEAT NO',
          'INVOICE NO / BATCH CODE',
          'REMARKS',
        ].map((head) => (
          <th style={{ textTransform: 'uppercase' }}>{head}</th>
        ))}
      </tr>
      <tr></tr>
    </thead>
  );
};

export default THead;
