//import './styles.css' 

import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { EditorProvider, useCurrentEditor, useEditor , EditorContent} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React from 'react'
import { FaBold , FaItalic, FaUnderline, FaStrikethrough, FaHeading, FaListOl, FaListUl, FaQuoteLeft, FaParagraph } from 'react-icons/fa6';
import { LuHeading1, LuHeading2, LuHeading3, LuHeading4, LuHeading5, LuHeading6 } from "react-icons/lu";
import { VscHorizontalRule } from "react-icons/vsc";
import { MdOutlineWrapText } from "react-icons/md";
import { FaRedo, FaUndo } from 'react-icons/fa';
import Underline from '@tiptap/extension-underline';
import { Padding } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useCallback } from 'react'


const MenuBar = ({editor}) => {
  //const { editor } = useCurrentEditor()

  if (!editor) {
    return null
  }

  return (
    <div className="control-group" style={{ display:'flex', justifyContent:'center', border:'none'}}>
      <div className="button-group" style={{backgroundColor:'white', borderRadius:'10px', marginBottom:'10px', maxWidth:'635px', minWidth:'635px', paddingLeft:'10px', paddingRight:'10px', paddingTop:'5px', paddingBottom:'6px'}}>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <FaBold />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <FaItalic />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          }
          className={editor.isActive('underline') ? 'is-active' : ''}
        >
          <FaUnderline />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          <FaStrikethrough />
        </button>
        
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          <FaParagraph />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          <LuHeading1 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          <LuHeading2 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          <LuHeading3 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          <LuHeading4 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          <LuHeading5 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          <LuHeading6 />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <FaListUl />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <FaListOl />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          <FaQuoteLeft />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <VscHorizontalRule />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().setHardBreak().run()}>
          <MdOutlineWrapText />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().undo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .undo()
              .run()
          }
        >
          <FaUndo />
        </button>
        <button style={{fontSize:'18px', outline:'none', border:'none', marginBottom:'2px', paddingTop:'5px', margintop:'2px',borderRadius:'10px', margin:'2px' }}
          onClick={() => editor.chain().focus().redo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .redo()
              .run()
          }
        >
          <FaRedo />
        </button>
      </div>
    </div>
  )
}

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    history: true,
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  Underline
]




const TipTap = ({setDesc, initialContent}) => {
  const [localContent, setLocalContent] = useState(initialContent);
  const editor = useEditor({
    extensions: extensions,
    content: localContent,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setLocalContent(html)
    },
  });

  useEffect(() => {
    if (editor && initialContent  !== localContent) {
      editor.commands.setContent(initialContent);
      setLocalContent(initialContent);
    }
  }, [editor, initialContent]);

  const handleBlur = useCallback(() => {
    setDesc(localContent);
  }, [setDesc, localContent]);


  
  
  return (
    <div onBlur={handleBlur}>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
    
    
  );
};

export default TipTap;