import React, { useState } from 'react';
import { TableRowComponent } from './TableRowComponent';
import StampComponent from '../helpers/stamp';

interface IProps {
  moduleData: any;
  handleDelete: any;
  updateModuleData: any;
  machineData?: any;
  id?: any;
}
const TBody = ({ handleDelete, moduleData, updateModuleData, id }: IProps) => {
  const handleInputChange = (
    fieldName: string,
    value: string | number | null,
    index: number,
    ishandleCalc: boolean = false
  ) => {
    updateModuleData(
      'row',
      index,
      fieldName,
      { [fieldName]: value },
      ishandleCalc
    );
  };

  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            updateModuleData,
            handleDelete,
            id,
            handleInputChange,
          }}
        />
      ))}
      <tr>
        <th align="right" colSpan={4}>
          TOTAL MARKS
        </th>
        <th>{moduleData?.total_obtained_marks}</th>
        <th>{moduleData?.total_max_marks}</th>
      </tr>
      <tr>
        <th align="right" colSpan={4}>
          PERCENTAGE
        </th>
        <th colSpan={2}>
          {moduleData?.percentage}
          {moduleData?.percentage >= 0 && '%'}
        </th>
      </tr>
      {moduleData?.percentage >= 0 && id && (
        <StampComponent passed={moduleData?.percentage >= 60 ? true : false} />
      )}
    </tbody>
  );
};

export default React.memo(TBody);
