import { TableInput } from '../../../../components/common';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React, { useState } from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { StyledNotesField } from '../../../../components/common/FormControlMui';
import DescriptionTextfield from '../../../../components/common/DescriptionNotes';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import { convToTime } from '../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
}

const THead = ({ moduleData, updateModuleData }: IProps) => {
  const [render, setRender] = useState<any>('');
  const isView = useQuery('isView') === 'true' ? true : false;
  const binNumber = useQuery('binNumber');

  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['F-MNT-03 AH', '01', ' 01.03.2021']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={10}></th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Trolly Number :- {binNumber}
        </th>
        <th colSpan={2} align="left">
          Date :-
          {isView ? (
            formatDate(moduleData.date1)
          ) : (
            <DatePickerMui
              label=""
              value={moduleData?.date1 ? moment(moduleData.date1) : null}
              onChange={(date1) => {
                handleInputChange('date1', date1);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          Date :-
          {isView ? (
            formatDate(moduleData.date2)
          ) : (
            <DatePickerMui
              label=""
              value={moduleData?.date2 ? moment(moduleData.date2) : null}
              onChange={(date2) => {
                handleInputChange('date2', date2);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          Date :-
          {isView ? (
            formatDate(moduleData.date3)
          ) : (
            <DatePickerMui
              label=""
              value={moduleData?.date3 ? moment(moduleData.date3) : null}
              onChange={(date3) => {
                handleInputChange('date3', date3);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          Date :-
          {isView ? (
            formatDate(moduleData.date4)
          ) : (
            <DatePickerMui
              label=""
              value={moduleData?.date4 ? moment(moduleData.date4) : null}
              onChange={(date4) => {
                handleInputChange('date4', date4);
              }}
            />
          )}
        </th>
        <th></th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
