const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'CHECK POINTS', style: style1 },
    { text: 'STANDARD', style: style1 },
    { text: 'ACTION IF NOT AS PER STANDARD', style: style1 },
    { text: 'A / NA', style: style1 },
    { text: 'METHOD', style: style1 },
    { text: 'BEFORE', style: style1 },
    { text: 'AFTER', style: style1 },
    { text: 'ACTION', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'DIE PM Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'DIE PM CHECK SHEET FOR',
};

export const apiConstant = {
  get: 'masterCheckListRouteDiePmPlan',
  getById: 'masterCheckListRouteDiePmPlan',
  post: 'masterCheckListRouteDiePmPlan',
  update: 'masterCheckListRouteDiePmPlan',
  delete: 'masterCheckListRouteDiePmPlan',
  forAutoComplete: 'moldRoutes/workersList/workersForAutoComplete',
  departmentAutoComplete: 'department',
};

export const tilesConstant = {
  childHeaderText: 'DIE PM PLAN',
};

export const navigationConstant = {
  createEditChecklist: '/common/diePmPlan_ChecklistCreate',
};
