export const listConstant = {
  childHeader: 'Annual PM Plan Metallic Bin list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Annual PM Plan Metallic Bin list',
  theadHeader: 'Annual PM Plan Metallic Bin',
  extracolSpan: 3,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'annualPmPlanMetallicBinRoute/monthlylist',
  deleteListurl: 'annualPmPlanMetallicBinRoute',
  getListPlanByid: 'annualPmPlanMetallicBinRoute',
  createListPlanByid: 'annualPmPlanMetallicBinRoute',

  // -----plan--
  sync: 'annualPmPlanMetallicBinRoute/handleSync',
  getAllPlanById: 'annualPmPlanMetallicBinRoute/allplansbyid',
  upadateCreateAllPlan:
    'annualPmPlanMetallicBinRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'annualPmPlanMetallicBinRoute/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'Annual PM Plan Metallic Bin',
};

export const navigationConstant = {
  planRouter: '/common/annualPmPlanMetallicBinCreate',
};
