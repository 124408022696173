import { TableInput } from '../../../../components/common';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React, { useState } from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { StyledNotesField } from '../../../../components/common/FormControlMui';
import DescriptionTextfield from '../../../../components/common/DescriptionNotes';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import { convToTime } from '../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  moduleData: any;
  auditorData?: any;
  updateModuleData?: any;
  employeeData: any;
}

const THead = ({
  auditorData,
  moduleData,
  updateModuleData,
  employeeData,
}: IProps) => {
  const [render, setRender] = useState<any>('');
  const isView = useQuery('isView') === 'true' ? true : false;
  const departmentName = useQuery('departmentName');

  const handleInputChange = (
    fieldName: string,
    value: string | number | null,
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['F-MR-07', '03', '28.11.2022']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th colSpan={1} align="left">
          CHECKSHEET NAME :{' '}
        </th>
        <th colSpan={2} align="left">
          {moduleData.checkList_name}
        </th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          Department : {departmentName}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Date :
          {isView ? (
            formatDate(moduleData.date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.date || null)}
              // views={['month',]}
              onChange={(date) => {
                moduleData.date = date;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          Auditor :
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="auditor_1"
            type="text"
            value={moduleData.auditor_1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={2} align="left">
          Auditee :
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="auditor_2"
            type="text"
            value={moduleData.auditor_2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={1} align="left">
          Counter Measure :
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="counter_measure"
            type="text"
            value={moduleData.counter_measure}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
