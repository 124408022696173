import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { styled as muiStyled } from '@mui/material/styles';

export const StyledDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSpan = styled.span`
  font-weight: bold;
`;

export const StyledTableRow: any = styled.tr`
  background: ${(props: any) =>
    props?.index % 2 === 0 ? 'fefefe' : '#f1f3f5'};
`;

export const StyledCheckbox = muiStyled(Checkbox)(({ theme }) => ({
  position: 'absolute',
  transform: 'scale(1.2)',
  right: -13,
  bottom: -13,
  color: '#51cf66',
  '&.Mui-checked': {
    color: '#087f5b',
  },
}));
