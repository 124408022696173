import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';

const useGetData = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setParentRender] = useState(false);
  const [renderforUpdate, setRenderforUpdate] = useState(false);
  const [toolData, setToolData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [toolProblemData, setToolProblemData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        // Make two GET requests in parallel
        const [result1, result2, result3] = await Promise.allSettled([
          axios.get(`${moduleConstant.autoComplete1}`),
          axios.get(`${moduleConstant.autoComplete2}`),
          axios.get(`${moduleConstant.autoComplete3}`),
        ]);

        // Handle results
        if (result1.status === 'fulfilled') {
          setToolData(result1.value.data);
        } else {
          console.error(
            'Error fetching data from auto complete 2:',
            result1.reason
          );
        }
        if (result2.status === 'fulfilled') {
          setMachineData(result2.value.data);
        } else {
          console.error(
            'Error fetching data from auto complete 2:',
            result2.reason
          );
        }
        if (result3.status === 'fulfilled') {
          setToolProblemData(result3.value.data);
        } else {
          console.error(
            'Error fetching data from auto complete 3:',
            result3.reason
          );
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      axios
        .get(`${moduleConstant.apiConstant}/getByMonth/${date}`)
        .then((response) => {
          setModuleData(response?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setModuleData([]);
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date, renderforUpdate]);

  return {
    error,
    moduleData,
    setModuleData,
    isLoading,
    setIsLoading,
    setParentRender,
    setRenderforUpdate,
    toolData,
    toolProblemData,
    machineData,
  };
};

export default useGetData;
