import moment from 'moment';

export const processes = [
  {
    time: null,
    part_count: null,
    actual_production: null,
    part_count_insert_after_changing: null,
    name_of_insert_tool: null,
    rework_qty: null,
    rej_qty: null,
    rework: null,
    insert_tool_change: null,
    inspection: null,
    setting: null,
    power_cut: null,
    machine_maintenance: null,
    no_feed: null,
    no_operator: null,
    no_material: null,
    machine_problem: null,
    rework_details: null,
    rest_problems: null,
    remarks: null,
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyProduction_date: new Date(),
  part_id: [null, null, null],
  mc_no: null,
  setter_name: null,
  cycle_time: [null, null, null],
  target_of_hour: [null, null, null],
  shift: null,
  jk_ck_ch_no: [null, null, null],
  program_no: [null, null, null],
  operation_no: [null, null, null],
  processes: Array.from({ length: 5 }, () => ({ ...processes[0] })),
};
