import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'S.No.',
  'Part Name',
  'Part No',
  'Rej. Qty',
  'Reason',
  'Receiver Sign',
  'Approved Sign(QA,Prod.,Wm)',
  'Remarks',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['F-QA-15', '00', '01.04.2011']}
        heading="SCRAP CHALLAN"
      />
      <tr>
        <th colSpan={2}>Date : </th>
        <th colSpan={7} align="left">
          {isView ? (
            formatDate(moduleData.moduleData_Date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_Date)}
              onChange={(date) => {
                moduleData.moduleData_Date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={3}>Rejection at Setup Stage</th>
        <th colSpan={3}>Rejection at In-Process Stage</th>
        <th colSpan={3}>Rejection at Final Stage</th>
      </tr>
      <tr>
        <th colSpan={3}>
          {isView && (moduleData.rejection_at_setup === true ? '⬤' : '◯')}
          {!isView && (
            <Checkbox
              checked={moduleData.rejection_at_setup}
              onChange={(e) => {
                moduleData.rejection_at_setup = e.target.checked;
                moduleData.rejection_at_pdir = false;
                moduleData.rejection_at_pir = false;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3}>
          {isView && (moduleData.rejection_at_pir === true ? '⬤' : '◯')}
          {!isView && (
            <Checkbox
              checked={moduleData.rejection_at_pir}
              onChange={(e) => {
                moduleData.rejection_at_pir = e.target.checked;
                moduleData.rejection_at_pdir = false;
                moduleData.rejection_at_setup = false;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3}>
          {isView && (moduleData.rejection_at_pdir === true ? '⬤' : '◯')}
          {!isView && (
            <Checkbox
              checked={moduleData.rejection_at_pdir}
              onChange={(e) => {
                moduleData.rejection_at_pdir = e.target.checked;
                moduleData.rejection_at_pir = false;
                moduleData.rejection_at_setup = false;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={heading === 'Remarks' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
