import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Switch,
  IconButton,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';

// Styled components for the modal
const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const SettingsContainer = styled(Box)({
  background: 'rgba(0, 0, 0, 0.85)',
  color: 'white',
  borderRadius: 8,
  padding: 16,
  outline: 'none',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
  width: '300px', // Set a fixed width or use 'auto' for automatic adjustment
  maxHeight: '90vh',
  overflowY: 'auto',
});

const SettingsTitle = styled(Typography)({
  fontSize: '2.3rem',
  fontWeight: 'bold',
  marginBottom: '0.6rem',
});

const SettingItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '10px 0',
  borderBottom: '1px solid #444', // Adjust color to match your UI
  paddingBottom: '8px',
});

const StyledDiv = styled('div')({
  width: '110px',
  height: '45px',
  backgroundColor: '#37b24d',
  padding: '1rem',
  border: '1px solid #f8f9fa', // Corrected 'Solid' to 'solid'
  color: '#f8f9fa',
  textTransform: 'capitalize',
  fontSize: '1.6rem',
  textAlign: 'center',
});

const SettingsSpeedDial = () => {
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState<any>({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Function to update settings to the server
  const newSettingsToServer = async (newSettings: any) => {
    try {
      await axios.post(`/api/operatorObservancePlanSettingRoute`, newSettings);
    } catch (err) {
      console.error('Error updating settings:', err);
    }
  };

  const updateSettingsToServer = async (newSettings: any) => {
    try {
      if (!settings?._id) return;
      await axios.put(
        `/api/operatorObservancePlanSettingRoute/${settings?._id}`,
        newSettings
      );
    } catch (err) {
      console.error('Error updating settings:', err);
    }
  };

  // Function to fetch settings from the server
  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `/api/operatorObservancePlanSettingRoute`
      );
      setSettings(response.data);
      return true;
    } catch (err) {
      console.error('Error fetching settings:', err);
      return false;
    }
  };

  useEffect(() => {
    let cancelRequest = false;
    const initializeSettings = async () => {
      const isSettingPresent = await fetchSettings();
      if (!isSettingPresent && !cancelRequest) {
        const defaultSettings = {
          show_date: true,
          show_A: false,
          data_preloaded: true,
          maintananceplan: 'maintananceplan',
        };
        await newSettingsToServer(defaultSettings);
        if (!cancelRequest) {
          setSettings(defaultSettings);
        }
      }
    };

    initializeSettings();
    return () => {
      cancelRequest = true;
    };
  }, []);

  const handleToggle = (e: any) => {
    setSettings((prev: any) => {
      const newSettings = { ...prev };
      const name = e.target.name;
      const value = e.target.checked;

      newSettings[name] = value;

      if (name === 'show_A') {
        newSettings.show_date = !value;
      } else if (name === 'show_date') {
        newSettings.show_A = !value;
      }
      updateSettingsToServer(newSettings);
      return newSettings;
    });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        style={{ position: 'fixed', bottom: 40, left: 15 }}
      >
        <SettingsIcon style={{ fontSize: '3rem' }} />
      </IconButton>

      <StyledModal open={open} onClose={handleClose}>
        <SettingsContainer>
          <SettingsTitle variant="h3">General</SettingsTitle>
          <Typography variant="subtitle1" sx={{ color: '#dee2e6', mb: 0.5 }}>
            Customise your plan
          </Typography>
          <FormGroup>
            <SettingItem>
              <StyledDiv>14/06/2023</StyledDiv>
              <FormControlLabel
                control={
                  <Switch
                    name={'show_date'}
                    checked={settings.show_date}
                    onChange={(e) => handleToggle(e)}
                  />
                }
                label=""
              />
            </SettingItem>
            <SettingItem>
              <StyledDiv>A</StyledDiv>
              <FormControlLabel
                control={
                  <Switch
                    name={'show_A'}
                    checked={settings.show_A}
                    onChange={(e) => handleToggle(e)}
                  />
                }
                label=""
              />
            </SettingItem>
            <SettingItem>
              <Tooltip title="When enabled, all data will be preloaded during the creation of a new plan.">
                <Typography>Data Preloaded</Typography>
              </Tooltip>
              <FormControlLabel
                control={
                  <Switch
                    name={'data_preloaded'}
                    checked={settings.data_preloaded}
                    onChange={(e) => handleToggle(e)}
                  />
                }
                label=""
              />
            </SettingItem>
            <SettingItem>
              <Tooltip title="When enabled,it will show sync button.">
                <Typography>SYNC</Typography>
              </Tooltip>
              <FormControlLabel
                control={
                  <Switch
                    name={'show_sync'}
                    checked={settings.show_sync}
                    onChange={(e) => handleToggle(e)}
                  />
                }
                label=""
              />
            </SettingItem>
            <SettingItem>
              <Tooltip title="When enabled,it will show Add Row button.">
                <Typography>ADD ROW</Typography>
              </Tooltip>
              <FormControlLabel
                control={
                  <Switch
                    name={'show_add_row'}
                    checked={settings.show_add_row}
                    onChange={(e) => handleToggle(e)}
                  />
                }
                label=""
              />
            </SettingItem>
          </FormGroup>
        </SettingsContainer>
      </StyledModal>
    </>
  );
};

export default SettingsSpeedDial;
