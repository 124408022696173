import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
import lodash from 'lodash';
import { processes } from './initialState';
const useGetData = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>({
    month: date && new Date(date).getMonth() + 1,
    year: date && new Date(date).getFullYear(),
    processes: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setParentRender] = useState(false);
  const [renderforUpdate, setRenderforUpdate] = useState(false);
  const [machineData, setMachineData] = useState([]);

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      axios
        .get(
          `${moduleConstant.apiConstant}/${date}?machineId=${moduleData.machine_id?._id}`,
        )
        .then((response) => {
          setModuleData(response?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setModuleData({
            month: date && new Date(date).getMonth() + 1,
            year: date && new Date(date).getFullYear(),
            machine_id: moduleData.machine_id,
            processes: lodash.cloneDeep(processes),
          });
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date, renderforUpdate]);

  return {
    error,
    moduleData,
    setModuleData,
    isLoading,
    machineData,
    setParentRender,
    setRenderforUpdate,
  };
};

export default useGetData;
