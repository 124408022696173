import { Box } from '@mui/material';
import { useState } from 'react';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  documentType: any;
}

const TController = ({ moduleData, isView, documentType }: IProps) => {
  const [renderController, setRenderController] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            documentType,
            setRenderController,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            documentType,
          }}
        />
        <TFoot
          {...{
            moduleData,
            isView,
            documentType,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
