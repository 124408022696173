import { MenuItem } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import CustomSelect from '../../../../../components/common/SelectField';
import { TableCompHead } from '../../../../../components/ui';
import { convToTime } from '../../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={21}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Dated']}
        values={['F-PROD-08A', '01', '20.04.2021']}
        heading="Process Parameter Sheet"
      />

      <tr>
        <th colSpan={4} align="left">
          Part Name:&emsp;
          {moduleData?.process_std_id?.part_id?.part_name ||
            moduleData?.partName}
        </th>
        <th colSpan={2} align="left">
          Machine No:&emsp;
          <TableInput
            name="machine_one"
            type="text"
            value={moduleData?.machine_one}
            onChange={(e) => {
              moduleData.machine_one = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
          {/* {moduleData?.process_std_id?.machine_id?.machine_no ||
            moduleData?.machineNo} */}
        </th>
        <th colSpan={2} align="left">
          Machine No:&emsp;
          <TableInput
            name="machine_two"
            type="text"
            value={moduleData?.machine_two}
            onChange={(e) => {
              moduleData.machine_two = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />

        </th>
        <th colSpan={2} align="left">
          Machine No:&emsp;
          <TableInput
            name="machine_three"
            type="text"
            value={moduleData?.machine_three}
            onChange={(e) => {
              moduleData.machine_three = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />

        </th>
        <th colSpan={2} align="left">
          Machine No:&emsp;
          <TableInput
            name="machine_four"
            type="text"
            value={moduleData?.machine_four}
            onChange={(e) => {
              moduleData.machine_four = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />

        </th>
        <th colSpan={9} align="left">
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            Date:
            {isView ? (
              formatDate(moduleData.moduleData_date)
            ) : (
              <DatePickerMui
                label=""
                value={moment(moduleData.moduleData_date)}
                onChange={(date) => {
                  moduleData.moduleData_date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </div>
        </th>
      </tr>
      <tr>
        <th rowSpan={2}>S.No.</th>
        {['M/C Parameter', 'Specification / Unit'].map((head) => (
          <th
            style={{ textTransform: 'uppercase', minWidth: '180px' }}
            rowSpan={2}
          >
            {head}
          </th>
        ))}
        <th style={{ minWidth: '100px' }}>Shift</th>
        {Array.from({ length: 8 }, (_, index) => (
          <th key={index + 'Heading'} colSpan={2}>
            {isView ? (
              formatDate(moduleData?.times[index])
            ) : (
              <DatePickerMui
                value={
                  (moduleData?.times[index] &&
                    moment(moduleData?.times[index])) ||
                  null
                }
                onChange={(date) => {
                  moduleData.times[index] = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </th>
        ))}
        <th
          style={{
            textTransform: 'uppercase',
            width: '150px',
            minWidth: '150px',
          }}
          rowSpan={2}
        >
          Remarks
        </th>
      </tr>
      <tr>
        <th>➡️</th>
        {Array.from({ length: 8 }, (_, index) => (
          <>
            <th style={{ width: '150px', minWidth: '150px' }}>
              <TableInput
                name="shift"
                type="text"
                value={moduleData?.shift[index]}
                onChange={(e) => {
                  moduleData.shift[index] = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </th>
            <th style={{ minWidth: '150px', width: '150px' }}>JUDGEMENT</th>
          </>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
