import { useState } from 'react';
import { TableInput } from '../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.list.map((spec: any, index: number) => (
        <tr>
          <td>
            <TableInput
              isView={isView}
              name="problem_identified"
              type="text"
              value={spec.problem_identified}
              onChange={(e: any) => {
                spec.problem_identified = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="corrective_action"
              type="text"
              value={spec.corrective_action}
              onChange={(e: any) => {
                spec.corrective_action = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="corrective_action_taken"
              type="text"
              value={spec.corrective_action_taken}
              onChange={(e: any) => {
                spec.corrective_action_taken = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
