import { RenderTiles } from '../../components/common';
import { VscServerProcess } from 'react-icons/vsc';
import { FcInspection } from 'react-icons/fc';
import {
  MdApproval,
  MdSupervisorAccount,
  MdSupportAgent,
} from 'react-icons/md';
import { TbReport } from 'react-icons/tb';
import { CgCalendarToday } from 'react-icons/cg';
import { TbNotebook } from 'react-icons/tb';
import { GiExitDoor } from 'react-icons/gi';
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
import { GrHostMaintenance } from 'react-icons/gr';
import { SlNotebook } from 'react-icons/sl';

const supervisorTilesObj = [
  {
    sNo: 3,
    link: '/common/clause3',
    isLink: true,
    clauseNo: 3,
    p: 'CHANGING',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    sNo: 1,
    link: '/supervisor/lineInspectionList',
    isLink: true,
    icon: <VscServerProcess size="5rem" color="#a12222" />,
    p: 'LINE',
    span: 'INSPECTION',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/supervisor/pdirList',
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'FINAL',
    span: 'INSPECTION',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/supervisor/setupList',
    isLink: true,
    icon: <MdApproval size="5rem" color="#a12222" />,
    p: 'FPA/',
    span: 'LPA',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/production',
    isLink: true,
    icon: <CgCalendarToday size="5rem" color="#212529" />,
    p: 'PRODUCTION',
    span: 'MANAGEMENT',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/clause8',
    isLink: true,
    clauseNo: 8,
    p: 'HANDLING ABNORMILITY',
    span: 'IN QUALITY',
    isIcon: false,
  },

  {
    sNo: 10,
    link: '/common/clause10',
    isLink: true,
    clauseNo: 10,
    p: 'EQUIPMENT / INSPECTION',
    span: "EQUIPMENT'S MANAGEMENT",
    isIcon: false,
  },
  {
    sNo: 102,
    link: '/supervisor/clause11',
    isLink: true,
    clauseNo: 11,
    p: 'IMPLEMENTATION OF',
    span: 'STANDARDS',
    isIcon: false,
  },
  {
    sNo: 11,
    link: '/common/clause15',
    isLink: true,
    clauseNo: 15,
    p: 'ADEQUATE TESTING',
    span: 'FACILITY',
    isIcon: false,
  },
  {
    sNo: 12,
    link: '/common/clause18',
    isLink: true,
    clauseNo: 18,
    p: 'SAFETY',
    span: '',
    isIcon: false,
  },
  {
    sNo: 13,
    link: '/common/clause19',
    isLink: true,
    clauseNo: 19,
    p: 'LEGAL COMPLIANCE',
    span: 'AND ENVIRONMENT',
    isIcon: false,
  },
  {
    sNo: 2,
    link: '/common/riDepartment',
    isLink: true,
    icon: <GiExitDoor size="5rem" color="#a12222" />,
    p: 'RI',
    span: 'DEPARTMENT',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/hpdcChecksheets',
    isLink: true,
    icon: <HiOutlineClipboardDocumentCheck size="5rem" color="#a12222" />,
    p: 'HPDC',
    span: 'CHECKSHEETS',
    isIcon: true,
  },
  {
    sNo: 20,
    link: '/common/qualityDepartmentHome',
    isLink: true,
    icon: <SlNotebook size="5rem" color="#a12222" />,
    p: 'QUALITY',
    span: 'DEPARTMENT',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/createDegassingCheckSheet',
    isLink: true,
    icon: <GrHostMaintenance size="5rem" color="#003566" />,
    p: 'Degassing Frequency &',
    span: ' Temperature Monitering',
    isIcon: true,
  },
  {
    sNo: 9,
    link: `/common/mushinHelpDesk`,
    isLink: true,
    icon: <MdSupportAgent size="5rem" color="#1864ab" />,
    p: 'MUSHIN HELP',
    span: 'DESK',
    isIcon: true,
  },
];

const SupervisorHome = () => {
  return <RenderTiles tilesObj={supervisorTilesObj} />;
};

export default SupervisorHome;
