import React, { useState } from 'react';
import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';

import useDebounce from '../../hooks/useDebounce';
import { TableCustom, StatePagination } from '../common';
import formatDate from '../common/formatDate';
import { CustomIconButton } from '../common/button/CustomIconButton';
import StateCustomTable from '../common/StateCustomTable';

interface Department {
  department_name: string;
}
interface IMold {
  _id?: string;
  mold_name: string | null;
  department_id: Department;
  frequency: number | null;
  date_orig: moment.Moment;
  createdAt?: Date;
}
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Mold Name', rowspan: 1, colspan: 1 },
    { text: 'Department', rowspan: 1, colspan: 1 },
    { text: 'FREQUENCY / NO. OF SHOTS', colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'mold_name', name: 'A-Z' }];

const MoldList = ({
  link,
  isMoldIdParam,
}: //   type,
//   riPartIds,
//   handleUpdatePart,
{
  link?: string;
  //   type?: string;
  //   handleUpdatePart?: (id: string) => void;
  //   riPartIds?: string[];
  isMoldIdParam?: boolean;
}) => {
  const [page, setPage] = useState(1);
  const [moldData, setMoldData] = useState<IMold[]>([]);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('createdAt');
  const navigate = useNavigate();

  const fetchPartData = async () => {
    const res = await axios
      .get(
        `/api/moldRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setMoldData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);

  return (
    <StateCustomTable
      filterOptions={filterOptions}
      {...{ search, setSearch, filter, setFilter }}
      label="Search Mold"
    >
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {moldData &&
            moldData.map((item: IMold, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item?.mold_name}</TableCell>
                  <TableCell align="center">
                    {item.department_id?.department_name}
                  </TableCell>
                  <TableCell align="center">{item?.frequency}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  {!isMoldIdParam && (
                    <TableCell align="center">
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={'select'}
                        onClick={() =>
                          navigate(
                            `${link}?mold_id=${item._id}&moldName=${item?.mold_name}&departmentName=${item?.department_id?.department_name}&frequency=${item?.frequency}&isView=false`
                          )
                        }
                      >
                        <Add style={{ color: '#343a40' }} />
                      </CustomIconButton>
                    </TableCell>
                  )}
                  {/* {!isPartIdParam && (
                    <TableCell align="center">
                      {type === 'choose' && handleUpdatePart && riPartIds ? (
                        <Checkbox
                          checked={riPartIds.includes(item._id || '')}
                          onChange={() => handleUpdatePart(item._id || '')}
                        />
                      ) : (
                        <CustomIconButton
                          sx={{ p: 0 }}
                          tooltipTitle={'select'}
                          onClick={() =>
                            navigate(
                              `${link}?ri_part_id=${item._id}&partName=${item.part_name}&partNumber=${item.part_number}&isView=false`
                            )
                          }
                        >
                          <Add style={{ color: '#343a40' }} />
                        </CustomIconButton>
                      )}
                    </TableCell>
                  )}
                  */}
                  {isMoldIdParam && (
                    <TableCell align="center">
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={'select'}
                        onClick={() =>
                          navigate(
                            `${link}/${item._id}?moldName=${item?.mold_name}&departmentName=${item?.department_id?.department_name}&frequency=${item?.frequency}&isView=false`
                          )
                        }
                      >
                        <Add style={{ color: '#343a40' }} />
                      </CustomIconButton>
                    </TableCell>
                  )}
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
    </StateCustomTable>
  );
};

export default MoldList;
