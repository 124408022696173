import { BsPersonWorkspace } from 'react-icons/bs';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { SiInstructure } from 'react-icons/si';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';

const tiles = [
  {
    sNo: 1,
    link: '/common/annualPmPlanRotorCastinglist',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'ANNUAL PM',
    span: 'PLAN',
    isIcon: true,
  },

  {
    sNo: 2,
    link: '/common/annualPmPlanRotorCastingChecklist',
    isLink: true,
    icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
    p: 'ANNUAL PM',
    span: 'STATUS',
    isIcon: true,
  },
  // {
  //   sNo: 2,
  //   link: '/common/annualPmPlanRotorCastingMasterlist',
  //   isLink: true,
  //   icon: <SiInstructure size="5rem" color="#343a40" />,
  //   p: 'Master list of',
  //   span: 'Instrument',
  //   isIcon: true,
  // },
];

const Tiles = () => {
  return (
    <>
      <ChildHeader text="INSTRUMENTS MSA PLAN" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Tiles;
