import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

const View = () => {
  const [moduleData, setModuleData] = useState<any>({
    ...lodash.cloneDeep(initialState),
  });
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const dailyAccidentId = useQuery('dailyAccidentId');
  const historyId = useQuery('historyId');

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Update',
    'Are you sure you want to update this document?'
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/fillCapa/${id}`)
        .then((res) => {
          setModuleData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (isSubmitted: boolean) => {
    if (id) {
      const ans = await confirmUpdate();
      if (!ans) return;
      await axios
        .put(`/api/fillCapa/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      const ans = await confirmSubmit();
      if (!ans) return;
      moduleData.dailyAccidentId = dailyAccidentId;
      moduleData.historyId = historyId;

      await axios
        .post('/api/fillCapa', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Box>
        <ChildHeader text="NEAR MISS /INCIDENT/ACCIDENT RECORD">
          {!isView && (
            <>
              <SubmitButton
                label={moduleData._id ? 'Update' : 'Submit'}
                onClick={() => handleSumbit(true)}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          setRender,
        }}
      />

      <DialogSubmit />
      <DialogUpdate />
    </>
  );
};

export default View;
