export const listConstant = {
  childHeader: 'THIRD PARTY MATERIAL TEST list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'THIRD PARTY MATERIAL TESTlist',
  theadHeader: 'THIRD PARTY MATERIAL TEST',
  extracolSpan: 3,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'thirdPartyMaterialTestRoute/monthlylist',
  deleteListurl: 'thirdPartyMaterialTestRoute',
  getListPlanByid: 'thirdPartyMaterialTestRoute',
  createListPlanByid: 'thirdPartyMaterialTestRoute',

  // -----plan--
  sync: 'thirdPartyMaterialTestRoute/handleSync',
  getAllPlanById: 'thirdPartyMaterialTestRoute/allplansbyid',
  upadateCreateAllPlan:
    'thirdPartyMaterialTestRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'thirdPartyMaterialTestRoute/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'THIRD PARTY MATERIAL TEST',
};

export const navigationConstant = {
  planRouter: '/common/thirdPartyMaterialTestPlanCreate',
};
