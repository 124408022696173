import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiConstant } from '../constant';
import { module_data } from './initialState';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>(module_data);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [render]);

  return { moduleData, isLoading, error, setIsLoading, setRender };
};

export default useGetData;
