import { TableInput } from '../../../../components/common';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React, { useState } from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { StyledNotesField } from '../../../../components/common/FormControlMui';
import DescriptionTextfield from '../../../../components/common/DescriptionNotes';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import { convToTime } from '../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  moduleData: any;
  machineData?: any;
  updateModuleData?: any;
}

const THead = ({ machineData, moduleData, updateModuleData }: IProps) => {
  const [render, setRender] = useState<any>('');
  const isView = useQuery('isView') === 'true' ? true : false;
  const operatorName = useQuery('operatorName');

  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['F-HRD-18B', '01', '02.01.2024']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3}></th>
      </tr>
      <tr>
        <th>Line Name : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="line_name"
            type="text"
            value={moduleData.line_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th>Component Name :</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="component_name"
            type="text"
            value={moduleData.component_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th>M/C Name :</th>
        <th colSpan={2}>
          <AutocompleteMuiCustom
            id={'machine_name'}
            label={''}
            option_name={'machine_name'}
            option_name_2={'machine_no'}
            arrayofObj={machineData}
            value={moduleData.machine_id || ''}
            onChange={(e, value) => {
              moduleData.machine_id = value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th>Operation Name :</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="operation_name"
            type="text"
            value={moduleData.operation_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th>Operator Name :</th>
        <th colSpan={2}>{operatorName}</th>
        <th>Month :</th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.month)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.month || null)}
              views={['month']}
              onChange={(date) => {
                moduleData.month = date;

                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
