import { AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { GrVmMaintenance } from 'react-icons/gr';
import { MdEmojiTransportation, MdOutlinePersonPin } from 'react-icons/md';
import { SiInstructure } from 'react-icons/si';
import { TbReportSearch } from 'react-icons/tb';

const tiles = [
  {
    sNo: 1,
    link: '/common/adequate_testing_listOfProcedures/?documentType=adequate_testing&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/instrumentMasterlist',
    isLink: true,
    icon: <SiInstructure size="5rem" color="#343a40" />,
    p: 'Master list of',
    span: 'Instrument',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/msaTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#343a40" />,
    p: 'MSA',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/calibrationTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#343a40" />,
    p: 'CALIBRATION',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/gaugeMasterlist',
    isLink: true,
    icon: <SiInstructure size="5rem" color="#343a40" />,
    p: 'Master list of',
    span: 'Gauges',
    isIcon: true,
  },
];

export default tiles;
