import { Checkbox, MenuItem } from '@mui/material';
import { Stack } from '@mui/system';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import CustomSelect from '../../../../../components/common/SelectField';
import useConfirm from '../../../../../components/common/useConfirm';
import { TableCompHead } from '../../../../../components/ui';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
  documentType: any;
  setRenderController: any;
}

const THead = ({
  moduleData,
  isView,
  documentType,
  setRenderController,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogCavity, confirmCavity] = useConfirm(
    'Alert',
    'Are you sure you want to change the cavity?',
  );
  const arr =
    documentType === 'retro'
      ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
      : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <thead>
      <TableCompHead
        colSpan={documentType === 'retro' ? 18 + 10 : 18}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-43', '00', '01.10.2019']}
        heading="4M CHANGE INSPECTION REPORT"
      />
      <tr>
        <th rowSpan={4} align="left">
          Type Of Change
        </th>
        <th align="left">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Man</p>
            <span
              style={{
                fontSize: '1rem',
                marginLeft: '6rem',
              }}
            >
              <Checkbox
                name="man"
                color="primary"
                checked={moduleData.change_item === 'man'}
                onChange={() => {
                  moduleData.change_item = 'man';
                  setRender((prev) => !prev);
                }}
                disabled={isView}
              />
            </span>
          </div>
        </th>
        <th rowSpan={4} align="left">
          Category of Changes
        </th>
        <th colSpan={2} align="left">
          Planned Change
        </th>
        <th align="left">
          <Checkbox
            name="planned_change"
            key="planned_change"
            color="primary"
            checked={moduleData.planned_change}
            onChange={(e) => {
              moduleData.planned_change = e.target.checked;
              setRender((prev) => !prev);
            }}
            disabled={isView}
          />
        </th>
        <th rowSpan={4} align="left" colSpan={2}>
          Activity to be performed (As per Std)
        </th>
        <th colSpan={3} align="left">
          First Setup Approval
        </th>
        <th align="left">
          <Checkbox
            name="first_setup_approval"
            color="primary"
            checked={moduleData.first_setup_approval === true}
            onChange={(e) => {
              moduleData.first_setup_approval = e.target.checked;
              setRender((prev) => !prev);
            }}
            disabled={isView}
          />
        </th>
        <th
          align="left"
          colSpan={documentType === 'retro' ? 4 + 10 : 4}
          rowSpan={2}
        >
          <Stack style={{ gap: '5px' }}>
            4M Change Date & Time:-
            {isView ? (
              `${formatDate(moduleData.change_4m_date)}  ${convToTime(
                moduleData.change_4m_time,
              )} `
            ) : (
              <Stack direction={'row'} gap={'10px'}>
                <DatePickerMui
                  value={moment(moduleData.change_4m_date || null)}
                  onChange={(date) => {
                    moduleData.change_4m_date = date;
                    setRender((prev) => !prev);
                  }}
                />
                <TimePicker
                  value={moment(moduleData.change_4m_time || null)}
                  onChange={(date) => {
                    moduleData.change_4m_time = date;
                    setRender((prev) => !prev);
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
        <th rowSpan={2} colSpan={2}>
          Cavity:
          {isView ? (
            moduleData.cavity
          ) : (
            <CustomSelect
              label=""
              name="part_type"
              value={moduleData.cavity || ''}
              onChange={async (e: any) => {
                const ans = await confirmCavity();
                if (!ans) return;
                moduleData.cavity = +e.target.value;
                setRenderController((prev: any) => !prev);
              }}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="10">10</MenuItem>
            </CustomSelect>
          )}
        </th>
      </tr>
      <tr>
        <th align="left">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {' '}
            <p>Machine</p>
            <span
              style={{
                fontSize: '1rem',
                marginLeft: '6rem',
              }}
            >
              <Checkbox
                name="machine"
                color="primary"
                checked={moduleData.change_item === 'machine'}
                onChange={() => {
                  moduleData.change_item = 'machine';
                  setRender((prev) => !prev);
                }}
                disabled={isView}
              />
            </span>
          </div>
        </th>

        <th colSpan={2} align="left">
          Unplanned Change
        </th>
        <th align="left">
          <Checkbox
            name="unplanned_change"
            color="primary"
            checked={moduleData.unplanned_change || false}
            onChange={(e) => {
              moduleData.unplanned_change = e.target.checked;
              setRender((prev) => !prev);
            }}
            disabled={isView}
          />
        </th>
        <th colSpan={3} align="left">
          Retroactive Check
        </th>
        <th align="left">
          <Checkbox
            name="retroactive_check"
            color="primary"
            checked={moduleData.retroactive_check === true}
            onChange={(e) => {
              moduleData.retroactive_check = e.target.checked;
              setRender((prev) => !prev);
            }}
            disabled={isView}
          />
        </th>
      </tr>
      <tr>
        <th align="left">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Material</p>
            <span
              style={{
                fontSize: '1rem',
                marginLeft: '6rem',
              }}
            >
              <Checkbox
                name="material"
                color="primary"
                checked={moduleData.change_item === 'material'}
                onChange={() => {
                  moduleData.change_item = 'material';
                  setRender((prev) => !prev);
                }}
                disabled={isView}
              />
            </span>
          </div>
        </th>
        <th colSpan={2} align="left">
          Abnormal Situations
        </th>
        <th align="left">
          <Checkbox
            name="abnormal_situations"
            color="primary"
            checked={moduleData.abnormal_situations || false}
            onChange={(e) => {
              moduleData.abnormal_situations = e.target.checked;
              setRender((prev) => !prev);
            }}
            disabled={isView}
          />
        </th>
        <th colSpan={3} align="left">
          Suspected Lot Insp.
        </th>
        <th align="left">
          <Checkbox
            name="suspected_lot_insp"
            color="primary"
            checked={moduleData.suspected_lot_insp === true}
            onChange={(e) => {
              moduleData.suspected_lot_insp = e.target.checked;
              setRender((prev) => !prev);
            }}
            disabled={isView}
          />
        </th>
        <th colSpan={4} align="left" rowSpan={2}>
          4M Change Final Status{' '}
          <TableInput
            isView={isView}
            name="change_4m_final_status"
            type="text"
            value={moduleData?.change_4m_final_status}
            onChange={(e) => {
              moduleData.change_4m_final_status = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={4} rowSpan={2} align="left">
          <Stack style={{ gap: '5px' }}>
            Termination Date:-
            {isView ? (
              formatDate(moduleData.termination_date)
            ) : (
              <DatePickerMui
                value={moment(moduleData.termination_date || null)}
                onChange={(date) => {
                  moduleData.termination_date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </Stack>
        </th>
        {documentType === 'retro' && <th colSpan={10} rowSpan={2}></th>}
      </tr>
      <tr>
        <th align="left">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {' '}
            <p>Method</p>
            <span
              style={{
                fontSize: '1rem',
                marginLeft: '6rem',
              }}
            >
              <Checkbox
                name="method"
                color="primary"
                checked={moduleData.change_item === 'method'}
                onChange={() => {
                  moduleData.change_item = 'method';
                  setRender((prev) => !prev);
                }}
                disabled={isView}
              />
            </span>
          </div>
        </th>

        <th colSpan={2} align="left"></th>
        <th></th>
        <th colSpan={3} align="left">
          Training
        </th>
        <th align="left">
          <Checkbox
            name="training"
            color="primary"
            checked={moduleData.training === true}
            onChange={(e) => {
              moduleData.training = e.target.checked;
              setRender((prev) => !prev);
            }}
            disabled={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          PART NAME: {moduleData.part_name || moduleData.part_id?.part_name}
        </th>
        <th colSpan={3} align="left">
          PART NO: {moduleData?.part_number || moduleData.part_id?.part_number}
        </th>

        <th colSpan={4} align="left">
          Batch No. :
          <TableInput
            isView={isView}
            name="batch_no"
            type="text"
            value={moduleData?.batch_no}
            onChange={(e) => {
              moduleData.batch_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={6} align="left">
          Heat No.:
          <TableInput
            isView={isView}
            name="heat_no"
            type="text"
            value={moduleData?.heat_no}
            onChange={(e) => {
              moduleData.heat_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={documentType === 'retro' ? 3 + 10 : 3} align="left">
          Customer:{' '}
          {moduleData?.customer_name ||
            moduleData.part_id?.customer_id?.customer_name}
        </th>
      </tr>
      <tr>
        <th colSpan={5}>SETUP APPROVAL RECORDS</th>
        <th colSpan={documentType === 'retro' ? 10 + 10 : 10}>OBSERVATIONS</th>
        <th colSpan={3}>RESULTS</th>
      </tr>
      <tr>
        {[
          'S.NO',
          'PARAMETER',
          'SPECIFICATION / TOLERANCE',
          'METHOD',
          'SAMPLE SIZE',
          ...arr,
          'Judgment /Status',
          'Verified By',
          'Remarks',
        ].map((head: any, index) => (
          <th
            key={head + index + 'heading'}
            style={{ textTransform: 'uppercase' }}
          >
            {head}
          </th>
        ))}
      </tr>
      <DialogCavity isSubmitContent={false} />
    </thead>
  );
};

export default THead;
