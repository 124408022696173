import { MdEmojiTransportation, MdOutlinePersonPin } from 'react-icons/md';
import { GiMoebiusStar } from 'react-icons/gi';
import { FaCalendarAlt } from 'react-icons/fa';
import { AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { GiAlliedStar } from 'react-icons/gi';
import { RiListIndefinite } from 'react-icons/ri';
import { VscFeedback } from 'react-icons/vsc';

const tiles = [
  // {
  //   sNo: 1,
  //   link: '/admin/supplier_list?filter=createdAt',
  //   isLink: true,
  //   p: 'MASTER LIST OF',
  //   span: 'SUPPLIER',
  //   isIcon: true,
  //   icon: <MdEmojiTransportation size="5rem" color="#135089" />,
  // },
  {
    sNo: 1,
    link: '/admin/supplier_control_listOfProcedures/?documentType=supplier_control&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/masterListOfVendor',
    isLink: true,
    icon: <MdOutlinePersonPin size="5rem" color="#064a29" />,
    p: 'APPROVED SUPPLIER',
    span: 'LIST',
    isIcon: true,
  },
  {
    sNo: 3,
    link: `/common/approvedSupplierList`,
    isLink: true,
    icon: <GiMoebiusStar size="5rem" color="#003566" />,
    p: 'SUPPLIER RATING',
    span: 'FORM 1',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/supplierRating/form2/vendorlist',
    isLink: true,
    icon: <GiAlliedStar size="5rem" color="#003566" />,
    p: 'Supplier Rating',
    span: 'Form 2',
    isIcon: true,
  },
  {
    sNo: 20,
    link: '/common/supplierAuditPlanTiles',
    isLink: true,
    icon: <FaCalendarAlt size="5rem" color="#495057" />,
    p: 'Supplier Audit',
    span: 'Plan (Jan To Dec)',
    isIcon: true,
  },
  {
    sNo: 21,
    link: '/common/supplierAuditPlanAprilToMarchTiles',
    isLink: true,
    icon: <FaCalendarAlt size="5rem" color="#495057" />,
    p: 'Supplier Audit',
    span: 'Plan (Apr To Mar)',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/supplier/defect/list?filter=createdAt',
    isLink: true,
    icon: <RiListIndefinite size="5rem" color="#495057" />,
    p: 'Master List Of',
    span: 'Supplier Defect',
    isIcon: true,
  },
  {
    sNo: 3,
    link: `/common/SupplierComplaintRegister`,
    isLink: true,
    icon: <VscFeedback size="5rem" color="#e03131" />,
    p: 'SUPPLIER COMPLAINT',
    span: 'REGISTER',
    isIcon: true,
  },
];

export default tiles;
