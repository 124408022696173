export const listConstant = {
  childHeader: 'PREVENTIVE MAINTENANCE LIST',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW MACHINE',
};

export const createConstant = {
  childHeader: 'ANNUAL MAINTENANCE SCHEDULE REPORT',
  theadHeader: 'ANNUAL PREVENTIVE MAINTENANCE SCHEDULE',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'LAYOUT STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'mtbfRoute',
  gettableDataurl: 'mtbfRoute/yearly',
  getPlanByid: 'mtbfRoute',
  createPlanByid: 'mtbfRoute',
  updatePlanByid: 'mtbfRoute',
  sync: 'layoutplanRoute/handleSync',
  getAllCheckList: 'layoutplanRoute/getAllCheckList',
  checkListStatus: 'layoutplanRoute/handleStatus',
};

export const navigationConstant = {
  createPlanSchedule:
    '/common/maintenance_breakdown/preventive_maintenance_create',
};
