export const listConstant = {
  childHeader: 'Spc Plan list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Spc Plan list',
  theadHeader: ' Spc Plan',
  extracolSpan: 5,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'spcPlanNewRoute/monthlylist',
  deleteListurl: 'spcPlanNewRoute',
  getListPlanByid: 'spcPlanNewRoute',
  createListPlanByid: 'spcPlanNewRoute',

  // -----plan--
  sync: 'spcPlanNewRoute/handleSync',
  getAllPlanById: 'spcPlanNewRoute/allplansbyid',
  upadateCreateAllPlan: 'spcPlanNewRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'spcPlanNewRoute/maintenancePlanDeleteBulkById',
};

export const settingPanelConstant = {
  // settingPanel setting for plan
  crudApi: 'spcPlanNewSettingRoute',
  docType: 'spcPlanNew',
};

export const tilesConstant = {
  childHeaderText: 'Spc Plan',
};

export const navigationConstant = {
  planRouter: '/common/spcPlanNewCreate',
};
