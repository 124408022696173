import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  updateModuleData: any;
  customerData: any;
  setPartIdx: any;
  setIsOpenPopup: any;
}

const TController = ({
  moduleData,
  updateModuleData,
  customerData,
  setPartIdx,
  setIsOpenPopup,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, updateModuleData }} />
        <TBody
          {...{
            moduleData,
            updateModuleData,
            setPartIdx,
            setIsOpenPopup,
            customerData,
          }}
        />
        <TFoot
          {...{
            moduleData,
            updateModuleData,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
