export const checklist_process = [
  {
    checkpoint: '1st of Month',
    month_year: null,
    last_filling_date: null,
    location: '',
    pressure: '',
    seal_condition: '',
    pipe_condition: '',
  },
  {
    checkpoint: '9th of Month',
    month_year: null,
    last_filling_date: null,
    location: '',
    pressure: '',
    seal_condition: '',
    pipe_condition: '',
  },
  {
    checkpoint: '17th on Month',
    month_year: null,
    last_filling_date: null,
    location: '',
    pressure: '',
    seal_condition: '',
    pipe_condition: '',
  },
  {
    checkpoint: '25th on Month',
    month_year: null,
    last_filling_date: null,
    location: '',
    pressure: '',
    seal_condition: '',
    pipe_condition: '',
  },
];
