import {
  Box,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';

import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../../../components/common';
import {
  AddButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import ModalCustom from '../../../../components/common/Modal';
import { SupervisorPartTable } from '../../../../components/ui';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import ModalInput from './ModalInput';
import { listConstant, apiConstant, navigationConstant } from './constant';
import React from 'react';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../../../components/common/SelectField';
import useDebounce from '../../../../hooks/useDebounce';
import FlexBox from '../../../../components/common/FlexBox';
import Search from '@mui/icons-material/Search';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import { SearchBar } from '../../../../components/common/SearchBar';
import { SearchFilterContainer } from '../../../../assets/styles/layout/searchFilter.styled';
const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Title', rowspan: 1, colspan: 1 },
    { text: 'Date Range', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const TableList = () => {
  const { state } = useAuth();
  const { user } = state;
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([{}]);
  const [listId, setListId] = useState('');
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // const [search, setSearch] = useState();
  const documentType = useQuery('documentType');
  const search = useQuery('search') || '';

  // useEffect(() => {
  //   axios
  //     .get(`/api/${apiConstant.gettableDataurl}/${date}`)
  //     .then((res) => {
  //       if (res.data) setTableData(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [render, date, isOpenPopup]);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/preventiveMaintenance/getAllPreventive/${documentType}?page=${page}&search=${search}`
      )
      .catch((err) => {
        console.log(err);
      });
    setTableData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    isOpenPopup /*isMaintenanceModal*/,
  ]);

  const handleDelete = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/preventiveMaintenance/deletePreventive/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <Box>
      <ChildHeader text={`${listConstant.childHeader}`}>
        <AddButton
          label={`${listConstant.addButtonLable}`}
          onClick={() => setIsOpenPopup(true)}
        />
      </ChildHeader>
      <SearchFilterContainer filter={false}>
        <SearchBar label="Search Title" />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item?.title}</TableCell>
                  <TableCell align="center">
                    {getYearRange(item.date_range)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="Edit Preventive Maintenace Plan"
                        onClick={() => {
                          navigate(
                            `${navigationConstant.createPlanSchedule}/${item?._id}`
                          );
                          setListId(item?._id || '');
                          setIsOpenPopup(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          handleDelete(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1rem',
          marginBottom: '4rem',
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
      {/* </TableContainer> */}
      <ModalCustom
        title={`${listConstant.modalTile}`}
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          setListId('');
        }}
      >
        <ModalInput
          setIsModal={setIsOpenPopup}
          id={listId}
          setListId={setListId}
        />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
