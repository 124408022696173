import { GrTest } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { RenderTiles } from '../../../../components/common';
import ChildHeader from '../../../../components/ui/ChildHeader';

export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    // link: 'https://uc.secure.aqua.mushinlabs.com',
    isLink: true,
    // target: '_blank',
    link: '/common/topicPaper/list',
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'SET',
    span: 'TNI TOPIC',
    isIcon: true,
  },
  {
    sNo: 2,
    // link: 'https://uc.secure.aqua.mushinlabs.com',
    link: '/common/tniList/data',
    isLink: true,
    // target: '_blank',
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'CREATE',
    span: 'TNI PLAN',
    isIcon: true,
  },
];

const TNITiles = () => {
  return (
    <>
      <ChildHeader text="TNI (TRAINING NEED IDENTIFICATION)" />
      <RenderTiles tilesObj={tiles} height={'100vh'} justify="start" />
    </>
  );
};

export default TNITiles;
