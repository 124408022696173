import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import AutocompleteMuiCustomRevised from '../../../../components/common/tableinputs/AutocompleteMuiCustomRevised';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { convToTime } from '../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';
import { StyledNotesField } from '../../../../components/common/FormControlMui';
import DescriptionTextfield from '../../../../components/common/DescriptionNotes';

interface IProps {
  moduleData: any;
  machineData?: any;
  mouldersData: any;
  updateModuleData?: any;
}

const THead = ({
  machineData,
  mouldersData,
  moduleData,
  updateModuleData,
}: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const [render, setRender] = useState<any>('');

  const handleAutocompleteChange = (newValue: any) => {
    updateModuleData('key-pair', null, 'machine_name', {
      machine_name: newValue?.machine_name,
    });
    updateModuleData('key-pair', null, 'machine_no', {
      machine_no: newValue?.machine_no,
    });
    updateModuleData('key-pair', null, 'machine_id', { machine_id: newValue });
    // updateModuleData('key-pair', null, 'machine_id', newValue);
  };
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['HO-F-MNT-19', '01', '01.11.2020']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3}></th>
      </tr>
      <tr>
        <th colSpan={1}>Mould ID : </th>
        <th colSpan={1}></th>
        <th colSpan={1}>Mould Name : </th>
        <th colSpan={1}></th>
        <th colSpan={1}>Name : </th>
        <th colSpan={1}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="name"
            type="text"
            value={moduleData.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={6}>
          <StyledNotesField>
            <DescriptionTextfield
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
              value={moduleData.note || ''}
              name={'note'}
              label="Note/Comments/Observations if any : "
              minRows={3}
            />
          </StyledNotesField>
        </th>
      </tr>
      <tr>
        <th colSpan={1}>QA Sign : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="qa_sign"
            type="text"
            value={moduleData.qa_sign}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3}></th>
      </tr>
      <tr>
        <th colSpan={1}>time : </th>
        <th colSpan={2}>Mould taken by(Maint/TR) : </th>
        <th colSpan={3}>Mould received after PM by(PRD) : </th>
      </tr>
      <tr>
        <th colSpan={1}>Deptt. : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="dept_maint"
            type="text"
            value={moduleData.dept_maint}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="dept_prd"
            type="text"
            value={moduleData.dept_prd}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={1}>Name : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="name_maint"
            type="text"
            value={moduleData.name_maint}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="name_prd"
            type="text"
            value={moduleData.name_prd}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={1}>Signature : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="signature_maint"
            type="text"
            value={moduleData.signature_maint}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="signature_prd"
            type="text"
            value={moduleData.signature_prd}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={1}>Date : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="date_maint"
            type="text"
            value={moduleData.date_maint}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="date_prd"
            type="text"
            value={moduleData.date_prd}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={1}>Time : </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="time_maint"
            type="text"
            value={moduleData.time_maint}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="time_prd"
            type="text"
            value={moduleData.time_prd}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Mould verified by(Prd Engineer):</th>
        <th colSpan={2}>
          Signature :
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="sign_by"
            type="text"
            value={moduleData.sign_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={1}>
          Date :
          {isView ? (
            formatDate(moduleData.date)
          ) : (
            <DatePickerMui
              label=""
              value={moduleData?.date ? moment(moduleData.date) : null}
              onChange={(date) => {
                handleInputChange('date', date);
              }}
            />
          )}
        </th>
        <th colSpan={1}>
          Time :
          {isView ? (
            convToTime(moduleData.time)
          ) : (
            <TimePicker
              sx={{
                width: '150px',
              }}
              value={moment(moduleData.time || null)}
              onChange={(date) => {
                moduleData.time = date;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
