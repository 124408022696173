// StampComponent.jsx
import React from 'react';
import passStamp from '../../assets/employeeTestSheetImage/pass.png';
import failStamp from '../../assets/employeeTestSheetImage/fail.png';

const StampComponent = ({ passed }: any) => {
  const stampImage = passed ? passStamp : failStamp;

  return (
    <div
      style={{
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0.844,
      }}
    >
      <img
        src={stampImage}
        alt={passed ? 'Passed' : 'Failed'}
        style={{ maxWidth: '90%', height: 'auto' }}
      />
    </div>
  );
};

export default StampComponent;
