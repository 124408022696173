import { useEffect, useState } from 'react';
import TController from './components/view/TController';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import { initialState, process } from './helpers/initialState';
import useConfirm from '../../../components/common/useConfirm';

const View = () => {
  const [moduleData, setModuleData] = useState<any>({
    ...lodash.cloneDeep(initialState),
  });
  const [render, setRender] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const navigate = useNavigate();
  const annual_month = useQuery('annual_date') || '';
  const isView = useQuery('isView') === 'true' ? true : false;

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?',
  );
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutoComplete')
      .then((res) => {
        const data = res.data;
        data.forEach((res: any) => {
          res['machine_no_name'] =
            `${res['machine_no']} - ${res['machine_name']}`;
        });
        setMachineData(data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios
      .get(
        `/api/operatorChecksheetRoute/monthly?annualMonth=${annual_month}&machineId=${moduleData?.machine_id?._id}`,
      )
      .then((res) => {
        if (res.data) setModuleData(res.data);
      })
      .catch((err) => {
        setModuleData({
          ...lodash.cloneDeep(initialState),
          machine_id: moduleData.machine_id,
        });
        console.log(err);
      });
  }, [render, moduleData?.machine_id?._id]);

  useEffect(() => {
    if (moduleData?._id) {
      axios
        .get(`/api/operatorChecksheetRoute/${moduleData?._id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [moduleData?._id]);

  const handleSumbit = async () => {
    if (!(moduleData.machine_id && annual_month))
      return alert('Machine is required!');
    if (moduleData?._id) {
      await axios
        .put(`/api/operatorChecksheetRoute/${moduleData?._id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.annual_month = annual_month;
      await axios
        .post('/api/operatorChecksheetRoute', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            setRender((prev) => !prev);
            // navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };
  const handleChangeMachine = (value: any) => {
    setModuleData((prev: any) => ({ ...prev, machine_id: value }));
  };
  return (
    <>
      <Box>
        <ChildHeader text={'OPERATOR CHECK SHEET'}>
          {!isView && (
            <>
              <SubmitButton
                label={moduleData?._id ? `UPDATE` : `SUBMIT`}
                onClick={() => handleSumbit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          handleChangeMachine,
          isView,
          handelDeleteRows,
          machineData,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
    </>
  );
};

export default View;
