import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { Checkbox } from '@mui/material';
import "../../../../App.css";
import TipTap from '../../TipTap';
import { parseUrl } from 'query-string/base';
import HTMLReactParser from 'html-react-parser';
interface IProps {
  moduleData: any;
  isView: boolean;
  setModuleData: any;
}


const THead = ({ moduleData, isView, setModuleData }: IProps) => {
  const [render, setRender] = useState(false);
  const [agenda, setAgenda] = useState("");
  const [invitees, setInvitees] = useState("");
  

  const handleSetDesc = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      meeting_agenda: desc
    }));
  };

  const handleSetDescListInvitees = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      list_of_invitees: desc
    }));
  };

  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['F-MR-01', '02', '25.09.2018']}
        heading="MANAGEMENT REVIEW MEETING"
      />

      <tr>
        <th colSpan={1} style={{padding:'0px', margin:'0px', border:'none', height:'0px',width:'40px'}}>

        </th>
        <th colSpan={1} style={{padding:'0px', margin:'0px', border:'none', height:'0px',width:'150px'}}>

        </th>
        <th colSpan={1} style={{padding:'0px', margin:'0px', border:'none', height:'0px',width:'150px'}}>

        </th>
        <th colSpan={1} style={{padding:'0px', margin:'0px', border:'none', height:'0px',width:'150px'}}>

        </th>
        <th colSpan={1} style={{padding:'0px', margin:'0px', border:'none', height:'0px',width:'150px'}}>

        </th>
        <th colSpan={1} style={{padding:'0px', margin:'0px', border:'none', height:'0px',width:'50px'}}>

        </th>
        <th colSpan={1} style={{padding:'0px', margin:'0px', border:'none', height:'0px',width:'100px'}}>

        </th>

      </tr>



      <tr style={{border:'none'}}>
        <th colSpan={5} style={{borderRight:'none', borderBottom:'none'}}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', borderBottom:'none', fontSize:'18px'}}>
             MEETING AGENDA
          </div>
        </th>
        <th colSpan={1} style={{borderRight:'none', borderLeft:'none', borderBottom:'none'}}>
          <div style={{marginLeft:'4px', textAlign:'right', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', borderLeft:'none', borderBottom:'none',fontSize:'18px'}}>
            DATE :
          </div>
        </th>

        <th colSpan={1} align="left" style={{borderLeft:'none', borderBottom:'none'}}>
          {isView ? (
            formatDate(moduleData.module_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.module_date)}
              onChange={(date) => {
                moduleData.module_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr style={{border:'none'}}>
        {isView ? (
            <td colSpan={7} style={{border:'none'}}>
              <div className='tiptap'>
                {HTMLReactParser(moduleData.meeting_agenda)}
              </div>
            </td>
        ) : (
          <td colSpan={7} style={{border:'none'}}>
            <div  className='tiptap' style={{columnSpan:'all'}}>
              <TipTap setDesc={handleSetDesc} initialContent={moduleData.meeting_agenda}/>
            </div>
          </td>
        )}
      </tr>

      <tr style={{border:'none'}}>
        <th colSpan={7} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none',fontSize:'18px'}}>
             LIST OF INVITEES ( IF ANY )
          </div>
        </th>
      </tr>

      <tr style={{border:'none'}}>
        {isView ? (
          <td colSpan={7} style={{border:'none'}}>
            <div className='tiptap'>
              {HTMLReactParser(moduleData.list_of_invitees)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{border:'none'}}>
            <div  className='tiptap' style={{columnSpan:'all'}}>
              <TipTap setDesc={handleSetDescListInvitees} initialContent={moduleData.list_of_invitees}/>
            </div>
          </td>
        )}
      </tr>

    </thead>
  );
};

export default THead;
