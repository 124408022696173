export const checkListList = {
  childHeader: 'ANNUAL PM PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'annualPmPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'annualPmPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'annualPmPlanStatusRoute/handleUpload',
  checklistDeleteImage: 'annualPmPlanStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const navigationConstant = {
  checklistRouter: '/common/annulPmPlan_StatusCheckList',
};
