// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';

export const TableRowComponent = React.memo(
  ({ key, item, index, allmonth, updateModuleData, handleDelete }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td>{index + 1}</td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="clause_ref"
              type="text"
              value={item.clause_ref}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <th>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '200px' }}
              name="header"
              type="text"
              value={item.header}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </th>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '300px' }}
              name="item"
              type="text"
              value={item.item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="ref_document"
              type="text"
              value={item.ref_document}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px', width: '400px' }}
              name="observation"
              type="text"
              value={item.observation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="status"
              type="text"
              value={item.status}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>

          {!isView && (
            <td style={{ minWidth: '50px' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handleDelete(index)}
              />
            </td>
          )}
        </tr>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
