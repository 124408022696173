import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiConstant } from '../../constant';
import lodash from 'lodash';
import {
  control_during_processing,
  control_of_5s_management,
  control_of_finished_product,
  control_of_measuring_and_test_equipment,
  control_of_nonconforming_parts_and_corrective_preventive_actions,
  control_of_purchase_material,
  last_audit_nc_verification,
  management_of_quality,
  quality_planning_and_change_control,
} from './checklist';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.checklistData}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      setModuleData({
        total_max_score: 108,
        last_audit_nc_verification: lodash.cloneDeep(
          last_audit_nc_verification
        ),
        management_of_quality: lodash.cloneDeep(management_of_quality),
        quality_planning_and_change_control: lodash.cloneDeep(
          quality_planning_and_change_control
        ),
        control_of_purchase_material: lodash.cloneDeep(
          control_of_purchase_material
        ),
        control_during_processing: lodash.cloneDeep(control_during_processing),
        control_of_finished_product: lodash.cloneDeep(
          control_of_finished_product
        ),
        control_of_nonconforming_parts_and_corrective_preventive_actions:
          lodash.cloneDeep(
            control_of_nonconforming_parts_and_corrective_preventive_actions
          ),
        control_of_measuring_and_test_equipment: lodash.cloneDeep(
          control_of_measuring_and_test_equipment
        ),
        control_of_5s_management: lodash.cloneDeep(control_of_5s_management),
      });
    }
  }, []);

  return { moduleData, isLoading, error };
};

export default useGetData;
