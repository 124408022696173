import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { differenceInDays } from './helpers/getNumberOfDays';
import moment from 'moment';
import { calculateDueDate } from './helpers/utils';

const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<any>({
    histories: [],
  });
  const [categoryData, setCategoryData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Instrument' : 'Create Instrument'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistinstrument/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));

    axios
      .get('/api/masterlistinstrument/instrumentcategory')
      .then((res) => {
        if (res.data.result) {
          setCategoryData(res.data.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterlistinstrument/${id}`, data)
        .then((res) => {
          setIsSubmit(false);
          setListId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      const makeFirstRow = {
        calibration_done_date: data?.calibration_done_date,
        next_due_date: calculateDueDate(
          data?.calibration_done_date,
          Number(data?.frequency_cycle_new) // here frequency in month
        ),
      };
      data.histories[0] = makeFirstRow;
      await axios
        .post('/api/masterlistinstrument', data)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <AutocompleteGridmui
        label={'Inst. Type'}
        id={'name'}
        option_name={'name'}
        value={data?.category_id || ''}
        arrayofObj={categoryData}
        onChange={(e, value) => {
          data.category_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <GridInputs
        id={'instrument_no'}
        name={'instrument_no'}
        html_for={'instrument_no'}
        label_name={'Inst. No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.instrument_no || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'instrument_name'}
        name={'instrument_name'}
        html_for={'instrument_name'}
        label_name={'Inst. Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.instrument_name || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'acceptance_criteria'}
        name={'acceptance_criteria'}
        html_for={'acceptance_criteria'}
        label_name={'Acceptance Criteria:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.acceptance_criteria || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'range'}
        name={'range'}
        html_for={'range'}
        label_name={'Range: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.range || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'least_count'}
        name={'least_count'}
        html_for={'least_count'}
        label_name={'Least Count:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.least_count || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'method_of_calibration'}
        name={'method_of_calibration'}
        html_for={'method_of_calibration'}
        label_name={'Method of Calibration:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.method_of_calibration || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'frequency_cycle_new'}
        name={'frequency_cycle_new'}
        html_for={'frequency_cycle_new'}
        label_name={'Calibration Frequency (In Month):'}
        input_type={'number'}
        focusType={'onchange'}
        value={data?.frequency_cycle_new ?? null}
        onChange={(e) => {
          data.frequency_cycle_new = e.target.value;
          data.next_due_date = calculateDueDate(
            data?.calibration_done_date,
            Number(data?.frequency_cycle_new) // here frequency in month
          );
          setRender((prev) => !prev);
        }}
      />
      <GridDatePicker
        label_name="Calibration Done Date"
        html_for={'calibration_done_date'}
        value={
          (data?.calibration_done_date &&
            moment(data?.calibration_done_date)) ||
          null
        }
        onChange={(date) => {
          data.calibration_done_date = date;
          data.next_due_date = calculateDueDate(
            data?.calibration_done_date,
            Number(data?.frequency_cycle_new) // here frequency in month
          );
          setRender((prev) => !prev);
        }}
      />

      <GridInputs
        id={'status_remark'}
        name={'status_remark'}
        html_for={'status_remark'}
        label_name={'Remark Status:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.status_remark || ''}
        onChange={handleData}
      />

      <GridInputs
        id={'comments'}
        name={'comments'}
        html_for={'comments'}
        label_name={'Comments:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.comments || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'agency'}
        name={'agency'}
        html_for={'agency'}
        label_name={'Agency:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.agency || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.location || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'make'}
        name={'make'}
        html_for={'make'}
        label_name={'Make:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.make || ''}
        onChange={handleData}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
