import { GrVmMaintenance } from 'react-icons/gr';
import { MdReceiptLong } from 'react-icons/md';
const tiles = [
  {
    sNo: 5,
    link: '/common/operatorObservanceTiles',
    isLink: true,
    icon: <GrVmMaintenance size="5rem" color="#087f5b" />,
    p: 'Operator Observance',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/poisonTestTiles',
    isLink: true,
    icon: <GrVmMaintenance size="5rem" color="#087f5b" />,
    p: 'Poison Test',
    span: 'Plan',
    isIcon: true,
  },
  // {
  //   sNo: 8,
  //   link: '/common/poisonTestRecordList',
  //   isLink: true,
  //   icon: <MdReceiptLong size="5rem" color="#a12222" />,
  //   p: 'Poison Test',
  //   span: 'Checksheet',
  //   isIcon: true,
  // },
];

export default tiles;
