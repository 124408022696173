import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { TableCompHead } from '../../../../components/ui';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  customerData: any;
  calculateCumulative: any;
  setModuleData: any;
  isView: boolean;
}

const THead = ({
  moduleData,
  isView,
  customerData,
  calculateCumulative,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc No.:-', 'Rev No.:-', 'Rev. Date:-']}
        values={['f-PROD-12', '01', '01.04.2023']}
        heading="DIE HISTORY CARD"
      />
      <tr>
        <th colSpan={3} align="left">
          Die Name : {moduleData?.die_name}
        </th>
        <th colSpan={3} align="left">
          Die No. : {moduleData?.die_number}
        </th>
        <th align="left" colSpan={3}>
          CUSTOMER
          {isView ? (
            moduleData?.customer_id?.customer_name
          ) : (
            <AutocompleteMuiCustom
              id="customer_name"
              label=""
              option_name="customer_name"
              arrayofObj={customerData}
              value={moduleData.customer_id || ''}
              onChange={(e, value) => {
                moduleData.customer_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          Frequency :-
          <TableInput
            name="changed_frequncy"
            type="number"
            value={moduleData.changed_frequncy}
            onChange={(e) => {
              moduleData.changed_frequncy = e.target.value;
              calculateCumulative(moduleData);
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>

      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'Sr. No.',
          'Loading Date',
          'Off Loading Date',
          'Dot. No.',
          'QTY. Prod.',
          'Cumm. Qty.',
          'Die Condition After Run',
          'Sign.',
          'Repair Detail/PM/Breakdown',
          'Incharge Sign.',
          'Status',
          'Remarks',
        ].map((head) => (
          <th>{head}</th>
        ))}
      </tr>
      <tr></tr>
    </thead>
  );
};

export default THead;
