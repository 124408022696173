import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  updateModuleData: any;
  selectionArray: any;
  handleBulkDeleteFeature: any;
  settings: any;
  utilityData: any;
}

const TController = ({
  moduleData,
  selectionArray,
  updateModuleData,
  handleBulkDeleteFeature,
  utilityData,
  settings,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{}} />
        <TBody
          {...{
            moduleData,
            updateModuleData,
            handleBulkDeleteFeature,
            selectionArray,
            utilityData,
            settings,
          }}
        />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
