import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { apiConstant } from './helpers/constant';
import moment from 'moment';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year}-${year + 1}`;
};

const ModalInput = ({
  id,
  setIsModal,
  setModuleId,
}: {
  id?: string | null;
  setModuleId?: any;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [department, setDepartment] = useState([{}]);
  const [moduleData, setMoudleData] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${apiConstant.getById}/${id}`)
        .then((res) => setMoudleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/${apiConstant.departmentAutoComplete}`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setMoudleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    if (!moduleData.date_range) {
      alert('MUST PICK DATE RANGE');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/${apiConstant.update}/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setModuleId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(
          `/api/${apiConstant.post}?department_id=${moduleData?.department_id?._id}`,
          moduleData
        )
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setModuleId(null);
        })
        .catch((err) => {
          if (err.response.data.error.message.includes('E11000')) {
            alert(
              'A report for this department has already been created for the selected year. Each department can only have one report per year to ensure data uniqueness and integrity. Please review the existing report or select a different year if you need to create a new one.'
            );
          }
          alert(err.response.data.error.message);
          setIsSubmit(false);
          console.error(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'TITLE'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleData}
      />
      <AutocompleteGridmui
        label={'DEPARTMENT'}
        id="department_name"
        option_name="department_name"
        key={'department_name'}
        arrayofObj={department}
        locked={moduleData?._id}
        value={moduleData?.department_id || ''}
        onChange={(_, newValue: any) =>
          setMoudleData((prev: any) => ({ ...prev, department_id: newValue }))
        }
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date_range'}
        views={['year']}
        value={moduleData?.date_range ? moment(moduleData?.date_range) : null}
        onChange={(date) => {
          const newDate = moment(date).startOf('year');
          moduleData.date_range = newDate;
          moduleData.year_range = getYearRange(moduleData.date_range);
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'year_range'}
        name={'year_range'}
        html_for={'year_range'}
        label_name={'YEAR RANGE'}
        input_type={'text'}
        focusType={'onblur'}
        last_child={2}
        value={moduleData?.year_range || ''}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
