export const getYearRange = (
  date: any,
  yearType: 'financialYear' | 'normalYear'
) => {
  const year = date && new Date(date).getFullYear();

  if (yearType === 'financialYear') {
    return (
      year &&
      `APR-${year.toString().slice(-2)} - MAR-${(year + 1)
        .toString()
        .slice(-2)}`
    );
  } else if ((yearType = 'normalYear')) {
    return (
      year &&
      `JAN-${year.toString().slice(-2)} - DEC-${year.toString().slice(-2)}`
    );
  } else {
    return '';
  }
};
