import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import CustomSelect from '../../../../../../components/common/SelectField';
import { Checkbox, MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((spec: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>

            <td>{spec.checkpoint}</td>
            <td>{spec.standard}</td>
            <td>{spec.action}</td>
            <td>{spec.a_na}</td>
            <td>{spec.method}</td>
            <td style={{ width: '180px' }}>
              <TableInput
                isView={isView}
                name="before"
                type="text"
                value={spec.before}
                onChange={(e) => {
                  spec.before = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ width: '180px' }}>
              <TableInput
                isView={isView}
                name="after"
                type="text"
                value={spec.after}
                onChange={(e) => {
                  spec.after = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
