import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import moment from 'moment';
import DatePickerMui from '../../../components/common/DatePickerMui';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';

interface IArray {
  [key: string]: any;
}

const CreateHeader = ({
  customer_name,
  partName,
  partNumber,
  msaData,
  process,
  spec,
  setSpec,
  autoCompleteProcess,
  setAutoCompleteProcess,
  setAutoCompleteSpec,
  autoCompleteSpec,
  setInstrumentType,
  instrumentType,
}: any) => {
  const [render, setRender] = useState<Boolean>(false);
  const [instrumentData, setInstrumentData] = useState<any>([]);
  const [instrumentId, setInstrumentId] = useState<any>([]);
  useEffect(() => {
    axios
      .get(`/api/msaRoute/masterListInstrument`)
      .then((res) => {
        let temp0 = res?.data;
        const temp1: IArray = {};
        temp0.forEach((t: any) => {
          const key = t?.category_id?.name || '';
          if (!temp1[key]) {
            temp1[key] = [];
          }
          temp1[key].push(t);
        });
        temp0 = [];
        console.log(temp1);
        for (const [key, value] of Object.entries(temp1)) {
          temp0.push({ instrument_type: key, value: value });
        }
        console.log(temp0);

        setInstrumentData(temp0);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <InputMuiCustom
              type="string"
              name="part_name"
              variant={'outlined'}
              placeholder={'Part Name'}
              value={partName || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              type="string"
              name="part_no"
              variant={'outlined'}
              placeholder={'Part No.'}
              value={partNumber || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <AutocompleteMuiCustom
              id="category_id.name"
              label="Gauge Type"
              option_name="instrument_type"
              arrayofObj={instrumentData}
              value={instrumentType || ''}
              onChange={(e, value) => {
                msaData.instrument_id = {};
                if (value === null || value === '') return;
                msaData.instrument_type = value?.instrument_type;
                setInstrumentType({ instrument_type: value?.instrument_type });
                setInstrumentId(value?.value);
                setRender((prev) => !prev);
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <AutocompleteMuiCustom
              id="category_id_no"
              label="Gauge Name"
              option_name="instrument_name"
              arrayofObj={instrumentId}
              value={msaData?.instrument_id || ''}
              onChange={(e, value) => {
                msaData.instrument_id = value;

                setRender((prev) => !prev);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <AutocompleteMuiCustom
              id="category_id_no"
              label="Gauge Number"
              option_name="instrument_no"
              arrayofObj={instrumentId}
              value={msaData?.instrument_id || ''}
              onChange={(e, value) => {
                msaData.instrument_id = value;

                setRender((prev) => !prev);
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <AutocompleteMuiCustom
              id={'process_name'}
              label="OPERATION/PROCESS NAME"
              option_name={'process_name'}
              value={autoCompleteProcess}
              arrayofObj={process}
              onChange={(e, value) => {
                value?.process_name
                  ? (msaData.process_id = value._id)
                  : (msaData.process_id = '');
                setAutoCompleteProcess(value);
                value?.specs ? setSpec(value.specs) : setSpec([]);
                setAutoCompleteSpec('');
                setRender((prev) => !prev);
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 0.2 }}>
          <Grid item xs={2}>
            <AutocompleteMuiCustom
              id={'charecteristics'}
              label={'Characteristics'}
              option_name={'charecteristics'}
              value={autoCompleteSpec}
              arrayofObj={spec}
              onChange={(e, value) => {
                value?._id
                  ? (msaData.spec_id = value._id)
                  : (msaData.spec_id = '');
                setAutoCompleteSpec(value);

                msaData.control_product = value?.control_product;
                setRender((prev) => !prev);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              type="string"
              name="least_count
"
              variant={'outlined'}
              placeholder={'Specification'}
              value={msaData?.instrument_id?.least_count || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="control_product"
              placeholder={`Control Product`}
              value={msaData.control_product}
              onChange={(e) => {
                msaData.control_product = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="test_no"
              placeholder={'TEST NUMBER'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.test_no}
            />
          </Grid>
          <Grid item xs={1}>
            <InputMuiCustom
              variant={'outlined'}
              type="number"
              name="sample_size"
              placeholder={'SAMPLE SIZE'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.sample_size}
            />
          </Grid>
          <Grid item xs={1}>
            <InputMuiCustom
              variant={'outlined'}
              type="number"
              name="no_of_appraisers"
              placeholder={'No. of Appraisers'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.no_of_appraisers}
            />
          </Grid>
          <Grid item xs={1}>
            <InputMuiCustom
              variant={'outlined'}
              type="number"
              name="no_of_trail"
              placeholder={'No. of Trail'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.no_of_trail}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 0.2 }}>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="appraisers_A"
              placeholder={'Appraisers A'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.appraisers_A}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="appraisers_B"
              placeholder={'Appraisers B'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.appraisers_B}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="appraisers_C"
              placeholder={'Appraisers C'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.appraisers_C}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePickerMui
              label="Date"
              value={moment(msaData.date || null)}
              onChange={(date) => {
                msaData.date = date;
                setRender((prev) => !prev);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateHeader;
function useSate(arg0: boolean): [any, any] {
  throw new Error('Function not implemented.');
}
