import React, { useEffect, useState } from "react";
import GridInputs from "../../../components/common/inputs/GridInputs";
import axios from "axios";
import { SubmitButton } from "../../../components/common/button/CustomButtons";
import useConfirm from "../../../components/common/useConfirm";
import GridDatePicker from "../../../components/common/inputs/GridDatePicker";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import GridSelect from "../../../components/common/inputs/GridSelect";
import { Loader } from "../../../components/common/Loader";
import ModalCustom from "../../../components/common/Modal";
import { DivStyled } from "../../../assets/styles/components/DivStyledLableInputs";
import {
  AddIconButton,
  RemoveIconButton,
} from "../../../components/common/button/CustomIconButton";
import PartTable from "./components/PartTable";
import { Checkbox } from "@mui/material";
import { monthsName } from "./helpers/monthName";
import { useNavigate, useParams } from 'react-router-dom';

import AutocompleteGridmui from "../../../components/common/autocomplete/AutocompleteGridmui";

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [suppliersData, setSuppliersData] = useState([]);
  const [dept, setDept] = useState<any>([]);
  const [defectsData, setDefectsData] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? "Update Complaint" : "Create Complaint"}`,
    `Are you sure you want to ${id ? "update" : "save"} this?`
  );

  useEffect(() => {
   
      axios
      .get("/api/department/department/departmentForAutoComplete")
      .then((res) => {
        if (res?.data) {
        //   const formattedData = res?.data.map((dept: any) => ({
        //     department_name: dept.department_name,
        //     value: dept.department_name,
        //   }));
          console.log("res.data is ",res?.data)
          setDept(res?.data);
          console.log("dept is",dept);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/nctracker/getById/${id}`)
        .then((res) => {
          setData(res?.data?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    // if (!data.supplier_id) {
    //   alert("Please enter vendor name");
    //   return;
    // }
    // if (!data.defect_id) {
    //   alert("Please enter defect name");
    //   return;
    // }

    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);

    if (id) {
      await axios
        .put(`/api/nctracker/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
          // alert('Updated Successfully');
          if (res?.data?.success) {
            alert('Updated Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/nctracker", data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
          if (res?.data?.success) {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  console.log("dept is",dept);

  return (
    <>
      <Loader loader={isLoading} />
      {/* <h1>Plan</h1> */}
      {/* <GridInputs
        id={"dar_no"}
        name={"dar_no"}
        html_for={"dar_no"}
        label_name={"DAR No. : "}
        input_type={"number"}
        focusType={"onblur"}
        value={data?.dar_no || ""}
        onChange={handleData}
      /> */}
      <GridDatePicker
        label_name="AUDIT DATE : "
        html_for={"audit_date"}
        value={data?.audit_date || null}
        onChange={(date: Date) => {
          data.audit_date = date;
        }}
      />
      <AutocompleteGridmui
        id={"department_name"}
        label={"Department Name : "}
        option_name={"department_name"}
        value={data?.dept_id || ""}
        arrayofObj={dept}
        onChange={(_, value: any) => {
          setData((prev: any) => ({ ...prev, dept_id: value }));
        }}
        last_child={2}
      />
     
     
      <GridInputs
        id={"nc"}
        name={"nc"}
        html_for={"nc"}
        label_name={"NC:"}
        input_type={"text"}
        focusType={"onblur"}
        value={data?.nc || ""}
        onChange={handleData}
      />
      <GridInputs
        id={"resp"}
        name={"resp"}
        html_for={"resp"}
        label_name={"RESPONSIBILITY:"}
        input_type={"text"}
        focusType={"onblur"}
        value={data?.resp || ""}
        onChange={handleData}
      />
     <GridDatePicker
        label_name="TARGET DATE : "
        html_for={"target_date"}
        value={data?.target_date || null}
        onChange={(date: Date) => {
          data.target_date = date;
        }}
      />
      
      <GridInputs
        id={"action"}
        name={"action"}
        html_for={"action"}
        label_name={" ACTION :"}
        input_type={"text"}
        focusType={"onblur"}
        value={data?.action || ""}
        onChange={handleData}
      />
      <GridDatePicker
        label_name="CLOSING DATE : "
        html_for={"closing_date"}
        value={data?.closing_date || null}
        onChange={(date: Date) => {
          data.closing_date = date;
        }}
      />
      {/* <GridDatePicker
        label_name="ROOT CAUSE ANALYSIS TIME 10 DAYS ACTUAL : "
        html_for={"rootcause_actual_date"}
        value={data?.rootcause_actual_date || null}
        onChange={(date: Date) => {
          data.rootcause_actual_date = date;
        }}
      />
      <GridInputs
        id={"root_cause"}
        name={"root_cause"}
        html_for={"root_cause"}
        label_name={"ROOT CAUSE:"}
        input_type={"text"}
        focusType={"onblur"}
        value={data?.root_cause || ""}
        onChange={handleData}
      />
       */}
     

      <h1>Effectiveness Monitoring</h1>
      {Array.from(monthsName, (_, i) => (
        <React.Fragment key={i + 1 + "heading"}>
          <GridSelect
            name={`${monthsName[i]}`}
            label_name={`${monthsName[i]}:`}
            html_for={`${monthsName[i]}`}
            value={data?.[`${monthsName[i]}`] || ""}
            onChange={handleData}
          >
            <MenuItem value="OK">OK</MenuItem>
            <MenuItem value="NOT OK">NOT OK</MenuItem>
          </GridSelect>
        </React.Fragment>
      ))}

      {/* <GridSelect
        name="status"
        label_name="Status"
        html_for={"status"}
        value={data?.status || ""}
        onChange={handleData}
      >
        <MenuItem value="CLOSED">CLOSED</MenuItem>
        <MenuItem value="OPEN">OPEN</MenuItem>
      </GridSelect>
      <GridSelect
        name="standard_hd"
        label_name="Standardisation/HD :"
        html_for={"standard_hd"}
        value={data?.standard_hd || ""}
        onChange={handleData}
      >
        <MenuItem value="Standardisation">Standardisation</MenuItem>
        <MenuItem value="HD">HD</MenuItem>
      </GridSelect> */}
      {/* <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Exclude from report
        </span>
      </div> */}

      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "140px",
          top: "-0.6px",
        }}
      >
        <SubmitButton
          label={`${id ? "update" : "submit"}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;