import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
}
interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th colSpan={17} align="left">
          Prepared By:
          <TableInput
            isView={isView}
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={18} align="left">
          Approved By:
          <TableInput
            isView={isView}
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
