//pir
import { Pir, PirTableList } from '../../pages/pir';

//pdir
import PdirList from '../../pages/pdir';
import Pdir from '../../pages/pdir/Create';
//setup
import { Setup, SetupList } from '../../pages/setup';
//incomingReport
import { IncomingReport, IncomingReportList } from '../../pages/incomingReport';
//material incoming
import {
  MaterialIncomingList,
  MaterialIncomingReport,
} from '../../pages/materialIncoming';
import ChemicalCompositionEdit from '../../pages/materialIncoming/ChemicalCompositionEdit';
import {
  LineInspectionTableList,
  LineInspection,
} from '../../pages/LineInspection';
import { Clause11Home } from '../../pages/clause11';

const pirRoute = [
  {
    path: 'pirList',
    element: <PirTableList />,
  },
  {
    path: 'pir/:partId/:pirId?',
    element: <Pir />,
  },
];

const LineInspectionRoute = [
  {
    path: 'lineInspectionList',
    element: <LineInspectionTableList />,
  },
  {
    path: 'lineInspection/:partId/:pirId?',
    element: <LineInspection />,
  },
];
const materialIncomingRoute = [
  {
    path: 'materialIncomingList',
    element: <MaterialIncomingList />,
  },
  {
    path: 'materialIncoming/:partId/:incomingId?',
    element: <MaterialIncomingReport />,
  },

  {
    path: 'chemicalCompositionIncomingMaterial/:materialId/:ccId?',
    element: <ChemicalCompositionEdit />,
  },
];
const incomingRoute = [
  {
    path: 'incomingReportList',
    element: <IncomingReportList />,
  },
  {
    path: 'incomingReport/:partId/:incomingId?',
    element: <IncomingReport />,
  },
];
const pdirRoute = [
  {
    path: 'pdirList',
    element: <PdirList />,
  },
  {
    path: 'pdir/:partId/:pdirId?',
    element: <Pdir />,
  },
];
const setupRoute = [
  {
    path: 'setupList',
    element: <SetupList />,
  },
  {
    path: 'setup/:partId/:setupId?',
    element: <Setup />,
  },
];

export const supervisor = [
  {
    path: 'clause11',
    element: <Clause11Home />,
  },
  ...pirRoute,
  ...pdirRoute,
  ...setupRoute,
  ...incomingRoute,
  ...materialIncomingRoute,
  ...LineInspectionRoute,
];
