import formatDate from '../../../../components/common/formatDate';
import TableCompHead from '../../../../components/ui/TableCompHead';

interface IProps {
  moduleData: any;
}
type revisionObj = {
  rev_no: number;
  summary: string;
  [key: string]: string | number;
};

const colSpanRevHeader = (head: string) => {
  if (head === 'Action Taken') return 5;
  if (head === 'Detail After Change') return 3;
  if (head === 'Detail Before Change' || head === 'Change Sugg.To') return 2;
  return 1;
};
const THead = ({ moduleData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={17}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="PROCESS CONTROL STANDARD"
      />
      <tr>
        {moduleData.part_type === 'prototype' ? (
          <td
            colSpan={2}
            style={{
              border: '1px solid black',
            }}
            align="left"
          >
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              ☑
            </span>
            <span>Proto Type</span>
          </td>
        ) : (
          <td colSpan={2} align="left" style={{ border: '1px solid black' }}>
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              □
            </span>
            <span>Proto Type</span>
          </td>
        )}

        {moduleData.part_type === 'pre-Launch' ? (
          <td
            colSpan={2}
            style={{
              border: '1px solid black',
            }}
            align="left"
          >
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              ☑
            </span>
            <span>Pre-Launch</span>
          </td>
        ) : (
          <td colSpan={2} align="left" style={{ border: '1px solid black' }}>
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              □
            </span>
            <span>Pre-Launch</span>
          </td>
        )}

        {moduleData.part_type === 'production' ? (
          <td
            colSpan={2}
            align="left"
            style={{
              border: '1px solid black',
            }}
          >
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              ☑
            </span>
            <span>Production</span>
          </td>
        ) : (
          <td colSpan={2} align="left" style={{ border: '1px solid black' }}>
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              □
            </span>
            <span>Production</span>
          </td>
        )}
        <td colSpan={11}></td>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Control Plan Number:
        </th>
        <th colSpan={2} align="left">
          {moduleData?.control_plan_no}
        </th>
        <th colSpan={3} align="left">
          Key Contact/Phone
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.key_contact}
        </th>
        <th colSpan={1} align="left">
          {moduleData?.part_id?.phone}
        </th>
        <th colSpan={2} align="left">
          DATE (Org) :
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.part_id?.date_orig)}
        </th>
        <th colSpan={2} align="left">
          DATE (Rev) :
        </th>
        <th colSpan={1} align="left">
          {formatDate(moduleData?.revision_date)}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part No./Latest change level:
        </th>
        <th colSpan={1} align="left">
          {moduleData?.part_id?.part_number}
        </th>
        <th colSpan={1} align="left">
          {moduleData?.latest_change_level}
        </th>
        <th colSpan={3} align="left">
          Core Team :
        </th>
        <th colSpan={3} align="left">
          {moduleData?.part_id?.core_team}
        </th>
        <th colSpan={2} align="left">
          Customer Engineering Approval Date(If Reqd)
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.customer_engg_approval_date)}
        </th>
        <th colSpan={2} align="left">
          Model / Vehicle
        </th>
        <th colSpan={1} align="left">
          {moduleData?.model}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part Name/Description :
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.part_name}
        </th>
        <th colSpan={3} align="left">
          Supplier/Plant ApprovalDate
        </th>
        <th colSpan={3} align="left">
          {formatDate(moduleData?.supplier_approval_date)}
        </th>
        <th colSpan={2} align="left">
          Customer Quality Approval date(If Reqd)
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.customer_quality_approval_date)}
        </th>
        <th colSpan={2} align="left">
          End Customer :
        </th>
        <th colSpan={1} align="left">
          {moduleData?.end_customer}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Supplier/Plant :{' '}
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.supplier_id?.supplier_name}
        </th>
        <th colSpan={3} align="left">
          Other approvalDate(If Reqd){' '}
        </th>
        <th colSpan={3} align="left">
          {formatDate(moduleData?.supplier_other_approval_date)}
        </th>
        <th colSpan={2} align="left">
          Other Approval Date(If Reqd){' '}
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.customer_other_approval_date)}
        </th>
        <th colSpan={2} align="left">
          Imm. Customer{' '}
        </th>
        <th colSpan={1} align="left">
          {moduleData?.part_id?.customer_id?.customer_name}
        </th>
      </tr>
      {/* revision map  */}
      {moduleData &&
        moduleData.revisionData
          .sort((a: revisionObj, b: revisionObj) => b.rev_no - a.rev_no)
          .map((revision: revisionObj, index: number) => (
            <tr style={{ background: 'yellow' }}>
              <td>{moduleData.revisionData?.length - index}</td>
              <td>{revision.rev_no}</td>
              <td>{formatDate(revision.rev_date)}</td>
              <td colSpan={2}>
                {revision.summary?.split('𝗧𝗢')[0].split('𝗙𝗥𝗢𝗠')[1]}
              </td>
              <td colSpan={3}>{revision.summary?.split('𝗧𝗢')[1]}</td>
              <td colSpan={5}>{revision.action_taken}</td>
              <td colSpan={2}>{revision.sugg_to}</td>
              <td colSpan={1}>{revision.sugg_by}</td>
              <td colSpan={1}>{revision.status}</td>
            </tr>
          ))}
      <tr style={{ background: 'yellow' }}>
        {[
          'S.No.',
          'Drg.Rev.No.',
          'Drg.Rev.Date',
          'Detail Before Change',
          'Detail After Change',
          'Action Taken',
          'Change Sugg.To',
          'Change Sugg.By',
          'Status',
        ].map((head, index) => (
          <th
            colSpan={colSpanRevHeader(head)}
            rowSpan={index === 3 || index === 4 || index === 5 ? 1 : 2}
            key={head + index}
          >
            {head} {' ⇧'}
          </th>
        ))}
      </tr>
      <tr style={{ background: 'yellow' }}>
        <th colSpan={10} style={{ textTransform: 'uppercase' }}>
          Ammendment Details
        </th>
      </tr>
    </thead>
  );
};

export default THead;
