import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  setRender: any;
  setSelectedMonth: (month: string) => void;
  selectedMonth: any;
}

const TController = ({
  moduleData,
  isView,
  setRender,
  setSelectedMonth,
  selectedMonth,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            setRender,
            setSelectedMonth,
            selectedMonth,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
          }}
        />
        <TFoot moduleData={moduleData} isView={isView} />
      </RenderTable>
    </Box>
  );
};

export default TController;
