export const listConstant = {
  childHeader: 'MSA plan',
  addButtonLable: 'New MSA',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'MSA plan',
  theadHeader: 'MSA (Gauge R & R ) Schedule',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'MSA STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'msaPlanRoute',
  gettableDataurl: 'msaPlanRoute/monthly',
  getPlanByid: 'msaPlanRoute',
  createPlanByid: 'msaPlanRoute',
  updatePlanByid: 'msaPlanRoute',
  sync: 'msaPlanRoute/handleSync',
  getAllCheckList: 'msaPlanRoute/getAllCheckList',
  checkListStatus: 'msaPlanRoute/handleStatus',
  checklistUpload: 'msaPlanRoute/handleUpload',
  checklistDeleteImage: 'msaPlanRoute/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/msaPlanCreate',
};
