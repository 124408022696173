import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';

const DataArray = ({ array, trial, bcColor }: any) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <Box sx={{ flexGrow: 1, width: '98%', margin: '1rem auto' }}>
        <div
          style={{
            borderBottom: '1px solid #343a40',
            marginBottom: '8px',
            fontSize: '1.6rem',
            backgroundColor: '#f8f9fa',
            marginLeft: '1px',
          }}
        >
          {trial}
        </div>
        <Grid container spacing={2}>
          {array?.length > 0 &&
            array?.map((_: any, MIdx: number) => (
              <Grid item xs={1.2} key={`${MIdx}+uud`}>
                <InputMuiCustom
                  type="number"
                  name=""
                  sx={{ ...bcColor }}
                  placeholder={`${MIdx + 1}`}
                  onChange={(e) => {
                    array[MIdx] = +e.target.value;
                    setRender((prev) => !prev);
                  }}
                  value={array[MIdx]}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default DataArray;
