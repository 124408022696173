import React, { useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
  CardIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import CategoryList from './CategoryList';
import formatDate from '../../../components/common/formatDate';
import { differenceInDays } from './helpers/getNumberOfDays';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import ViewFile from './ViewFile';
import { useNavigate } from 'react-router-dom';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'INSTRUMENT TYPE', rowspan: 1, colspan: 1 },
    { text: 'Inst. No.', rowspan: 1, colspan: 1 },
    { text: 'Inst. Name', rowspan: 1, colspan: 1 },
    { text: 'Acceptance Criteria', rowspan: 1, colspan: 1 },
    { text: 'Range', rowspan: 1, colspan: 1 },
    { text: 'Least Count', rowspan: 1, colspan: 1 },
    { text: 'Method of Calibration', rowspan: 1, colspan: 1 },
    { text: 'Calibration Frequency', rowspan: 1, colspan: 1 },
    { text: 'Calibration Frequency New', rowspan: 1, colspan: 1 },
    { text: 'Calibration Done Date', rowspan: 1, colspan: 1 },
    { text: 'Next Due Date', rowspan: 1, colspan: 1 },
    { text: 'Status', rowspan: 1, colspan: 1 },
    { text: 'Days Left', rowspan: 1, colspan: 1 },
    { text: 'Remark Status', rowspan: 1, colspan: 1 },
    { text: 'Comments', rowspan: 1, colspan: 1 },
    { text: 'Agency', rowspan: 1, colspan: 1 },
    { text: 'Location', rowspan: 1, colspan: 1 },
    {
      text: 'UPLOAD VENDOR DOCUMENTS',
      colspan: 1,
      rowspan: 1,
    },
    { text: 'HISTORY CARD', rowspan: 1, colspan: 1 },

    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'instrument_name', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
  { value: 'createdAt_old', name: 'Oldest First' },
];

const getCurrentStatus = (newDewDate: any) => {
  let status = '';
  const statusMap: any = {
    overdue: [{ bgColor: '#ff8787', width: '300px', text: 'NEED CALIBRATION' }],
    ok: [{ bgColor: '#69db7c', width: '300px', text: 'OK' }],
  };

  const daysLeft = differenceInDays(newDewDate);

  if (daysLeft <= 30) {
    status = 'overdue';
  } else {
    status = 'ok';
  }
  const [{ bgColor, text, width }] = statusMap[status] || [];
  return bgColor ? (
    <TableCell align="center" style={{ backgroundColor: bgColor, width }}>
      {text}
    </TableCell>
  ) : null;
};

const List = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [listId, setListId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const [categoryModal, setCategoryModal] = useState(false);
  const navigate = useNavigate();

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const idRef = useRef('');
  const fileTypeRef = useRef('');

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterlistinstrument/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const handleDelete = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterlistinstrument/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter]);

  const uploadFile = async (e: any, id: string, fileType: string) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);
    axios
      .patch(`/api/masterlistinstrument/uploadFile/${fileType}/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <ChildHeader text="INSTRUMENT LIST">
        <AddButton onClick={() => setCategoryModal(true)} label="CATEGORY" />
        <AddButton onClick={() => setIsModal(true)} label="New INSTRUMENT" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    {item?.category_id?.name}
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: '100px' }}>
                    {item?.instrument_no}
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: '200px' }}>
                    {item?.instrument_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.acceptance_criteria}
                  </TableCell>
                  <TableCell align="center">{item?.range}</TableCell>
                  <TableCell align="center">{item?.least_count}</TableCell>
                  <TableCell align="center">
                    {item?.method_of_calibration}
                  </TableCell>
                  <TableCell align="center">{item?.frequency_cycle}</TableCell>
                  <TableCell align="center">
                    {item?.frequency_cycle_new}
                  </TableCell>
                  <TableCell align="center">
                    {item.calibration_done_date &&
                      formatDate(item.calibration_done_date)}
                  </TableCell>
                  <TableCell align="center">
                    {item.next_due_date && formatDate(item.next_due_date)}
                  </TableCell>
                  {getCurrentStatus(item.next_due_date)}
                  <TableCell align="center">
                    {item.next_due_date &&
                      differenceInDays(new Date(item.next_due_date))}
                  </TableCell>
                  <TableCell align="center">{item?.status_remark}</TableCell>
                  <TableCell align="center">{item?.comments}</TableCell>
                  <TableCell align="center">{item?.agency}</TableCell>
                  <TableCell align="center">{item?.location}</TableCell>
                  <TableCell align="center">
                    <Stack
                      direction={'row'}
                      alignItems="center"
                      justifyContent="start"
                    >
                      <CustomUploadButton
                        id={`upload-vendor-documents-${index}`}
                        accept="image/png,image/jpeg,image/jpg,application/pdf"
                        multiple={true}
                        onChange={(e) => {
                          uploadFile(e, item?._id, 'files');
                        }}
                      />
                      {item.files?.length > 0 && (
                        <>
                          <ViewIconButton
                            tooltipTitle="view"
                            sx={{ marginLeft: '1.5rem' }}
                            onClick={() => {
                              idRef.current = item._id;
                              fileTypeRef.current = 'files';
                              setIsOpenPopup(true);
                            }}
                          />
                        </>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <CardIconButton
                        tooltipTitle="History Card"
                        onClick={() => {
                          navigate(
                            `/common/instrumentMasterlist/history/${item._id}`
                          );
                        }}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setListId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          handleDelete(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />

      <ModalCustom
        title="FILE LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <ViewFile id={idRef.current} fileType={fileTypeRef.current} />
      </ModalCustom>
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setListId(null);
          setIsModal(false);
        }}
        title="CREATE NEW INSTUEMENT"
      >
        <Create id={listId} setIsModal={setIsModal} setListId={setListId} />
      </ModalCustom>
      <ModalCustom
        openModal={categoryModal}
        closeModal={() => {
          setCategoryModal(false);
        }}
        title="CATEGORY LIST"
      >
        <CategoryList />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
