import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../../components/common';
import DatePickerMui from '../../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../../../hooks/UseQuery';
import AutocompleteMuiCustom from '../../../../../../../components/common/AutocompleteMuiCustom';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  tniTopics: any;
  allDepartment: any;
  getTopicByDepartment: any;
  autocompleteData: any;
  setAutocompleteData: any;
}

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const THead = ({
  moduleData,
  tniTopics,
  allDepartment,
  getTopicByDepartment,
  autocompleteData,
  setAutocompleteData,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={4 + tniTopics.length}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['F-HRD-15', '02', '10.05.2023']}
        heading={`${createConstant.theadHeader}${getYearRange(
          moduleData.created_year
        )}`}
      />
      <tr>
        <th>DEPARTMENT</th>
        <th>
          <AutocompleteMuiCustom
            label={''}
            id="department_name"
            option_name={'department_name'}
            value={autocompleteData || ''}
            arrayofObj={allDepartment}
            onChange={(e, value) => {
              moduleData.area_of_work = value;
              setAutocompleteData(value);
              getTopicByDepartment(value.department_id);
            }}
          />
        </th>
        <th>YEAR:</th>
        <th colSpan={1 + tniTopics.length}>
          {formatDate(moduleData.createdAt)}
        </th>
      </tr>
      <tr>
        {[
          'S.no.',
          'Employee Name',
          'Designation',
          'Training Topics',
          'Remarks',
        ].map((el, index) => (
          <th
            rowSpan={index === 3 ? 1 : 3}
            colSpan={index === 3 ? tniTopics.length : 1}
            align="center"
            style={{ textAlign: 'center' }}
          >
            {el}
          </th>
        ))}
      </tr>
      <tr>
        {tniTopics.map((topic: any, index: number) => (
          <th align="center" style={{ textAlign: 'center' }}>
            {topic.topic_type}
          </th>
        ))}
      </tr>
      <tr>
        {tniTopics.map((topic: any, index: number) => (
          <th align="center" style={{ textAlign: 'center' }}>
            {topic.name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
