import React, { useEffect, useState } from 'react';
import { Checkbox, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import {
  AddButton,
  CustomButton,
} from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  CardIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { Loader } from '../../components/common/Loader';
import { useNavigate } from 'react-router-dom';
import { GrStatusGood } from 'react-icons/gr';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1, style: { border: '1px solid black' } },
    {
      text: 'Die Name',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Die Number ',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    // {
    //   text: 'Part Name',
    //   rowspan: 1,
    //   colspan: 1,
    //   style: { border: '1px solid black' },
    // },
    {
      text: 'Department Name',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Frequency / No. of Shots (From)',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Frequency / No. of Shots (To)',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Remarks',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Maintenance',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Die Under Testing',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Action',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const List = () => {
  const [data, setData] = useState<any>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const department = useQuery('department');
  const [filterDepartment, setFilterDepartment] = useState([]);
  const search = useQuery('search');
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const navigate = useNavigate();

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );
  const [DialogMaintenance, confirmMaintenance] = useConfirm(
    'Maintenance',
    'Are you sure you want to complete it?'
  );
  useEffect(() => {
    axios
      .get('/api/department/department/departmentForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((department: any) => ({
            name: department.department_name,
            value: department.department_name,
          }));
          setFilterDepartment(formattedData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterListOfToolsOrDieRoute?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&department=${department}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteModuleData = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterListOfToolsOrDieRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, department]);

  const handleMaintenance = async (id: string) => {
    const ans = await confirmMaintenance();
    if (!ans) return;
    await axios
      .put(`/api/masterListOfToolsOrDieRoute/${id}`, {
        maintenance_done: 'completed',
      })
      .then((res) => {
        alert('successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  const handleUnderTesting = async (e: any, id: string) => {
    const { name, checked } = e.target;
    await axios
      .put(`/api/masterListOfToolsOrDieRoute/${id}`, {
        [name]: checked,
      })
      .then((res) => {
        alert('successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <ChildHeader text="DIE LIST">
        <AddButton onClick={() => setIsModal(true)} label="New Die" />
      </ChildHeader>
      <SearchFilterContainer filter={true} style={{ display: 'flex' }}>
        <SearchBar label="Search Die name, number" sx={{ width: 900 }} />
        <FilterBy options={filterOptions} sx={{ width: 300 }} />
        <FilterBy
          sx={{ width: 300 }}
          name="department"
          label="Filter By Department"
          options={filterDepartment}
        />
      </SearchFilterContainer>
      <Loader loader={isLoading} />
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow
                  style={{
                    background:
                      item?.maintenance_done === 'start' ? '#ff6b6b' : '',
                    border: '1px solid black',
                  }}
                >
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.die_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.die_number}
                  </TableCell>
                  {/* <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.part_id?.part_name}
                  </TableCell> */}
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.department_id?.department_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.frequency}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.frequency_to}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.remarks}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {item?.maintenance_done && (
                      <CustomButton
                        color="greenButton"
                        sx={{ margin: '10px' }}
                        icon={<GrStatusGood />}
                        disabled={false}
                        size="small"
                        onClick={() => handleMaintenance(item._id)}
                      >
                        {item?.maintenance_done || 'None'}
                      </CustomButton>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    <Checkbox
                      name="under_testing"
                      color="primary"
                      checked={item?.under_testing}
                      onChange={(e) => {
                        handleUnderTesting(e, item?._id);
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ border: '1px solid black' }}>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <CardIconButton
                        tooltipTitle="Tools History"
                        onClick={() => {
                          navigate(`/common/toolsOrDieHistory/${item._id}`);
                        }}
                      />

                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setModuleId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteModuleData(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title="DIE"
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
      <DialogMaintenance isSubmitContent={false} />
    </>
  );
};

export default List;
