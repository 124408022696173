import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';

const Create = ({
  id,
  setIsModal,
}: {
  id?: null | string;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleDate, setModuleData] = useState<IData>({
    erp_code: '',
    spare_part_desc: '',
    used_for_machine: '',
    function: '',
    min_stock_qty: null,
    max_stock_qty: null,
    // opening_stock_qty: null,
    closing_stock_qty: null,
    opening_stock_UOM: '',
    lead_time: null,
    // location: '',
    // remarks: '',
    first_week: '',
    second_week: '',
    third_week: '',
    fourth_week: '',
  });
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update ' : 'Create '}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/criticalSparePartsRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModuleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/criticalSparePartsRoute/${id}`, moduleDate)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/criticalSparePartsRoute', moduleDate)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'erp_code'}
        name={'erp_code'}
        html_for={'erp_code'}
        label_name={'Spare Part Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.erp_code || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'spare_part_desc'}
        name={'spare_part_desc'}
        html_for={'spare_part_desc'}
        label_name={'Spare Part Description:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.spare_part_desc || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'used_for_machine'}
        name={'used_for_machine'}
        html_for={'used_for_machine'}
        label_name={'Use Area:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.used_for_machine || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'function'}
        name={'function'}
        html_for={'function'}
        label_name={'Function:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.function || ''}
        onChange={handleModuleData}
      />
      <h2>REQUIREMENT</h2>
      <GridInputs
        id={'min_stock_qty'}
        name={'min_stock_qty'}
        html_for={'min_stock_qty'}
        label_name={'MIN. QTY. :'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleDate?.min_stock_qty || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'max_stock_qty'}
        name={'max_stock_qty'}
        html_for={'max_stock_qty'}
        label_name={'MAX. QTY. :'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleDate?.max_stock_qty || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'closing_stock_qty'}
        name={'closing_stock_qty'}
        html_for={'closing_stock_qty'}
        label_name={'STOCK QTY. :'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleDate?.closing_stock_qty || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'opening_stock_UOM'}
        name={'opening_stock_UOM'}
        html_for={'opening_stock_UOM'}
        label_name={'UOM:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.opening_stock_UOM || ''}
        onChange={handleModuleData}
      />
      <div style={{ marginTop: '20px' }}></div>
      <GridInputs
        id={'lead_time'}
        name={'lead_time'}
        html_for={'lead_time'}
        label_name={'Lead Time (In Days):'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleDate?.lead_time || ''}
        onChange={handleModuleData}
      />
      {/* <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.location || ''}
        onChange={handleModuleData}
      /> */}
      {/* <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.remarks || ''}
        onChange={handleModuleData}
        last_child={2}
      /> */}
      <h2>STATUS OF AVAILABILITY</h2>
      <GridInputs
        id={'first_week'}
        name={'first_week'}
        html_for={'first_week'}
        label_name={'1st Week:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.first_week || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'second_week'}
        name={'second_week'}
        html_for={'second_week'}
        label_name={'2nd Week:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.second_week || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'third_week'}
        name={'third_week'}
        html_for={'third_week'}
        label_name={'3rd Week:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.third_week || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'fourth_week'}
        name={'fourth_week'}
        html_for={'fourth_week'}
        label_name={'4th Week:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.fourth_week || ''}
        onChange={handleModuleData}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
