import React from 'react';
// icon
import { TbReportAnalytics } from 'react-icons/tb';
import { FcInspection } from 'react-icons/fc';
// module
import ChildHeader from '../../../components/ui/ChildHeader';
import { RenderTiles } from '../../../components/common';
import { useAuth } from '../../../services/auth/AuthProvider';

const PdirTiles = () => {
  const { state } = useAuth();
  const { user } = state;
  const tiles = [
    {
      sNo: 1,
      link: `/common/maintenance_breakdown/preventive_maintenance_list?documentType=preventive`,
      isLink: true,
      icon: <FcInspection size="5rem" color="#a12222" />,
      p: ' PREVENTIVE MAINTENANCE',
      span: 'PLAN',
      isIcon: true,
      // <BuildIcon style={{ fontSize: "3rem", color: "maroon" }} />
    },
    {
      sNo: 2,
      link: `/common/maintenance_breakdown/preventive_maintenance_check_list?documentType=preventive`,
      isLink: true,
      icon: <TbReportAnalytics size="5rem" color="#2b8a3e" />,
      p: ' PREVENTIVE MAINTENANCE',
      span: 'STATUS',
      isIcon: true,
      // <Spellcheck style={{ fontSize: "3rem", color: "#003566" }} />
    },
    // {
    //   sNo: 3,
    //   link: `/common/maintenance_breakdown/maintenance?documentType=predictive`,
    //   isLink: true,
    //   icon: <TbReportAnalytics size="5rem" color="#2b8a3e" />,
    //   p: 'PREDICTIVE MAINTENANCE',
    //   span: 'PLAN',
    //   isIcon: true,
    // },
    // {
    //   sNo: 3,
    //   link: `/common/checklist_actions_list?documentType=predictive`,
    //   isLink: true,
    //   icon: <TbReportAnalytics size="5rem" color="#2b8a3e" />,
    //   p: 'PREDICTIVE MAINTENANCE',
    //   span: 'STATUS',
    //   isIcon: true,
    // },
  ];
  return (
    <>
      <ChildHeader text=" MAINTENANCE BREAKDOWN" />
      <RenderTiles
        height="100vh"
        tilesObj={tiles}
        justify="start"
        columns="0fr"
      />
      ;
    </>
  );
};

export default PdirTiles;
