import { AiOutlineCloudUpload, AiOutlineFundView } from 'react-icons/ai';
import { MdModelTraining } from 'react-icons/md';
import { GrTest } from 'react-icons/gr';
import { ImUserTie } from 'react-icons/im';
import { GiSkills } from 'react-icons/gi';

const tiles = [
  {
    sNo: 1,
    link: '/admin/masterlistoffaculty?filter=createdAt',
    isLink: true,
    p: 'Master List Of',
    span: 'FACULTY',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
  {
    sNo: 2,
    link: '/common/masterlistofdepartment?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    p: 'Master List Of',
    span: 'Department',
    icon: <AiOutlineFundView size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/employeesList?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'EMPLOYEE',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
  {
    sNo: 1,
    link: '/common/skillEvaluationSheetList',
    isLink: true,
    p: 'SKILL',
    span: 'EVALUATION',
    isIcon: true,
    icon: <GiSkills size="5rem" color="#343a40" />,
  },
  {
    sNo: 1,
    link: '/common/tnihome',
    isLink: true,
    p: 'TRAINING',
    span: 'NEED IDENTIFICATION',
    isIcon: true,
    icon: <MdModelTraining size="5rem" color="#087f5b" />,
  },
];

export default tiles;
