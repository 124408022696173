import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';

interface IProps {
  deleteDocument: (id: string) => void;
  tableData: {
    [key: string | number]: any;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'Item NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Item NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'MACHINE NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: ' DATE',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const List = ({ deleteDocument, tableData }: IProps) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData?.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">
                  {document?.part_id?.part_name}
                </TableCell>
                <TableCell align="center">
                  {document?.part_id?.part_number}
                </TableCell>
                <TableCell align="center">
                  {document?.machine_id?.machine_no}
                </TableCell>
                <TableCell align="center">
                  {document?.moduleDate && formatDate(document.moduleDate)}
                </TableCell>

                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/createProcessParameterStd/${document?.part_id?._id}/${document._id}?isView=true`
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/createProcessParameterStd/${document?.part_id?._id}/${document._id}?isView=false`
                      )
                    }
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default List;
