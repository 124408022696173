import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot style={{ background: '#a9e34b' }}>
      <tr>
        <th colSpan={8} align="left">
          TOTAL NON CONFORMANCES
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          AUDITOR SIGNATURE
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          CONCERNED AUDITEE SIGNATURE
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Prepared By :-
        </th>
        <th colSpan={4} align="left">
          Approved By :-
        </th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
