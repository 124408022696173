import { Checkbox, Tooltip } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import useConfirm from '../../../../../components/common/useConfirm';

interface IProps {
  isView: boolean;
  moduleData: any;
}
const instruction = [
  'डाई मे किसी प्रकार  का लीकेज नहीं होना चाहिए I',
  'डाई की आवश्यकता अनुसार कूलिंग चलानी चाहिए I',
  'मशीन चलाने से पहले मैटल को पूरी तरह से साफ कर ले I',
  'डाई व प्लंजर से फ्लैश नहीं मारना चाहिए I',
  'डाई में ज्यादा लुब्रिकेशन नहीं होना चाहिए I',
  'डाई के  अन्दर ओवर फ्लो, एयर वेंट व चिलर वेंट को साफ करके ही मशीन चलाये I',
  'मेटल का तापमान यदि दिये हुए स्टेन्डर्ड से कम है तो मशीन को न चलाये  व तापमान को स्टेन्डर्ड करके ही मशीन चलाये  I',
];
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );

  const handelDeleteRows = async (index: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    moduleData.specs.splice(index, 1);
    setRender((prev) => !prev);
  };
  return (
    <tbody>
      {moduleData?.specs?.map((item: any, index: number) => (
        <tr>
          <td style={{ width: '120px' }}>
            <TableInput
              name="s_no"
              type="text"
              value={item.s_no}
              onChange={(e) => {
                item.s_no = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="parameter"
              type="text"
              value={item.parameter}
              onChange={(e) => {
                item.parameter = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="specification"
              type="text"
              value={item.specification}
              onChange={(e) => {
                item.specification = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="method"
              type="text"
              value={item.method}
              onChange={(e) => {
                item.method = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="responsibility"
              type="text"
              value={item.responsibility}
              onChange={(e) => {
                item.responsibility = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="freq"
              type="text"
              value={item.freq}
              onChange={(e) => {
                item.freq = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td colSpan={isView ? 2 : 1}>
            <TableInput
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e) => {
                item.remarks = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          {!isView && (
            <td>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(index)}
              />
            </td>
          )}

          {/* <td style={{ width: '150px' }}>
            {isView ? (
              item.spec_to_inspection && '✓'
            ) : (
              <>
                <Tooltip title="Add To Inspection Report">
                  <Checkbox
                    checked={item.spec_to_inspection === true}
                    onChange={(e) => {
                      item.spec_to_inspection = e.target.checked;
                      setRender((prev) => !prev);
                    }}
                  />
                </Tooltip>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </>
            )}
          </td> */}
        </tr>
      ))}
      <tr>
        <th colSpan={8} align="left">
          Plunger size (mm) :-
          <TableInput
            name="plunger_size"
            type="number"
            value={moduleData.plunger_size}
            onChange={(e) => {
              moduleData.plunger_size = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      {instruction?.map((item, index) => (
        <tr>
          <th colSpan={8} align="left">
            {index + 1} - {item}
          </th>
        </tr>
      ))}
      <DialogDeleteRow isSubmitContent={false} />
    </tbody>
  );
};

export default TBody;
