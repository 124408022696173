export const checkListList = {
  childHeader: 'Maintenance STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'operatorObservancePlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'operatorObservancePlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'operatorObservancePlanStatusRoute/handleUpload',
  checklistDeleteImage:
    'operatorObservancePlanStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const navigationConstant = {
  checklistRouter: '/common/operatorObservanceStatusCheckList',
};
