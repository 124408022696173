import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
// import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import { SelectChangeEvent } from '@mui/material';
import { IData } from './Interface';
// import { IData } from './Interface';

const Create = ({
  id,
  setIsModal,
  setMachineId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMachineId: any;
}) => {
  const [moduleData, setModuleData] = useState<IData>({});
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Auditor' : 'Create Auditor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/qualifiedAuditors/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    const { name, value } = e.target;
    setModuleData((prev: any) => ({ ...prev, [name]: value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/qualifiedAuditors/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
          setMachineId(null);
        })
        .catch((err) => console.log(err));
    } else {
      console.log('mod', moduleData);
      await axios
        .post('/api/qualifiedAuditors', moduleData)
        .then((res) => {
          setIsModal(false);
          setMachineId(null);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'name'}
        name={'name'}
        html_for={'name'}
        label_name={'NAME:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.name}
        onChange={handleChange}
      />
      <GridInputs
        id={'certificate'}
        name={'certificate'}
        html_for={'certificate'}
        label_name={'Certificate:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.certificate}
        onChange={handleChange}
      />
      <GridInputs
        id={'workEx'}
        name={'workEx'}
        html_for={'workEx'}
        label_name={'Work Ex:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.workEx}
        onChange={handleChange}
      />
      <GridInputs
        id={'designation'}
        name={'designation'}
        html_for={'designation'}
        label_name={'Designation:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.designation}
        onChange={handleChange}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
