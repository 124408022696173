import {
  Box,
  MenuItem,
  Pagination,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import { SetStateAction, useEffect, useState } from 'react';

import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../../../components/common';
import {
  AddButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import ModalCustom from '../../../../components/common/Modal';
import { SupervisorPartTable } from '../../../../components/ui';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import ModalInput from './ModalInput';
import { listConstant, apiConstant, navigationConstant } from './constant';
import React from 'react';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
  AddIconButton,
  BlockIconButton,
  SyncAltIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../../../components/common/SelectField';
import { SearchFilterContainer } from '../../../../assets/styles/layout/searchFilter.styled';
import { SearchBar } from '../../../../components/common/SearchBar';
import useDebounce from '../../../../hooks/useDebounce';
import formatDate from '../../../../components/common/formatDate';
import useConfirm from '../../../../components/common/useConfirm';

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Machine Name', rowspan: 1, colspan: 1 },
    { text: 'Machine No.', rowspan: 1, colspan: 1 },
    { text: 'Area', rowspan: 1, colspan: 1 },
    { text: 'Maintenance Due Date', rowspan: 1, colspan: 1 },
    { text: 'Last Maintenance Date', rowspan: 1, colspan: 1 },
    { text: 'Status', rowspan: 1, colspan: 2 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

function getCurrentStatus(status: any, dueDate: any) {
  let diff: any;
  if (dueDate) {
    let dueDateArr = dueDate.split('/');
    let a: any = new Date(`${dueDateArr[1]}/${dueDateArr[0]}/${dueDateArr[2]}`);
    let b: any = new Date();
    diff = a - b;
  }
  if (status === 'pending' && diff < 0) {
    return (
      <TableCell
        align="center"
        style={{ backgroundColor: '#e03131', color: 'white' }}
      >
        MAINTENANCE OVERDUE
      </TableCell>
    );
  }

  if (status === 'pending') {
    return (
      <TableCell align="center" style={{ backgroundColor: '#fcc419' }}>
        MAINTENANCE NOT STARTED
      </TableCell>
    );
  }

  if (status === 'completed') {
    return (
      <TableCell
        align="center"
        style={{ backgroundColor: '#2f9e44', color: 'white' }}
      >
        COMPLETED
      </TableCell>
    );
  }
  if (status === 'started') {
    return (
      <TableCell align="center" style={{ backgroundColor: '#5c7cfa' }}>
        MAINTENANCE ONGOING
      </TableCell>
    );
  }
}

const TableList = () => {
  const { state } = useAuth();
  const { user } = state;
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([{}]);
  const [listId, setListId] = useState('');
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const documentType = useQuery('documentType');
  const search = useQuery('search') || '';
  const [DialogChange, confirmChange] = useConfirm(
    'Change Machine',
    'Are you sure you want to change machine?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/preventiveChecklist/getAllChecklist/${documentType}?page=${page}&search=${search}`
      )
      .catch((err) => {
        console.log(err);
      });
    setTableData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    /* isMaintenanceModal*/
    ,
  ]);
  // useEffect(() => {
  //   axios
  //     .get(`/api/${apiConstant.gettableDataurl}/${date}`)
  //     .then((res) => {
  //       if (res.data) setTableData(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [render, date, isOpenPopup]);

  const handleDelete = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/${apiConstant.deleteurl}/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  const handleClearChecklistTable = async (machineid: any) => {
    const isClear = await confirmChange();
    if (isClear) {
      axios
        .get(`/api/preventiveChecklist/getChecklistById/${machineid}`)
        .then((res) => {
          if (res.data) {
            let data = res.data;
            data.checkListMachineData = {
              Spot_Welding: [],
              Grinding: [],
              // Bending_Machine_01: [],
              // Induction_Brazing_Machine: [],
              // NRV_Testing_Machine: [],
              // Pera_Flange_Machine: [],
              // Bending_Machine_02: [],
              // Flushing_Machine: [],
              // Pipe_Flairing_Machine: [],
              // Flushing: [],
              // Leakage_Testing: [],
              // Pipe_Bending: [],
              // Ultrasonic: [],
              // Tig_Welding: [],
              // Clamping: [],
              // Ultrasonic_1: [],
              // Oven: [],
              // Farule_Fitting_Machine: [],
              // Magna_Flux: [],
              // Lazer_Marking: [],
              // Air_Decay: [],
              // Soco_Cutter_Machine: [],
              // Crimping_Machine: [],
              // Ss_Furnace: [],
              // Cutting_Machine: [],
              // Benjo_Press_Machine: [],
              // Deburring_Machine: [],
              // Oil_Flushing_Machine: [],
              // Polishing_Machine: [],
              // End_Forming_Machine: [],
              // Pad_Printing: [],
              // Centre_Lathe_Machine: [],
              // Hydraullic_Press_1: [],
              // Radial_Drill_Machine: [],
              // Ultrasonic_2: [],
              // Hydraullic_Press_2: [],
              // Surface_Grinder: [],
              // Blockage_Testing_Machine: [],
              // Drill_Machine: [],
              // Air_Leaking_Testing: [],
              // T_Drill_Machine: [],
              // High_Pressure_Leakage: [],
              // Bust_Testing_Machine: [],
              // ORing_Testing_Machine: [],
              // Bending_Machine: [],
            };
            data.document_name = '';
            data.status = 'pending';
            axios
              .put(
                `/api/preventiveChecklist/updateChecklist/${machineid}`,
                data
              )
              .then((res) => {
                window.alert('updated successfully');
                setRender((prev) => !prev);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box>
      <ChildHeader text={`${listConstant.childHeader}`}>
        {/* <AddButton
          label={`${listConstant.addButtonLable}`}
          onClick={() => setIsOpenPopup(true)}
        /> */}
      </ChildHeader>
      {/* <Box sx={{ margin: 2 }}>
        <PersitedDatePicker
          label={`${listConstant.datePickerLabel}`}
          views={['year']}
        />
      </Box> */}
      <SearchFilterContainer filter={false}>
        <SearchBar label="Search Machine Name" />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item?.machine_name}</TableCell>
                  <TableCell align="center">{item?.machine_no}</TableCell>
                  <TableCell align="center">{item?.area}</TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {item?.preventive_time}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item?.last_maintenance_date)}
                  </TableCell>

                  {getCurrentStatus(item?.status, item?.preventive_time)}

                  <TableCell align="center">
                    {getYearRange(item.date_range)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {item?.document_name ? (
                        <SyncAltIconButton
                          tooltipTitle="Change Machine"
                          // onClick={() => {
                          //   setListId(item?._id || '');
                          //   setIsOpenPopup(true);
                          // }}
                          onClick={() => {
                            handleClearChecklistTable(item._id);
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            height: '28.4px',
                            width: '28.4px',
                            marginRight: '20px',
                          }}
                        ></div>
                      )}
                      <ViewIconButton
                        tooltipTitle="View Checklist"
                        onClick={() => {
                          if (item.document_name) {
                            navigate(
                              `/common/maintenanceForCheckedMachineForm/${item._id}/${item.document_name}?isView=true`
                            );
                          }
                        }}
                      />
                      <AddIconButton
                        tooltipTitle="Edit Checklist"
                        // onClick={() => {
                        //   handleDelete(item?._id || '');
                        // }}
                        // onClick={() => setIsOpenPopup(true)}
                        onClick={() => {
                          if (item.document_name) {
                            navigate(
                              `/common/maintenanceForCheckedMachineForm/${item._id}/${item.document_name}?isView=false`
                            );
                          } else {
                            setListId(item?._id || '');
                            setIsOpenPopup(true);
                          }
                        }}
                        // label={`${listConstant.addButtonLable}`}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1rem',
          marginBottom: '4rem',
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
      <ModalCustom
        title={`${listConstant.modalTile}`}
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          setListId('');
        }}
      >
        <ModalInput
          setIsModal={setIsOpenPopup}
          id={listId}
          setListId={setListId}
        />
      </ModalCustom>
      <DialogChange />
    </Box>
  );
};

export default TableList;
