import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { TableCompHead } from '../../../../../components/ui';
import { moduleConstant } from '../../helpers/constant';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc', 'Rev No', 'Rev Date']}
        values={['F-MNT-12 AH', '00', '01-01-2021']}
        heading={moduleConstant?.checklist?.childHeader}
      />
      <tr>
        <th colSpan={4} align="left">
          Responsibility :
          <TableInput
            isView={isView}
            name="responsibility"
            type="text"
            value={moduleData.responsibility}
            onChange={(e) => {
              moduleData.responsibility = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <td colSpan={7}></td>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.No.',
          'Location',
          'Month & Year',
          'Check Point',
          'लाइट की सफाई',
          'लाइट की चार्जिंग',
          'लाइट की वाइर',
          'लाइट की वर्किंग',
          'चेक करने वाला',
          'परखने वाला',
          'Action',
        ].map((head) => (
          <th style={{ textTransform: 'uppercase' }}>{head}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
