import React from 'react';
import AutocompleteMuiCustom from '../AutocompleteMuiCustom';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import LockIcon from '@mui/icons-material/Lock';
import styled from 'styled-components';

interface Iprops {
  id: string;
  value: string | {};
  option_name: string;
  arrayofObj: [] | any;
  label: string;
  last_child?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: any) => void;
  locked?: boolean;
  // disabled?: boolean;
}

const LabelWithIcon = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledLockIcon = styled(LockIcon)`
  position: absolute;
  right: 0; /* Adjust based on your design */
  color: #ced4da; /* Lock icon color */
`;

const AutocompleteGridmui = ({
  id,
  value,
  option_name,
  arrayofObj,
  label,
  onChange,
  last_child,
  locked = false,
}: // disabled,
Iprops) => {
  return (
    <DivStyled last_child={last_child}>
      <LabelWithIcon htmlFor={id}>
        {label}
        {locked && <StyledLockIcon />}
      </LabelWithIcon>
      <div
        style={{
          borderLeft: '2px solid #ced4da',
          paddingLeft: '1rem',
          width: '100%',
        }}
      >
        <AutocompleteMuiCustom
          id={id}
          value={value}
          option_name={option_name}
          arrayofObj={arrayofObj}
          variant={'standard'}
          onChange={onChange}
          disabled={locked}
        />
      </div>
    </DivStyled>
  );
};

export default AutocompleteGridmui;
