import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { handleChangeTimeByShift } from '../../helpers/handleChangeModuleData';

interface IProps {
  moduleData: any;
  isView: boolean;
  setControllerRender: any;
}

const theadData = [
  'Shift A/B ',
  'M/C No',
  'Part Name & Number',
  'Rotor Size in mm',
  'Batch Number',
  'Operator-1',
  'Operator-2',
  'Time',
  'Shots',
  'No of Cavity',
  'Total Qty.',
  'OK Qty.',
  'Rej Qty',
  'Verified By Sign.',
];

const THead = ({ moduleData, isView, setControllerRender }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={17}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-PROD-02A', '01', '01.04.2023']}
        heading="Daily Production - Rotor"
      />
      <tr>
        <th colSpan={2}>Daily Production Date : </th>
        <th colSpan={3} align="left">
          {isView ? (
            formatDate(moduleData.dailyProduction_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyProduction_date)}
              onChange={(date) => {
                moduleData.dailyProduction_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4} align="left">
          Shift:
          {isView ? (
            moduleData.shift
          ) : (
            <CustomSelect
              name="shift"
              value={moduleData.shift || ''}
              onChange={(e: any) => {
                moduleData.shift = e.target.value;
                handleChangeTimeByShift(moduleData, e.target.value);
                setControllerRender((prev: any) => !prev);
              }}
            >
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>=
            </CustomSelect>
          )}
        </th>
        <th colSpan={8}></th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            rowSpan={heading === 'Time' ? 1 : 2}
            colSpan={heading === 'Time' ? 2 : 1}
          >
            {heading}
          </th>
        ))}
        <th colSpan={2} rowSpan={2}>
          Remarks
        </th>
      </tr>
      <tr>
        <th>Start</th>
        <th>Stop</th>
      </tr>
    </thead>
  );
};

export default THead;
