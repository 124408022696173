export const checkListList = {
  childHeader: 'SPC PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'spcPlanNewStatusRoute/getAllSatusByYear',
  upadateListStatus: 'spcPlanNewStatusRoute/handleStatusUpdate',
  checklistUpload: 'spcPlanNewStatusRoute/handleUpload',
  checklistDeleteImage: 'spcPlanNewStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: '',
};

export const navigationConstant = {
  checklistRouter: '/common/spcPlanNewStatusCheckList',
};
