const style1 = {
  backgroundColor: '#4F81BD',
  // backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
  border: '1px solid #343a40',
};

export const listHeaderObj = [
  [
    { text: '#', style: { ...style1 } },
    { text: 'TITLE', style: { ...style1, minWidth: '100px' } },
    { text: 'DEPARTMENT', style: { ...style1 } },
    { text: 'Date Range	', style: { ...style1 } },
    { text: 'ACTION', style: { color: '#fff', backgroundColor: '#c92a2a' } },
  ],
];

export const createHeaderStandard = (props: any) => {
  const { headerList } = props;
  const topicNameCols = headerList.map((item: any) => ({
    text: item?.traning_topic,
    style: { ...style1, backgroundColor: '#00B050' },
  }));
  const topicLvlCols = headerList.map((item: any) => ({
    text: item?.minimum_level,
    style: { ...style1, backgroundColor: '#00B050' },
  }));

  return [
    [
      {
        text: 'S.No.',
        style: { ...style1, minWidth: '90px', width: '80px' },
        rowspan: 3,
      },
      { text: 'Name of Employee', style: style1, rowspan: 3 },
      { text: 'Designation', style: style1, rowspan: 3 },
      { text: ' Department	', style: style1, rowspan: 3 },
      {
        text: 'Training Topics',
        style: style1,
        rowspan: 1,
        colspan: headerList?.length || 1,
      },
      { text: 'Remarks', style: style1, rowspan: 3 },
      {
        text: 'Action',
        style: { ...style1, color: '#fff', backgroundColor: '#c92a2a' },
        rowspan: 3,
      },
    ],
    [...topicLvlCols],
    [...topicNameCols],
  ];
};

export const listConstant = {
  childHeader: 'TNI REPORT LIST',
  datePickerLabel: 'Search By Year',
  addButtonLable: 'New TNI REPORT',
  modalHeaderName: 'CREATE NEW TNI REPORT',
};

export const headerConstant = {
  tableCompHeadTitle: 'SKILL EVALUATION SHEET',
};

export const apiConstant = {
  get: 'tniReportRoute/byDate/tnidocs',
  getById: 'tniReportRoute/tnidoc',
  post: 'tniReportRoute/create/tnidoc',
  update: 'tniReportRoute/update/tnidoc',
  delete: 'tniReportRoute/delete/tnidoc',
  patch: 'tniReportRoute', //sync
  departmentAutoComplete: 'department',

  getTniTopicAndChildEmps: 'tniReportRoute/tniTopicAndEmpsReport',
  upadateCreateEmpTniReport: 'tniReportRoute/createUpdate/empTniReports',
  bulkDeleteEmpTniReport: 'tniReportRoute/empTniReports/deleteBulkById',
  empAutocomplete: 'tniReportRoute/empAutocomplete',
};

export const tilesConstant = {
  childHeaderText: 'TNI FOR',
};

export const navigationConstant = {
  createEditTniReport: '/common/tniReportCreate',
};
