import axios from 'axios';
import { useState, useEffect } from 'react';
import lodash from 'lodash';
import { checklistData } from './checklistData';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    checklist_date: new Date(),
    list: lodash.cloneDeep(checklistData),
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/dieLoadingChecklistRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);
  return {
    moduleData,
    isLoading,
    error,
  };
};

export default useGetData;
