import React, { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TableCustom } from '../../../../components/common';
import useDebounce from '../../../../hooks/useDebounce';

interface IPart {
  // _id?: string;
  // erp_code: string | null;
  // spare_part_desc: string | null;
  // used_for_machine: string | null;
  // opening_stock_qty: number | null;
  // opening_stock_UOM: string | null;
  // min_stock_qty: number | null;
  // max_stock_qty: number | null;
  // closing_stock_qty: number | null;
  // location: string | null;
  // remarks: string | null;
  _id?: string;
  erp_code: string | null;
  spare_part_desc: string | null;
  used_for_machine: string | null;
  function: string | null;
  min_stock_qty: number | null;
  max_stock_qty: number | null;
  closing_stock_qty: number | null;
  opening_stock_UOM: string | null;
  lead_time: string | null;
  // location: string | null;
  // remarks: string | null;
  first_week: string | null;
  second_week: string | null;
  third_week: string | null;
  fourth_week: string | null;
}
const tableHeaderObj = [
  [
    // { text: 'S.No.', rowspan: 1, colspan: 1 },
    // { text: 'ERP Code', rowspan: 1, colspan: 1 },
    // { text: 'Spare Part Description', rowspan: 1, colspan: 1 },
    // { text: 'Used For machine', rowspan: 1, colspan: 1 },
    // { text: 'Stock Opening Qty', rowspan: 1, colspan: 1 },
    // { text: 'Stock Opening UOM', rowspan: 1, colspan: 1 },
    // { text: 'Stock Minimum Qty ', rowspan: 1, colspan: 1 },
    // { text: 'Stock Maximum Qty', rowspan: 1, colspan: 1 },
    // { text: 'Stock Closing Qty', rowspan: 1, colspan: 1 },
    // { text: 'Location', rowspan: 1, colspan: 1 },
    // { text: 'ACTION', rowspan: 1, colspan: 1 },
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'SPARE PART NAME', rowspan: 1, colspan: 1 },
    { text: 'SPARE PART DESCRIPTION', rowspan: 1, colspan: 1 },
    { text: 'USE AREA', rowspan: 1, colspan: 1 },
    { text: 'Function', rowspan: 1, colspan: 1 },
    // { text: 'Stock Opening Qty', rowspan: 1, colspan: 1 },
    { text: 'MIN. QTY.', rowspan: 1, colspan: 1 },
    { text: 'MAX. QTY.', rowspan: 1, colspan: 1 },
    { text: 'Stock QTY.', rowspan: 1, colspan: 1 },
    { text: 'UOM', rowspan: 1, colspan: 1 },
    { text: 'Lead Time', rowspan: 1, colspan: 1 },
    { text: '1st WEEK', rowspan: 1, colspan: 1 },
    { text: '2nd WEEK', rowspan: 1, colspan: 1 },
    { text: '3rd WEEK', rowspan: 1, colspan: 1 },
    { text: '4th WEEK', rowspan: 1, colspan: 1 },
  ],
];

// const filterOptions = [
//   { value: 'createdAt', name: 'Most Recent' },
//   { value: 'spare_part_desc', name: 'A-Z' },
// ];

const ViewSparePartList = ({
  moduleId,
}: //   closeModal,
{
  moduleId?: any;
  //   closeModal?: any;
}) => {
  const [partData, setPartData] = useState<IPart[]>([]);

  const navigate = useNavigate();

  const fetchPartData = () => {
    if (!moduleId) return;
    axios
      .get(`/api/machineRoutes/sparePartsForMachine/${moduleId}`)
      .then((res) => {
        setPartData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, []);
  return (
    <>
      {/* <StateCustomTable
        filterOptions={filterOptions}
        {...{ search, setSearch, filter, setFilter }}
        label="Search By ERP And Spare Part "
      > */}
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {partData &&
            partData.map((item: IPart, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.erp_code}</TableCell>
                  <TableCell align="center">{item.spare_part_desc}</TableCell>
                  <TableCell align="center">{item.used_for_machine}</TableCell>
                  <TableCell align="center">{item.function}</TableCell>
                  {/* <TableCell align="center">{item.opening_stock_qty}</TableCell> */}
                  <TableCell align="center">{item.min_stock_qty}</TableCell>
                  <TableCell align="center">{item.max_stock_qty}</TableCell>
                  <TableCell align="center">{item.closing_stock_qty}</TableCell>
                  <TableCell align="center">{item.opening_stock_UOM}</TableCell>
                  <TableCell align="center">{item.lead_time}</TableCell>
                  <TableCell align="center">{item.first_week}</TableCell>
                  <TableCell align="center">{item.second_week}</TableCell>
                  <TableCell align="center">{item.third_week}</TableCell>
                  <TableCell align="center">{item.fourth_week}</TableCell>
                  {/* <TableCell align="center">{item.location}</TableCell> */}
                  {/* <TableCell align="center">
                    {sparePartIds && handleUpdateSparePart && (
                      <Checkbox
                        checked={sparePartIds.includes(item._id || '')}
                        onChange={() => handleUpdateSparePart(item._id || '')}
                      />
                    )}
                  </TableCell> */}
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      {/* <CustomPagination totalPage={totalPages} /> */}
      {/* </StateCustomTable> */}
    </>
  );
};

export default ViewSparePartList;
