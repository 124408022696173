export const tableHeaderObj = [
  [
    { text: '#', rowspan: 2, colspan: 1, style: { border: '1px solid black' } },
    {
      text: 'Capacity (In Kgs.)',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Location',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'code no.',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },

    {
      text: 'Refilling Date',
      rowspan: 1,
      colspan: 2,
      style: { border: '1px solid black' },
    },
    {
      text: 'Refilling Agency',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Remark',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Checklist',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Action',
      rowspan: 2,
      colspan: 1,
      style: { border: '1px solid black' },
    },
  ],
  [
    {
      text: 'Done',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
    {
      text: 'Due',
      rowspan: 1,
      colspan: 1,
      style: { border: '1px solid black' },
    },
  ],
];
