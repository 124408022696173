import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Box, Checkbox, Divider, Stack } from '@mui/material';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import { TableInput } from '../../../../../components/common';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'S.N.',
  'Part Name',
  'Model',
  'Customer Name',
  'S. No.',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  'Total parts',
  'Total Ng qty',
  'Total Ok qty',
  'Remarks',
  'Delete',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={28}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['F-HR-17', '03', '01.11.2019']}
        heading="POISON TEST RECORD SHEET"
      />
      <tr>
        <th align="left" colSpan={10}>
          Visual inspector Name:
          <TableInput
            isView={isView}
            name="name"
            type="text"
            value={moduleData.name}
            onChange={(e) => {
              moduleData.name = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={18}>
          Date :
          {isView ? (
            formatDate(moduleData.moduleData_Date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_Date)}
              onChange={(date) => {
                moduleData.moduleData_Date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} rowSpan={index <= 3 || index >= 23 ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        <th>Defect details</th>
        {moduleData?.defect_details?.map(
          (_: any, defect_details_index: any) => {
            return (
              <th>
                {' '}
                <TableInput
                  isView={isView}
                  name={`defect_details[${defect_details_index}]`}
                  type="text"
                  value={moduleData?.defect_details?.[defect_details_index]}
                  onChange={(e) => {
                    moduleData.defect_details[defect_details_index] =
                      e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
            );
          }
        )}
      </tr>
    </thead>
  );
};

export default THead;
