import { IconButton, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { CgProfile } from 'react-icons/cg';
import { FaFilePdf } from 'react-icons/fa6';
import { SiMicrosoftexcel } from 'react-icons/si';
import {
  ViewIconButton,
  CustomIconButton,
  EditIconButton,
  DeleteIconButton,
} from '../../../components/common/button/CustomIconButton';
import CustomUploadIcon from '../../../components/common/button/CustomUploadIcon';
import formatDate from '../../../components/common/formatDate';
import {
  StyledTableRow,
  StyledTableCell,
  CircleIndicator,
} from '../helpers/module.styled';
import { FaFileAlt } from 'react-icons/fa';
import { TbHandClick } from 'react-icons/tb';
import { GrWorkshop } from 'react-icons/gr';
import { GrUserWorker } from 'react-icons/gr';

const testLevels = {
  level_1: 'L1_test',
  level_2: 'L2_test',
};

const ListRows = ({
  item,
  index,
  handleDownloadDocs,
  perPage,
  page,
  idRef,
  setDocFiles,
  setIsOpenFiles,
  setIsProfileModal,
  setModuleId,
  setIsModal,
  handleDelete,
  handleNavigateTest,
  setIsOpenTniModal,
  setDepartmentId,
  handleNavigateTniTopic,
  handleNavigatOJT,
}: any) => {
  return (
    <React.Fragment key={item._id}>
      <StyledTableRow index={index}>
        <StyledTableCell align="center">
          {perPage * (+page - 1) + index + 1}
        </StyledTableCell>
        <StyledTableCell align="center">{item?.employee_name}</StyledTableCell>
        <StyledTableCell align="center">{item?.designation}</StyledTableCell>
        <StyledTableCell align="center">
          {typeof item?.department_id === 'object' &&
            item?.department_id?.department_name}
        </StyledTableCell>
        <StyledTableCell align="center">{item?.employee_type}</StyledTableCell>
        <StyledTableCell align="center">
          {formatDate(item?.doj)}
        </StyledTableCell>
        <StyledTableCell align="center">{item?.qualification}</StyledTableCell>
        <StyledTableCell align="center">
          {item?.work_experience}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            spacing={0.5}
            sx={{ justifyContent: 'center' }}
          >
            <Tooltip title="Download Pdf">
              <IconButton
                color={'primary'}
                size={'small'}
                onClick={() => {
                  handleDownloadDocs(
                    item?.employee_type,
                    'pdf',
                    item?.employee_name,
                    item?.department_id?.department_name,
                    formatDate(item?.doj),
                    item?.designation,
                    item?.qualification
                  );
                }}
              >
                <FaFilePdf />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Download Excel">
              <IconButton
                size={'small'}
                onClick={() => {
                  handleDownloadDocs(item?.employee_type, 'xlsx');
                }}
              >
                <SiMicrosoftexcel style={{ color: '#099268' }} />
              </IconButton>
            </Tooltip> */}
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <CustomUploadIcon
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                id={`${index}_files_`}
                multiple={true}
                onChange={(e) => {}}
              />
              {item.files?.length > 0 ? (
                <>
                  <ViewIconButton
                    sx={{ m: 0 }}
                    tooltipTitle="view"
                    onClick={() => {
                      idRef.current = item?._id;
                      setDocFiles(item.files);
                      setIsOpenFiles(true);
                    }}
                  />
                </>
              ) : (
                <ViewIconButton
                  sx={{
                    opacity: '0',
                    PointerEvent: 'none',
                    visibility: 'hidden',
                    m: 0,
                  }}
                  tooltipTitle=""
                />
              )}
            </Stack>
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            gap={0.5}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <CustomIconButton
              sx={{ p: 0, m: 1 }}
              tooltipTitle="L1 TEST"
              onClick={() => {
                handleNavigateTest(item, testLevels.level_1);
              }}
            >
              <FaFileAlt style={{ color: '#15967d' }} />
            </CustomIconButton>

            <CircleIndicator width="20px" height="20px">
              {item?.l1_test_count}
            </CircleIndicator>
          </Stack>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Stack
            direction="row"
            gap={0.5}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <CustomIconButton
              sx={{ p: 0, m: 1 }}
              tooltipTitle="OJT SHEET"
              onClick={() => {
                handleNavigatOJT(
                  item?.ojt_id,
                  item?._id,
                  item?.department_id?.department_name,
                  false
                );
              }}
            >
              <GrUserWorker />
            </CustomIconButton>

            {item.ojt_id ? (
              <CircleIndicator width="15px" height="15px"></CircleIndicator>
            ) : (
              <CircleIndicator
                width="15px"
                height="15px"
                opacity="0"
              ></CircleIndicator>
            )}
          </Stack>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Stack
            direction="row"
            gap={0.5}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <CustomIconButton
              sx={{ p: 0, m: 1 }}
              tooltipTitle="L2 TEST"
              onClick={() => {
                handleNavigateTest(item, testLevels.level_2);
              }}
            >
              <FaFileAlt style={{ color: '#15967d' }} />
            </CustomIconButton>
            <CircleIndicator width="20px" height="20px">
              {item?.l2_test_count}
            </CircleIndicator>
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="center">{item?.skill_level}</StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            gap={0.5}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            {item?.emp_tni_topic_id ? (
              <CustomIconButton
                sx={{ p: 0, m: 1 }}
                tooltipTitle="TOPIC SHEET"
                onClick={() => {
                  handleNavigateTniTopic(
                    item?.emp_tni_topic_id,
                    item?._id,
                    false
                  );
                }}
              >
                <GrWorkshop style={{}} />
              </CustomIconButton>
            ) : (
              <CustomIconButton
                sx={{ p: 0, m: 1 }}
                tooltipTitle="pic topic"
                onClick={() => {
                  setModuleId(item?._id || '');
                  setDepartmentId(item?.department_id?._id);
                  setIsOpenTniModal(true);
                }}
              >
                <TbHandClick style={{ color: '#087f5b' }} />
              </CustomIconButton>
            )}

            {item.emp_tni_topic_id ? (
              <CircleIndicator width="15px" height="15px"></CircleIndicator>
            ) : (
              <CircleIndicator
                width="15px"
                height="15px"
                opacity="0"
              ></CircleIndicator>
            )}
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="center">{}</StyledTableCell>
        <StyledTableCell align="center">{}</StyledTableCell>
        <StyledTableCell align="center">{}</StyledTableCell>
        <StyledTableCell align="center">{}</StyledTableCell>
        <StyledTableCell align="center">{item.status}</StyledTableCell>
        <StyledTableCell>
          <Stack
            direction="row"
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <CustomIconButton
              sx={{ p: 0, m: 1 }}
              tooltipTitle="VIEW PROFILE"
              onClick={() => {
                setIsProfileModal(true);
                idRef.current = item?._id;
              }}
            >
              <CgProfile style={{ color: '#343a40', fontSize: '2rem' }} />
            </CustomIconButton>
            <EditIconButton
              tooltipTitle="EDIT"
              onClick={() => {
                setModuleId(item?._id || '');
                setIsModal(true);
              }}
            />
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(item?._id || '');
              }}
            />
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default ListRows;
