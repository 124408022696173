const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    {
      text: 'S.No.',
      style: { ...style1, minWidth: '90px', width: '80px' },
      rowspan: 2,
    },
    { text: 'POINTS TO BE VERIFIED', colspan: 3, style: style1, rowspan: 2 },
    { text: 'Obtained Marks', style: style1, rowspan: 1 },
    { text: ' Max Marks', style: style1, rowspan: 1 },
  ],
  [
    { text: '', style: { height: '30px', backgroundColor: '#f8f9fa' } },
    { text: '', style: { height: '30px', backgroundColor: '#f8f9fa' } },
  ],
];

export const listConstant = {
  childHeader: 'Given test list',
  addButtonLable: 'Give Test',
};

export const headerConstant = {
  tableCompHeadTitle: 'SKILL EVALUATION SHEET',
};

export const apiConstant = {
  get: 'employeeSkillEvaluationRoute',
  getById: 'employeeSkillEvaluationRoute',
  post: 'employeeSkillEvaluationRoute',
  update: 'employeeSkillEvaluationRoute',
  delete: 'employeeSkillEvaluationRoute',
  departmentAutoComplete: 'department',
  masterlistSkillEvaluationList: 'masterSkillEvaluationRoute',
  masterSkillSheetAutoComplete:
    'employeeSkillEvaluationRoute/masterSkillSheetAutoComplete',
  getMasterSkillSheetById: 'masterSkillEvaluationRoute',
};

export const tilesConstant = {
  childHeaderText: 'SKILL EVALUATION SHEET',
};

export const navigationConstant = {
  skillEvaluationTestSheetRouter: '/common/employeeSkillEvaluationSheetCreate',
};
