export const moduleConstant = {
  crudApi: '/api/fireSafetyRoute',
  childHeader: 'List Of Fire Safety Equipment',
  btnName: 'New Fire Safety',
  searchLabel: 'Equipment / Location',
  moduleName: 'Fire Safety Equipment',
};

export const tableHeaderObj = [
  [
    { text: '#', style: { border: '1px solid black' } },
    { text: 'Equipment Name', style: { border: '1px solid black' } },
    {
      text: 'Quantity',
      style: { border: '1px solid black' },
    },
    {
      text: 'Location',
      style: { border: '1px solid black' },
    },
    {
      text: 'Remarks',
      style: { border: '1px solid black' },
    },
    {
      text: 'Action',
      style: { border: '1px solid black' },
    },
  ],
];
