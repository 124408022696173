import { useState } from 'react';
import { TableInput } from '../../../components/common';
import SimpleSelectRevised from '../../../components/common/SimpleSelectRevised';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
  moduleData: any;
}
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: 'rgb(255, 236, 153)',
  color: 'black',
} as const;

const TBodyRow = ({
  moduleData,
  item,
  index,
  isView,
  handleDelete,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  return (
    <>
      <tr>
        {item.time && (
          <td
            style={{
              minWidth: '200px',
              textAlign: 'center',
              fontWeight: '600',
              ...stickStylesColumn,
              left: 0,
            }}
            rowSpan={item.time && 5}
          >
            {item.time}
          </td>
        )}
        <td style={{
          minWidth: '200px', textAlign: 'center',
          ...stickStylesColumn,
          left: 200,
        }}>{item.desc}</td>
        {Array.from({ length: 31 }, (_, indx) =>
          item.data_type === 'footer' ? (
            <td
              key={index + 2.2 + indx + 'after' + 111.33}
              style={{
                minWidth: '100px',
              }}
            >
              <TableInput
                isView={isView}
                name="verified_by"
                disabled={!moduleData.machine_id}
                type="text"
                value={item.shift_A_results[indx]}
                onChange={(e) => {
                  item.shift_A_results[indx] = e.target.value;
                  setRowRender((prev) => !prev);
                }}
              />
            </td>
          ) : (
            <td
              style={{
                minWidth: '100px',
              }}
              key={index + 2.2 + indx + 'after' + 111.33}
            >
              {moduleData.machine_id && (
                <SimpleSelectRevised
                  name={`shift_A_results[${indx}]`}
                  value={item.shift_A_results[indx]}
                  onChange={(e: any) => {
                    item.shift_A_results[indx] = e.target.value;
                    setRowRender((prev) => !prev);
                  }}
                  options={[
                    { value: '', label: 'none' },
                    { value: 'true', label: '✓' },
                    { value: 'false', label: 'x' },
                  ]}
                />
              )}
            </td>
          ),
        )}

        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            disabled={!moduleData.machine_id}
            isView={isView}
            name="remarks_1"
            type="text"
            value={item.remarks_1}
            onChange={(e) => {
              // handleChangeModuleData(e, item);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default TBodyRow;
