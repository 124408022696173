import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  
  {
    id:1,
    desc: 'General Particular',
    freq: 'Date Of Trial',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },

  {
    id:2,
    desc: '',
    freq: 'Machine Tonnage',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  {
    id:3,
    desc: '',
    freq: 'Material',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  {
    id:4,
    desc: '',
    freq: 'Qty OK shot',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:5,
    desc: 'Die Check List',
    freq: 'Sprue Bush and diffuser Polishing and matching (OK/NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:6,
    desc: '',
    freq: 'Guide Piller & Bush',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:7,
    desc: '',
    freq: 'Ejector Pin Height  (OK/NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:8,
    desc: '',
    freq: 'Sleeve Size',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:9,
    desc: '',
    freq: 'Die Run on Bumper/ Ejector',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:10,
    desc: '',
    freq: 'Over Flow/Vent (OK/NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:11,
    desc: 'PDC Parameters',
    freq: 'Low Speed',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:12,
    desc: '',
    freq: 'High Speed',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:13,
    desc: '',
    freq: 'Low Speed Stroke Length',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:14,
    desc: '',
    freq: 'Intensifying Position',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:15,
    desc: '',
    freq: 'Die Cooling Time',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:16,
    desc: '',
    freq: 'Water Spray',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:17,
    desc: '',
    freq: 'Intensifying Speed',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:18,
    desc: '',
    freq: 'High Speed Acc.Pressure',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:19,
    desc: '',
    freq: 'Intensifying Acc.Pressure',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:20,
    desc: '',
    freq: 'Biscuit Thickness',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:21,
    desc: '',
    freq: 'Metal Temperature',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:22,
    desc: '',
    freq: 'Oil Temperature',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:23,
    desc: '',
    freq: 'Intensifier Holding Time',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:24,
    desc: '',
    freq: 'Air blow Time',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:25,
    desc: '',
    freq: 'Shot Delay Time',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:26,
    desc: '',
    freq: 'Die Temprature',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:27,
    desc: '',
    freq: 'Cooling for die(OK/NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:28,
    desc: '',
    freq: 'Cooling for Plunger(OK/NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:29,
    desc: '',
    freq: 'Ejection Rod Length/ Bumper (mm)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:30,
    desc: '',
    freq: 'Degassing',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:31,
    desc: '',
    freq: 'IP cylinder (Kg/cm2)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:32,
    desc: '',
    freq: 'Nitrogen gas accumulator cylinder(Kg/cm2)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:33,
    desc: '',
    freq: 'Plunger Lubrication',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:34,
    desc: '',
    freq: 'Visual Defects:',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:35,
    desc: '',
    freq: 'Total Shot taken',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:36,
    desc: '',
    freq: 'Total OK Shots',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:37,
    desc: '',
    freq: 'Weight of casting/weight of shot',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:38,
    desc: '',
    freq: 'Trial run in auto or manual mode.',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:39,
    desc: '',
    freq: 'Proper Casting Ejection (OK/ NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:40,
    desc: '',
    freq: 'Overflow/ Chillvent comming with shot (OK/ NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:41,
    desc: '',
    freq: 'Gate chip off (OK/ NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:42,
    desc: '',
    freq: 'Non filling (OK/ NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:43,
    desc: '',
    freq: 'Catching (OK/ NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:44,
    desc: '',
    freq: 'Soldering (OK/ NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:45,
    desc: '',
    freq: 'Blow hole (OK/ NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:46,
    desc: '',
    freq: 'Die flash (OK/ NG)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
  
  {
    id:47,
    desc: '',
    freq: 'Cycle time  (Sec)',
    shift_A_results: Array(8).fill(''),
    remarks: '',
    data_type:'footer'
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyBin_date: moment(),
  machine_id:null,
  processes: lodash.cloneDeep(processes),
};
