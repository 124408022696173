import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { Grid, SelectChangeEvent } from '@mui/material';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import { Checkbox } from '@mui/material';
import { StyledNotesField } from '../../../components/common/FormControlMui';
import DescriptionTextfield from '../../../components/common/DescriptionNotes';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
  });
  const [files, setFiles] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier Complaint' : 'Create Supplier Complaint'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [defectData, setDefectData] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/supplierComplaintRoutes/getById/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/supplierDefectRoute/forAutoComplete')
      .then((res) => {
        setDefectData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.customer_id) {
      alert('Please enter Customer name');
      return;
    }
    if (!data.defect_id) {
      alert('Please enter Defect name');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);

    if (id) {
      await axios
        .put(`/api/supplierComplaintRoutes/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/supplierComplaintRoutes', data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <AutocompleteGridmui
        id={'customer_name'}
        label={'Customer'}
        option_name={'customer_name'}
        arrayofObj={customerData}
        value={data.customer_id || ''}
        onChange={(e, value) => {
          data.customer_id = value;
          setRender((prev) => !prev);
        }}
      />
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part No.'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_number
                  : data?.partNumber || ''}
              </p>
            </Grid>

            {data.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Name'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_name
                  : data?.partName || ''}
              </p>
            </Grid>
          </Grid>
        </div>
      </DivStyled>
      <GridInputs
        id={'lot_size'}
        name={'lot_size'}
        html_for={'lot_size'}
        label_name={'LOT SIZE'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.lot_size || ''}
        onChange={handleCustomerData}
      />
      <AutocompleteGridmui
        id={'defect_name'}
        label={'PROBLEM DESCRIPTION'}
        option_name={'defect_name'}
        arrayofObj={defectData}
        value={data.defect_id || ''}
        onChange={(e, value) => {
          data.defect_id = value;
          setRender((prev) => !prev);
        }}
      />
      <StyledNotesField>
        <DescriptionTextfield
          name="action_taken"
          onChange={handleCustomerData}
          value={data?.action_taken || ''}
          label={'ACTION TAKEN :'}
        />
      </StyledNotesField>
      <GridInputs
        id={'capa_no'}
        name={'capa_no'}
        html_for={'capa_no'}
        label_name={'CAPA NO.'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.capa_no || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="DATE OF CLOSURE"
        html_for={'actual_closure_date'}
        value={data?.actual_closure_date || null}
        onChange={(date) => {
          data.actual_closure_date = date;
        }}
      />

      <GridInputs
        id={'sign'}
        name={'sign'}
        html_for={'sign'}
        label_name={'SIGN.'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.sign || ''}
        onChange={handleCustomerData}
      />
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART LIST"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
