import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CloseButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import formatDate from '../../../components/common/formatDate';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
// import TController from './components/create/TController';
// import useGetData from './helpers/useGetData';
import useConfirm from '../../../components/common/useConfirm';
import TController from './components/create/TController';
// import HeaderInputs from './../../components/create/HeaderInputs';
// import ModuleBody from './components/create/ModuleBody';
interface MyData {
  moduleData: any; // replace PirType with the type of your `pir` data
  isLoading: boolean;
  error?: string | null;
}
const Create = () => {
  const { id } = useParams();
  // const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [moduleData, setModuleData] = useState<any>({ levels: [] });
  const [render, setRender] = useState(false);

  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );

  const [DialogUpdate, confirmUpdate] = useConfirm(
    'SAVE',
    'Are you sure you want to save?'
  );
  const [DialogDeleteProcess, confirmDeleteProcess] = useConfirm(
    'DELETE QUESTION',
    'Are you sure you want to delete this Question?'
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/skillEvaluation/getSkillEvaluation/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const onSubmit = async (bool: Boolean) => {
    let ans = await confirmUpdate();
    if (!ans) return;
    axios
      .put(`/api/skillEvaluation/updateSkillEvaluation/${id}`, moduleData)
      .then((res) => {
        window.alert('success');
      })
      .then(() => {
        if (bool) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    moduleData.levels[index][name] = value;
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="SKILL REQUIREMENT SHEET">
          {!isView && (
            <>
              <SaveButton
                label="Save & Continue"
                onClick={() => onSubmit(false)}
              />
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (!ans) return;
                  navigate(-1);
                }}
                label="Close"
              />
            </>
          )}
        </ChildHeader>
        <TController {...{ moduleData, isView, handleChange }} />
      </Box>
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
      <DialogDeleteProcess isSubmitContent={false} />
    </Box>
  );
};

export default Create;
