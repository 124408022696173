import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { moduleConstant } from '../../helpers/constant';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  const yearRange = getYearRange(new Date(moduleData?.date_range));
  return (
    <thead>
      <TableCompHead
        colSpan={21}
        rowSpan={3}
        labels={['DOC.NO.:-', 'REV. NO.:-', ' REV. DATE:-']}
        values={['F-PUR-03', '03', '01.01.2020']}
        heading={`${moduleConstant.tableHeader} (YEAR : ${yearRange})`}
      />
      <tr style={{ minHeight: '180px', height: '180px' }}>
        {[
          'S.N.',
          'MONTH',
          'Supplier Name',
          'Material Name',
          'Qty Orderd (NOS.)',
          'Expected Delievry Date [M/D/Y]',
          'Actual Material Received Date [M/D/Y]',
          'Material Delievery Delay  (In Days) ',
          'Invoice No',
          'Qty Received (NOS.)',
          'Qty Rejected',
          'Ok Qty (NOS.)',
          'Material Received in no of supply',
          'Quality Rating',
          'Delievery Rating',
          'Premium Freight',
          'Field Failure',
          'Line stoppages/customer disruptions',
          'Overall Rating (%)',
          'RATING',
          'REMARKS',
        ].map((heading: string, index: number) => (
          <th key={`heading` + index} style={{ transform: 'rotate(270deg)' }}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
