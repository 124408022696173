import { TableCompHead } from '../../../../../components/ui';
import { moduleConstant } from '../../helpers/constant';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={3}
        labels={['Doc', 'Rev No', 'Rev Date']}
        values={['F-MNT-12 AE', '01', '01-01-2019']}
        heading={moduleConstant?.checklist?.childHeader}
      />

      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.No.',
          'Capacity No. (In Kgs.)',
          'Month & Year',
          'Check Point',
          'Last Filling Date',
          'Location',
          'Pressure',
          'Seal Condition',
          'Pipe Condition',
          'Action',
        ].map((head) => (
          <th style={{ textTransform: 'uppercase' }}>{head}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
