import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import { spclChar } from '../../../cp/helpers/SpecialCharacters';

interface IProps {
  moduleData: any;
}
const colSpanBodyHeader = (head: string) => {
  if (head === 'Charecteristics / Parameters') return 3;
  if (head === 'Methods') return 6;
  return 1;
};
const TBody = ({ moduleData }: IProps) => {
  const [render, setRender] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;

  return (
    <tbody>
      <tr>
        {[
          'Part/ Process Number',
          'Process Name/ Operation Description',
          'Machine,  Device, Jigs, Tools for Mfg.',
          'Charecteristics / Parameters',
          'Spl. Char. Class',
          'Methods',
          'Resp.',
          'Reaction Plan',
          'Corrective Action',
          'Process Capability',
          'Observation',
          'Remarks',
        ].map((head, index) => (
          <th
            colSpan={colSpanBodyHeader(head)}
            rowSpan={index === 3 || index === 5 ? 1 : 3}
            key={index + head}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        {[
          'No.',
          'Product',
          'Process',
          'Product/Process Specification/ Tolerance.',
          'Evalution Mesurements Techniques',
          'Error Proofing',
          'Sample',
          'Current Process Controls',
        ].map((head, index) => (
          <th
            colSpan={index === 6 ? 2 : 1}
            rowSpan={index === 6 ? 1 : 2}
            key={index + head}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        <th colSpan={1}>Size</th>
        <th colSpan={1}>Freq</th>
      </tr>
      {moduleData.processes &&
        moduleData.processes.map((process: any, index: number) => (
          <React.Fragment key={index}>
            {process.specs.map((spec: any, indx: number) => (
              <React.Fragment key={indx}>
                <tr>
                  {indx === 0 && (
                    <>
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_no}
                      </td>
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_name}
                      </td>
                    </>
                  )}
                  <td
                    align="center"
                    style={{ border: '1px solid black' }}
                    rowSpan={(spec?.machine_rowSpan as number) || 1}
                  >
                    {spec?.machine}
                    {spec?.machine && spec?.tool_no ? '/' : ''}
                    {spec?.tool_no}
                  </td>

                  <td align="center" style={{ border: '1px solid black' }}>
                    {indx + 1}
                  </td>

                  {/* product or process */}
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.char_product_rowSpan as number) || 1}
                  >
                    {spec?.char_product}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.char_process_rowSpan as number) || 1}
                  >
                    {spec?.char_process}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                  >
                    {spec.special_character && spclChar[spec.special_character]}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                  >
                    {spec.control_product}
                    {spec.control_process && spec.control_product ? '/' : ''}
                    {spec.control_process}
                    {spec.control_product_remark &&
                      `* ${spec.control_product_remark}`}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    align="center"
                  >
                    {spec.methods_measure}
                  </td>

                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.error_proofing_rowSpan as number) || 1}
                  >
                    {spec.error_proofing}
                  </td>

                  {/* Sample Size	 */}
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.methods_size_rowSpan as number) || 1}
                  >
                    {spec?.methods_size}
                  </td>

                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.methods_by_rowSpan as number) || 1}
                  >
                    {spec?.methods_by}
                  </td>

                  {/* CONTROL METHOD	 */}
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.methods_process_rowSpan as number) || 1}
                  >
                    {spec?.methods_process}
                  </td>

                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.responsibility_rowSpan as number) || 1}
                  >
                    {spec?.responsibility}
                  </td>

                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.reaction_plan_rowSpan as number) || 1}
                  >
                    {spec?.reaction_plan}
                  </td>

                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.corrective_action_rowSpan as number) || 1}
                  >
                    {spec.corrective_action}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    rowSpan={(spec?.process_capability_rowSpan as number) || 1}
                  >
                    {spec.process_capability}
                  </td>
                  <td>
                    <TableInput
                      isView={isView}
                      name="observation"
                      type="text"
                      value={spec.observation}
                      onChange={(e: any) => {
                        spec.observation = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td>
                    <TableInput
                      isView={isView}
                      name="remarks"
                      type="text"
                      value={spec.remarks || ''}
                      onChange={(e: any) => {
                        spec.remarks = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
    </tbody>
  );
};

export default TBody;
