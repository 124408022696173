import { Button, Paper, Tab, Tabs, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  SaveButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { useAuth } from '../../services/auth/AuthProvider';
import TController from './components/view/TController';
import { mergerRowsControlPlan } from './helpers/merger';
import { AiOutlinePrinter } from 'react-icons/ai';
import FlexBox from '../../components/common/FlexBox';
interface MyData {
  moduleData: any; // replace PdirType with the type of your `pdir` data
  isLoading: boolean;
  error?: string | null;
}
interface Iprops {
  hideHeader?: boolean;
}
const View = () => {
  const { id, levelType } = useParams();
  const [moduleData, setModuleData] = useState<any>();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/skillEvaluationQuestionPaper/getOneQuestionPaper/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  function printTable() {
    var divToPrint: any = document.getElementById('table-to-xls');
    let newWin: any = window.open('');
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <Box>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          textColor="secondary"
        >
          {moduleData &&
            moduleData.levels.map((level: any) => (
              <Tab label={level.levels_type.split('_').join(' ')} />
            ))}
        </Tabs>
      </Paper>
      <FlexBox
        style={{
          position: 'absolute',
          top: '0.2rem',
          right: '1rem',
          zIndex: '100000',
        }}
      >
        <Tooltip arrow title="Print table">
          <AiOutlinePrinter
            style={{
              color: 'white',
              cursor: 'pointer',
              fontSize: '1.9rem',
              marginRight: '0.6rem',
            }}
            onClick={printTable}
          />
        </Tooltip>
      </FlexBox>

      <TController {...{ moduleData, tabValue }} />
    </Box>
  );
};

export default View;
