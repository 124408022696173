import { HiDocumentReport } from 'react-icons/hi';
import { CgCalendarToday } from 'react-icons/cg';
import { RenderTiles } from '../../components/common';
import { MdSummarize, MdSupportAgent } from 'react-icons/md';
import { FaTruckArrowRight } from 'react-icons/fa6';
import { useAuth } from '../../services/auth/AuthProvider';
import { FaChartLine, FaBuildingCircleCheck } from 'react-icons/fa6';
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
import { GrHostMaintenance } from 'react-icons/gr';
import { SlNotebook } from 'react-icons/sl';
import { IoFileTrayFull } from 'react-icons/io5';
import { SiGotomeeting } from "react-icons/si";

const adminTilesObj = [
  {
    sNo: 1,
    link: '/admin/clause1',
    isLink: true,
    clauseNo: 1,
    p: 'PRODUCTION PREPARATION /',
    span: 'NEW PRODUCT DEVELOPEMENT',
    isIcon: false,
  },
  {
    sNo: 3,
    link: '/common/clause3',
    isLink: true,
    clauseNo: 3,
    p: 'CHANGING',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    sNo: 4,
    link: '/admin/clause4',
    isLink: true,
    clauseNo: 4,
    p: 'STANDARDS',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    sNo: 5,
    link: '/admin/clause5',
    isLink: true,
    clauseNo: 5,
    p: 'EDUCATION AND',
    span: 'TRAINING',
    isIcon: false,
  },
  {
    sNo: 5,
    link: '/common/clause_5',
    isLink: true,
    clauseNo: 5,
    p: 'EDUCATION AND',
    span: 'TRAINING NEW',
    isIcon: false,
  },
  {
    sNo: 6,
    link: '/admin/clause6',
    isLink: true,
    clauseNo: 6,
    p: 'QUALITY AUDIT AND',
    span: 'PROCESS VERIFICATION',
    isIcon: false,
  },
  {
    sNo: 7,
    link: '/admin/clause7',
    isLink: true,
    clauseNo: 7,
    p: 'Supplier',
    span: 'Control',
    isIcon: false,
  },
  {
    sNo: 8,
    link: '/common/clause8',
    isLink: true,
    clauseNo: 8,
    p: 'HANDLING ABNORMILITY',
    span: 'IN QUALITY',
    isIcon: false,
  },
  {
    sNo: 8,
    link: '/admin/clause9',
    isLink: true,
    clauseNo: 9,
    p: '5S',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    sNo: 10,
    link: '/common/clause10',
    isLink: true,
    clauseNo: 10,
    p: 'EQUIPMENT / INSPECTION',
    span: "EQUIPMENT'S MANAGEMENT",
    isIcon: false,
  },
  {
    sNo: 102,
    link: '/admin/clause11',
    isLink: true,
    clauseNo: 11,
    p: 'IMPLEMENTATION OF',
    span: 'STANDARDS',
    isIcon: false,
  },
  {
    sNo: 22,
    link: '/admin/clause12',
    isLink: true,
    clauseNo: 12,
    p: 'PRODUCTS',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    sNo: 11,
    link: '/common/clause15',
    isLink: true,
    clauseNo: 15,
    p: 'ADEQUATE TESTING',
    span: 'FACILITY',
    isIcon: false,
  },
  {
    sNo: 117,
    link: '/admin/clause17',
    isLink: true,
    clauseNo: 17,
    p: 'SCALE AND TOP',
    span: 'MANAGEMENT BANDWIDTH',
    isIcon: false,
  },
  {
    sNo: 12,
    link: '/common/clause18',
    isLink: true,
    clauseNo: 18,
    p: 'SAFETY',
    span: '',
    isIcon: false,
  },
  {
    sNo: 13,
    link: '/common/clause19',
    isLink: true,
    clauseNo: 19,
    p: 'LEGAL COMPLIANCE',
    span: 'AND ENVIRONMENT',
    isIcon: false,
  },
  {
    sNo: 2,
    link: '/common/production',
    isLink: true,
    icon: <CgCalendarToday size="5rem" color="#003566" />,
    p: 'PRODUCTION',
    span: 'MANAGEMENT',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/riDepartment',
    isLink: true,
    icon: <FaTruckArrowRight size="5rem" color="#343a40" />,
    p: 'RI',
    span: 'Department',
    isIcon: true,
  },
  {
    sNo: 14,
    link: '/common/organization_chart_listOfProcedures/?documentType=organization_chart&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <FaChartLine size="5rem" color="#343a40" />,
    p: 'Organization Chart',
    span: '',
    isIcon: true,
  },
  {
    sNo: 15,
    link: '/common/company_roles_responsibilities_listOfProcedures/?documentType=company_roles_responsibilities&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <FaBuildingCircleCheck size="5rem" color="#343a40" />,
    p: 'Company Roles & Responsibilities',
    span: '',
    isIcon: true,
  },

  {
    sNo: 4,
    link: '/common/hpdcChecksheets',
    isLink: true,
    icon: <HiOutlineClipboardDocumentCheck size="5rem" color="#a12222" />,
    p: 'HPDC',
    span: 'CHECKSHEETS',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/createDegassingCheckSheet',
    isLink: true,
    icon: <GrHostMaintenance size="5rem" color="#003566" />,
    p: 'Degassing Frequency &',
    span: ' Temperature Monitering',
    isIcon: true,
  },
  {
    sNo: 20,
    link: '/common/qualityDepartmentHome',
    isLink: true,
    icon: <SlNotebook size="5rem" color="#a12222" />,
    p: 'QUALITY',
    span: 'DEPARTMENT',
    isIcon: true,
  },
  {
    sNo: 21,
    link: '/common/home/masterList',
    isLink: true,
    icon: <IoFileTrayFull size="5rem" color="#1864ab" />,
    p: 'Master',
    span: 'List',
    isIcon: true,
  },
  {
    sNo: 9,
    link: `/common/mushinHelpDesk`,
    isLink: true,
    icon: <MdSupportAgent size="5rem" color="#1864ab" />,
    p: 'MUSHIN HELP',
    span: 'DESK',
    isIcon: true,
  },
  {
    sNo: 26,
    link: `/common/mrm/list`,
    isLink: true,
    icon: <SiGotomeeting size="5rem" color="#94090B" />,
    p: 'MANAGEMENT REVIEW',
    span: 'MEETING',
    isIcon: true,
  },
];
const sagarClauses = [
  {
    sNo: 5,
    link: '/admin/clause5',
    isLink: true,
    clauseNo: 5,
    p: 'EDUCATION AND',
    span: 'TRAINING',
    isIcon: false,
  },
  {
    sNo: 12,
    link: '/common/clause18',
    isLink: true,
    clauseNo: 18,
    p: 'SAFETY',
    span: '',
    isIcon: false,
  },
];

const AdminHome = () => {
  const { state } = useAuth();
  return (
    <RenderTiles
      tilesObj={
        state.user?._id === '65bb6c370bf37e8c5c869445'
          ? sagarClauses
          : adminTilesObj
      }
      height=""
    />
  );
};

export default AdminHome;
