interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ preparedBy, approvedBy }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={8}>
          मेंटेनेंस करने वाले का नाम/sign :-
          {preparedBy}
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={8}>
          मेंटेनेंस चेक करने वाले का नाम/sign :-
          {approvedBy}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
