import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
interface IProps {
  moduleData: any;
  isView: boolean;
  documentType: any;
}
const TBody = ({ moduleData, isView, documentType }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.specs.map((spec: any, index: number) => (
        <>
          <tr>
            <td rowSpan={moduleData.cavity || 1}>{index + 1}</td>
            <td
              rowSpan={moduleData.cavity || 1}
              style={{ width: '200px', minWidth: '200px' }}
            >
              {spec.parameter}
            </td>
            <td
              rowSpan={moduleData.cavity || 1}
              style={{ width: '200px', minWidth: '200px' }}
            >
              {spec.specification}
            </td>
            <td
              rowSpan={moduleData.cavity || 1}
              style={{ width: '200px', minWidth: '200px' }}
            >
              {spec.methods}
            </td>
            <td
              rowSpan={moduleData.cavity || 1}
              style={{ width: '150px', minWidth: '150px' }}
            >
              <TableInput
                isView={isView}
                name="sample_size"
                type="number"
                value={spec.sample_size}
                onChange={(e) => {
                  spec.sample_size = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            {documentType === 'retro'
              ? Array.from({ length: 20 }, (_, obsIndex) => (
                  <td style={{ width: '150px', minWidth: '150px' }}>
                    <TableInput
                      isView={isView}
                      name="obs"
                      type="text"
                      value={spec.check_0_values[obsIndex] || ''}
                      onChange={(e) => {
                        spec.check_0_values[obsIndex] = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                ))
              : Array.from({ length: 10 }, (_, obsIndex) => (
                  <td style={{ width: '150px', minWidth: '150px' }}>
                    <TableInput
                      isView={isView}
                      name="obs"
                      type="text"
                      value={spec.check_0_values[obsIndex] || ''}
                      onChange={(e) => {
                        spec.check_0_values[obsIndex] = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                ))}
            <td style={{ width: '150px', minWidth: '150px' }}>
              <TableInput
                isView={isView}
                name="status"
                type="text"
                value={spec.status}
                onChange={(e) => {
                  spec.status = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td
              rowSpan={moduleData.cavity || 1}
              style={{ width: '150px', minWidth: '150px' }}
            >
              <TableInput
                isView={isView}
                name="verified_by"
                type="text"
                value={spec.verified_by}
                onChange={(e) => {
                  spec.verified_by = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td
              rowSpan={moduleData.cavity || 1}
              style={{ width: '150px', minWidth: '150px' }}
            >
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>

          {Array.from({ length: moduleData.cavity - 1 }, (_, obsNumber) => (
            <tr>
              {documentType === 'retro'
                ? Array.from({ length: 20 }, (_, obsIndex) => (
                    <td style={{ width: '150px', minWidth: '150px' }}>
                      <TableInput
                        isView={isView}
                        name="obs"
                        type="text"
                        value={
                          (spec[`check_${obsNumber + 1}_values`] &&
                            spec[`check_${obsNumber + 1}_values`][obsIndex]) ||
                          ''
                        }
                        onChange={(e) => {
                          if (!spec[`check_${obsNumber + 1}_values`])
                            spec[`check_${obsNumber + 1}_values`] = [];
                          spec[`check_${obsNumber + 1}_values`][obsIndex] =
                            e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                  ))
                : Array.from({ length: 10 }, (_, obsIndex) => (
                    <td style={{ width: '150px', minWidth: '150px' }}>
                      <TableInput
                        isView={isView}
                        name="obs"
                        type="text"
                        value={
                          (spec[`check_${obsNumber + 1}_values`] &&
                            spec[`check_${obsNumber + 1}_values`][obsIndex]) ||
                          ''
                        }
                        onChange={(e) => {
                          if (!spec[`check_${obsNumber + 1}_values`])
                            spec[`check_${obsNumber + 1}_values`] = [];
                          spec[`check_${obsNumber + 1}_values`][obsIndex] =
                            e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                  ))}

              <td style={{ width: '150px', minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="status"
                  type="text"
                  value={spec[`status_${obsNumber + 1}`]}
                  onChange={(e) => {
                    spec[`status_${obsNumber + 1}`] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </tr>
          ))}
        </>
      ))}
    </tbody>
  );
};

export default TBody;
