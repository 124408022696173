import { useState } from 'react';

const THead = () => {
  const [render, setRender] = useState(false);
  return (
    <thead style={{ background: '#38d9a9' }}>
      <tr>
        <th colSpan={5}>TABLE MACHINE WISE PARETO CHART</th>
      </tr>
      <tr>
        {['S.No', 'MACHINE', 'DOWN TIME(hrs)', 'COM. TIME', 'COM. %'].map(
          (heading) => (
            <th key={heading}>{heading}</th>
          )
        )}
      </tr>
    </thead>
  );
};

export default THead;
