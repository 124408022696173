import React, { useState } from 'react';
import { TableRowComponent } from './TableRowComponent';

interface IProps {
  moduleData: any;
  updateModuleData: any;
  machineData?: any;
}
const TBody = ({ moduleData, updateModuleData }: IProps) => {
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            updateModuleData,
          }}
        />
      ))}
    </tbody>
  );
};

export default React.memo(TBody);
