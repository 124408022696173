import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox } from '@mui/material';
// import "../../../../App.css";
import TipTap from '../../TipTap';
import { parseUrl } from 'query-string/base';
import HTMLReactParser from 'html-react-parser';
import { TableInput } from '../../../../../components/common';
interface IProps {
  moduleData: any;
  isView: boolean;
  setModuleData: any;
}

const THead = ({ moduleData, isView, setModuleData }: IProps) => {
  const [render, setRender] = useState(false);
  const [agenda, setAgenda] = useState('');
  const [invitees, setInvitees] = useState('');

  const handleSetDesc = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      details_of_nc: desc,
    }));
  };

  const handleSetDescListInvitees = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      root_cause: desc,
    }));
  };
  const handleSetDescRemarks = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      remarks: desc,
    }));
  };
  const handleSetDescVerif = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      verif: desc,
    }));
  };
  const handleSetDescCorrAction = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      corr_action: desc,
    }));
  };

  const handleInputChange = (name: string, value: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <thead>
      {/* <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['F-MR-06 ', '03', '01-04-2019']}
        heading="NON-CONFORMANCE REPORT"
      /> */}

      <tr>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '40px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '50px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '100px',
          }}
        ></th>
      </tr>

   
      <tr style={{ border: 'none' }}>
        <th colSpan={5} style={{ border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            DETAILS OF NON-CONFORMANCE OBSERVED
          </div>
        </th>
        
      </tr>

      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.details_of_nc)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDesc}
                initialContent={moduleData?.details_of_nc}
              />
            </div>
          </td>
        )}
      </tr>

      <tr style={{ border: 'none' }}>
        <th colSpan={2} style={{ border:'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            Signature of Auditor   
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="auditor_sign"
              type="text"
              value={moduleData?.auditor_sign}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        <th colSpan={1} style={{ border:'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            Signature of Auditee  
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="auditee_sign"
              type="text"
              value={moduleData?.auditee_sign}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        
        
      </tr>

      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{ borderRight: 'none', border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              border: 'none',
              fontSize: '18px',
            }}
          >
            ROOT CAUSE ANALYSIS
          </div>
        </th>
      </tr>

      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.root_cause)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescListInvitees}
                initialContent={moduleData?.root_cause}
              />
            </div>
          </td>
        )}
      </tr>

      <tr style={{ border: 'none' }}>
        <th colSpan={5} style={{ borderRight: 'none', borderBottom: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            CORRECTIVE ACTION
          </div>
        </th>
        <th
          colSpan={1}
          style={{
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            DATE :
          </div>
        </th>

        <th
          colSpan={1}
          align="left"
          style={{ borderLeft: 'none', borderBottom: 'none' }}
        >
          {isView ? (
            formatDate(moduleData.target_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.target_date)}
              onChange={(date) => {
                moduleData.target_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.corr_action)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescCorrAction}
                initialContent={moduleData?.corr_action}
              />
            </div>
          </td>
        )}
      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={2} style={{ border:'none',}}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            Signature 
          </div>
        </th>
        <td colSpan={7} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="sign1"
              type="text"
              value={moduleData?.sign1}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        
        
        
      </tr>


      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{ borderRight: 'none', border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              border: 'none',
              fontSize: '18px',
            }}
          >
            VERIFICATION OF CORRECTIVE ACTION TAKEN
          </div>
        </th>
      </tr>

      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData.verif)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescVerif}
                initialContent={moduleData.verif}
              />
            </div>
          </td>
        )}
      </tr>

      <tr style={{ border: 'none' }}>
        <th colSpan={2} style={{ border:'none',}}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            Signature 
          </div>
        </th>
        <td colSpan={7} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="sign2"
              type="text"
              value={moduleData?.sign2}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        
        
        
      </tr>

      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{ borderRight: 'none', border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              border: 'none',
              fontSize: '18px',
            }}
          >
            General Remarks/Effectiveness monitoring of closures:
          </div>
        </th>
      </tr>

      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData.remarks)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescRemarks}
                initialContent={moduleData.remarks}
              />
            </div>
          </td>
        )}
      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{ borderRight: 'none', border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              border: 'none',
              fontSize: '18px',
            }}
          >
            N.C. Closed / N.C. Still Open / Details of Verification Required
          </div>
        </th>
      </tr>
    </thead>
  );
};

export default THead;
