import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';
import { apiConstant } from './constant';
import moment from 'moment';
import AutocompleteGridmui from '../../../../components/common/autocomplete/AutocompleteGridmui';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useQuery } from '../../../../hooks/UseQuery';
import lodash from 'lodash';
import {
  Spot_Welding,
  Grinding,
  // Air_Decay,
  // Air_Leaking_Testing,
  // Bending_Machine,
  // Bending_Machine_01,
  // Bending_Machine_02,
  // Benjo_Press_Machine,
  // Blockage_Testing_Machine,
  // Bust_Testing_Machine,
  // Centre_Lathe_Machine,
  // Clamping,
  // Crimping_Machine,
  // Cutting_Machine,
  // Deburring_Machine,
  // Drill_Machine,
  // End_Forming_Machine,
  // Farule_Fitting_Machine,
  // Flushing_Machine,
  // High_Pressure_Leakage,
  // Hydraullic_Press_1,
  // Hydraullic_Press_2,
  // Induction_Brazing_Machine,
  // Lazer_Marking,
  // Magna_Flux,
  // NRV_Testing_Machine,
  // Oil_Flushing_Machine,
  // ORing_Testing_Machine,
  // Oven,
  // Pad_Printing,
  // Pera_Flange_Machine,
  // Pipe_Flairing_Machine,
  // Polishing_Machine,
  // Radial_Drill_Machine,
  // Soco_Cutter_Machine,
  // Ss_Furnace,
  // Surface_Grinder,
  // Tig_Welding,
  // T_Drill_Machine,
  // Ultrasonic_1,
  // Ultrasonic_2,
} from './allmachineDATA';
import { useNavigate } from 'react-router-dom';

const selectChecklistPreventive = [
  'None',
  'Spot_Welding',
  'Grinding',
  // "Bending_Machine_01",
  // "Induction_Brazing_Machine",
  // "NRV_Testing_Machine",
  // "Pera_Flange_Machine",
  // "Bending_Machine_02",
  // "Flushing_Machine",
  // "Pipe_Flairing_Machine",
  // "Tig_Welding",
  // "Clamping",
  // "Ultrasonic_1",
  // "Oven",
  // "Farule_Fitting_Machine",
  // "Magna_Flux",
  // "Lazer_Marking",
  // "Air_Decay",
  // "Soco_Cutter_Machine",
  // "Crimping_Machine",
  // "Ss_Furnace",
  // "Cutting_Machine",
  // "Benjo_Press_Machine",
  // "Deburring_Machine",
  // "Oil_Flushing_Machine",
  // "Polishing_Machine",
  // "End_Forming_Machine",
  // "Pad_Printing",
  // "Centre_Lathe_Machine",
  // "Hydraullic_Press_1",
  // "Radial_Drill_Machine",
  // "Ultrasonic_2",
  // "Hydraullic_Press_2",
  // "Surface_Grinder",
  // "Blockage_Testing_Machine",
  // "Drill_Machine",
  // "Air_Leaking_Testing",
  // "T_Drill_Machine",
  // "High_Pressure_Leakage",
  // "Bust_Testing_Machine",
  // "ORing_Testing_Machine",
  // "Bending_Machine",
];

const selectChecklistValuePredictive = [
  'None',
  'Flushing',
  'Leakage_Testing',
  'Pipe_Bending',
  'Ultrasonic',
];
const selectType = {
  preventive: selectChecklistPreventive,
  predictive: selectChecklistValuePredictive,
};
const ModalInput = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const [customer, setCustomer] = useState([]);
  const [checkMachine, setCheckMachine] = useState('None');
  // const history = useHistory();
  // const documentType = useQuery('documentType');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update MTTR' : 'Create MTTR'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  // useEffect(() => {
  //   if (id) {
  //     axios
  //       .get(`/api/${apiConstant.getPlanByid}/${id}`)
  //       .then((res) => setMoudleData(res.data))
  //       .catch((err) => console.log(err));
  //   }
  // }, [id]);

  // useEffect(() => {
  //   axios
  //     .get('/api/customerRoutes/customerList/customerForAutoComplete')
  //     .then((res) => {
  //       setCustomer(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);
  // useEffect(() => {
  //   axios
  //     .get(`/api/preventiveChecklist/getChecklistById/${id}`)
  //     .then((res) => {
  //       if (res.data) {
  //         const data = res.data;
  //         if (!data.document_name) {
  //           const values = { ...data };
  //           values.checkListMachineData[checkMachine] = tableType[checkMachine];
  //           setMoudleData(values);
  //         }
  //         setMoudleData(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // function handleData(e: React.ChangeEvent<HTMLInputElement>) {
  //   setMoudleData((prev: any) => ({
  //     ...prev,
  //     [e.target.name]: e.target.value,
  //   }));
  // }

  // const handleSumbit = async () => {
  //   setIsSubmit(true);
  //   const ans = await confirmSubmit();
  //   if (!ans) return;
  //   if (id) {
  //     await axios
  //       .put(`/api/${apiConstant.getPlanByid}/${id}`, moduleData)
  //       .then((res) => {
  //         setIsSubmit(false);
  //         setIsModal(false);
  //         setListId('');
  //       })
  //       .catch((err) => console.log(err));
  //   } else {
  //     await axios
  //       .post(`/api/${apiConstant.createPlanByid}`, moduleData)
  //       .then((res) => {
  //         setIsSubmit(false);
  //         setIsModal(false);
  //         setListId('');
  //       })
  //       .catch((err) => {
  //         setIsSubmit(false);
  //         console.log(err);
  //       });
  //   }
  // };
  //console.log('ggggggggggggggggggggggggg', tableData);
  return (
    <>
      {/* <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleData}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date_range'}
        views={['year']}
        value={moduleData?.date_range || null}
        onChange={(date: any) => {
          moduleData.date_range = moment(date._d).add(10, 'hours');
          setRender((prev) => !prev);
        }}
      /> */}
      <FormControl
        style={{
          width: '500px',
          marginRight: '0.5rem',
          marginTop: '0.6rem',
          marginLeft: '0.6rem',
        }}
        variant="filled"
        className="select-field"
      >
        <InputLabel>Select Machine</InputLabel>
        <Select
          name="checkMachine"
          key={checkMachine}
          defaultValue={checkMachine}
          onChange={(e) => {
            setCheckMachine(e.target.value);
          }}
          className="spec-select-character"
        >
          {selectChecklistPreventive.map((machine: any, index: any) => {
            return (
              <MenuItem key={index} value={machine}>
                {machine.replace(/_/g, ' ')}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          // label={`${id ? 'update' : 'submit'}`}
          label="OK"
          type="button"
          //
          onClick={() => {
            navigate(
              `/common/maintenanceForCheckedMachineForm/${id}/${checkMachine}`
            );
          }}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
