import moment from 'moment';

export const processes = [
  Object.seal(
  {
    process_date:null,
    part_id: null,
    prod_qty: null,
    rej_qty: null,
    prepared_by:'',
    invoice_no:'',
    approved_by:'',
    reasons_for_rej: Array.from({ length: 10 }, () => ({
      reason: '',
      rej_qty: null,
    })),
  },
  )
];

export const initialState = {
  annual_month: '',
  processes: Array.from({ length: 15 }, () => ({ ...processes[0] })),
};
