import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import moment from 'moment';
import { formatDateinMMMYY } from '../../helpers/formatDateForRowInMMYY';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { calcRow } from '../../helpers/calculate';

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
}
const TBodyRow = ({ moduleData, isView, process, index }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <React.Fragment key={index + 10.1 + 'heading '}>
      <tr>
        <th style={{ minWidth: '50px', textAlign: 'center' }}>{index + 1}</th>
        <td>
          {moduleData?.date_range &&
            formatDateinMMMYY(index + 1, moduleData?.date_range)}
        </td>
        {index === 0 && (
          <th
            rowSpan={moduleData?.processes?.length}
            style={{ transform: 'rotate(270deg)', minWidth: '100px' }}
          >
            {moduleData?.supplier_id?.vender_name}
          </th>
        )}
        {index === 0 && (
          <th
            rowSpan={moduleData?.processes?.length}
            style={{ transform: 'rotate(270deg)', minWidth: '100px' }}
          >
            {moduleData?.supplier_id?.material}
          </th>
        )}
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="qty_order_nos"
            type="number"
            value={process?.qty_order_nos}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              process && calcRow(process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '100px' }}>
          {isView ? (
            process?.expected_delivery_date &&
            formatDate(process?.expected_delivery_date)
          ) : (
            <DatePickerMui
              sx={{ width: '150px' }}
              onChange={(date) => {
                process.expected_delivery_date = date;
                setRender((prev) => !prev);
              }}
              value={
                (process?.expected_delivery_date &&
                  moment(process?.expected_delivery_date)) ||
                null
              }
            />
          )}
        </td>
        <td style={{ minWidth: '100px' }}>
          {isView ? (
            process?.act_material_received_date &&
            formatDate(process?.act_material_received_date)
          ) : (
            <DatePickerMui
              sx={{ width: '150px' }}
              onChange={(date) => {
                process.act_material_received_date = date;
                setRender((prev) => !prev);
              }}
              value={
                (process?.act_material_received_date &&
                  moment(process?.act_material_received_date)) ||
                null
              }
            />
          )}
        </td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="material_delivery_delay"
            type="number"
            value={process?.material_delivery_delay}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              process && calcRow(process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="invoice_no"
            type="string"
            value={process?.invoice_no}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="qty_received_nos"
            type="number"
            value={process?.qty_received_nos}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              process && calcRow(process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="qty_rejected"
            type="number"
            value={process?.qty_rejected}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              process && calcRow(process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '100px' }}>{process?.ok_qty_nos}</td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="material_received_in_no_of_supply"
            type="number"
            value={process?.material_received_in_no_of_supply}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              process && calcRow(process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          {process?.quality_rating}
        </td>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          {process?.delivery_rating}
        </td>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="premium_freight"
            type="number"
            value={process?.premium_freight}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              process && calcRow(process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="field_failure"
            type="number"
            value={process?.field_failure}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              process && calcRow(process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="line_stoppages_customer_disruptions"
            type="number"
            value={process?.line_stoppages_customer_disruptions}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              process && calcRow(process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          {process?.overall_rating_percentage}
        </td>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          {process?.rating}
        </td>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          {process?.remarks}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default TBodyRow;
