import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../components/common/useConfirm';
import moment from 'moment';
import DateGridMui from '../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    department_name: '',
    hod: '',
    location: '',
  });

  const [render, setRender] = useState(false);
  const [autoCompleteDepartment, setAutocompleteDepartment] = useState('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/department/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleDepartment(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/department/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/department', moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'department_name'}
        name={'department_name'}
        html_for={'department_name'}
        label_name={'Department Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.department_name}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'hod'}
        name={'hod'}
        html_for={'hod'}
        label_name={'Head Of Department:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.hod}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.location}
        onChange={handleDepartment}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
