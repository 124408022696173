interface IProps {
  name: any;
  value: any;
  onChange: any;
  values: any;
  style: any;
  optionStyle: any;
}

const SimpleSelect = ({
  name,
  value,
  onChange,
  values,
  style,
  optionStyle,
}: IProps) => {
  return (
    <select name={name} value={value} onChange={onChange} style={style}>
      {values.map((option: any, optionIndex: any) => (
        <option
          key={optionIndex + 'optionIndex'}
          value={option.value}
          style={optionStyle}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SimpleSelect;
