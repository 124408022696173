import lodash from 'lodash';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
import { createConstant } from '../../constant';
import TableInput from '../../../../../components/common/TableInput';
import { c_list } from '../../helpers/initialState';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

interface ICustomerList {
  moduleData: any;
  setRender: any;
  confirmAddRow: any;
  customerData: any;
  isView: any;
  handelDeleteRows: any;
}
const getYearTotal = (month: any, processIndex: any) => {
  let countNotNullElements = month
    .slice(0, month.length - 1)
    .filter((elem: any) => elem != null).length;
  if (countNotNullElements == 0) return null;
  if (processIndex == 0) {
    countNotNullElements = 1;
  }
  return +(
    month
      .slice(0, month.length - 1)
      .reduce((sum: any, count: any) => sum + +count, 0) / countNotNullElements
  ).toFixed(0);
};
const getMonthTotal = (process: any, monthIndex: any, processIndex: any) => {
  const total = process.reduce(
    (sum: any, curr: any) => sum + +curr.month[monthIndex],
    0
  );
  let countNotNullElements = process.filter(
    (item: any) => item.month[monthIndex] != null
  ).length;
  if (countNotNullElements == 0) return null;
  if (processIndex == 0) {
    countNotNullElements = 1;
  }

  return +(total / countNotNullElements).toFixed(0);
};
const CustomerList = ({
  moduleData,
  setRender,
  confirmAddRow,
  customerData,
  isView,
  handelDeleteRows,
}: ICustomerList) => {
  return (
    <>
      {moduleData?.customer_list?.map((process: any, processIndex: any) => (
        <>
          <tr style={{ background: '#38d9a9' }}>
            <th>{processIndex + 1}</th>
            <th style={{ minWidth: '200px' }} rowSpan={process.length + 1}>
              {!isView && (
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                  }}
                  onClick={async () => {
                    const ans = await confirmAddRow();
                    if (!ans) return;

                    process.push(lodash.cloneDeep(c_list));
                  }}
                >
                  ADD ROWS
                </CustomButton>
              )}
              <br />
              <br />
              {process?.[0]?.parameter}
            </th>
            <th style={{ minWidth: '200px' }}>{process?.[0]?.header}</th>
            <th style={{ width: '100px' }} rowSpan={process.length + 1}>
              {process?.[0]?.uom}
            </th>
            <th style={{ minWidth: '100px' }}></th>
            <th style={{ minWidth: '100px' }}></th>
            {[...Array(13).keys()].map((index) => (
              <th key={index} style={{ minWidth: '100px' }}>
                {getMonthTotal(process, index, processIndex)}
              </th>
            ))}
            <th></th>
          </tr>
          {process?.map((subProcess: any, subProcessIndex: any) => (
            <tr key={`${processIndex}_${subProcessIndex}_table_row`}>
              <th>{String.fromCharCode(65 + subProcessIndex)}</th>

              <td style={{ minWidth: '200px' }}>
                {isView ? (
                  subProcess?.customer_id?.customer_name
                ) : (
                  <AutocompleteMuiCustom
                    id={'customer_name'}
                    label={''}
                    option_name={'customer_name'}
                    arrayofObj={customerData}
                    value={subProcess.customer_id || ''}
                    onChange={(e: any, value: any) => {
                      subProcess.customer_id = value;
                      setRender((prev: any) => !prev);
                    }}
                  />
                )}
              </td>

              <td style={{ width: '100px' }}>
                <TableInput
                  isView={isView}
                  name="status"
                  type="number"
                  value={subProcess.status}
                  onChange={(e: any) => {
                    subProcess.status = e.target.value;
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
              <td style={{ width: '100px' }}>
                <TableInput
                  isView={isView}
                  name="target"
                  type="number"
                  value={subProcess.target}
                  onChange={(e: any) => {
                    subProcess.target = e.target.value;
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
              {subProcess?.month?.map((monthValue: any, monthIndex: any) => (
                <td key={`month_${monthIndex}`} style={{ width: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="month"
                    type="number"
                    value={subProcess?.month?.[monthIndex]}
                    onChange={(e: any) => {
                      subProcess.month[monthIndex] = e.target.value;
                      subProcess.month[12] = getYearTotal(subProcess.month, processIndex);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
              ))}
              {!isView && subProcessIndex > 0 && (
                <td rowSpan={1} style={{ minWidth: '50px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() =>
                      handelDeleteRows('c_list', processIndex, subProcessIndex)
                    }
                  />
                </td>
              )}
            </tr>
          ))}
        </>
      ))}
    </>
  );
};

export default CustomerList;
