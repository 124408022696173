export const listConstant = {
  childHeader: 'Supplier Audit List',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Supplier Audit List',
  theadHeader: 'Supplier Audit Plan',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'Supplier Audit STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'supplierAuditPlanRoute',
  gettableDataurl: 'supplierAuditPlanRoute/monthly',
  getPlanByid: 'supplierAuditPlanRoute',
  createPlanByid: 'supplierAuditPlanRoute',
  updatePlanByid: 'supplierAuditPlanRoute',
  sync: 'supplierAuditPlanRoute/handleSync',
  getAllCheckList: 'supplierAuditPlanRoute/getAllCheckList',
  checkListStatus: 'supplierAuditPlanRoute/handleStatus',
  checklistUpload: 'supplierAuditPlanRoute/handleUpload',
  checklistDeleteImage: 'supplierAuditPlanRoute/handleDeleteImage',
  checklistData: 'supplierAuditPlanRoute/checklistRoute',
  getMasterDataAutocomplete: 'vendorRoute/vendor/forAutoComplete',
};

export const tilesConstant = {
  childHeaderText: 'Supplier Audit',
};

export const navigationConstant = {
  createPlanSchedule: '/common/supplierAuditPlanCreate',
  createChecklist: '/common/supplierAuditPlanChecklistCreate',
};
