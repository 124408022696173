import moment from 'moment';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import { useQuery } from '../../../../hooks/UseQuery';
import React, { useState } from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
export interface IRev {
  rev_no?: any;
  rev_date?: any;
  rev_desc?: string;
  prepared_by?: string;
  approved_by?: string;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const [render, setRender] = useState(false);
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={10}>
          Performed By:
          <TableInput
            isView={isView}
            name="performed_by"
            type="text"
            value={moduleData?.performed_by}
            onChange={(e) => {
              moduleData.performed_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={18}>
          Approved By:
          <TableInput
            isView={isView}
            name="approved_by"
            type="text"
            value={moduleData?.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={28} style={{ background: 'brown' }}>
          Revision History
        </th>
      </tr>
      <tr>
        <th colSpan={1}>REV. NO</th>
        <th colSpan={4}>REV. DATE</th>
        <th colSpan={15}>REV. DETAILS</th>
        <th colSpan={4}>REVISED BY</th>
        <th colSpan={4}>APPROVED BY</th>
      </tr>

      {moduleData?.revision_history?.map((revData: IRev, index: number) => (
        <tr>
          <td style={{ width: '120px' }} colSpan={1}>
            <TableInput
              name="rev_no"
              type="number"
              value={revData.rev_no}
              onChange={(e) => {
                revData.rev_no = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
          <td style={{ width: '250px' }} colSpan={4}>
            {/* {isView ? (
              formatDate(revData.rev_date || null)
            ) : ( */}
            <DatePickerMui
              value={moment(revData.rev_date)}
              onChange={(date) => {
                revData.rev_date = date;
                setRender((prev) => !prev);
              }}
            />
            {/* )} */}
          </td>
          <td colSpan={15}>
            <TableInput
              name="rev_desc"
              type="text"
              value={revData.rev_desc}
              onChange={(e) => {
                revData.rev_desc = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
          <td colSpan={4}>
            <TableInput
              name="prepared_by"
              type="text"
              value={revData.prepared_by}
              onChange={(e) => {
                revData.prepared_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
          <td colSpan={4}>
            <TableInput
              name="approved_by"
              type="text"
              value={revData.approved_by}
              onChange={(e) => {
                revData.approved_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
        </tr>
      ))}
    </tfoot>
  );
};

export default TFoot;
