import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import SimpleSelectRevised from '../../../../../components/common/SimpleSelectRevised';

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  handelDeleteRows: any;
}
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: 'rgb(255, 236, 153)',
  color: 'black',
} as const;
const TBodyRow = ({
  moduleData,
  isView,
  process,
  index,
  handelDeleteRows,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <React.Fragment key={index + 10.1 + 'heading '}>
      <tr>
        <th style={{
          minWidth: '50px', textAlign: 'center',
          ...stickStylesColumn,
          left: -100
        }}>{index + 1}</th>
        <td style={{
          minWidth: '200px', textAlign: 'center', ...stickStylesColumn,
          left: 0
        }}>
          {process.desc}
        </td>
        <td style={{
          minWidth: '200px', textAlign: 'center', ...stickStylesColumn,
          left: 200
        }}>
          {process.freq}
        </td>
        {Array.from({ length: 31 }, (_, indx) =>
          process.data_type === 'footer' ? (
            <td key={index + 2.2 + indx + 'after' + 111.33}>
              <TableInput
                isView={isView}
                name="verified_by"
                type="text"
                value={process.shift_A_results[indx]}
                onChange={(e) => {
                  process.shift_A_results[indx] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          ) : (
            <td
              style={{
                minWidth: '100px',
                textAlign: 'center',
              }}
              key={index + 2.2 + indx + 'after' + 111.33}
            >
              {moduleData.machine_id && (
                <SimpleSelectRevised
                  name={`shift_A_results[${indx}]`}
                  value={process.shift_A_results[indx]}
                  onChange={(e: any) => {
                    process.shift_A_results[indx] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  options={[
                    { value: '', label: 'none' },
                    { value: 'true', label: '✓' },
                    { value: 'false', label: 'x' },
                  ]}
                />
              )}
            </td>
          ),
        )}
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="remarks"
            type="text"
            value={process.remarks}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default TBodyRow;
