export const listConstant = {
  childHeader: 'Attribute MSA Plan list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Attribute MSA Plan list',
  theadHeader: ' Attribute MSA Plan',
  extracolSpan: 1,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'attributeMsaPlanNewRoute/monthlylist',
  deleteListurl: 'attributeMsaPlanNewRoute',
  getListPlanByid: 'attributeMsaPlanNewRoute',
  createListPlanByid: 'attributeMsaPlanNewRoute',

  // -----plan--
  sync: 'attributeMsaPlanNewRoute/handleSync',
  getAllPlanById: 'attributeMsaPlanNewRoute/allplansbyid',
  upadateCreateAllPlan: 'attributeMsaPlanNewRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'attributeMsaPlanNewRoute/maintenancePlanDeleteBulkById',
  forAutoComplete: '/api/defectRoutes/defectList/defectForAutoComplete',
};

export const settingPanelConstant = {
  // settingPanel setting for plan
  crudApi: 'attributeMsaPlanNewSettingRoute',
  docType: 'attributeMsaPlanNew',
};

export const tilesConstant = {
  childHeaderText: 'Attribute MSA Plan',
};

export const navigationConstant = {
  planRouter: '/common/attributeMsaPlanNewCreate',
};
