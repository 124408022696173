export const apiConstant = {
  // list api url
  gettableListDataurl: 'annualPmPlanTrollyRoute/monthlylist',
  deleteListurl: 'annualPmPlanTrollyRoute',
  getListPlanByid: 'annualPmPlanTrollyRoute',
  createListPlanByid: 'annualPmPlanTrollyRoute',
  // -----plan--
  getAllPlanById: 'annualPmPlanTrollyRoute/allplansbyid',
  upadateCreateAllPlan:
    'annualPmPlanTrollyRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'annualPmPlanTrollyRoute/maintenancePlanDeleteBulkById',
  sync: 'annualPmPlanTrollyRoute/handleSync',
  // ----status---
  getAllStatus: 'annualPmPlanTrollyRoute/getAllSatusByYear',
  upadateListStatus: 'annualPmPlanTrollyRoute/handleStatusUpdate',

  //
  updatePlanByid: 'annualPmPlanTrollyRoute',
  checklistUpload: 'annualPmPlanTrollyRoute/handleUpload',
  checklistDeleteImage: 'annualPmPlanTrollyRoute/handleDeleteImage',
  checklistData: 'annualPmPlanTrollyRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'Annual PM Plan Trolly',
};
