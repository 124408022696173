import React, { useCallback, useRef, useState } from 'react';
import { TableBody } from '@mui/material';
import axios from 'axios';
import {
  AddButton,
  CustomButton,
} from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import Profile from './components/Profile';
import { RiFileExcel2Line } from 'react-icons/ri';
import { navigationConstant, tableHeaderObj } from './helpers/contant';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import ViewFile from './components/ViewFile';
import ListRows from './ListRows';
import { useNavigate } from 'react-router-dom';
import { excelDownload, handleImport } from './helpers/utils';
import TniModalTable from './components/TniModalTable';
import { PDFDocument, rgb } from 'pdf-lib';

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const inductionFormUrl: { [key: string]: string } = {
  staff_pdf:
    'https://mushin-labs.s3.ap-south-1.amazonaws.com/united-cores/EducationAndTraining/INDUCTION+FOR+STAFF.pdf',
  worker_pdf:
    'https://mushin-labs.s3.ap-south-1.amazonaws.com/united-cores/EducationAndTraining/INDUCTION+FOR+WORKER.pdf',
  staff_xlsx:
    'https://mushin-labs.s3.ap-south-1.amazonaws.com/united-cores/EducationAndTraining/INDUCTION+FOR+STAFF.xlsx',
  worker_xlsx:
    'https://mushin-labs.s3.ap-south-1.amazonaws.com/united-cores/EducationAndTraining/INDUCTION+FOR+WORKER.xlsx',
};

const drawTextOnPage = (page: any, text: any, options: any) => {
  const { x, y, size = 9, color = 'rgb(0, 0, 0)' } = options;
  page.drawText(text, {
    x,
    y,
    size,
    color,
  });
};

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [departmentId, setDepartmentId] = useState<string | null>(null);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [profile, setProfile] = useState('');
  const [loader, setLoader] = useState<boolean>(false);
  const search = useQuery('search');
  const idRef = useRef<any>({});
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const [docFiles, setDocFiles] = useState<any>({});
  const [isOpenFiles, setIsOpenFiles] = useState(false);
  const [isOpenTniModal, setIsOpenTniModal] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE Employee',
    'Are you sure you want to delete this?'
  );
  const navigate = useNavigate();

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/employee?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [page, search, render, isModal]);

  const handleDelete = useCallback(async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/employee/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDownloadDocs = useCallback(
    async (
      emp_type: string,
      file_type: 'pdf' | 'xlsx',
      emp_name: string,
      department: string,
      date: string,
      designation: string,
      qualification: string
    ) => {
      if (!emp_type) return;

      const url = inductionFormUrl[`${emp_type.toLowerCase()}_${file_type}`];
      try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);

        // Add your text to the first page of the PDF
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const { width, height } = firstPage.getSize();

        const option = { size: 9, color: rgb(0, 0, 0) };
        if (emp_type?.toLowerCase() == 'worker') {
          drawTextOnPage(firstPage, emp_name, {
            x: 60,
            y: height - 139,
            ...option,
          });
          drawTextOnPage(firstPage, department, {
            x: 80,
            y: height - 154,
            ...option,
          });
          drawTextOnPage(firstPage, date, {
            x: 60,
            y: height - 169,
            ...option,
          });
          drawTextOnPage(firstPage, designation, {
            x: 600,
            y: height - 139,
            ...option,
          });
          drawTextOnPage(firstPage, qualification, {
            x: 600,
            y: height - 154,
            ...option,
          });
        }

        if (emp_type?.toLowerCase() == 'staff') {
          drawTextOnPage(firstPage, emp_name, {
            x: 60,
            y: height - 131,
            ...option,
          });
          drawTextOnPage(firstPage, department, {
            x: 80,
            y: height - 147,
            ...option,
          });
          drawTextOnPage(firstPage, date, {
            x: 60,
            y: height - 163,
            ...option,
          });
          drawTextOnPage(firstPage, designation, {
            x: 600,
            y: height - 131,
            ...option,
          });
          drawTextOnPage(firstPage, qualification, {
            x: 600,
            y: height - 147,
            ...option,
          });
        }
        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Create a Blob from the PDF bytes and trigger download
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${emp_name}-${designation}-${date}.pdf`;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    []
  );

  const handleNavigateTest = useCallback(
    (item: any, test_level: string) => {
      navigate(
        `${navigationConstant.skillEvaluationTestSheetListRouter}?test_level=${test_level}&employee_id=${item?._id}&employee_name=${item.employee_name}&department_id=${item?.department_id?._id}`
      );
    },
    [navigate]
  );

  const handleNavigateTniTopic = useCallback(
    (id: any, empId: any, isView: boolean) => {
      navigate(
        `${navigationConstant.empTniTopicCreateRoute}/${id}?employee_id=${empId}&isView=${isView}`
      );
    },
    [navigate]
  );

  const handleNavigatOJT = useCallback(
    (id: any, empId: any, depertment_id: any, isView: boolean) => {
      navigate(
        `${navigationConstant.empOjtCreateRoute}/${
          id || null
        }?employee_id=${empId}&isView=${isView}&depertment_id=${depertment_id}`
      );
    },
    [navigate]
  );

  return (
    <>
      <ChildHeader text="EMPLOYEE LIST">
        <CustomButton
          color="primary"
          sx={{ margin: '10px', backgroundColor: '#087f5b' }}
          icon={<RiFileExcel2Line />}
          disabled={false}
          size="large"
          onClick={() => excelDownload()}
        >
          Excel Download
        </CustomButton>
        <CustomUploadButton
          lable={'Upload Employee List'}
          onChange={(e) => handleImport(e, setLoader, setRender)}
        />
        <AddButton onClick={() => setIsModal(true)} label="New Employee" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data?.map((item: any, index: number) => (
              <ListRows
                {...{
                  item,
                  index,
                  handleDownloadDocs,
                  perPage,
                  page,
                  idRef,
                  setDocFiles,
                  setIsOpenFiles,
                  setIsProfileModal,
                  setModuleId,
                  setIsModal,
                  handleDelete,
                  handleNavigateTest,
                  setIsOpenTniModal,
                  setDepartmentId,
                  handleNavigateTniTopic,
                  handleNavigatOJT,
                }}
              />
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title="CREATE NEW EMPLOYEE"
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        openModal={isOpenTniModal}
        closeModal={() => {
          setIsOpenTniModal(false);
        }}
        title="TNI"
      >
        <TniModalTable departmentId={departmentId} empId={moduleId} />
      </ModalCustom>
      <ModalCustom
        openModal={isProfileModal}
        closeModal={() => {
          setProfile('');
          setIsProfileModal(false);
        }}
        title="PROFILE"
      >
        <Profile id={idRef.current} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenFiles}
        closeModal={() => {
          setIsOpenFiles(false);
        }}
      >
        <ViewFile
          id={idRef.current}
          docFiles={docFiles}
          setIsOpenFiles={setIsOpenFiles}
        />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
