const style1 = {
  backgroundColor: '#4F81BD',
  // backgroundColor: '#00B050',
  color: '#fff',
  // minWidth: '80px',
  border: '1px solid #343a40',
};

export const listHeaderObj = [
  [
    { text: '#', style: { ...style1 } },
    { text: 'TOPIC NAME', style: { ...style1 } },
    {
      text: 'DEPARTMENT',
      style: { ...style1 },
    },
    {
      text: 'Action',
      style: { ...style1, color: '#fff', backgroundColor: '#c92a2a' },
    },
  ],
];

export const HeaderStandard: any = [
  [
    {
      text: 'S.No',
      style: { ...style1, minWidth: '90px', width: '80px' },
    },
    {
      text: 'Minimum Skill Required',
      style: { ...style1, minWidth: '300px', width: '300px' },
    },
    { text: 'Symbol', style: { ...style1, minWidth: '150px', width: '130px' } },
    { text: 'Training Topic', style: style1 },
    {
      text: 'Action',
      style: { color: '#fff', backgroundColor: '#c92a2a' },
    },
  ],
];

export const listConstant = {
  childHeader: 'List of Training Topics',
  addButtonLable: 'New Topic',
};

export const headerConstant = {
  tableCompHeadTitle: 'Training Topics SHEET',
};

export const apiConstant = {
  get: 'tniTopicRoute',
  getById: 'tniTopicRoute',
  post: 'tniTopicRoute',
  update: 'tniTopicRoute',
  delete: 'tniTopicRoute',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
  departmentAutoComplete: 'department',
};

export const tilesConstant = {
  childHeaderText: 'Training Topics SHEET',
};

export const navigationConstant = {
  createEditChecklist: '/common/traningtopicscreateedit',
};
