import { Box } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';

import InspectionStandardList from './components/tablelist/List';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import useDebounce from '../../../hooks/useDebounce';
import { CustomPagination } from '../../../components/common';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { SupervisorPartTable } from '../../../components/ui';

const filterOptions = [
  { value: 'createdAt', name: 'Most Recent First' },
  { value: 'createdAt_old', name: 'Oldest First' },
];
const TableList = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/processParameterStdRoute?page=${page}&sortBy=${
          filter || 'part_name'
        }&search=${search}&perPage=${10}`
      )
      .catch((err) => {
        console.log(err);
      });
    setTableData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };
  useDebounce(fetchData, 1000, [page, search, render, filter]);

  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/processParameterStdRoute/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box>
      <ChildHeader text="Process Parmeter Std">
        <AddButton label="New Std" onClick={() => setIsOpenPopup(true)} />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Item Name" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <InspectionStandardList {...{ tableData, deleteDocument }} />
      <ModalCustom
        title="Pick Part For Std"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <SupervisorPartTable link={`/common/createProcessParameterStd`} />
      </ModalCustom>
      <CustomPagination totalPage={totalPages} />
    </Box>
  );
};

export default TableList;
