import moment from 'moment';
import { useEffect, useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import AutocompleteGridmui from '../../../../components/common/autocomplete/AutocompleteGridmui';
import axios from 'axios';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  // console.log('moduleData', moduleData);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc', 'Rev No', 'Rev Date']}
        values={['F-MNT-11', '01', '24.02.2023']}
        heading="Machine History Card"
      />
      {/* <tr>
        <th colSpan={4} align="left">
          <TableInput
            name="department"
            type="text"
            value={moduleData.department}
            onChange={(e) => {
              moduleData.department = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          /> 
           <AutocompleteGridmui
            label={'Department'}
            id={'department_name'}
            option_name={'department_name'}
            value={department || moduleData.department_id || ''}
            arrayofObj={autoCompleteDepartment}
            onChange={(e, value) => {
              moduleData.department_id = value?._id;
              setDepartment(value);
              setRender((prev) => !prev);
            }}
            // last_child={2}
            // isView={isView}
          /> 
        </th>
        <th colSpan={3} align="left">
          Issue Date:
          {isView ? (
            formatDate(moduleData.issue_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.issue_date)}
              onChange={(date) => {
                moduleData.issue_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr> */}
      <tr>
        <th colSpan={6} align="left">
          Machine No. : {moduleData.machine_no}
        </th>
        <th colSpan={6} align="left">
          Machine Name : {moduleData.machine_name}
        </th>
      </tr>

      {/* <tr>
        <th colSpan={4} align="left">
          Make:{moduleData.make}
        </th>
        <th colSpan={3} align="left">
          Capacity:
          <TableInput
            name="capacity"
            type="text"
            value={moduleData.capacity}
            onChange={(e) => {
              moduleData.capacity = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Installation Date:{moduleData.make}
        </th>
        <th colSpan={3} align="left">
          Power:
          <TableInput
            name="power"
            type="text"
            value={moduleData.power}
            onChange={(e) => {
              moduleData.power = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Operating Manual:
          <TableInput
            name="operating_manual"
            type="text"
            value={moduleData.operating_manual}
            onChange={(e) => {
              moduleData.operating_manual = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={3} align="left">
          Available/Not Available
        </th>
      </tr> */}
      {/* <tr>
        <th
          colSpan={12}
          style={{
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: 600,
            backgroundColor: '#63C4EF',
            color: '#343a40',
            border: '1px solid black',
            textTransform: 'uppercase',
          }}
        >
          Machine Breakdown Details
        </th>
      </tr> */}
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.NO',
          'DATE',
          'TIME',
          'Breakdown Details/ Preventive Maintenance',
          'Action Taken',
          'Breakdown Complete Date',
          'Breakdown Complete Time',
          'Breakdown Hours',
          'Spare Parts Qty',
          'Spare Parts Detail',
          // 'TYPE OF MAINTENANCE',
          // 'MAINTENANCE DETAILS',
          // 'DETAILS OF PART CHANGED',
          'REMARKS',
        ].map((head) => (
          <th
            style={{ textTransform: 'uppercase' }}
            colSpan={
              // head === 'REMARKS' ||
              // head === 'Breakdown Details/ Preventive Maintenance' ||
              // head === 'Action Taken'
              //   ? 2
              //   :
              head === 'REMARKS' ? 2 : 1
            }
          >
            {head}
          </th>
        ))}
      </tr>
      <tr></tr>
    </thead>
  );
};

export default THead;
