import { useState } from 'react';
import { Grid } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setRender: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
  setRender,
}: IProps) => {
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <th>{index + 1}</th>
              {/* <td style={{ minWidth: '130px' }}>
                {isView ? (
                  formatDate(process.date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.date)}
                    onChange={(date) => {
                      process.date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td> */}
              <td style={{ width: '280px', minWidth: '280px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.part_name
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.2rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name
                          : process?.partName || ''}
                        {!process?.part_id && 'Pick Part Name.'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            setRender((prev: any) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ width: '280px', minWidth: '280px' }}>
                {process?.partName || process?.part_id?.part_number || ''}
              </td>

              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="no_of_rejection"
                  type="number"
                  value={process.no_of_rejection}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '300px' }}>
                <TableInput
                  isView={isView}
                  name="reason"
                  type="text"
                  value={process.reason}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="receiver_sign"
                  type="text"
                  value={process.receiver_sign}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="approved_sign"
                  type="text"
                  value={process.approved_sign}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '120px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
