import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import SimpleSelect from '../../../../../components/common/SimpleSelect';

// const shiftResult = {
//   true: '✓',
//   false: '⨯',
// };

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}
const TBodyRow = ({
  moduleData,
  isView,
  process,
  index,
}: // handelDeleteRows,
// setIsOpenPopup,
// setPartIdx,
IProps) => {
  const [render, setRender] = useState(false);

  return (
    <React.Fragment key={index + 10.1 + 'heading '}>
      <tr>
        <th style={{ minWidth: '100px', textAlign: 'center' }}>{index + 1}</th>

        {process.id === 1 && (
          <td style={{ minWidth: '200px', textAlign: 'center' }} rowSpan={4}>
            {process.desc}
          </td>
        )}
        {process.id === 5 && (
          <td style={{ minWidth: '200px', textAlign: 'center' }} rowSpan={6}>
            {process.desc}
          </td>
        )}
        {process.id === 11 && (
          <td style={{ minWidth: '200px', textAlign: 'center' }} rowSpan={37}>
            {process.desc}
          </td>
        )}

        <td style={{ minWidth: '200px', textAlign: 'center' }}>
          {process.freq}
        </td>
        {Array.from({ length: 8 }, (_, indx) =>
          process.data_type === 'footer' ? (
            <td
              key={index + 2.2 + indx + 'after' + 111.33}
              style={{ minWidth: '200px', textAlign: 'center' }}
            >
              <TableInput
                isView={isView}
                name="verified_by"
                type="text"
                value={process.shift_A_results[indx]}
                onChange={(e) => {
                  process.shift_A_results[indx] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          ) : (
            <td
              style={{
                minWidth: '100px',
                textAlign: 'center',
                fontSize: '2rem',
              }}
              key={index + 2.2 + indx + 'after' + 111.33}
            >
              {isView ? (
                (process.shift_A_results[indx] === 'true' && '✓') ||
                (process.shift_A_results[indx] === 'false' && '⨯') ||
                (process.shift_A_results[indx] === '' && '')
              ) : (
                <SimpleSelect
                  name={`shift_A_results[${indx}]`}
                  value={process.shift_A_results[indx]}
                  onChange={(e: any) => {
                    process.shift_A_results[indx] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  values={[
                    { value: '', label: 'none' },
                    { value: 'true', label: '✓' },
                    { value: 'false', label: '⨯' },
                  ]}
                  style={{
                    padding: '8px',
                    fontSize: '1.2rem',
                    border: '3px solid white',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    outline: 'none',
                    width: '70px',
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                  optionStyle={{
                    backgroundColor: 'white',
                    color: 'black',
                    width: '40px',
                  }}
                />
              )}
            </td>
          ),
        )}
      </tr>
    </React.Fragment>
  );
};

export default TBodyRow;
