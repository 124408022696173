import moment from 'moment';

export const processes = [
  {
    date: moment(),
    part_id: null,
    no_of_rejection: null,
    reason: '',
    receiver_sign: '',
    approved_sign: '',
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  no_of_rows: 1,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  moduleData_Date: moment(),
  rejection_at_setup: false,
  rejection_at_pir: false,
  rejection_at_pdir: false,
  processes: Array.from({ length: 5 }, () => ({ ...processes[0] })),
};
