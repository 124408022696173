import { Grid, MenuItem } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableInput } from '../../../../components/common';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import CustomSelect from '../../../../components/common/SelectField';
import { useQuery } from '../../../../hooks/UseQuery';
import { calMinutes, convToTime } from '../../../../utils/helpers';
import { actionTakens, actionTakenTickMarks, reasons } from '../helpers/data';
import ReportsButton from './ReportsButton';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import formatDate from '../../../../components/common/formatDate';
import SelectGridMui from '../../../../components/common/selectscustom/SelectGridMui';

const TBodyRow = ({
  isView,
  process,
  machineData,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  index,
  getReportValues,
  redirect,
  setTbodyRender,
}: any) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <td style={{ minWidth: '200px' }}>
        {isView ? (
          process?.machine_id?.machine_no
        ) : (
          <AutocompleteMuiCustom
            id="Machine"
            label=""
            option_name="machine_no"
            arrayofObj={machineData}
            value={process.machine_id || ''}
            onChange={(e, value) => {
              process.machine_id = value;
              setRender((prev) => !prev);
            }}
          />
        )}
      </td>
      <td style={{ minWidth: '100px' }}>
        {isView ? (
          process.date && formatDate(process.date)
        ) : (
          <DatePickerMui
            sx={{ width: '150px' }}
            onChange={(date) => {
              process.date = date;
              setRender((prev) => !prev);
            }}
            value={moment(process.date || null)}
          />
        )}
      </td>
      <td style={{ minWidth: '100px' }}>
        {isView ? (
          convToTime(process.from)
        ) : (
          <TimePicker
            sx={{ width: '120px' }}
            value={moment(process.from || null)}
            onChange={(date) => {
              process.from = date;
              setRender((prev) => !prev);
            }}
          />
        )}
      </td>
      <td style={{ minWidth: '100px' }}>
        {isView ? (
          convToTime(process.to)
        ) : (
          <TimePicker
            sx={{ width: '120px' }}
            value={moment(process.to || null)}
            onChange={(date) => {
              process.to = date;
              setRender((prev) => !prev);
            }}
          />
        )}
      </td>
      <td style={{ minWidth: '100px' }}>
        {calMinutes(process.from, process.to)}
      </td>
      <td style={{ minWidth: '150px' }}>
        {isView ? (
          process?.reason && reasons[process?.reason.slice(5) - 1]?.name
        ) : (
          <CustomSelect
            name="reason"
            value={process?.reason}
            onChange={(e) => {
              process.reason = e.target.value;
              process.action_taken = '';
              setRender((prev) => !prev);
            }}
          >
            {reasons.map((reason) => (
              <MenuItem value={reason.value}>{reason.name}</MenuItem>
            ))}
          </CustomSelect>
        )}
      </td>
      <td style={{ minWidth: '150px' }}>
        {isView
          ? process?.action_taken &&
            actionTakens[process?.reason][process?.action_taken.slice(6) - 1]
              .name
          : actionTakens[process?.reason] && (
              <CustomSelect
                name="reason"
                key="reason"
                value={process?.action_taken}
                onChange={(e) => {
                  process.action_taken = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem>None</MenuItem>
                {actionTakens[process.reason].map((action: any) => (
                  <MenuItem value={action.value}>{action.name}</MenuItem>
                ))}
              </CustomSelect>
            )}
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.change_type
          : actionTakenTickMarks[`${process?.reason}`]?.change_type}
      </td>
      <td style={{ width: '250px', minWidth: '250px' }}>
        {isView ? (
          process?.part_id && process.part_id?.part_number
        ) : (
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.2rem',
                }}
              >
                {typeof process?.part_id === 'object' &&
                process?.part_id !== null
                  ? process.part_id?.part_number
                  : (process?.partNumber && process?.partNumber) || ''}
                {!process?.part_id && 'Pick Part No.'}
              </p>
            </Grid>

            {process.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    process.part_id = null;
                    process.partNumber = null;
                    process.partName = null;
                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsOpenPopup(true);
                    setPartIdx(index);
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
      </td>
      <td style={{ minWidth: '120px' }}>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="old"
          type="text"
          value={process.old}
          onChange={(e) => {
            process.old = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '120px' }}>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="new"
          type="text"
          value={process.new}
          onChange={(e) => {
            process.new = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="inform_to_QA"
          type="text"
          value={process.inform_to_QA}
          onChange={(e) => {
            process.inform_to_QA = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.setup && '✓'
          : actionTakenTickMarks[`${process?.reason}`]?.setup && '✓'}
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.training && '✓'
          : actionTakenTickMarks[`${process?.reason}`]?.training && '✓'}
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.retro && '✓'
          : actionTakenTickMarks[`${process?.reason}`]?.retro && '✓'}
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.suspected && '✓'
          : actionTakenTickMarks[`${process?.reason}`]?.suspected && '✓'}
      </td>
      <td style={{ minWidth: '100px' }}>
        {getReportValues(process, 'retro', 'total_part_to_inspect')}
      </td>
      <td style={{ minWidth: '100px' }}>
        {getReportValues(process, 'retro', 'ok_qty')}
      </td>
      <td style={{ minWidth: '100px' }}>
        {getReportValues(process, 'retro', 'rejected_qty')}
      </td>
      <td style={{ minWidth: '100px' }}>
        {getReportValues(process, 'suspected', 'total_part_to_inspect')}
      </td>
      <td style={{ minWidth: '100px' }}>
        {getReportValues(process, 'suspected', 'ok_qty')}
      </td>
      <td style={{ minWidth: '100px' }}>
        {getReportValues(process, 'suspected', 'rejected_qty')}
      </td>
      <td style={{ minWidth: '120px' }}>
        {isView ? (
          process.prod_head
        ) : (
          <CustomSelect
            name="prod_head"
            value={process.prod_head || ''}
            label={''}
            onChange={(e: any) => {
              process.prod_head = e.target.value;
              setRender((prev) => !prev);
            }}
            sx={{ width: '120px' }}
          >
            <MenuItem value={'Manoj Rai'}>Manoj Rai</MenuItem>
            <MenuItem value={'Inderjeet Singh'}>Inderjeet Singh</MenuItem>
            <MenuItem value={'Sukhbeer'}>Sukhbeer</MenuItem>
            <MenuItem value={'Praveen'}>Praveen</MenuItem>
            <MenuItem value={'Pradeep'}>Pradeep</MenuItem>
            <MenuItem value={'M.S. Chawla'}>M.S. Chawla</MenuItem>
          </CustomSelect>
        )}
        {/* <TableInput
              isView={isView}
              style={{ width: '120px' }}
              name="prod_head"
              type="text"
              value={process.prod_head}
              onChange={(e) => {
                process.prod_head = e.target.value;
                setRender((prev) => !prev);
              }}
            /> */}
      </td>
      <td style={{ minWidth: '120px' }}>
        {isView ? (
          process.qa_inspector
        ) : (
          <CustomSelect
            name="qa_inspector"
            value={process.qa_inspector || ''}
            label={''}
            onChange={(e: any) => {
              process.qa_inspector = e.target.value;
              setRender((prev) => !prev);
            }}
            sx={{ width: '120px' }}
          >
            <MenuItem value={'Dilip'}>Dilip</MenuItem>
            <MenuItem value={'Ujjwal'}>Ujjwal</MenuItem>
            <MenuItem value={'Sunil'}>Sunil</MenuItem>
            <MenuItem value={'Jeetu'}>Jeetu</MenuItem>
            <MenuItem value={'Vedprakash'}>Vedprakash</MenuItem>
            <MenuItem value={'Vivek'}>Vivek</MenuItem>
            <MenuItem value={'Jitender'}>Jitender</MenuItem>
            <MenuItem value={'Pannalal'}>Pannalal</MenuItem>
          </CustomSelect>
        )}
        {/* <TableInput
              isView={isView}
              style={{ width: '120px' }}
              name="qa_inspector"
              type="text"
              value={process.qa_inspector}
              onChange={(e) => {
                process.qa_inspector = e.target.value;
                setRender((prev) => !prev);
              }}
            /> */}
      </td>
      <td style={{ minWidth: '120px' }}>
        {isView ? (
          process.qa_head
        ) : (
          <CustomSelect
            name="qa_head"
            value={process.qa_head || ''}
            label={''}
            onChange={(e: any) => {
              process.qa_head = e.target.value;
              setRender((prev) => !prev);
            }}
            sx={{ width: '120px' }}
          >
            <MenuItem value={'Vikas Tyagi'}>Vikas Tyagi</MenuItem>
          </CustomSelect>
        )}
        {/* <TableInput
              isView={isView}
              style={{ width: '120px' }}
              name="qa_head"
              type="text"
              value={process.qa_head}
              onChange={(e) => {
                process.qa_head = e.target.value;
                setRender((prev) => !prev);
              }}
            /> */}
      </td>
      <td style={{ minWidth: '120px' }}>
        {isView ? (
          process.maint_inspector
        ) : (
          <CustomSelect
            name="maint_inspector"
            value={process.maint_inspector || ''}
            label={''}
            onChange={(e: any) => {
              process.maint_inspector = e.target.value;
              setRender((prev) => !prev);
            }}
            sx={{ width: '120px' }}
          >
            <MenuItem value={'Sandeep'}>Sandeep</MenuItem>
            <MenuItem value={'Manoj Rai'}>Manoj Rai</MenuItem>
          </CustomSelect>
        )}
        {/* <TableInput
              isView={isView}
              style={{ width: '120px' }}
              name="maint_inspector"
              type="text"
              value={process.maint_inspector}
              onChange={(e) => {
                process.maint_inspector = e.target.value;
                setRender((prev) => !prev);
              }}
            /> */}
      </td>
      <td style={{ minWidth: '120px' }}>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="customer"
          type="text"
          value={process.customer}
          onChange={(e) => {
            process.customer = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '120px' }}>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="heat_no"
          type="text"
          value={process.heat_no}
          onChange={(e) => {
            process.heat_no = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '180px' }}>
        <TableInput
          isView={isView}
          style={{ width: '200px' }}
          name="invoice_no"
          type="text"
          value={process.invoice_no}
          onChange={(e) => {
            process.invoice_no = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '200px' }}
          name="remarks"
          type="text"
          value={process.remarks}
          onChange={(e) => {
            process.remarks = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.part_id && (
          <ReportsButton {...{ process, redirect }} reportKey="setup" />
        )}
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.part_id && (
          <ReportsButton {...{ process, redirect }} reportKey="retro" />
        )}
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.part_id && (
          <ReportsButton {...{ process, redirect }} reportKey="suspected" />
        )}
      </td>
      {!isView && (
        <td style={{ minWidth: '100px' }}>
          <DeleteIconButton
            tooltipTitle={'delete'}
            onClick={() => handelDeleteRows(index)}
          />
        </td>
      )}
    </>
  );
};

export default TBodyRow;
