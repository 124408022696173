import React, { useState, useEffect } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import {
  CustomPagination,
  TableCustom,
  TextAreaInput,
} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'Problem Date', rowspan: 1, colspan: 1 },
    { text: 'Customer', rowspan: 1, colspan: 1 },
    { text: 'Part Name & No.', rowspan: 1, colspan: 1 },
    { text: 'Problem Description', rowspan: 1, colspan: 1 },
    { text: 'Responsibility', rowspan: 1, colspan: 1 },
    { text: 'Target Date', rowspan: 1, colspan: 1 },
    { text: 'Action Taken', rowspan: 1, colspan: 1 },
    { text: 'Status', rowspan: 1, colspan: 1 },
    { text: 'Type(UC/Supplier)', rowspan: 1, colspan: 1 },
    { text: '1st lot', rowspan: 1, colspan: 1 },
    { text: '2nd lot', rowspan: 1, colspan: 1 },
    { text: '3rd lot', rowspan: 1, colspan: 1 },
    { text: 'remarks', rowspan: 1, colspan: 1 },
    { text: 'DEFECT PHOTO', rowspan: 1, colspan: 1 },
    { text: 'SUPPORTING DOCUMENTS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [contentType, setContentType] = useState('');
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');
  const defect = useQuery('defects');
  const [all, setAll] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer([...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/customerComplaintRecords/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&customer=${customer}&defects=${defect}&all=${all}`
      )
      .catch((err) => {
        console.log(err);
      });

    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/customerComplaintRecords/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    customer,
    all,
  ]);
  return (
    <>
      <ChildHeader text="CUSTOMER COMPLAINT RECORDS">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton
          onClick={() => setIsModal(true)}
          label="NEW CUSTOMER RECORD"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <FilterBy
          sx={{ width: 500 }}
          name="customer"
          label="Filter By Customer"
          options={filterCustomer}
        />
      </SearchFilterContainer>

      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: '150px' }}>
                      {item.issue_reported_date &&
                        formatDate(item.issue_reported_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.customer_id?.customer_name}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: '150px' }}>
                      {item.part_id?.part_name +
                        ' - ' +
                        item.part_id?.part_number}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: '200px' }}>
                      <TextAreaInput value={item?.problem_description} />
                    </TableCell>
                    <TableCell align="center">{item?.responsibility}</TableCell>
                    <TableCell align="center" style={{ minWidth: '150px' }}>
                      {item.target_date && formatDate(item.target_date)}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: '250px' }}>
                      <TextAreaInput value={item.action_taken} />
                    </TableCell>
                    <TableCell align="center">{item.status}</TableCell>
                    <TableCell align="center">
                      {item?.type_of_complaint}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        ...(item.first_lot_status &&
                          item.first_lot_status.toLowerCase() === 'ok' && {
                            background: '#69db7c',
                          }),
                        ...(item.first_lot_status &&
                          item?.first_lot_status.toLowerCase() === 'ng' && {
                            background: '#ff8787',
                          }),
                        minWidth: '200px',
                      }}
                    >
                      {'Lot date - ' +
                        (item.first_lot_date &&
                          formatDate(item.first_lot_date)) || ''}
                      <br></br>
                      {'Lot qty. - ' + item?.first_lot_quantity}
                      <br></br>
                      {'status - ' + item?.first_lot_status}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        ...(item.second_lot_status &&
                          item.second_lot_status.toLowerCase() === 'ok' && {
                            background: '#69db7c',
                          }),
                        ...(item.second_lot_status &&
                          item?.second_lot_status.toLowerCase() === 'ng' && {
                            background: '#ff8787',
                          }),
                        minWidth: '200px',
                      }}
                    >
                      {'Lot date - ' +
                        (item.second_lot_date &&
                          formatDate(item.second_lot_date)) || ''}
                      <br></br>
                      {'Lot qty. - ' + item?.second_lot_quantity}
                      <br></br>
                      {'status - ' + item?.second_lot_status}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        ...(item.third_lot_status &&
                          item.third_lot_status.toLowerCase() === 'ok' && {
                            background: '#69db7c',
                          }),
                        ...(item.third_lot_status &&
                          item?.third_lot_status.toLowerCase() === 'ng' && {
                            background: '#ff8787',
                          }),
                        minWidth: '200px',
                      }}
                    >
                      {'Lot date - ' +
                        (item.third_lot_date &&
                          formatDate(item.third_lot_date)) || ''}
                      <br></br>
                      {'Lot qty. - ' + item?.third_lot_quantity}
                      <br></br>
                      {'status - ' + item?.third_lot_status}
                    </TableCell>
                    <TableCell align="center">{item?.remarks}</TableCell>
                    <TableCell align="center">
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('image');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('file');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setComplaintId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomerConcern(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="CUSTOMER COMPLAINT RECORD"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} contentType={contentType} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
