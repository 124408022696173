// for handling color change of obs based on remarks

export const handleChangeColorOnRemarks = (spec: any, remarks: string) => {
  if (remarks === 'under deviation' || remarks === 'tbc') {
    [0, 1, 2, 3, 5, 6, 10].forEach((cavity) => {
      spec[`check_${cavity}_colors`]?.forEach(
        (color: string, index: number) => {
          if (color === '#f7aeae') {
            spec[`check_${cavity}_colors`][index] = '#fbfbac';
          }
        },
      );
    });
  }
  if (
    remarks === 'ok' ||
    remarks === 'none' ||
    remarks === 'other' ||
    remarks === 'operation not done'
  ) {
    [0, 1, 2, 3, 5, 6, 10].forEach((cavity) => {
      spec[`check_${cavity}_colors`]?.forEach(
        (color: string, index: number) => {
          if (color === '#fbfbac') {
            spec[`check_${cavity}_colors`][index] = '#f7aeae';
          }
        },
      );
    });
  }
};
