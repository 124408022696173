import Delete from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { CustomIconButton } from '../../../components/common/button/CustomIconButton';
import useConfirm from '../../../components/common/useConfirm';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/create/TController';
const heading: any = {
  setup: 'SETUP REPORT',
  retro: 'RETRO REPORT',
  suspected: 'SUSPECTED REPORT',
};
const Create = () => {
  const navigate = useNavigate();
  const { partId, rowId, setupId } = useParams();
  const annual_month = useQuery('annual_date') || '';
  const documentType = useQuery('documentType') || '';
  const [DialogDelete, confirmDelete] = useConfirm(
    'Reset Document',
    'Are you sure you want to reset this?'
  );
  const [moduleData, setModuleData] = useState<any>({
    specs: [],
    rejectionDetails: [],
  });
  const isView = useQuery('isView') === 'true' ? true : false;

  useEffect(() => {
    if (setupId) {
      axios
        .get(`/api/ForMSetupRetroSuspectedRoute/${setupId}`, moduleData)
        .then((res) => {
          if (res.data) {
            setModuleData(res.data);
            if (res.data.part_id?._id !== partId) {
              axios
                .get(
                  `/api/forMSetupRetroSuspectedRoute/getProcessFromControlPlan/${partId}` // ?documentType=${documentType}
                )
                .then((res) => {
                  if (res.data) {
                    const response = res.data;
                    const setupObj: any = {
                      //header
                      category_of_changes: '',
                      suspected_lot_insp: false,
                      retroactive_check: false,
                      first_setup_approval: false,
                      planned_change: false,
                      unplanned_change: false,
                      abnormal_situations: false,
                      training: false,
                      heat_no: '',
                      batch_no: '',
                      change_4m_final_status: '',
                      change_4m_time: '',
                      termination_date: '',
                      part_id: partId,
                      part_name: response.part_id?.part_name,
                      part_number: response.part_id?.part_number,
                      customer_id: response.part_id?.customer_id,
                      customer_name:
                        response.part_id?.customer_id?.customer_name,
                      specs: [],
                      rejectionDetails: Array.from({ length: 2 }, () => ({
                        rejectionValues: [],
                      })),
                    };
                    response.processes.map((process: any) => {
                      process.specs.map((spec: any) => {
                        if (
                          documentType === 'setup' &&
                          spec.spec_to_setup === true
                        ) {
                          setupObj.specs.push({
                            parameter: spec.char_product || spec.char_process,
                            check4_symbol: spec.check4_symbol,
                            checkbox4_num: spec.checkbox4_num,
                            specification: spec.control_product,
                            methods: spec.methods_measure,
                            appearance: spec.appearance,
                            mean: spec.mean,
                            utolerance: spec.utolerance,
                            ltolerance: spec.ltolerance,
                            utl: spec.utl,
                            ltl: spec.ltl,
                            check_0_colors: Array.from(Array(10)),
                            check_0_values: Array.from(Array(10)),
                          });
                        } else if (
                          documentType === 'retro' &&
                          spec.spec_to_retro === true
                        ) {
                          setupObj.specs.push({
                            parameter: spec.char_product || spec.char_process,
                            check4_symbol: spec.check4_symbol,
                            checkbox4_num: spec.checkbox4_num,
                            specification: spec.control_product,
                            methods: spec.methods_measure,
                            appearance: spec.appearance,
                            mean: spec.mean,
                            utolerance: spec.utolerance,
                            ltolerance: spec.ltolerance,
                            utl: spec.utl,
                            ltl: spec.ltl,
                            check_0_colors: Array.from(Array(20)),
                            check_0_results: Array.from(Array(20)),
                            check_0_values: Array.from(Array(20)),
                          });
                        } else if (
                          documentType === 'suspected' &&
                          spec.spec_to_suspected === true
                        ) {
                          setupObj.specs.push({
                            parameter: spec.char_product || spec.char_process,
                            check4_symbol: spec.check4_symbol,
                            checkbox4_num: spec.checkbox4_num,
                            specification: spec.control_product,
                            methods: spec.methods_measure,
                            appearance: spec.appearance,
                            mean: spec.mean,
                            utolerance: spec.utolerance,
                            ltolerance: spec.ltolerance,
                            utl: spec.utl,
                            ltl: spec.ltl,
                            check_0_colors: Array.from(Array(10)),
                            check_0_results: Array.from(Array(10)),
                            check_0_values: Array.from(Array(10)),
                          });
                        }
                      });
                    });
                    setupObj._id = setupId;
                    setModuleData(setupObj);
                  }
                })
                .catch((err) => console.log(err));
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(
          `/api/forMSetupRetroSuspectedRoute/getProcessFromControlPlan/${partId}`
        )
        .then((res) => {
          if (res.data) {
            const response = res.data;
            const setupObj: any = {
              part_id: partId,
              part_name: response.part_id?.part_name,
              part_number: response.part_id?.part_number,
              customer_id: response.part_id?.customer_id,
              customer_name: response.part_id?.customer_id?.customer_name,
              specs: [],
              rejectionDetails: Array.from({ length: 2 }, () => ({
                rejectionValues: [],
              })),
            };
            response.processes.map((process: any) => {
              process.specs.map((spec: any) => {
                if (documentType === 'setup' && spec.spec_to_setup === true) {
                  setupObj.specs.push({
                    parameter: spec.char_product || spec.char_process,
                    check4_symbol: spec.check4_symbol,
                    checkbox4_num: spec.checkbox4_num,
                    specification: spec.control_product,
                    methods: spec.methods_measure,
                    appearance: spec.appearance,
                    mean: spec.mean,
                    utolerance: spec.utolerance,
                    ltolerance: spec.ltolerance,
                    utl: spec.utl,
                    ltl: spec.ltl,
                    check_0_colors: Array.from(Array(10)),
                    check_0_results: Array.from(Array(10)),
                    check_0_values: Array.from(Array(10)),
                  });
                } else if (
                  documentType === 'retro' &&
                  spec.spec_to_retro === true
                ) {
                  setupObj.specs.push({
                    parameter: spec.char_product || spec.char_process,
                    check4_symbol: spec.check4_symbol,
                    checkbox4_num: spec.checkbox4_num,
                    specification: spec.control_product,
                    methods: spec.methods_measure,
                    appearance: spec.appearance,
                    mean: spec.mean,
                    utolerance: spec.utolerance,
                    ltolerance: spec.ltolerance,
                    utl: spec.utl,
                    ltl: spec.ltl,
                    check_0_colors: Array.from(Array(20)),
                    check_0_results: Array.from(Array(20)),
                    check_0_values: Array.from(Array(20)),
                  });
                } else if (
                  documentType === 'suspected' &&
                  spec.spec_to_suspected === true
                ) {
                  setupObj.specs.push({
                    parameter: spec.char_product || spec.char_process,
                    check4_symbol: spec.check4_symbol,
                    checkbox4_num: spec.checkbox4_num,
                    specification: spec.control_product,
                    methods: spec.methods_measure,
                    appearance: spec.appearance,
                    mean: spec.mean,
                    utolerance: spec.utolerance,
                    ltolerance: spec.ltolerance,
                    utl: spec.utl,
                    ltl: spec.ltl,
                    check_0_colors: Array.from(Array(10)),
                    check_0_results: Array.from(Array(10)),
                    check_0_values: Array.from(Array(10)),
                  });
                }
              });
            });
            setModuleData(setupObj);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);
  const onSubmit = async () => {
    moduleData.part_id = partId;
    if (moduleData._id) {
      await axios
        .put(`/api/ForMSetupRetroSuspectedRoute/${moduleData._id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfully ');
          }
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.annual_month = annual_month;
      moduleData.summary_row_id = rowId;
      moduleData.document_type = documentType;
      await axios
        .post(
          `/api/ForMSetupRetroSuspectedRoute/${rowId}/${annual_month}?documentType=${documentType}`,
          moduleData
        )
        .then((res) => {
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    }
  };
  const deleteDocument = async () => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/ForMSetupRetroSuspectedRoute/${moduleData._id}`)
      .then((res) => {
        alert(' successfully');
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      <Box>
        <ChildHeader text={heading[documentType]}>
          {!isView && (
            <CustomButton
              color="blueButton"
              sx={{ margin: '10px' }}
              icon={<Delete />}
              disabled={false}
              size="large"
              onClick={deleteDocument}
            >
              RESET
            </CustomButton>
          )}
          {!isView && (
            <>
              <SubmitButton
                label={moduleData._id ? `UPDATE` : `SUBMIT`}
                onClick={() => onSubmit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          documentType,
        }}
      />
      <DialogDelete isSubmitContent={false} />
    </Box>
  );
};

export default Create;
