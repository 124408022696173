export const checklist_process = [
  {
    checkpoint: '1st of Month',
    cleaning_of_light: '',
    charging_of_light: '',
    wire_of_light: '',
    working_of_light: '',
    checking_by: '',
    inspected_by: '',
  },
  {
    checkpoint: '9th of Month',
    cleaning_of_light: '',
    charging_of_light: '',
    wire_of_light: '',
    working_of_light: '',
    checking_by: '',
    inspected_by: '',
  },
  {
    checkpoint: '17th on Month',
    cleaning_of_light: '',
    charging_of_light: '',
    wire_of_light: '',
    working_of_light: '',
    checking_by: '',
    inspected_by: '',
  },
  {
    checkpoint: '25th on Month',
    cleaning_of_light: '',
    charging_of_light: '',
    wire_of_light: '',
    working_of_light: '',
    checking_by: '',
    inspected_by: '',
  },
];
