import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    id: 1,

    freq: 'Gloves',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 2,

    freq: 'Apron',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 3,

    freq: 'Helmet',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 4,

    freq: 'Shoes',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 5,

    freq: 'Goggles',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 6,

    freq: 'Ear plug',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 7,
    freq: 'Verify By :',
    data_type: 'footer',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
  {
    id: 8,
    freq: '',
    shift_A_results: Array(31).fill(''),
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  department_id: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyBin_date: moment(),
  machine_id: null,
  processes: lodash.cloneDeep(processes),
};
