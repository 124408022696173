export const last_audit_nc_verification = [
  {
    s_no: 1,
    auditor_parameter: 'Last audit NC verification',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
];

export const management_of_quality = [
  {
    s_no: 'A',
    auditor_parameter: 'Do they have any Quality Management system in place',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'B',
    auditor_parameter:
      'Are they preparing for any Quality system Certification',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'C',
    auditor_parameter: 'Do they have Company Orgnization structure',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'D',
    auditor_parameter: 'Do they take actions for Any Non Conformance',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'E',
    auditor_parameter: 'Do conducts Internal Audit at planned Intervals.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'F',
    auditor_parameter:
      "Do They counduct follow-up Audit to close the NC'S found  during Internal audits",
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'G',
    auditor_parameter:
      'Do The have any  Training schedule and are the Training Effective.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
];

export const quality_planning_and_change_control = [
  {
    s_no: 'A',
    auditor_parameter:
      'Are the relevant current / pertinent  Drawing &  Customer inspection standard.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'B',
    auditor_parameter:
      'Do they have any ECN Implimentation in place and is the Effective.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'C',
    auditor_parameter:
      'Do they have any proper documentation system ? For this:-',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'i',
    auditor_parameter: 'Available of Control pln',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'ii',
    auditor_parameter:
      'Available of work instructions (Job setup instructions, W.I. for special process, safety and material handling instructions, Inspection check sheet ETC.)',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'iii',
    auditor_parameter: 'Do they have all the requierd documents in place.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'D',
    auditor_parameter:
      'Do they inform about any Major process change to the customer ',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'E',
    auditor_parameter: 'Do they follow the PPAP /PSW submisson system.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
];

export const control_of_purchase_material = [
  {
    s_no: 'A',
    auditor_parameter:
      'Do the have any  System for their Supplier Quality Management system. (Supplier audit plan)',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'B',
    auditor_parameter:
      'Do they have procure material from approved suppliers ?  Do they have Approved list of supplier.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'C',
    auditor_parameter:
      'Do they conduct Incoming / Raw material Inspection ? Do they maintain record of the Same.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'D',
    auditor_parameter: 'Do they have material test reports ?',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'E',
    auditor_parameter: 'Have they maintained proper identification status.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'F',
    auditor_parameter:
      'Is FIFO followed at receiving and Finished goods store.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'G',
    auditor_parameter: 'Do they conduct Revalidation record availble.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
];

export const control_during_processing = [
  {
    s_no: 'A',
    auditor_parameter: 'Do they carry out the work as per the control plan.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'B',
    auditor_parameter:
      'Are the First pcs & Last pcs Reports made and followed before starting and ending the production.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'C',
    auditor_parameter:
      'Have they identified Critical charecterstics in their work instructions.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'D',
    auditor_parameter: 'Are all  the processes / stages identified properly.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'E',
    auditor_parameter:
      'Are the relevant Work instructions displayed on the lines.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'F',
    auditor_parameter:
      'Limit samples availability for apperance as agreed mutually',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'G',
    auditor_parameter: 'Operator Awerness about customer problam',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'H',
    auditor_parameter: 'Mould storage & Identification ',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'I',
    auditor_parameter:
      'Do they have any maintenance schedue and do they adhere to it.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'J',
    auditor_parameter:
      'Do they carryout the prevantive maintenance activities.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'K',
    auditor_parameter: 'Have the employed traind operators on critical stages.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'L',
    auditor_parameter:
      'Do they have any system for the maintenance of Production Tools, Dies, Fixtures.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'M',
    auditor_parameter: 'Do they have Operators Skill matrix',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
];

export const control_of_finished_product = [
  {
    s_no: 'A',
    auditor_parameter:
      'Check child parts Traceability with respect to RM and Production date.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'B',
    auditor_parameter:
      'Do They carry out "Final Inspection" and do the maintain the records of the same.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'C',
    auditor_parameter:
      'Do they have " Packing Standard" & Do they provide material as per decided norms.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'D',
    auditor_parameter:
      'Is material packaging proper to prevent damage or deterioration of product quality. ',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
];

export const control_of_nonconforming_parts_and_corrective_preventive_actions =
  [
    {
      s_no: 'A',
      auditor_parameter:
        'Do the attend customer complaints within 15th days and do they take necessary actions to rectify the problams.',
      score: [null, null, null],
      obs: '',
      remarks: '',
    },
    {
      s_no: 'B',
      auditor_parameter:
        'Do they have identified Customer complaints record available.',
      score: [null, null, null],
      obs: '',
      remarks: '',
    },
    {
      s_no: 'C',
      auditor_parameter:
        'Do they carryout the "Root Cause Analysis" for any found Rejection.',
      score: [null, null, null],
      obs: '',
      remarks: '',
    },
    {
      s_no: 'D',
      auditor_parameter:
        'Are The corrective actions emplimented properly effectivness verified .',
      score: [null, null, null],
      obs: '',
      remarks: '',
    },
    {
      s_no: 'E',
      auditor_parameter:
        'Are non-conferming product reviewed and there disposition decided.',
      score: [null, null, null],
      obs: '',
      remarks: '',
    },
    {
      s_no: 'F',
      auditor_parameter: 'Are customer problams communicated to operators.',
      score: [null, null, null],
      obs: '',
      remarks: '',
    },
  ];
export const control_of_measuring_and_test_equipment = [
  {
    s_no: 'A',
    auditor_parameter:
      'Do they have any schedule for Calibration? And do they adhere to the calibration schedule.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'B',
    auditor_parameter: 'Are all existing instruments properly calibrated',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'C',
    auditor_parameter: 'Check gauge drg /tool drgs /jig /fixturedrgs  and its',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'D',
    auditor_parameter: 'Are all the instruments handled safely.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
];
export const control_of_5s_management = [
  {
    s_no: 'A',
    auditor_parameter:
      'Storage of RM, BOP, WIP, FG and bins/trolleys to prevent from dust, rust, scratch, deformation and rainwater (Shop floor audit)',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'B',
    auditor_parameter:
      'Storage of RM, BOP, WIP & FG parts at designated location with tag for visual control ',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'C',
    auditor_parameter:
      'Regular check of Pokayoke and their record List of Poka Yoke',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
  {
    s_no: 'D',
    auditor_parameter:
      'Do they have Rework area/table with responsibility & Record available.',
    score: [null, null, null],
    obs: '',
    remarks: '',
  },
];
