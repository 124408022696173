import moment from 'moment';
import { useState } from 'react';
import { FlexBox, TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../../hooks/UseQuery';
import { CenteredLogoImg } from '../../../../../../components/ui/TableCompHead';
import { images, textInputs } from '../../../../../../utils/constants/constant';
import InputMuiCustom from '../../../../../../components/common/inputs/InputMuiCustom';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const THead = ({ moduleData, isView }: IProps) => {
  // const { checkListMachineData } = moduleData;
  const [render, setRender] = useState(false);
  // const date = new Date(moduleData?.date_range)
  //   ?.getFullYear()
  //   .toString()
  //   .slice(-2);
  // const yearRange = getYearRange(new Date(moduleData?.date_range));

  return (
    // <thead>
    //   {/* <TableCompHead
    //     colSpan={13 + createConstant.extracolSpan}
    //     rowSpan={3}
    //     labels={['Doc No', 'Rev', 'Dt']}
    //     values={['FM/XLV/MT/022', '00', '01.06.2022']}
    //     heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
    //   />

    //   {['S. No.', 'Item'].map((el, index) => (
    //     <th
    //       key={`${index}_head`}
    //       align="center"
    //       style={{
    //         textAlign: 'center',
    //         ...style1,
    //         minWidth: `${
    //           (el === 'INSTRUMENTS/GAUGE' && '250px') ||
    //           (el === 'S. No.' && '50px') ||
    //           style1.minWidth
    //         }`,
    //       }}
    //     >
    //       {el}
    //     </th>
    //   ))}

    //   {[
    //     'APR',
    //     'MAY',
    //     'JUN',
    //     'JUL',
    //     'AUG',
    //     'SEP',
    //     'OCT',
    //     'NOV',
    //     'DEC',
    //     'JAN',
    //     'FEB',
    //     'MAR',
    //   ].map((el, index) => (
    //     <th
    //       key={`${index}_headcol`}
    //       align="center"
    //       style={{ textAlign: 'center', ...style2 }}
    //     >
    //       {el}-{index > 8 ? +date + 1 : date}
    //     </th>
    //   ))}
    //   <th style={{ textAlign: 'center', ...style1 }}>Average</th>
    //   <th style={{ textAlign: 'center', ...style1 }}>Remarks</th> */}
    // </thead>
    <thead>
      <TableCompHead
        colSpan={8 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['FM/XLV/MT/008', '00', '01.06.2022']}
        heading={`${createConstant.theadHeader}`}
      />
      {/* <tr>
        <th
          data-img={images.companyLogo}
          colSpan={6}
          rowSpan={2}
          align="left"
          style={{
            border: '1px solid black',
            fontSize: '1.6rem',
            textAlign: 'center',
          }}
        >
          <CenteredLogoImg>
            <img
              src={images.companyLogo}
              alt={textInputs.companyName}
              height="46"
              width="120"
            />
            <span>PREVENTIVE MAINTENANCE CHECKLIST</span>
          </CenteredLogoImg>
        </th>
        <th align="left" colSpan={2}>
          Doc.No.:-FM/XLV/MT/008
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          FOR THE MONTH :
        </th>
      </tr> */}
      <tr>
        <th align="left" colSpan={3}>
          Machine Name :- {moduleData.machine_name}
        </th>
        <th align="left" rowSpan={2} colSpan={5}>
          SCHEDULE : MONTHLY (M) / QUATERLY (Q) / HALF YEARLY (H) / ANNUALY (A)
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={3}>
          Machine Name :- {moduleData.machine_no}
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={3}>
          Dept./Section :-
          <TableInput
            name="department"
            type="text"
            value={moduleData.department}
            isView={isView}
            onChange={(e) => {
              moduleData.department = e.target.value;
              setRender((prev) => !prev);
            }}
            style={{ width: '120px' }}
          />
        </th>
        <th align="left" colSpan={4}>
          Schedule :
          <TableInput
            name="schedule"
            type="text"
            value={moduleData.schedule}
            isView={isView}
            onChange={(e) => {
              moduleData.schedule = e.target.value;
              setRender((prev) => !prev);
            }}
            style={{ width: '120px' }}
          />
        </th>
        <th align="left" colSpan={1}>
          Date:-
          {/* {isView ? (
            formatDate(moduleData.last_maintenance_date)
          ) : (
            <FlexBox style={{ gap: '5px' }}> */}
          <DatePickerMui
            label=""
            value={moment(moduleData.last_maintenance_date || null)}
            onChange={(date) => {
              moduleData.last_maintenance_date = date;
              setRender((prev) => !prev);
            }}
          />
          {/* <DatePickerMui
            // name="last_maintenance_date"
            // format="dd/MM/yyyy"

            // style={{
            //   backgroundColor: '#f8f9fa',
            // }}
            value={moduleData.last_maintenance_date || null}
            onChange={(date: any) => {
              moduleData.last_maintenance_date = date;
              setRender((prev) => !prev);
            }}
          /> */}
          {/* </FlexBox> */}
          {/* )} */}
        </th>
      </tr>
      <tr>
        <th rowSpan={2} style={{ width: '100px' }}>
          S NO.
        </th>
        <th rowSpan={2} style={{ width: '550px' }}>
          CHECK POINTS
        </th>
        <th colSpan={4}>FREQUENCY OF MAINT.</th>
        <th> OBSERVATIONS</th>
        <th rowSpan={2} style={{ width: '20px' }}>
          REMARKS
        </th>
      </tr>
      <tr>
        <th style={{ width: '60px' }}>M</th>
        <th style={{ width: '60px' }}>H</th>
        <th style={{ width: '60px' }}>Q</th>
        <th style={{ width: '60px' }}>A</th>
        <th></th>
      </tr>
    </thead>
  );
};

export default THead;
