import { useState } from 'react';
import TableInput from '../../../../../components/common/TableInput';
import GridInputs from '../../../../../components/common/inputs/GridInputs';
import { createConstant } from '../../constant';

interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th colSpan={createConstant.extracolSpan} rowSpan={2}>
          LEGENDS
        </th>
        <th rowSpan={2}>PLAN</th>
        <th rowSpan={1} style={{ background: '#ffd43b' }}>
          P
        </th>
        <th colSpan={2} rowSpan={2}>
          DONE
        </th>
        <th rowSpan={1} style={{ background: '#ffd43b' }}>
          P
        </th>
        <th rowSpan={2}></th>
        <th colSpan={2} rowSpan={2}>
          RE-PLAN
        </th>
        <th rowSpan={1} style={{ background: '#00B0F0' }}>
          P
        </th>
        <th rowSpan={2}></th>
        <th colSpan={2} rowSpan={2}>
          RE-PLAN DONE
        </th>
        <th rowSpan={1} style={{ background: '#00B0F0' }}>
          P
        </th>
      </tr>
      <tr>
        <th rowSpan={1}></th>
        <th rowSpan={1} style={{ background: '#00B050' }}>
          A
        </th>
        <th colSpan={2} rowSpan={1}></th>
        <th rowSpan={1} style={{ background: '#00B050' }}>
          A
        </th>
      </tr>

      <tr>
        <th colSpan={(14 + createConstant.extracolSpan) / 2} align="left">
          Prepared By:
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="remarks"
            type="text"
            value={moduleData?.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={(14 + createConstant.extracolSpan) / 2} align="left">
          Approved By:
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="remarks"
            type="text"
            value={moduleData?.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
