/** ----- object type should be passed!!! ---------------  
 we have an array of array object. Multidimentional
 First layer for the rows  [[] []] its has 2 TableRow
 Inside each array we have ojects [{},{},{}] for the TableCell
 * */

type TableHeaderObj = {
  text: string;
  component?: any;
  rowspan?: number;
  colspan?: number;
  style?: React.CSSProperties;
};

const TableHeaderHtmlCustom = ({
  tableHeaderObj,
  customStyle,
}: {
  tableHeaderObj: TableHeaderObj[][];
  customStyle?: React.CSSProperties;
}) => {
  return (
    <>
      <>
        {tableHeaderObj.map((row, index) => (
          <tr
            style={{
              padding: '10px',
              width: '100%',
              ...customStyle,
            }}
            key={`row-${index}`}
          >
            {row.map((col, colIdx) => (
              <th
                key={`col-${index}-${colIdx}`}
                align="center"
                style={{ ...col?.style }}
                rowSpan={col?.rowspan || 1}
                colSpan={col?.colspan || 1}
              >
                {col.text}
                {col?.component && col.component}
              </th>
            ))}
          </tr>
        ))}
      </>
    </>
  );
};

export default TableHeaderHtmlCustom;
