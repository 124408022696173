import React, { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';

import { PersitedDatePicker, TableCustom } from '../../../components/common';

import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { useNavigate } from 'react-router-dom';
import formatDate from '../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import { useAuth } from '../../../services/auth/AuthProvider';
import AdminTable from './components/tablelist/AdminTable';
import SupervisorTable from './components/tablelist/SupervisorTable';

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'DIE NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'REPORT DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];
const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};
const List = () => {
  const { state } = useAuth();
  const { user, IS } = state;

  const date = useQuery('date');
  const [render, setRender] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this document?'
  );

  useEffect(() => {
    let userType = '';
    user?.userType === IS.Admin
      ? (userType = 'admin')
      : (userType = 'supervisor');

    axios
      .get(`/api/lastShotReportRoute/monthly/${date}`)
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [date, render]);

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`/api/lastShotReportRoute/${id}`)
      .then(() => {
        alert('document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  };

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/lastShotReportRoute/updateStatus/${id}`, {
          is_admin_request_no: tableData[documentIndex].is_admin_request_no,
          report_approved_by: user?.name,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => {
    const values = [...tableData];
    values[documentIndex].is_admin_request = null;
    values[documentIndex].is_admin_request_no = +e.target.value;
    setTableData(values);
  };

  return (
    <>
      <ChildHeader text="LAST SHOT REPORT">
        <AddButton
          onClick={() => navigate(`/common/lastShotReportCreate`)}
          label="NEW REPORT"
        />
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      {/* <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">
                  {document?.tool_id?.die_name}
                </TableCell>
                <TableCell align="center">
                  {formatDate(document.report_date)}
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/lastShotReportCreate/${document._id}?isView=true`
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/lastShotReportCreate/${document._id}?isView=false`
                      )
                    }
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom> */}
      {user?.userType === IS.Admin && (
        <AdminTable
          {...{
            handleChangeStatus,
            deleteDocument,
            updateStatus,
            statusObj,
            tableData,
          }}
        />
      )}
      {user?.userType === IS.Supervisor && (
        <SupervisorTable {...{ deleteDocument, tableData }} />
      )}
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
