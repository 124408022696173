import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../../components/ui';
import { moduleConstant } from '../constant';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};

interface IProps {
  isView: boolean;
}

const THead = ({ isView }: IProps) => {
  const tableHeaderObj = [
    [
      { text: 'S.No.', style: style1, rowspan: 2 },
      { text: 'DATE', style: style1, rowspan: 2 },
      { text: 'DIE NAME', style: style1, rowspan: 2 },
      { text: 'DIE NO.', style: style1, rowspan: 2 },
      { text: 'MACHINE NO.', style: style1, rowspan: 2 },
      { text: 'PROBLEM', style: style1, rowspan: 2 },
      { text: 'Action', style: style1, rowspan: 2 },
      { text: 'DOWN TIME', style: style1, colspan: 1 },
      { text: 'DELETE', style: style1, rowspan: 2 },
    ],
  ];

  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={3}
        labels={['Doc. No.', 'Rev. No.', 'Eff.Dated']}
        values={['F-PROD-33A', '01', '01. 10. 2019']}
        heading={`${moduleConstant?.heading}`}
      />
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
