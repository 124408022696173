import {
  AiFillLayout,
  AiOutlineDeliveredProcedure,
  AiOutlinePartition,
} from 'react-icons/ai';
import { FaTools, FaUserCog } from 'react-icons/fa';
import { TbBuildingWarehouse } from 'react-icons/tb';
import { GrVmMaintenance } from 'react-icons/gr';
import { TbAspectRatio } from 'react-icons/tb';
import { GoTools } from 'react-icons/go';
import {
  MdOutlineReportProblem,
  MdReceiptLong,
  MdTrolley,
} from 'react-icons/md';
import { PiBriefcaseMetalDuotone } from 'react-icons/pi';
import { FaGaugeHigh } from 'react-icons/fa6';

const tiles = [
  {
    sNo: 1,
    link: '/common/equipment_inspection_listOfProcedures/?documentType=equipment_inspection&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/masterlistofmachine?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlinePartition size="5rem" color="#135089" />,
    p: 'Master List Of',
    span: 'Machine',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/manMachineMatrixList?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <FaUserCog size="5rem" color="#135089" />,
    p: 'Man Machine',
    span: 'Matrix',
    isIcon: true,
  },
  // {
  //   sNo: 5,
  //   link: '/common/maintenance_breakdown',
  //   isLink: true,
  //   clauseNo: 10,
  //   icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
  //   p: 'Maintenance',
  //   span: 'Module',
  //   isIcon: true,
  // },
  // {
  //   sNo: 7,
  //   link: '/common/annualPmPlanRotorCastingTiles?filter=createdAt',
  //   isLink: true,
  //   clauseNo: 4,
  //   icon: <GrVmMaintenance size="5rem" color="#343a40" />,
  //   p: ' Annual PM',
  //   span: 'Plan',
  //   isIcon: true,
  // },
  {
    sNo: 8,
    link: '/common/masterListOfCriticalSparePart?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Critical Spare Parts',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/maintenanceLog',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'Maintenance',
    span: 'Log',
    isIcon: true,
  },
  {
    sNo: 10,
    link: '/common/appearanceplanMasterlist',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'Master list of',
    span: 'Utilities',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/annualPmPlan_Tiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#343a40" />,
    p: ' Annual PM',
    span: 'Plan New',
    isIcon: true,
  },
  {
    sNo: 11,
    link: '/common/annualPmPlanUtilityTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#343a40" />,
    p: ' Annual PM',
    span: 'Plan (Utility)',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/annualPmPlanNewUtility_Tiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#343a40" />,
    p: ' Annual PM',
    span: 'Plan New (Utility)',
    isIcon: true,
  },
  // {
  //   sNo: 11,
  //   link: '/common/appearanceplanMasterlist',
  //   isLink: true,
  //   icon: <TbAspectRatio size="5rem" color="#343a40" />,
  //   p: 'Master list of',
  //   span: 'Appearance',
  //   isIcon: true,
  // },
  {
    sNo: 1,
    link: '/common/annualPmDieCastingPlanTiles?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiFillLayout size="5rem" color="#1864ab" />,
    p: 'Annual Pm Die Casting',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 13,
    link: '/common/toolRoomTiles',
    isLink: true,
    clauseNo: 10,
    icon: <FaTools size="5rem" color="#1864ab" />,
    p: 'Tool',
    span: 'Room',
    isIcon: true,
  },
  {
    sNo: 13,
    link: '/common/masterListOfProblemsTiles',
    isLink: true,
    clauseNo: 10,
    icon: <MdOutlineReportProblem size="5rem" color="#1864ab" />,
    p: 'Master List Of',
    span: 'Problems',
    isIcon: true,
  },
  {
    sNo: 10,
    link: '/common/breakDownRecordsList',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'BREAKDOWN',
    span: 'RECORDS',
    isIcon: true,
  },
  {
    sNo: 10,
    link: '/common/monthlyBreakDownRecordsDashboard',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'BREAKDOWN RECORDS',
    span: 'DASHBOARD',
    isIcon: true,
  },

  {
    sNo: 3,
    link: '/common/masterlistofToolsOrDie?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <GoTools size="5rem" color="#343a40" />,
    p: 'Master List Of',
    span: 'Tools Or Die',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/masterListofTrolly?filter=createdAt',
    isLink: true,
    icon: <MdTrolley size="5rem" color="#a12222" />,
    p: 'Master List of',
    span: 'Trolley',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/masterListofmetallicBin?filter=createdAt',
    isLink: true,
    icon: <PiBriefcaseMetalDuotone size="5rem" color="#a12222" />,
    p: 'Master List of',
    span: 'Metallic Bin',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/annualPMPlanMetallicBinTiles',
    isLink: true,
    icon: <GrVmMaintenance size="5rem" color="#087f5b" />,
    p: 'Annual PM (METALLIC BIN)',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/annualPMPlanTrollyTiles',
    isLink: true,
    icon: <GrVmMaintenance size="5rem" color="#087f5b" />,
    p: 'Annual PM (Trolly)',
    span: 'Plan',
    isIcon: true,
  },

  {
    sNo: 1,
    link: '/common/pressureGauge?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <FaGaugeHigh size="5rem" color="#135089" />,
    p: 'Pressure Gauge',
    span: '',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/breakDownSlipList',
    isLink: true,
    icon: <MdReceiptLong size="5rem" color="#a12222" />,
    p: 'BREAK DOWN',
    span: 'SLIP',
    isIcon: true,
  },
];

export default tiles;
