import { useState } from 'react';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';
import useConfirm from '../../../../../components/common/useConfirm';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  allmonth: any;
  setTBodyRender: any;
}
const TBodyRight = ({
  item,
  isView,
  index,
  allmonth,
  setTBodyRender,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogisCheck, setDialogisCheck] = useConfirm(
    'ADD',
    'Are you sure you want to Change Plan?'
  );
  const [DialogRemoveCheck, setDialogRemoveCheck] = useConfirm(
    'Remove',
    'Are you sure you want remove you cant roll back?'
  );

  //  1 double click -> plan 2 | click double -> replan | 3 double click -> nothing
  const stateTransitions: any = {
    // [current p, current r] => [next p, next r]
    'false,false': { p: true, r: false }, //first p
    'true,false': { p: false, r: true }, //second R
    'false,true': { p: false, r: false }, //third remove
  };

  const handleDoubleClick = async (event: any, month: string) => {
    if (event.detail === 2) {
      const ans = await setDialogisCheck();
      if (!ans) return;
      const currentStateKey = `${item[month].p},${item[month].r}`;
      const nextState = stateTransitions[currentStateKey];

      if (nextState) {
        item[month].p = nextState.p;
        item[month].r = nextState.r;
        item[month].created_date = null;
        setTBodyRender((prev: any) => !prev);
      }
    }
  };

  return (
    <>
      {allmonth.map((month: string) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
          }}
          onClick={(e) => handleDoubleClick(e, month)}
        >
          <InteractiveIcon
            isYellow={item[month].p}
            isBlue={item[month].r}
            width={'100%'}
          >
            {item[month].p && 'P'}
            {item[month].r && 'R'}
          </InteractiveIcon>
        </td>
      ))}
      <DialogisCheck isSubmitContent={false} />
      <DialogRemoveCheck isSubmitContent={false} />
    </>
  );
};

export default TBodyRight;
