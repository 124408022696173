import moment from 'moment';

export const processes = [
  {
    date_audit: '',
    proc_audit: '',
    auditor: '',
    auditee: '',
    // to_be_verified: '',
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  no_of_rows: 5,
  //is_admin_request_no: null,
  //is_admin_request: null,
  //report_prepared_by: null,
  //report_approved_by: null,
  //moduleData_Date: moment(),
  auditor_sign:'',
  sign1:'',
  sign2:'',
  auditee_sign:'',
  details_of_nc: '<h1></h1>',
  root_cause: '<h1></h1>',
  corr_action: '<h1></h1>',
  target_date:moment(),
  verif: '<h1></h1>',
  remarks: '<h1></h1>',
  // verif: '',
  // remarks: '',
  nc_minutes:'<table style="width: 1392px"><colgroup><col style="width: 58px"><col style="width: 223px"><col style="width: 283px"><col style="width: 258px"><col style="width: 221px"><col style="width: 177px"><col style="width: 172px"></colgroup><tbody><tr><th colspan="1" rowspan="1" colwidth="58"><p>S.NO.</p></th><th colspan="1" rowspan="1" colwidth="223"><p>DATE OF AUDIT</p></th><th colspan="1" rowspan="1" colwidth="283"><p>PROCESS AUDITED  </p></th><th colspan="1" rowspan="1" colwidth="258"><p>AUDITOR</p></th><th colspan="1" rowspan="1" colwidth="221"><p>AUDITEE  </p></th><th colspan="1" rowspan="1" colwidth="172"><p>ANY OTHER REMARKS</p></th></tr><tr><td colspan="1" rowspan="1" colwidth="58"><p></p></td><td colspan="1" rowspan="1" colwidth="223"><p></p></td><td colspan="1" rowspan="1" colwidth="283"><p></p></td><td colspan="1" rowspan="1" colwidth="258"><p></p></td><td colspan="1" rowspan="1" colwidth="221"><p></p></td><td colspan="1" rowspan="1" colwidth="177"><p></p></td></tr></tbody></table>',
  module_date: moment(),
  mrm_date: null,
  processes: Array.from({ length: 5 }, () => ({ ...processes[0] })),
};
