import axios from 'axios';
import { useState, useEffect } from 'react';
import lodash from 'lodash';
import { reportData } from './reportData';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    report_date: new Date(),
    list: lodash.cloneDeep(reportData),
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/lastShotReportRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/api/lastShotReportRoute/reportNo`)
        .then((res) => {
          setModuleData((prev: any) => {
            return {
              ...prev,
              report_no: res?.data?.newReportNumber,
            };
          });
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);
  return {
    moduleData,
    isLoading,
    error,
  };
};

export default useGetData;
