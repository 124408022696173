import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
}

const TController = ({ moduleData }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData }} />
        <TBody {...{ moduleData }} />
        {/* <TFoot /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
