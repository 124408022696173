import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot style={{ background: '#a9e34b' }}>
      <tr>
        <th colSpan={7}>
          Note:- if Operator not able the do work as per the standard ,then
          fail. Give immediate training to that Operator and Operator Observance
          to be done again after 3 days and within one week.
        </th>
      </tr>
      <tr>
        <th>Checked by.</th>
        <th colSpan={2}></th>
        <th>Signature of Production Head</th>
        <th colSpan={3}></th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
