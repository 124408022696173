import React, { useState } from 'react';
import { TableRowComponent } from './TableRowComponent';

interface IProps {
  moduleData: any;
  handleDelete: any;
  updateModuleData: any;
  machineData?: any;
}
const TBody = ({ handleDelete, moduleData, updateModuleData }: IProps) => {
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            updateModuleData,
            handleDelete,
          }}
        />
      ))}
      <tr>
        <th align="right" colSpan={4}>
          TOTAL MARKS
        </th>
        <th></th>
        <th>{moduleData?.total_max_marks}</th>
        <th></th>
      </tr>
      <tr>
        <th align="right" colSpan={4}>
          PERCENTAGE
        </th>
        <th colSpan={2}></th>
        <th></th>
      </tr>
    </tbody>
  );
};

export default React.memo(TBody);
