import { Box } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { FlexBox, PersitedDatePicker } from '../../components/common';
import { AddButton } from '../../components/common/button/CustomButtons';
import ModalCustom from '../../components/common/Modal';
import { SupervisorPartTable } from '../../components/ui';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import AdminTable from './components/tablelist/AdminTable';
import SupervisorTable from './components/tablelist/SupervisorTable';
import DatePickerMui from '../../components/common/DatePickerMui';
import moment from 'moment';
import { SearchBar } from '../../components/common/SearchBar';
import useDebounce from '../../hooks/useDebounce';

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

const TableList = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [day, setDay] = useState<any>(null);
  const [maxMinDate, setMaxMinDate] = useState<any>(null);
  const { user } = state;
  const search = useQuery('search');
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const page = useQuery('page') || 1;

  const fetchData = async () => {
    // console.log(search, date);
    if (!search && !date && !day) return;
    let userType = '';
    if (state.user?.userType === 2) {
      userType = 'admin';
    } else {
      userType = 'supervisor';
    }
    axios
      .get(
        `/api/lineInspectionRoute/monthly/${userType}/${date}?day=${day}&page=${page}&search=${search}&perPage=${perPage}`
      )
      .then((res) => {
        if (res.data) setTableData(res.data);
        setPerPage(res?.data?.perPage);
        setTotalPages(res?.data?.pages);
      })
      .catch((err) => console.log(err));
  };
  useDebounce(fetchData, 500, [page, search, render, date, day]);

  useEffect(() => {
    if (!date) return;
    setMaxMinDate({
      year: new Date(date).getFullYear(),
      month: new Date(date).getMonth() + 1,
      maxDay: moment(date).daysInMonth(),
    });
  }, [date]);

  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/lineInspectionRoute/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/lineInspectionRoute/updateStatus/${id}`, {
          is_admin_request_no: tableData[documentIndex].is_admin_request_no,
          report_approved_by: state.user?.name,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => {
    const values = [...tableData];
    values[documentIndex].is_admin_request = null;
    values[documentIndex].is_admin_request_no = e.target.value;
    setTableData(values);
  };
  return (
    <Box>
      <ChildHeader text="line inspection">
        <AddButton
          label="New Line Inspection"
          onClick={() => setIsOpenPopup(true)}
        />
      </ChildHeader>
      <Box
        sx={{
          margin: 2,
          display: 'flex',
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
        <SearchBar label="Search" sx={{ width: '100%' }} />
        <DatePickerMui
          minDate={moment(
            new Date(`${maxMinDate?.month}/01/${maxMinDate?.year}`)
          )}
          maxDate={moment(
            new Date(
              `${maxMinDate?.month}/${maxMinDate?.maxDay}/${maxMinDate?.year}`
            )
          )}
          label="Search By Day"
          onChange={(edate) => {
            setDay(edate?._d || null);
          }}
          value={moment(day) || null}
        />
      </Box>
      {state.user?.userType === 2 && (
        <AdminTable
          {...{
            handleChangeStatus,
            updateStatus,
            deleteDocument,
            statusObj,
            tableData,
          }}
        />
      )}
      {state.user?.userType === 3 && (
        <SupervisorTable {...{ deleteDocument, tableData }} />
      )}
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <SupervisorPartTable
          link={
            user?.userType === 2
              ? '/admin/lineInspection'
              : '/supervisor/lineInspection'
          }
        />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
