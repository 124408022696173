import axios from 'axios';
import { useState, useEffect } from 'react';

import { useQuery } from '../../../../hooks/UseQuery';

import { moduleConstant } from '../constant';

const useGetData = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>({
    month: date && new Date(date).getMonth() + 1,
    year: date && new Date(date).getFullYear(),
    processes: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [parentRender, setParentRender] = useState(false);
  const [renderforUpdate, setRenderforUpdate] = useState(false);
  const [departmentData, setDepartmentData] = useState([{}]);
  useEffect(() => {
    axios
      .get('/api/department/department/departmentForAutoComplete')
      .then((res) => {
        setDepartmentData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      axios
        .get(
          `${moduleConstant.apiConstant}/${date}?departmentId=${moduleData.department_id?._id}`
        )
        .then((response) => {
          setModuleData(response?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setModuleData({
            month: date && new Date(date).getMonth() + 1,
            year: date && new Date(date).getFullYear(),
            processes: [],
            department_id: moduleData.department_id,
          });
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date, renderforUpdate]);

  return {
    error,
    moduleData,
    setModuleData,
    isLoading,
    setParentRender,
    setRenderforUpdate,
    departmentData,
  };
};

export default useGetData;
