import React, { useState, useEffect } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
  TextAreaInput,
} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';
import { convToTime } from '../../../utils/helpers';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'Date', rowspan: 1, colspan: 1 },
    { text: 'AREA', rowspan: 1, colspan: 1 },
    { text: 'MACHINE', rowspan: 1, colspan: 1 },
    { text: 'ASSY', rowspan: 1, colspan: 1 },
    { text: 'PROBLEM', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
    { text: 'B/D Start time', rowspan: 1, colspan: 1 },
    { text: 'B/D End time', rowspan: 1, colspan: 1 },
    { text: 'DOWN TIME(min)', rowspan: 1, colspan: 1 },
    { text: 'DOWN TIME(hrs)', rowspan: 1, colspan: 1 },
    { text: 'remarks', rowspan: 1, colspan: 1 },
    { text: 'DEFECT PHOTO', rowspan: 1, colspan: 1 },
    { text: 'SUPPORTING DOCUMENTS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const List = () => {
  const date = useQuery('date');
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  // const [perPage, setPerPage] = useState<number>(10);
  // const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [contentType, setContentType] = useState('');
  // const [filterMachine, setFilterMachine] = useState<any>([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const machine = useQuery('machine');

  const [all, setAll] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    axios
      .get(`/api/breakdownRecords/monthly/${date}?all=${all}`)
      .then((res) => {
        if (res.data) setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/breakdownRecords/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    // search,
    render,
    isModal,
    // filter,
    // machine,
    all,
    date,
  ]);
  return (
    <>
      <ChildHeader text="BREAKDOWN RECORDS">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton
          onClick={() => setIsModal(true)}
          label="NEW BREAKDOWN RECORD"
        />
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center" style={{ minWidth: '150px' }}>
                      {item.issue_reported_date &&
                        formatDate(item.issue_reported_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.machine_id?.department_id?.department_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.machine_id?.machine_no}
                    </TableCell>
                    <TableCell align="center">{item?.assy}</TableCell>
                    <TableCell align="center">
                      {item?.problem_id?.problem}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: '250px' }}>
                      <TextAreaInput value={item.action_taken} />
                    </TableCell>
                    <TableCell align="center">
                      {convToTime(item?.bd_start_time)}
                    </TableCell>
                    <TableCell align="center">
                      {convToTime(item?.bd_end_time)}
                    </TableCell>
                    <TableCell align="center">{item?.down_time_mins}</TableCell>
                    <TableCell align="center">
                      {item?.down_time_hrs?.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">{item?.remarks}</TableCell>
                    <TableCell align="center">
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('image');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('file');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setComplaintId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomerConcern(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      {/* <CustomPagination totalPage={totalPages} /> */}
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="BREAKDOWN RECORD"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} contentType={contentType} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
