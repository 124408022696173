interface Ifoot {
  apiData: any;
}
const TFoot = ({ apiData }: Ifoot) => {
  const { machineMonthWise } = apiData;
  const downTimeTotal = machineMonthWise.reduce(
    (sum: any, item: any) => sum + item.downTime,
    0
  );
  return (
    <tfoot style={{ background: '#a9e34b' }}>
      <tr>
        <th></th>
        <th>TOTAL</th>
        <th>{Number(downTimeTotal).toFixed(2)}</th>
        <th></th>
        <th></th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
