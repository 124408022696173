import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    desc: 'PP-01',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PP-02',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PP-03',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PP-04',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PP-05',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-01',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-02',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-03',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-04',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-05',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-06',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-07',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-08',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-09',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'PDC-10',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'BRR-01',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPRS-01',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPRS-02',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPRS-03',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPRS-04',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPRS-05',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPRS-06',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPFT-01',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPFT-02',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPFT-03',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPSS-01',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'HPSS-02',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'LANF-01',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'LANF-02',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'LANF-03',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'LANF-04',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'SB-01,02',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'DFH-01',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Inspection Table (Incoming)-1 ',
    freq: '750-1500',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Inspection table (Armature Line)',
    freq: '750-1500',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Inspection table (Final-01)',
    freq: '750-1500',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Inspection table (Final-02)',
    freq: '750-1500',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Inspection table (Final-03)',
    freq: '750-1500',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Rework Table',
    freq: '750-1500',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Inspection Table (Incoming) -2',
    freq: '750-1500',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Fetling Table -01',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Fetling Table -02',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Fetling Table -03',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Fetling Table -04',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Fetling Table -05',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Fetling Table -06',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Fetling Table -07',
    freq: '300-700',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },
  {
    desc: 'Quality Room',
    freq: '750-1500',
    inst: 'Lux Meter',
    shift_A_results: Array(2).fill(''),
    data_type: 'footer',
    remarks: '',
  },  
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyBin_date: moment(),
  machine_id:null,
  processes: lodash.cloneDeep(processes),
};
