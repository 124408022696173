import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import AutocompleteMuiCustomRevised from '../../../../components/common/tableinputs/AutocompleteMuiCustomRevised';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { convToTime } from '../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';
import { StyledNotesField } from '../../../../components/common/FormControlMui';
import DescriptionTextfield from '../../../../components/common/DescriptionNotes';

interface IProps {
  moduleData: any;
  instrumentCategoryData?: any;
  updateModuleData?: any;
}

const THead = ({
  instrumentCategoryData,
  moduleData,
  updateModuleData,
}: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const [render, setRender] = useState<any>('');

  const handleAutocompleteChange = (newValue: any) => {
    updateModuleData('key-pair', null, 'machine_name', {
      machine_name: newValue?.machine_name,
    });
    updateModuleData('key-pair', null, 'machine_no', {
      machine_no: newValue?.machine_no,
    });
    updateModuleData('key-pair', null, 'machine_id', { machine_id: newValue });
    // updateModuleData('key-pair', null, 'machine_id', newValue);
  };
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={15}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['F-QA-32-A', '00', '08.01.2018']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME (Instrument Category) : </th>

        <th colSpan={2}>{moduleData.checkList_name}</th>
        <th colSpan={12}></th>
      </tr>
      <tr>
        <th colSpan={1}>MONTH & YEAR : </th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.moduleDate)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleDate || null)}
              views={['month', 'year']}
              onChange={(date) => {
                moduleData.moduleDate = date;

                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
        <th>Instrument No. : </th>
        <th colSpan={2}>
          <AutocompleteMuiCustom
            id="Instrument"
            label=""
            option_name="instrument_no"
            key={'tools'}
            arrayofObj={instrumentCategoryData}
            value={moduleData?.instrument_id || ''}
            onChange={(e, value) => {
              moduleData.instrument_id = value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={9}></th>
      </tr>
      <tr></tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
