import { useState } from 'react';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import TableHeaderHtmlCustom from '../../../components/common/customTable/TableHeaderHtmlCustom';
import { useQuery } from '../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
import { TableCompHead } from '../../../components/ui';
import formatDate from '../../../components/common/formatDate';
import DatePickerMui from '../../../components/common/DatePickerMui';
import moment from 'moment';
import CustomSelect from '../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
interface IProps {
  moduleData: any;
  setModuleData: any;
  isView: boolean;
  machineData: any;
  setRenderforUpdate: any;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  // height: '50px',
  position: 'sticky',
  width: '100%',
  top: '-.5rem',
  border: '1px solid black',
  zIndex: '1',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};
const stickStylesRow = {
  position: 'sticky',
  width: '100%',
  top: '0rem',
  border: '1px solid black',
  zIndex: '2',
  height: '50px',
  backgroundColor: 'rgb(79, 129, 189)',
} as const;
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#4F81BD',
  color: '#fff',
} as const;
const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);
const theadData = ['Time', 'Point', ...numberCol, 'Remarks'];

const THead = ({ moduleData, machineData, setRenderforUpdate }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  const [render, setRender] = useState(false);
  return (
    <thead style={stickStylesRow}>
      <tr>
        <th style={{ background: '#495057', color: '#f8f9fa' }} colSpan={39}>
          {`${moduleConstant?.heading} - ${moment(date).format(
            'MMM-YY',
          )}`?.toUpperCase()}
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Machine No.</th>
        <th colSpan={5} align="left">
          <AutocompleteMuiCustom
            id="Machine"
            label=""
            option_name="machine_no"
            option_name_2="machine_name"
            arrayofObj={machineData}
            value={moduleData.machine_id || ''}
            onChange={(e, value) => {
              moduleData.machine_id = value;
              setRenderforUpdate((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Shift :{' '}
        </th>
        <th colSpan={5} align="left">
          <CustomSelect
            name="shift"
            value={moduleData.shift || ''}
            onChange={(e: any) => {
              moduleData.shift = e.target.value;
              setRender((prev: any) => !prev);
            }}
          >
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="B">B</MenuItem>=
          </CustomSelect>
        </th>
        <th colSpan={20}></th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            // style={{
            //   backgroundColor: '#4F81BD',
            //   color: '#fff',
            //   minWidth: '100px',
            //   position: 'sticky',
            //   width: '100%',
            //   top: '-.5rem',
            //   border: '1px solid black',
            //   zIndex: '1',
            // }}
            style={index <= 1 ? {
              ...stickStylesColumn,
              left: 200 * index
            } : {}}
            key={heading}
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
