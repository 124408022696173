import React from 'react';
import { useQuery } from '../../../../hooks/UseQuery';
import { StyledTableRow } from '../helpers/module.styled';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

export const TableRowComponent1 = React.memo(
  ({ key, item, index, updateModuleData, handleDeleteRows }: any) => {
    const depertment_id = useQuery('depertment_id');
    const isView = useQuery('isView') === 'true' ? true : false;
    console.log(item);
    const handleInputChange = (fieldName: string, value: any) => {
      updateModuleData(index, fieldName, value, 'list_1');
    };

    return (
      <React.Fragment key={key}>
        <StyledTableRow index={index}>
          <td>
            <DatePickerMui
              value={item.date ? moment(item.date) : null}
              onChange={(date) => {
                item.date = date;
                handleInputChange('date', date);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={false}
              name="topic_of_training"
              type="text"
              value={item.topic_of_training}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={false}
              name="trainer"
              type="text"
              value={item.trainer}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>

          <td>
            {/* <AutocompleteMuiCustom
              label={''}
              id={'checkList_name'}
              option_name={'checkList_name'}
              value={checkSheetType}
              arrayofObj={masterCheckListData}
              onChange={async (e, value) => {
                setCheckSheetType(value);
              }}
            /> */}
            {depertment_id}
          </td>

          <td style={{ minWidth: '50px' }}>
            <DeleteIconButton
              tooltipTitle={'delete'}
              onClick={() => handleDeleteRows(index, 'list_1')}
            />
          </td>
        </StyledTableRow>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
