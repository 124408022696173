import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  handleChangeMachine: (value: any) => void;
}
const stickStylesRow = {
  position: 'sticky',
  width: '100%',
  top: '0rem',
  border: '1px solid black',
  zIndex: '2',
  height: '50px',
  backgroundColor: 'rgb(79, 129, 189)',
} as const;
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#4F81BD',
  color: '#fff',
} as const;

const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = [
  'S.No.',
  'Classfication',
  'Place',
  'Tool',
  'Description',
  'Frequency',
  ...numberCol,
  'Remarks',
];

const THead = ({
  moduleData,
  isView,
  machineData,
  handleChangeMachine,
}: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('annual_date');

  return (
    <thead style={stickStylesRow}>
      <TableCompHead
        colSpan={38}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date:']}
        values={['F-PROD-44', '07', '01-05-2021']}
        heading={`कॉम्पोनेंट डाई कास्टिंग मशीन चेकशीट - ${date}`}
      />
      <tr>
        <th colSpan={38} align="left">
          Month : {date}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine No. :{' '}
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            `${moduleData?.machine_id?.machine_no}/
            ${moduleData?.machine_id?.machine_name}`
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              option_name_2="machine_name"
              arrayofObj={machineData}
              value={moduleData.machine_id || ''}
              onChange={(e, value) => {
                handleChangeMachine(value);
              }}
            />
          )}
        </th>
        <th colSpan={32}></th>
      </tr>
      {/* <tr>
        <th colSpan={2} align="left">
          Date :
        </th>
        <th colSpan={4} align="left">
          <DatePickerMui
            label=""
            value={moment(moduleData.dailyBin_date || '')}
            onChange={(date) => {
              moduleData.dailyBin_date = date;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={32}></th>
      </tr> */}
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading} style={index <= 5 ? {
            ...stickStylesColumn,
            left: 100 * index
          } : {}}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
