const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const getMonthYearly = (year: any) => {
  console.log('year', year);

  const currentYear = new Date().getFullYear();
  const validYear = isNaN(year) || !year ? currentYear : parseInt(year, 10);

  console.log('validYear', validYear);

  return months.map((month) => ({
    month_year: `${month}-${validYear}`,
  }));
};

export { getMonthYearly };
