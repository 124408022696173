import React, { useState } from 'react';
import { allmonth } from '../../helpers/utils';
import { TableRowComponent } from './TableRowComponent';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import moment from 'moment';

interface IProps {
  moduleData: any;

  updateModuleData: any;
  setPartIdx: any;
  setIsOpenPopup: any;
  customerData: any;
}
export interface IRev {
  rev_no?: any;
  rev_date?: any;
  rev_desc?: string;
  prepared_by?: string;
  approved_by?: string;
}
const TBody = ({
  moduleData,
  updateModuleData,
  setPartIdx,
  setIsOpenPopup,
  customerData,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            allmonth,
            updateModuleData,
            setPartIdx,
            setIsOpenPopup,
            customerData,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
