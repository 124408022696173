import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [_, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.list &&
        moduleData.list.map((item: any, index: number) => (
          <tr key={`${index}_row`}>
            <td>{index + 1}</td>
            <td>{item?.date && formatDate(item?.date)}</td>
            <td style={{ width: '180px' }}>
              {isView ? (
                formatDate(item?.unloading_date || null)
              ) : (
                <DatePickerMui
                  value={
                    (item?.unloading_date && moment(item?.unloading_date)) ||
                    null
                  }
                  onChange={(date) => {
                    item.unloading_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td>
              <TableInput
                isView={isView}
                name="dot_no"
                type="text"
                value={item.dot_no}
                onChange={(e) => {
                  item.dot_no = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={true}
                name="actual_prod"
                type="number"
                value={item.actual_prod}
              />
            </td>
            <td>
              <TableInput
                isView={true}
                name="comulative"
                type="text"
                value={item.comulative}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="die_condition_after_run"
                type="text"
                value={item.die_condition_after_run}
                onChange={(e) => {
                  item.die_condition_after_run = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="sign"
                type="text"
                value={item.sign}
                onChange={(e) => {
                  item.sign = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="repair_detail_pm_breakdown"
                type="text"
                value={item.repair_detail_pm_breakdown}
                onChange={(e) => {
                  item.repair_detail_pm_breakdown = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="incharge_sign"
                type="text"
                value={item.incharge_sign}
                onChange={(e) => {
                  item.incharge_sign = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td
              style={{
                ...(item.status === 'RED (R)' && { background: '#e03131' }),
              }}
            >
              {item.status}
            </td>
            <td>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
