import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';

import { SubmitButton } from '../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../components/common/FormControlMui';
import useConfirm from '../../../components/common/useConfirm';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components/common/Loader';
import { useQuery } from '../../../hooks/UseQuery';
import DatePickerMui from '../../../components/common/DatePickerMui';
import moment from 'moment';
interface Iprop {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
}

const CreateCustomerStandard = ({ setIsOpenPopup, id, setId }: Iprop) => {
  const { customerId } = useParams();
  const documentType = useQuery('documentType') || '';
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'SUBMIT',
    `Are you sure you want to ${id ? 'update' : 'save'} this document?`,
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/customerRatingRoute/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setData((prev: any) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    let formData = new FormData();
    setIsLoading(true);
    if (customerId) formData.append('customer_id', customerId);
    if (data.document_name)
      formData.append('document_name', data.document_name);
    if (data.document_no) formData.append('document_no', data?.document_no);
    if (data.rating_date)
      formData.append('rating_date', data.rating_date?.toString());
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/customerRatingRoute/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/customerRatingRoute', formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Box>
        <FormControlMui handleSubmit={onSubmit} style={{ height: '400px' }}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="document_name"
              placeholder="Document Name"
              required={true}
              onChange={handlePartData}
              value={data?.document_name || ''}
            />
            <InputMuiCustom
              type="string"
              name="document_no"
              placeholder="Document No."
              onChange={handlePartData}
              value={data?.document_no || ''}
            />

            <DatePickerMui
              label="Customer Rating Date"
              onChange={(date) => {
                data.rating_date = date;
                setRender((prev) => !prev);
              }}
              value={moment(data.rating_date || null)}
            />
            <CustomUploadButton
              lable={'UPLOAD DRAWING'}
              multiple
              accept="image/png,image/jpeg,image/jpg,application/pdf,.doc,.docx,.xls,.xlsx"
              onChange={(e) => setFiles(e.target.files)}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default CreateCustomerStandard;
