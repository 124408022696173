const style1 = {
  backgroundColor: '#4F81BD',
  // backgroundColor: '#00B050',
  color: '#fff',
  // minWidth: '80px',
  border: '1px solid #343a40',
};

export const listHeaderObj = [
  [
    { text: '#', style: { ...style1 } },
    { text: 'NAME', style: { ...style1 } },
    { text: 'LEVEL', style: { ...style1 } },
    {
      text: 'Action',
      style: { ...style1, color: '#fff', backgroundColor: '#c92a2a' },
    },
  ],
];

export const HeaderStandard = [
  [
    {
      text: 'S.No.',
      style: { ...style1, minWidth: '90px', width: '80px' },
      rowspan: 2,
    },
    { text: 'POINTS TO BE VERIFIED', colspan: 3, style: style1, rowspan: 2 },
    { text: 'Obtained Marks', style: style1, rowspan: 1 },
    { text: ' Max Marks', style: style1, rowspan: 1 },
    {
      text: 'Action',
      style: { color: '#fff', backgroundColor: '#c92a2a' },
      rowspan: 2,
    },
  ],
  [
    { text: '', style: { height: '30px', backgroundColor: '#f8f9fa' } },
    { text: '', style: { height: '30px', backgroundColor: '#f8f9fa' } },
  ],
];

export const listConstant = {
  childHeader: 'SKILL EVALUATION SHEET list',
  addButtonLable: 'New Test Paper',
};

export const headerConstant = {
  tableCompHeadTitle: 'SKILL EVALUATION SHEET',
};

export const apiConstant = {
  get: 'masterSkillEvaluationRoute',
  getById: 'masterSkillEvaluationRoute',
  post: 'masterSkillEvaluationRoute',
  update: 'masterSkillEvaluationRoute',
  delete: 'masterSkillEvaluationRoute',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
  departmentAutoComplete: 'department',
};

export const tilesConstant = {
  childHeaderText: 'SKILL EVALUATION SHEET',
};

export const navigationConstant = {
  createEditChecklist: '/common/skillEvaluationSheetCreate',
};
