import moment from 'moment';
import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleChangeMachine: (value: any) => void;

  machineData: any;
}
const stickStylesRow = {
  position: 'sticky',
  width: '100%',
  top: '0rem',
  border: '1px solid black',
  zIndex: '2',
  height: '50px',
  backgroundColor: 'rgb(79, 129, 189)',
} as const;
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#4F81BD',
  color: '#fff',
} as const;
const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = ['Sr.No.', 'विवरण', 'फ्रीक्वेंसी', ...numberCol, 'Remarks'];

const THead = ({
  handleChangeMachine,
  moduleData,
  isView,
  machineData,
}: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('annual_date');

  return (
    <thead style={stickStylesRow}>
      <TableCompHead
        colSpan={35}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date:']}
        values={['F-PROD-52', '03', '19-07-2023']}
        heading={`Supervisor  (HPDC) Check Sheet ${date}`}
      />
      <tr>
        <th colSpan={35} align="left">
          Month : {date}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine No. :{' '}
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            `${moduleData?.machine_id?.machine_no}/
            ${moduleData?.machine_id?.machine_name}`
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              option_name_2="machine_name"
              arrayofObj={machineData}
              value={moduleData.machine_id || ''}
              onChange={(e, value) => {
                handleChangeMachine(value);
              }}
            />
          )}
        </th>
        <th colSpan={29}></th>
      </tr>
      {/* <tr>
        <th colSpan={2} align="left">
          Date :{' '}
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            formatDate(moduleData.dailyBin_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyBin_date)}
              onChange={(date) => {
                moduleData.dailyBin_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={29}></th>
      </tr> */}
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading} style={index <= 2 ? {
            ...stickStylesColumn,
            left: 200 * (index - 1)
          } : {}}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
