import { AiOutlineCloudUpload } from 'react-icons/ai';
import { AiOutlinePartition } from 'react-icons/ai';
import { FaList } from 'react-icons/fa';
import { GrUser } from 'react-icons/gr';
const tiles = [
  {
    sNo: 2,
    link: '/common/masterListOfProject?sort=createdAt',
    isLink: true,
    icon: <FaList size="5rem" color="#003566" />,
    p: 'MASTER LIST OF',
    span: 'PROJECT',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/npdPartList?sort=part_number',
    isLink: true,
    icon: <FaList size="5rem" color="#003566" />,
    p: 'MASTER LIST OF',
    span: 'NPD PART',
    isIcon: true,
  },
  {
    sNo: 3,
    link: `/common/devlopmentTracker`,
    isLink: true,
    icon: <GrUser size="5rem" color="#003566" />,
    p: 'Devlopment',
    span: 'Tracker',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/npdMatrixList',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'NPD',
    span: 'MATRIX',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/npdTimePlanList',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'NPD',
    span: 'TIME PLAN',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/clauseUploadList?documentType=masterProcedureClause1&heading=MASTER PROCEDURE',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'MASTER',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/clauseUploadList?documentType=listOfProblemClause1&heading=LIST OF PROBLEM',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'LIST OF',
    span: 'PROBLEM ',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/clauseUploadList?documentType=fmeaReviewPlanClause1&heading=FMEA REVIEW PLAN',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'FMEA REVIEW',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/clauseUploadList?documentType=evidenceOfImplementationClause1&heading=EVIDENCE OF IMPLEMENTATION',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'EVIDENCE OF',
    span: 'IMPLEMENTATION',
    isIcon: true,
  },
  {
    sNo: 10,
    link: '/common/clauseUploadList?documentType=summaryInPdcaClause1&heading=SUMMARY IN PDCA',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'SUMMARY IN',
    span: 'PDCA',
    isIcon: true,
  },
];

export default tiles;
