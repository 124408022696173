import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';
import { SelectChangeEvent } from '@mui/material';

const ModalInput = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    faculty_name: '',
    faculty_type: '',
    address: '',
    quesion_paper_name: '',
    area_of_work: {
      department_name: '',
    },
    levels: [],
  });

  const [render, setRender] = useState(false);
  const [autoCompleteDepartment, setAutocompleteDepartment] = useState('');
  const [department, setDepartment] = useState('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update' : 'Submit'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setAutocompleteDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/skillEvaluationQuestionPaper/getOneQuestionPaper/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleQuestionPaper(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      axios
        .patch(
          `/api/skillEvaluationQuestionPaper/patchQuestionPaper/${id}`,
          moduleData
        )
        .then((res) => {
          window.alert('success');
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      moduleData['levels'] = new Array(4).fill(0).map((_, index) => {
        return { levels_type: `Level_${index + 1}`, question: [] };
      });

      axios
        .post(
          `/api/skillEvaluationQuestionPaper/createQuestionPaper`,
          moduleData
        )
        .then((res) => {
          window.alert('success');
          setIsModal(false);
        })
        .catch((err) => {
          if (err.response.data.error.message.includes('E11000')) {
            window.alert('Can not create duplicate area of work');
          }
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'quesion_paper_name'}
        name={'quesion_paper_name'}
        html_for={'quesion_paper_name'}
        label_name={'Question Paper Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.quesion_paper_name}
        onChange={handleQuestionPaper}
      />
      <AutocompleteGridmui
        label={'Department'}
        id={'department_name'}
        option_name={'department_name'}
        value={department || moduleData?.area_of_work || ''}
        arrayofObj={autoCompleteDepartment}
        onChange={(e, value) => {
          moduleData.area_of_work = value?._id;
          //moduleData.area_of_work_id = value;
          setDepartment(value);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
