import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
// import "../../../../App.css";
import TipTap from '../../TipTapTable';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import HTMLReactParser from 'html-react-parser';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
  setModuleData: any;
}

const TBody = ({
  moduleData,
  isView,
  setModuleData,
}: IProps) => {
  

  const handleSetDesc = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      nc_minutes: desc
    }));
  };

  return (
    <>
     <thead>
     <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['F-MR-06 ', '03', '01-04-2019']}
        heading="NON-CONFORMANCE REPORT"
      />
       </thead>
       <>
      
      <tbody>


      <tr style={{border:'none'}}>
        <th colSpan={5} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
             NCR
          </div>
        </th>
      

      </tr>

      <tr style={{border:'none'}}>
        {isView ? (
          <td align='center' colSpan={7} style={{border:'none'}}>
            <div className='tiptaptable' style={{textAlign:'center'}}>
              {HTMLReactParser(moduleData.nc_minutes)}
            </div>
          </td>
        ) : (
          <td align='center' colSpan={7} style={{border:'none', textAlign:'center'}}>
            <div  className='tiptaptable' style={{columnSpan:'all', textAlign:'center'}}>
              <TipTap setDesc={handleSetDesc} initialContent={moduleData.nc_minutes} />
            </div>
          </td>
        )}
      </tr>
      </tbody>
      </>
    </>
  );
};

export default TBody;
