import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../components/common/button/CustomIconButton';
import ModalInput from './ModalInput';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';

import { IData } from './Interface';
import CustomTileButton from '../../components/common/button/CustomTileButton';
import DescriptionIcon from '@mui/icons-material/Description';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Name', rowspan: 1, colspan: 1 },
    { text: 'Department', rowspan: 1, colspan: 1 },
    { text: 'Add Question', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'faculty_name', name: 'Name' },
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [questionPaperId, setQuestionPaperId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);

  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE test paper',
    'Are you sure you want to delete this?'
  );
  const navigate = useNavigate();

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/skillEvaluationQuestionPaper/getAllQuesionPaper/list/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteFaculty = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/skillEvaluationQuestionPaper/deleteQuestionPaper/${id}`)
      .then((res) => {
        alert('Test Paper deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal]);

  return (
    <>
      <ChildHeader text="TNI QUESTION LIST">
        <AddButton
          onClick={() => setIsModal(true)}
          label="New Question Paper"
        />
      </ChildHeader>
      <SearchFilterContainer filter={false}>
        <SearchBar label="Search" />
        {/* <FilterBy options={filterOptions} /> */}
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    {item?.quesion_paper_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.area_of_work?.department_name}
                  </TableCell>
                  <TableCell align="center">
                    <CustomTileButton
                      label="Add Question paper"
                      icon={<DescriptionIcon />}
                      url={`/common/createEditSkillEvaluationQuestionPaper/${item._id}`}
                      sx={{
                        background: '#003566',
                        color: '#fafafa',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setQuestionPaperId(item?._id || '');
                          setIsModal(true);
                        }}
                      />
                      <ViewIconButton
                        tooltipTitle="View Question paper"
                        onClick={() => {
                          navigate(
                            `/common/quickViewSkillEvaluationQuestionPaper/${item._id}`
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteFaculty(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setQuestionPaperId(null);
          setIsModal(false);
        }}
        title="CREATE NEW TNI"
      >
        <ModalInput id={questionPaperId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
