// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import {
  DeleteIconButton,
  UploadImageIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { Stack } from '@mui/material';

export const TableRowComponent = React.memo(
  ({
    key,
    item,
    index,
    allmonth,
    updateModuleData,
    handleDelete,
    uploadImageFile,
    moduleId,
  }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td>{index + 1}</td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="checkpoint"
              type="text"
              value={item.checkpoint}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="judgement_criteria"
              type="text"
              value={item.judgement_criteria}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="method"
              type="text"
              value={item.method}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            {moduleId && (
              <Stack
                direction="row"
                sx={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <UploadImageIconButton
                  id={`${index}-process-upload`}
                  tooltipTitle="upload"
                  onChange={(e) => {
                    uploadImageFile(e, index);
                  }}
                />
                {item.files?.length > 0 && (
                  <>
                    <a target={'_blank'} href={item.files[0]?.url}>
                      <ViewIconButton tooltipTitle={'view'} />
                    </a>
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => {
                        updateModuleData('row', index, 'files', { files: [] });
                      }}
                    />
                  </>
                )}
              </Stack>
            )}
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="observation_before_1"
              type="text"
              value={item.observation_before_1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="after_1"
              type="text"
              value={item.after_1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remark_1"
              type="text"
              value={item.remark_1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="observation_before_2"
              type="text"
              value={item.observation_before_2}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="after_2"
              type="text"
              value={item.after_2}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remark_2"
              type="text"
              value={item.remark_2}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          {!isView && (
            <td style={{ minWidth: '50px' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handleDelete(index)}
              />
            </td>
          )}
        </tr>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
