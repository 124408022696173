export const moduleConstant = {
  crudApi: '/api/fireExtinguisherRoute',
  childHeader: 'List Of Fire Extinguishers',
  btnName: 'New Fire Extinguishers',
  searchLabel: 'Capacity / Location',
  moduleName: 'Fire Extinguishers',
  checklist: {
    childHeader: 'Daily Check Sheet For Extinguishers',
    toolTipTitle: 'Checklist',
    crudApi: '/api/fireExtinguisherRoute',

    router: {
      checklist: '/common/fireExtinguishers/checklist',
    },
  },
};
