import lodash from 'lodash';
import moment from 'moment';

export const list = [
  {
    part_id: null,
    customer_id:null,
    defect_no : {
      actual: new Array(22).fill(null),
      result: new Array(22).fill(null),
      status: new Array(22).fill(null),
    },
  },
];

export const initialState = {
  name: "",
  moduleData_Date: moment(),
  defect_details : new Array(18).fill(""),
  list: Array.from({ length: 1 }, () => ({ ...list[0] })),
  performed_by:"",
  approved_by:"",
  revision_history: Array.from({ length: 5 }, () => ({
    ...lodash.cloneDeep({}),
  })),
};
