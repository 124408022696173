import React, { useCallback, useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import StateCustomTable from '../../../../components/common/StateCustomTable';
import { TableCustom, StatePagination } from '../../../../components/common';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';
import useDebounce from '../../../../hooks/useDebounce';
import { apiConstant, navigationConstant } from '../helpers/constant';
import { useQuery } from '../../../../hooks/UseQuery';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Sheet Name', rowspan: 1, colspan: 1 },
    { text: 'LEVEL', rowspan: 1, colspan: 1 },
    { text: 'Deapartment', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'part_number', name: 'A-Z' }];

const ModalTable = () => {
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('part_number');
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState<number>(10);

  const test_level = useQuery('test_level');
  const employee_id = useQuery('employee_id');
  const employee_name = useQuery('employee_name');
  const department_id = useQuery('department_id');
  const isView = useQuery('isView') === 'true' ? true : false;

  const fetchPartData = () => {
    axios
      .get(
        `/api/${apiConstant.masterlistSkillEvaluationList}?level=${
          (test_level === 'L1_test' && 'LEVEL-1') ||
          (test_level === 'L2_test' && 'LEVEL-2') ||
          null
        }&page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&isView=${isView}`
      )
      .then((res) => {
        setTableData(res?.data?.results);
        setPerPage(res?.data?.perPage);
        setTotalPages(res?.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);

  const handleNavigate = useCallback(
    (id: any) => {
      navigate(
        `${navigationConstant.skillEvaluationTestSheetRouter}/${id}?&test_level=${test_level}&employee_id=${employee_id}&employee_name=${employee_name}&department_id=${department_id}&isView=${isView}`
      );
    },
    [navigate]
  );

  return (
    <StateCustomTable
      filterOptions={filterOptions}
      {...{ search, setSearch, filter, setFilter }}
      label="Search Part"
    >
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((item: any, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.sheet_name}</TableCell>
                  <TableCell align="center">{item.level}</TableCell>
                  <TableCell align="center">
                    {item?.department_id?.department_name}
                  </TableCell>
                  <TableCell align="center">
                    <CustomIconButton
                      sx={{ p: 0 }}
                      tooltipTitle={'select'}
                      onClick={() => handleNavigate(item?._id)}
                    >
                      <Add style={{ color: '#343a40' }} />
                    </CustomIconButton>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
    </StateCustomTable>
  );
};

export default ModalTable;
