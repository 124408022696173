import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { apiConstant } from './constant';
import useConfirm from '../../../../components/common/useConfirm';
import { useQuery } from '../../../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    list_1: [],
    list_2: [],
  });
  const [error, setError] = useState(null);
  const tniTopicId = useQuery('tniTopicId');
  const employee_id = useQuery('employee_id');
  const [isLoading, setIsLoading] = useState(false);
  const [triggerRender, setTriggerRender] = useState(false);
  const [machineData, setMachineData] = useState([{}]);
  const navigate = useNavigate();
  const [department, setDepartment] = useState([{}]);
  const [Sync, confirmSync] = useConfirm(
    'SYNC',
    'Are you sure you want to sync? Save any changes before sync!'
  );

  const addRowList1 = useCallback(() => {
    setModuleData((prev: any) => {
      return {
        ...prev,
        list_1: [
          ...prev.list_1,
          {
            _tempId: `temp-id-${new Date().getTime()}`,
            date: null,
            department_id: null,
          },
        ],
      };
    });
  }, []);

  const addRowList2 = useCallback(() => {
    setModuleData((prev: any) => {
      return {
        ...prev,
        list_2: [
          ...prev.list_2,
          {
            _tempId: `temp-id-${new Date().getTime()}`,
            date: null,
            department_id: null,
          },
        ],
      };
    });
  }, []);

  const updateCheckBox = useCallback((key: string, newValue: any) => {
    setModuleData((prev: any) => {
      return {
        ...prev,
        [key]: newValue,
      };
    });
  }, []);

  const updateModuleData = useCallback(
    (
      rowIndex: number | null,
      key: string,
      newValues: any,
      list_type: string
    ) => {
      setModuleData((prev: any) => {
        const updatedModuleData: any = { ...prev };
        if (rowIndex === null) return;
        const updatedRow = { ...updatedModuleData?.[list_type]?.[rowIndex] };
        updatedRow[key] = newValues;
        updatedModuleData[list_type][rowIndex] = updatedRow;
        return updatedModuleData;
      });
    },
    [moduleData]
  );

  const handleDelete = async () => {
    axios
      .delete(`/api/${apiConstant.delete}/${id}?employee_id=${employee_id}`)
      .then((res) => {
        alert('Succesfully deleted');
        navigate(-1);
      })
      .catch((err) => {
        setError(err);
        alert('cant  deleted');
      });
  };

  const handleDeleteRows = useCallback((index: number, listType: string) => {
    setModuleData((prev: any) => {
      const newList = prev?.[listType]?.filter(
        (_: any, i: number) => i !== index
      );
      return { ...prev, [listType]: newList };
    });
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axios
        .get(`/api/${apiConstant.getById}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [triggerRender]);

  return {
    moduleData,
    isLoading,
    error,
    Sync,
    updateModuleData,
    setTriggerRender,
    handleDelete,
    addRowList1,
    addRowList2,
    handleDeleteRows,
    updateCheckBox,
  };
};

export default useGetData;
