import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import useConfirm from '../../../../components/common/useConfirm';
import ChildHeader from '../../../../components/ui/ChildHeader';
import {
  CustomButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import TController from './components/TController';
import { useQuery } from '../../../../hooks/UseQuery';

import lodash from 'lodash';
import { IData } from '../helpers/interface';
import { initialState } from '../helpers/initialState';

export const AccidentRecordForm = () => {
  const [moduleData, setModuleData] = useState<any>({});

  const [selectedMonth, setSelectedMonth] = useState<string>('');

  const navigate = useNavigate();

  const [render, setRender] = useState(false);

  const { id } = useParams();

  const isView = useQuery('isView') === 'true' ? true : false;
  const departmentId = useQuery('departmentId');
  console.log('departmentId', departmentId);
  console.log('selectedMonth', selectedMonth);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/machineRoutes/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    if (selectedMonth && departmentId) {
      axios
        .get(
          `/api/dailyAccidentRoute/${selectedMonth}?departmentId=${departmentId}`
        )
        .then((res) => {
          const fetchedData = res.data;
          const [monthName, year] = fetchedData.annual_month.split('-');
          const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();

          if (fetchedData.histories && fetchedData.histories.length > 0) {
            setModuleData(fetchedData);
          } else {
            const newHistories: any[] = [];

            fetchedData.processes.forEach((process: any) => {
              if (
                process.freq === 'Major' ||
                process.freq === 'Minor' ||
                process.freq === 'Near Miss' ||
                process.freq === 'First Aid'
              ) {
                process.shift_A_results.forEach(
                  (result: any, dayIndex: any) => {
                    if (result === 'false') {
                      // Prepare a history entry
                      const historyEntry = {
                        date: new Date(year, monthIndex, dayIndex + 1), // Construct the date
                        nameOfInjuredPerson: '', // To be filled by user
                        nameOfDepartment:
                          fetchedData.department_id.department_name || '', // From fetched data
                        accidentType: process.freq || '', // From process data
                        accidentDetail: '', // To be filled by user
                        reasonForAccident: '', // To be filled by user
                        actionPlanToAvoidAccident: '', // To be filled by user
                        supSign: '', // To be filled by user
                        remarks: '', // To be filled by user
                      };
                      newHistories.push(historyEntry);
                    }
                  }
                );
              }
            });

            newHistories.sort((a, b) => a.date - b.date);

            setModuleData({ ...fetchedData, histories: newHistories });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedMonth, departmentId]);
  // add comments

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );

  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    console.log('moduleData', moduleData);
    await axios
      .put(`/api/dailyAccidentRoute/accidentLog/${moduleData._id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
    navigate(-1);
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.histories.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="ACCIDENT RECORD FORM">
          {!isView && (
            <>
              {/* <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  if (selectedMonth) {
                    moduleData.histories.push({});
                  } else {
                    alert('Please Select A Month First');
                  }
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton> */}
              <UpdateButton label="Update" onClick={() => handleSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          setRender,
          handelDeleteRows,
          setSelectedMonth,
          setModuleData,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
    </>
  );
};
