const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Inspection Item', style: style1 },
    { text: 'Specification', style: style1 },
    { text: 'Observation', style: style1, colspan: 5 },
    { text: 'Ok-o/Ng-x', style: style1 },
    { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'REVALIDATION Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'MACHINE REVALIDATION CHECKSHEET ',
};

export const apiConstant = {
  get: 'revalidationMasterChecklistRoute',
  getById: 'revalidationMasterChecklistRoute',
  post: 'revalidationMasterChecklistRoute',
  update: 'revalidationMasterChecklistRoute',
  delete: 'revalidationMasterChecklistRoute',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
  childHeaderText: 'REVALIDATION',
};

export const navigationConstant = {
  createEditChecklist: '/common/revalidationChecklistCreate',
};
