import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import SimpleSelect from '../../../../../components/common/SimpleSelect';
import SimpleSelectRevised from '../../../../../components/common/SimpleSelectRevised';

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  handelDeleteRows: any;
}
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: 'rgb(255, 236, 153)',
  color: 'black',
} as const;

const TBodyRow = ({
  moduleData,
  isView,
  process,
  index,
  handelDeleteRows,
}: IProps) => {
  const [render, setRender] = useState(false);
  const handleTd = (rowSpan: number, text: string) => {
    return (
      <td
        rowSpan={rowSpan}
        style={{
          transform: 'rotate(180deg)',
          minWidth: '100px',
          writingMode: 'vertical-lr',
          textOrientation: 'mixed',
          whiteSpace: 'nowrap',
          ...stickStylesColumn,
          left: 100
        }}
      >
        <strong style={{ fontSize: '1.8rem' }}> {text} </strong>
      </td>
    );
  };

  return (
    <React.Fragment key={index + 10.1 + 'heading '}>
      {process.frequency == 'शिफ्ट' ? (
        <>
          <tr>
            <th style={{
              minWidth: '98px', textAlign: 'center',
              ...stickStylesColumn,
              left: 0
            }} rowSpan={2}>
              {index + 1}
            </th>

            {index === 0 && handleTd(11 + 2, process?.classfication)}

            {index === 11 && handleTd(2 + 2, process?.classfication)}

            {index === 13 && handleTd(13 + 7, process?.classfication)}

            {index === 26 && handleTd(4 + 2, process?.classfication)}

            {index === 0 && (
              <td
                rowSpan={31 + 13}
                style={{
                  transform: 'rotate(180deg)',
                  minWidth: '100px',
                  writingMode: 'vertical-lr',
                  textOrientation: 'mixed',
                  whiteSpace: 'nowrap',
                  ...stickStylesColumn,
                  left: 200
                }}
              >
                <strong style={{ fontSize: '1.8rem' }}>{process?.place}</strong>
              </td>
            )}
            <td style={{
              minWidth: '100px', textAlign: 'center',
              ...stickStylesColumn,
              left: 300
            }} rowSpan={2}>
              {process.tool || ''}
            </td>

            <td style={{
              minWidth: '100px', textAlign: 'center',
              ...stickStylesColumn,
              left: 400
            }} rowSpan={2}>
              {process.description || ''}
            </td>
            <td style={{
              minWidth: '100px', textAlign: 'center',
              ...stickStylesColumn,
              left: 500
            }} rowSpan={2}>
              {process.frequency || ''}
            </td>

            {Array.from({ length: 31 }, (_, indx) =>
              process.point === 'VERIFIED BY' ? (
                <td key={index + 2.2 + indx + 'after' + 111.33}>
                  <TableInput
                    isView={isView}
                    name="verified_by"
                    type="text"
                    value={process.shift_A_results[indx]}
                    onChange={(e) => {
                      process.shift_A_results[indx] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              ) : (
                <td
                  style={{
                    minWidth: '100px',
                    textAlign: 'center',
                  }}
                  key={index + 2.2 + indx + 'after' + 111.33}
                >
                  {moduleData.machine_id && (
                    <SimpleSelectRevised
                      name={`shift_A_results[${indx}]`}
                      value={process.shift_A_results[indx]}
                      onChange={(e: any) => {
                        process.shift_A_results[indx] = e.target.value;
                        setRender((prev) => !prev);
                      }}
                      options={[
                        { value: '', label: 'none' },
                        { value: 'true', label: '✓' },
                        { value: 'false', label: 'x' },
                      ]}
                    />
                  )}
                </td>
              ),
            )}
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={process.remarks}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
          <tr>
            {Array.from({ length: 31 }, (_, indx) =>
              process.point === 'VERIFIED BY' ? (
                <td key={index + 2.2 + indx + 'after' + 111.33}>
                  <TableInput
                    isView={isView}
                    name="verified_by"
                    type="text"
                    value={process.shift_B_results[indx]}
                    onChange={(e) => {
                      process.shift_B_results[indx] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              ) : (
                <td
                  style={{
                    minWidth: '100px',
                  }}
                  key={index + 2.2 + indx + 'after' + 111.33}
                >
                  {moduleData.machine_id && (
                    <SimpleSelectRevised
                      name={`shift_B_results[${indx}]`}
                      value={process.shift_B_results[indx]}
                      onChange={(e: any) => {
                        process.shift_B_results[indx] = e.target.value;
                        setRender((prev) => !prev);
                      }}
                      options={[
                        { value: '', label: 'none' },
                        { value: 'true', label: '✓' },
                        { value: 'false', label: 'x' },
                      ]}
                    />
                  )}
                </td>
              ),
            )}
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={process.remarks}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        </>
      ) : (
        <tr>
          <th
            style={{
              minWidth: '98px', textAlign: 'center',
              ...stickStylesColumn,
              left: 0
            }}
            rowSpan={process?.rowSpan}
          >
            {index + 1}
          </th>

          {index === 0 && handleTd(11 + 2, process?.classfication)}

          {index === 11 && handleTd(2 + 2, process?.classfication)}

          {index === 13 && handleTd(13 + 7, process?.classfication)}

          {index === 26 && handleTd(4 + 2, process?.classfication)}

          {index === 0 && (
            <td
              rowSpan={31 + 13}
              style={{
                transform: 'rotate(180deg)',
                minWidth: '100px',
                writingMode: 'vertical-lr',
                textOrientation: 'mixed',
                whiteSpace: 'nowrap',
                ...stickStylesColumn,
                left: 200
              }}
            >
              <strong style={{ fontSize: '1.8rem' }}>{process?.place}</strong>
            </td>
          )}
          <td
            style={{
              minWidth: '100px', textAlign: 'center',
              ...stickStylesColumn,
              left: 300
            }}
            rowSpan={process?.rowSpan}
          >
            {process.tool || ''}
          </td>

          <td
            style={{
              minWidth: '100px', textAlign: 'center',
              ...stickStylesColumn,
              left: 400
            }}
            rowSpan={process?.rowSpan}
          >
            {process.description || ''}
          </td>
          <td
            style={{
              minWidth: '100px', textAlign: 'center',
              ...stickStylesColumn,
              left: 500
            }}
            rowSpan={process?.rowSpan}
          >
            {process.frequency || ''}
          </td>

          {Array.from({ length: 31 }, (_, indx) =>
            process.point === 'VERIFIED BY' ? (
              <td key={index + 2.2 + indx + 'after' + 111.33}>
                <TableInput
                  isView={isView}
                  name="verified_by"
                  type="text"
                  value={process.shift_A_results[indx]}
                  onChange={(e) => {
                    process.shift_A_results[indx] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            ) : (
              <td
                style={{
                  minWidth: '100px',
                }}
                key={index + 2.2 + indx + 'after' + 111.33}
              >
                {moduleData.machine_id && (
                  <SimpleSelectRevised
                    name={`shift_A_results[${indx}]`}
                    value={process.shift_A_results[indx]}
                    onChange={(e: any) => {
                      process.shift_A_results[indx] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    options={[
                      { value: '', label: 'none' },
                      { value: 'true', label: '✓' },
                      { value: 'false', label: 'x' },
                    ]}
                  />
                )}
              </td>
            ),
          )}

          <td
            style={{ minWidth: '150px', textAlign: 'center' }}
            rowSpan={process?.rowSpan}
          >
            <TableInput
              isView={isView}
              name="remarks"
              type="text"
              value={process.remarks}
              onChange={(e) => {
                handleChangeModuleData(e, process);
                setRender((prev) => !prev);
              }}
            />
          </td>
          {/* {!isView && (
            <td style={{ minWidth: '100px' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(index)}
              />
            </td>
          )} */}
        </tr>
      )}
    </React.Fragment>
  );
};

export default TBodyRow;
