import { GrUser, GrVmMaintenance } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';
import { VscFeedback } from 'react-icons/vsc';
import { FaDrumSteelpan } from 'react-icons/fa';
import { IoDocuments } from 'react-icons/io5';

// import { GiRing } from 'react-icons/gi';
// import { SiMaterialdesignicons } from 'react-icons/si';
// import { GiUnbalanced } from 'react-icons/gi';
// import { GiTeePipe } from 'react-icons/gi';
import { TbReport } from 'react-icons/tb';
// import { GrServices } from 'react-icons/gr';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { MdOutlinePersonPin } from 'react-icons/md';
import { BsFillDeviceSsdFill } from 'react-icons/bs';
import { FaCalendarAlt } from 'react-icons/fa';
import { AiOutlineDeliveredProcedure } from 'react-icons/ai';
import { GiMaterialsScience } from 'react-icons/gi';

const tiles = [
  {
    sNo: 23,
    link: '/common/ri_department_listOfProcedures/?documentType=ri_department&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/riPart',
    isLink: true,
    icon: <FaDrumSteelpan size="5rem" color="#343a40" />,
    p: 'RI PART',
    span: 'LIST',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/inspectionStandardList`,
    isLink: true,
    icon: <TbReport size="5rem" color="#c92a2a" />,
    p: 'Incoming',
    span: 'STANDARD',
    isIcon: true,
  },
  {
    sNo: 5,
    link: `/common/inspectionReportList`,
    isLink: true,
    icon: <TbReportAnalytics size="5rem" color="#a12222" />,
    p: 'INCOMING INSPECTION',
    span: 'Report',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/thirdPartyMaterialTestTiles',
    isLink: true,
    icon: <GiMaterialsScience size="5rem" color="#087f5b" />,
    p: 'THIRD PARTY',
    span: 'Plan',
    isIcon: true,
  },
  // {
  //   sNo: 5,
  //   link: `/common/inspectionReportList`,
  //   isLink: true,
  //   icon: <TbReportAnalytics size="5rem" color="#a12222" />,
  //   p: 'INCOMING INSPECTION',
  //   span: 'Report',
  //   isIcon: true,
  // },
  // {
  //   sNo: 6,
  //   link: `/common/samplingStandard`,
  //   isLink: true,
  //   icon: <BsFillDeviceSsdFill size="5rem" color="#a12222" />,
  //   p: 'SAMPLING',
  //   span: 'STANDARD',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: `/admin/SupplierComplaintRegister`,
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <VscFeedback size="5rem" color="#e03131" />,
  //   p: 'SUPPLIER COMPLAINT',
  //   span: 'REGISTER',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/common/supplierplanlist',
  //   isLink: true,
  //   icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
  //   p: 'SUPPLIER',
  //   span: 'PLAN',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/supplierchecklist',
  //   isLink: true,
  //   icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
  //   p: 'SUPPLIER',
  //   span: 'STATUS',
  //   isIcon: true,
  // },
  // {
  //   sNo: 4,
  //   link: `/common/vendorDocumentList`,
  //   isLink: true,
  //   icon: <IoDocuments size="5rem" color="#1864ab" />,
  //   p: 'SUPPLIER',
  //   span: 'DOCUMENTS',
  //   isIcon: true,
  // },
  // {
  //   sNo: 20,
  //   link: '/common/supplierAuditPlanTiles',
  //   isLink: true,
  //   clauseNo: 4,
  //   icon: <FaCalendarAlt size="5rem" color="#495057" />,
  //   p: 'Supplier Audit',
  //   span: 'Plan',
  //   isIcon: true,
  // },
  //   {
  //     sNo: 4,
  //     link: `/common/vendorDocumentList`,
  //     isLink: true,
  //     icon: <TbReport size="5rem" color="#a12222" />,
  //     p: 'SUPPLIER',
  //     span: 'DOCUMENTS',
  //     isIcon: true,
  //   },

  //   {
  //     sNo: 6,
  //     link: `/common/supplierComplaintRegister`,
  //     isLink: true,
  //     icon: <GrUser size="5rem" color="#003566" />,
  //     p: 'SUPPLIER COMPLAINT',
  //     span: 'REGISTER',
  //     isIcon: true,
  //   },
  //   {
  //     sNo: 7,
  //     link: `/common/riDocumentUpload/?documentType=iaiSSTPlan`,
  //     isLink: true,
  //     icon: <HiDocumentReport size="5rem" color="#003566" />,
  //     p: 'IAI SST',
  //     span: 'PLAN',
  //     isIcon: true,
  //   },
  //   {
  //     sNo: 8,
  //     link: `/common/riDocumentUpload/?documentType=iaiMtcPlanList`,
  //     isLink: true,
  //     icon: <HiDocumentReport size="5rem" color="#003566" />,
  //     p: 'IAI MTC',
  //     span: 'PLAN',
  //     isIcon: true,
  //   },
  //   {
  //     sNo: 9,
  //     link: `/common/riDocumentUpload/?documentType=riDocuments`,
  //     isLink: true,
  //     icon: <HiDocumentReport size="5rem" color="#003566" />,
  //     p: 'RI',
  //     span: 'DOCUMENTS',
  //     isIcon: true,
  //   },
  //   {
  //     sNo: 10,
  //     link: `/common/RawMaterialPlanTiles`,
  //     isLink: true,
  //     icon: <SiMaterialdesignicons size="4rem" color="#343a40" />,
  //     p: 'RAW MATERIAL',
  //     span: 'TEST PLAN (Child Parts)',
  //     isIcon: true,
  //   },
  //   {
  //     sNo: 10,
  //     link: `/common/RubberoringPartsTiles`,
  //     isLink: true,
  //     icon: <GiRing size="5rem" color="#343a40" />,
  //     p: 'RAW MATERIAL',
  //     span: "TEST PLAN (RUBBER & O'RING  PARTS)",
  //     isIcon: true,
  //   },
  //   {
  //     sNo: 10,
  //     link: `/common/SSTPlanTiles`,
  //     isLink: true,
  //     icon: <GrServices size="5rem" color="#343a40" />,
  //     p: 'SST',
  //     span: 'PLAN',
  //     isIcon: true,
  //   },
  //   {
  //     sNo: 10,
  //     link: `/common/TubesTiles`,
  //     isLink: true,
  //     icon: <GiTeePipe size="5rem" color="#343a40" />,
  //     p: 'Tube',
  //     span: 'PLAN',
  //     isIcon: true,
  //   },
  //   {
  //     sNo: 10,
  //     link: `/common/BOPPlanTiles`,
  //     isLink: true,
  //     icon: <GiUnbalanced size="5rem" color="#343a40" />,
  //     p: 'BOP',
  //     span: 'PLAN',
  //     isIcon: true,
  //   },
];

const RIDeparment = () => {
  return (
    <>
      <ChildHeader text="RI DEPARTMENT" />
      <RenderTiles
        height="100%"
        tilesObj={tiles}
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default RIDeparment;
