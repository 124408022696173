import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { TimePicker } from '@mui/x-date-pickers';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import moment from 'moment';
import { calculateHourDifference } from '../../../../../../helpers/timeDifferenceMinutes';
import { convToTime } from '../../../../../../utils/helpers';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  const updateBreakdownTime = () => {
    if (
      moduleData.breakdown_receipt_time &&
      moduleData.machine_ok_Date &&
      moduleData.machine_ok_time
    ) {
      const startDateTime = moment(moduleData.breakdown_receipt_time);
      const endDateTime = moment(moduleData.machine_ok_Date).set({
        hour: moment(moduleData.machine_ok_time).hour(),
        minute: moment(moduleData.machine_ok_time).minute(),
      });

      moduleData.machine_breakdown_time_min = calculateHourDifference(
        startDateTime,
        endDateTime
      );
    } else {
      moduleData.machine_breakdown_time_min = 0;
    }

    setRender((prev) => !prev);
  };

  const handleDateChange = (date: any, type: any) => {
    moduleData[type] = date;
    updateBreakdownTime();
  };

  const handleTimeChange = (date: any, type: any) => {
    moduleData[type] = date;
    updateBreakdownTime();
  };

  return (
    <>
      <tbody>
        <tr>
          <th colSpan={2} align="left">
            Die Name :{' '}
            <TableInput
              isView={isView}
              name="die_name"
              type="text"
              value={moduleData?.die_name}
              onChange={(e) => {
                moduleData.die_name = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
          <th colSpan={3} align="left">
            Breakdown Details :{' '}
            <TableInput
              isView={isView}
              name="breakdown_details"
              type="text"
              value={moduleData?.breakdown_details}
              onChange={(e) => {
                moduleData.breakdown_details = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
        <tr>
          <th style={{ minWidth: '150px' }} colSpan={3} align="left">
            Breakdown Receipt Time :{' '}
            {isView ? (
              moment(moduleData?.breakdown_receipt_time).format('hh:mm A')
            ) : (
              <TimePicker
                value={moment(moduleData?.breakdown_receipt_time || null)}
                onChange={(date) =>
                  handleTimeChange(date, 'breakdown_receipt_time')
                }
              />
            )}
          </th>
          <th colSpan={2} align="left">
            Prodn. Sup/Incharge Signature :{' '}
            <TableInput
              isView={isView}
              name="prod_incharge_sign"
              type="text"
              value={moduleData?.prod_incharge_sign}
              onChange={(e) => {
                moduleData.prod_incharge_sign = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
        <tr>
          <th style={{ minWidth: '150px' }} colSpan={3} align="left">
            Breakdown Receipt Time :{' '}
            {isView ? (
              convToTime(moduleData?.breakdown_receipt_time)
            ) : (
              <TimePicker
                value={moment(moduleData?.breakdown_receipt_time || null)}
                onChange={(date) => {
                  moduleData.breakdown_receipt_time = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </th>
          <th colSpan={2} align="left">
            Maint. Sup/Incharge Signature :{' '}
            <TableInput
              isView={isView}
              name="maint_incharge_sign"
              type="text"
              value={moduleData?.maint_incharge_sign}
              onChange={(e) => {
                moduleData.maint_incharge_sign = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
        <tr>
          <th colSpan={5} align="left">
            Action Taken :{' '}
            <TableInput
              isView={isView}
              name="action_taken"
              type="text"
              value={moduleData?.action_taken}
              onChange={(e) => {
                moduleData.action_taken = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
        <tr>
          <th colSpan={3} align="left">
            Spares Replaced If Any :{' '}
            <TableInput
              isView={isView}
              name="spare_replaced"
              type="text"
              value={moduleData?.spare_replaced}
              onChange={(e) => {
                moduleData.spare_replaced = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
          <th colSpan={2} align="left">
            Job Done By :{' '}
            <TableInput
              isView={isView}
              name="job_done_by"
              type="text"
              value={moduleData?.job_done_by}
              onChange={(e) => {
                moduleData.job_done_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
        <tr>
          <th style={{ minWidth: '150px' }} colSpan={3} align="left">
            Machine O.K Date / Time :{' '}
            {isView ? (
              moment(moduleData?.machine_ok_Date).format('DD-MM-YYYY') +
              '  ' +
              moment(moduleData?.machine_ok_time).format('hh:mm A')
            ) : (
              <>
                <DatePickerMui
                  label=""
                  value={
                    moduleData?.machine_ok_Date
                      ? moment(moduleData.machine_ok_Date)
                      : null
                  }
                  onChange={(date) => handleDateChange(date, 'machine_ok_Date')}
                />
                <TimePicker
                  value={moment(moduleData?.machine_ok_time || null)}
                  onChange={(date) => handleTimeChange(date, 'machine_ok_time')}
                />
              </>
            )}
          </th>
          <th colSpan={2} align="left">
            Prodn. Sup/Incharge Signature :{' '}
            <TableInput
              isView={isView}
              name="prod_incharge_sign_new"
              type="text"
              value={moduleData?.prod_incharge_sign_new}
              onChange={(e) => {
                moduleData.prod_incharge_sign_new = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
        <tr>
          <th colSpan={3} align="left">
            Total Machine Breakdown Time (In Minutes) :{' '}
            {moduleData.machine_breakdown_time_min}
          </th>
          <th colSpan={2} align="left">
            Maint. Sup/Incharge Signature :{' '}
            <TableInput
              isView={isView}
              name="maint_incharge_sign_new"
              type="text"
              value={moduleData?.maint_incharge_sign_new}
              onChange={(e) => {
                moduleData.maint_incharge_sign_new = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
      </tbody>
    </>
  );
};

export default TBody;
