const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard_1: any = [
  [
    {
      text: 'Date',
      style: { ...style1, minWidth: '200px', width: '200px' },
    },
    {
      text: 'Topic of training',
      style: { ...style1 },
    },
    {
      text: 'Trainer',
      style: { ...style1 },
    },
    { text: 'Dept', style: { ...style1, minWidth: '150px', width: '130px' } },
    {
      text: '',
      style: { color: '#fff', backgroundColor: '#495057' },
    },
  ],
];
export const HeaderStandard_2: any = [
  [
    {
      text: 'Description of Training',
      style: { ...style1 },
    },
    {
      text: 'Name of Trainee',
      style: { ...style1 },
    },
    {
      text: 'Signature',
      style: { ...style1 },
    },
    {
      text: 'Remarks',
      style: { ...style1, minWidth: '150px', width: '130px' },
    },
    {
      text: '',
      style: { color: '#fff', backgroundColor: '#495057' },
    },
  ],
];

export const listConstant = {
  childHeader: 'List of Training Topics',
  addButtonLable: 'New Topic',
};

export const headerConstant = {
  tableCompHeadTitle: 'ON JOB TRAINING RECORD (OJT)',
};

export const apiConstant = {
  getById: 'ojtRoute',
  post: 'ojtRoute',
  update: 'ojtRoute',
  delete: 'ojtRoute',
};

export const tilesConstant = {
  childHeaderText: 'ON JOB TRAINING RECORD (OJT)',
};

export const navigationConstant = {
  createEditChecklist: '/common/empTraningTopicsCreateEdit',
};
