const common_list = {
  parameter:"",
  header:"", 
  uom:"",
  status:null,
  target:null,
  month:new Array(13).fill(null),
}

export const c_list = { 
    customer_id:null,
    ...common_list
}
export const d_list = {
   department_id:null,
  ...common_list
}

export const s_list = {
   supplier_id:null,
  ...common_list
}
export const m_list = {
   machine_id:null,
  ...common_list
}

const customer_list = [
  [{...c_list,...{ parameter:"Quality",header:"Customers complaints", uom:"Nos.",}}],
  [{...c_list,...{ parameter:"Quality",header:"Customer Return PPM", uom:"PPM",}}],
  [{...c_list,...{ parameter:"Quality",header:"Machining Rejection PPM", uom:"PPM",}}],
  [{...c_list,...{ parameter:"Quality",header:"In process Defect Casting",uom:"PPM",}}],
  [{...c_list,...{ parameter:"Marketing",header:"Customer Feed Back",uom:"%age",}}],
  [{...c_list,...{ parameter:"Marketing",header:"Customer Score Card", uom:"%age",}}],
  [{...c_list,...{ parameter:"Dispatch",header:"On time delivery",  uom:"%age",}}],
];
const department_list = [
  [{...d_list,...{ parameter:"Maintenance",header:"Machine Breakdown", uom:"Hours",}}],
  [{...d_list,...{  parameter:"Management",header:"Total sales", uom:"Cr.",}}],
  [{...d_list,...{parameter:"Tool Maintenance",header:"Tool Breakdown Hours",uom:"Hours",}}],
];
const supplier_list = [
  [{...s_list,...{parameter:"Purchase",header:"Supplier rating",uom:"%age",}}],
];
const machine_list = [
  [{...m_list,...{parameter:"Production",header:"Prod. planning v/s actual ", uom:"%age",}}],
];
const normal_list = [
  {
   parameter:"HR",
   header:"Training Hours",
   uom:"Hours",
   status:"86 Hrs.",
   target:"100 Hrs/ Total  hrs in year",
   month:new Array(13).fill(null),
  },
  {
   parameter:"Store",
   header:"Raw material availability no of time shortage",
   uom:"Times",
   status:"0",
   target:"0 Times",
   month:new Array(13).fill(null),
  },
  {
   parameter:"Quality",
   header:"Reduce Cost of Poor Quality",
   uom:"%age",
   status:"5.05%",
   target:"3%",
   month:new Array(13).fill(null),
  },
  {
   parameter:"Marketing",
   header:"New Customer development",
   uom:"Nos",
   status:"2 parts",
   target:"Min-01 Customer / Year",
   month:new Array(13).fill(null),
  },
  {
   parameter:"NPD",
   header:"On time development",
   uom:"%age",
   status:"100%age",
   target:"",
   month:new Array(13).fill(null),
  },
  {
   parameter:"MSA",
   header:"Variation reduce in Variable MSA",
   uom:"%age",
   status:"<10%",
   target:"",
   month:new Array(13).fill(null),
  },
  {
   parameter:"MSA",
   header:"Attribute MSA",
   uom:"Acceptable",
   status:"",
   target:"",
   month:new Array(13).fill(null),
  },
  {
   parameter:"SPC",
   header:"Inhance Cpk value",
   uom:"%age",
   status:"> 1.67%",
   target:"",
   month:new Array(13).fill(null),
  },
  {
   parameter:"PURCHASE",
   header:"",
   uom:"%age",
   status:"Plan",
   target:"",
   month:new Array(13).fill(null),
  },
  {
   parameter:"PURCHASE",
   header:"",
   uom:"%age",
   status:"Actual",
   target:"",
   month:new Array(13).fill(null),
  },
 ];

export const module_data = {
  customer_list,
  department_list,
  supplier_list,
  machine_list,
  normal_list,
}