import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import { TableInput } from '../../../../components/common';
import { Checkbox } from '@mui/material';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [tools, setTools] = useState([]);
  useEffect(() => {
    axios
      .get('/api/masterListOfToolsOrDieRoute/toolsList/toolsForAutoComplete')
      .then((res) => {
        if (res.data) setTools(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <thead>
      <TableCompHead
        colSpan={3}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-PROD-14', '01', '17.11.2018']}
        heading="LAST SHOT REPORT"
      />
      <tr>
        <th align="left">
          Schedule Complete Without Breakdown:
          <Checkbox
            checked={moduleData.schedule_complete || ''}
            disabled={isView}
            onChange={(e) => {
              moduleData.schedule_complete = e.target.checked;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left">
          Date:
          {isView ? (
            formatDate(moduleData.report_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.report_date)}
              onChange={(date) => {
                moduleData.report_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left">
          Breakdown Die Upload:
          <Checkbox
            disabled={isView}
            checked={moduleData.breakdown_die_upload || ''}
            onChange={(e) => {
              moduleData.breakdown_die_upload = e.target.checked;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left">
          Die Name:
          {isView ? (
            moduleData?.tool_id?.die_name
          ) : (
            <AutocompleteMuiCustom
              id="Tools"
              label=""
              option_name="die_name"
              key={'tools'}
              arrayofObj={tools}
              value={moduleData?.tool_id || ''}
              onChange={(e, value) => {
                moduleData.tool_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left">
          Customer:
          <TableInput
            isView={isView}
            name="customer"
            type="text"
            value={moduleData.customer}
            onChange={(e: any) => {
              moduleData.customer = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left">
          Report No.:{moduleData.report_no}
          {/* <TableInput
            isView={isView}
            name="report_no"
            type="text"
            value={moduleData.report_no}
            onChange={(e: any) => {
              moduleData.report_no = e.target.value;
              setRender((prev) => !prev);
            }}
          /> */}
        </th>
      </tr>
      <tr>
        {[
          'PROBLEM IDENTIFIED',
          'PROPOSED CORRECTIVE ACTION',
          'CORRECTIVE ACTION TAKEN',
        ].map((head) => (
          <th style={{ textTransform: 'uppercase' }}>{head}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
