import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  console.log(moduleData);
  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((oneProcesses: any, index: number) =>
          oneProcesses.process.map((item: any, idx: number) => (
            <tr>
              {idx === 0 && (
                <td rowSpan={oneProcesses?.process?.length}>{index + 1}</td>
              )}

              {idx === 0 && (
                <td rowSpan={oneProcesses?.process?.length}>
                  {moduleData?.location}
                </td>
              )}
              {idx === 0 && (
                <td rowSpan={oneProcesses?.process?.length}>
                  {isView ? (
                    formatDate(oneProcesses.month_year)
                  ) : (
                    <DatePickerMui
                      value={
                        (oneProcesses.month_year &&
                          moment(oneProcesses.month_year)) ||
                        null
                      }
                      onChange={(date) => {
                        oneProcesses.month_year = date?._d;
                        console.log(oneProcesses.month_year);
                        setRender((prev) => !prev);
                      }}
                      format={'MMMM-YY'}
                      views={['month', 'year']}
                      sx={{ width: '150px' }}
                    />
                  )}
                </td>
              )}

              <td style={{ minWidth: '150px' }}>{item?.checkpoint}</td>

              <td>
                <TableInput
                  isView={isView}
                  name="cleaning_of_light"
                  type="text"
                  value={item.cleaning_of_light}
                  onChange={(e) => {
                    item.cleaning_of_light = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="charging_of_light"
                  type="text"
                  value={item.charging_of_light}
                  onChange={(e) => {
                    item.charging_of_light = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="wire_of_light"
                  type="text"
                  value={item.wire_of_light}
                  onChange={(e) => {
                    item.wire_of_light = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="working_of_light"
                  type="text"
                  value={item.working_of_light}
                  onChange={(e) => {
                    item.working_of_light = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="checking_by"
                  type="text"
                  value={item.checking_by}
                  onChange={(e) => {
                    item.checking_by = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="inspected_by"
                  type="text"
                  value={item.inspected_by}
                  onChange={(e) => {
                    item.inspected_by = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {idx === 0 && (
                <td
                  style={{ minWidth: '100px', textAlign: 'center' }}
                  rowSpan={oneProcesses?.process?.length}
                >
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))
        )}
    </tbody>
  );
};

export default TBody;
