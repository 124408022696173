export const checklistData = [
  {
    checkPoint: ' फिक्स साइड का इन्सर्ट चेक करना है | ',
    standard: ' कट्टा या टुटा न हो ',
    action: ' वेल्डिंग करके मैचिंग करनी है ',
    method: ' देख कर ',
  },
  {
    checkPoint: ' फिक्स साइड का गाइड वेज चेक करना है |',
    standard: '  टुटा न हो',
    action: ' बदलना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' फिक्स साइड की सभी पिन चेक करनी है',
    standard: ' चिप ऑफ़ या मुड़ा  न हो',
    action: ' बदलना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' फिक्स साइड का एंगल पिलर चेक करना है',
    standard: ' मुड़ा  न हो',
    action: ' बदलना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' चिल वेंट चेक करना है',
    standard: ' कैचिंग या बंद न हो',
    action: ' साफ़ करना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' फिक्स साइड का गाइड पिन चेक करना है',
    standard: ' चिप ऑफ़ या मुड़ा  न हो',
    action: ' बदलना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' मूविंग साइड का पार्टिंग सरफेस चेक करना है',
    standard: ' कट्टा या टुटा न हो',
    action: ' वेल्डिंग करके मैचिंग करनी है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' मूविंग साइड का कैविटी चेक करना है',
    standard: ' कट्टा या टुटा न हो',
    action: ' वेल्डिंग करके मैचिंग करनी है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' मूविंग साइड का स्लाइडर कोर चेक करे',
    standard: ' जाम न हो',
    action: ' साफ़ करना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' कोर पिन चेक करे',
    standard: ' सोल्डरिंग व् अन्य कुछ चिपका व् कट मार्क न हो',
    action: ' पोलिश करनी है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' स्लाइडर कोर पिन चेक करे |',
    standard: ' सोल्डरिंग व् अन्य कुछ चिपका व् कट मार्क न हो',
    action: ' पोलिश करनी है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' डॉग लिमिट प्लेट ,कपलिंग चेक करे |',
    standard: ' टूटी  नीचे न हो ',
    action: ' बदलना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' स्लाइडर कोर चेक करे |',
    standard: ' चिप ऑफ़ या मुड़ा  न हो',
    action: ' बदलना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' इजेक्टर पिन & रिटर्न  पिन चेक करे',
    standard: ' टूटी,बैंड या लेबल ऊपर नीचे न हो ',
    action: ' बदलना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' गेट चेक करना है  |',
    standard: ' टुटा न हो',
    action: ' बदलना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' ओवरफ्लो एरिया चेक करे |',
    standard: ' टुटा न हो',
    action: ' बदलना है',
    aNa: '',
    method: ' देख कर ',
  },
  {
    checkPoint: ' कोई फ्लैश या चिप न हो |',
    standard: ' ठीक हो',
    action: ' साफ़ करे',
    method: ' देख कर ',
  },
  {
    checkPoint:
      ' इजेक्टर / कोर पिन पर तेल व हाई टेंपरेचर ग्रीस  लगा होना चेक करे |',
    standard: ' सुखी न हो',
    action: ' तेल व ग्रीस  लगाना है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' लूस पंच चेक करें  |',
    standard: ' अच्छे से लोक होनी चाहिए',
    action: ' लोक करनी है',
    method: ' देख कर ',
  },
  {
    checkPoint: ' डाइ की मुविंग और फिक्स  साइड मे कार्बन चेक करे |',
    standard: ' कार्बन नही होना चाहिए |',
    action: ' पोलिश करे |',
    method: ' देख कर ',
  },
  {
    checkPoint: ' सिलिंडर की स्ट्रोक की लंबाई चेक करे |',
    standard: ' डिज़ाइन के    अनुसार |',
    action: ' सही करना है |',
    method: ' देख कर |',
  },
  {
    checkPoint: ' गेट के नीचे लगी इन्सर्ट को चेक करे |',
    standard: ' वियर आउट ना  हो |',
    action: ' बदलना है',
    method: ' देख कर |',
  },
  {
    checkPoint: ' सेन्टर ओवर फ्लो  को चेक करना है |',
    standard: ' वियर आउट ना  हो |',
    action: ' सही करना है |',
    method: ' देख कर |',
  },
  {
    checkPoint: ' होल पिन चेक करे |',
    standard: ' वियर आउट ना  हो |',
    action: ' सही करना है |',
    method: ' देख कर |',
  },
  {
    checkPoint: ' लिमिट स्विच व् तार चेक करे |',
    standard: ' कटा / ख़राब ना हो |',
    action: ' बदलना है',
    method: ' देख कर |',
  },
  {
    checkPoint: ' गाइड पिलर का फिटमेंट चेक करे |',
    standard: ' सही से लॉक हो  |',
    action: ' लॉक करना है |',
    method: ' देख कर |',
  },
  {
    checkPoint: ' इजेक्टर प्लेट का बोल्ट चेक करे |',
    standard: ' लूज़ ना हो |',
    action: ' टाइट करना है |',
    method: ' ALLEN KEY',
  },
  {
    checkPoint: ' होल पिन के पीछे गड्डा चेक करे |',
    standard: ' प्लेन होना चाहिए |',
    action: ' वेल्डिंग करना है |',
    method: ' देख कर |',
  },
  {
    checkPoint: ' मोल्ड में कूलिंग पाइप को चेक करना है कि वह चोक तो नहीं है',
    standard: ' बंद  नहीं होना चाहिए',
    action: ' खोलना है',
    method: ' देख कर |',
  },
];
