import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import TFoot from './TFoot';
interface IProps {
  apiData: any;
}

const TController = ({ apiData }: IProps) => {
  return (
    <Box style={{ width: '80vw', margin: '14rem' }}>
      <RenderTable>
        <THead />
        <TBody apiData={apiData} />
        <TFoot apiData={apiData} />
      </RenderTable>
    </Box>
  );
};

export default TController;
