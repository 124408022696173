import { Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../components/common/button/CustomIconButton';
interface IProps {
  isView: boolean;
  data: any;
  removePart: () => void;
  addPart: () => void;
}
const PickPartPlanUi = ({ isView, data, removePart, addPart }: IProps) => {
  return (
    <>
      <Grid
        sx={{ border: '2px solid #d3f9d8' }}
        container
        xs={12}
        direction="row"
        alignItems={'center'}
      >
        <Grid item xs={10}>
          <p
            style={{
              fontSize: '1.2rem',
            }}
          >
            {typeof data?.row_id === 'object' && data?.row_id !== null
              ? data?.row_id?.part_name
              : data?.a1 || ''}
            {!data?.row_id || (data?.row_id === undefined && 'Pick Part Name')}
          </p>
        </Grid>

        {data.row_id ? (
          <Grid item xs={2}>
            <RemoveIconButton tooltipTitle="remove part" onClick={removePart} />
          </Grid>
        ) : (
          <Grid item xs={2}>
            <AddIconButton tooltipTitle="add part" onClick={addPart} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PickPartPlanUi;
