import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CloseButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import formatDate from '../../../components/common/formatDate';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import useConfirm from '../../../components/common/useConfirm';
import TController from './components/create/TController';
import lodash from 'lodash';

interface MyData {
  moduleData: any; // replace PirType with the type of your `pir` data
  isLoading: boolean;
  error?: string | null;
}
const months = {
  april: {
    isChecked: false,
  },
  may: {
    isChecked: false,
  },
  june: {
    isChecked: false,
  },
  july: {
    isChecked: false,
  },
  august: {
    isChecked: false,
  },
  september: {
    isChecked: false,
  },
  october: {
    isChecked: false,
  },
  november: {
    isChecked: false,
  },
  december: {
    isChecked: false,
  },
  january: {
    isChecked: false,
  },
  february: {
    isChecked: false,
  },
  march: {
    isChecked: false,
  },
};
const Create = () => {
  const { id } = useParams();
  // const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [moduleData, setModuleData] = useState<any>({ levels: [] });
  const [render, setRender] = useState(false);
  const [facultyAutocomplete, setFacultyAutocomplete] = useState([]);
  const [allFaculty, setAllFaculty] = useState([]);

  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );
  const [DialogSave, confirmSave] = useConfirm(
    'CLOSE',
    'Are you sure you want to save and modify this document?'
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'SAVE',
    'Are you sure you want to save?'
  );
  const [DialogDeleteProcess, confirmDeleteProcess] = useConfirm(
    'DELETE QUESTION',
    'Are you sure you want to delete this Question?'
  );
  const [DialogAddTask, confirmDialogAddTask] = useConfirm(
    'Add Task',
    'Are you sure you want to add a Task?'
  );
  const [DialogRemoveTask, confirmDialogRemoveTask] = useConfirm(
    'Remove Task',
    'Are you sure you want to remove a Task?'
  );
  const [DialogRemoveRow, confirmDialogRemoveRow] = useConfirm(
    'Remove Row',
    'Are you sure you want to remove this row'
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/annualTrainingPlan/getAnnualTrainingById/${id}`)
        .then((res) => {
          setModuleData(res.data);
          const facultyforAutoComplete = res.data.processes.map((item: any) => {
            if (item.faculty)
              return {
                faculty_id: item.faculty?._id,
                faculty_name: item.faculty?.faculty_name,
                faculty_type: item.faculty?.faculty_type,
              };
          });
          setFacultyAutocomplete(facultyforAutoComplete);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    if (moduleData?.processes && moduleData?.processes.length === 0) {
      axios
        .get(`/api/tni/getTopicByDepartment/${moduleData?.departments?._id}`)
        .then((res) => {
          if (res.data) {
            moduleData.processes = Array.from(
              { length: res.data.topics.length },
              (_, index) => ({
                training_topic: res.data.topics[index]?.name,
                topic_id: res.data.topics[index]?._id,
                faculty: null,
                internal_external: '',
                participant: '',
                reamarks: '',
                training_months: lodash.cloneDeep(months),
                // ...res.data?.topics[index],
              })
            );
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(`/api/tni/getTopicByDepartment/${moduleData?.departments?._id}`)
        .then((res) => {
          if (res.data) {
            const topicObj: any = {};
            res.data.topics.map((topic: { _id: string | number }) => {
              topicObj[topic._id] = topic;
            });
            const temp = { ...moduleData };
            temp.processes.map(
              (process: { topic_id: string }, pIdx: string | number) => {
                if (process.topic_id in topicObj) {
                  moduleData.processes[pIdx].training_topic =
                    topicObj[process.topic_id]?.name;
                  delete topicObj[process.topic_id];
                } else {
                  temp.processes.splice(pIdx, 1);
                }
              }
            );
            for (let key in topicObj) {
              moduleData?.processes.push({
                training_topic: topicObj[key]?.name,
                topic_id: topicObj[key]?._id,
                faculty: null,
                internal_external: '',
                participant: '',
                reamarks: '',
                training_months: lodash.cloneDeep(months),
              });
            }
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [moduleData]);
  useEffect(() => {
    axios
      .get(`/api/masterlistofFaculty`)
      .then((res) => {
        const faculty = res?.data?.results.map(
          (item: { _id: any; faculty_name: any; faculty_type: any }) => {
            return {
              faculty_id: item._id,
              faculty_name: item.faculty_name,
              faculty_type: item.faculty_type,
            };
          }
        );
        setAllFaculty(faculty);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleDoubleClick = async (
    event: { detail: number },
    month: string | number,
    idx: string | number
  ) => {
    if (isView) return;
    if (event.detail === 2) {
      if (moduleData.processes[idx].training_months[month].isChecked) {
        let ans = await confirmDialogRemoveTask();
        if (ans) {
          moduleData.processes[idx].training_months[month].isChecked = false;
          setRender((prev) => !prev);
        }
      } else {
        let ans = await confirmDialogAddTask();
        if (ans) {
          moduleData.processes[idx].training_months[month].isChecked = true;
          setRender((prev) => !prev);
        }
      }
    }
  };
  const handleSaveAndContinue = async () => {
    let ans = await confirmUpdate();
    if (!ans) return;
    axios
      .put(`/api/annualTrainingPlan/updateAnnualTraining/${id}`, moduleData)
      .then((res) => {
        window.alert('success');
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };
  const handleDeleteRow = async (idx: any) => {
    const ans = await confirmDialogRemoveRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(idx, 1);
    setModuleData(data);
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="ANNUAL TRAINING PLAN FOR STAFF">
          {!isView && (
            <SaveButton
              label="Save & Continue"
              onClick={() => handleSaveAndContinue()}
            />
            //  <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
          )}
          <CloseButton
            onClick={async () => {
              let ans = await confirmClose();
              if (!ans) return;
              navigate(-1);
            }}
            label="Close"
          />
        </ChildHeader>
        <TController
          {...{
            moduleData,
            isView,
            allFaculty,
            facultyAutocomplete,
            handleDoubleClick,
          }}
        />
      </Box>
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
      <DialogDeleteProcess isSubmitContent={false} />
      <DialogAddTask isSubmitContent={false} />
      <DialogRemoveTask isSubmitContent={false} />
      <DialogClose isSubmitContent={false} />
      <DialogSave isSubmitContent={false} />
      <DialogRemoveRow isSubmitContent={false} />
    </Box>
  );
};

export default Create;
