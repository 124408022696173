export const calculateDueDate = (date: any, freq: any) => {
  if (
    date === undefined ||
    date === null ||
    date === ' ' ||
    date === '' ||
    freq === '' ||
    freq === 0 ||
    freq === undefined
  ) {
    return '';
  }

  // const freqInMonth = Math.ceil(freq * 12);
  const freqInMonth = Math.ceil(freq); // because freq heare we are getting in months

  let calcDate = new Date(date);

  const dueDateMili = calcDate.setMonth(
    calcDate.getMonth() + freqInMonth,
    calcDate.getDate() - 1
  );

  const dueDate = new Date(dueDateMili).toLocaleDateString('en-GB').split('/');
  return `${dueDate[1]}/${dueDate[0]}/${dueDate[2]}`;
};

// export const calculateDueDate = (date: any, freq: any) => {
//   if (
//     date === undefined ||
//     date === null ||
//     date === ' ' ||
//     date === '' ||
//     freq === '' ||
//     freq === 0 ||
//     freq === undefined
//   ) {
//     return null;
//   }

//   const freqInMonth = Math.ceil(Number(freq) * 12);
//   let calcDate = new Date(date);

//   calcDate.setMonth(calcDate.getMonth() + freqInMonth, calcDate.getDate() - 1);

//   return new Date(calcDate);
// };
