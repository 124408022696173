export const listConstant = {
  childHeader: 'KPI list',
  addButtonLable: 'New KPI',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW KPI',
};

export const createConstant = {
  childHeader: 'QUALITY OBJECTIVES & TARGET',
  theadHeader: 'QUALITY OBJECTIVES & TARGET',
  extracolSpan: 3,
};


export const apiConstant = {
  deleteurl: 'kpiRoute',
  gettableDataurl: 'kpiRoute/monthly',
  getPlanByid: 'kpiRoute',
  createPlanByid: 'kpiRoute',
  updatePlanByid: 'kpiRoute',
  fileUpload: 'kpiRoute/handleUpload',
  fileDeleteImage: 'kpiRoute/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'QUALITY OBJECTIVES & TARGET',
};

export const navigationConstant = {
  createPlanSchedule: '/common/kpiCreate',
};
 
