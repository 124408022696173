import React, { useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../../../components/common';
import formatDate from '../../../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import { UpdateButton } from '../../../../../../components/common/button/CustomButtons';
import CustomUploadButton from '../../../../../../components/common/button/CustomUploadButton';
import ModalCustom from '../../../../../../components/common/Modal';

interface IProps {
  handleChangeStatus: (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => void;
  deleteDocument: (id: string) => void;
  updateStatus: (id: string, documentIndex: number) => void;
  statusObj: {
    pending: number;
    accepted: number;
    rejected: number;
  };
  tableData: {
    [key: string | number]: any;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'MOLD NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'DEPARTMENT',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'FREQUENCY',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const AdminTable = ({
  handleChangeStatus,
  deleteDocument,
  updateStatus,
  statusObj,
  tableData,
}: // uploadFile,
IProps) => {
  const navigate = useNavigate();
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">
                  {document?.mold_id?.mold_name}
                </TableCell>
                <TableCell align="center">
                  {document?.mold_id?.department_id?.department_name}
                </TableCell>
                <TableCell align="center">
                  {document?.mold_id?.frequency}
                </TableCell>
                <TableCell align="center">
                  {formatDate(document.inspection_date)}
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/createBeforeDieCastingDailyCheckSheet/${document.mold_id?._id}/${document._id}?isView=true&partName=${document.ri_part_id?.part_name}&partNumber=${document.ri_part_id?.part_number}`
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/createBeforeDieCastingDailyCheckSheet/${document.mold_id?._id}/${document._id}?isView=false&partName=${document.ri_part_id?.part_name}&partNumber=${document.ri_part_id?.part_number}`
                      )
                    }
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default AdminTable;
