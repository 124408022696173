import moment from 'moment';

export const processes = [
  {
    part_id: null,
    customer_id:null,
    defect_no : {
      actual: new Array(22).fill(null),
      result: new Array(22).fill(null),
      status: new Array(22).fill(null),
    },
  },
];

export const initialState = {
  name: "",
  moduleData_Date: moment(),
  defect_details : new Array(18).fill(""),
  processes: Array.from({ length: 1 }, () => ({ ...processes[0] })),
  performed_by:"",
  approved_by:"",
};
