import { TableInput } from '../../../../../../components/common';
import { createConstant } from '../../constant';

// interface Ifoot {
//   preparedBy: string | null | undefined;
//   approvedBy: string | null | undefined;
// }
interface Ifoot {
  moduleData: any;
  isView: boolean;
  handleChecklist: any;
  checklistType: any;
}
const TFoot = ({
  moduleData,
  isView,
  handleChecklist,
  checklistType,
}: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <td align="left" colSpan={3}>
          Electrician / Fitter Signature:
          <TableInput
            name="maintenance_engg"
            value={moduleData.electrician_sign}
            onChange={(e) => {
              moduleData.electrician_sign = e.target.value;
            }}
            isView={isView}
            type={'text'}
          />
        </td>
        <td align="left" colSpan={3}>
          Maintenance Engg.:
          <TableInput
            name="maintenance_engg"
            value={moduleData.maintenance_engg}
            onChange={(e) => {
              moduleData.maintenance_engg = e.target.value;
            }}
            isView={isView}
            type={'text'}
          />
        </td>
        <td align="left" colSpan={2}>
          Preventive Maintenance date:
          {moduleData.preventive_time}
        </td>
      </tr>
    </tfoot>
  );
};

export default TFoot;
