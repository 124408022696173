import { TbAspectRatio } from 'react-icons/tb';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { BiLogoMediumOld } from 'react-icons/bi';
import { LiaCodiepie } from 'react-icons/lia';
import { FaCheckToSlot } from 'react-icons/fa6';
import { AiOutlineFundView } from 'react-icons/ai';
import { MdReceiptLong } from 'react-icons/md';

// import { tilesConstant } from './helpers/constant';

const tiles = [
  {
    sNo: 2,
    link: '/common/diePmTiles',
    isLink: true,
    icon: <FaCheckToSlot size="5rem" color="purple" />,
    p: 'DIE',
    span: 'PM PLAN',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/dieLoadingChecklistTableList',
    isLink: true,
    icon: <LiaCodiepie size="5rem" color="orange" />,
    p: 'BEFORE DIE LOADING',
    span: 'CHECKSHEET',
    isIcon: true,
  },
  // {
  //   sNo: 3,
  //   link: '/common/beforeDieCastingCheckSheetList',
  //   isLink: true,
  //   icon: <LiaCodiepie size="5rem" color="orange" />,
  //   p: 'Before Loading Die',
  //   span: 'Checksheet (formats)',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/common/beforeDieCastingDailyCheckSheetList',
  //   isLink: true,
  //   icon: <LiaCodiepie size="5rem" color="orange" />,
  //   p: 'Before Loading Die',
  //   span: 'Checksheet (Daily Basis)',
  //   isIcon: true,
  // },
  {
    sNo: 5,
    link: '/common/dieTrialRecord',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'Die Trial Record',
    span: '',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/toolBreakdownRecord',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlineFundView size="5rem" color="#a12222" />,
    p: 'Tool Breakdown',
    span: 'Record',
    isIcon: true,
  },
];

const Tiles = () => {
  return (
    <>
      <ChildHeader text={'Tool Room'} />
      {/* tilesConstant.childHeaderText */}
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Tiles;
