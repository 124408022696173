import { Box, Stack } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { PersitedDatePicker } from '../../../../components/common';
import { AddButton } from '../../../../components/common/button/CustomButtons';
import FilterBy from '../../../../components/common/FilterBy';
import { Loader } from '../../../../components/common/Loader';
import ModalCustom from '../../../../components/common/Modal';
import ChildHeader from '../../../../components/ui/ChildHeader';
import RIPartList from '../../../../components/ui/RIPartList';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import AdminTable from './components/tablelist/AdminTable';
// import SupervisorTable from './components/tablelist/SupervisorTable';
import MoldList from '../../../../components/ui/MoldList';

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

// const filterOptions = [
//   { value: 'BOP', name: 'BOP' },
//   { value: 'Plating', name: 'Plating' },
// ];
const TableList = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  const filter = useQuery('filter');
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // fetch data here
    let userType = '';
    if (state.user?.userType === 2) {
      userType = 'admin';
    } else {
      userType = 'supervisor';
    }
    axios
      .get(
        `/api/beforeLoadingDieDaily/monthly/${userType}/${date}?filter=${filter}`
      )
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, date, filter]);

  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/beforeLoadingDieDaily/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/beforeLoadingDieDaily/updateStatus/${id}`, {
          is_admin_request_no: tableData[documentIndex].is_admin_request_no,
          report_approved_by: state.user?.name,
          report_approved_by_date: new Date(),
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => {
    const values = [...tableData];
    values[documentIndex].is_admin_request = null;
    values[documentIndex].is_admin_request_no = e.target.value;
    setTableData(values);
  };
  // const uploadFile = async (e: any, id: string, fileType: string) => {
  //   if (!window.confirm('Are you sure you want upload this Files')) return;
  //   const files = e.target.files;
  //   if (!files) return;
  //   let formData = new FormData();
  //   for (const key of Object.keys(files)) {
  //     formData.append('files', files[key]);
  //   }
  //   setIsLoading(true);
  //   axios
  //     .patch(
  //       `/api/beforeLoadingDieDaily/uploadFile/${fileType}/${id}`,
  //       formData
  //     )
  //     .then((res) => {
  //       if (res.data) {
  //         alert('document upload successly');
  //         setIsLoading(false);
  //         setRender((prev) => !prev);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //     });
  // };
  return (
    <Box>
      <Loader loader={isLoading} />
      <ChildHeader text="Before Loading Die Checksheet">
        <AddButton label="New Report" onClick={() => setIsOpenPopup(true)} />
      </ChildHeader>
      <Stack
        sx={{ margin: 2 }}
        justifyContent="space-between"
        direction={'row'}
      >
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Stack>
      <AdminTable
        {...{
          handleChangeStatus,
          updateStatus,
          deleteDocument,
          statusObj,
          // uploadFile,
          tableData,
        }}
      />
      <ModalCustom
        title="Choose Mold"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <MoldList
          isMoldIdParam
          link={'/common/createBeforeDieCastingDailyCheckSheet'}
        />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
