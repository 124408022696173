import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../../hooks/UseQuery';

const useGetData = (
  processStdId: string | undefined,
  moduleId: string | undefined
) => {
  const [moduleData, setModuleData] = useState<any>({
    moduleData_date: new Date(),
    date_obs: [],
    processes: [],
    casting_rej_percentage: {
      total_qty: [],
      rejection_qty: [],
      rejection_percentage: [],
    },
    machining_rej_percentage: {
      total_qty: [],
      rejection_qty: [],
      rejection_percentage: [],
    },
    any_other_problem: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const machineNo = useQuery('machineNo');
  const part_id = useQuery('part_id');
  const machine_id = useQuery('machine_id');
  useEffect(() => {
    setIsLoading(true);
    if (moduleId) {
      axios
        .get(`/api/processValidationSheetRoute/${moduleId}`)
        .then((res) => {
          if (!res?.data?.date_obs) {
            res.data.date_obs = [];
          }
          if (
            !res?.data?.casting_rej_percentage ||
            !res?.data?.machining_rej_percentage ||
            !res?.data?.any_other_problem
          ) {
            res.data.casting_rej_percentage = {
              total_qty: [],
              rejection_qty: [],
              rejection_percentage: [],
            };
            res.data.machining_rej_percentage = {
              total_qty: [],
              rejection_qty: [],
              rejection_percentage: [],
            };
            res.data.any_other_problem = [];
          }
          setModuleData(res?.data);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/api/processParameterStdRoute/${processStdId}`)
        .then((res) => {
          const response = res.data;
          setModuleData((item: any) => {
            item.process_std_id = processStdId;
            item.part_id = part_id;
            item.machine_id = machine_id;
            item.moduleData_date = new Date();
            item.partNumber = partNumber;
            item.partName = partName;
            item.machineNo = machineNo;
            item.casting_rej_percentage = {
              total_qty: [],
              rejection_qty: [],
              rejection_percentage: [],
            };
            item.machining_rej_percentage = {
              total_qty: [],
              rejection_qty: [],
              rejection_percentage: [],
            };
            item.any_other_problem = [];
            item.date_obs = [null, null, null, null, null];

            item.processes = response?.specs?.map((spec: any) => {
              return {
                // start of spec in inspection
                parameter: spec.parameter,
                specification: spec?.specification,
                method: spec?.method,
                check4_symbol: spec.check4_symbol,
                checkbox4_num: spec.checkbox4_num,
                methods: spec.methods_measure,
                appearance: spec.appearance,
                mean: spec.mean,
                utolerance: spec.utolerance,
                ltolerance: spec.ltolerance,
                utl: spec.utl,
                ltl: spec.ltl,
                check_0_colors: Array.from(Array(5)),
                check_0_values: Array.from(Array(5)),
              };
            });
            return { ...item };
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, []);
  return { moduleData, isLoading, error };
};

export default useGetData;
