export const listConstant = {
  childHeader: 'Supplier Audit List',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Supplier Audit List',
  theadHeader: 'Supplier Audit Plan',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'Supplier Audit STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'supplierAuditPlanAprilToMarchRoute',
  gettableDataurl: 'supplierAuditPlanAprilToMarchRoute/monthly',
  getPlanByid: 'supplierAuditPlanAprilToMarchRoute',
  createPlanByid: 'supplierAuditPlanAprilToMarchRoute',
  updatePlanByid: 'supplierAuditPlanAprilToMarchRoute',
  sync: 'supplierAuditPlanAprilToMarchRoute/handleSync',
  getAllCheckList: 'supplierAuditPlanAprilToMarchRoute/getAllCheckList',
  checkListStatus: 'supplierAuditPlanAprilToMarchRoute/handleStatus',
  checklistUpload: 'supplierAuditPlanAprilToMarchRoute/handleUpload',
  checklistDeleteImage: 'supplierAuditPlanAprilToMarchRoute/handleDeleteImage',
  checklistData: 'supplierAuditPlanAprilToMarchRoute/checklistRoute',
  getMasterDataAutocomplete: 'vendorRoute/vendor/forAutoComplete',
};

export const tilesConstant = {
  childHeaderText: 'Supplier Audit',
};

export const navigationConstant = {
  createPlanSchedule: '/common/supplierAuditPlanAprilToMarchCreate',
  createChecklist: '/common/supplierAuditPlanAprilToMarchChecklistCreate',
};
