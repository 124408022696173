export const listConstant = {
  childHeader: 'TNI LIST',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW TNA',
};

export const createConstant = {
  childHeader: 'TRAINING NEED IDENTIFICATION REPORT',
  theadHeader: 'TRAINING NEED IDENTIFICATION (ASSOCIATE) ',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'LAYOUT STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'mtbfRoute',
  gettableDataurl: 'tniReport/getTniReport/yearly',
  getPlanByid: 'mtbfRoute',
  createPlanByid: 'mtbfRoute',
  updatePlanByid: 'mtbfRoute',
  sync: 'layoutplanRoute/handleSync',
  getAllCheckList: 'layoutplanRoute/getAllCheckList',
  checkListStatus: 'layoutplanRoute/handleStatus',
};

export const navigationConstant = {
  createPlanSchedule:
    '/common/createEditTNI/report',
};
