export const checkListList = {
  childHeader: 'ANNUAL PM PLAN UTILITY STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'annualPmPlanNewUtilityStatusRoute/getAllSatusByYear',
  upadateListStatus: 'annualPmPlanNewUtilityStatusRoute/handleStatusUpdate',
  checklistUpload: 'annualPmPlanNewUtilityStatusRoute/handleUpload',
  checklistDeleteImage: 'annualPmPlanNewUtilityStatusRoute/handleDeleteImage',
  utilityDataForAutoComplete: 'masterlistofAppearance/utility/ForAutocomplete',
};

export const navigationConstant = {
  checklistRouter: '/common/annulPmPlanNewUtility_StatusCheckList',
};
