import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useState,
} from 'react';
import { TableInput } from '../../../../../components/common';
import { createConstant } from '../../constant';
import formatDate from '../../../../../components/common/formatDate';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
  allFaculty: any;
  facultyAutocomplete: any;
  handleDoubleClick: any;
}
const TBody = ({
  moduleData,
  isView,
  allmonth,
  allFaculty,
  facultyAutocomplete,
  handleDoubleClick,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((item: any, index: number) => (
          <>
            <tr key={`${index}_table_row`}>
              <td>{index + 1}</td>
              <td width={'200px'}>{item.training_topic}</td>
              <td width="150px">
                {item.internal_external === 'EXTERNAL' ? (
                  <TableInput
                    isView={isView}
                    style={{ border: '1px solid #f9e0e0', height: '50px' }}
                    name="faculty_name"
                    type="text"
                    value={item?.faculty_name}
                    // defaultValue={item.faculty_name}
                    onChange={(e) => {
                      item.faculty_name = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                ) : isView ? (
                  item.faculty?.faculty_name
                ) : (
                  <AutocompleteMuiCustom
                    id="training_topics"
                    label=""
                    option_name="faculty_name"
                    arrayofObj={allFaculty}
                    value={
                      facultyAutocomplete[index] || item?.faculty?.faculty_name
                    }
                    onChange={(e, value) => {
                      facultyAutocomplete[index] = value;
                      value && (item.faculty = value.faculty_id);
                      value && (item.faculty_type = value.faculty_type);
                      // setAutoCompleteMachineValue(value);
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td width="200px">
                {item.faculty_type
                  ? item.faculty_type
                  : item.faculty?.faculty_type}
              </td>
              <td width="120px">
                <TableInput
                  isView={isView}
                  style={{ border: '1px solid #f9e0e0', height: '50px' }}
                  name="participant"
                  type="text"
                  value={item.participant}
                  onChange={(e) => {
                    item.participant = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {[
                'april',
                'may',
                'june',
                'july',
                'august',
                'september',
                'october',
                'november',
                'december',
                'january',
                'february',
                'march',
              ].map((month) => (
                <td
                  style={{
                    padding: '0px',
                    margin: '0px',
                    backgroundColor: '#f1f3f5',
                  }}
                  onClick={(e) => handleDoubleClick(e, month, index)}
                >
                  <InteractiveIcon
                    isYellow={item.training_months[month]?.isChecked}
                  >
                    {item.training_months[month]?.isChecked && '○'}{' '}
                  </InteractiveIcon>
                </td>
              ))}
              <td width="150px" colSpan={2}>
                <TableInput
                  isView={isView}
                  style={{ border: '1px solid #f9e0e0', height: '50px' }}
                  name="remarks"
                  type="text"
                  value={item.remarks}
                  onChange={(e) => {
                    item.remarks = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </tr>
          </>
        ))}
    </tbody>
  );
};

export default TBody;
