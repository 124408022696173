import { useEffect, useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { useQuery } from '../../../../../hooks/UseQuery';
import { getMonthYearly } from '../../helpers/utils';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
  setRender: any;
  setSelectedMonth: (month: any) => void;
  setModuleData: (data: any) => void;
}

const THead = ({
  moduleData,
  isView,
  setRender,
  setSelectedMonth,
  setModuleData,
}: IProps) => {
  const [monitoringList, setMoniteringList] = useState<
    { month_year: string }[]
  >([]);
  const date = useQuery('date');

  useEffect(() => {
    const year = date ? parseInt(date, 10) : new Date().getFullYear();
    const data = getMonthYearly(year);
    setMoniteringList(data);
  }, [date]);

  const handleMonthChange = (e: any, value: any) => {
    const selectedMonthYear = value?.month_year || '';

    setModuleData((prevData: any) => ({
      ...prevData,
      annual_month: selectedMonthYear,
    }));

    setSelectedMonth(selectedMonthYear);
  };

  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc. NO.', 'Rev No', 'Rev Date']}
        values={['F-EHS-25', '01', '26.O4.2023']}
        heading="Accident Record Form"
      />

      <tr>
        <th colSpan={2} align="left">
          Select Month:
        </th>
        <th colSpan={4} align="left" style={{ minWidth: '200px' }}>
          {/* <AutocompleteMuiCustom
            id="Month"
            label="Select Month"
            option_name="month_year"
            key="month_selector"
            arrayofObj={monitoringList}
            value={moduleData?.annual_month || ''}
            onChange={(e, value) => {
              handleMonthChange(e, value);
            }}
          /> */}
          <CustomSelect
            name="annual_month"
            value={moduleData?.annual_month || ''}
            label={''}
            onChange={(e) => {
              const value = e.target.value;
              setModuleData((prevData: any) => ({
                ...prevData,
                annual_month: value,
              }));

              setSelectedMonth(value);
            }}
            // sx={{ minWidth: '200px' }}
          >
            {monitoringList.map((month) => {
              return (
                <MenuItem value={month.month_year}>{month.month_year}</MenuItem>
              );
            })}
          </CustomSelect>
        </th>
        <th colSpan={39} align="left"></th>
      </tr>

      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S. NO.',
          'DATE',
          'NAME OF INJURED PERSON',
          'DEPTT',
          'ACCIDENT TYPE',
          'ACCIDENT DETAIL',
          'REASON FOR ACCIDENT',
          'ACTION PLAN TO AOID ACCIDENT',
          'SUP. SIGN',
          'REMARKS',
        ].map((head) => (
          <th
            style={{ textTransform: 'uppercase' }}
            colSpan={head === 'REMARKS' ? 2 : 1}
          >
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
