import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import CustomSelect from '../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [defectData, setDefectData] = useState<any>({
    defect_name: null,
    // type_of_defect: null,
  });
  const [render, setRender] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Reasons For Rejection' : 'Create Reasons For Rejection'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/reasonsForRejection/${id}`)
        .then((res) => setDefectData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleDefectData(e: React.ChangeEvent<HTMLInputElement>) {
    setDefectData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/reasonsForRejection/${id}`, defectData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/reasonsForRejection', defectData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'defect_name'}
        name={'defect_name'}
        html_for={'defect_name'}
        label_name={'Defect Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={defectData?.defect_name || ''}
        onChange={handleDefectData}
      />
      {/* <SelectGridMui
        name="type_of_defect"
        value={defectData?.type_of_defect || ''}
        lable={'Type Of Defect: '}
        onChange={(e) => {
          defectData.type_of_defect = e.target.value;
          setRender((prev: any) => !prev);
        }}
      >
        <MenuItem value="Scrap">Scrap</MenuItem>
        <MenuItem value="Rework">Rework</MenuItem>
      </SelectGridMui> */}
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
