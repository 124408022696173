import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import {
  CustomButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import TController from './components/create/TController';
import { IModule, ISpec } from './interface';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import lodash from 'lodash';
import { Loader } from '../../../../components/common/Loader';

const Create = () => {
  const navigate = useNavigate();
  const { beforeDieCastingId } = useParams();
  const mold_id = useQuery('mold_id');
  const moldName = useQuery('moldName') || '';
  const departmentName = useQuery('departmentName');
  const frequency = useQuery('frequency');
  const [render, setRender] = useState(false);

  const [moduleData, setModuleData] = useState<IModule>({
    insp_standard_date: new Date(),
    mold_id: mold_id || null,
    moldName: moldName || '',
    departmentName: departmentName || '',
    frequency: frequency,
    prepared_by: '',
    approved_by: '',
    specs: [],
    image: [],
    revision_history: Array.from({ length: 5 }, () => ({
      ...lodash.cloneDeep({}),
    })),
  });
  const [loader, setLoader] = useState<boolean>(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  useEffect(() => {
    if (!beforeDieCastingId) return;
    axios
      .get(`/api/beforeLoadingDieFormats/${beforeDieCastingId}`)
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const addRows = () => {
    const values = { ...moduleData };
    values.specs.push({
      s_no: moduleData.specs.length + 1,
      spec_to_inspection: true,
    });
    setModuleData(values);
  };

  const onSubmit = async () => {
    // const ans = await confirmSubmit();
    // if (!ans) return;
    if (beforeDieCastingId) {
      axios
        .put(`/api/beforeLoadingDieFormats/${beforeDieCastingId}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfull');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/beforeLoadingDieFormats', moduleData)
        .then((res) => {
          if (res.data) {
            alert('created successfull');
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // const upload = (e: any) => {
  //   setLoader(true);
  //   const formData = new FormData();
  //   formData.append('file', e.target.files[0], 'iqs.xlsx');

  //   axios
  //     .post('/api/excelUploadRoute/inspectionQualityStandard', formData)
  //     .then((res) => {
  //       const uploadData = res.data.newPart.specs;
  //       console.log(uploadData);
  //       uploadData.forEach((item: ISpec, index: number) => {
  //         item.s_no = moduleData.specs.length + index + 1;
  //         item.spec_to_inspection = true;
  //       });
  //       moduleData.specs = [...uploadData, ...moduleData.specs];
  //       e.target.value = '';
  //       setLoader(false);
  //       setRender((prev) => !prev);
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       if (err.response.data.err) {
  //         window.alert(err.response.data.err);
  //       } else {
  //         window.alert(
  //           'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.'
  //         );
  //       }
  //     });
  // };
  return (
    <Box>
      <Box>
        <Loader loader={loader} />

        <ChildHeader text="Before Loading Die Checksheet">
          {/* <a target={'_blank'} href={moduleData.image && moduleData.image[0]}>
            <CustomButton icon={<AiFillEye />}>VIEW DRAWING</CustomButton>
          </a> */}
          {!isView && (
            <>
              {/* <CustomUploadButton lable={'UPLOAD'} onChange={upload} /> */}

              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={addRows}
              >
                ADD ROWS
              </CustomButton>

              <SubmitButton label="Submit" onClick={() => onSubmit()} />
            </>
          )}
        </ChildHeader>
        <TController {...{ moduleData, isView }} />
      </Box>
    </Box>
  );
};

export default Create;
