import React, { useState } from 'react';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView, allmonth }: IProps) => {
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.processes?.map((process: any, index: number) => (
          <React.Fragment key={index + 1 + 'process'}>
            <TBodyRow
              {...{
                moduleData,
                process,
                index,
                isView,
                // handelDeleteRows,
                // setIsOpenPopup,
                // setPartIdx,
              }}
            />
          </React.Fragment>
        ))}
    </tbody>
  );
};

export default TBody;
