import React from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Iprops {
  label?: string;
  onChange: (e: any) => void;
  accept?: string;
  multiple?: boolean;
  id?: string;
}

const CustomUploadIcon = (props: Iprops) => {
  const { onChange, label, accept, multiple, id } = props;

  return (
    <div>
      <input
        id={id ? id : 'contained-button-file'}
        type="file"
        name="excelFile"
        multiple={multiple}
        accept={
          accept
            ? accept
            : '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        }
        onChange={onChange}
        hidden
      />
      <label htmlFor={id ? id : 'contained-button-file'}>
        <Tooltip title={label ? label : 'Upload'}>
          <IconButton component="span" sx={{ color: '#1864ab' }}>
            <AiOutlineCloudUpload />
          </IconButton>
        </Tooltip>
      </label>
    </div>
  );
};

export default CustomUploadIcon;
