import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
// import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import { SelectChangeEvent } from '@mui/material';
import { IData } from './Interface';
// import { IData } from './Interface';

const Create = ({
  id,
  setIsModal,
  setMachineId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMachineId: any;
}) => {
  const [moduleData, setModuleData] = useState<IData>({});
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Auditor' : 'Create Auditor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/internalAuditorSelection/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    const { name, value } = e.target;
    setModuleData((prev: any) => ({ ...prev, [name]: value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/internalAuditorSelection/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
          setMachineId(null);
        })
        .catch((err) => console.log(err));
    } else {
      console.log('mod', moduleData);
      await axios
        .post('/api/internalAuditorSelection', moduleData)
        .then((res) => {
          setIsModal(false);
          setMachineId(null);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'categories'}
        name={'categories'}
        html_for={'categories'}
        label_name={'categories:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.categories}
        onChange={handleChange}
      />
      <GridInputs
        id={'systemAudit'}
        name={'systemAudit'}
        html_for={'systemAudit'}
        label_name={'System Audit:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.systemAudit}
        onChange={handleChange}
      />
      <GridInputs
        id={'productAudit'}
        name={'productAudit'}
        html_for={'productAudit'}
        label_name={'Product Audit :'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.productAudit}
        onChange={handleChange}
      />
      <GridInputs
        id={'manufacturingProcessAudit'}
        name={'manufacturingProcessAudit'}
        html_for={'manufacturingProcessAudit'}
        label_name={'Manufacturing Process Audit'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.manufacturingProcessAudit}
        onChange={handleChange}
      />
      <GridInputs
        id={'secondPartyAudit'}
        name={'secondPartyAudit'}
        html_for={'secondPartyAudit'}
        label_name={'Second Party Audit'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.secondPartyAudit}
        onChange={handleChange}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
