export const tableHeaderObj = [
  [
    { text: '#', style: { border: '1px solid black' } },

    {
      text: 'Location',
      style: { border: '1px solid black' },
    },
    {
      text: 'Code No.',

      style: { border: '1px solid black' },
    },

    {
      text: 'Remark',

      style: { border: '1px solid black' },
    },
    {
      text: 'Checklist',

      style: { border: '1px solid black' },
    },
    {
      text: 'Action',

      style: { border: '1px solid black' },
    },
  ],
];
