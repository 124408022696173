export const listConstant = {
  childHeader: 'Annual PM Plan',
  addButtonLable: 'New Annual PM Plan ',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW Annual PLAN',
};

export const createConstant = {
  childHeader: 'Annual PM Plan list',
  theadHeader: 'Annual PM Plan',
  extracolSpan: 4,
};

export const checkListList = {
  childHeader: 'Annual PM Plan STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'annualpmplanrotorcastingRoute',
  gettableDataurl: 'annualpmplanrotorcastingRoute/monthly',
  getPlanByid: 'annualpmplanrotorcastingRoute',
  createPlanByid: 'annualpmplanrotorcastingRoute',
  updatePlanByid: 'annualpmplanrotorcastingRoute',
  sync: 'annualpmplanrotorcastingRoute/handleSync',
  getAllCheckList: 'annualpmplanrotorcastingRoute/getAllCheckList',
  checkListStatus: 'annualpmplanrotorcastingRoute/handleStatus',
  checklistUpload: 'annualpmplanrotorcastingRoute/handleUpload',
  checklistDeleteImage: 'annualpmplanrotorcastingRoute/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/annualPmPlanRotorCastingCreate',
};
