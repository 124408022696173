export const listConstant = {
  childHeader: 'CALIBRATION plan',
  addButtonLable: 'New CALIBRATION',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'CALIBRATION plan',
  theadHeader: 'CALIBRATION Plan',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'CALIBRATION STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'calibrationPlanRoute',
  gettableDataurl: 'calibrationPlanRoute/monthly',
  getPlanByid: 'calibrationPlanRoute',
  createPlanByid: 'calibrationPlanRoute',
  updatePlanByid: 'calibrationPlanRoute',
  sync: 'calibrationPlanRoute/handleSync',
  getAllCheckList: 'calibrationPlanRoute/getAllCheckList',
  checkListStatus: 'calibrationPlanRoute/handleStatus',
  checklistUpload: 'calibrationPlanRoute/handleUpload',
  checklistDeleteImage: 'calibrationPlanRoute/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/calibrationPlanCreate',
};
