import moment from 'moment';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot>
      <tr>
        <th colSpan={11}>प्रिवेंटिव मेंटेनेंस करने वाले का नाम</th>
      </tr>
      <tr>
        <th colSpan={11}>प्रिवेंटिव मेंटेनेंस चेक करने वाले का नाम</th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
