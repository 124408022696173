export const levels = [
  { name: 'L1', value: 'L1' },
  { name: 'L2', value: 'L2' },
  { name: 'L3', value: 'L3' },
  { name: 'L4', value: 'L4' },
];

export const testLevel = [
  { name: 'LEVEL-1', value: 'LEVEL-1' },
  { name: 'LEVEL-2', value: 'LEVEL-2' },
];
