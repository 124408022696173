import lodash from 'lodash';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
import { createConstant } from '../../constant';
import TableInput from '../../../../../components/common/TableInput';
import { c_list } from '../../helpers/initialState';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

interface ICustomerList {
  moduleData: any;
  setRender: any;
  isView: any;
}
const getYearTotal = (month: any) => {
  const countNotNullElements = month
    .slice(0, month.length - 1)
    .filter((elem: any) => elem != null).length;
  if (countNotNullElements == 0) return null;

  return +(
    month
      .slice(0, month.length - 1)
      .reduce((sum: any, count: any) => sum + +count, 0) / countNotNullElements
  ).toFixed(0);
};

const NormalList = ({ moduleData, setRender, isView }: ICustomerList) => {
  return (
    <>
      {moduleData?.normal_list?.map((process: any, processIndex: any) => (
        <>
          <tr style={{ background: '#38d9a9' }}>
            {processIndex != 6 && processIndex != 9 && (
              <>
                <th rowSpan={processIndex === 5 || processIndex === 8 ? 2 : 1}>
                  {processIndex === 7 || processIndex === 8
                    ? processIndex + 12
                    : processIndex + 13}
                </th>
                <th
                  style={{ minWidth: '200px' }}
                  rowSpan={processIndex === 5 || processIndex === 8 ? 2 : 1}
                >
                  {process?.parameter}
                </th>
              </>
            )}
            <th style={{ minWidth: '200px' }}>{process?.header}</th>
            <th style={{ width: '100px' }} rowSpan={process.length + 1}>
              {process?.uom}
            </th>
            <th style={{ minWidth: '100px' }}>
              <TableInput
                isView={isView}
                name="status"
                type="text"
                value={process.status}
                onChange={(e: any) => {
                  process.status = e.target.value;
                  setRender((prev: any) => !prev);
                }}
              />
            </th>
            <th style={{ minWidth: '100px' }}>
              <TableInput
                isView={isView}
                name="target"
                type="text"
                value={process.target}
                onChange={(e: any) => {
                  process.target = e.target.value;
                  setRender((prev: any) => !prev);
                }}
              />
            </th>
            {process?.month?.map((monthValue: any, monthIndex: any) => (
              <td key={`month_${monthIndex}`} style={{ width: '100px' }}>
                <TableInput
                  isView={isView}
                  name="month"
                  type={processIndex == 6 ? 'text' : 'number'}
                  step={
                    processIndex == 2 || processIndex == 5 || processIndex == 7
                      ? 0.01
                      : 1
                  }
                  value={process?.month?.[monthIndex]}
                  onChange={(e: any) => {
                    process.month[monthIndex] = e.target.value;
                    if (processIndex != 6)
                      process.month[12] = getYearTotal(process.month);
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
            ))}
          </tr>
        </>
      ))}
    </>
  );
};

export default NormalList;
