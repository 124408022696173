import { useState } from 'react';
import { TableInput } from '../../../../components/common';

const TFoot = ({ moduleData, isView }: any) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      {/* <tr>
        <th colSpan={1} align="left">
          Prepared By: {moduleData?.report_prepared_by}
        </th>
        <th colSpan={2} align="left">
          Approved By: {moduleData?.report_approved_by}
        </th>
      </tr> */}
      <tr>
        <th style={{ textAlign: 'left' }}></th>
        <th style={{ textAlign: 'left' }}></th>
        <th style={{ textAlign: 'left' }}>
          Deviation If Any :{' '}
          <TableInput
            isView={isView}
            name="deviation"
            type="text"
            value={moduleData.deviation}
            onChange={(e: any) => {
              moduleData.deviation = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th style={{ textAlign: 'left' }}>
          Signature of prodn. supervisor :{' '}
          <TableInput
            isView={isView}
            name="prod_supervisor"
            type="text"
            value={moduleData.prod_supervisor}
            onChange={(e: any) => {
              moduleData.prod_supervisor = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th style={{ textAlign: 'left' }} rowSpan={2}>
          Production machine :{' '}
          <TableInput
            isView={isView}
            name="prod_machine"
            type="text"
            value={moduleData.prod_machine}
            onChange={(e: any) => {
              moduleData.prod_machine = e.target.value;
              setRender((prev) => !prev);
            }}
          />
          <br></br>
          Die loading on :{' '}
          <TableInput
            isView={isView}
            name="die_loading_on"
            type="text"
            value={moduleData.die_loading_on}
            onChange={(e: any) => {
              moduleData.die_loading_on = e.target.value;
              setRender((prev) => !prev);
            }}
          />
          <br></br>
          Die unloaded on :{' '}
          <TableInput
            isView={isView}
            name="die_unloaded"
            type="text"
            value={moduleData.die_unloaded}
            onChange={(e: any) => {
              moduleData.die_unloaded = e.target.value;
              setRender((prev) => !prev);
            }}
          />
          <br></br>
          Total shot made :{' '}
          <TableInput
            isView={isView}
            name="total_shot"
            type="text"
            value={moduleData.total_shot}
            onChange={(e: any) => {
              moduleData.total_shot = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th style={{ textAlign: 'left' }} rowSpan={3}>
          Incharge die maintenance :{' '}
          <TableInput
            isView={isView}
            name="incharge_maint"
            type="text"
            value={moduleData.incharge_maint}
            onChange={(e: any) => {
              moduleData.incharge_maint = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th style={{ textAlign: 'left' }}>
          Signature of Q.C. incharge :{' '}
          <TableInput
            isView={isView}
            name="qc_incharge"
            type="text"
            value={moduleData.qc_incharge}
            onChange={(e: any) => {
              moduleData.qc_incharge = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th style={{ textAlign: 'left' }}>
          Sample enclosed yes/no :{' '}
          <TableInput
            isView={isView}
            name="sample_enclosed"
            type="text"
            value={moduleData.sample_enclosed}
            onChange={(e: any) => {
              moduleData.sample_enclosed = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th style={{ textAlign: 'left' }}>
          Last sample handed over to QC yes/no :{' '}
          <TableInput
            isView={isView}
            name="qc_sample"
            type="text"
            value={moduleData.qc_sample}
            onChange={(e: any) => {
              moduleData.qc_sample = e.target.value;
              setRender((prev) => !prev);
            }}
          />
          <br></br>
          Signature Of Prodn. Incharge :{' '}
          <TableInput
            isView={isView}
            name="prod_incharge"
            type="text"
            value={moduleData.prod_incharge}
            onChange={(e: any) => {
              moduleData.prod_incharge = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
