import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { createConstant } from '../../constant';
import formatDate from '../../../../../components/common/formatDate';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import { SelectChangeEvent, TextField } from '@mui/material';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
// import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleChange: any;
}

const TBody = ({ moduleData, isView, handleChange }: IProps) => {
  const [render, setRender] = useState(false);
  const [data, setData] = useState<any>({});

  return (
    <tbody>
      {moduleData.levels.map((level: any, levelIndex: any) => (
        <tr key={levelIndex + 'skillEval'}>
          <th
            style={{
              border: '1px solid black',
              textAlign: 'center',
            }}
          >
            {level.name}
          </th>
          <th
            style={{
              border: '1px solid black',
              textAlign: 'center',
              fontSize: '2rem',
            }}
          >
            {level.symbol}
          </th>
          <th
            style={{
              border: '1px solid black',
            }}
          >
            {isView ? (
              <div>
                {level.criteria &&
                  level.criteria.split(/\n/).map((line: any) => (
                    <>
                      {line}
                      <br />
                    </>
                  ))}
              </div>
            ) : (
              <TextField
                type="text"
                variant="filled"
                name="criteria"
                multiline={true}
                minRows={10}
                maxRows={20}
                className="textarea-field"
                InputProps={{ disableUnderline: true }}
                style={{
                  width: '100%',
                  border: 'none',
                  paddingTop: '10px',
                }}
                onChange={(e) => {
                  handleChange(e, levelIndex);
                }}
                defaultValue={level.criteria}
              />
            )}
          </th>
          <th
            style={{
              border: '1px solid black',
            }}
          >
            {isView ? (
              <div>
                {level.level &&
                  moduleData?.levels[levelIndex].level
                    .split(/\n/)
                    .map((line: any) => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
              </div>
            ) : (
              <TextField
                type="text"
                variant="filled"
                name="level"
                multiline={true}
                minRows={10}
                maxRows={20}
                className="textarea-field"
                InputProps={{ disableUnderline: true }}
                style={{
                  width: '100%',
                  border: 'none',
                  paddingTop: '10px',
                }}
                onChange={(e) => {
                  handleChange(e, levelIndex);
                }}
                defaultValue={level.level}
              />
            )}
          </th>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
