import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import AutocompleteMuiCustomRevised from '../../../../../components/common/AutocompleteMuiCustomRevised';
import { getTotalColsSpan } from '../../../../../utils/helpers/utils';

interface IProps {
  moduleData: any;
  machineData?: any;
  updateModuleData?: any;
  department?: any;
}

const THead = ({
  machineData,
  moduleData,
  updateModuleData,
  department,
}: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;

  const handleAutocompleteChangeMachine = (newValue: any) => {
    updateModuleData('key-pair', null, 'machine_name', {
      machine_name: newValue?.machine_name,
    });
    updateModuleData('key-pair', null, 'machine_no', {
      machine_no: newValue?.machine_no,
    });
    updateModuleData('key-pair', null, 'machine_id', { machine_id: newValue });
  };
  const handleAutocompleteChangeDepartment = (newValue: any) => {
    updateModuleData('key-pair', null, 'department_name', {
      department_name: newValue?.department_name,
    });
    updateModuleData('key-pair', null, 'department_id', {
      department_id: newValue,
    });
  };

  const handleInputChange = (
    fieldName: string,
    value: string | number | null,
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(HeaderStandard)}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Date']}
        values={['F-MNT-03 AS', '00', '13.10.2023']}
        heading={`${headerConstant.tableCompHeadTitle} ${
          moduleData?.checkList_name || ''
        }`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={8}></th>
      </tr>

      <tr style={{ height: '50px' }}>
        <th>Machine Number</th>
        <th align="left">
          <AutocompleteMuiCustomRevised
            id="Machine No."
            label=""
            option_name="machine_no"
            key={'machine'}
            arrayofObj={machineData}
            value={moduleData?.machine_id || ''}
            onChange={(_, newValue: any) =>
              handleAutocompleteChangeMachine(newValue)
            }
            sx={{ width: '200px' }}
          />
        </th>
        <th>Department</th>
        <th align="left">
          <AutocompleteMuiCustomRevised
            id="department_name"
            label=""
            option_name="department_name"
            key={'department_name'}
            arrayofObj={department}
            value={moduleData?.department_id || ''}
            onChange={(_, newValue: any) =>
              handleAutocompleteChangeDepartment(newValue)
            }
            sx={{ width: '200px' }}
          />
        </th>
        <th></th>
        <th>Date:</th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.pm_date_2)
          ) : (
            <DatePickerMui
              label=""
              value={
                moduleData?.pm_date_2 ? moment(moduleData.pm_date_2) : null
              }
              onChange={(date) => {
                handleInputChange('pm_date_2', date);
              }}
            />
          )}
        </th>
        <th>Date:</th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.pm_date_3)
          ) : (
            <DatePickerMui
              label=""
              value={
                moduleData?.pm_date_3 ? moment(moduleData.pm_date_3) : null
              }
              onChange={(date) => {
                handleInputChange('pm_date_3', date);
              }}
            />
          )}
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
