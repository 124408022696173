import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={3}
        labels={['Doc. ', 'Rev. No', 'Dated']}
        values={['No-F-QA-16B', ' 00', '12.07.2019']}
        heading="History Card- Gauges & Instruments"
      />
      <tr>
        <th colSpan={3} align="left">
          Gauge/Instrument Identification Code :-
        </th>
        <th colSpan={1} align="left">
          {/* {moduleData?.instrument_type?.name} */}
          {moduleData?.instrument_no}
        </th>
        <th colSpan={2} align="left">
          Least Count :-
        </th>
        <th colSpan={2} align="left">
          {moduleData?.least_count}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          History Card No. :-
        </th>
        <th colSpan={1} align="left">
          <TableInput
            isView={isView}
            name="history_card_no"
            type="number"
            value={moduleData.history_card_no}
            onChange={(e) => {
              moduleData.history_card_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Cal. Frequency :-
        </th>
        <th colSpan={2} align="left">
          {moduleData?.frequency_cycle_new}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Location :-
        </th>
        <th colSpan={1} align="left">
          {moduleData?.location}
        </th>
        <th colSpan={2} align="left">
          Range :-
        </th>
        <th colSpan={2} align="left">
          {moduleData?.range}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Make :-
        </th>
        <th colSpan={1} align="left">
          {moduleData?.make}
        </th>
        <th colSpan={2} align="left"></th>
        <th colSpan={2} align="left"></th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.NO',
          'Calibration Date',
          'Calibrated By',
          'Obervations',
          'Next Calibration',
          'Sign.',
          'Remarks',
        ].map((head, index) => (
          <th
            style={{ textTransform: 'uppercase' }}
            colSpan={head === 'Remarks' ? 2 : 1}
            key={'heding' + index}
          >
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
