// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const numberCol = Array.from({ length: 2 }, (_, index) => index + 1);

const theadData = [
  'S.N.',
  'Area',
  'Standard',
  'Instrument',
  'First 15 days',
  'Next 15 days',
  'Remarks',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [machineData, setMachineData] = useState([]);
  useEffect(() => {
    axios
      .get('/api/luxLevelRoute/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date:']}
        values={['F-PROD-52', '03', '19-07-2023']}
        heading="LUX LEVEL MONITORING Sheet"
      />
      <tr>
        <th colSpan={1} align="left"></th>
        <th colSpan={1} align="left">
          {/* {isView ? (
            `${moduleData?.machine_id?.machine_no}/
            ${moduleData?.machine_id?.machine_name}`
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              option_name_2="machine_name"
              arrayofObj={machineData}
              value={moduleData.machine_id || ''}
              onChange={(e, value) => {
                moduleData.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )} */}
        </th>
        <th colSpan={1} align="left"></th>

        <th colSpan={1} align="left"></th>
        <th colSpan={1} align="left">
          Date :
          {isView ? (
            formatDate(moduleData.dailyBin_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyBin_date)}
              onChange={(date) => {
                moduleData.dailyBin_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1} align="left">
          Date :
          {isView ? (
            formatDate(moduleData.dailyBin_date1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyBin_date1)}
              onChange={(date) => {
                moduleData.dailyBin_date1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1}></th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
