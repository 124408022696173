import { Box } from '@mui/material';
import TBody from './TBody';
import THead from './THead';
import { RenderTable } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setRender: any;
  setSelectedMonth: (month: string) => void;
  setModuleData: (data: any) => void;
}

const TController = ({
  moduleData,
  isView,
  setRender,
  handelDeleteRows,
  setSelectedMonth,
  setModuleData,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            setRender,
            moduleData,
            isView,
            setSelectedMonth,
            setModuleData,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
