import { useEffect, useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = ['Sr.No.', 'Area', ...numberCol, 'Remarks'];

const THead = ({
  moduleData,
  isView,
  departmentData,
  setDepartmentAutoComplete,
  departmentAutoComplete,
}: any) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={34}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date:']}
        values={['F-HRD-20', '01', '26.04.2023']}
        heading="DAILY PPE MONITORING SHEET FOR SAFETY"
      />
      <tr>
        <th colSpan={2} align="left">
          Department:-
        </th>
        <th colSpan={3} align="left">
          {isView ? (
            moduleData?.department_id?.department_name
          ) : (
            <AutocompleteMuiCustom
              id="Department"
              label=""
              option_name="department_name"
              key={'department_name'}
              arrayofObj={departmentData}
              value={departmentAutoComplete || moduleData?.department_id || ''}
              onChange={(e, value) => {
                moduleData.department_id = value;
                setDepartmentAutoComplete(value ? value : null);
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={29} align="left"></th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
