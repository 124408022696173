import { Verified } from '@mui/icons-material';
import React from 'react';
import {
  AddIconButton,
  CustomIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { actionTakenTickMarks } from '../helpers/data';
export const CheckIconButton = ({
  onClick,
  tooltipTitle,
  style,
}: {
  onClick?: () => void;
  tooltipTitle: string;
  style?: any;
}) => {
  return (
    <CustomIconButton
      tooltipTitle={tooltipTitle}
      color="secondary"
      sx={{ m: 1 }}
      disabled={false}
      size="small"
      onClick={onClick}
    >
      <Verified sx={{ m: 0, ...style, color: '#40c057' }} />
    </CustomIconButton>
  );
};

const ReportsButton = ({
  process,
  reportKey,
  redirect,
}: {
  process: any;
  reportKey: string;
  redirect: any;
}) => {
  if (process.reason && process.action_taken) {
    if (
      actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]?.[
        reportKey
      ]
    ) {
      return process[`${reportKey}_id`] ? (
        <CheckIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey, false);
          }}
        />
      ) : (
        <EditIconButton
          tooltipTitle="add"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey, false);
          }}
        />
      );
    }
  }
  if (process.reason) {
    if (actionTakenTickMarks[`${process?.reason}`]?.[reportKey]) {
      return process[`${reportKey}_id`] ? (
        <CheckIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey, false);
          }}
        />
      ) : (
        <EditIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey, false);
          }}
        />
      );
    }
  }

  return <React.Fragment></React.Fragment>;
};
export default ReportsButton;
