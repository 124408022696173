import { useState } from 'react';
import { TableInput } from '../../../../../../../components/common';
import { createConstant } from '../../constant';
import formatDate from '../../../../../../../components/common/formatDate';
import InputMuiCustom from '../../../../../../../components/common/inputs/InputMuiCustom';
import { SelectChangeEvent } from '@mui/material';
import DatePickerMui from '../../../../../../../components/common/DatePickerMui';
// import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
interface IProps {
  moduleData: any;
  tniTopics: any;
  TickCrossSelectField: any;
}

const TBody = ({ moduleData, tniTopics, TickCrossSelectField }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData?.departments?.length &&
        moduleData.departments[0].employeelist.map(
          (item: any, index: number) => (
            <>
              <tr>
                <td>{index + 1}</td>
                <td
                  style={{
                    minWidth: '200px',
                  }}
                >
                  {item?.employee_id?.employee_name}
                </td>
                <td>{item?.employee_id?.designation}</td>
                {tniTopics.map((topic: any, index: number) => (
                  <td>{TickCrossSelectField(item, index)}</td>
                ))}
                <td align="center">
                  <TableInput
                    isView={false}
                    style={{ width: '120px' }}
                    name="remarks"
                    type="text"
                    value={item.remarks || ''}
                    onChange={(e) => {
                      item.remarks = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
            </>
          )
        )}
    </tbody>
  );
};

export default TBody;
