import { HiDocumentReport } from 'react-icons/hi';
import { RenderTiles } from '../../../../components/common';
import ChildHeader from '../../../../components/ui/ChildHeader';

export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    link: '/common/skillEvaluationUploadList?documentType=eductionManagementClause5&heading=MANAGEMENT',
    isLink: true,
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'MANAGEMENT',
    span: '',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/skillEvaluationUploadList?documentType=eductionOperatorClause5&heading=OPERATOR',
    isLink: true,
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'OPERATOR',
    span: '',
    isIcon: true,
  },
];

const SkillEvaluationDownloadTestPaperHome = () => {
  return (
    <>
      <ChildHeader text="DOWNLOAD TEST PAPER" />
      <RenderTiles tilesObj={tiles} height={'100vh'} justify="start" />
    </>
  );
};

export default SkillEvaluationDownloadTestPaperHome;
