import { createConstant } from '../../constant';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = () => {
  return (
    <tfoot>
      <tr style={{ height: '40px', border: '1px solid black' }}></tr>
      <tr>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        ></td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          LEGENDS:-
        </td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        ></td>
      </tr>
      <tr>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        ></td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          ◔
        </td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          Trainee
        </td>
      </tr>
      <tr>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        ></td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          ◑
        </td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          Semi skilled
        </td>
      </tr>
      <tr>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        ></td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          ◕
        </td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          Skilled
        </td>
      </tr>
      <tr>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        ></td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          ⬤
        </td>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          Highly skilled
        </td>
      </tr>
      <tr style={{ height: '40px', border: '1px solid black' }}></tr>
      <tr>
        <td
          align="center"
          style={{
            border: '1px solid black',
          }}
        >
          नोट -
        </td>
        <td
          align="center"
          colSpan={2}
          style={{
            border: '1px solid black',
          }}
        >
          फिटर या टर्नर का स्किल लेवल कम से कम दो होना चाहिए
        </td>
      </tr>
    </tfoot>
  );
};

export default TFoot;
