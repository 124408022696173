import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'Sr. No.',
  'दिनांक',
  'आइटम नाम व कोड ',
  'कुल रीवर्क पार्ट्स ',
  'रीवर्क करने के बाद रिजैक्ट पार्ट्स',
  'रीवर्क करने के बाद ओके पार्ट्स ',
  'Reason for rejection',
  'रिवर्क करने वाले का नाम ',
  'Invoice नंबर ',
  'इंचार्ज के साइन',
  'Action',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('annual_date');
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-PROD-05', '00', '01.04.2011']}
        heading={`रिवर्क रजिस्टर : Month - (${date})`}
      />
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
