import { useState } from 'react';
import {
  calculateTotalShots,
  handleChangeModuleData,
} from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import Operator from '../../../components/Operator';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  autoCompleteMachineName: any;
  handleSumbit: any;
  toolsData: any;
}

const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  autoCompleteMachineName,
  handleSumbit,
  toolsData,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td style={{ minWidth: '200px' }}>
                {isView ? (
                  process?.die_id?.die_name
                ) : (
                  <AutocompleteMuiCustom
                    id="die_id"
                    label=""
                    option_name="die_name"
                    arrayofObj={toolsData}
                    value={process?.die_id || ''}
                    onChange={(e, value) => {
                      process.die_id = value;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              {/* <td style={{ minWidth: '200px' }}>
                {process?.die_id?.part_id?.part_name}
              </td> */}
              <td style={{ minWidth: '200px' }}>
                {moduleData?.mc_id && (
                  <Operator
                    onSubmit={handleSumbit}
                    field="operator"
                    machineId={moduleData?.mc_id?._id || moduleData?.mc_id}
                    dataObj={{
                      date: moduleData?.dailyProduction_date?._d,
                    }}
                    {...{ isView, process }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="product_desc"
                  type="text"
                  value={process.product_desc}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="target_shots"
                  type="number"
                  value={process.target_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  convToTime(process.counter_start)
                ) : (
                  <TimePicker
                    value={moment(process.counter_start)}
                    onChange={(date: any) => {
                      process.counter_start = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>

              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  convToTime(process?.counter_end)
                ) : (
                  <TimePicker
                    value={moment(process?.counter_end)}
                    onChange={(date: any) => {
                      process.counter_end = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="total_shots"
                  type="number"
                  value={process.total_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    calculateTotalShots(moduleData);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="off_shots"
                  type="number"
                  value={process.off_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="n_f"
                  type="number"
                  value={process.n_f}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="catch"
                  type="number"
                  value={process.catch}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="a_b"
                  type="number"
                  value={process.a_b}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="b_h"
                  type="number"
                  value={process.b_h}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="solder"
                  type="number"
                  value={process.solder}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="bend"
                  type="number"
                  value={process.bend}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="crack"
                  type="number"
                  value={process.crack}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="dent"
                  type="number"
                  value={process.dent}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="rejection_detail_others"
                  type="number"
                  value={process.rejection_detail_others}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="total_rej_shots"
                  type="number"
                  value={process.total_rej_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="ok_shots"
                  type="number"
                  value={process.ok_shots}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="die_maint"
                  type="text"
                  value={process.die_maint}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="die_dh_over"
                  type="text"
                  value={process.die_dh_over}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="degassing"
                  type="text"
                  value={process.degassing}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="sft_ch_over"
                  type="text"
                  value={process.sft_ch_over}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="no_operator"
                  type="text"
                  value={process.no_operator}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="mc_maint"
                  type="text"
                  value={process.mc_maint}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="power_cut"
                  type="text"
                  value={process.power_cut}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="no_plan"
                  type="text"
                  value={process.no_plan}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="reason_less_prod_others"
                  type="text"
                  value={process.reason_less_prod_others}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{moduleData?.sumOfTotalShots}</td>
          <td colSpan={22}></td>
        </tr>
      </tbody>
    </>
  );
};

export default TBody;
