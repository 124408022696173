export const listConstant = {
  childHeader: 'ACTIVE PREVENTIVE CHECKLISTS',
  addButtonLable: 'New MTTR',
  datePickerLabel: 'Search By Year',
  modalTile: 'SELECT CHECKLIST',
};

export const createConstant = {
  childHeader: 'CHECKLIST OF MACHINE MAINTENANCE',
  theadHeader: 'PREVENTIVE MAINTENANCE CHECKLIST',
  extracolSpan: 0,
};

export const checkListList = {
  childHeader: 'LAYOUT STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'mttrRoute',
  gettableDataurl: 'mttrRoute/yearly',
  getPlanByid: 'mttrRoute',
  createPlanByid: 'mttrRoute',
  updatePlanByid: 'mttrRoute',
  sync: 'layoutplanRoute/handleSync',
  getAllCheckList: 'layoutplanRoute/getAllCheckList',
  checkListStatus: 'layoutplanRoute/handleStatus',
};

export const navigationConstant = {
  createPlanSchedule: '/common/mttrCreate',
};
