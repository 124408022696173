import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { Box, Checkbox, Divider, Stack } from '@mui/material';
import AutocompleteMuiCustom from '../../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import { TableInput } from '../../../../../../components/common';
import { convToTime } from '../../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  // toolsData: any;
  departmentData: any;
}

const theadData = [
  'Breakdown Details',
  'Action Taken',
  'Spare Parts Used',
  'Remarks',
];

const THead = ({
  moduleData,
  isView,
  machineData,
  // toolsData,
  departmentData,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['F-MNT-30', '01', '02-02-2023']}
        heading="Maintenance Break Down Slip"
      />
      <tr>
        <th style={{ minWidth: '120px' }} align="left">
          Machine No:
          {isView && moduleData?.machine_id?.machine_no}
          {!isView && (
            <AutocompleteMuiCustom
              id="machine_no"
              // label="Machine"
              option_name="machine_no"
              // key={'machine'}
              arrayofObj={machineData}
              value={moduleData?.machine_id || ''}
              onChange={(e, value) => {
                moduleData.machine_id = value;
                setRender((prev) => !prev);
              }}
              sx={{ width: '350px' }}
            />
          )}
        </th>
        <th align="left">
          Notification No.:
          <TableInput
            isView={isView}
            name="notification_no"
            type="text"
            value={moduleData.notification_no}
            onChange={(e) => {
              moduleData.notification_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th style={{ minWidth: '250px' }} align="left">
          Department:
          {isView ? (
            moduleData?.department_id?.department_name
          ) : (
            <AutocompleteMuiCustom
              id="department_name"
              label=""
              option_name="department_name"
              // key={'tools'}
              arrayofObj={departmentData}
              value={moduleData?.department_id || ''}
              onChange={(e, value) => {
                moduleData.department_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left">
          Date :
          {isView ? (
            formatDate(moduleData.moduleData_Date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_Date)}
              onChange={(date) => {
                moduleData.moduleData_Date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left">
          Shift:
          <TableInput
            isView={isView}
            name="shift"
            type="text"
            value={moduleData.shift}
            onChange={(e) => {
              moduleData.shift = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </thead>
  );
};

export default THead;
