import moment from 'moment';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot style={{ background: '#a9e34b' }}>
      <tr>
        <th colSpan={2}>
          No.of parts checked after PM and result(10 parts) -{' '}
        </th>
        <th colSpan={1}>
          <TableInput
            isView={isView}
            name="foot_one"
            type="text"
            value={moduleData.foot_one}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            name="foot_two"
            type="text"
            value={moduleData.foot_two}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
