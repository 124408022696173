import { useEffect, useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import { useQuery } from '../../../../../hooks/UseQuery';
import { getMonthYearly } from '../../helpers/utils';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
  setRender: any;
  setSelectedMonth: (month: any) => void;
  selectedMonth: any;
}

const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  backgroundColor: '#ffec99',
} as const;

const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = ['Sr.No.', 'Accident', '', ...numberCol, 'Remarks'];

const THead = ({
  moduleData,
  isView,
  setRender,
  setSelectedMonth,
  selectedMonth,
}: IProps) => {
  const [departmentData, setDepartmentData] = useState([{}]);
  const [monitoringList, setMoniteringList] = useState<
    { month_year: string }[]
  >([]);
  const date = useQuery('date');

  useEffect(() => {
    const year = date ? parseInt(date, 10) : new Date().getFullYear();
    const data = getMonthYearly(year);
    setMoniteringList(data);
  }, [date]);

  useEffect(() => {
    axios
      .get('/api/department/department/departmentForAutocomplete')
      .then((res) => {
        setDepartmentData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <thead>
      <TableCompHead
        colSpan={35}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date:']}
        values={['F-HRD-20', '01', '26.04.2023']}
        heading="DAILY ACCIDENT MONITORING SHEET"
      />
      <tr>
        <th colSpan={2} align="left">
          Select Month:
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            moduleData?.annual_month
          ) : (
            // <AutocompleteMuiCustom
            //   id="Month"
            //   label="Select Month"
            //   option_name="month_year"
            //   key="month_selector"
            //   arrayofObj={monitoringList}
            //   value={moduleData?.annual_month || ''}
            //   onChange={(e, value) => {
            //     const selectedMonthYear = value?.month_year || '';
            //     moduleData.annual_month = selectedMonthYear;
            //     setRender((prev: any) => !prev);
            //     setSelectedMonth(selectedMonthYear);
            //   }}
            // />
            <CustomSelect
              name="annual_month"
              value={selectedMonth || moduleData?.annual_month}
              label={''}
              onChange={(e) => {
                const value = e.target.value;
                moduleData.annual_month = value;
                setSelectedMonth(value);
              }}
              // sx={{ minWidth: '200px' }}
            >
              {monitoringList.map((month) => {
                return (
                  <MenuItem value={month.month_year}>
                    {month.month_year}
                  </MenuItem>
                );
              })}
            </CustomSelect>
          )}
        </th>
        <th colSpan={39} align="left"></th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading}
            style={
              index === 1 || index === 2
                ? {
                    minWidth: '150px',
                    ...stickStylesColumn,
                    left: 200 * (index - 1),
                  }
                : { minWidth: '150px' }
            }
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
